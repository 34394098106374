import { IGenericModal, Modal } from 'components/Modal/Modal';
import {
  IStatusFilters,
  TasksStatusFilters
} from 'components/TasksStatusFilters/TasksStatusFilters';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskTable } from 'views/TasksView/components/Content/TaskTable/TaskTable';

export const TasksConnectedWithEvaluationModal = ({
  isOpen,
  onCancelClick
}: IGenericModal) => {
  const [statusFilters, setStatusFilters] = useState<IStatusFilters>({
    without_active: false,
    without_archived: false,
    without_cancelled: false
  });

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      mainButton={{
        action: onCancelClick,
        label: t('common.close')
      }}
      headerTitle={t('evaluationsView.evaluationConnectedTasksList')}
      onCancelClick={onCancelClick}
      gridTemplateColumns="90%"
      additionalFooter={
        <TasksStatusFilters
          statusFilters={statusFilters}
          setStatusFilters={setStatusFilters}
        />
      }
    >
      {/* TODO: handle params () for fetching tasks from api */}
      <TaskTable
        // evaluationIds={[]}
        nameColMinWidth="150px"
        showTopBar={false}
        showDetailedHeader
        tableContainerHeight="55vh"
        defaultHiddenItems={['practice', 'taskType']}
        areItemsSelectable={false}
        without_active={statusFilters.without_active}
        without_archived={statusFilters.without_archived}
        without_cancelled={statusFilters.without_cancelled}
        loadContextFilters={false}
        // TODO: additional buttons prop (archive etc.)
      />
    </Modal>
  );
};
