import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { TextareaField } from 'components/_form';

import { useTranslation } from 'react-i18next';

interface Props extends IGenericModal {}

export const CancelTaskModal = ({ isOpen, onCancelClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('tasks.taskCancelationConfirm?')}
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t('common.yes')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column">
        <TextareaField label="administration.description" />
      </Flex>
    </Modal>
  );
};
