import { DatePicker } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './ImplementationFilter.styled';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';

export const ImplementationFilter: React.FC = () => {
  const { t } = useTranslation();
  const { filters: { implementation }, setFilters, getDateLabel } = useTaskSidebarContext();

  return (
    <Wrapper>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          label="Od"
          isClearable
          value={implementation.value.from}
          maxDate={implementation.value.to}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              implementation: {
                label: getDateLabel(t('tasks.implementation') as string, val, prevState.implementation.value.to),
                value: {
                  from: val,
                  to: prevState.implementation.value.to
                }
              }
            }
          })}
        />
      </div>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          label="Do"
          isClearable
          value={implementation.value.to}
          minDate={implementation.value.from}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              implementation: {
                label: getDateLabel(t('tasks.implementation') as string, prevState.implementation.value.from, val),
                value: {
                  from: prevState.implementation.value.from,
                  to: val
                }
              }
            }
          })}
        />
      </div>
    </Wrapper>
  );
};
