import * as yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(yup);

export const changeProfilePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'auth.passMinVal')
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minSymbols(1, 'password must contain at least 1 special character')
    .required('auth.passwordRequired'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'auth.passDiffErr')
});
