import { FC } from 'react';
import { Flex } from 'components';
import styled from 'styled-components';
import { P } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';

const StyledImg = styled.img`
  object-fit: contain;
  margin-top: -100px;
`;

export const AnalyticsView: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="start"
      justifyItems="start"
      mt="82px"
      minHeight="90vh"
    >
      <P variant="body">{t('common.makeAuditsToUnlock')}</P>
      {/* <StyledImg width="100%" src={analyticsMockImage} alt="analytics" /> */}
    </Flex>
  );
};
