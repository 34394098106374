import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { theme } from 'styles';
import { ITask } from 'types/task';

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  task?: ITask;
  fontSize?: string;
}

export const TaskStar: React.FC<Props> = ({
  task,
  fontSize = '18px',
  ...props
}) => {
  const isElementStarred = (el?: ITask) => {
    if (!el) return false;

    if (el.starred_by_current_user_areas) return theme.palette.accent.red;
    else if (el.starred_by_team_and_current_user_participate_in)
      return theme.palette.accent.blue;
    else if (el.starred_by_current_user) return theme.palette.accent.green;

    return false;
  };

  return (
    <FontAwesomeIcon
      {...props}
      fontSize={fontSize}
      icon={!!isElementStarred(task) ? faStar : faStarRegular}
      color={isElementStarred(task) || ''}
    />
  );
};
