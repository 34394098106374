import { Flex } from 'components';
import styled from 'styled-components';

export const TableHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.tableHeader};
  min-height: 50px;
  align-items: end;
  padding: 6px;
  font-size: ${({ theme }) => `${theme.textStyles.body.fontSize}px`};
  font-weight: bold;
`;

export const TableCell = styled(Flex)`
  min-height: 50px;
  padding: 5px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
  font-size: ${({ theme }) => `${theme.textStyles.body.fontSize}px`};
  font-weight: bold;
`;
