import React from 'react';
import styled from 'styled-components';
import { Box, Card } from 'components';
import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { useDropdown } from 'hooks/useDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';

export interface IDropdown {
  items?: Array<{
    label: string;
    onClick: () => void;
    icon: IconDefinition;
  }>;
  children?: React.ReactNode;
  customDropdown?: React.ReactNode;
  bgColor?: string;
  width?: string;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled(Flex)<{ bgColor?: string }>`
  cursor: pointer;
  border-radius: 30px 0 0 30px;
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.palette.neutral.tablePagination};
`;

const WrapperDropdown = styled.div`
  ${({ theme }) => `
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: 16px;
    min-width: 180px;
    z-index: 1000;
  `}
`;

const StyledTypography = styled(P)`
  ${({ theme }) => `
    padding: 12px 0px;
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: ${theme.palette.neutral.lightBlue};
    }
  `}
`;

export const Dropdown: React.FC<IDropdown> = ({
  items,
  children,
  customDropdown,
  bgColor,
  width
}) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role="button"
        aria-hidden="true"
        alignItems="center"
        bgColor={bgColor}
      >
        {children}

        <Box mx={2}>
          {isVisible ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </Box>
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Card p={3}>
            {items?.map(({ label, onClick, icon }) => (
              <div
                key={label}
                onClick={handleClick(onClick)}
                aria-hidden="true"
                role="button"
              >
                <StyledTypography variant="body" color="grey">
                  {icon && <FontAwesomeIcon icon={icon} />} {label}
                </StyledTypography>
              </div>
            ))}

            {customDropdown && customDropdown}
          </Card>
        </WrapperDropdown>
      )}
    </Root>
  );
};
