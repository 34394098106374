import { Flex } from 'components';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { TabPanel } from 'react-tabs';

export const Methodology = () => {
  return (
    <CustomTabs selectedTabClassName="is-selected" style={{ display: 'flex' }}>
      <CustomTabList style={{ width: '180px' }}>
        <Flex flexDirection="column" width="180px">
          {/* <CustomTab
            bgColor={theme.palette.neutral.light}
            color={theme.palette.neutral.dark}
          >
            test
            <P variant="body">test</P>
          </CustomTab>
          <CustomTab
            bgColor={theme.palette.neutral.light}
            color={theme.palette.neutral.dark}
          >
            test
          </CustomTab> */}
        </Flex>
      </CustomTabList>
      <TabPanel>
        {/* <div data-color-mode="light">
          <MDEditor.Markdown
            style={{ padding: 10 }}
            source="Markdown <b>testowy<b/>"
            linkTarget="_blank"
          />
        </div> */}
      </TabPanel>
    </CustomTabs>
  );
};
