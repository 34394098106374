import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { Table } from 'components/Table';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { PadlockButton } from 'components/_form/PadlockButton/PadlockButton';
import { IOption, Select } from 'components/_form/Select/Select';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { format } from 'date-fns';
import { useAccount } from 'hooks/useAccount';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAuditsClosing } from 'types/auditsClosings';
import { IEvaluationsClosing } from 'types/evaluationsClosings';
import { handlePrevState } from 'utilities/handlePrevState';
import { makeUserOption } from 'utilities/makeUserOption';
import { onlyUniqueArray } from 'utilities/onlyUniqueArray';

export interface UserOption extends IOption {
  role?: string;
}

interface Props {
  closingIds: string[];
  setClosingIds: (ids: string[]) => void;
  closings: (IEvaluationsClosing | IAuditsClosing)[];
  usersList: IOption[];
  userFunctionsList: IOption[];
  authorId?: string;
  leaderId?: string;
  teamLeaderId?: string;
  teamManagerId?: string;
  participationsAttributes: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
  handleClose: (id?: number) => void;
  handleOpen: (id?: number) => void;
  isAddingRowVisible: boolean;
  setIsAddingRowVisible: Dispatch<SetStateAction<boolean>>;
}

export const ClosingTable: React.FC<Props> = ({
  closingIds,
  setClosingIds,
  closings,
  usersList,
  userFunctionsList,
  authorId,
  leaderId,
  teamLeaderId,
  teamManagerId,
  participationsAttributes,
  handleClose,
  handleOpen,
  isAddingRowVisible,
  setIsAddingRowVisible
}) => {
  const { t } = useTranslation();

  const { loggedUserData } = useAccount();

  const usersOptions = [
    makeUserOption({
      usersList,
      userFunctionsList,
      userId: authorId,
      functionName: t('tasks.author')
    }),
    makeUserOption({
      usersList,
      userFunctionsList,
      userId: leaderId,
      functionName: t('createEvalutionView.leader')
    }),
    makeUserOption({
      usersList,
      userFunctionsList,
      userId: teamLeaderId,
      functionName: t('tasks.teamLeader')
    }),
    makeUserOption({
      usersList,
      userFunctionsList,
      userId: teamManagerId,
      functionName: t('tasks.teamManager')
    }),
    ...participationsAttributes
      .filter((participant) => participant.value)
      .map((participant) =>
        makeUserOption({
          usersList,
          userFunctionsList,
          userId: participant.value,
          functionId: participant.function_id
        })
      )
  ].filter((option) => option) as UserOption[];

  const tableHeaders: string[] = [
    t('evaluationsView.evaluationStatusModal.table.headers.role'),
    t('evaluationsView.evaluationStatusModal.table.headers.person'),
    `${t('evaluationsView.evaluationStatusModal.table.headers.isClosed')}?`,
    ''
  ];

  const data = useMemo(
    () => [
      ...(closingIds.map((id) => {
        const evaluationClosing = closings.find(
          ({ user: { id: userId } }) => userId.toString() === id.toString()
        );

        return {
          role: usersOptions.find(({ value }) => value === id)?.role || '',
          person: (
            <Select
              disabled={authorId === id}
              withoutMargin
              options={usersOptions}
              selectedOptions={usersOptions.find(
                ({ value }) => value.toString() === id.toString()
              )}
              onChange={(selectedOption) => {
                if (!Array.isArray(selectedOption)) {
                  setClosingIds(
                    onlyUniqueArray(
                      closingIds.map((prevId) =>
                        prevId === id ? selectedOption.value : prevId
                      )
                    )
                  );
                }
              }}
            />
          ),
          isClosed: (
            <Flex flexDirection="column" alignItems="center">
              <Flex
                flexDirection="row"
                justifyContent="space-around"
                width="100%"
              >
                <PadlockButton
                  disabled={
                    !(
                      evaluationClosing &&
                      (id.toString() === loggedUserData.id.toString() ||
                        loggedUserData.role === 'admin')
                    )
                  }
                  locked
                  active={!!evaluationClosing?.closed_at}
                  onClick={() => {
                    handleClose(evaluationClosing?.id);
                  }}
                />
                <PadlockButton
                  disabled={
                    !(
                      evaluationClosing &&
                      (id.toString() === loggedUserData.id.toString() ||
                        loggedUserData.role === 'admin')
                    )
                  }
                  active={!evaluationClosing?.closed_at}
                  onClick={() => {
                    handleOpen(evaluationClosing?.id);
                  }}
                />
              </Flex>
              <P variant="body" fontWeight={700}>
                {evaluationClosing?.closed_at &&
                  format(new Date(evaluationClosing?.closed_at), dateFormat)}
              </P>
            </Flex>
          ),
          options: (
            <Button
              disabled={authorId === id || !!evaluationClosing?.closed_at}
              onClick={() => {
                setClosingIds(handlePrevState(closingIds, id));
              }}
              variant="redWhite"
              bordered
              transparent
              icon={<FontAwesomeIcon icon={faUserSlash} />}
            />
          )
        };
      }) || []),
      ...(isAddingRowVisible
        ? [
            {
              role: '',
              person: (
                <Select
                  withoutMargin
                  isMulti={false}
                  options={usersOptions}
                  onChange={(selectedOption) => {
                    if (!Array.isArray(selectedOption)) {
                      setClosingIds(
                        onlyUniqueArray(
                          handlePrevState(closingIds, selectedOption.value)
                        )
                      );
                      setIsAddingRowVisible(false);
                    }
                  }}
                />
              ),
              isClosed: (
                <Flex flexDirection="row" justifyContent="space-around"></Flex>
              )
            }
          ]
        : [])
    ],
    [
      JSON.stringify(closingIds),
      isAddingRowVisible,
      JSON.stringify(usersOptions),
      JSON.stringify(closings),
      loggedUserData.id,
      loggedUserData.role,
      authorId,
      setClosingIds,
      handleClose,
      handleOpen,
      setIsAddingRowVisible
    ]
  );

  return <Table header={tableHeaders} data={data} />;
};
