import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileImage,
  faFile
} from '@fortawesome/free-solid-svg-icons';

interface Props extends Omit<FontAwesomeIconProps, 'icon'> {
  fileType?: string;
}

export const FileIcon: React.FC<Props> = ({ fileType, ...props }) => {
  let icon = faFile;

  switch (fileType) {
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'doc':
    case 'docx':
      icon = faFileWord;
      break;
    case 'xls':
    case 'xlsx':
      icon = faFileExcel;
      break;
    case 'ppt':
    case 'pptx':
      icon = faFilePowerpoint;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      icon = faFileImage;
      break;
    default:
      break;
  }

  return <FontAwesomeIcon {...props} icon={icon} />;
};
