import { Box, Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H3, P } from 'components/Typography/Typography';
import { Button, Select } from 'components/_form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const GappedFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const ErrorContainer = styled(Box)`
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.medium};
`;

const StyledSelect = styled(Box)`
  width: 300px;
`;
export const ImportTranslationsModal = ({
  isOpen,
  onCancelClick
}: IGenericModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      headerTitle={t('translationsView.importTranslations')}
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: onCancelClick,
        variant: 'grey',
        label: t('buttons.close')
      }}
    >
      <GappedFlex>
        <P variant="body">{t('employeesAndUsersView.shortInstruction')}</P>
        <Flex
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          style={{ gap: '10px' }}
        >
          <Button label={t('buttons.saveTemplate')} variant="eucalyptus" />
          <StyledSelect>
            <Select
              label={t('translationsView.importedLanguage')}
              options={[]}
            />
          </StyledSelect>

          <Button label={t('buttons.import')} variant="eucalyptus" />
        </Flex>
        <Box>
          <H3 variant="h3">{t('employeesAndUsersView.errorsRaport')}</H3>
          <ErrorContainer></ErrorContainer>
        </Box>
      </GappedFlex>
    </Modal>
  );
};
