import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import { useAccount } from 'hooks/useAccount';
import { t } from 'i18next';
import { useState } from 'react';

import { AccordionItemButton } from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import { IPasswordReset } from 'types/forms/passwordReset';
import { changeProfilePasswordSchema } from 'validation/profileSchemas';
import {
  StyledAccordionItemHeading,
  StyledAccordionItemPanel
} from '../../../ProfileView.styled';
import { PasswordErrorModal } from './PasswordErrorModal';
// @ts-ignore
import HIBPPasswordChecker from 'react-have-i-been-pwned';

export const PasswordForm = () => {
  const [passwordToCheck, setPasswordToCheck] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { loggedUserData } = useAccount();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm<IPasswordReset>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      password_confirmation: ''
    },
    resolver: yupResolver(changeProfilePasswordSchema)
  });

  const onSubmit = handleSubmit(async (data) => {
    const { password } = data;

    if (
      (loggedUserData.email && password.includes(loggedUserData.email)) ||
      (loggedUserData.firstName &&
        password.includes(loggedUserData.firstName)) ||
      (loggedUserData.lastName && password.includes(loggedUserData.lastName))
      // (loggedUserData.company && password.includes(loggedUserData.company))
    ) {
      setIsErrorModalOpen(true);
    }

    setPasswordToCheck(data.password);

    if (!isLoading) {
      // sending data to api
    }
  });

  return (
    <>
      <StyledAccordionItemHeading>
        <AccordionItemButton>
          <Flex p={2}>
            <FontAwesomeIcon icon={faCircleUser} />
            <P ml={2} variant="body">
              {t('auth.changePassword')}
            </P>
          </Flex>
        </AccordionItemButton>
      </StyledAccordionItemHeading>
      <StyledAccordionItemPanel>
        <form onSubmit={onSubmit}>
          <Flex flexDirection={['column', 'row']}>
            <Box mr={2} width="100%">
              <TextField
                label="password"
                type="password"
                {...register('password')}
              />
            </Box>
            <TextField
              label="auth.passwordConfirm"
              type="password"
              {...register('password_confirmation')}
            />
          </Flex>

          <Flex
            justifyContent="space-between"
            flexDirection={['column', 'row']}
          >
            <Flex flexDirection="column">
              <P variant="body" color="red">
                Minimalne wymagania wobec hasta:
              </P>
              <P variant="body" color="red">
                8 znaków, 1 duza litera, 1 mala litera, 1 znak nieliterowy
              </P>
            </Flex>
            <Button type="submit" disabled={!formState.isValid} variant="green">
              {t('common.save')}
            </Button>
          </Flex>
        </form>
      </StyledAccordionItemPanel>
      <PasswordErrorModal
        isModalOpen={isErrorModalOpen}
        setIsModalOpen={setIsErrorModalOpen}
      />

      <HIBPPasswordChecker password={passwordToCheck}>
        {({
          initial,
          loading,
          pwned
        }: {
          initial: boolean;
          loading: boolean;
          error: string;
          pwned: boolean;
          count: number;
        }) => {
          if (initial) return null;
          if (loading) {
            setIsLoading(true);
          }
          if (!pwned) {
            setIsLoading(false);
          }
          if (pwned) {
            setIsLoading(false);
            setIsErrorModalOpen(true);
            setPasswordToCheck('');
          }
        }}
      </HIBPPasswordChecker>
    </>
  );
};
