import { Flex } from 'components/Flex';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  // z-index: 100;
`;

export const Item = styled(Flex)<{
  childrenNumber?: number;
  childrenLines?: string | boolean;
  isActive?: boolean;
  canBeActive?: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  margin: 10px 0;
  align-items: center;
  position: relative;

  ${({ childrenNumber, childrenLines, isActive, canBeActive, theme }) => `
    ${
      isActive
        ? `
      background-color: ${theme.palette.accent.eucalyptus};
      color: ${theme.palette.neutral.white};
      `
        : ``
    }
    ${
      canBeActive
        ? `
        cursor: pointer;
      `
        : ``
    }
    ${
      childrenNumber
        ? `
      margin-left: auto;
      ::before {
        content: '';
        background: ${theme.palette.neutral.lightGrey};
        height: 1px;
        width: 10%;
        position: absolute;
        top: calc(50%);
        left: -10%;
        // z-index: 1;
      }
    `
        : ``
    }
    width: ${childrenNumber || 100}%;
    ${
      childrenLines
        ? `
      ::after {
        content: '';
        height: calc(${childrenLines});
        background: ${theme.palette.neutral.white};
        // z-index: 3;
        width: 5%;
        border-right: 1px solid ${theme.palette.neutral.lightGrey};
        position: absolute;
        top: 103%;
        left: 0;
      }
    `
        : ``
    };
  `};

  > p {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const ToggleSubItem = styled(Flex)<{ color?: string }>`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 26px;
  width: 26px;
  height: 26px;
  margin-right: 6px;
  background: ${({ theme }) => theme.palette.neutral.lightGrey};
  color: ${({ color }) => color};
`;

export const ToggleSubItemMock = styled(Flex)`
  min-width: 5px;
  width: 5px;
  height: 26px;
  margin-right: 6px;
  background: ${({ theme }) => theme.palette.neutral.lightGrey};
`;

export const InfoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;

  > svg {
    color: ${({ theme }) => theme.palette.neutral.lightGrey};
  }
`;
