import React, { useState } from 'react';
import { Typography, Card, Box } from 'components';
import styled from 'styled-components';
import { getCookie, setCookie } from '../../utilities/cookies';
import { Button } from '../_form';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Card).attrs({ width: '330px', height: '70px' })`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: 2px solid ${({ theme }) => theme.palette.neutral.mainBlue};
`;

export const Cookies = () => {
  const { t } = useTranslation();
  const acceptCookies = getCookie('acceptCookies');
  const [accepted, setAccepted] = useState<boolean>(!!acceptCookies);
  if (accepted) return <></>;

  return (
    <Wrapper>
      <Typography.P variant="h3" color="dark">
        {t('common.cookiesMessage')}
      </Typography.P>
      <Box ml={3}>
        <Button
          onClick={() => {
            setCookie('acceptCookies', 'true', 3600);
            setAccepted(true);
          }}
        >
          OK
        </Button>
      </Box>
    </Wrapper>
  );
};
