import { Flex } from 'components';
import { AuditSummaryStats } from 'components/_audit/AuditSummaryStats/AuditSummaryStats';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  IAuditScore,
  IDetailedAudit,
  IDetailedAuditCriterion,
  IPostAuditFormRequest
} from 'types/audits';
import { IRatingScale } from 'types/ratingScales';
import { addFakeIdToCriteria } from 'utilities/audit/addFakeIdToCriteria';
import { addFakeIdToCriteriaInGroups } from 'utilities/audit/addFakeIdToCriteriaInGroups';
import { parseGroupsToRadarChart } from 'utilities/audit/parseGroupsToRadarChart';
import { flattenNestedCriteria } from 'utilities/auditForm/flattenNestedCriteria';
import { parseGroupsAtrributesToGroups } from 'utilities/auditForm/parseGroupsAtrributesToGroups';
import AuditCriteriaHeaders from 'views/EvaluationsView/components/Audits/AuditFormModal/FormAudit/AuditCriteriaModal/AuditCriteriaHeaders/AuditCriteriaHeaders';
import { AuditCriteriaTable } from 'views/EvaluationsView/components/Audits/AuditFormModal/FormAudit/AuditCriteriaModal/AuditCriteriaTable';

interface PartialAuditForm
  extends Partial<Omit<IDetailedAudit['audit_form'], 'criteria'>> {
  criteria: Partial<IDetailedAuditCriterion>[];
}

export interface PartialAudit
  extends Partial<Omit<IDetailedAudit, 'audit_form' | 'scores'>> {
  audit_form: PartialAuditForm;
  scores?: Partial<IAuditScore>[];
}
interface Props {
  ratingScale?: IRatingScale;
}

export const AuditFormPreview: React.FC<Props> = ({ ratingScale }) => {
  const [previewAudit, setPreviewAudit] = useState<PartialAudit>();

  const { watch } = useFormContext<IPostAuditFormRequest['audit_form']>();

  const criteriaWithFakeIds = useMemo(
    () => addFakeIdToCriteria(watch('criteria_attributes')),
    [JSON.stringify(watch('criteria_attributes'))]
  );

  const groupsWithFakeCriteriaIds = useMemo(
    () => addFakeIdToCriteriaInGroups(watch('groups_attributes')),
    [JSON.stringify(watch('groups_attributes'))]
  );

  const flattenCriteria = useMemo(
    () => [
      ...(criteriaWithFakeIds || []),
      ...flattenNestedCriteria(groupsWithFakeCriteriaIds || [])
    ],
    [
      JSON.stringify(criteriaWithFakeIds),
      JSON.stringify(groupsWithFakeCriteriaIds)
    ]
  );

  const radarChartData = useMemo(
    () =>
      parseGroupsToRadarChart({
        groups: groupsWithFakeCriteriaIds,
        radarChartOrderNumber: watch('radar_chart_order_number') || '',
        scores: previewAudit?.scores || [],
        buttons: ratingScale?.buttons || []
      }),
    [
      JSON.stringify(groupsWithFakeCriteriaIds),
      previewAudit?.scores,
      JSON.stringify(ratingScale?.buttons),
      watch
    ]
  );

  useEffect(() => {
    setPreviewAudit((previewAuditPreview) => ({
      ...(previewAuditPreview || {}),
      audit_form: {
        ...(previewAuditPreview?.audit_form || {}),
        name: watch('name'),
        state: watch('state'),
        enforce_completeness: watch('enforce_completeness'),
        allow_copying_answers: watch('allow_copying_answers'),
        allow_copying_comments: watch('allow_copying_comments'),
        allow_copying_attachments: watch('allow_copying_attachments'),
        goal: watch('goal'),
        goal_range: watch('goal_range'),
        maximum_task_completion_time_iso8601: '',
        first_header_name: watch('first_header_name'),
        first_header_value: watch('first_header_value'),
        second_header_name: watch('second_header_name'),
        second_header_value: watch('second_header_value'),
        third_header_name: watch('third_header_name'),
        third_header_value: watch('third_header_value'),
        fourth_header_name: watch('fourth_header_name'),
        fourth_header_value: watch('fourth_header_value'),
        introduction: watch('introduction') || '',
        radar_chart_order_number: watch('radar_chart_order_number'),
        criteria: criteriaWithFakeIds.map((criterion) => ({
          ...criterion
        })),
        groups: parseGroupsAtrributesToGroups(groupsWithFakeCriteriaIds)
      },
      used_rating_scale: ratingScale,
      name: watch('name'),
      first_header_name: watch('first_header_name'),
      first_header_value: watch('first_header_value'),
      second_header_name: watch('second_header_name'),
      second_header_value: watch('second_header_value'),
      third_header_name: watch('third_header_name'),
      third_header_value: watch('third_header_value'),
      fourth_header_name: watch('fourth_header_name'),
      fourth_header_value: watch('fourth_header_value')
    }));
  }, [JSON.stringify(criteriaWithFakeIds), JSON.stringify(ratingScale), watch]);

  return (
    <Flex flexDirection="column">
      <AuditCriteriaHeaders audit={previewAudit} />

      <AuditCriteriaTable
        isFullData={false}
        audit={previewAudit}
        setAudit={setPreviewAudit}
        rowHeight={80}
        gradingScaleSwitch={false}
        hintSwitch={false}
        fontSize={16}
        textInCellLineHeight={24}
        flattenCriteria={flattenCriteria}
      />

      <AuditSummaryStats
        radarChartData={
          Object.keys(radarChartData).length > 2 ? radarChartData : undefined
        }
        scores={previewAudit?.scores || []}
        criteria={flattenCriteria}
        ratingScale={ratingScale}
        audit={previewAudit}
      />
    </Flex>
  );
};
