import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Wrapper } from './StarredFilter.styled';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '../../../../../../../components';
import { Checkbox } from '../../../../../../../components/_form';
import { theme } from '../../../../../../../styles';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { handlePrevState } from 'utilities/handlePrevState';

interface IStarredFilterItem {
  id: number;
  label: string;
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}

const filterItems: IStarredFilterItem[] = [
  {
    id: 1,
    label: 'tasks.starMy',
    color: theme.palette.accent.green
  },
  {
    id: 2,
    label: 'tasks.starMyTeams',
    color: theme.palette.accent.blue
  },
  {
    id: 3,
    label: 'tasks.starAsterisk',
    color: theme.palette.accent.red
  },
  {
    id: 4,
    label: 'tasks.starWithout',
    color: undefined
  }
];

export const StarredFilter: React.FC = () => {
  const [items, setItems] = useState<IStarredFilterItem[]>(filterItems);
  const {
    filters: { starred },
    setFilters
  } = useTaskSidebarContext();
  const { t } = useTranslation();

  const handleSelect = useCallback((id: number) => {
    setFilters((prevState) => {
      const actualState = handlePrevState(prevState.starred.value, id);

      return {
        ...prevState,
        starred: {
          label:
            actualState.length > 0
              ? t('tasks.withAnAsterisk') +
                ' - ' +
                filterItems
                  .filter((el) => actualState.includes(el.id))
                  .map((el) => t(el.label))
                  .join(', ')
              : '',
          value: actualState
        }
      };
    });
  }, []);

  return (
    <Wrapper>
      {items.map((el) => (
        <React.Fragment key={el.id}>
          <Item iconColor={el.color} onClick={() => handleSelect(el.id)}>
            <FontAwesomeIcon icon={el.color ? faStar : faStarRegular} />
            <p>{t(el.label)}</p>
            <Flex ml="auto">
              <Checkbox
                onChange={() => handleSelect(el.id)}
                checked={starred.value.includes(el.id)}
              />
            </Flex>
          </Item>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
