import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { AreaAccordionItem } from 'types/area';
import { getAreas } from 'api/areas';
import { parentToChildrenAreasStructure } from 'utilities/areas';
import { handlePrevState } from 'utilities/handlePrevState';
import { AreasTree } from 'components/AreasTree/AreasTree';

export const AreaFilter: React.FC = () => {
  const { t } = useTranslation();
  const [items, setItems] = useState<AreaAccordionItem[]>([]);

  const {
    filters: { areas },
    setFilters
  } = useTaskSidebarContext();

  const handleClick = useCallback((id: number) => {
    setFilters((prevState) => {
      const actualState = handlePrevState(prevState.areas.value, id);
      return {
        ...prevState,
        areas: {
          label:
            actualState.length > 0
              ? t('tasks.area') +
                ' - ' +
                items
                  .filter((el) => actualState.includes(el.id))
                  .map((el) => t(el.name))
                  .join(', ')
              : '',
          value: actualState
        }
      };
    });
  }, []);

  const handleGetAreas = async () => {
    const { data } = await getAreas();

    setItems(parentToChildrenAreasStructure(data));
  };

  useEffect(() => {
    handleGetAreas();
  }, []);

  return <AreasTree onClick={handleClick} items={items} />;
};
