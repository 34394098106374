import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { Select, TextField } from 'components/_form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ImageInput } from 'components/ImageInput/ImageInput';

const Row = styled(Flex)`
  width: 100%;
  align-items: center;
  gap: 10px;
`;

export const UserAddModal = ({ isOpen, onCancelClick }: IGenericModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      isSecondButtonVisible
      headerTitle={t('employeesAndUsersView.addUser')}
      mainButton={{
        action: () => {},
        label: t('buttons.add')
      }}
    >
      <Flex flexDirection="column">
        <Row flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
          <Select
            label={t('employeesAndUsersView.form.companyName')}
            options={[
              { label: 'aa', value: 'ss' },
              { label: 'aa', value: 'ss' }
            ]}
          />
          <TextField label="Kod ID" disabled />
          <ImageInput
            type="photo"
            placeholder={t('employeesAndUsersView.form.photoPlaceholder')}
          />
        </Row>
        <Row flexWrap={['wrap', 'nowrap']}>
          <TextField label={t('employeesAndUsersView.form.firstName')} />
          <TextField label={t('employeesAndUsersView.form.secondName')} />
          <TextField label={t('employeesAndUsersView.form.lastName')} />
        </Row>
        <Row flexWrap={['wrap', 'nowrap']}>
          <TextField label={t('employeesAndUsersView.form.emailAddress')} />
          <TextField label={t('employeesAndUsersView.form.phone')} />
          <TextField label={t('employeesAndUsersView.form.mobilePhone')} />
        </Row>
        <Row flexWrap={['wrap', 'nowrap']}>
          <TextField label={t('employeesAndUsersView.form.userName')} />
          <TextField label={t('employeesAndUsersView.form.password')} />
        </Row>
        <Row flexWrap={['wrap', 'nowrap']}>
          <Select
            label={t('employeesAndUsersView.form.language')}
            options={[
              { label: 'aa', value: 'ss' },
              { label: 'aa', value: 'ss' }
            ]}
          />
          <Select
            label={t('employeesAndUsersView.form.mainArea')}
            options={[
              { label: 'aa', value: 'ss' },
              { label: 'aa', value: 'ss' }
            ]}
          />
          <Select
            label={t('employeesAndUsersView.form.roleInCompany')}
            options={[
              { label: 'aa', value: 'ss' },
              { label: 'aa', value: 'ss' }
            ]}
          />
        </Row>
      </Flex>
    </Modal>
  );
};
