import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as PasswordIcon } from 'assets/icons/lock-password-fill.svg';
import { useForm } from 'react-hook-form';
import { Box, Flex, Typography, Spinner } from 'components';
import { Button, TextField } from 'components/_form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { changePasswordSchema } from '../validation/authSchemas';
import { changeUserPassword } from '../api/auth';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IChangePassword } from '../types/forms/auth';

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
`;

export const ChangePasswordView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const token = searchParams.get('token') || undefined;

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm<IChangePassword>({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        reset_password_token: token
      }
    },
    resolver: yupResolver(changePasswordSchema)
  });

  const onSubmit = async (data: IChangePassword) => {
    if (!token) return;

    setIsLoading(true);
    try {
      await changeUserPassword(data);
      setIsLoading(false);
      toast.success(t('auth.passwordChanged'));
      navigate('/login');
    } catch (err: any) {
      toast.error(err?.message || t('anErrorOccurred'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !token && navigate('/');
  }, [token]);

  return (
    <Wrapper height="100%" flexDirection="column" justifyContent="center">
      <Typography.H2 variant="h3" color="primary" fontWeight={600} mb={4}>
        {t('auth.changePassword')}
      </Typography.H2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type="email"
          label="common.email"
          transparent
          {...register('user.email')}
          error={errors?.user?.email?.message}
        />
        <TextField
          type="password"
          label="auth.newPassword"
          transparent
          icon={<PasswordIcon />}
          {...register('user.password')}
          error={errors?.user?.password?.message}
        />
        <Box mt={4} mb={2}>
          <TextField
            type="password"
            label="auth.newPasswordConfirm"
            transparent
            icon={<PasswordIcon />}
            {...register('user.password_confirmation')}
            error={errors?.user?.password_confirmation?.message}
          />
        </Box>

        <Flex minWidth={['0px', '390px']} my={4} flexWrap={['wrap', 'nowrap']}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Box mr={[0, 1]} mb={[2, 0]} width={['100%', '55%']}>
                <Button
                  fullWidth
                  type="button"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  {t('auth.backToLoginPage')}
                </Button>
              </Box>
              <Box width={['100%', '45%']}>
                <Button
                  variant="primary"
                  fullWidth
                  type="submit"
                  disabled={!formState.isValid}
                >
                  {t('auth.saveNewPassword')}
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </form>
    </Wrapper>
  );
};
