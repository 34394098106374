import { Flex, Box } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H3 } from 'components/Typography/Typography';
import { Radio } from 'components/_form';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RadioGroup = styled(Flex)`
  gap: 30px;
`;

const Logo = styled(Flex)`
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 0 auto;
  padding: 2px;
  width: fit-content;
  ${({ theme }) => `
  background-color: ${theme.palette.primary.dark};
  color: ${theme.palette.neutral.light};
  `};
`;

export const SendMessageModal = ({ isOpen, onCancelClick }: IGenericModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('employeesAndUsersView.messageForTeam')}
      mainButton={{
        variant: 'blue',
        action: () => {},
        label: t('buttons.sendMessage')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column" style={{ gap: '30px' }}>
        <Flex style={{ gap: '50px' }}>
          <H3 variant="h3">{t('employeesAndUsersView.team')}: Nazwa Zespołu</H3>
          <H3 variant="h3">{t('employeesAndUsersView.leader')}: Lider</H3>
        </Flex>
        <Editor
          placeholder={
            t('employeesAndUsersView.form.editorPlaceholder') as string
          }
        />
        <Box>
          <H3 variant="h3">{t('buttons.sendMessage')}:</H3>
          <RadioGroup>
            <Radio label={t('employeesAndUsersView.form.onlyToLeader')} />
            <Radio label={t('employeesAndUsersView.form.toAllMembers')} />
          </RadioGroup>
        </Box>
      </Flex>
    </Modal>
  );
};
