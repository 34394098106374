import {
  IAuditsClosingCloseResponse,
  IAuditsClosingOpenResponse
} from 'types/auditsClosings';
import request from './index';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const closeAuditClosing = (id: number | string) => {
  try {
    return request<IAuditsClosingCloseResponse>({
      options: {
        url: `/audits_closings/${id}/close`,
        method: 'patch'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const openAuditClosing = (id: number | string) => {
  try {
    return request<IAuditsClosingOpenResponse>({
      options: {
        url: `/audits_closings/${id}/open`,
        method: 'patch'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
