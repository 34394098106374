import {
  CollapseButtonWrapper,
  Content,
  ContentChildrenWrapper,
  ResizerButton,
  SidebarResizer,
  StyledBlockName,
  Wrapper
} from './DocumentsView.styled';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Sidebar } from './components/Sidebar/Sidebar';
import {
  faCaretLeft,
  faCaretRight,
  faChevronDown,
  faChevronUp,
  faDownload,
  faFileCirclePlus,
  faFilePen,
  faLanguage,
  faShareFromSquare,
  faTrashCan,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { t } from 'i18next';
import { SearchBar } from './components/SearchBar/SearchBar';
import { Blocks } from 'components/Blocks';
import { useDocumentsSidebarContext } from 'contexts/DocumentsSidebarContext';
import { Button } from 'components/_form';
import { TabPanel } from 'react-tabs';
import { IBlock } from 'types/methologies';
import { ImplementationActivities } from './components/ImplementationActivities/ImplementationActivities';
import { Methodology } from './components/Methodology/Methodology';
import { Examples } from './components/Examples/Examples';
import { SearchResults } from './components/SearchResults/SearchResults';
import { useAccount } from 'hooks/useAccount';
import { Tooltip } from 'react-tooltip';
import { faComment, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import SendLinkModal from './components/SendLinkModal/SendLinkModal';
import { Fade } from '@mui/material';
import Popper from '@mui/base/Popper';
import CommentPopper from './components/CommentPopper/CommentPopper';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const DocumentsView = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(280);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchResultsOpen, setSearchResultsOpen] = useState(true);
  const [blocksOpen, setBlocksOpen] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState<IBlock>();
  const [searchPhrase, setSearchPhrase] = useState('');
  const { selectedPractice } = useDocumentsSidebarContext();

  const windowWidth = useWindowWidth();

  //For Buttons section
  const { loggedUserRole } = useAccount();
  const [allLanguageActive, setAllLanguageActive] = useState<boolean>(false);
  const [isComment, setIsComment] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const showComment = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [openSendModal, setOpenSendModal] = useState<boolean>(false);
  const startResizing = useCallback((mouseDownEvent: MouseEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: unknown) => {
      if (isResizing) {
        const val =
          // @ts-ignore
          mouseMoveEvent?.clientX -
          // @ts-ignore
          sidebarRef?.current?.getBoundingClientRect().left;

        val > 250 && val < 400 && setSidebarWidth(val);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    setSidebarOpen(windowWidth > 768);
  }, [windowWidth]);

  const sidebarMemo = useMemo(() => <Sidebar />, []);
  const content = useMemo(
    () => (
      <Content>
        <ContentChildrenWrapper>
          <SearchBar
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
          />
        </ContentChildrenWrapper>

        {selectedPractice && (
          <>
            <CollapseButtonWrapper>
              <Button
                variant="greyWhite"
                onClick={() => {
                  setBlocksOpen((prevState) => !prevState);
                }}
              >
                <Flex gap="5px" alignItems="center">
                  <FontAwesomeIcon
                    icon={blocksOpen ? faChevronUp : faChevronDown}
                  />

                  {blocksOpen
                    ? t('documentsView.collapseBlocks')
                    : t('documentsView.expandBlocks')}
                </Flex>
              </Button>
            </CollapseButtonWrapper>

            {blocksOpen && (
              <ContentChildrenWrapper>
                <Blocks
                  practice={selectedPractice}
                  selectedBlock={selectedBlock}
                  checkButton={false}
                  onBlockClick={(block) => {
                    setSelectedBlock(block);
                  }}
                />
              </ContentChildrenWrapper>
            )}
          </>
        )}

        {searchPhrase.length > 0 && (
          <>
            <CollapseButtonWrapper>
              <Button
                variant="redWhite"
                onClick={() => {
                  setSearchPhrase('');
                }}
              >
                <Flex gap="5px" alignItems="center">
                  <FontAwesomeIcon icon={faX} />

                  {t('documentsView.closeSearchResults')}
                </Flex>
              </Button>

              <Button
                variant="greyWhite"
                onClick={() => {
                  setSearchResultsOpen((prevState) => !prevState);
                }}
              >
                <Flex gap="5px" alignItems="center">
                  <FontAwesomeIcon
                    icon={searchResultsOpen ? faChevronUp : faChevronDown}
                  />

                  {searchResultsOpen
                    ? t('documentsView.collapseSearchResults')
                    : t('documentsView.expandSearchResults')}
                </Flex>
              </Button>
            </CollapseButtonWrapper>

            {searchResultsOpen && (
              <ContentChildrenWrapper>
                <SearchResults />
              </ContentChildrenWrapper>
            )}
          </>
        )}

        {selectedBlock && (
          <ContentChildrenWrapper flexDirection="column" gap="10px">
            <CustomTabs selectedTabClassName="is-selected">
              <CustomTabList style={{ borderBottom: 'none' }}>
                <Flex flexWrap="wrap">
                  <CustomTab>{t('documentsView.methodology')}</CustomTab>
                  <CustomTab>{t('documentsView.actions')}</CustomTab>
                  <CustomTab>{t('documentsView.examples')}</CustomTab>
                  <CustomTab>{t('documentsView.companyFiles')}</CustomTab>

                  <Flex ml="auto" my="auto" maxWidth="300px">
                    <StyledBlockName
                      variant="body"
                      color="coloured"
                      fontWeight={700}
                    >
                      {selectedBlock.name}
                    </StyledBlockName>
                  </Flex>
                </Flex>
              </CustomTabList>

              <TabPanel>
                <Methodology />
              </TabPanel>

              <TabPanel>
                <Flex justifyContent="end">
                  <ImplementationActivities />
                </Flex>
              </TabPanel>

              <TabPanel>
                <Examples document_urls={selectedBlock.document_urls} />
              </TabPanel>
            </CustomTabs>
            <Flex flexDirection="column" gap="5px" width="170px">
              {loggedUserRole != 'admin' && (
                <Flex flexDirection="row" gap="5px">
                  <Button
                    fontSize="18px"
                    bordered
                    variant="green"
                    fullWidth
                    icon={<FontAwesomeIcon icon={faFileCirclePlus} />}
                    onClick={() => {}}
                  />
                  <Button
                    fontSize="18px"
                    bordered
                    variant="blue"
                    icon={<FontAwesomeIcon icon={faFilePen} />}
                    fullWidth
                    onClick={() => {}}
                  />
                  <Button
                    fontSize="18px"
                    bordered
                    variant="primary"
                    icon={<FontAwesomeIcon icon={faDownload} />}
                    fullWidth
                    onClick={() => {}}
                  />
                  <Button
                    fontSize="18px"
                    bordered
                    variant="red"
                    icon={<FontAwesomeIcon icon={faTrashCan} />}
                    fullWidth
                    onClick={() => {}}
                  />
                </Flex>
              )}

              <Flex flexDirection="row" gap="5px">
                <Tooltip
                  anchorId="languagesButton"
                  content={
                    allLanguageActive
                      ? t('documentsView.showInMyLanguage') ?? ''
                      : t('documentsView.showInAllLanguages') ?? ''
                  }
                />
                <Button
                  id="languagesButton"
                  fontSize="18px"
                  bordered={!allLanguageActive ? true : false}
                  variant={allLanguageActive ? 'eucalyptus' : 'grey'}
                  fullWidth
                  icon={<FontAwesomeIcon icon={faLanguage} />}
                  onClick={() => {
                    setAllLanguageActive((prev) => !prev);
                  }}
                />

                <Button
                  fontSize="18px"
                  bordered
                  variant="blue"
                  icon={<FontAwesomeIcon icon={faShareFromSquare} />}
                  fullWidth
                  onClick={() => {
                    setOpenSendModal(true);
                  }}
                />
                <Button
                  aria-describedby={id}
                  fontSize="18px"
                  disabled={!isComment}
                  bordered={!isComment}
                  variant={!isComment ? 'grey' : open ? 'primary' : 'blue'}
                  icon={
                    <FontAwesomeIcon
                      icon={!isComment ? faComment : faCommentDots}
                    />
                  }
                  fullWidth
                  onClick={showComment}
                />
                <Popper
                  open={open}
                  id={id}
                  anchorEl={anchorEl}
                  placement="right-end"
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <div>
                        <CommentPopper
                          file={{ name: 'Nazwa pliku', desc: 'Opis pliku' }}
                          comment="Dołączony komentarz do pliku"
                          onClose={() => {
                            setAnchorEl(null);
                          }}
                        />
                      </div>
                    </Fade>
                  )}
                </Popper>
              </Flex>
            </Flex>
          </ContentChildrenWrapper>
        )}
      </Content>
    ),
    [
      selectedBlock,
      selectedPractice,
      blocksOpen,
      searchResultsOpen,
      searchPhrase,
      allLanguageActive,
      anchorEl
    ]
  );

  return (
    <>
      <Wrapper>
        <div
          ref={sidebarRef}
          style={{
            width: sidebarWidth,
            display: sidebarOpen ? 'block' : 'none'
          }}
        >
          {sidebarMemo}
        </div>
        <SidebarResizer onMouseDown={startResizing}>
          <ResizerButton
            onClick={() => setSidebarOpen((prevState) => !prevState)}
          >
            <FontAwesomeIcon icon={sidebarOpen ? faCaretLeft : faCaretRight} />
          </ResizerButton>
        </SidebarResizer>
        {content}
      </Wrapper>
      <SendLinkModal
        isOpen={openSendModal}
        onCancelClick={() => setOpenSendModal(false)}
      />
    </>
  );
};
