import { Flex } from 'components';
import { H2 } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import styled from 'styled-components';

export const ModalHeaderWrapper = styled(Flex)`
  width: 100%;
`;

export const CriteriaExample = styled(H2)`
  min-width: 20%;
  line-height: 48px;
  font-weight: 800;
`;

export const HeaderTitle = styled(H2)`
  color: ${({ theme }) => theme.palette.neutral.mainBlue};
  max-height: 72px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderTitleWrapper = styled(Flex)`
  display: flex;
  align-items: center;
`;

export const LeftPanelScrollWrapper = styled(Flex)`
  max-height: 60vh;
  width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledButton = styled(Button)`
  margin-right: 5px;
`;
