import { IPostAuditFormRequest } from 'types/audits';

export const clearCriteriaFromIds = (
  criteriaAttributes: IPostAuditFormRequest['audit_form']['criteria_attributes']
) => {
  let result: IPostAuditFormRequest['audit_form']['criteria_attributes'] = [];

  criteriaAttributes.map((criterionAttribute) => {
    if ('id' in criterionAttribute) {
      const { id, ...restData } = criterionAttribute;

      result = [...result, { ...restData }];
    } else {
      result = [...result, criterionAttribute];
    }
  });

  return result;
};
