import { Box } from 'components/Box';
import { FieldLabel as Label } from 'components/_form/FieldLabel';
import { t } from 'i18next';
import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { rgba } from 'utilities/rgba';
import { ErrorText } from '../ErrorText';

export interface ITextareaField
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string | null;
  error?: string | null;
  unit?: string;
  icon?: React.ReactNode;
  bgColor?: string;
  ExternalItem?: JSX.Element;
  resize?: boolean;
  width?: string;
}

interface StyledTextareaI {
  error?: ITextareaField['error'];
  unit?: ITextareaField['unit'];
  icon?: ITextareaField['icon'];
  bgColor?: ITextareaField['bgColor'];
  resize?: ITextareaField['resize'];
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const InputWrapper = styled.div<{
  width?: ITextareaField['width'];
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ width }) => `width: ${width || '100%'};`};
  position: relative;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;   
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledTextarea = styled.textarea<StyledTextareaI>`
  ${({ theme, error, unit, icon, bgColor, resize }) => `
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    ${resize ? '' : 'resize: none;'}
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ''};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ''};
    font-size: 13px;
    font-family: inherit;
    background-color: ${bgColor ? bgColor : theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.lightGrey};
    border-radius: 3px;
    outline: none;

    &:focus {
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

const ExternalItemWrapper = styled(Box)`
  position: absolute;
  right: 0;
`;

export const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaField
>(
  (
    {
      label,
      error,
      unit,
      icon,
      disabled,
      bgColor,
      ExternalItem,
      resize = false,
      width,
      ...props
    },
    ref
  ) => (
    <InputWrapper width={width}>
      {label ? (
        <Label error={error || ''} disabled={disabled}>
          {t(label)}
        </Label>
      ) : null}
      {ExternalItem && (
        <ExternalItemWrapper>{ExternalItem}</ExternalItemWrapper>
      )}
      <>
        <StyledTextarea
          ref={ref}
          error={error}
          unit={unit}
          icon={icon}
          bgColor={bgColor}
          disabled={disabled}
          resize={resize}
          {...props}
        />
        {unit && <Unit>{unit}</Unit>}
        {icon && <Icon>{icon}</Icon>}
      </>
      {error && <ErrorText>{error}</ErrorText>}
    </InputWrapper>
  )
);

TextareaField.displayName = 'TextareaField';
