import { DatePicker, Flex, Line } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { H2, H4, P } from 'components/Typography/Typography';
import {
  Button,
  FieldLabel,
  SwitchButton,
  TextField,
  TextareaField
} from 'components/_form';
import {
  MainSection,
  TitleContainer,
  IconButtonContainer
} from './FormAudit.styled';
import {
  faCirclePlay,
  faClipboard,
  faCopy,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faClockRotateLeft,
  faDownload,
  faFilePen,
  faPeopleGroup,
  faPersonRunning,
  faTrashCan,
  faEdit,
  faCalendar,
  faCalendarPlus,
  faCalendarDays
} from '@fortawesome/free-solid-svg-icons';
import { theme } from 'styles';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { Select } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import {
  NestedSelect,
  IOption as OptionForSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { useEvaluationContext } from 'contexts/EvaluationContext';

import { DescriptionModal } from 'components/_form/DescriptionModal/DescriptionModal';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { hourChecker, minuteChecker } from 'utilities/timePicker';
import { LabelPosition } from 'components/_form/TextField/TextField';
import { format } from 'date-fns';
import { getUsers } from 'api/user';
import { getTeams } from 'api/teams';
import { ITeam } from 'types/team';
import { FormDataControl } from '../CreateAuditModal';
import { fullName } from 'utilities/fullName';
import { AuditCriteriaModal } from './AuditCriteriaModal/AuditCriteriaModal';
import { getAuditForms } from 'api/audits';
import { makeAuditFormDataForSelect } from 'utilities/audit/makeAuditFormDataForSelect';
import { AuditParticipantsModal } from '../AuditParticipantsModal/AuditParticipantsModal';
import { IDetailedAudit } from 'types/audits';
import { AuditStatusModal } from '../AuditStatusModal/AuditStatusModal';
import { getUserFunctions } from 'api/userFunctions';
import { PadlockButton } from 'components/_form/PadlockButton/PadlockButton';
import { Loader } from 'components/Loader/Loader';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { NotesModal } from 'components/NotesModal/NotesModal';
import { IconButton } from 'views/EvaluationsView/components/IconButton/IconButton';
import { DeleteAuditModal } from '../DeleteAuditModal/DeleteAuditModal';

export enum FormType {
  CREATE = 'create',
  EDIT = 'edit'
}

interface Props {
  control: Control<FormDataControl, any>;
  register: UseFormRegister<FormDataControl>;
  canChangeAuditStatus?: boolean;
  errors: FieldErrors<FormDataControl>;
  watch: UseFormWatch<FormDataControl>;
  setValue: UseFormSetValue<FormDataControl>;
  submitAuditForm: (
    data: FormDataControl,
    doClose?: boolean
  ) => Promise<IDetailedAudit | undefined>;
  type?: FormType;
  selectedAudit?: IDetailedAudit;
  setSelectedAudit?: Dispatch<SetStateAction<IDetailedAudit | undefined>>;
  handleDeleteAudit?: () => void;
}

export interface ITeamOption extends IOption {
  members: ITeam['members'];
}

export const FormAudit: React.FC<Props> = ({
  control,
  register,
  canChangeAuditStatus = false,
  errors,
  watch,
  setValue,
  submitAuditForm,
  selectedAudit,
  setSelectedAudit,
  type = FormType.CREATE,
  handleDeleteAudit
}) => {
  const [loading, setLoading] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [auditFormsTreeForSelect, setAuditFormsTreeForSelect] = useState<
    OptionForSelect[]
  >([]);
  const [isAuditStatusModalOpen, setIsAuditStatusModalOpen] = useState(false);
  const [isCloseEvaluationStatusActive, setIsCloseEvaluationStatusActive] =
    useState(false);
  const [isCriteriaListModalOpen, setIsCriteriaListModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [areas, setAreas] = useState<OptionForSelect[]>([]);
  const [usersList, setUsersList] = useState<IOption[]>([]);
  const [teamsList, setTeamsList] = useState<ITeamOption[]>([]);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userFunctionsList, setUserFunctionsList] = useState<IOption[]>([]);

  const { t } = useTranslation();
  const ctx = useEvaluationContext();

  const evaluationTypes: IOption[] = [
    {
      label: t('evaluationsView.evaluationTypes.target') as string,
      value: 'target'
    },
    {
      label: t('evaluationsView.evaluationTypes.baseline') as string,
      value: 'baseline'
    },
    {
      label: t('evaluationsView.evaluationTypes.current') as string,
      value: 'current'
    },
    {
      label: t('evaluationsView.evaluationTypes.derivative') as string,
      value: 'derivative'
    },
    {
      label: t('evaluationsView.evaluationTypes.normal') as string,
      value: 'normal'
    }
  ];

  const handleGetAuditForms = useCallback(async () => {
    const { data } = await getAuditForms({});

    const dataParsed = makeAuditFormDataForSelect(data);
    setAuditFormsTreeForSelect(dataParsed);
  }, []);

  useEffect(() => {
    handleGetAuditForms();
  }, [handleGetAuditForms]);

  const handleGetAreas = useCallback(async () => {
    const areasData = ctx.areas;

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ctx.areas)]);

  const handleSelectedAreaIdChange = useCallback(() => {
    const selectedAreaIds = ctx.filters.area_ids.value;
    if (selectedAreaIds.length) {
      flattenOptions(areas).map((area) => {
        if (area.value && selectedAreaIds.includes(Number(area.value))) {
          setValue('area_id', area.value);
        }
      });
    }
  }, [ctx.filters.area_ids.value, areas]);

  const handleGetUsers = async () => {
    const { data } = await getUsers();

    setUsersList(
      data.map((user) => ({
        label: fullName(user.profile),
        value: String(user.id)
      }))
    );
  };

  const handleGetTeams = async () => {
    const teamsReponse = await getTeams();

    setTeamsList(
      teamsReponse.data.map((team) => ({
        value: team.id?.toString(),
        label: team.name,
        members: team.members
      }))
    );
  };

  const hadnleFunctionsOptions = useCallback(async () => {
    const { data } = await getUserFunctions();

    setUserFunctionsList(
      data.map((functionItem) => ({
        value: functionItem.id,
        label: functionItem.name
      }))
    );
  }, []);

  useEffect(() => {
    hadnleFunctionsOptions();
  }, [hadnleFunctionsOptions]);

  useEffect(() => {
    handleGetAreas();
  }, [handleGetAreas]);

  useEffect(() => {
    handleSelectedAreaIdChange();
  }, [handleSelectedAreaIdChange]);

  useEffect(() => {
    handleGetUsers();
    handleGetTeams();
  }, []);

  const onLaunchClick = useCallback(async () => {
    setLoading(true);
    const auditSubmitResponse = await submitAuditForm(
      // TODO: adjust type of control._formValues here
      control._formValues as FormDataControl,
      false
    );

    if (!auditSubmitResponse) return;

    if (auditSubmitResponse) {
      setSelectedAudit?.(auditSubmitResponse);
      ctx.setTabIndex(2);
      ctx.setSelectedAuditId(auditSubmitResponse.id);
      ctx.setIsCreateAuditModalOpen(false);
      ctx.setIsAuditCriteriaModalOpen(true);
      ctx.setIsEditAuditModalOpen(true);

      ctx.handleLoadAudits();
    }
    setLoading(false);
  }, [control._formValues, submitAuditForm]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <MainSection flexDirection={['column', 'row']}>
        <Flex flexDirection="column" width={['100%', '50%']} gap="8px">
          <Flex width="95%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="65%">
              <Controller
                control={control}
                name="audit_form_id"
                render={({ field: { value, onChange } }) => (
                  <NestedSelect
                    label={t('createTaskView.auditForm')}
                    selectedOptions={auditFormsTreeForSelect.filter(
                      (option) => option.value?.toString() === value?.toString()
                    )}
                    setSelectedOptions={(newOptions) => {
                      onChange(newOptions[0]?.value);
                    }}
                    options={auditFormsTreeForSelect}
                    isOneOptionSelect={true}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column" width="30%">
              <Controller
                control={control}
                name="version"
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    label={t('createTaskView.version')}
                    selectedOptions={value}
                    options={[{ value: '3.0-GW', label: '3.0-GW' }]}
                    withoutMargin
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="100%">
              <TextareaField
                label={`${t('createTaskView.auditName')} / ${t(
                  'createTaskView.activities'
                )}`}
                width="95%"
                {...register('name')}
              />
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="95%">
              <TextareaField
                {...register('description')}
                label={t('createTaskView.description') || ''}
                rows={5}
                error={t(errors?.description?.message || '')}
                disabled
                ExternalItem={
                  <Button
                    variant="secondary"
                    icon={
                      <FontAwesomeIcon color="grey" size="2x" icon={faEdit} />
                    }
                    onClick={() => {
                      setIsDescriptionModalOpen(
                        (prevIsDescriptionModalOpen) =>
                          !prevIsDescriptionModalOpen
                      );
                    }}
                  />
                }
              />
            </Flex>
          </Flex>
          <Flex
            width="95%"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            alignItems="end"
            gap="15px"
          >
            <TextField disabled label={t('createAuditView.proccess')} />
            <Flex>
              <FieldLabel disabled>
                <Flex gap="10px">
                  <input disabled type="radio" />
                  {t('createAuditView.combineProcessAuditPackage')}
                </Flex>
              </FieldLabel>
            </Flex>
          </Flex>

          <Flex
            width="95%"
            flexDirection="row"
            justifyContent="space-between"
            gap="4px"
            flexWrap="wrap"
          >
            <Flex flexDirection="column" width="30%">
              <FieldLabel htmlFor="priority">
                {t('createTaskView.evaluationType')}
              </FieldLabel>
              <Controller
                control={control}
                name="evaluation_type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    selectedOptions={(() => {
                      let selectedEvaluation: IOption = {
                        label: '',
                        value: ''
                      };

                      evaluationTypes.forEach((evaluationType) => {
                        if (evaluationType.value === value.value)
                          selectedEvaluation = evaluationType;
                      });

                      return selectedEvaluation;
                    })()}
                    options={evaluationTypes}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.evaluationScore')}:
              </TitleContainer>
              <TitleContainer
                variant="body"
                lineHeight="21px"
                textAlign="center"
              >
                {selectedAudit?.result || '0.00'}%
              </TitleContainer>
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.isFinished')}?
              </TitleContainer>
              <Flex flexDirection="row" justifyContent="center">
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { value, onChange } }) => (
                    <SwitchButton
                      value={value === 'finished'}
                      onChange={(newValue) => {
                        onChange(newValue ? 'finished' : '');
                      }}
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.isClosed')}?
              </TitleContainer>
              <PadlockButton
                locked={
                  !!selectedAudit?.audits_closings.find(
                    ({ closed_at }) => closed_at
                  )
                }
                onClick={() => {
                  if (canChangeAuditStatus === true) {
                    setIsAuditStatusModalOpen(true);
                  }
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" width={['100%', '50%']}>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="end"
            gap="10px"
          >
            <Flex flexDirection="column" width="100%">
              <FieldLabel htmlFor="area-select">
                {t('createTaskView.areas')}
              </FieldLabel>

              <Controller
                control={control}
                name="area_id"
                render={({ field: { onChange, value } }) => (
                  <NestedSelect
                    selectedOptions={flattenOptions(areas).filter(
                      (option) => value === option?.value
                    )}
                    setSelectedOptions={(newOptions) => {
                      onChange(newOptions[0]?.value);
                    }}
                    options={areas}
                    isOneOptionSelect
                  />
                )}
              />
            </Flex>
            <Button variant="grey">
              <FontAwesomeIcon icon={faCopy} />
              <P variant="body">{t('createTaskView.copy')}</P>
            </Button>
          </Flex>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="10px"
          >
            <Flex flexDirection="column" width="100%">
              <Flex width="95%">
                <Controller
                  control={control}
                  name="leader_id"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('createAuditView.auditLeaderAuditor')}
                      selectedOptions={usersList.find(
                        (userOption) => userOption.value === String(value)
                      )}
                      onChange={(val: IOption) => {
                        onChange(val.value);
                      }}
                      options={usersList}
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" width="100%">
              <TextField
                label={t('createTaskView.createdBy')}
                value={
                  usersList
                    .find(({ value }) => value === watch('author_id'))
                    ?.label?.toString() || ''
                }
                disabled
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Controller
              control={control}
              name="team_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  isMulti
                  label={t('createTaskView.auditTeam')}
                  selectedOptions={teamsList.filter((teamItem) =>
                    value?.includes(teamItem.value)
                  )}
                  onChange={(options: IOption[]) => {
                    onChange(options.length ? [options[0].value] : []);
                  }}
                  options={teamsList}
                />
              )}
            />
          </Flex>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="10px"
          >
            <Flex flexDirection="column" width="100%">
              <TitleContainer variant="body">
                {t('createTaskView.participants')}
              </TitleContainer>
              <Flex flexDirection="row" justifyContent="space-between">
                <TextareaField
                  disabled
                  width="80%"
                  rows={2}
                  value={watch('audit_participations_attributes')?.map(
                    (auditParticipationAttribute) =>
                      `${auditParticipationAttribute.label} \n`
                  )}
                />
                <IconButtonContainer
                  flexDirection="column"
                  width="100px"
                  height="55px"
                  onClick={() => {
                    setIsParticipantsModalOpen(
                      (prevIsParticipantsModalOpen) =>
                        !prevIsParticipantsModalOpen
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPeopleGroup} size="xl" />
                  <H2 variant="h3">{t('createTaskView.manage')}</H2>
                </IconButtonContainer>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            width="90%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="22px"
            flexWrap="wrap"
            gap="5px"
          >
            <Button
              variant="eucalyptus"
              onClick={() => {
                onLaunchClick();
              }}
              disabled={watch('state') === 'finished'}
            >
              <Flex
                flexDirection="row"
                width="120px"
                height="45px"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <FontAwesomeIcon icon={faCirclePlay} size="xl" />
                <H2 variant="h3">{t('createTaskView.launch')}</H2>
              </Flex>
            </Button>
            <Flex justifyContent="center" gap="4px" flexWrap="wrap">
              <IconButton>
                <FontAwesomeIcon icon={faBars} size="2x" />
              </IconButton>
              <IconButton onClick={() => setIsCriteriaListModalOpen(true)}>
                <FontAwesomeIcon icon={faFilePen} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faDownload} size="2x" />
              </IconButton>
              <IconButton
                disabled={!selectedAudit}
                onClick={setIsNotesModalOpen}
              >
                <FontAwesomeIcon icon={faClipboard} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faPaperPlane} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faClockRotateLeft} size="2x" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="2x" />
              </IconButton>
            </Flex>
          </Flex>
          <Flex
            width="90%"
            flexDirection="row"
            justifyContent="center"
            marginTop="10px"
          >
            <Flex flexDirection="column">
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.show')}:
              </TitleContainer>
              <IconButtonContainer
                flexDirection="row"
                width="100px"
                height="30px"
                bgColor={theme.palette.primary.main}
              >
                <FontAwesomeIcon icon={faPersonRunning} />
                <H2 variant="h3">{t('createTaskView.tasks')}</H2>
              </IconButtonContainer>
            </Flex>
          </Flex>
        </Flex>
      </MainSection>

      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <H4 variant="h4" color="coloured">
            {t('tasks.planning')}
          </H4>
          <Line />

          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="planned_start_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.plannedStartDate') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>

            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="planned_completion_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.timeLimitForCompletion') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column" width={['100%', '30%']} mt="4px">
              <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
              <Flex mr={1} width="100%" alignItems="end">
                <Flex mr={1} width="50%">
                  <Controller
                    control={control}
                    name="planned_duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.hrs."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('T') + 1,
                            value.indexOf('H')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!hourChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('T') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('H'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex width="50%">
                  <Controller
                    control={control}
                    name="planned_duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.min."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('H') + 1,
                            value.indexOf('M')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('H') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('M'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="30%" mt={3} justifyContent="center">
              <Flex flexDirection="column" gap="5px">
                <Button transparent bordered minHeight="30px">
                  <Flex gap="10px" alignItems="center">
                    <FontAwesomeIcon icon={faCalendarPlus} />
                    <Flex mx="auto">{t('createAuditView.planSeries')}</Flex>
                  </Flex>
                </Button>
                <Button transparent bordered minHeight="30px">
                  <Flex gap="10px" alignItems="center">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <Flex mx="auto">
                      {t('createAuditView.sendInvitations')}
                    </Flex>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column">
          <H4 variant="h4" color="green">
            {t('tasks.implementation')}
          </H4>
          <Line variant="tertiary" />

          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="start_time"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.plannedStartDate') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>

            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="completion_time"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.timeLimitForCompletion') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column" width={['100%', '30%']} mt="6px">
              <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
              <Flex mr={1} width="100%" alignItems="end">
                <Flex mr={1} width="50%">
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.hrs."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('T') + 1,
                            value.indexOf('H')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('T') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('H'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex width="50%">
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.min."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('H') + 1,
                            value.indexOf('M')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('H') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('M'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="30%">
              <RealizationProgress
                label={t('createTaskView.status')}
                showStatusInfo
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {setSelectedAudit && (
        <AuditCriteriaModal
          isOpen={ctx.isAuditCriteriaModalOpen}
          onCancelClick={() => {
            ctx.setIsAuditCriteriaModalOpen(false);
          }}
          audit={selectedAudit}
          setAudit={setSelectedAudit}
          onFinishAuditSuccess={(newAudit) => {
            setSelectedAudit(newAudit);
            ctx.setIsAuditCriteriaModalOpen(false);

            ctx.handleLoadAudits();
          }}
        />
      )}

      <DescriptionModal
        value={watch('description')}
        setValue={(value) => {
          setValue('description', value || '');
        }}
        isOpen={isDescriptionModalOpen}
        onCancelClick={() => {
          setIsDescriptionModalOpen(
            (prevIsDescriptionModalOpen) => !prevIsDescriptionModalOpen
          );
        }}
      />

      <AuditParticipantsModal
        isOpen={isParticipantsModalOpen}
        control={control}
        watch={watch}
        setValue={setValue}
        usersList={usersList}
        teamsList={teamsList}
        onCancelClick={() => {
          setIsParticipantsModalOpen(
            (prevIsParticipantsModalOpen) => !prevIsParticipantsModalOpen
          );
        }}
        saveAndClose={() => {
          setIsParticipantsModalOpen(
            (prevIsParticipantsModalOpen) => !prevIsParticipantsModalOpen
          );
        }}
      />

      {isAuditStatusModalOpen && (
        <AuditStatusModal
          watch={watch}
          setValue={setValue}
          isOpen={isAuditStatusModalOpen}
          onCancelClick={() => setIsAuditStatusModalOpen(false)}
          usersList={usersList}
          userFunctionsList={userFunctionsList}
          selectedAudit={selectedAudit}
          setSelectedAudit={setSelectedAudit}
        />
      )}

      <NotesModal
        isOpen={isNotesModalOpen}
        onCancelClick={() => {
          setIsNotesModalOpen((prevIsNotesModalOpen) => !prevIsNotesModalOpen);
        }}
        noteableId={selectedAudit?.id}
        noteableType="Audit"
        notes={selectedAudit?.notes}
        setNotes={(newNotes) =>
          setSelectedAudit?.(
            (prevSelectedItemData) =>
              prevSelectedItemData && {
                ...prevSelectedItemData,
                notes: newNotes
              }
          )
        }
      />

      {handleDeleteAudit && (
        <DeleteAuditModal
          onSubmit={handleDeleteAudit}
          isOpen={isDeleteModalOpen}
          onCancelClick={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
};
