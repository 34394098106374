import { Card, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';

export const Wrapper = styled(Card)`
  overflow: auto;
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 8px;
  padding: 14px;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;

export const ChosenItemsHeader = styled(Flex)`
  justify-content: space-between;
  padding-top: 8px;
  background: ${({ theme }) => theme.palette.neutral.veryLight};
`;

export const DetailedHeaderWrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
`;

export const StyledP = styled(P)`
  font-size: 13px;
`;
