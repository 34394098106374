import { Flex } from 'components';
import { TableCell, TableHeader } from './RatingScalesList.styled';
import { useTranslation } from 'react-i18next';
import { getRatingScales } from 'api/ratingScales';
import { useEffect, useState } from 'react';
import { IRatingScale } from 'types/ratingScales';
import { ActiveDot } from 'components/ActiveDot/ActiveDot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/_form';
import {
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { RatingScaleButtons } from 'components/RatingScaleButtons/RatingScaleButtons';
import { AddRatingScaleForm } from '../RatingScaleForm/AddRatingScaleForm';
import { EditRatingScaleForm } from '../RatingScaleForm/EditRatingScaleForm';

export const RatingScalesList = () => {
  const [isAddFormOpen, setAddIsFormOpen] = useState(false);
  const [editingRatingScaleId, setEditingRatingScaleId] = useState<number>();
  const [ratingScales, setRatingScales] = useState<IRatingScale[]>([]);

  const { t } = useTranslation();

  const handleGetRatingScales = async () => {
    const ratingScales = await getRatingScales({});

    if ('error' in ratingScales) {
      return;
    }

    setRatingScales(ratingScales.data);
  };

  useEffect(() => {
    handleGetRatingScales();
  }, []);

  return (
    <Flex flexDirection="column" gap="5px">
      <Flex justifyContent="end" mb={2}>
        <Button
          variant="eucalyptus"
          onClick={() => {
            setAddIsFormOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faCirclePlus} /> {t('common.add')}
        </Button>
      </Flex>
      <Flex overflow="auto" width="100%">
        <Flex flexDirection="column" gap="5px" minWidth="1200px" width="100%">
          <Flex>
            <TableHeader width="8%">
              {t('administrationAuditsView.ratingScaleTableHeaders.active')}
            </TableHeader>
            <TableHeader width="25%">
              {t('administrationAuditsView.ratingScaleTableHeaders.scaleName')}
            </TableHeader>
            <TableHeader width="7%">
              {t('administrationAuditsView.ratingScaleTableHeaders.levels')}
            </TableHeader>
            <TableHeader width="25%">
              {t('administrationAuditsView.ratingScaleTableHeaders.preview')}
            </TableHeader>
            <TableHeader width="25%">
              {t(
                'administrationAuditsView.ratingScaleTableHeaders.usedInForms'
              )}
            </TableHeader>
            <TableHeader width="10%"></TableHeader>
          </Flex>

          {ratingScales.map((ratingScale) => (
            <Flex key={`ratingScalesList-${ratingScale.id}`}>
              <TableCell width="8%" justifyContent="center">
                <ActiveDot active={ratingScale.active} />
              </TableCell>
              <TableCell width="25%">{ratingScale.name}</TableCell>
              <TableCell width="7%" justifyContent="center">
                {ratingScale.buttons?.length}
              </TableCell>
              <TableCell width="25%" overflow="hidden">
                <RatingScaleButtons
                  buttons={ratingScale.buttons}
                  showImages={ratingScale.button_type === 'picture'}
                  bordered={ratingScale.border}
                  borderRadius
                />
              </TableCell>
              <TableCell width="25%">
                {ratingScale.active_audit_forms_names?.join(', ')}
              </TableCell>
              <TableCell width="10%" gap="5px">
                <Button
                  variant="eucalyptus"
                  icon={<FontAwesomeIcon icon={faEdit} size="xl" />}
                  onClick={() => {
                    setEditingRatingScaleId(ratingScale.id);
                  }}
                />
                <Flex flexDirection="column">
                  <Button
                    bordered
                    transparent
                    icon={<FontAwesomeIcon icon={faChevronUp} size="xl" />}
                    minHeight="17px"
                  />
                  <Button
                    bordered
                    transparent
                    icon={<FontAwesomeIcon icon={faChevronDown} size="xl" />}
                    minHeight="17px"
                  />
                </Flex>
              </TableCell>
            </Flex>
          ))}
        </Flex>
      </Flex>

      <AddRatingScaleForm
        isOpen={isAddFormOpen}
        onCancelClick={() => {
          setAddIsFormOpen(false);
        }}
        onSubmitSuccess={() => {
          handleGetRatingScales();
          setAddIsFormOpen(false);
        }}
      />

      <EditRatingScaleForm
        isOpen={!!editingRatingScaleId}
        editingRatingScaleId={editingRatingScaleId}
        onCancelClick={() => {
          setEditingRatingScaleId(undefined);
        }}
        onSubmitSuccess={() => {
          handleGetRatingScales();
          setEditingRatingScaleId(undefined);
        }}
        onDeleteSuccess={() => {
          handleGetRatingScales();
          setEditingRatingScaleId(undefined);
        }}
      />
    </Flex>
  );
};
