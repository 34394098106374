import qs from 'qs';
import request from './index';
import {
  IGetPracticesFilters,
  IMethodologyResponse,
  IPracticeResponse,
  IPracticesResponse
} from 'types/methologies';

export const getMethodologies = () =>
  request<IMethodologyResponse>({
    options: {
      url: '/methodologies',
      method: 'get'
    }
  });

export const getPractices = (filters?: IGetPracticesFilters) => {
  const query = qs.stringify(filters, { arrayFormat: 'brackets' });

  return request<IPracticesResponse>({
    options: {
      url: `/practices?${query}`,
      method: 'get'
    }
  });
};

export const getPractice = (id: string) =>
  request<IPracticeResponse>({
    options: {
      url: `/practices/${id}`,
      method: 'get'
    }
  });
