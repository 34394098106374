import { Flex } from 'components';
import { H2, P } from 'components/Typography/Typography';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { Cell, ValueBar } from './EvaluationTopics.styled';
import { typeColorsDict } from 'styles/theme';

export const EvaluationTopics = () => {
  const { selectedEvaluations } = useEvaluationContext();

  return selectedEvaluations.length ? (
    <Flex overflow="auto">
      <Flex flexDirection="column" mt={5} pb={2}>
        {selectedEvaluations[0].practice?.topics.map((topic, i) => (
          <Flex key={topic.id}>
            <Flex width="200px" height="40px" alignItems="center">
              <P variant="h4">{topic.name}</P>
            </Flex>
            {selectedEvaluations[0].practice?.stages.map((stage, j) => {
              return (
                <Cell
                  key={stage.id}
                  isBorderBottom={
                    i === selectedEvaluations[0].practice?.topics.length - 1
                  }
                  isBorderRight={
                    j === selectedEvaluations[0].practice?.stages.length - 1
                  }
                >
                  {selectedEvaluations.map((evaluation, k) => (
                    <ValueBar
                      key={k + topic.id}
                      value={
                        selectedEvaluations[k].practice?.stages[j]?.topics[
                          topic.id - 1
                        ]?.score * 100 ?? 0
                      }
                      bgColor={
                        typeColorsDict[selectedEvaluations[k].evaluation_type]
                      }
                    />
                  ))}
                </Cell>
              );
            })}
          </Flex>
        ))}

        <Flex gap="10px">
          <Flex
            alignItems="center"
            justifyContent="center"
            width="200px"
          ></Flex>
          {selectedEvaluations[0].practice?.stages
            ?.sort((a, b) => a.number - b.number)
            .map((stage) => (
              <Flex key={stage.id} gap="6px">
                <Flex width="150px" alignItems="center" justifyContent="end">
                  <Flex flexDirection="column" alignItems="center">
                    <H2 variant="h2">{stage.number}</H2>
                  </Flex>
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};
