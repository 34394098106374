const common = {
  privacyPolicy: 'Privacy policy',
  regulamin: 'Terms & conditions',
  email: 'email address',
  first_name: 'name',
  last_name: 'surname',
  username: 'Username',
  anErrorOccurred: 'An error occurred',
  addedEvaluation: 'Assessment added',
  cookiesMessage:
    'This site uses functional cookies to ensure that you get the best experience on our Platform.\nBy continuing to use this site you are accepting the use of these cookies.',
  other: 'Other',
  save: 'Save',
  language: 'Language',
  yes: 'yes',
  close: 'Close',
  no: 'no',
  loading: 'Logging you in...',
  'leave blank if all company': 'leave blank if all company',
  uncheck: 'Uncheck',
  cancel: 'Cancel',
  message: 'message',
  success: 'The operation succeeded',
  justificationRequired: 'Justification required',
  showAll: 'Show all',
  add: 'Add',
  showAlsoMedia: 'Also show media files',
  addImage: 'Add photo',
  'document/file': 'Document / file',
  show: 'Show',
  copy: 'Copy',
  today: 'Today',
  fieldRequired: 'This field is required',
  notes: 'Notes',
  makeAuditsToUnlock: 'Perform audits to unlock',
  installApp: 'Install app',
  pressShareButton: 'Press the Share button',
  pressAddHomeScreen: 'Press Add to Home Screen'
};

const nav = {
  'my-desktop': 'My desktop',
  tasks: 'Tasks',
  evaluation: 'Assessment',
  analytics: 'Analytics',
  documents: 'Documents',
  administration: 'Admin',
  profile: 'Profile',
  logout: 'Logout'
};

const auth = {
  login: 'login',
  register: 'register',
  password: 'password',
  newPassword: 'new password',
  passwordConfirm: 'confirm password',
  newPasswordConfirm: 'confirm new password',
  forgotPass: 'Forgot password?',
  accountless: "Don't have an account?",
  emailRequired: 'E-mail is required',
  badEmailFormat: 'Invalid e-mail format',
  passwordRequired: 'Password is required',
  passMinVal: 'The password needs to be at least 6 characters long',
  passMaxVal: 'The password can be up to 18 characters long',
  passDiffErr: 'The passwords do not match',
  acceptRegulamin: 'Accept the terms and conditions',
  password_confirmation: 'password confirmation',
  send_email: 'Send an e-mail',
  verify_your_email: 'Verify your e-mail',
  passwordChanged: 'Password has been changed',
  changePassword: 'Change password',
  saveNewPassword: 'Save new password',
  reset: 'Reset',
  writeRegisteredEmail:
    'Please enter your e-mail address registered in the system',
  backToLoginPage: 'Back to the login screen',
  succeedSendResetEmail:
    'You will receive a password reset link to this address, if it is registered in the system'
};

const profile = {
  myProfile: 'My profile',
  profile: 'Profile',
  basicInformation: 'Basic information',
  changeEmailAddress: 'Change e-mail address',
  changeUsername: 'Change username',
  areaPermissions: 'Area permissions',
  mailNotifications: 'E-mail notifications',
  idCode: 'ID Code',
  userCompany: "User's company",
  name: 'Name',
  secondname: 'Middle name',
  surname: 'Surname',
  phone: 'Mobile phone',
  landlinePhone: 'Landline phone',
  mainArea: 'Main area',
  myRoleInCompany: 'My roles in the company',
  employeeRoles: 'Employee functions',
  userAreaPermissions: 'The user has the right to edit in the following areas:',
  userHasRiparianAreasViewPermission:
    'The user has the right to view parent areas',
  userHasNeighboringAreasViewPermission:
    'The user has the right to view peer areas',
  overallSettings: 'General settings',
  sendMailAfterSystemNotifications:
    'Send e-mail notifications about receiving notifications in the system',
  sendMailStraightAfter:
    'send an e-mail immediately after receiving a notification',
  groupNotificationsAndSendEvery:
    'group notifications and send an e-mail every (minutes)',
  sendTasksStatusSummary: 'Send status summaries of my tasks',
  never: 'never',
  everyday: 'every day',
  by: 'at',
  everyWeek: 'every week',
  on: 'on',
  everyMonth: 'every month',
  'ds.': 'on',
  notificationsAboutplannedTasks: 'Scheduled task reminders',
  sendMailFutureNotifications:
    'Send e-mail notifications about upcoming and scheduled tasks',
  beforeStart: 'Before start',
  minutes: 'minute(s)',
  hours: 'hour(s)',
  days: 'days',
  before: 'earlier',
  beforeFinish: 'Before finish',
  notifyAboutPricesAndAuditsInMyAreas:
    'Notify me (as the area owner) about assessments and audits in my area',
  mailNotificationsAboutPDCATasksStatus:
    'E-mail notifications about changes in the PDCA / DMAIC status of tasks',
  sendMailNotificationsWhenPDCATasksStatusChangedWhichIAm:
    'Send e-mail notifications when someone changes the PDCA / DMAIC status of a task, where I am:',
  byTheAuthor: 'An author',
  responsibleBy: 'A responsible',
  participantBy: 'A participant',
  StatusP: 'P status',
  StatusD: 'D status',
  StatusC: 'C status',
  StatusA: 'A status',
  displayPreferences: 'Display preferences',
  dateFormat: 'Date format',
  separators: 'Separators',
  decimal: 'decimal',
  thousands: 'thousand',
  firstWeekDay: 'First day of the week',
  default: 'Default',
  imageChange: 'Change photo',
  imageRemove: 'Remove photo',
  addImage: 'Add photo',
  deleteUser: 'Delete the user',
  recoverDefault: 'Reset and restore defaults',
  'Password change error': 'Password change error',
  'Your password cannot be:': 'Your password cannot be:',
  'such as your username or similar': 'such as or similar to your username',
  'such as your first and/or last name': 'such as your name and/or surname',
  'company name or a variation thereof': 'company name or a variation thereof',
  'the names of your children, family, dogs and cats':
    'the names of your children, family, dogs and cats',
  'a string of consecutive letters and/or keyboard characters, e.g. qwertyuiop, abcd1234, 12345678, 09876543...':
    'a sequence of letters and/or characters on the keyboard, e.g. qwertyuiop, abcd1234, 12345678, 09876543...',
  'be on the list of commonly known passwords':
    'be on the list of commonly known passwords',
  'Instead, we encourage you to use phrases that are easy to remember, e.g.':
    'We encourage you to use phrases that are easy to remember, e.g.',
  "My pink dog is in love with my neighbor's green parrot":
    "My pink dog is in love with my neighbour's green parrot"
};

const desktop = {
  my: 'Mine',
  solvingProblems: 'Problem solving',
  audits: 'Audits'
};

const tasks = {
  savedFilters: 'Saved filters',
  defaultFilter: 'Default filter',
  defaultFilterNotExists: 'No default filters',
  savedFiltersNotExists: 'No saved filters',
  setAsDefault: 'Set as default',
  delete: 'Delete',
  addTask: 'New task',
  editTask: 'Edit task',
  exportToExcel: 'Export to Excel',
  tasksCount: 'Number of tasks',
  criteriaConnectedTasksList: 'List of tasks related to the criterion',
  realizationTimes: 'execution times',
  chart: 'Chart',
  calendar: 'Calendar',
  chosenLines: 'selected rows',
  toArchive: 'Move to archive',
  exportToCalendar: 'Export to calendar',
  remindLatecomers: 'Remind latecomers',
  sendNotificationsToSelectedOnes: 'Send notifications to selected ones',
  'taskCancelationConfirm?': 'Are you sure you want to cancel this task?',
  'archiveTaskConfirmation?': 'Archive selected tasks?',
  //filters
  area: 'area',
  withAnAsterisk: 'Star',
  statusPDCA: 'PDCA status',
  PDCANew: 'New',
  responsible: 'Responsible',
  author: 'Author',
  teamsAndGroups: 'Teams and groups',
  taskPriorities: 'Task priorities',
  typesOfTasks: 'Task types',
  idCodeName: 'ID code, name',
  created: 'Creation date',
  startDate: 'Start date',
  timeLimitForCompletion: 'Deadline',
  implementation: 'Execution',
  hidden: 'Hidden',
  plan: 'Plan',
  starMy: 'For me',
  starMyTeams: 'For my team(s)',
  starAsterisk: 'For my area(s)',
  starWithout: 'No star',
  activeFilter: 'Active',
  archivedFilter: 'Archived',
  canceledFilter: 'Cancelled',
  additionalPermissions: 'Additional permissions',
  'Can perform some administrative tasks':
    'Can perform selected administrative tasks',
  'Disabling and enabling user account activity':
    'Disabling and enabling user accounts',
  deletingTasksInSubordinatesAreas: 'Deleting tasks in their areas',
  deletingTasksInAllAreas: 'Deleting tasks in all areas',
  'Access to the translation table': 'Can access the translations table',
  'Translation table preview': 'Viewing the translation table',
  languagesEditing: 'Editing languages',
  // actions
  filtersSaved: 'Filter settings saved',
  // table
  'task.table.star': '★ Star',
  'task.table.practice': 'Practice',
  'task.table.name': 'Name',
  'task.table.taskType': 'Task type',
  'task.table.responsible': 'Responsible',
  'task.table.area': 'Area',
  'task.table.status': 'Status',
  'task.table.deadlineRealisation': 'Deadline',
  'task.table.timeRealisation': 'Execution time',
  'task.table.daysCount': 'No. of days',
  // table tooltips
  'task.table.tooltips.responsible': "Click to see this person's tasks",
  //form
  taskName: 'Task name',
  Priority: 'Priority',
  TaskType: 'Task type',
  participants: 'Participants',
  'Teams, shifts, groups': 'Teams & groups',
  carryOut: 'Go do it',
  planning: 'Planning',
  plannedStartDate: 'Planned start date',
  plannedTime: 'Planned time',
  'realizationOnly (D)': 'Only execution (D)',
  taskDescription: 'Task description',
  taskParticipants: 'Task participants',
  teamLeader: 'Team leader',
  addTeamMember: 'Add a team member',
  teamManager: 'Team Manager',
  addFromTeamOrGroup: 'Add from a team or group',
  teamMembers: 'Team members',
  'role/function': 'Role / function',
  starForTeamInstruction:
    'Turn on the star for everyone on the team assigned to this task. Newly added team members will also have it turned on.',
  'turn on the star?': 'Mark with a star?',
  forMe: 'For me',
  forTeam: 'For the team',
  forArea: 'For area',
  'Area(s)': 'Area(s)',
  'justification for the star': 'Justification for the star',
  'add another file/document': 'Add another file / document',
  photos: 'Photos',
  TaskPhotosAndDocuments: 'Task photos and documents',
  documents: 'Documents',
  notebook: 'Notes',
  commentTitle: 'Comments',
  'removeTask?': 'Do you want to delete this task?',
  notifyParticipants: 'send notifications to participants',
  taskNameRequired: 'Task name is required',
  taskDescriptionRequired: 'Task description is required',
  'hrs.': 'hrs.',
  'min.': 'min.',
  active: 'Active',
  archived: 'Archived',
  canceled: 'Canceled'
};

export const administration = {
  administrationLabel: 'Admin',
  company: 'Company',
  companySettingsDescription: 'Manage company data, licenses, permissions',
  employeesAndUsers: 'Employees and users',
  employeesAndUsersSettingsDescription:
    'Manage users, their roles, permissions and access, etc.',
  areasAndOrganizationStructure: 'Areas and structure of the organization',
  areasAndOrganizationStructureSettingsDescription:
    'Manage areas and the structure of the organization',
  tasks: 'Tasks',
  tasksSettingsDescription:
    'Manage tasks, their types, colouring, highlighting',
  audits: 'Audits',
  auditsSettingsDescription: 'Create and manage audit forms in the system',
  translations: 'Translations',
  translationsSettingsDescription:
    'Add and change languages, edit translations, access for translators',
  otherSettingsListsDictionaries: 'Other settings, lists, dictionaries',
  companyData: 'Company data',
  licencesAndPermission: 'Licenses and access',
  usersPermission: 'User access',
  defaultSettings: 'Default settings',
  storage: 'Storage',
  contactAndHelp: 'Contact and support',
  fullCompanyName: 'Full company name',
  shortCompanyName: 'Short company name',
  'unit/workplace': 'Business unit / site',
  streetAndNumber: 'Street and number',
  postCode: 'Postal code',
  city: 'City',
  country: 'Country',
  NIPNumber: 'Tax ID number',
  KRSNumber: 'Registration number',
  website: 'Company website',
  officeContactData: 'OFFICE CONTACT DETAILS',
  emailAddress: 'E-mail address',
  contactPeopleData: "CONTACT PERSONS' DATA",
  fullname: 'Name and surname',
  addPerson: 'Add a person',
  sendLogo: 'Upload company logo',
  materialsLicensesAndPermission:
    'LICENSES, ACCESS TO MATERIALS AND METHODOLOGIES',
  materialsPermissionSettings: 'Access to materials settings:',
  global: 'global',
  byStructure: 'by structure',
  total: 'total',
  admin: 'admin',
  'usr.': 'user',
  guest: 'guest',
  usersLicensesCount: 'Number of licenses (users)',
  usedLicensesCount: 'Number of used licenses',
  licenseExpirationDate: 'License expiration date',
  extendOrChange: 'Extend or change',
  availableBlocks: 'Available stages',
  customizeBlocks: 'Customize blocks',
  availableVersions: 'Available versions',
  selectAll: 'Select all:',
  toEveryone: 'Apply to all:',
  materialsLanguages: 'LANGUAGES OF METHODOLOGICAL MATERIALS',
  license: 'LICENSE',
  update: 'Update',
  leadershipDevelopmentAndChangeManagement:
    'Leadership Development & Change Management',
  motivationAndProvidingOpportunity: 'Motivation & Enablement',
  'Processes,Structures,RolesandResponsibilities':
    'Processes, Structures, Roles and Responsibilities',
  effectiveTeams: 'Effective Teams',
  '5S': '5S',
  continuousImprovement: 'Continuous Improvement',
  safetyAndEnvironment: 'Safety & Environment',
  manufacturingProcessManagement: 'Production Process Management',
  maintenanceManagement: 'Maintenance Management',
  supplyChainManagement: 'Supply Chain Management',
  rangeOfAccessToDiffrentUserAccounts:
    'SCOPE OF ACCESS FOR DIFFERENT USER ACCOUNT TYPES',
  'Bydefault,eachtypeofuserhasaccesstotheareatowhichheisassignedandtoitssub-areas':
    'By default, each user account type has access to the area, to which they are assigned and to its sub-areas, e.g.:',
  addedattheGrouplevelhasaccesstoallfactoriesinthestructure:
    'added at the Group level has access to all sites / plants in the whole structure,',
  addedatthefactorylevelhasaccesstoallareasofthefactory:
    'added at the site / plant level has access to all areas of this site / plant,',
  'addedatthedepartmentlevelhasaccesstoitsdepartmentanditssub-areas':
    'added at the department level has access to their department and all its sub-areas.',
  Usingthecheckboxesbelowyoucanadjustthisscopeofaccess:
    'This scope of access can be customised using the checkboxes below.',
  lawOfView: 'RIGHT TO VIEW',
  lawOfEdit: 'RIGHT TO EDIT',
  otherBookmarksInGroup: 'other plants in the Group',
  holeCompanyAreasTree: 'whole company area tree',
  mineAndEqualAreas: 'own and peer areas',
  mineAndLevelsBelowAreas: 'own area & its sub-areas',
  user: 'user',
  consultant: 'consultant',
  'creating assessments, audits': 'creating assessments, audits',
  'adding data (comments, photos) to existing ratings, audits':
    'adding data (comments, photos) to existing assessments, audits',
  diagrams: 'charts',
  'plans, reports': 'plans, reports',
  notifications: 'notifications',
  'access to documents, templates, examples':
    'access to documents, templates, examples',
  'adding your own documents, templates, examples':
    'adding own documents, templates, examples',
  'changes in the structure of the company':
    'changes in the company area structure',
  'access to the administration panel': 'access to the admin panel',
  'adding, blocking, unblocking users': 'adding, blocking, unblocking users',
  'making changes to the settings': 'making changes to the settings',
  'The editing right allows you to create assessments, audits, charts, plans, reports and available areas':
    'The right to edit allows one to create assessments, audits, charts, plans, reports for accessible areas.',
  restoreDefault: 'Restore defaults',
  legend: 'Legend',
  lackOfAccess: 'no access',
  preview: 'preview',
  downloadOption: 'right to download',
  selectedItems: 'selected items',
  'creating, editing': 'creating, editing',
  'FUNCTIONAL USER RIGHTS': "FUNCTIONAL USERS' RIGHTS",
  'Uses users middle name': "Use users' middle name",
  'USER CODES AND MAIL DOMAINS': 'USER CODES AND E-MAIL DOMAINS',
  'Data for own users': 'Data for own employees',
  'Email domain': 'E-mail domain',
  'user code': 'User code',
  'next number': 'sequence number',
  'DATE FOR PARTNERS AND SUBCONTRACTORS':
    'DATA FOR PARTNER COMPANIES AND (SUB)CONTRACTORS',
  companyName: 'Company name',
  'Feature / Service': 'Function / service provided',
  LOCATION: 'LOCALISATION',
  'The localization parameters set here override the default localization settings assigned to the main application language.':
    'The localisation parameters set here override the default localization settings derived from the primary application language.',
  LocaleID: 'Locale ID',
  currencySymbol: 'Currency symbol',
  'First day of the week': 'First day of the week',
  'Measuring units': 'Measurement units',
  'Calendar of holidays and days off': 'Holidays and days off calendar',
  color: 'colour',
  'area(s)': 'Area(s)',
  'Import holidays to the calendar': 'Import holidays to the calendar',
  add: 'Add',
  'Default language for new users': 'Default language for new users',
  mainAppLanguage: 'primary application language',
  'Interface languages available': 'Available interface languages',
  'Default session duration (minutes)': 'Default session duration (minutes)',
  'Only use corporate email addresses': 'Only use company e-mail addresses',
  'Along with the subscription, disk space for data research and the full amount of space for storing files - photos, documents, attachments, etc. are provided. You can provide any large storage for files on your servers or in the cloud service, providing access data below.':
    'The subscription includes disk space for databases and a certain amount of space for storing files – photos, documents, attachments, etc. You can ensure any large file storage on your own servers or in a cloud service by providing access data below.',
  'Storage for storing files - photos, documents, switches, etc.:':
    'Storage for storing files – photos, documents, attachments, etc.:',
  'Platform providers': "Platform provider's",
  'own or cloud': 'own or cloud',
  'Disk usage': 'Disk usage',
  'Access data:': 'Access data:',
  login: 'login',
  'Check the connection': 'Check connection',
  'Migrate files': 'Migrate files',
  'ACCOUNT MANAGER': 'ACCOUNT MANAGER',
  'PLATFORM ADMINISTRATOR / TECHNICAL CONTACT':
    'PLATFORM ADMINISTRATOR / TECHNICAL CONTACT',
  CONSULTANT: 'CONSULTANT',
  newPerson: 'Add a person',

  'STRUCTURE OF AREAS': 'AREA STRUCTURE',
  submissive: 'Sub-area',
  'Add a parent area': 'Add a parent area',
  areaCode: 'Area Code',
  areaOwner: 'Area owner',
  'active?': 'Active?',
  deleteArea: 'Delete area',
  name: 'Name',
  description: 'Description',
  'You add a subarea to:': 'You are adding a sub-area to: ',
  'Add a subarea': 'Add a sub-area',

  OnlyUseCorporateEmailAddressesTooltip:
    "Prevents users from providing e-mail addresses outside of the company's domain (e.g. @gmail.com, @yahoo.com, ...) as addresses for communication – this means that e-mails, e.g. with notifications and links to activities on the Platform will only be sent to the company e-mail domains listed below.",

  stage: 'Stage',
  topics: 'Themes',
  noCompanyData: 'Missing company data',
  check: 'Verify',
  role: 'Role'
};

export const employeesAndUsersView = {
  usersList: 'Users list',
  rolesAndFunctions: 'Roles and functions',
  teamsAndGroups: 'Teams and groups',
  importUsersList: 'Import users list',
  shortInstruction:
    'Short instruction – save the template, fill it in, import the file',
  errorsRaport: 'Error report',
  addUser: 'Add a user',
  addTeam: 'Add a team',
  add: 'Add',
  editRole: 'Edit role / function',
  messageForTeam: 'Message the team',
  team: 'Team',
  leader: 'Leader',
  specialPermissions: 'Special permissions',
  tableHeaders: {
    active: 'Active?',
    id: 'ID',
    photo: 'Photo',
    firstName: 'Name',
    lastName: 'Surname',
    emailAddress: 'E-mail address',
    userName: 'Username',
    accountType: 'Account type',
    area: 'Area',
    logo: 'Logo',
    teamName: 'Team name',
    leader: 'Leader',
    company: 'Company',
    lastLogging: 'Last log-in',
    lastActivity: 'Last activity',
    administrativeActivities: 'Administr. activities',
    accountActivity: 'Account activity',
    deletingTasksInAreas: 'Deleting tasks in:',
    subordinate: 'own areas',
    all: 'all areas',
    accessToTranslations: 'Access to translations',
    PE: 'V/E',
    languagesEditing: 'edit languages',
    email: 'E-mail address',
    type: 'Account type'
  },
  form: {
    photoPlaceholder: 'Place for photo',
    companyName: 'Company name',
    firstName: 'Name',
    secondName: 'Middle name',
    lastName: 'Surname',
    emailAddress: 'E-mail address',
    phone: 'Landline phone number',
    mobilePhone: 'Mobile phone number',
    userName: 'Username',
    password: 'Password',
    language: 'Language',
    mainArea: 'Main area',
    roleInCompany: 'Role in company',
    usingInAreas: 'Used in areas',
    usingInAreasHelperText: 'leave empty it the whole company',
    editorPlaceholder: 'Enter text...',
    onlyToLeader: 'only to the leader',
    toAllMembers: 'to all members',
    teamName: 'Team name',
    id: 'ID',
    teamLeader: 'Team leader',
    teamManager: 'Team manager',
    areas: 'Area(s)',
    chooseCompany: 'Select the company',
    chooseArea: 'Select the area',
    search: 'Search',
    filter: 'Filter',
    administrative: 'administrative',
    translations: 'translations',
    areYouSureDeleteTeam: 'Are you sure you want to delete this team?',
    edit: 'edit'
  }
};

export const administrationTasksView = {
  TASKS: 'TASKS',
  tasksSettings: 'Task settings',
  tasksRealizationProgressFollowedBySteps: 'Task execution progress tracked by',
  'Dates given when confirming PDCA steps can be simple':
    'Dates for confirming completion of task steps can be in the past',
  'When creating a task, also notify the creator via':
    'When creating a task, also notify the creator via',
  '- color (default Yellow) - time to end':
    '– colour (yellow by default) – time to end',
  '- color (red by default) - time to end':
    '– colour (red by default) – time to end',
  '- color (burgundy by default) - time to end':
    '– colour (default burgundy) – time to end',
  'Highlighting tasks in the list':
    'Task highlight colours in the task table, depending on time left till deadline',
  'Highlight color in the archived task table':
    'Highlight colour for archived tasks in the task table',
  'The highlight color in the canceled job table':
    'Highlight colour for canceled tasks in the task table',
  tasksTypes: 'Task types',
  priorities: 'Priorities',
  clickToChooseColor: 'Click to select colour: ',
  code: 'Code',
  name: 'Name',
  'active?': 'Active?',
  usedInAreas: 'Used in areas',
  followingStatusBy: 'Status tracking by',
  addTaskType: 'Add task type',
  editTaskType: 'Edit task type',
  realizationProgressesToolTip:
    'PDCA and DMAIC are used to track progress of tasks created in the system. All new tasks without a defined type will by default be using the selected progress tracking method.',

  taskTypeTableHeaders: {
    active: 'Active?',
    taskType: 'Task type',
    status: 'Status by',
    requiresApproval: 'Requires approval',
    stepsToApprove: 'Steps requiring apprival',
    whoApproves: 'Who approves'
  }
};

export const administrationAuditsView = {
  AUDITS: 'AUDITS',
  forms: 'Forms',
  inactive: 'inactive',
  archived: 'archived',
  addAuditForm: 'Add audit form',
  editAuditForm: 'Edit audit form',
  designing: 'Design',
  version: 'Version',
  pinToFormMethodologyPracticeArea:
    'Link the form to methodology / practice / area',
  createDate: 'Creation date',
  lastChange: 'Last update',
  goal: 'Target',
  goodAboveBelow: 'Good above / below?',
  createdBy: 'Created by',
  authorizedToEdit: 'Authorized to edit',
  active: 'Active?',
  'closed?': 'Closed?',
  requireConfirmation: 'Requires approval?',
  panel: 'Panel',
  basicSettings: 'Basic settings',
  additionalSettings: 'Additional settings',
  enforceCompatibility: 'Enforce completeness',
  enforceCompatibilityToolTip:
    'Enables verification of audit completeness of the audit – if on, in order to finish the audit, all criteria must be assessed and all criteria requirements must be met (e.g. comments, tasks).',
  maxTasksRealizationTime: 'Max task execution time:',
  maxTasksRealizationTimeTooltip:
    'Sets the time, which will be the span of the analytical chart for tasks related to this audit',
  allowCopyCopyingDoneAudit:
    'When copying a completed audit, allow for copying:',
  allowCopyCopyingDoneAuditToolTip:
    'Define what can be copied when copying an already done audit to another area.',
  answers: 'answers',
  attachments: 'attachments',
  comments: 'comments',
  auditHeading: 'Audit header',
  name: 'Name',
  content: 'Content',
  field: 'Field',
  codeAndFormName: 'Form code and name',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  formName: 'Form name',
  area: 'Area',
  team: 'Team',
  shift: 'Shift',
  practice: 'Practice',
  methodology: 'Methodology',
  process: 'Process',
  audit_name: 'Process',
  usedInAreas: 'Used in areas',
  auditCriteria: 'Audit criteria',
  auditIntroductionInsertText: 'audit introduction – please enter text',
  example: 'Example',
  subgroup: 'Subgroup',
  group: 'Group',
  ratingScale: 'Rating scale',
  criterion: 'Criterion',
  showResults: 'Show results',
  ratingScales: 'Rating scales',
  ratingScalePreview: 'Rating scale preview',
  addCiterionDetails: 'Add details to criterion',
  tipsToDoEvaluation: 'Tips for performing assessment',
  addRatingScale: 'Add а rating scale',
  ratingScaleName: 'Rating scale name',
  consequences: 'Buttons',
  textType: 'textual',
  pictorial: 'pictorial',
  text: 'Text:',
  value: 'Value:',
  color1: 'Colour 1:',
  color2: 'Colour 2:',
  ratingButtons: 'Rating buttons:',
  evaluationInAudit: 'Look of the rating in audit:',
  picture: 'Picture:',
  'frame?': 'Frame?',
  'useThese?': 'Use these?',
  'active?': 'Active?',
  setNrManually: 'Set number manually',
  omitInNumbering: 'Skip in numbering',
  preview: 'Preview',
  radarChartOnThisLevel: 'Radar chart \nat this level',
  copyForm: 'Copy form',
  color1Tooltip:
    'This colour is applied to the value and frame of the rating button, the rating value in the audit, and the bars and fields in the audit statistics charts.',
  color2Tooltip:
    'This color is used to fill the button when highlighted and to fill the rating field in the audit.',

  tableHeaders: {
    active: 'Active?',
    'practice/area/form': 'Practice / area / form',
    version: 'Version',
    usedInAreas: 'Used in areas'
  },

  criteriaTableHeaders: {
    nr: 'No.',
    evaluationCriteria: 'Assessment criteria',
    weight: 'Weight',
    scaleDesc: 'Scale descr.',
    tips: 'Guide-lines',
    example: 'Example'
  },

  ratingScaleTableHeaders: {
    active: 'Active?',
    scaleName: 'Scale name',
    levels: 'Grades',
    preview: 'Preview',
    usedInForms: 'Used in forms'
  }
};

export const documentsView = {
  practicesPlatT: 'PlatT practices',
  areas: 'Areas',
  searchIn: 'Search in:',
  selectedBlock: 'selected block',
  inAll: 'all blocks',
  results: 'Results:',
  collapseBlocks: 'Collapse blocks',
  expandBlocks: 'Expand blocks',
  collapseSearchResults: 'Collapse results',
  expandSearchResults: 'Expand results',
  closeSearchResults: 'Close results',
  methodology: 'Methodology',
  actions: 'Actions',
  examples: 'Examples',
  companyFiles: 'Company files',
  implementationActivities: 'Implementation actions',
  run: 'Run',

  tableHeaders: {
    number: 'No.',
    activityName: 'Activity name',
    area: 'Area',
    responsible: 'Responsible',
    task: 'Task',

    launchedFor: 'Launched for',
    status: 'Status',
    startDate: 'Start date',
    redlizationDate: 'Deadline',
    numberOfDays: 'Number of days',

    block: 'Block',
    document: 'Document / file',
    date: 'Date',
    size: 'Size',
    addedBy: 'Added by'
  },
  displayingCommentInfo:
    "Automatic display of comments can be turned off in user's profile settings",
  showInAllLanguages: 'Show in all languages',
  showInMyLanguage: 'Show in my language',
  sendLinkModal: {
    sendLink: 'Send a link',
    sendTo: 'Send to',
    sendMessage: 'Send a message',
    system: 'system',
    email: 'e-mail',
    withCopy: 'copy me on it'
  }
};

export const translationsView = {
  translations: 'Tarnslations',
  importTranslations: 'Import translations',
  importedLanguage: 'Language being imported',
  addLanguage: 'Add language',
  editLanguage: 'Edit language',
  'authorized to edit translations': 'Users authorized to edit translations',
  updateTranslation: 'Update translation',
  tableHeaders: {
    number: 'No.',
    idLong: 'Identifier',
    id: 'ID',
    firstName: 'Name',
    lastName: 'Surname',
    email: 'E-mail address',
    PE: 'V/E',
    languagesEditing: 'Editing languages',
    company: 'Company',
    type: 'Account type',
    lastLogging: 'Last log-in',
    lastActivity: 'Last activity',
    idetificator: 'Identifier',
    en: 'English',
    pl: 'Polish',
    un: 'Ukrainian',
    ru: 'Russian'
  },
  form: {
    language: 'Language',
    dateFormat: 'Date format',
    localeId: 'LocaleID',
    separators: 'Separators',
    decimal: 'decimal',
    thousandth: 'thousands',
    country: 'Country',
    firstWeekDay: 'First day of the week',
    languageFlag: 'Language flag',
    placeForFlag: 'Flag',
    preview: 'Preview',
    activeLanguageInInterface: 'Language active in interface',
    personsAuthorizedToEdit: 'Users authorized to edit the language',
    show: 'Show',
    tooltips: {
      language: 'Enter the language name in English',
      localeId:
        'Select / enter the LocaleID if different locale variants are needed, e.g. en_GB, en_US. ”Show” enables locale visibility in language selection dropdowns.',
      flag: 'Load a .PNG, .GIF or .BMP file with a flag sized XXX x YYY pixels',
      flagFile: 'Upload a flag file'
    }
  }
};

export const evaluationsView = {
  evaluation: 'Assessment',
  change: 'Shift',
  team: 'Team',
  area: 'Area',
  audit: 'Audit',
  practiceBasedAssessments: 'Assessments by practice',
  evaluationConnectedTasksList: 'List of tasks related to the assessment',
  ratingsByDate: 'Assessments by date',
  audits: 'Audits',
  blocks: 'Blocks',
  topics: 'Themes',
  radar: 'Radar',
  data: 'Data',
  operators: 'Operators',
  management: 'Management',

  practiceEvaluation: 'Practice assessment',
  topic: 'Theme',
  block: 'Block',

  nr: 'No.',
  evaluationCriteria: 'Assessment criteria',
  gradingScale: 'Rating scale',
  standards: 'Standards',
  guidelines: 'Assessment guidelines',

  alwaysShow: 'Always show',
  hints: 'Guidelines',

  addAnArea: 'Add an area',
  chooseAreaWhichWantToAdd:
    'Select the area(s) you would like to add to the assessment',

  tableHeaders: {
    type: 'Type',
    number: 'Number',
    evaluationDate: 'Assessment date',
    deadLine: 'Deadline',
    lastUpdate: 'Last update',
    evaluator: 'Assessor',
    name: 'Name',
    version: 'Version',
    result: 'Result',
    status: 'Status',
    group: 'Group',
    evaluationLeader: 'Assessment leader'
  },

  gradingScaleDescription: {
    '3': 'Clear conservation strategy exists for brewing and packaging and it is well aligned with the global strategy',
    '1': 'There is a local (site) maintenance strategy, but not sufficiently linked it with the global strategy',
    '0': 'Global strategy not available on site, no local maintenance strategies (brewing and packaging)'
  },

  createEvaluation: 'Create assessment',
  editEvaluation: 'Edit assessment',
  evaluationSubmitButton: 'Create',
  selectEvaluationType: 'Select assessment type',
  selectEvaluationVersion: 'Select assessment version',
  selectPractice: 'Select practice',
  selectArea: 'Select area',
  'deleteEvaluation?': 'Delete evaluation?',
  evaluationTypes: {
    target: 'Target',
    baseline: 'Baseline',
    current: 'Current',
    derivative: 'Derivative',
    normal: 'Normal'
  },
  evaluationStatusModal: {
    closingEvaluation: 'Closing assessment',
    table: {
      headers: {
        roles: 'Role',
        person: 'Person',
        isClosed: 'Closed'
      }
    }
  },
  criteriaListModal: {
    openedCriteriasList: 'List of open criteria',
    table: {
      headers: {
        topic: 'Theme',
        stage: 'Stage',
        block: 'Block',
        criterion: 'Criterion'
      }
    }
  },
  criterionTasksModal: {
    criterionTasks: 'Criterion tasks',
    launchedTasks: 'Launched tasks',
    predefinedTasks: 'Predefined tasks',
    table: {
      headers: {
        name: 'Name',
        description: 'Description',
        responsible: 'Responsible',
        area: 'Area'
      }
    }
  },
  actionsMenu: {
    newEvaluation: 'New practice assessment in selected areas',
    newAudit: 'New audit in selected areas',
    newForm: 'New form for selected areas',
    newDerivative: 'New derivative assessment in the selected area',
    importEvaluation: 'Import assessment or audit file'
  }
};

export const createEvalutionView = {
  leader: 'Leader'
};

export const auditsView = {
  createAudit: 'Create an audit',
  editAudit: 'Edit an audit',
  chart: 'Chart',
  auditSummaryAndStatistics: 'Audit summary and statistics',
  criteriaEvaluation: 'Criteria rating',
  criterionNumber: 'Criterion number',
  area: 'Area:',
  team: 'Team:',
  shift: 'Shift:',
  auditResult: 'Audit result:',
  from: 'from',
  pnt: 'pnt.',
  finish: 'Finish',
  'deleteAudit?': 'Delete audit?',

  tableHeaders: {
    type: 'Type',
    auditForm: 'Audit form',
    number: 'Number',
    auditDate: 'Audit date',
    deadLine: 'Execution date',
    lastUpdate: 'Last update',
    auditsLeader: 'Audit Leader',
    name: 'Name',
    version: 'Version',
    result: 'Result',
    status: 'Status',
    team: 'Team'
  },
  previewTableHeaders: {
    number: 'Nо.',
    evaluationCriteria: 'Assessment criteria',
    evaluation: 'Assessment',
    comment: 'Comment'
  },
  mainThingsToImprove: 'Main areas for improvement and development',
  sum: 'Total:',
  comment: 'Comment',
  show: 'Show',
  closingAudit: 'Closing the audit'
};

export const createAuditView = {
  process: 'Process',
  combineProcessAuditPackage: 'Combine into a process audit package',
  auditLeaderAuditor: 'Audit leader / chief auditor',
  planSeries: 'Schedule a series',
  sendInvitations: 'Send invitations'
};

export const createTaskView = {
  activities: 'activities',
  auditName: 'Audit name',
  createdBy: 'Created by',
  auditForm: 'Audit form',
  taskTitle: 'Create a new task',
  evaluationTitle: 'Create assessment',
  practice: 'Practice',
  footer: 'Requires approval?',
  isAssignedToPractice: 'Linked to practice?',
  areas: 'Area(s)',
  copy: 'Copy',
  responsible: 'Responsible',
  author: 'Author',
  participants: 'Participants',
  manage: 'Manage',
  taskName: 'Task name',
  evaluationName: 'Assessment name',
  description: 'Description',
  priority: 'Priority',
  taskType: 'Task type',
  teams: 'Teams & groups',
  planning: 'Planning',
  plannedCommencementDate: 'Planned start date',
  completionDate: 'Deadline',
  scheduledTime: 'Planned time hrs/mins',
  onlyCompletion: 'Only execution (D)',
  completion: 'Execution',
  actualCommencementDate: 'Actual start date',
  actualCompletionDate: 'Actual completion date',
  completionTime: 'Completion time',
  version: 'Version',
  evaluationOnLevel: 'Assessment on level',
  status: 'Status',
  associateWith: 'Link with',
  auditTeam: 'Audit team',
  evaluationType: 'Type',
  evaluationScore: 'Assessment result',
  isFinished: 'Finished',
  isClosed: 'Closed',
  show: 'Show',
  launch: 'Launch',
  tasks: 'Tasks'
};

export const buttons = {
  highlightBlocks: 'Highlight blocks',
  add: 'Add',
  change: 'Change',
  remove: 'Remove',
  import: 'Import',
  close: 'Close',
  saveTemplate: 'Save template',
  cancel: 'Cancel',
  ok: 'OK',
  save: 'Save',
  saveAndClose: 'Save and close',
  delete: 'Delete',
  sendMessage: 'Send message',
  addNew: 'Add new',
  importTranslation: 'Import a translation',
  exportToExcel: 'Export to Excel',
  entitled: 'Entitled',
  addLanguage: 'Add a language',
  specialLicenses: 'Special permissions',
  addPerson: 'Add a person',
  manage: 'Manage',
  turnOn: 'Turn on',
  choose: 'Select',
  download: 'Download'
};

export const pagination = {
  show: 'Showing',
  from: 'of',
  positions: 'entries',
  next: 'Next',
  previous: 'Previous',
  rowsPerPage: 'Rows per page',
  page: 'Page',
  rows: 'rows'
};

export const criteriaView = {
  exampleForCriterion: 'Criterion examples',
  attachmentsForCriterion: 'Criterion attachments',
  addFile: {
    title: 'Add file',
    selectFile: 'Select file',
    preview: 'Preview',
    fileNameDescription: 'File name will be here',
    documentName: 'Document name',
    documentDescription: 'Document description',
    link: 'Link',
    comment: 'Comment'
  },
  editFile: {
    title: 'Edit file'
  }
};

export const loginView = {
  platform: 'Platform',
  ofTransformation: 'оf Transformation',
  welcome: 'Welcome'
};

export default {
  common,
  nav,
  auth,
  profile,
  tasks,
  administration,
  employeesAndUsersView,
  translationsView,
  buttons,
  pagination,
  administrationTasksView,
  administrationAuditsView,
  evaluationsView,
  createEvalutionView,
  auditsView,
  createAuditView,
  criteriaView,
  createTaskView,
  documentsView,
  desktop,
  loginView
};
