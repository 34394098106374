import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import styled from 'styled-components';

const StyledP = styled(P)`
  padding: 7px;
`;

const StyledLabel = styled.label`
  margin-right: 8px;
  font-size: ${({ theme }) => `${theme.textStyles.body.fontSize}px`};
`;

interface Props {
  label?: string | null;
  onChange?: (state: boolean) => void;
  value?: boolean;
  disabled?: boolean;
}
export const SwitchButton: FC<Props> = ({
  label,
  value,
  onChange,
  disabled
}) => {
  const [checked, setChecked] = useState(!!value);
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" alignItems="center">
      {label && <StyledLabel>{t(label)}</StyledLabel>}
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={() => {
          setChecked((prevChecked) => {
            const newChecked = !prevChecked;
            onChange && onChange(newChecked);

            return newChecked;
          });
        }}
        checkedIcon={
          <StyledP variant="body2" color="white">
            {t('common.yes')}
          </StyledP>
        }
        uncheckedIcon={
          <StyledP variant="body2" color="white">
            {t('common.no')}
          </StyledP>
        }
      />
    </Flex>
  );
};
