import React, { MouseEvent, useState } from 'react';
import { Box, Card, Flex } from 'components';
import { useDropdown } from 'hooks/useDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faTrash,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { Item, Root, FilterItem, WrapperChildren, WrapperDropdown } from './DropdownFilters.styled';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useTaskSidebarContext } from '../../../../../../contexts/TaskSidebarContext';
import { SidebarFilters } from '../../../../../../types/sidebar';

type FilterName = keyof SidebarFilters
type FilterNamePartial = Partial<keyof SidebarFilters>

export const DropdownFilters: React.FC = () => {
  const { t } = useTranslation();
  const {
    savedFilters,
    defaultFilters,
    setSavedFilters,
    setDefaultFilters
  } = useTaskSidebarContext();
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const savedFiltersAsArray = Object.keys(savedFilters);
  const defaultFiltersAsArray = Object.keys(defaultFilters);

  const removeSavedFilter = (filterName: FilterName) => {
    setSavedFilters(prevState => {
      const filtersUpdated = { ...prevState };
      delete filtersUpdated[filterName];
      return filtersUpdated;
    });
  };

  const saveFilterAsDefault = (filterName: FilterNamePartial) => {
    setDefaultFilters(prevState => {
      const filtersUpdated: Partial<SidebarFilters> = { ...prevState };
      // @ts-ignore
      filtersUpdated[filterName] = savedFilters[filterName];
      return filtersUpdated;
    });
  };

  const removeDefaultFilter = (filterName: string) => {
    setDefaultFilters(prevState => {
      const filtersUpdated = { ...prevState };
      delete filtersUpdated[filterName as FilterName];
      return filtersUpdated;
    });
  };

  const handleFilterAction = (e: MouseEvent<HTMLDivElement>, filterName: FilterNamePartial) => {
    // @ts-ignore
    const action = e.target?.viewportElement?.dataset?.action;
    action === 'save' && (saveFilterAsDefault(filterName));
    action === 'remove' && (removeSavedFilter(filterName));
    action === 'removeDefault' && (removeDefaultFilter(filterName));
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role='button'
        aria-hidden='true'
        alignItems='center'
      >
        {t('tasks.savedFilters')}
        <Box mr={1}>
          {isVisible ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </Box>
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Item>
            <h4>{t('tasks.defaultFilter')}</h4>
            {defaultFiltersAsArray.length < 1
              ? <p>{t('tasks.defaultFilterNotExists')}</p>
              // @ts-ignore
              : defaultFiltersAsArray.filter(el => defaultFilters?.[el as FilterName]?.label?.length > 0).map(key =>
                <FilterItem key={key}>
                  <p>{defaultFilters?.[key as FilterName]?.label}</p>
                  <Flex onClick={(e) => handleFilterAction(e, key as FilterNamePartial)}>
                    <FontAwesomeIcon
                      data-action='removeDefault'
                      id={'trashTooltipForDefault' + key}
                      data-tooltip-content={t('tasks.delete')}
                      icon={faTrash}
                    />
                  </Flex>
                  <Tooltip anchorId={'trashTooltipForDefault' + key} />
                </FilterItem>
              )
            }
          </Item>
          <Item>
            <h4>{t('tasks.savedFilters')}</h4>
            {savedFiltersAsArray.length < 1
              ? <p>{t('tasks.savedFiltersNotExists')}</p>
              // @ts-ignore
              : savedFiltersAsArray.filter(el => savedFilters[el]?.label?.length > 0).map(key =>
                <FilterItem key={key}>
                  <p>{savedFilters?.[key as FilterName]?.label}</p>
                  <Flex onClick={(e) => handleFilterAction(e, key as FilterNamePartial)}>
                    <FontAwesomeIcon
                      data-action='save'
                      id={'heartTooltip' + key}
                      data-tooltip-content={t('tasks.setAsDefault')}
                      icon={faHeart}
                    />
                    <FontAwesomeIcon
                      data-action='remove'
                      id={'trashTooltip' + key}
                      data-tooltip-content={t('tasks.delete')}
                      icon={faTrash}
                    />
                  </Flex>
                  <Tooltip anchorId={'heartTooltip' + key} />
                  <Tooltip anchorId={'trashTooltip' + key} />
                </FilterItem>
              )
            }
          </Item>
        </WrapperDropdown>
      )}
    </Root>
  );
};
