import { Flex } from 'components/Flex';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import React, { InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FieldLabel } from '../FieldLabel';
import { Tooltip } from 'react-tooltip';

export interface CheckboxI extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null;
  error?: boolean;
  name?: string;
  id?: string;
  checked?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  unCheckedIconDisplay?: boolean;
  customStyle?: {
    label: string;
    bgColor: string;
  };
  sizeVariant?: 'normal' | 'small';
  tooltip?: string;
}

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: pre-wrap;
  + label {
    cursor: pointer;
    ${({ theme }) => `
      color: ${theme.palette.neutral.grey};
    `};
  }
`;

const Control = styled(Flex)<{
  bgColor?: string;
  label?: string;
  sizeVariant: CheckboxI['sizeVariant'];
}>`
  flex-shrink: 0;
  ${({ bgColor, label, theme, sizeVariant }) => `    
    position: relative;
    width: ${sizeVariant === 'small' ? '14px' : '22px'};
    height: ${sizeVariant === 'small' ? '14px' : '22px'};
    margin-right: 8px;
    border: 1px solid ${theme.palette.primary.main};
    background-color: ${bgColor && label && theme.palette[label][bgColor]};
    border-radius: 3px;
    top: 0;

    :focus-visible {
      outline: 1px solid ${theme.palette.neutral.grey};
    }
  `}
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)<{
  sizeVariant: CheckboxI['sizeVariant'];
}>`
  ${({ theme, sizeVariant }) => `
    position: absolute;
    top: 2px;
    left: 3px;
    width: ${sizeVariant === 'small' ? '8px' : '16px'};
    height: ${sizeVariant === 'small' ? '8px' : '16px'};
    fill: ${theme.palette.neutral.white};
  `}
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
`;

interface IInput {
  error?: boolean;
  variant?: CheckboxI['variant'];
  sizeVariant?: CheckboxI['sizeVariant'];
}

const Input = styled.input<IInput>`
  ${({ theme, error, variant, sizeVariant }) => `
    opacity: 0;
    width: ${sizeVariant === 'small' ? '14px' : '22px'};
    height: ${sizeVariant === 'small' ? '14px' : '22px'};
    display: none;
    border-radius: 3px;
    + ${Control} {
      ${error ? `border-color: ${theme.palette.primary.main}` : ''};
    }

    &:checked {
      + ${Control} {
        background-color: ${
          variant === 'primary'
            ? theme.palette.primary.main
            : variant === 'secondary'
            ? theme.palette.accent.green
            : theme.palette.accent.blue
        };
        border-color: ${
          error ? theme.palette.accent.red : theme.palette.primary.main
        };
      }
    }

    &:disabled {
      + ${Control} {
        opacity: 0.4;

        background-color: ${theme.palette.neutral.medium};
        border-color: ${theme.palette.neutral.medium};
      }
    }
  `}
`;

export const Checkbox: React.FC<CheckboxI> = ({
  label,
  name,
  error,
  checked,
  unCheckedIconDisplay,
  onChange,
  id,
  variant = 'primary',
  sizeVariant = 'normal',
  customStyle,
  tooltip,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <Label htmlFor={id} id={`${id}-label`} data-tooltip-content={tooltip}>
      <Input
        type="checkbox"
        checked={checked}
        error={error}
        name={name}
        onChange={onChange}
        id={id}
        ref={ref}
        variant={variant}
        sizeVariant={sizeVariant}
        {...props}
      />
      <Control
        onKeyDown={() => {
          ref?.current?.click();
        }}
        tabIndex={0}
        bgColor={customStyle && customStyle.bgColor}
        label={customStyle && customStyle.label}
        sizeVariant={sizeVariant}
      >
        {checked ? (
          <StyledCheckmarkIcon sizeVariant={sizeVariant} />
        ) : (
          unCheckedIconDisplay && <StyledFontAwesomeIcon icon={faX} />
        )}
      </Control>
      {label && <FieldLabel>{t(label)}</FieldLabel>}
      {tooltip && <Tooltip anchorId={`${id}-label`} />}
    </Label>
  );
};
