import {
  faFile,
  faFolderOpen,
  faTrashCan,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Flex, Line, Table } from 'components';
import { FileIcon } from 'components/FileIcon/FileIcon';
import { ImagesPicker } from 'components/ImagesPicker/ImagesPicker';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { Button, SwitchButton } from 'components/_form';
import saveAs from 'file-saver';
import { ChangeEvent, useRef, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: none;
`;

interface Props extends IGenericModal {
  data?: { name: string };
  setValue?: UseFormSetValue<FieldValues>;
  documents: File[];
  setDocuments: (images: File[]) => void;
  images: File[];
  setImages: (images: File[]) => void;
  allowEdit?: boolean;
}

export const FilesModal = ({
  isOpen,
  onCancelClick,
  documents,
  setDocuments,
  images,
  setImages,
  allowEdit = true
}: Props) => {
  const [showMedia, setShowMedia] = useState(false);
  const { t } = useTranslation();

  const documentsToDisplay = showMedia ? [...images, ...documents] : documents;

  const ref = useRef<HTMLInputElement>(null);

  const handleDeleteDocument = (document: File) => {
    const filteredDocuments = documents.filter(
      (prevDocument) => prevDocument.name !== document.name
    );

    setDocuments(filteredDocuments);
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    if (e.target.files) {
      const newDocuments = [...documents, ...Array.from(e.target.files || [])];

      setDocuments(newDocuments);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('tasks.TaskPhotosAndDocuments')}
      mainButton={{
        action: onCancelClick,
        variant: 'grey',
        label: t('buttons.close')
      }}
    >
      <Flex flexDirection={['column', 'row']}>
        <Flex width={['100%', '65%']} pr={2} py={2} flexDirection="column">
          <H2 textTransform="uppercase" variant="h2" color="coloured">
            {t('tasks.photos')}
          </H2>
          <Line />

          <ImagesPicker
            images={images}
            setImages={setImages}
            allowEdit={allowEdit}
          />
        </Flex>
        <Flex width={['100%', '35%']} pl={2} py={2} flexDirection="column">
          <H2 textTransform="uppercase" variant="h2" color="coloured">
            {t('tasks.documents')}
          </H2>
          <Line />

          <Flex m={1} display={documentsToDisplay.length ? 'none' : ''}>
            <Button
              fullWidth
              variant="eucalyptus"
              onClick={() => {
                ref?.current?.click();
              }}
              disabled={!allowEdit}
            >
              <Flex style={{ position: 'relative' }}>
                <Box mr={2} style={{ position: 'absolute' }}>
                  <FontAwesomeIcon icon={faFile} />
                </Box>
                <StyledInput
                  ref={ref}
                  type="file"
                  multiple
                  onChange={onChange}
                />

                <Flex mx="auto">{t('tasks.add another file/document')}</Flex>
              </Flex>
            </Button>
          </Flex>

          {!!documentsToDisplay.length && (
            <>
              <Table
                header={[
                  t('common.document/file'),
                  <FontAwesomeIcon
                    key="documents-person-cell-header"
                    icon={faUser}
                  />,
                  ''
                ]}
                data={documentsToDisplay.map((document) => ({
                  name: (
                    <Flex gap="4px" alignItems="center">
                      <FileIcon fileType="doc" size="xl" />
                      {document.name}
                    </Flex>
                  ),
                  person: <Avatar size="extra-small" />,
                  buttons: (
                    <Flex gap="5px">
                      <Button
                        variant="eucalyptus"
                        icon={<FontAwesomeIcon icon={faFolderOpen} />}
                        onClick={() => {
                          saveAs(URL.createObjectURL(document), document.name);
                        }}
                      />
                      <Button
                        disabled={!allowEdit}
                        variant="red"
                        icon={<FontAwesomeIcon icon={faTrashCan} />}
                        onClick={() => {
                          handleDeleteDocument(document);
                        }}
                      />
                    </Flex>
                  )
                }))}
              />

              <Flex
                gap="5px"
                justifyContent="space-between"
                flexDirection={['column', 'row']}
              >
                <SwitchButton
                  value={showMedia}
                  onChange={() => {
                    setShowMedia((prevShowMedia) => !prevShowMedia);
                  }}
                  label={t('common.showAlsoMedia')}
                />
                <Button
                  variant="eucalyptus"
                  onClick={() => {
                    ref?.current?.click();
                  }}
                  disabled={!allowEdit}
                >
                  <Flex gap="5px" alignItems="center">
                    <FontAwesomeIcon icon={faFile} />
                    <P variant="body">{t('tasks.add another file/document')}</P>
                  </Flex>
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
