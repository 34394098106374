import qs from 'qs';
import request from './index';
import {
  IPostRatingScale,
  IRatingScaleResponse,
  IRatingScalesRequest,
  IRatingScalesResponse
} from 'types/ratingScales';
import toErrorWithMessage from 'utilities/getErrorMessage';
import { serialize } from 'object-to-formdata';

export const getRatingScales = ({
  filters
}: {
  filters?: IRatingScalesRequest;
}) => {
  try {
    const query = qs.stringify(filters, { arrayFormat: 'brackets' });

    return request<IRatingScalesResponse>({
      options: {
        url: `/rating_scales?${query}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const getRatingScale = (id: number) => {
  try {
    return request<IRatingScaleResponse>({
      options: {
        url: `/rating_scales/${id}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const postRatingScale = (data: IPostRatingScale) => {
  try {
    return request<IRatingScaleResponse>({
      options: {
        url: `/rating_scales`,
        method: 'post',
        data: serialize(data)
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const patchRatingScale = (id: number, data: IPostRatingScale) => {
  try {
    return request<IRatingScaleResponse>({
      options: {
        url: `/rating_scales/${id}`,
        method: 'patch',
        data: serialize(data)
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const deleteRatingScale = (id: number) => {
  try {
    return request<{}>({
      options: {
        url: `/rating_scales/${id}`,
        method: 'delete'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
