import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { SwitchButton } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormAudit } from './FormAudit/FormAudit';
import { Loader } from 'components/Loader/Loader';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { deleteAudit, getAudit, patchAudit } from 'api/audits';
import { CreateAuditBody, IAudit, IDetailedAudit } from 'types/audits';
import { iso8601HourAndHalf } from 'constants/iso8601';
import { onlyUniqueArray } from 'utilities/onlyUniqueArray';

export type FormData = Omit<CreateAuditBody, 'audit_participations_attributes'>;

export type FormDataControl = FormData & {
  practice_id: IOption;
  version: IOption;
  evaluation_type: IOption;
  audit_participations_attributes: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
};

interface Props extends IGenericModal {
  isOpen: boolean;
  onCancelClick: () => void;
  auditId?: IAudit['id'];
}

export const EditAuditModal: React.FC<Props> = ({
  isOpen,
  onCancelClick,
  auditId
}) => {
  const [loading, setLoading] = useState(true);
  const [approvalSwitch, setApprovalSwitch] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState<IDetailedAudit>();

  const { handleLoadAudits, setSelectedAuditId } = useEvaluationContext();

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FormDataControl>();

  const handleGetSelectedAudit = async (id: number) => {
    setLoading(true);

    const auditRes = await getAudit(id);

    if ('data' in auditRes) {
      setSelectedAudit(auditRes.data);
    }

    setLoading(false);
  };

  const handleSubmitAuditForm = useCallback(
    async (data: FormDataControl, closeOnSubmit = true) => {
      try {
        const actualEvaluationParticipationsAttributes = auditId
          ? [
              // participants included in fetched list and not in selected list are removed
              ...(selectedAudit?.audit_participations
                ?.filter(
                  (auditParticipation) =>
                    !data.audit_participations_attributes?.find(
                      (auditParticipationAttribute) =>
                        auditParticipationAttribute.value.toString() ===
                        auditParticipation.participant?.id.toString()
                    )
                )
                .map((auditParticipation) => ({
                  id: auditParticipation.id,
                  _destroy: 1
                })) || []),
              // participants included in selected list and not in fetched list are removed
              ...(
                data.audit_participations_attributes?.filter(
                  (auditParticipationAttribute) =>
                    !selectedAudit?.audit_participations?.find(
                      (auditParticipation) =>
                        auditParticipationAttribute.value.toString() ===
                        auditParticipation.participant.id.toString()
                    )
                ) || []
              ).map((auditParticipationAttribute) => ({
                participant_id: auditParticipationAttribute.value,
                function_id: auditParticipationAttribute.function_id
              })),
              // participants included in both but with different function_id
              ...(
                selectedAudit?.audit_participations?.filter(
                  (auditParticipation) =>
                    data.audit_participations_attributes?.find(
                      (auditParticipationAttribute) =>
                        auditParticipationAttribute.value.toString() ===
                          auditParticipation.participant?.id.toString() &&
                        auditParticipationAttribute.function_id?.toString() !==
                          auditParticipation.function?.id.toString()
                    )
                ) || []
              ).map((auditParticipation) => ({
                id: auditParticipation.id,
                function_id: data.audit_participations_attributes?.find(
                  (auditParticipationAttribute) =>
                    auditParticipationAttribute.value.toString() ===
                    auditParticipation.participant?.id.toString()
                )?.function_id
              }))
            ]
          : data.audit_participations_attributes?.map(
              (auditParticipationAttribute) => ({
                participant_id: auditParticipationAttribute.value,
                function_id: auditParticipationAttribute.function_id
              })
            );

        const payload = {
          audit: {
            ...data,
            type: 'Audit',
            evaluation_type:
              data.evaluation_type?.value || data.evaluation_type,
            author_id: JSON.parse(localStorage.getItem('currentUser') as string)
              .id,
            audit_participations_attributes:
              actualEvaluationParticipationsAttributes
          }
        };

        const auditResponse = await patchAudit(selectedAudit?.id || 1, payload);
        await handleLoadAudits();
        toast.success(t('common.success'));
        setSelectedAuditId(undefined);
        closeOnSubmit && onCancelClick();

        return auditResponse.data;
      } catch (error) {
        console.log(error);
        toast.error(t('common.anErrorOccurred'));
      }
    },
    [
      auditId,
      handleLoadAudits,
      onCancelClick,
      selectedAudit?.audit_participations,
      selectedAudit?.id,
      t,
      setSelectedAuditId
    ]
  );

  const handleDeleteEvaluation = async () => {
    if (auditId) {
      const deleteEvaluationRes = await deleteAudit(auditId);

      if (deleteEvaluationRes && 'error' in deleteEvaluationRes) {
        return;
      }

      toast.success(t('common.success'));

      await handleLoadAudits();
      onCancelClick();
    }
  };

  const defaultFormValues: FormData = useMemo(() => {
    return {
      realization_date: new Date().toJSON().slice(0, 10).toString(),
      evaluation_type: {
        value: selectedAudit?.evaluation_type || 'normal',
        label: selectedAudit?.evaluation_type || 'normal'
      },
      version: {
        value: '3.0-GW',
        label: '3.0-GW'
      },
      date: new Date().toJSON().slice(0, 10).toString(),
      name: selectedAudit?.name || '',
      description: selectedAudit?.description.body || '',
      audit_form_id: selectedAudit?.audit_form?.id,
      area_id: selectedAudit?.area.id.toString() || '',
      planned_duration:
        selectedAudit?.planned_duration_iso8601 || iso8601HourAndHalf,
      duration: selectedAudit?.duration_iso8601 || iso8601HourAndHalf,
      author_id: selectedAudit?.author?.id?.toString(),
      leader_id: selectedAudit?.leader?.id?.toString(),
      team_leader_id: selectedAudit?.team_leader?.id?.toString(),
      team_manager_id: selectedAudit?.team_manager?.id?.toString(),
      audit_participations_attributes: selectedAudit?.audit_participations?.map(
        (auditParticipation) => ({
          value: auditParticipation.participant.id,
          label: `${auditParticipation.participant.first_name} ${auditParticipation.participant.last_name}`,
          function_id: auditParticipation.function?.id
        })
      ),
      team_ids: selectedAudit?.teams?.map((team) => team.id.toString()),
      notes: selectedAudit?.notes || [],
      planned_start_date: selectedAudit?.planned_start_date,
      planned_completion_date: selectedAudit?.planned_completion_date,
      start_time: selectedAudit?.start_time,
      completion_time: selectedAudit?.completion_time,
      state: selectedAudit?.state,
      closing_ids: onlyUniqueArray(
        [
          ...(selectedAudit?.audits_closings.map(({ user: { id } }) =>
            id.toString()
          ) || []),
          selectedAudit?.author?.id?.toString()
        ].filter((id) => id)
      )
    };
  }, [selectedAudit]);

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  useEffect(() => {
    if (auditId) {
      handleGetSelectedAudit(auditId);
    }
  }, [auditId]);

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {
          handleSubmit((data) => handleSubmitAuditForm(data))();
          reset(defaultFormValues);
        },
        variant: 'eucalyptus',
        label: t(`common.save`)
      }}
      isSecondButtonVisible={true}
      gridTemplateColumns="95%"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {t(`auditsView.editAudit`)}
          </H2>
        </Flex>
      }
      additionalFooter={
        <Flex
          minWidth="250px"
          alignItems="center"
          gap="10px"
          display={['none', 'flex']}
        >
          <P variant="body">{t('createTaskView.footer')}</P>
          <SwitchButton value={approvalSwitch} onChange={setApprovalSwitch} />
        </Flex>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <FormAudit
          control={control}
          register={register}
          canChangeAuditStatus={true}
          errors={errors}
          watch={watch}
          setValue={setValue}
          submitAuditForm={handleSubmitAuditForm}
          selectedAudit={selectedAudit}
          setSelectedAudit={setSelectedAudit}
          handleDeleteAudit={handleDeleteEvaluation}
        />
      )}
    </Modal>
  );
};
