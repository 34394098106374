import { faCirclePlus, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCompanyContactAndSupports } from 'api/companies';
import { Box, Flex, Line } from 'components';
import { H2 } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ICompanyContactAndSupport } from 'types/company';

export const ContactAndHelpForm = () => {
  const { control, register } = useForm<{
    contact_people: ICompanyContactAndSupport[];
  }>();

  const { fields, append, remove } = useFieldArray({
    name: 'contact_people',
    control
  });

  const handleGetContactAndSupports = async () => {
    const { data } = await getCompanyContactAndSupports();
    append(data);
  };

  useEffect(() => {
    handleGetContactAndSupports();
  }, []);

  return (
    <Flex p={3} flexDirection="column">
      {fields.map((field, index) => (
        <Flex key={field.id} flexDirection="column">
          <H2 mt={2} variant="h2" color="coloured">
            {field.title}
          </H2>
          <Line />
          <Flex flexWrap={['wrap', 'nowrap']}>
            <Flex width="60%" mr={2} flexWrap={['wrap', 'nowrap']}>
              <Box width="100%" mr={2}>
                <TextField
                  {...register(
                    `contact_people.${index}.first_and_last_name` as const
                  )}
                  label="administration.fullname"
                />
              </Box>
              <TextField
                {...register(`contact_people.${index}.email_address` as const)}
                label="administration.emailAddress"
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                onIconClick={() => {
                  window.open(
                    `mailto:${field.email_address}?subject=Subject&body=Body%20goes%20here`
                  );
                }}
              />
            </Flex>

            <Flex width="40%" flexWrap={['wrap', 'nowrap']}>
              <Box width="100%" mr={2}>
                <TextField
                  {...register(`contact_people.${index}.phone` as const)}
                  label="profile.phone"
                />
              </Box>
              <Box width="100%" mr={2}>
                <TextField
                  {...register(
                    `contact_people.${index}.landline_phone` as const
                  )}
                  label="profile.landlinePhone"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      ))}

      {/* <Flex justifyContent="end">
        <Button variant="eucalyptus" onClick={append}>
          <Flex>
            <Box mr={2}>
              <FontAwesomeIcon icon={faCirclePlus} />
            </Box>
            {t('administration.addPerson')}
          </Flex>
        </Button>
      </Flex> */}
    </Flex>
  );
};
