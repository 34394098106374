import { faFilter, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'styles';
import { device } from 'styles/theme';

export const ButtonWrapper = styled(Flex)`
  min-width: 380px;

  > button:first-child {
    margin-right: 12px;
  }

  @media ${device.tablet} {
    min-width: 200px;
  }
`;

export const CustomTitle = styled(P)<{ fontColor?: string }>`
  color: ${({ fontColor }) => fontColor};
`;

interface Props {
  customTitle?: string;
  setIsFormModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paginationTotal: number;
  realizationTime?: string;
}

export const TopBar: React.FC<Props> = ({
  customTitle,
  setIsFormModalOpen,
  paginationTotal,
  // TODO: handle get realizationTime from api
  realizationTime = '3.67 h'
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      alignItems="center"
      gap="15px"
    >
      {customTitle && (
        <CustomTitle
          variant="h3"
          fontWeight={700}
          fontColor={theme.palette.accent.blue}
        >
          {customTitle}
        </CustomTitle>
      )}
      <P variant="body">
        {t('tasks.tasksCount')} {paginationTotal} | ∑{' '}
        {t('tasks.realizationTimes')} {realizationTime}
      </P>
      <ButtonWrapper flexDirection={['column', 'row']} gap="5px">
        <Button
          iconPosition="left"
          icon={<FontAwesomeIcon icon={faFilter} />}
          fullWidth
        >
          {t('tasks.exportToExcel')}
        </Button>
        <Button
          iconPosition="left"
          variant="eucalyptus"
          fullWidth
          icon={<FontAwesomeIcon icon={faFloppyDisk} />}
          onClick={() => {
            setIsFormModalOpen((prevIsFormModalOpen) => !prevIsFormModalOpen);
          }}
        >
          {t('tasks.addTask')}
        </Button>
      </ButtonWrapper>
    </Flex>
  );
};
