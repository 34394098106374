import { FieldLabel } from 'components/_form';
import React, { forwardRef, MouseEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Flex } from '../Flex';

interface IDatePicker {
  icon?: JSX.Element;
  label?: string;
  value?: Date | null;
  onChange: (val: Date | null) => void;
  isClearable?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const Wrapper = styled.div`
  width: 100%;

  .react-datepicker-wrapper {
    height: 35px;
    border-radius: 6px;
    ${({ theme }) => `
      border: 1px solid ${theme.palette.neutral.lightGrey};
      color: ${theme.palette.text.primary};
    `};

    .react-datepicker__input-container {
      height: 100%;

      input {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        padding: 0 12px;
      }
    }
  }
`;

const CustomInput = styled(Flex)`
  letter-spacing: -0.5px;
  max-height: 100%;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  padding: 0 8px;
  height: 100%;

  > span {
  }

  > svg {
    font-size: 20px;
  }
`;

export const DatePicker: React.FC<IDatePicker> = ({
  icon,
  label,
  value,
  onChange,
  isClearable,
  minDate,
  maxDate
}) => {
  const InputWithIcon = forwardRef(
    (
      {
        value,
        onClick
      }: {
        value?: string;
        onClick?: React.MouseEvent<HTMLDivElement, MouseEvent>;
      },
      ref
    ) => (
      // @ts-ignore
      <CustomInput onClick={onClick} ref={ref}>
        <span>{value}</span>
        {isClearable ? !value && icon : icon}
      </CustomInput>
    )
  );
  InputWithIcon.displayName = 'InputWithIcon';

  return (
    <Wrapper>
      {label && <FieldLabel htmlFor={'datePicker' + label}>{label}</FieldLabel>}
      <ReactDatePicker
        id={'datePicker' + label}
        dateFormat="dd.MM.yyyy"
        selected={value}
        onChange={onChange}
        isClearable={isClearable}
        minDate={minDate}
        maxDate={maxDate}
        customInput={icon ? <InputWithIcon /> : undefined}
      />
    </Wrapper>
  );
};
