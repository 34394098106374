import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Box, Avatar, Dropdown, Menu } from 'components';
import styled from 'styled-components';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { useNavigate } from 'react-router';
import { device } from 'styles/theme';
import { navItems } from 'constants/navItems';
import { P } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { Notifications } from 'components/Notifications';
import { useAccount } from 'hooks/useAccount';
import { logoutUser } from 'api/auth';
import { faIdBadge } from '@fortawesome/free-regular-svg-icons';
import { IUser } from 'types/user';
import { getUser } from 'api/user';

export interface INavbar {
  logged: boolean;
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
}

const Wrapper = styled(Flex)`
  border-bottom: 0.5px solid #e9e9e9;
  grid-area: navbar;
  width: 100%;
  height: 80px;

  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `}
`;
const MenuToggleIcon = styled(MenuIcon)`
  display: none;
  margin-right: auto;
  padding: 10px;
  height: 50px;
  width: 50px;
  cursor: pointer;

  @media ${device.tablet} {
    display: block;
  }
`;

const TabletBox = styled(Box)`
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

const Languages = styled(Flex)`
  align-items: center;

  span {
    &[data-val='true'] {
      border-bottom: 1px solid black;
    }
    cursor: pointer;
    margin: 0 10px;
  }
`;

const StyledLanguage = styled.button`
  background-color: transparent;
  padding: 5px;
  ${({ theme }) => `
    :focus-visible {
      outline: 1px solid ${theme.palette.neutral.grey};
    }
  `}
`;

export const Navbar: React.FC<INavbar> = ({
  logged,
  onMenuIconClick,
  isSidebarOpen
}) => {
  const { t, i18n } = useTranslation();
  const handleLangChange = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };
  const { loggedUserData } = useAccount();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<IUser>();

  const handleGetUserData = useCallback(async () => {
    const { data } = await getUser(loggedUserData.id);
    setUserData(data);
  }, [loggedUserData.id]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  const logout = async () => {
    setIsLoading(true);
    try {
      await logoutUser();
      localStorage.clear();
      navigate('/login');
    } catch {
      setIsLoading(false);
    }
  };

  const profileDropdownItems = [
    {
      label: t('nav.profile'),
      onClick: () => {
        navigate('/app/my-profile');
      },
      icon: faIdBadge
    },
    {
      label: t('nav.logout'),
      onClick: logout,
      icon: faIdBadge
    }
  ];

  useEffect(() => {
    if (localStorage.getItem('lang')) return;
    handleLangChange('pl');
  }, []);

  return (
    <Wrapper px={2} alignItems="center" justifyContent="space-between">
      <TabletBox>
        <LogoIcon />
      </TabletBox>

      <MenuToggleIcon onClick={onMenuIconClick} />

      <TabletBox>
        <Menu
          view="vertical"
          onMenuIconClick={onMenuIconClick}
          items={navItems}
        />
      </TabletBox>

      <Flex mr={10} alignItems="center">
        <Box mr={5}>
          <Notifications />
        </Box>

        <Dropdown items={profileDropdownItems}>
          <Avatar
            border
            url={`${process.env.REACT_APP_API_URL}${userData?.profile?.photo}`}
          />
          <P variant="body" mx={2}>
            {loggedUserData.firstName} {loggedUserData.lastName}
          </P>
        </Dropdown>
      </Flex>
    </Wrapper>
  );
};
