import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import styled from 'styled-components';
import { theme } from 'styles';

const StyledButton = styled.button<{ disabled?: boolean }>`
  background: transparent;
  ${({ disabled }) => (disabled ? 'cursor:not-allowed;' : '')}
`;

interface Props {
  onClick?: () => void;
  locked?: boolean;
  active?: boolean;
  disabled?: boolean;
}

export const PadlockButton: React.FC<Props> = ({
  onClick,
  locked,
  active = true,
  disabled
}) => {
  return (
    <StyledButton disabled={disabled} type="button" onClick={onClick}>
      <Flex flexDirection="row" justifyContent="center">
        {locked ? (
          <FontAwesomeIcon
            icon={faLock}
            size="2x"
            color={
              active
                ? theme.palette.accent.orange
                : theme.palette.neutral.greyButton
            }
          />
        ) : (
          <FontAwesomeIcon
            icon={faLockOpen}
            size="2x"
            color={
              active
                ? theme.palette.accent.green
                : theme.palette.neutral.greyButton
            }
          />
        )}
      </Flex>
    </StyledButton>
  );
};
