import {
  IAuditGroup,
  IAuditScore,
  IGroupsAttributes
} from 'types/audits';
import { IRatingScaleButton } from 'types/ratingScales';

export const findGroupToRadarChart = (
  groups: ((IGroupsAttributes & Partial<IAuditGroup>) | IAuditGroup)[],
  radarChartOrderNumber: string,
  orderNumberCandidate?: string
): (IGroupsAttributes & Partial<IAuditGroup>) | IAuditGroup | undefined => {
  let foundGroup;

  groups.map((group, i) => {
    const orderNumber = `${orderNumberCandidate || ''}${i}.`;

    if (radarChartOrderNumber === orderNumber) {
      foundGroup = group;
    } else {
      const foundSubGroup = findGroupToRadarChart(
        ('groups' in group ? group.groups : group.groups_attributes) || [],
        radarChartOrderNumber,
        orderNumber
      );

      if (foundSubGroup) {
        foundGroup = foundSubGroup;
      }
    }
  });

  return foundGroup;
};

interface Args {
  groups: ((IGroupsAttributes & Partial<IAuditGroup>) | IAuditGroup)[];
  radarChartOrderNumber: string;
  scores: Partial<IAuditScore>[];
  buttons: IRatingScaleButton[];
}

export const parseGroupsToRadarChart = ({
  groups,
  radarChartOrderNumber,
  scores,
  buttons
}: Args) => {
  const foundGroup = findGroupToRadarChart(groups, radarChartOrderNumber);

  const counts: { [key: string]: number } = {};

  if (foundGroup) {
    const foundGroupGroups =
      'groups' in foundGroup ? foundGroup.groups : foundGroup.groups_attributes;

    foundGroupGroups?.map((foundGroupGroup, index) => {
      const criteria =
        'criteria' in foundGroupGroup
          ? foundGroupGroup.criteria
          : foundGroupGroup.criteria_attributes;

      let averageScore = 0.05;

      criteria?.map((criterion) => {
        const ratingButtonId = scores.find(
          (score) => score.audit_criterion_id === criterion.id
        )?.rating_button_id;

        const value = buttons.find(
          (button) => button.id === ratingButtonId
        )?.value;

        if (value) {
          averageScore = averageScore + value;
        }
      });

      counts[foundGroupGroup.nr || index] = averageScore;
    });
  }

  return counts;
};
