import { Flex, Line } from 'components';
import { H3, P } from 'components/Typography/Typography';
import {
  Button,
  FieldLabel,
  Radio,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { Controller, useFormContext } from 'react-hook-form';
import { IOption } from 'components/_form/Select/Select';
import { IAuditForm, IPostAuditFormRequest } from 'types/audits';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClockRotateLeft,
  faCopy,
  faDownload,
  faEdit,
  faPersonCircleCheck,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { dateHoursFormat } from 'constants/dateFormats/dateHourFormat';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { PadlockButton } from 'components/_form/PadlockButton/PadlockButton';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  NestedSelect,
  IOption as OptionForSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { getAreas } from 'api/areas';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';
import { getUsers } from 'api/user';
import { getPractices } from 'api/methodologies';
import {
  makeMethodologyPracticeTree,
  parseMethodologyPracticeTreeForSelect
} from 'utilities/practices';
import { fullName } from 'utilities/fullName';
import { RatingScaleButtons } from 'components/RatingScaleButtons/RatingScaleButtons';
import { IRatingScale } from 'types/ratingScales';
import { CopyForm } from '../CopyForm/CopyForm';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { NotesModal } from 'components/NotesModal/NotesModal';

interface Props {
  ratingScales: IRatingScale[];
  selectedAuditForm?: IAuditForm;
  setSelectedAuditForm: Dispatch<SetStateAction<IAuditForm | undefined>>;
  showCopyButton?: boolean;
}

export const BasicSettings: React.FC<Props> = ({
  ratingScales,
  selectedAuditForm,
  setSelectedAuditForm,
  showCopyButton
}) => {
  const [areas, setAreas] = useState<OptionForSelect[]>([]);
  const [usersList, setUsersList] = useState<IOption[]>([]);
  const [methodologyTreeForSelect, setMethodologyTreeForSelect] = useState<
    OptionForSelect[]
  >([]);
  const [showCopyForm, setShowCopyForm] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const ratingScaleOptions = ratingScales.map((ratingScale) => ({
    label: ratingScale.name,
    value: String(ratingScale.id),
    buttons: ratingScale.buttons,
    button_type: ratingScale.button_type,
    border: ratingScale.border
  }));

  const { t } = useTranslation();

  const {
    control,
    register,
    getValues,
    formState: { errors }
  } = useFormContext<IPostAuditFormRequest['audit_form']>();

  const loadOptions = useCallback(async () => {
    const { data: areasData } = await getAreas();

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );

    const { data } = await getUsers();

    setUsersList(
      data.map((user) => ({
        label: fullName(user.profile),
        value: String(user.id)
      }))
    );

    const { data: practicesData } = await getPractices();

    const methodologyObject = makeMethodologyPracticeTree(practicesData);

    const treeForSelect =
      parseMethodologyPracticeTreeForSelect(methodologyObject);
    setMethodologyTreeForSelect(treeForSelect);
  }, []);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  return (
    <>
      <H3 variant="h3" color="coloured" mt={1}>
        {t('administrationAuditsView.basicSettings')}
      </H3>
      <Line />
      <Flex flexDirection="column" gap="10px">
        <Flex flexDirection={['column', 'row']} alignItems="center" gap="25px">
          <Flex width={['100%', '35%']} alignItems="start" gap="15px">
            <Controller
              control={control}
              name="practice_id"
              render={({ field: { onChange, value } }) => (
                <NestedSelect
                  width="100%"
                  label={t(
                    'administrationAuditsView.pinToFormMethodologyPracticeArea'
                  )}
                  selectedOptions={methodologyTreeForSelect
                    .map((item) => item.options || [])
                    .flat()
                    .filter((option) => option?.value === value)}
                  setSelectedOptions={(newOption) => {
                    onChange(newOption[0]?.value);
                  }}
                  options={methodologyTreeForSelect}
                  isOneOptionSelect={true}
                  error={t(errors?.practice_id?.message || '')}
                />
              )}
            />
            {showCopyButton && (
              <Flex mt="20px">
                <Button
                  variant="grey"
                  bordered
                  transparent
                  onClick={() => {
                    setShowCopyForm((prevShowCopyForm) => !prevShowCopyForm);
                  }}
                >
                  <Flex alignItems="center" gap="5px">
                    <FontAwesomeIcon icon={faCopy} /> {t('common.copy')}
                  </Flex>
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex width={['100%', '35%']} flexDirection="column">
            <FieldLabel>
              {t('administrationAuditsView.codeAndFormName')}
            </FieldLabel>
            <Flex gap="15px">
              <TextField
                {...register('name')}
                error={t(errors?.name?.message || '')}
              />
              <Button
                variant="eucalyptus"
                icon={<FontAwesomeIcon icon={faEdit} size="xl" />}
              />
            </Flex>
          </Flex>
          <Flex width={['100%', '30%']} justifyContent="space-between" px={2}>
            <Flex flexDirection="column" alignItems="center">
              <FieldLabel>{t('administrationAuditsView.version')}</FieldLabel>
              <P variant="h3" mt={2}>
                1
              </P>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <FieldLabel whiteSpace="nowrap">
                {t('administrationAuditsView.createDate')}:
              </FieldLabel>
              <P variant="body" mt={2}>
                {format(new Date(), dateHoursFormat)}
              </P>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <FieldLabel whiteSpace="nowrap">
                {t('administrationAuditsView.lastChange')}:
              </FieldLabel>
              <P variant="body" mt={2}>
                {format(new Date(), dateHoursFormat)}
              </P>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection={['column', 'row']} width="100%" gap="25px">
          <Controller
            control={control}
            name="area_ids"
            render={({ field: { onChange, value } }) => (
              <NestedSelect
                label={t('administrationAuditsView.usedInAreas') as string}
                selectedOptions={flattenOptions(areas).filter(
                  (option) => option?.value && value?.includes(option?.value)
                )}
                setSelectedOptions={(newOptions) => {
                  onChange(
                    flattenOptions(newOptions).map(
                      (newOption) => newOption.value
                    )
                  );
                }}
                options={areas}
                width={['100%', '35%']}
              />
            )}
          />

          <Flex width={['100%', '45%']} alignItems="start" gap="10px">
            <Controller
              control={control}
              name="rating_scale_id"
              render={({ field: { onChange, value } }) => {
                const selectedRatingScale = ratingScaleOptions.find(
                  (ratingScale) => ratingScale.value === value
                );
                return (
                  <Flex
                    width="100%"
                    gap="5px"
                    flexDirection={['column', 'row']}
                  >
                    <Select
                      label={t('administrationAuditsView.ratingScale')}
                      selectedOptions={selectedRatingScale}
                      onChange={(val: IOption) => {
                        onChange(val.value);
                      }}
                      options={ratingScaleOptions}
                      withoutMargin
                      error={t(errors?.rating_scale_id?.message || '')}
                    />
                    <Flex
                      flexDirection="column"
                      minWidth="200px"
                      minHeight="60px"
                      overflow="hidden"
                    >
                      <FieldLabel>
                        {t('administrationAuditsView.ratingScalePreview')}
                      </FieldLabel>
                      <Flex height="35px">
                        <RatingScaleButtons
                          buttons={selectedRatingScale?.buttons || []}
                          showImages={
                            selectedRatingScale?.button_type === 'picture'
                          }
                          bordered={selectedRatingScale?.border}
                          borderRadius
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                );
              }}
            />
          </Flex>
          <Flex width={['100%', '20%']} flexDirection={['column', 'row']}>
            <TextField
              width={['100%', '80px']}
              label={t('administrationAuditsView.goal')}
              {...register('goal')}
              error={t(errors?.goal?.message || '')}
            />

            <Flex alignItems="center" flexDirection="column" mx="auto">
              <FieldLabel width="fit-content">
                {t('administrationAuditsView.goodAboveBelow')}
              </FieldLabel>
              <Flex gap="15px" mt={2}>
                <Radio label="≥" value="(>=)" {...register('goal_range')} />
                <Radio label="≤" value="(<=)" {...register('goal_range')} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="25px" flexDirection={['column', 'row']}>
          <Flex width={['100%', '15%']}>
            <Controller
              control={control}
              name="author_id"
              render={({ field: { onChange, value } }) => (
                <Select
                  disabled
                  label={t('administrationAuditsView.createdBy')}
                  selectedOptions={usersList.find(
                    (userOption) => userOption.value === value
                  )}
                  onChange={(option: IOption) => {
                    onChange(option.value);
                  }}
                  options={usersList}
                />
              )}
            />
          </Flex>

          <Flex width={['100%', '40%']}>
            <Controller
              control={control}
              name="person_authorized_to_edit_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('administrationAuditsView.authorizedToEdit')}
                  selectedOptions={
                    usersList.filter((userOption) =>
                      value?.includes(userOption.value)
                    ) || []
                  }
                  isMulti
                  onChange={(val) => {
                    onChange(
                      Array.isArray(val) ? val.map(({ value }) => value) : []
                    );
                  }}
                  options={usersList}
                  withoutMargin
                />
              )}
            />
          </Flex>

          <Flex flexDirection="column">
            <FieldLabel>{t('administrationAuditsView.active')}</FieldLabel>
            <Flex mt={2}>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <SwitchButton
                    value={value === 'active'}
                    onChange={(checked) => {
                      onChange(checked ? 'active' : 'inactive');
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column" gap="5px">
            <FieldLabel>{t('administrationAuditsView.closed?')}</FieldLabel>
            <PadlockButton />
          </Flex>

          <Flex flexDirection="column">
            <FieldLabel whiteSpace="nowrap">
              {t('administrationAuditsView.requireConfirmation')}
            </FieldLabel>
            <Flex mt={2} gap="10px">
              <SwitchButton />
              <Button>
                <Flex gap="5px">
                  <FontAwesomeIcon size="xl" icon={faPersonCircleCheck} />
                  {t('administrationAuditsView.panel')}
                </Flex>
              </Button>
            </Flex>
          </Flex>

          <Flex alignItems="end" gap="5px" ml="auto" pr={2}>
            <Button bordered transparent minHeight="50px" width="50px">
              <FontAwesomeIcon size="2x" icon={faUpload} />
            </Button>
            <Button bordered transparent minHeight="50px" width="50px">
              <FontAwesomeIcon size="2x" icon={faDownload} />
            </Button>
            <Button
              disabled={!selectedAuditForm}
              bordered
              transparent
              minHeight="50px"
              width="50px"
              onClick={() => setIsNotesModalOpen(true)}
            >
              <FontAwesomeIcon size="2x" icon={faClipboard} />
            </Button>
            <Button bordered transparent minHeight="50px" width="50px">
              <FontAwesomeIcon size="2x" icon={faClockRotateLeft} />
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <CopyForm
        isOpen={showCopyForm}
        onCancelClick={() => {
          setShowCopyForm(false);
        }}
        defaultValues={getValues()}
        methodologyTreeForSelect={methodologyTreeForSelect}
        areas={areas}
        usersList={usersList}
      />

      <NotesModal
        isOpen={isNotesModalOpen}
        onCancelClick={() => {
          setIsNotesModalOpen((prevIsNotesModalOpen) => !prevIsNotesModalOpen);
        }}
        noteableId={selectedAuditForm?.id}
        noteableType="AuditForm"
        notes={selectedAuditForm?.notes}
        setNotes={(newNotes) =>
          setSelectedAuditForm?.(
            (prevSelectedItemData) =>
              prevSelectedItemData && {
                ...prevSelectedItemData,
                notes: newNotes
              }
          )
        }
      />
    </>
  );
};
