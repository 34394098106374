import { IPractice, Methodologies, Practice } from 'types/methologies';
import { IOption as OptionForSelect } from 'components/_form/NestedSelect/NestedSelect';

export const makeMethodologyPracticeTree = (data: IPractice[]) => {
  const methodologyObject: Methodologies = {};
  data.map((practice) => {
    let practiceObject: Practice[];
    if (methodologyObject[practice.methodology.id]) {
      practiceObject = [
        ...methodologyObject[practice.methodology.id].practice,
        {
          id: practice.id,
          name: practice.name
        }
      ];
    } else {
      practiceObject = [
        {
          id: practice.id,
          name: practice.name
        }
      ];
    }

    methodologyObject[practice.methodology.id] = {
      id: practice.methodology.id,
      name: practice.methodology.name,
      practice: practiceObject
    };
  });

  return methodologyObject;
};

export const parseMethodologyPracticeTreeForSelect = (data: Methodologies) => {
  let options: OptionForSelect[] = [];
  Object.keys(data).map((methodologyId) => {
    const methodology = data[Number(methodologyId)];
    const nestedOptions: OptionForSelect[] = [];
    methodology.practice.map((practice) => {
      nestedOptions.push({
        label: practice.name,
        value: practice.id.toString()
      });
    });
    options.push({
      value: methodology.id.toString(),
      label: methodology.name,
      options: nestedOptions
    });
  });

  return options;
};

interface ItemWithMethodologyAtrrs {
  methodology: {
    id: number;
    name: string;
  };
  practice: {
    id: number;
    name: string;
    state: string;
  };
}

interface IParsedData<TItem> {
  name: string;
  id: number;
  practices: {
    name: string;
    id: number;
    items: TItem[];
  }[];
}

export const parseItemsToMethodologyTree = <
  TItem extends ItemWithMethodologyAtrrs
>(
  items: TItem[]
) => {
  const methodologies: IParsedData<TItem>[] = [];

  items.map((item) => {
    !methodologies.find(
      (findMethodology) => findMethodology.id === item.methodology?.id
    ) && methodologies.push({ ...item.methodology, practices: [] });
  });

  items.map((item) => {
    methodologies.map((methodology) => {
      if (methodology?.id === item.methodology?.id) {
        const foundPractice = methodology.practices.find(
          (findPractice) => findPractice.id === item.practice?.id
        );

        if (!foundPractice) {
          methodology.practices = [
            ...(methodology.practices || []),
            { ...item.practice, items: [] }
          ];
        }
      }
    });
  });

  methodologies.map((methodology) => {
    methodology.practices.map((methodologyPractice) => {
      items.map((item) => {
        if (
          methodology?.id === item.methodology?.id &&
          methodologyPractice?.id === item?.practice?.id
        ) {
          methodologyPractice.items = [...methodologyPractice.items, item];
        }
      });
    });
  });

  return methodologies;
};
