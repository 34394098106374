import MDEditor from '@uiw/react-md-editor';
import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { Button } from 'components/_form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends IGenericModal {
  value: string;
  setValue: (text?: string) => void;
}

export const DescriptionModal = ({
  isOpen,
  onCancelClick,
  value,
  setValue
}: Props) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>('');
  const { t } = useTranslation();

  const handleSubmit = () => {
    setValue(currentValue);
    onCancelClick();
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Modal
      gridTemplateColumns="60%"
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('tasks.taskDescription')}
      mainButton={{
        action: handleSubmit,
        variant: 'eucalyptus',
        label: t('buttons.saveAndClose')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column">
        <div data-color-mode="light">
          <MDEditor
            height={200}
            preview="edit"
            extraCommands={[
              {
                name: 'save',
                keyCommand: 'save',
                value: 'save',
                icon: (
                  <Button
                    variant="eucalyptus"
                    onClick={() => {
                      setValue(currentValue);
                    }}
                  >
                    {t('common.save')}
                  </Button>
                )
              }
            ]}
            value={currentValue}
            onChange={setCurrentValue}
          />
        </div>
      </Flex>
    </Modal>
  );
};
