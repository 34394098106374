import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  border-radius: 50px;
  height: 40px;
  width: 40px;
  justify-content: center;
`;

const StyledButton = styled.button`
  font-size: 18px;

  :focus-visible {
    outline: 1px solid ${({ theme }) => theme.palette.neutral.dark};
  }
`;

export const Notifications = () => {
  return (
    <Wrapper p={2}>
      <StyledButton>
        <FontAwesomeIcon icon={faBell} size="lg" />
      </StyledButton>
    </Wrapper>
  );
};
