import { Tab } from 'react-tabs';
import styled from 'styled-components';
import { device } from 'styles/theme';

export const CustomTab = styled(Tab)<{ bgColor?: string; color?: string }>`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  margin-right: 10px;
  border-radius: 0;
  padding: 8px;
  cursor: pointer;

  &.is-selected {
    background-color: ${({ theme, bgColor }) =>
      bgColor ? bgColor : theme.palette.primary.main};
    color: ${({ theme, color }) =>
      color ? color : theme.palette.neutral.white};
  }

  @media ${device.tablet} {
    padding: 4px;
    margin-right: 5px;
  }
`;
