import { Flex } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 8px;
  padding: 14px;
  overflow: visible;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;

export const CollapseButton = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.palette.neutral.light};
  color: ${({ theme }) => theme.palette.neutral.grey};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.palette.neutral.grey};
  }
`;
