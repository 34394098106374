const common = {
  privacyPolicy: 'Политика конфиденциальности',
  regulamin: 'Условия и положения',
  email: 'электронный адрес',
  first_name: 'имя',
  last_name: 'фамилия',
  username: 'Имя пользователя',
  anErrorOccurred: 'Произошла ошибка',
  addedEvaluation: 'Оценка добавлена',
  cookiesMessage:
    'Этот сайт использует функциональные файлы cookie, чтобы обеспечить Вам максимально эффективное использование нашей Платформы.\nПродолжая использовать этот сайт, Вы соглашаетесь на использование этих файлов cookie.',
  other: 'Другие',
  save: 'Сохранить',
  language: 'Язык',
  yes: 'да',
  close: 'Закрыть',
  no: 'нет',
  loading: 'Авторизация...',
  'leave blank if all company': 'оставьте пустым, если вся компания',
  uncheck: 'Снять флажок',
  cancel: 'Отменить',
  message: 'сообщение',
  success: 'Операция прошла успешно',
  justificationRequired: 'Требуется обоснование',
  showAll: 'Показать всё',
  add: 'Dodaj',
  showAlsoMedia: 'Также показывать медиафайлы',
  addImage: 'Добавить изображение',
  'document/file': 'Документ / файл',
  show: 'Показать',
  copy: 'Копировать',
  today: 'Сегодня',
  fieldRequired: 'Это поле обязательно к заполнению',
  notes: 'Примечания',
  makeAuditsToUnlock: 'Проведите аудиты, чтобы разблокировать',
  installApp: 'Установить приложение',
  pressShareButton: 'Нажмите кнопку Поделиться',
  pressAddHomeScreen: 'Нажмите Добавить на главный экран'
};

const nav = {
  'my-desktop': 'Мой рабочий стол',
  tasks: 'Задачи',
  evaluation: 'Оценка',
  analytics: 'Аналитика',
  documents: 'Документы',
  administration: 'Админ',
  profile: 'Профиль',
  logout: 'Выйти'
};

const auth = {
  login: 'авторизоваться',
  register: 'регистрироваться',
  password: 'пароль',
  newPassword: 'новый пароль',
  passwordConfirm: 'повторить пароль',
  newPasswordConfirm: 'повторить новый пароль',
  forgotPass: 'Забыли пароль?',
  accountless: 'У вас нет учетной записи?',
  emailRequired: 'Требуется адрес электронной почты',
  badEmailFormat: 'Неверный формат адреса электронной почты',
  passwordRequired: 'Необходим пароль',
  passMinVal: 'Пароль должен быть длиной не менее 6 символов',
  passMaxVal: 'Пароль должен быть длиной не больше 18 символов',
  passDiffErr: 'Пароли не совпадают',
  acceptRegulamin: 'Примите условия использования',
  password_confirmation: 'подтверждение пароля',
  send_email: 'Отправить письмо',
  verify_your_email: 'Подтвердите Ваш электронный адрес',
  passwordChanged: 'Пароль был изменён',
  changePassword: 'Изменить пароль',
  saveNewPassword: 'Сохранить новый пароль',
  reset: 'Сбросить',
  writeRegisteredEmail:
    'Введите адрес электронной почты, зарегистрированный в системе',
  backToLoginPage: 'Вернуться к экрану входа в систему',
  succeedSendResetEmail:
    'На этот адрес вы получите ссылку для сброса пароля, если он зарегистрирован в системе.'
};

const profile = {
  myProfile: 'Мой профайл',
  profile: 'Профайл',
  basicInformation: 'Основная информация',
  changeEmailAddress: 'Изменить адрес электронной почты',
  changeUsername: 'Изменить имя пользователя',
  areaPermissions: 'Доступ к участкам',
  mailNotifications: 'Уведомления по электронной почте',
  idCode: 'Код ID',
  userCompany: 'Компания пользователя',
  name: 'Имя',
  secondname: 'Отчество',
  surname: 'Фамилия',
  phone: 'Мобильный телефон',
  landlinePhone: 'Стационарный телефон',
  mainArea: 'Основной участок',
  myRoleInCompany: 'Мои роли в компании',
  employeeRoles: 'Функции сотрудника',
  userAreaPermissions:
    'Пользователь имеет право редактировать на следующих участках:',
  userHasRiparianAreasViewPermission:
    'Пользователь имеет право просматривать родительские участки',
  userHasNeighboringAreasViewPermission:
    'Пользователь имеет право просматривать одноранговые участки',
  overallSettings: 'Общие настройки',
  sendMailAfterSystemNotifications:
    'Отправка уведомлений по электронной почте о получении уведомлений в системе',
  sendMailStraightAfter:
    'отправить электронное письмо сразу после получения уведомления',
  groupNotificationsAndSendEvery:
    'группировать уведомления и отправлять электронное письмо каждые (минут)',
  sendTasksStatusSummary: 'Отправлять сводки статуса моих задач',
  never: 'никогда',
  everyday: 'каждый день',
  by: 'в',
  everyWeek: 'каждую неделю',
  on: 'в',
  everyMonth: 'каждый месяц',
  'ds.': 'дн.',
  notificationsAboutplannedTasks: 'Напоминания о запланированных задачах',
  sendMailFutureNotifications:
    'Отправлять уведомления по электронной почте о предстоящих и запланированных задачах',
  beforeStart: 'Перед началом',
  minutes: 'минут(ы)',
  hours: 'часа(ов)',
  days: 'дня(ей)',
  before: 'заранее',
  beforeFinish: 'Перед окончанием',
  notifyAboutPricesAndAuditsInMyAreas:
    'Уведомлять меня (как владельца участка) об оценках и аудитах на моём участке',
  mailNotificationsAboutPDCATasksStatus:
    'Уведомления по электронной почте об изменении статуса PDCA / DMAIC задач',
  sendMailNotificationsWhenPDCATasksStatusChangedWhichIAm:
    'Отправлять уведомления по электронной почте, когда кто-то изменит статус PDCA / DMAIC задачи, которой я являюсь:',
  byTheAuthor: 'Автором',
  responsibleBy: 'Ответственным',
  participantBy: 'Участником',
  StatusP: 'Статус P',
  StatusD: 'Статус D',
  StatusC: 'Статус C',
  StatusA: 'Статус A',
  displayPreferences: 'Настройки отображения',
  dateFormat: 'Формат даты',
  separators: 'Разделители',
  decimal: 'десятичный',
  thousands: 'тысяч',
  firstWeekDay: 'Первый день недели',
  default: 'По умолчанию',
  imageChange: 'Измени фото',
  imageRemove: 'Удалить фото',
  addImage: 'Добавить фото',
  deleteUser: 'Удалить пользователя',
  recoverDefault: 'Сброс и восстановление настроек по умолчанию',
  'Password change error': 'Ошибка смены пароля',
  'Your password cannot be:': 'Ваш пароль не может быть:',
  'such as your username or similar':
    'такой как или похожий на ваше имя пользователя',
  'such as your first and/or last name': 'такой как Ваше имя и/или фамилия',
  'company name or a variation thereof': 'названием компании или его вариантом',
  'the names of your children, family, dogs and cats':
    'именами ваших детей, семьи, собак и кошек',
  'a string of consecutive letters and/or keyboard characters, e.g. qwertyuiop, abcd1234, 12345678, 09876543...':
    'последовательными буквами и/или символами на клавиатуре, напр. йцукенгшщзхъ, qwertyuiop, aбвг1234, 12345678, 09876543...',
  'be on the list of commonly known passwords':
    'быть в списке общеизвестных паролей',
  'Instead, we encourage you to use phrases that are easy to remember, e.g.':
    'Мы рекомендуем вам использовать фразы, которые легко запомнить, напр.',
  "My pink dog is in love with my neighbor's green parrot":
    'Моя розовая собака влюблена в зеленого попугая моего соседа'
};

const desktop = {
  my: 'Мои',
  solvingProblems: 'Решение проблем',
  audits: 'Аудиты'
};

const tasks = {
  savedFilters: 'Сохранённые фильтры',
  defaultFilter: 'Фильтр по умолчанию',
  defaultFilterNotExists: 'Нет фильтров по умолчанию',
  savedFiltersNotExists: 'Нет сохранённых фильтров',
  setAsDefault: 'Установить по умолчанию',
  delete: 'Убрать',
  addTask: 'Новая задача',
  editTask: 'Редактировать задачу',
  exportToExcel: 'Экспорт в Excel',
  tasksCount: 'Количество задач',
  criteriaConnectedTasksList: 'Перечень задач, связанных с критерием',
  realizationTimes: 'время выполнения',
  chart: 'График',
  calendar: 'Календарь',
  chosenLines: 'выбранных строк',
  toArchive: 'Переместить в архив',
  exportToCalendar: 'Экспорт в календарь',
  remindLatecomers: 'Напомнить опоздывающим',
  sendNotificationsToSelectedOnes: 'Отправлять уведомления выбранным',
  'taskCancelationConfirm?': 'Вы уверены, что хотите отменить эту задачу?',
  'archiveTaskConfirmation?': 'Архивировать выбранные задачи?',
  //filters
  area: 'участок',
  withAnAsterisk: 'Звёздочка',
  statusPDCA: 'Статус PDCA',
  PDCANew: 'Новые',
  responsible: 'Ответственный',
  author: 'Автор',
  teamsAndGroups: 'Команды и группы',
  taskPriorities: 'Приоритеты задач',
  typesOfTasks: 'Виды задач',
  idCodeName: 'Код ID, название',
  created: 'Дата создания',
  startDate: 'Дата начала',
  timeLimitForCompletion: 'Срок реализации',
  implementation: 'Реализация',
  hidden: 'Скрытые',
  plan: 'Plan',
  starMy: 'Моя',
  starMyTeams: 'Моих команд',
  starAsterisk: 'Моих участков',
  starWithout: 'Без звёздочки',
  activeFilter: 'Активные',
  archivedFilter: 'Архивированные',
  canceledFilter: 'Отменённые',
  additionalPermissions: 'Дополнительные разрешения',
  'Can perform some administrative tasks':
    'Может выполнять выбранные административные задачи',
  'Disabling and enabling user account activity':
    'Отключение и включение учетных записей пользователей',
  deletingTasksInSubordinatesAreas: 'Удаление задач на суб-участках',
  deletingTasksInAllAreas: 'Удаление задач на всех участках',
  'Access to the translation table': 'Доступ к таблице переводов',
  'Translation table preview': 'Просмотр таблицы переводов',
  languagesEditing: 'Редактирование языков',
  // actions
  filtersSaved: 'Настройки фильтра сохранены',
  // table
  'task.table.star': '★ Звёздочка',
  'task.table.practice': 'Практика',
  'task.table.name': 'Название',
  'task.table.taskType': 'Вид задачи',
  'task.table.responsible': 'Ответственный',
  'task.table.area': 'Участок',
  'task.table.status': 'Статус',
  'task.table.deadlineRealisation': 'Срок реализции',
  'task.table.timeRealisation': 'Время реализации',
  'task.table.daysCount': 'Кол-во дней',
  // table tooltips
  'task.table.tooltips.responsible':
    'Нажмите, чтобы просмотреть задачи этого человека',
  //form
  taskName: 'Название задачи',
  Priority: 'Приоритет',
  TaskType: 'Вид задачи',
  participants: 'Участники',
  'Teams, shifts, groups': 'Команды и группы',
  carryOut: 'Реализовать',
  planning: 'Планирование',
  plannedStartDate: 'Планируемая дата начала',
  plannedTime: 'Планируемое время',
  'realizationOnly (D)': 'Ролько реализция (D)',
  taskDescription: 'Описание задачи',
  taskParticipants: 'Участники задайи',
  teamLeader: 'Лидер команды',
  addTeamMember: 'Добавить члена команды',
  teamManager: 'Менеджер команды',
  addFromTeamOrGroup: 'Добавить из команды или группы',
  teamMembers: 'Члены команды',
  'role/function': 'Роль / функция',
  starForTeamInstruction:
    'Включить звёздочку для всех членов команды этой задачи. Вновь добавленные члены команды также будут иметь включенную звёздочку.',
  'turn on the star?': 'Отметить звёздочкой?',
  forMe: 'Для меня',
  forTeam: 'Дла команды',
  forArea: 'Дла участка',
  'Area(s)': 'Участок(ки)',
  'justification for the star': 'Обоснование звёздочки',
  'add another file/document': 'Добавить еще один файл / документ',
  photos: 'Фото',
  TaskPhotosAndDocuments: 'Фото и документы задачи',
  documents: 'Документы',
  notebook: 'Примечания',
  commentTitle: 'Комментарии',
  'removeTask?': 'Вы хотите удалить эту задачу?',
  notifyParticipants: 'отправлять уведомления участникам',
  taskNameRequired: 'Название задачи необходимое',
  taskDescriptionRequired: 'Описание задачи необходимое',
  'hrs.': 'часа(ов)',
  'min.': 'минут',
  active: 'Активные',
  archived: 'Архивированные',
  canceled: 'Отменённые'
};

export const administration = {
  administrationLabel: 'Админ',
  company: 'Компания',
  companySettingsDescription:
    'Управлять данными компании, лицензиями, разрешениями',
  employeesAndUsers: 'Сотрудники и пользователи',
  employeesAndUsersSettingsDescription:
    'Управлять пользователями, их ролями, разрешениями и доступом и т.д.',
  areasAndOrganizationStructure: 'Участки и структура организации',
  areasAndOrganizationStructureSettingsDescription:
    'Управлять участками и структурой организации',
  tasks: 'Задачи',
  tasksSettingsDescription:
    'Управлять задачами, их видами, раскраской, выделением',
  audits: 'Аудиты',
  auditsSettingsDescription: 'Создать и управлять формами аудита в системе',
  translations: 'Переводы',
  translationsSettingsDescription:
    'Добавить и менять языки, редактировать переводы, доступ для переводчиков',
  otherSettingsListsDictionaries: 'Прочие настройки, списки, словари',
  companyData: 'Данные компании',
  licencesAndPermission: 'Лицензии и доступ',
  usersPermission: 'Доступ пользователей',
  defaultSettings: 'Настройки по умолчанию',
  storage: 'Хранилище',
  contactAndHelp: 'Контакты и поддержка',
  fullCompanyName: 'Полное название компании',
  shortCompanyName: 'Краткое название компании',
  'unit/workplace': 'Бизнес-подразделение / сайт',
  streetAndNumber: 'Улица и номер',
  postCode: 'Почтовый индекс',
  city: 'Город',
  country: 'Страна',
  NIPNumber: 'Идентификационный номер налогоплательщика',
  KRSNumber: 'Регистрационный номер',
  website: 'Вебсайт компании',
  officeContactData: 'КОНТАКТНЫЕ ДАННЫЕ ОФИСА',
  emailAddress: 'Адрес электронной почты',
  contactPeopleData: 'ДАННЫЕ КОНТАКТНЫХ ЛИЦ',
  fullname: 'ФИО',
  addPerson: 'Добавить лицо',
  sendLogo: 'Добавить логотип компании',
  materialsLicensesAndPermission: 'ЛИЦЕНЗИИ, ДОСТУП К МАТЕРИАЛАМ И МЕТОДИКАМ',
  materialsPermissionSettings: 'Настройки доступа к материалам:',
  global: 'глобальные',
  byStructure: 'по структуре',
  total: 'суммарно',
  admin: 'админ',
  'usr.': 'пользов.',
  guest: 'гость',
  usersLicensesCount: 'Количество лицензий (пользователей)',
  usedLicensesCount: 'Количество использованных лицензий',
  licenseExpirationDate: 'Срок действия лицензии',
  extendOrChange: 'Продлить или изменить',
  availableBlocks: 'Доступные этапы',
  customizeBlocks: 'Настройка кубиков',
  availableVersions: 'Доступные версии',
  selectAll: 'Выбрать все:',
  toEveryone: 'Применить ко всем:',
  materialsLanguages: 'ЯЗЫКИ МЕТОДИЧЕСКИХ МАТЕРИАЛОВ',
  license: 'ЛИЦЕНЗИЯ',
  update: 'Обновить',
  leadershipDevelopmentAndChangeManagement:
    'Развитие Лидерства и Управление Изменениями',
  motivationAndProvidingOpportunity: 'Мотивация и Предоставление Возможностей',
  'Processes,Structures,RolesandResponsibilities':
    'Процессы, Структуры, Роли и Ответственности',
  effectiveTeams: 'Эффективные Команды',
  '5S': '5S',
  continuousImprovement: 'Постоянное Совершенствование',
  safetyAndEnvironment: 'Безопасность и Окружающая Среда',
  manufacturingProcessManagement: 'Управление Процессами Производства',
  maintenanceManagement: 'Управление содержанием и техобслуживанием',
  supplyChainManagement: 'Управление Цепочкой Поставок',
  rangeOfAccessToDiffrentUserAccounts:
    'ОБЪЕМ ДОСТУПА ДЛЯ РАЗНЫХ ВИДОВ УЧЁТНЫХ ЗАПИСЕЙ ПОЛЬЗОВАТЕЛЕЙ',
  'Bydefault,eachtypeofuserhasaccesstotheareatowhichheisassignedandtoitssub-areas':
    'По умолчанию каждый вид пользователя имеет доступ к участку, к которому он привязан, и к его суб-участкам, напр.:',
  addedattheGrouplevelhasaccesstoallfactoriesinthestructure:
    'добавленный на уровне Группы имеет доступ ко всем заводам / сайтам во всей структуре,',
  addedatthefactorylevelhasaccesstoallareasofthefactory:
    'добавленный на уровне сайта / завода имеет доступ ко всем участкам этого сайта / завода,',
  'addedatthedepartmentlevelhasaccesstoitsdepartmentanditssub-areas':
    'добавленный на уровне отдела имеет доступ к своему отделу и всем его подразделениям.',
  Usingthecheckboxesbelowyoucanadjustthisscopeofaccess:
    'Этот объем доступа можно настроить с помощью флажков ниже.',
  lawOfView: 'ПРАВО НА ПРОСМОТР',
  lawOfEdit: 'ПРАВО НА РЕДАКТИРОВАНИЕ',
  otherBookmarksInGroup: 'другие заводы Группы',
  holeCompanyAreasTree: 'всё дерево участков компании',
  mineAndEqualAreas: 'свой участок и одноранговые',
  mineAndLevelsBelowAreas: 'свой участок и его суб-участки',
  user: 'пользователь',
  consultant: 'консультант',
  'creating assessments, audits': 'создание оценок, аудитов',
  'adding data (comments, photos) to existing ratings, audits':
    'добавление данных (комментариев, фото) к существующим оценкам, аудитам',
  diagrams: 'графики',
  'plans, reports': 'планы, отчёты',
  notifications: 'уведомления',
  'access to documents, templates, examples':
    'доступ к документам, шаблонам, примерам',
  'adding your own documents, templates, examples':
    'добавление своих документов, шаблонов, примеров',
  'changes in the structure of the company':
    'изменения в структуре участков компании',
  'access to the administration panel': 'доступ к админ-панели',
  'adding, blocking, unblocking users':
    'добавление, блокировка, разблокировка пользователей',
  'making changes to the settings': 'внесение изменений в настройки',
  'The editing right allows you to create assessments, audits, charts, plans, reports and available areas':
    'Право редактировать позволяет создавать оценки, аудиты, графики, планы, отчёты по доступным участкам.',
  restoreDefault: 'Восстановить настройки по умолчанию',
  legend: 'Легенда',
  lackOfAccess: 'нет доступа',
  preview: 'просмотр',
  downloadOption: 'право на скачивание',
  selectedItems: 'выбранные элементы',
  'creating, editing': 'создание, редактирование',
  'FUNCTIONAL USER RIGHTS': 'ФУНКЦИОНАЛЬНЫЕ ПРАВА ПОЛЬЗОВАТЕЛЯ',
  'Uses users middle name': 'Использовать отчество пользователя',
  'USER CODES AND MAIL DOMAINS':
    'КОДЫ ПОЛЬЗОВАТЕЛЕЙ И ДОМЕНЫ ЭЛЕКТРОННОЙ ПОЧТЫ',
  'Data for own users': 'Данные о собственных сотрудниках',
  'Email domain': 'Домен электронной почты',
  'user code': 'Код пользователя',
  'next number': 'следующий номер',
  'DATE FOR PARTNERS AND SUBCONTRACTORS':
    'ДАННЫЕ ДЛЯ КОМПАНИЙ-ПАРТНЁРОВ И ПОДРЯДЧИКОВ',
  companyName: 'Название компании',
  'Feature / Service': 'Предоставляемая функция / услуга',
  LOCATION: 'ЛОКАЛИЗАЦИЯ',
  'The localization parameters set here override the default localization settings assigned to the main application language.':
    'Установленные здесь параметры локализации переопределяют настройки локализации по умолчанию, связанные с основным языком приложения.',
  LocaleID: 'Locale ID',
  currencySymbol: 'Символ валюты',
  'First day of the week': 'Первый день недели',
  'Measuring units': 'Единицы измерения',
  'Calendar of holidays and days off': 'Календарь праздников и выходных',
  color: 'цвет',
  'area(s)': 'Участкок(ки)',
  'Import holidays to the calendar': 'Импортировать праздники в календарь',
  add: 'Добавить',
  'Default language for new users': 'Язык по умолчанию для новых пользователей',
  mainAppLanguage: 'основной язык приложения',
  'Interface languages available': 'Доступные языки интерфейса',
  'Default session duration (minutes)':
    'Продолжительность сеанса по умолчанию (минуты)',
  'Only use corporate email addresses':
    'Использовать только корпоративные адреса электронной почты.',
  'Along with the subscription, disk space for data research and the full amount of space for storing files - photos, documents, attachments, etc. are provided. You can provide any large storage for files on your servers or in the cloud service, providing access data below.':
    'В подписку входит дисковое пространство для баз данных и определённый объём места для хранения файлов – фото, документов, вложений и т.д. Вы можете обеспечить хранение любых больших файлов на своих серверах или в облачном сервисе, предоставив ниже данные для доступа.',
  'Storage for storing files - photos, documents, switches, etc.:':
    'Место для хранения файлов – фото, документов, вложений и т.п.:',
  'Platform providers': 'У поставщика Платформы',
  'own or cloud': 'собственный или облачный',
  'Disk usage': 'Занятость диска',
  'Access data:': 'Данные по доступу:',
  login: 'логин',
  'Check the connection': 'Проверить подключение',
  'Migrate files': 'Перенос файлов',
  'ACCOUNT MANAGER': 'МЕНЕДЖЕР ПО РАБОТЕ С КЛИЕНТОМ',
  'PLATFORM ADMINISTRATOR / TECHNICAL CONTACT':
    'АДМИНИСТРАТОР ПЛАТФОРМЫ / ТЕХНИЧЕСКИЙ КОНТАКТ',
  CONSULTANT: 'КОНСУЛЬТАНТ',
  newPerson: 'Добавить лицо',

  'STRUCTURE OF AREAS': 'СТРУКТУРА УЧАСТКОВ',
  submissive: 'Суб-участок',
  'Add a parent area': 'Добавить родительский участок',
  areaCode: 'Код участка',
  areaOwner: 'Собственник участка',
  'active?': 'Активный?',
  deleteArea: 'Удалить участок',
  name: 'Название',
  description: 'Описание',
  'You add a subarea to:': 'Вы добавляете суб-участок к: ',
  'Add a subarea': 'Добавить суб-участок',

  OnlyUseCorporateEmailAddressesTooltip:
    'Запрещает пользователям предоставлять адреса электронной почты за пределами домена компании (напр., @gmail.com, @mail.ru, ...) в качестве адресов для связи – это означает, что электронные письма, напр. уведомления и ссылки на задачи на Платформе будут отправляться только на корпоративные домены электронной почты компании, перечисленные ниже.',

  stage: 'Этап',
  topics: 'Темы',
  noCompanyData: 'Данные компании отсутствуют',
  check: 'Проверить',
  role: 'Роль'
};

export const translationsView = {
  translations: 'Переводы',
  importTranslations: 'Импортировать перевод',
  importedLanguage: 'Импортируемый язык',
  addLanguage: 'Добавить язык',
  editLanguage: 'Редактировать язык',
  'authorized to edit translations': 'Уполномоченные редактировать переводы',
  updateTranslation: 'Обновить перевод',
  tableHeaders: {
    number: '№',
    idLong: 'Идентификатор',
    id: 'ID',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Адрес электронной почты',
    PE: 'П/Р',
    languagesEditing: 'Редактирование языков',
    company: 'Компания',
    type: 'Вид аккаунта',
    lastLogging: 'Последний вход',
    lastActivity: 'Последняя активность',
    idetificator: 'Идентификатор',
    en: 'английский',
    pl: 'польский',
    un: 'украинский',
    ru: 'русский'
  },
  form: {
    language: 'Язык',
    dateFormat: 'Формат даты',
    localeId: 'LocaleID',
    separators: 'Пазделители',
    decimal: 'десятичный',
    thousandth: 'тысяч',
    country: 'Страна',
    firstWeekDay: 'Первый день недели',
    languageFlag: 'Флаг языка',
    placeForFlag: 'Флаг',
    preview: 'Просмотр',
    activeLanguageInInterface: 'Язык активен в интерфейсе',
    personsAuthorizedToEdit: 'Уполномоченные редактировать язык',
    show: 'Показывать',
    tooltips: {
      language: 'Введите название языка по-английски',
      localeId:
        'Выберите / введите LocaleID, если нужны разные варианты локали, напр. en_GB, en_US. «Показывать» включает видимость локали в раскрывающихся списках выбора языка.',
      flag: 'Загрузите файл .PNG, .GIF или .BMP с флагом размером XXX x YYY пикселей',
      flagFile: 'Загрузите файл с флагом'
    }
  }
};

export const employeesAndUsersView = {
  usersList: 'Список пользователей',
  rolesAndFunctions: 'Роли и функции',
  teamsAndGroups: 'Команды и группы',
  importUsersList: 'Импортировать список пользователей',
  shortInstruction:
    'Краткая инструкция – сохраните шаблон, заполните его, импортируйте файл',
  errorsRaport: 'Сообщение об ошибке',
  addUser: 'Добавить пользователя',
  addTeam: 'Добавить команду',
  add: 'Добавить',
  editRole: 'Изменить роль / функцию',
  messageForTeam: 'Написать команде',
  team: 'Команда',
  leader: 'Лидер',
  specialPermissions: 'Специальные разрешения',
  tableHeaders: {
    active: 'Активный?',
    id: 'ID',
    photo: 'Фото',
    firstName: 'Имя',
    lastName: 'Фамилия',
    emailAddress: 'Адрес эл. почты',
    userName: 'Имя пользователя',
    accountType: 'Вид аккаунта',
    area: 'Участок',
    logo: 'Логотип',
    teamName: 'Название команды',
    leader: 'Лидер',
    company: 'Компания',
    lastLogging: 'Последний вход',
    lastActivity: 'Последняя активность',
    administrativeActivities: 'Админ. активности',
    accountActivity: 'Активность аккаунта',
    deletingTasksInAreas: 'Удаление задач в:',
    subordinate: 'своих участках',
    all: 'всех участках',
    accessToTranslations: 'Доступ к переводам',
    PE: 'П/Р',
    languagesEditing: 'редактирование языков',
    email: 'Адрес эл. почты',
    type: 'Вид аккаунта'
  },
  form: {
    photoPlaceholder: 'Место для фото',
    companyName: 'Название компании',
    firstName: 'Имя',
    secondName: 'Отчество',
    lastName: 'Фамилия',
    emailAddress: 'Адрес эл. почты',
    phone: 'Номер стационарного телефона',
    mobilePhone: 'Номер мобильного телефона',
    userName: 'Имя пользователя',
    password: 'Пароль',
    language: 'Язык',
    mainArea: 'Основной участок',
    roleInCompany: 'Роль в компании',
    usingInAreas: 'Используется на участках',
    usingInAreasHelperText: 'оставьте пустым, если вся компания',
    editorPlaceholder: 'Введите текст...',
    onlyToLeader: 'только лидеру',
    toAllMembers: 'всем членам',
    teamName: 'Название команды',
    id: 'ID',
    teamLeader: 'Лидер команды',
    teamManager: 'Менеджер команды',
    areas: 'Участок(ки)',
    chooseCompany: 'Выберите компанию',
    chooseArea: 'Выберите участок',
    search: 'Поиск',
    filter: 'Фильтр',
    administrative: 'административные',
    translations: 'переводы',
    areYouSureDeleteTeam: 'Вы уверены, что хотите удалить эту команду?',
    edit: 'редактировать'
  }
};

export const administrationTasksView = {
  TASKS: 'ЗАДАЧИ',
  tasksSettings: 'Настройки задач',
  tasksRealizationProgressFollowedBySteps:
    'прогресс выполнения задач отслеживается по шагам',
  'Dates given when confirming PDCA steps can be simple':
    'Даты подтверждения выполнения шагов задачи могут находиться в прошлом',
  'When creating a task, also notify the creator via':
    'При создании задачи также сообщите об этом создателю через',
  'Highlighting tasks in the list':
    'Цвета выделения задач в таблице задач в зависимости от времени до срока реализации',
  '- color (default Yellow) - time to end':
    '– цвет (по умолчанию жёлтый) – оставшееся время',
  '- color (default red) - time to end':
    '– цвет (по умолчанию красный) – оставшееся время',
  '- color (default burgundy) - time to end':
    '– цвет (по умолчанию бордовый) – оставшееся время',
  'Highlight color in the archived task table':
    'Цвет выделения архивированных задач в таблице задач',
  'The highlight color in the canceled job table':
    'Цвет выделения отменённых задач в таблице задач',
  tasksTypes: 'Виды задач',
  priorities: 'Приоритеты',
  clickToChooseColor: 'Щёлкните, чтобы выбрать цвет: ',
  code: 'Код',
  name: 'Название',
  'active?': 'Активный?',
  usedInAreas: 'Используется на участках',
  followingStatusBy: 'Отслеж. статуса по',
  addTaskType: 'Добавить вид задачи',
  editTaskType: 'Редактировать вид задачи',
  realizationProgressesToolTip:
    'PDCA и DMAIC используются дла отслеживания прогресса реализации задач в системе. Все новые задачи без выбранного вида будут по умолчанию отслеживаться по выбранному методу.',

  taskTypeTableHeaders: {
    active: 'Активный?',
    taskType: 'Вид задачи',
    status: 'Статус по',
    requiresApproval: 'Требует одобрения',
    stepsToApprove: 'Шаги требующие одобрения',
    whoApproves: 'Кто одобряет'
  }
};

export const administrationAuditsView = {
  AUDITS: 'АУДИТЫ',
  forms: 'Бланки',
  inactive: 'неактивные',
  archived: 'архивированные',
  addAuditForm: 'Добавить бланк аудита',
  editAuditForm: 'Редактировать бланк аудита',
  designing: 'Проектировка',
  version: 'Версия',
  pinToFormMethodologyPracticeArea:
    'Привязать бланк к методике / практике / участку',
  createDate: 'Дата создания',
  lastChange: 'Последняя актуализация',
  goal: 'Цель',
  goodAboveBelow: 'Хорошо выше / ниже?',
  createdBy: 'Создал(а)',
  authorizedToEdit: 'Уполномоченные редактировать',
  active: 'Активный?',
  'closed?': 'Закрытый?',
  requireConfirmation: 'Требует одобрения?',
  panel: 'Панель',
  basicSettings: 'Основные настройки',
  additionalSettings: 'Дополнительные настройки',
  enforceCompatibility: 'Требовать комплектность',
  enforceCompatibilityToolTip:
    'Включает проверку комплектности аудита – если да, для окончания аудита необходимо оценить все крутерии аудита и выполнить все требования к оценке критериев (напр. комментарии, задачи).',
  maxTasksRealizationTime: 'Макс. время реализации задач:',
  maxTasksRealizationTimeTooltip:
    'Определяет время, которое будет диапазоном аналитического графика для задач, привязанных к данному аудиту.',
  allowCopyCopyingDoneAudit:
    'При копировании проведенного аудита разрешать копировать:',
  allowCopyCopyingDoneAuditToolTip:
    'Определяет, что можно копировать при копировании проведенного аудита на другой участок.',
  answers: 'ответы',
  attachments: 'вложения',
  comments: 'комментарии',
  auditHeading: 'Заголовок аудита',
  name: 'Название',
  content: 'Содержание',
  field: 'Поле',
  codeAndFormName: 'Код и название бланка',
  days: 'дней',
  weeks: 'недель',
  months: 'месяцев',
  formName: 'Название бланка',
  area: 'Участок',
  team: 'Команда',
  shift: 'Смена',
  practice: 'Практика',
  methodology: 'Методика',
  process: 'Процесс',
  audit_name: 'Процесс',
  usedInAreas: 'Используется на участках',
  auditCriteria: 'Критерии аудита',
  auditIntroductionInsertText: 'введение в аудит – введите текст',
  example: 'Пример',
  subgroup: 'Подгруппа',
  group: 'Группа',
  ratingScale: 'Шкала оценки',
  criterion: 'Критерий',
  showResults: 'Показывать результаты',
  ratingScales: 'Шкалы оценки',
  ratingScalePreview: 'Просмотр шкалы оценки',
  addCiterionDetails: 'Добавьте детали к критерию',
  tipsToDoEvaluation: 'Указания по проведению оценки',
  addRatingScale: 'Добавить шкалу оценки',
  ratingScaleName: 'Название шкалы оценки',
  consequences: 'Кнопки',
  textType: 'текстовые',
  pictorial: 'графические',
  text: 'Текст:',
  value: 'Значение:',
  color1: 'Цвет 1:',
  color2: 'Цвет 2:',
  ratingButtons: 'Кнопки оценки:',
  evaluationInAudit: 'Вид оценки в аудите:',
  picture: 'Картинка:',
  'frame?': 'Рамка?',
  'useThese?': 'Использ. эти?',
  'active?': 'Активная?',
  setNrManually: 'Установить номер вручную',
  omitInNumbering: 'Пропустить нумерацию',
  preview: 'Просмотр',
  radarChartOnThisLevel: 'Радарная диаграмма на этом уровне',
  copyForm: 'Копировать бланк',
  color1Tooltip:
    'Этот цвет применяется к содержимому и рамке кнопки рейтинга, значению рейтинга в аудите, а также столбцам и полям на диаграммах статистики аудита.',
  color2Tooltip:
    'Этот цвет используется для заливки кнопки рейтинга при ее выделении и для заливки поля рейтинга в аудите.',

  tableHeaders: {
    active: 'Активный?',
    'practice/area/form': 'Практика / участок / бланк',
    version: 'Версия',
    usedInAreas: 'Используется на участках'
  },

  criteriaTableHeaders: {
    nr: '№',
    evaluationCriteria: 'Критерии оценки',
    weight: 'Вес',
    scaleDesc: 'Описание шкалы',
    tips: 'Указания',
    example: 'Пример'
  },

  ratingScaleTableHeaders: {
    active: 'Активная?',
    scaleName: 'Название шкалы',
    levels: 'Степеней',
    preview: 'Просмотр',
    usedInForms: 'Используется в бланкаx'
  }
};

export const documentsView = {
  practicesPlatT: 'Практики ПлатТ',
  areas: 'Участки',
  searchIn: 'Искать в:',
  selectedBlock: 'выбраннoм кубике',
  inAll: 'всех кубиках',
  results: 'Результатow:',
  collapseBlocks: 'Свернуть кубики',
  expandBlocks: 'Развернуть кубики',
  collapseSearchResults: 'Свернуть результаты',
  expandSearchResults: 'Развернуть результаты',
  closeSearchResults: 'Закрыть результаты',
  methodology: 'Методика',
  actions: 'Действия',
  examples: 'Примеры',
  companyFiles: 'Файлы компании',
  implementationActivities: 'Действия по внедрению',
  run: 'Запустить',

  tableHeaders: {
    number: '№',
    activityName: 'Название действия',
    area: 'Участок',
    responsible: 'Ответственный',
    task: 'Задача',

    launchedFor: 'Запущено для',
    status: 'Статус',
    startDate: 'Дата начала',
    redlizationDate: 'Срок реализации',
    numberOfDays: 'К-во дней',

    block: 'Кубик',
    document: 'Документ / файл',
    date: 'Дата',
    size: 'Размер',
    addedBy: 'Добавлено'
  },
  displayingCommentInfo:
    'Автоматическое указывание комментариев можно выключить в настройках профиля пользователя',
  showInAllLanguages: 'Показать на всех языках',
  showInMyLanguage: 'Показать на моём языке',
  sendLinkModal: {
    sendLink: 'Отправить ссылку',
    sendTo: 'Отправить к',
    sendMessage: 'Отправить сообщение',
    system: 'по системе',
    email: 'по эл. почте',
    withCopy: 'с копией мне'
  }
};

export const evaluationsView = {
  evaluation: 'Оценка',
  change: 'Смена',
  team: 'Конанда',
  area: 'Участок',
  audit: 'Аудит',
  practiceBasedAssessments: 'Оценки по практикам',
  evaluationConnectedTasksList: 'Список задач привязанных к оценке',
  ratingsByDate: 'Оценки по дате',
  audits: 'Аудиты',
  blocks: 'Кубики',
  topics: 'Темы',
  radar: 'Радар',
  data: 'Данные',
  operators: 'Операторы',
  management: 'Менеджмент',

  practiceEvaluation: 'Оценка практики',
  topic: 'Тема',
  block: 'Кубик',

  nr: '№',
  evaluationCriteria: 'Критерии оценки',
  gradingScale: 'Шкала оценки',
  standards: 'Стандарты',
  guidelines: 'Указания по проведению оценки',

  alwaysShow: 'Всегда показывать',
  hints: 'Указания',

  addAnArea: 'Добавить участок',
  chooseAreaWhichWantToAdd:
    'Выберите участок(ки), которые хотите добавить к оценке',

  tableHeaders: {
    type: 'Вид',
    number: 'Номер',
    evaluationDate: 'Дата оценки',
    deadLine: 'Срок реализации',
    lastUpdate: 'Последнее обновление',
    evaluator: 'Оценивающий',
    name: 'Название',
    version: 'Версия',
    result: 'Результат',
    status: 'Статус',
    group: 'Группа',
    evaluationLeader: 'Лидер оценки'
  },

  gradingScaleDescription: {
    '3': 'Clear maintenance strategy exists for brewing and packaging and it is well aligned with the global strategy',
    '1': 'There is a local (site) maintenance strategy, but not sufficiently linked it with the global strategy',
    '0': 'Global strategy not available on site, no local maintenance strategies (brewing and packaging)'
  },

  createEvaluation: 'Создать оценку',
  editEvaluation: 'Редактировать оценку',
  evaluationSubmitButton: 'Создать',
  selectEvaluationType: 'Выберите вид оценки',
  selectEvaluationVersion: 'Выберите версию оценки',
  selectPractice: 'Выберите практику',
  selectArea: 'Выберите участок',
  'deleteEvaluation?': 'Удалить эту оценку?',
  evaluationTypes: {
    target: 'Цель',
    baseline: 'Базовый уровень',
    current: 'Текущая',
    derivative: 'Производная',
    normal: 'Обычная'
  },
  evaluationStatusModal: {
    closingEvaluation: 'Закрытие оценки',
    table: {
      headers: {
        roles: 'Роль',
        person: 'Лицо',
        isClosed: 'Закрытая'
      }
    }
  },
  criteriaListModal: {
    openedCriteriasList: 'Список открытых критериев',
    table: {
      headers: {
        topic: 'Тема',
        stage: 'Этап',
        block: 'Кубик',
        criterion: 'Критерий'
      }
    }
  },
  criterionTasksModal: {
    criterionTasks: 'Задачи критерия',
    launchedTasks: 'Запущенные задачи',
    predefinedTasks: 'Предопределённые задачи',
    table: {
      headers: {
        name: 'Название',
        description: 'Описание',
        responsible: 'Ответственный',
        area: 'Участок'
      }
    }
  },
  actionsMenu: {
    newEvaluation: 'Новая оценка практики на выбранных участках',
    newAudit: 'Новый аудит на выбранных участках',
    newForm: 'Новый бланк для выбранных участков',
    newDerivative: 'Новая производная оценка для выбранного участка',
    importEvaluation: 'Импорт сохранённой оценки или аудита'
  }
};

export const createEvalutionView = {
  leader: 'Лидер'
};

export const auditsView = {
  createAudit: 'Создать аудит',
  editAudit: 'Редактировать аудит',
  chart: 'График',
  auditSummaryAndStatistics: 'Итоги и статистика аудита',
  criteriaEvaluation: 'Оценка критериев',
  criterionNumber: 'Номер критерия',
  area: 'Участок:',
  team: 'Команда:',
  shift: 'Смена:',
  auditResult: 'Результат аудита:',
  from: 'из',
  pnt: 'баллов',
  finish: 'Окончить',
  'deleteAudit?': 'Удалить аудит?',

  tableHeaders: {
    type: 'Вид',
    auditForm: 'Бланк аудита',
    number: 'Номер',
    auditDate: 'Дата аудита',
    deadLine: 'Зрок реализации',
    lastUpdate: 'Последнее обновление',
    auditsLeader: 'Лидер аудитов',
    name: 'Название',
    version: 'Версия',
    result: 'Реаультат',
    status: 'Статус',
    team: 'Команда'
  },
  previewTableHeaders: {
    number: '№',
    evaluationCriteria: 'Критерии оценки',
    evaluation: 'Оценка',
    comment: 'Комментарий'
  },
  mainThingsToImprove: 'Основные области для развития и уличшения',
  sum: 'Итого:',
  comment: 'Комментарий',
  show: 'Показать',
  closingAudit: 'Закрытие аудита'
};

export const createAuditView = {
  proccess: 'Процесс',
  combineProcessAuditPackage: 'Объединить в пакет аудита процесса',
  auditLeaderAuditor: 'Лидер аудита / Главный аудитор',
  planSeries: 'Заpланирoвать серию',
  sendInvitations: 'Отправить приглашения'
};

export const createTaskView = {
  activities: 'действия',
  auditName: 'Название аудита',
  createdBy: 'Создал(а)',
  auditForm: 'Бланк аудита',
  taskTitle: 'Создать новую задачу',
  evaluationTitle: 'Соаздать оценку',
  practice: 'Практика',
  footer: 'Требует одобрения?',
  isAssignedToPractice: 'Связано с практикой?',
  areas: 'Участок(ки)',
  copy: 'Копировать',
  responsible: 'Ответственный',
  author: 'Автор',
  participants: 'Участники',
  manage: 'Управлять',
  taskName: 'Название задачи',
  evaluationName: 'Название оценки',
  description: 'Описание',
  priority: 'Приоритет',
  taskType: 'Вид задачи',
  teams: 'Команды и группы',
  planning: 'Планирование',
  plannedCommencementDate: 'Планируемая дата начала',
  completionDate: 'Срок реализации',
  scheduledTime: 'Планируемое время часов/минут',
  onlyCompletion: 'Только реализация (D)',
  completion: 'Реализация',
  actualCommencementDate: 'Фактическая дата начала',
  actualCompletionDate: 'Фактическая дата завершения',
  completionTime: 'Время завершения',
  version: 'Версия',
  evaluationOnLevel: 'Оценка на уровне',
  status: 'Статус',
  associateWith: 'Связать с',
  auditTeam: 'Аудиторская команда',
  evaluationType: 'Вид',
  evaluationScore: 'Результат оценки',
  isFinished: 'Законченная',
  isClosed: 'Закрытая',
  show: 'Показать',
  launch: 'Запустить',
  tasks: 'Задачи'
};

export const buttons = {
  highlightBlocks: 'Выделить кубики',
  add: 'Добавить',
  change: 'Изменить',
  remove: 'Убрать',
  import: 'Импортировать',
  close: 'Закрыть',
  saveTemplate: 'Сохранить шаблон',
  cancel: 'Отменить',
  ok: 'ОК',
  save: 'Сохранить',
  saveAndClose: 'Сохранить и закрыть',
  delete: 'Убрать',
  sendMessage: 'Отправить сообщение',
  addNew: 'Добавить новое',
  importTranslation: 'Импортировать перевод',
  exportToExcel: 'Экспорт в Excel',
  entitled: 'Уполномоченные',
  addLanguage: 'Добавить язык',
  specialLicenses: 'Специальные разрешения',
  addPerson: 'Добавить лицо',
  manage: 'Управлять',
  turnOn: 'Включить',
  choose: 'Выбрать',
  download: 'Скачать'
};

export const pagination = {
  show: 'Показаны',
  from: 'из',
  positions: 'позиций',
  next: 'Следующая',
  previous: 'Предыдущая',
  rowsPerPage: 'Строк на страницу',
  page: 'Страница',
  rows: 'строк'
};

export const criteriaView = {
  exampleForCriterion: 'Примеры критериев',
  attachmentsForCriterion: 'Вложения критериев',
  addFile: {
    title: 'Добавить файл',
    selectFile: 'Выбрать файл',
    preview: 'Просмотр',
    fileNameDescription: 'Здесь указывается название файла',
    documentName: 'Название документа',
    documentDescription: 'Описание документа',
    link: 'Ссылка',
    comment: 'Комментарий'
  },
  editFile: {
    title: 'Редактировать файл'
  }
};

export const loginView = {
  platform: 'Платформа',
  ofTransformation: 'Трансформации',
  welcome: 'Добро пожаловать'
};

export default {
  common,
  nav,
  auth,
  profile,
  tasks,
  administration,
  employeesAndUsersView,
  buttons,
  pagination,
  translationsView,
  administrationTasksView,
  administrationAuditsView,
  documentsView,
  evaluationsView,
  createEvalutionView,
  auditsView,
  createAuditView,
  criteriaView,
  createTaskView,
  desktop,
  loginView
};
