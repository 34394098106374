import { Flex } from 'components';
import { Blocks } from 'components/Blocks';
import styled from 'styled-components';
import { useEvaluationContext } from 'contexts/EvaluationContext';

const Wrapper = styled(Flex)`
  position: relative;
`;

const Legend = styled(Flex)`
  position: absolute;
  left: 50%;
`;

interface IEvaluationBlocksProps {
  showLegend?: boolean;
}

export const EvaluationBlocks: React.FC<IEvaluationBlocksProps> = ({
  showLegend
}) => {
  const { selectedBlock, selectedEvaluations, setSelectedBlock } =
    useEvaluationContext();

  return (
    <Wrapper>
      {showLegend && <Legend>Legend</Legend>}
      {selectedEvaluations[0] && (
        <Blocks
          practice={selectedEvaluations[0].practice}
          selectedBlock={selectedBlock}
          checkButton={false}
          onBlockClick={(block) => {
            setSelectedBlock(block);
          }}
          width={110}
          height={80}
          fontVariant="body2"
          topicFontVariant="body"
          increasing={false}
          showScores
        />
      )}
    </Wrapper>
  );
};
