import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { useState } from 'react';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { IOption } from './NestedSelect';
import { flattenOptions } from './utilities';

interface Props {
  option: IOption;
  selectedOptions: IOption[];
  setSelectedOptions: (options: IOption[]) => void;
  disabled?: boolean;
  isOneOptionSelect?: boolean;
  setIsSelectOpen: (isOpen: boolean) => void;
}

export const Option = ({
  option,
  selectedOptions,
  setSelectedOptions,
  disabled,
  isOneOptionSelect,
  setIsSelectOpen
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const isEveryItemSelected = !!flattenOptions(option.options)?.every(
    (item) =>
      !!flattenOptions(selectedOptions).find(
        (selectedOption) => selectedOption.value === item.value
      )
  );

  const handleOptionOnChange = () => {
    if (option.options) {
      if (isEveryItemSelected) {
        setSelectedOptions([
          ...flattenOptions(selectedOptions).filter(
            (item) =>
              !flattenOptions(option.options)?.find(
                (selectedOption) => selectedOption.value === item.value
              )
          )
        ]);
      } else {
        setSelectedOptions([
          ...selectedOptions,
          ...flattenOptions(option.options)?.filter(
            (item) =>
              !selectedOptions.find(
                (selectedOption) => selectedOption.value === item.value
              )
          )
        ]);
      }
    } else {
      if (isOneOptionSelect) {
        setSelectedOptions([option]);
        setIsSelectOpen(false);
      } else {
        if (
          selectedOptions.find(
            (selectedOption) => selectedOption.value === option.value
          )
        ) {
          setSelectedOptions([
            ...selectedOptions.filter(
              (filterOption) => filterOption.value !== option.value
            )
          ]);
        } else {
          setSelectedOptions([...selectedOptions, option]);
        }
      }
    }
  };

  return (
    <Flex p={1} flexDirection="column">
      <Flex alignItems="center" pl={option.options ? 0 : '40px'}>
        {option.options && (
          <Button
            variant="secondary"
            icon={
              <FontAwesomeIcon
                color="grey"
                icon={isOpen ? faCaretDown : faCaretRight}
              />
            }
            onClick={() => {
              setIsOpen((prevIsOpen) => !prevIsOpen);
            }}
          />
        )}
        {isOneOptionSelect ? (
          !option.options && (
            <Checkbox
              variant="tertiary"
              disabled={disabled ? true : option.isDisabled}
              checked={
                option.options
                  ? isEveryItemSelected
                  : !!flattenOptions(selectedOptions).find(
                      (selectedOption) => selectedOption.value === option.value
                    )
              }
              onChange={handleOptionOnChange}
            />
          )
        ) : (
          <Checkbox
            variant="tertiary"
            disabled={disabled ? true : option.isDisabled}
            checked={
              option.options
                ? isEveryItemSelected
                : !!flattenOptions(selectedOptions).find(
                    (selectedOption) => selectedOption.value === option.value
                  )
            }
            onChange={handleOptionOnChange}
          />
        )}

        {option.label}
      </Flex>

      {isOpen && (
        <Flex pl={4} flexDirection="column">
          {option.options?.map((nestedOption) => (
            <Option
              disabled={disabled}
              key={nestedOption.label}
              option={nestedOption}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              isOneOptionSelect={isOneOptionSelect}
              setIsSelectOpen={setIsSelectOpen}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
