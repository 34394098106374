import { faCirclePlus, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Line } from 'components';
import { H2 } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import { t } from 'i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { CompanyPhotoForm } from './CompanyPhoto/CompanyPhotoForm';
import { editCompany, getCompany } from 'api/companies';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ICompanyForm } from 'types/forms/company';

export const CompanyDataForm = () => {
  const {
    control,
    reset,
    register,
    handleSubmit,
    watch,
    formState: { isDirty }
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: 'company_contact_people_attributes',
    control
  });

  const addNewField = async () => {
    const attributes = {
      title: '-',
      full_name: '',
      email: '',
      phone: '',
      landline_phone_number: '',
      mobile_phone_number: '',
      fax: ''
    };

    append(attributes);

    const { data: newCompanyData } = await editCompany(watch('id'), {
      company: {
        company_contact_people_attributes: [attributes]
      }
    });

    const { company_contact_people, ...restData } = newCompanyData;

    reset({
      ...restData,
      company_contact_people_attributes: company_contact_people?.map(
        (companyContactPerson) => companyContactPerson
      )
    });
  };

  const handleGetCompanyData = async () => {
    try {
      const {
        data: { company_contact_people, ...restData }
      } = await getCompany('main');

      reset({
        ...restData,
        company_contact_people_attributes: company_contact_people?.map(
          (companyContactPerson) => companyContactPerson
        )
      });
    } catch (e) {
      toast.error(t('administration.noCompanyData'));
    }
  };

  const handleUpdateCompany = async (data: ICompanyForm['company']) => {
    try {
      const { id, logo, ...restData } = data;

      const payload = {
        company: {
          ...restData,
          ...(typeof logo === 'string' ? [] : logo ? { logo } : [])
        }
      };

      if (id && isDirty) {
        const { data: newCompanyData } = await editCompany(id, payload);

        toast.success(t('common.success'));
      }
    } catch (e) {
      toast.error(t('anErrorOccurred'));
    }
  };

  const handleUpdateLogo = (logo: Blob) => {
    handleSubmit((data) => {
      handleUpdateCompany({ ...data, logo });
    })();
  };

  const handleDeleteContactPerson = async (index: number) => {
    const companyContactPersonId = watch(
      `company_contact_people_attributes.${index}.id`
    );

    if (companyContactPersonId && watch('id')) {
      const { data: newCompanyData } = await editCompany(watch('id'), {
        company: {
          company_contact_people_attributes: [
            { id: companyContactPersonId, _destroy: 1 }
          ]
        }
      });
    }

    await remove(index);
  };

  const onSubmit = handleSubmit(handleUpdateCompany);

  useEffect(() => {
    handleGetCompanyData();
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column">
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Flex
            px={3}
            width={['100%', '30%']}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex width="100%" alignItems="center" flexDirection="column">
              <CompanyPhotoForm
                logo={watch('logo')}
                handleSubmit={handleUpdateLogo}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
              <Box mr={2} width="100%">
                <TextField
                  {...register('full_name')}
                  onBlur={onSubmit}
                  label="administration.fullCompanyName"
                />
              </Box>
              <Box mr={2} width="100%">
                <TextField
                  {...register('short_name')}
                  onBlur={onSubmit}
                  label="administration.shortCompanyName"
                />
              </Box>
              <TextField
                {...register('workplace_unit')}
                label="administration.unit/workplace"
              />
            </Flex>

            <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
              <Box mr={2} width="100%">
                <TextField
                  {...register('street_number')}
                  onBlur={onSubmit}
                  label="administration.streetAndNumber"
                />
              </Box>
              <Box mr={2} width="40%">
                <TextField
                  {...register('postal_code')}
                  onBlur={onSubmit}
                  label="administration.postCode"
                />
              </Box>
              <Box mr={2} width="60%">
                <TextField
                  {...register('city')}
                  onBlur={onSubmit}
                  label="administration.city"
                />
              </Box>
              <TextField
                {...register('country')}
                onBlur={onSubmit}
                label="administration.country"
              />
            </Flex>

            <Flex alignItems="center" flexWrap={['wrap', 'nowrap']}>
              <Box mr={2} width="100%">
                <TextField
                  {...register('tax_id_number')}
                  onBlur={onSubmit}
                  label="administration.NIPNumber"
                />
              </Box>
              <Box mr={2} width="100%">
                <TextField
                  {...register('krs_number')}
                  onBlur={onSubmit}
                  label="administration.KRSNumber"
                />
              </Box>
              <TextField
                {...register('website')}
                onBlur={onSubmit}
                label="administration.website"
              />
            </Flex>

            <H2 variant="h2" color="coloured" my={2}>
              {t('administration.officeContactData')}
            </H2>
            <Line />

            <Flex flexWrap={['wrap', 'nowrap']}>
              <Flex width={['100%', '47%']} mr={2}>
                <TextField
                  {...register('email')}
                  onBlur={onSubmit}
                  label="administration.emailAddress"
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  onIconClick={() => {
                    window.open(
                      'mailto:email@example.com?subject=Subject&body=Body%20goes%20here'
                    );
                  }}
                />
              </Flex>
              <Box width={['100%', '17%']} mr={2}>
                <TextField
                  {...register('phone')}
                  onBlur={onSubmit}
                  label="profile.phone"
                />
              </Box>
              <Box width={['100%', '17%']} mr={2}>
                <TextField
                  {...register('landline_phone')}
                  onBlur={onSubmit}
                  label="profile.landlinePhone"
                />
              </Box>
              <Box width={['100%', '17%']}>
                <TextField {...register('fax')} onBlur={onSubmit} label="fax" />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <H2 variant="h2" color="coloured" my={2}>
          {t('administration.contactPeopleData')}
        </H2>
        <Line />

        {fields.map((field, index) => (
          <Flex key={field.id} flexWrap={['wrap', 'nowrap']} mb={3}>
            <Flex width="60%" gap="10px" mr={2} flexWrap={['wrap', 'nowrap']}>
              <TextField
                label="administration.fullname"
                {...register(
                  `company_contact_people_attributes.${index}.full_name`
                )}
                onBlur={onSubmit}
              />

              <TextField
                label="administration.role"
                {...register(
                  `company_contact_people_attributes.${index}.title`
                )}
                onBlur={onSubmit}
              />

              <TextField
                {...register(
                  `company_contact_people_attributes.${index}.email`
                )}
                label="administration.emailAddress"
                icon={<FontAwesomeIcon icon={faEnvelope} />}
                onIconClick={() => {
                  window.open(
                    `mailto:${watch(
                      `company_contact_people_attributes.${index}.email`
                    )}?subject=Subject&body=Body%20goes%20here`
                  );
                }}
                onBlur={onSubmit}
              />
            </Flex>

            <Flex
              width="40%"
              gap="10px"
              alignItems="end"
              flexWrap={['wrap', 'nowrap']}
            >
              <TextField
                label="profile.phone"
                {...register(
                  `company_contact_people_attributes.${index}.mobile_phone_number`
                )}
                onBlur={onSubmit}
              />
              <TextField
                label="profile.landlinePhone"
                {...register(
                  `company_contact_people_attributes.${index}.landline_phone_number`
                )}
                onBlur={onSubmit}
              />
              <TextField
                label="fax"
                {...register(`company_contact_people_attributes.${index}.fax`)}
                onBlur={onSubmit}
              />
              <Flex>
                <Button
                  onClick={() => {
                    handleDeleteContactPerson(index);
                  }}
                  variant="red"
                  icon={<FontAwesomeIcon icon={faUserSlash} />}
                />
              </Flex>
            </Flex>
          </Flex>
        ))}

        <Flex justifyContent="end">
          <Button variant="eucalyptus" onClick={addNewField}>
            <Flex>
              <Box mr={2}>
                <FontAwesomeIcon icon={faCirclePlus} />
              </Box>
              {t('administration.addPerson')}
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
