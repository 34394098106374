import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
  font-size: 12px;
  color: white;
  font-weight: bold;

  .pie-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    > div {
      width: 50%;
    }

    :before,
    :after {
      content: '';
      position: absolute;
      display: block;
      background: white;
    }

    :after {
      top: 48%;
      left: 0;
      height: 4%;
      width: 100%;
    }

    :before {
      top: 0;
      left: 48%;
      height: 100%;
      width: 4%;
    }
  }
`;

const Entry = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  color: ${({ theme }) => theme.palette.neutral.white};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.palette.accent.green : theme.palette.neutral.lightGrey};

  &.top {
    padding-top: 2px;
  }

  &.bottom {
    padding-bottom: 2px;
  }

  &.right {
    padding-right: 2px;
  }

  &.left {
    padding-left: 2px;
  }
`;

export interface IPDCAPie {
  activeAll?: boolean;
  activeLetters?: string[];
  handleClickKind?: (kind: string) => void;
  onLetterHover?: (kind: string) => void;
  onLetterMouseLeave?: () => void;
  availableLetter: string;
}

export const PDCAPie: React.FC<IPDCAPie> = ({
  activeAll,
  activeLetters,
  handleClickKind,
  onLetterHover,
  onLetterMouseLeave,
  availableLetter
}) => {
  return (
    <Container>
      <div className="pie-wrap">
        <div>
          <Entry
            type="button"
            isActive={activeAll || activeLetters?.includes('A')}
            className="top left"
            onClick={() => {
              if (
                !(activeAll || activeLetters?.includes('A')) &&
                availableLetter === 'A'
              ) {
                handleClickKind?.('A');
              }
            }}
            onMouseEnter={() => {
              onLetterHover?.('A');
            }}
            onMouseLeave={onLetterMouseLeave}
          >
            A
          </Entry>
          <Entry
            type="button"
            isActive={activeAll || activeLetters?.includes('C')}
            className="bottom left"
            onClick={() => {
              if (
                !(activeAll || activeLetters?.includes('C')) &&
                availableLetter === 'C'
              ) {
                handleClickKind?.('C');
              }
            }}
            onMouseEnter={() => {
              onLetterHover?.('C');
            }}
            onMouseLeave={onLetterMouseLeave}
          >
            C
          </Entry>
        </div>
        <div>
          <Entry
            type="button"
            isActive={activeAll || activeLetters?.includes('P')}
            className="top right"
            onClick={() => {
              if (
                !(activeAll || activeLetters?.includes('P')) &&
                availableLetter === 'P'
              ) {
                handleClickKind?.('P');
              }
            }}
            onMouseEnter={() => {
              onLetterHover?.('P');
            }}
            onMouseLeave={onLetterMouseLeave}
          >
            P
          </Entry>
          <Entry
            type="button"
            isActive={activeAll || activeLetters?.includes('D')}
            className="bottom right"
            onClick={() => {
              if (
                !(activeAll || activeLetters?.includes('D')) &&
                availableLetter === 'D'
              ) {
                handleClickKind?.('D');
              }
            }}
            onMouseEnter={() => {
              onLetterHover?.('D');
            }}
            onMouseLeave={onLetterMouseLeave}
          >
            D
          </Entry>
        </div>
      </div>
    </Container>
  );
};
