import { IPostAuditFormRequest } from 'types/audits';

type CriteriaAttributes =
  IPostAuditFormRequest['audit_form']['criteria_attributes'];

export const parseRemovedCriteria = (
  defaultCriteriaAttribute: CriteriaAttributes,
  criteriaAttributes: CriteriaAttributes
): CriteriaAttributes => {
  return [
    ...(
      defaultCriteriaAttribute?.filter(
        (defaultCriteriaAttribute) =>
          !criteriaAttributes.find(
            (criteriaAttribute) =>
              'id' in criteriaAttribute &&
              'id' in defaultCriteriaAttribute &&
              criteriaAttribute.id === defaultCriteriaAttribute.id
          )
      ) || []
    ).map((criteriaAttribute) => ({ ...criteriaAttribute, _destroy: 1 })),
    ...criteriaAttributes
  ];
};
