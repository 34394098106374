import { Wrapper } from './Sidebar.styled';
import React, { useEffect, useState } from 'react';
import { Flex } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/_form';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { IPractice } from 'types/methologies';
import { getPractices } from 'api/methodologies';
import { useDocumentsSidebarContext } from 'contexts/DocumentsSidebarContext';
import { AreasTree } from 'components/AreasTree/AreasTree';
import { AreaAccordionItem } from 'types/area';
import { getAreas } from 'api/areas';
import { parentToChildrenAreasStructure } from 'utilities/areas';

export const Sidebar: React.FC = () => {
  const [areas, setAreas] = useState<AreaAccordionItem[]>([]);
  const [practices, setPractices] = useState<IPractice[]>([]);
  const { selectedPractice, setSelectedPractice } =
    useDocumentsSidebarContext();
  const { t } = useTranslation();

  const handleGetPractices = async () => {
    const { data } = await getPractices();
    setPractices(data);
  };

  const handleGetAreas = async () => {
    const { data } = await getAreas();

    setAreas(parentToChildrenAreasStructure(data));
  };

  useEffect(() => {
    handleGetAreas();
  }, []);

  useEffect(() => {
    handleGetPractices();
  }, []);

  return (
    <Wrapper flexDirection="column">
      <CustomTabs selectedTabClassName="is-selected">
        <CustomTabList>
          <Flex flexWrap="wrap">
            <CustomTab>{t('documentsView.practicesPlatT')}</CustomTab>
            <CustomTab>{t('documentsView.areas')}</CustomTab>
          </Flex>
        </CustomTabList>

        <CustomTabPanel>
          <Flex flexDirection="column" gap="10px">
            {practices.map((practice) => (
              <Button
                textAlign="start"
                key={practice.id}
                variant={
                  selectedPractice?.id === practice.id ? 'eucalyptus' : 'grey'
                }
                onClick={() => setSelectedPractice(practice)}
              >
                {practice.name}
              </Button>
            ))}
          </Flex>
        </CustomTabPanel>

        <CustomTabPanel>
          <AreasTree items={areas} />
        </CustomTabPanel>
      </CustomTabs>
    </Wrapper>
  );
};
