import {
  SquareNumberBoxGreen,
  SquareNumberBoxBlue,
  SquareNumberBoxRed
} from './SquareNumberBox.styled';

interface Props {
  color: 'red' | 'green' | 'blue';
  value: string;
  setValue: (value: string) => Promise<void>;
}

export const SquareNumberBox = ({ color, value, setValue }: Props) => {
  let squareNumberBox;
  switch (color) {
    case 'red':
      squareNumberBox = <SquareNumberBoxRed>{value}</SquareNumberBoxRed>;
      break;
    case 'green':
      squareNumberBox = <SquareNumberBoxGreen>{value}</SquareNumberBoxGreen>;
      break;
    case 'blue':
      squareNumberBox = <SquareNumberBoxBlue>{value}</SquareNumberBoxBlue>;
      break;
  }

  return (
    <div
      onMouseDown={() => {
        setValue(value);
      }}
    >
      {squareNumberBox}
    </div>
  );
};
