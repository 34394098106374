import { IArea } from 'types/area';
import { ITeam } from 'types/team';
import { IUser } from 'types/user';

interface IUseAccount {
  isLogged: boolean;
  loggedUserData: TUserData;
  loggedUserRole: TRole;
}

type TUserData = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  companyId: string;
  ownedAreas: IArea[];
  areas: IArea[];
  teams: ITeam[];
};

type TRole = 'admin' | 'user' | 'none';

export const useAccount = (): IUseAccount => {
  const currentUser = localStorage.getItem('currentUser');
  const isLogged = currentUser ? true : false;

  const getUserData = (): TUserData => {
    if (isLogged) {
      let data: IUser = JSON.parse(currentUser!);
      return {
        id: String(data.id) ?? '',
        email: data.email ?? '',
        firstName: data.profile?.first_name ?? '',
        lastName: data.profile?.last_name ?? '',
        role: data.role ?? '',
        companyId: String(data.company_id) ?? '',
        ownedAreas: data.owned_areas ?? [],
        areas: data.areas ?? [],
        teams: data.teams ?? []
      };
    } else {
      return {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        companyId: '',
        ownedAreas: [],
        areas: [],
        teams: []
      };
    }
  };

  const getUserRole = (): TRole => {
    if (isLogged) {
      let data = JSON.parse(currentUser!);
      return data.profile?.role;
    } else {
      return 'none';
    }
  };

  const loggedUserData = getUserData();
  const loggedUserRole = getUserRole();

  return { isLogged, loggedUserData, loggedUserRole };
};
