import request from './index';
import { IRegulations } from '../types/forms/regulation';

export const fetchRegulations = async () =>
  await request<IRegulations>({
    options: {
      url: '/regulations',
      method: 'get'
    }
  });
