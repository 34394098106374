import React, { useContext, useState } from 'react';
import { initSidebarFilters, SidebarFilters } from '../types/sidebar';
import { formatDate } from '../utilities/date';

export interface ITaskSidebarContext {
  filters: SidebarFilters;
  defaultFilters: Partial<SidebarFilters>;
  savedFilters: Partial<SidebarFilters>;
  setFilters: React.Dispatch<React.SetStateAction<SidebarFilters>>;
  setDefaultFilters: React.Dispatch<
    React.SetStateAction<Partial<SidebarFilters>>
  >;
  setSavedFilters: React.Dispatch<
    React.SetStateAction<Partial<SidebarFilters>>
  >;
  getDateLabel: (
    filterName: string,
    from: Date | null,
    to: Date | null
  ) => string;
}

const TaskSidebarContext = React.createContext<ITaskSidebarContext | undefined>(
  undefined
);

export const TaskSidebarContextContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [defaultFilters, setDefaultFilters] = useState<Partial<SidebarFilters>>(
    {}
  );
  const [savedFilters, setSavedFilters] = useState<Partial<SidebarFilters>>({});
  const [filters, setFilters] = useState<SidebarFilters>(initSidebarFilters);

  const getDateLabel = (
    filterName: string,
    from: Date | null,
    to: Date | null
  ) => {
    if (!from && !to) return '';
    let label = filterName + ' -';
    if (from) label += ' od: ' + formatDate(from);
    if (to) label += ' do: ' + formatDate(to);
    return label;
  };

  return (
    <TaskSidebarContext.Provider
      value={{
        filters,
        defaultFilters,
        savedFilters,
        setFilters,
        setDefaultFilters,
        setSavedFilters,
        getDateLabel
      }}
    >
      {children}
    </TaskSidebarContext.Provider>
  );
};

export const useTaskSidebarContext = () => {
  const context = useContext(TaskSidebarContext);
  if (!context) {
    throw new Error(
      'useTaskSidebarContext must be inside a TaskSidebarContextProvider with a value'
    );
  }
  return context;
};
