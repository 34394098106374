import React, { useCallback, useEffect, useState } from 'react';
import {
  InfoWrapper,
  Item,
  ToggleSubItem,
  ToggleSubItemMock,
  Wrapper
} from './AreasTree.styled';
import { Tooltip } from 'react-tooltip';
import {
  faInfoCircle,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AreaAccordionItem, IArea } from 'types/area';
import { handlePrevState } from 'utilities/handlePrevState';
import { Flex } from 'components/Flex';
import { theme } from 'styles';
import { AreaDetailsForm } from 'views/AdministrationView/views/AreasAndOrganizationStructureView/components/AreaDetailsForm';
import { Modal } from 'components/Modal';
import { useTranslation } from 'react-i18next';

interface Props {
  items: AreaAccordionItem[];
  onClick?: (id: number) => void;
  canBeActive?: boolean;
  controlledCheckedFilters?: number[];
}

export const AreasTree: React.FC<Props> = ({
  items,
  canBeActive,
  onClick,
  controlledCheckedFilters
}) => {
  const [areaToPreview, setAreaToPreview] = useState<IArea>();
  const [openedFilters, setOpenedFilters] = useState<number[]>([]);
  const [checkedFilters, setCheckedFilters] = useState<number[]>([]);

  const { t } = useTranslation();

  const handleOpenChildren = useCallback((id: number) => {
    setOpenedFilters((prevState) => {
      if (prevState.includes(id)) return prevState.filter((el) => el !== id);
      return [...prevState, id];
    });
  }, []);

  const handleOnClick = useCallback(
    (id: number) => {
      setCheckedFilters((prevState) => handlePrevState(prevState, id));

      onClick && onClick(id);
    },
    [checkedFilters]
  );

  const getChildrenWithSubChildrenCount = (item: AreaAccordionItem) => {
    let count = 0;
    if (!openedFilters.includes(item.id)) return count;
    if (item.children && item.children.length > 0) {
      count += item.children.length;
      item.children.forEach((el, index, arr) => {
        if (arr[index + 1]) count += getChildrenWithSubChildrenCount(el);
      });
    }
    return count;
  };

  useEffect(() => {
    setCheckedFilters(controlledCheckedFilters || []);
  }, [controlledCheckedFilters]);

  const renderItems = (
    passedItems: AreaAccordionItem[],
    childrenStack: number[]
  ) =>
    passedItems.map((el, index) => (
      <React.Fragment key={el.id}>
        <Item
          childrenNumber={
            childrenStack.length > 1
              ? 100 - (childrenStack.length - 1) * 10
              : undefined
          }
          childrenLines={
            openedFilters.includes(el.id) &&
            getChildrenWithSubChildrenCount(el) * 44 + 'px - 16px'
          }
          isActive={checkedFilters.includes(el.id)}
          canBeActive={canBeActive}
        >
          <Flex ml="auto">
            {el?.children && el?.children.length > 0 ? (
              <ToggleSubItem
                color={theme.palette.neutral.black}
                onClick={() => handleOpenChildren(el.id)}
              >
                <FontAwesomeIcon
                  icon={openedFilters.includes(el.id) ? faMinus : faPlus}
                />
              </ToggleSubItem>
            ) : (
              <ToggleSubItemMock />
            )}
          </Flex>

          <Flex paddingX="4px" width="100%">
            <InfoWrapper
              id={el.name + el.id + 'Tooltip'}
              data-tooltip-content={el.name}
              onClick={() => {
                setAreaToPreview(el);
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </InfoWrapper>
            <Flex onClick={() => handleOnClick(el.id)} width="100%">
              {el.name}
            </Flex>

            <Tooltip
              style={{ zIndex: 400 }}
              anchorId={el.name + el.id + 'Tooltip'}
            />
          </Flex>
        </Item>
        {openedFilters.includes(el.id) &&
          el.children &&
          renderItems(
            el.children,
            childrenStack ? [...childrenStack, index] : [index]
          )}
      </React.Fragment>
    ));

  return (
    <>
      <Wrapper>{renderItems(items, [0])}</Wrapper>

      {areaToPreview && (
        <Modal
          isOpen={!!areaToPreview}
          onCancelClick={() => {
            setAreaToPreview(undefined);
          }}
          headerTitle={areaToPreview.name}
          mainButton={{
            variant: 'grey',
            action: () => {
              setAreaToPreview(undefined);
            },
            label: t('common.close')
          }}
        >
          <AreaDetailsForm data={areaToPreview} />
        </Modal>
      )}
    </>
  );
};
