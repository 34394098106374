import { IPageResponse, IPagesResponse } from 'types/pages';
import request from './index';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const getPages = async () => {
  try {
    return await request<IPagesResponse>({
      options: {
        url: '/pages',
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const getPage = async (slug: string) => {
  try {
    return await request<IPageResponse>({
      options: {
        url: `/pages/${slug}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
