import {
  faCircleArrowDown,
  faCircleArrowUp,
  faGear
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'components/Box';
import { Dropdown } from 'components/Dropdown';
import { Flex } from 'components/Flex';
import { theme } from 'styles';
import { P } from 'components/Typography/Typography';
import { ChangeComponentOrder } from '../../EvaluationModal';

interface IColumnsOptionsProps {
  switchableItems: string[];
  changeComponentOrder: (props: ChangeComponentOrder) => void;
}

export const ColumnOrder: React.FC<IColumnsOptionsProps> = ({
  switchableItems,
  changeComponentOrder
}) => {
  const CustomDropdown = () => (
    <Flex flexDirection="column">
      {switchableItems.map((itemId, i) => {
        return (
          <Box key={itemId} my={1}>
            <Flex flexDirection="row" justifyContent="space-between">
              <P variant="body">{itemId}</P>
              <Flex width="40px" justifyContent="space-around">
                {i !== 0 && (
                  <FontAwesomeIcon
                    icon={faCircleArrowUp}
                    cursor="pointer"
                    size="xs"
                    onClick={() =>
                      changeComponentOrder({ itemId, direction: 'up' })
                    }
                  />
                )}
                {i !== switchableItems.length - 1 && (
                  <FontAwesomeIcon
                    icon={faCircleArrowDown}
                    cursor="pointer"
                    size="xs"
                    onClick={() =>
                      changeComponentOrder({ itemId, direction: 'down' })
                    }
                  />
                )}
              </Flex>
            </Flex>
          </Box>
        );
      })}
    </Flex>
  );

  return (
    <Dropdown
      bgColor={theme.palette.neutral.white}
      customDropdown={<CustomDropdown />}
    >
      <Box mr={2} alignItems="center">
        <FontAwesomeIcon icon={faGear} />
      </Box>
    </Dropdown>
  );
};
