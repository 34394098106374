const common = {
  privacyPolicy: 'Polityka prywatności',
  regulamin: 'Zasady i warunki',
  email: 'adres e-mail',
  first_name: 'imię',
  last_name: 'nazwisko',
  username: 'Nazwa użytkownika',
  anErrorOccurred: 'Wystąpił błąd',
  addedEvaluation: 'Dodano ocenę',
  cookiesMessage:
    'Ta strona korzysta z funkcjonalnych plików cookie, aby zapewnić najlepszą jakość działania Platformy.\nKontynując korzystanie z niej wyrażasz zgodę na użycie tych plików cookie.',
  other: 'Inne',
  save: 'Zapisz',
  language: 'Język',
  yes: 'tak',
  close: 'Zamknij',
  no: 'nie',
  loading: 'Logowanie...',
  'leave blank if all company': 'pozostaw puste, jeżeli cała firma',
  uncheck: 'Odznacz',
  cancel: 'Anuluj',
  message: 'wiadomość',
  success: 'Operacja powiodła się',
  justificationRequired: 'Uzasadnienie jest wymagane',
  showAll: 'Pokaż wszystko',
  add: 'Dodaj',
  showAlsoMedia: 'Pokazuj też media',
  addImage: 'Dodaj zdjęcie',
  'document/file': 'Dokument / plik',
  show: 'Pokaż',
  copy: 'Kopiuj',
  today: 'Dzisiaj',
  fieldRequired: 'Pole jest wymagane',
  notes: 'Notatki',
  makeAuditsToUnlock: 'Przeprowadź audyty by odblokować',
  installApp: 'Zainstaluj aplikacje',
  pressShareButton: 'Naciśnij przycisk udostępniania',
  pressAddHomeScreen: 'Naciśnij przycisk Do ekranu początkowego'
};

const nav = {
  'my-desktop': 'Mój pulpit',
  tasks: 'Zadania',
  evaluation: 'Ocena',
  analytics: 'Analityka',
  documents: 'Dokumenty',
  administration: 'Admin',
  profile: 'Profil',
  logout: 'Wyloguj'
};

const auth = {
  login: 'zaloguj się',
  register: 'zarejestruj się',
  password: 'hasło',
  newPassword: 'nowe hasło',
  passwordConfirm: 'powtórz hasło',
  newPasswordConfirm: 'powtórz nowe hasło',
  forgotPass: 'Nie pamietam hasła',
  accountless: 'Nie masz konta?',
  emailRequired: 'E-mail jest wymagany',
  badEmailFormat: 'Niepoprawny format adresu e-mail',
  passwordRequired: 'Hasło jest wymagane',
  passMinVal: 'Hasło musi mieć przynajmniej 6 znaków',
  passMaxVal: 'Hasło może mieć maksymalnie 18 znaków',
  passDiffErr: 'Hasła nie są takie same',
  acceptRegulamin: 'Zaakceptuj Zasady i warunki',
  password_confirmation: 'potwierdzenie hasła',
  send_email: 'Wyślij e-mail',
  verify_your_email: 'Sprawdź swój e-mail',
  passwordChanged: 'Hasło zostało zmienione',
  changePassword: 'Zmiana hasła',
  saveNewPassword: 'Zapisz nowe hasło',
  reset: 'Resetuj',
  writeRegisteredEmail: 'Wpisz swój adres e-mail, zarejestrowany w systemie',
  backToLoginPage: 'Wróć do ekranu logowania',
  succeedSendResetEmail:
    'Na ten adres, jeżeli jest on zarejestrowany w systemie, otrzymasz link do resetowania hasła'
};

const profile = {
  myProfile: 'Mój profil',
  profile: 'Profil',
  basicInformation: 'Informacje podstawowe',
  changeEmailAddress: 'Zmień adres e-mail',
  changeUsername: 'Zmień nazwę użytkownika',
  areaPermissions: 'Uprawnienia do obszarów',
  mailNotifications: 'Powiadomienia mailowe',
  idCode: 'Kod ID',
  userCompany: 'Firma użytkownika',
  name: 'Imię',
  secondname: 'Drugię imię',
  surname: 'Nazwisko',
  phone: 'Telefon komórkowy',
  landlinePhone: 'Telefon stacjonarny',
  mainArea: 'Główny obszar',
  myRoleInCompany: 'Moje funkcje w firmie',
  employeeRoles: 'Funkcje pracownika',
  userAreaPermissions: 'Użytkownik ma prawo edycji w obszarach:',
  userHasRiparianAreasViewPermission:
    'Użytkownik ma prawo podglądu obszarów nadrzędnych',
  userHasNeighboringAreasViewPermission:
    'Użytkownik ma prawo podglądu obszarów sąsiednich',
  overallSettings: 'Ustawienia ogólne',
  sendMailAfterSystemNotifications:
    'Wysyłaj powiadomienia mailowe o otrzymaniu powiadomień w systemie',
  sendMailStraightAfter: 'wysyłaj maila zaraz po otrzymaniu powiadomienia',
  groupNotificationsAndSendEvery: 'grupuj powiadomienia i wysyłaj co (minut)',
  sendTasksStatusSummary: 'Wysyłaj podsumowania statusu moich zadan',
  never: 'nigdy',
  everyday: 'codziennie',
  by: 'o',
  everyWeek: 'co tydzień',
  on: 'w',
  everyMonth: 'co miesiąc',
  'ds.': 'dn.',
  notificationsAboutplannedTasks: 'Przypomnienia o zaplanowanych zadaniach',
  sendMailFutureNotifications:
    'Wysyłaj powiadomienia mailowe o nadchodzqcych i zaplanowanych zadaniach',
  beforeStart: 'Przed rozpoczęciem',
  minutes: 'minut(y)',
  hours: 'godzin(y)',
  days: 'dni',
  before: 'wcześniej',
  beforeFinish: 'Przed zakończeniem',
  notifyAboutPricesAndAuditsInMyAreas:
    'Powiadom mnie (jako właściciela obszaru) o ocenach i audytach w moim obszarze',
  mailNotificationsAboutPDCATasksStatus:
    'Powiadomienia mailowe o zmianach statusu PDCA / DMAIC zadań',
  sendMailNotificationsWhenPDCATasksStatusChangedWhichIAm:
    'Wysyłaj powiadomienia mailowe, gdy ktoś zmieni status PDCA / DMAIC zadania, którego jestem:',
  byTheAuthor: 'Autorem',
  responsibleBy: 'Odpowiedzialnym',
  participantBy: 'Uczestnikiem',
  StatusP: 'Status P',
  StatusD: 'Status D',
  StatusC: 'Status C',
  StatusA: 'Status A',
  displayPreferences: 'Preferencje wyświetlania',
  dateFormat: 'Format daty',
  separators: 'Separatory',
  decimal: 'dziesiętny',
  thousands: 'tysięcy',
  firstWeekDay: 'Pierwszy dzień tygodnia',
  default: 'Domyślne',
  imageChange: 'Zmień zdjęcie',
  imageRemove: 'Usuń zdjęcie',
  addImage: 'Dodaj zdjęcie',
  deleteUser: 'Usuń użytkownika',
  recoverDefault: 'Zresetuj i przywróć domyślne',
  'Password change error': 'Błąd zmiany hasła',
  'Your password cannot be:': 'Twoje hasło nie może być:',
  'such as your username or similar':
    'takie jak Twoja nazwa użytkownika lub podobne do niej',
  'such as your first and/or last name': 'takie jak Twoje imię i/lub nazwisko',
  'company name or a variation thereof': 'nazwą firmy lub jej wariacją',
  'the names of your children, family, dogs and cats':
    'imionami Twoich dzieci, rodziny, psów czy kotów',
  'a string of consecutive letters and/or keyboard characters, e.g. qwertyuiop, abcd1234, 12345678, 09876543...':
    'ciągiem kolejnych liter i/lub znaków na klawiaturze, np. qwertyuiop, abcd1234, 12345678, 09876543...',
  'be on the list of commonly known passwords':
    'znajdować się na liście haseł powszechnie znanych',
  'Instead, we encourage you to use phrases that are easy to remember, e.g.':
    'Zachecamy za to do stosowania fraz, które łatwo zapamiętać, np.',
  "My pink dog is in love with my neighbor's green parrot":
    'Mój różowy pies kocha się w zielonej papudze sąsiada'
};

const desktop = {
  my: 'Moje',
  solvingProblems: 'Rozwiązywanie problemów',
  audits: 'Audyty'
};

const tasks = {
  savedFilters: 'Zapisane filtry',
  defaultFilter: 'Domyślny filtr',
  defaultFilterNotExists: 'Brak domyślnych filtrów',
  savedFiltersNotExists: 'Brak zapisanych filtrów',
  setAsDefault: 'Ustaw jako domyślny',
  delete: 'Usuń',
  addTask: 'Dodaj zadanie',
  editTask: 'Edytuj zadanie',
  exportToExcel: 'Eksportuj do Excela',
  tasksCount: 'Liczba zadań',
  criteriaConnectedTasksList: 'Lista zadań powiązanych z kryterium',
  realizationTimes: 'czasów realizacji',
  chart: 'Wykres',
  calendar: 'Kalendarz',
  chosenLines: 'wybranych wierszy',
  toArchive: 'Do archiwum',
  exportToCalendar: 'Eksportuj do kalenarza',
  remindLatecomers: 'Przypomnij spóźnialskim',
  sendNotificationsToSelectedOnes: 'Wyślij powiadomienia do wybranych',
  'taskCancelationConfirm?': 'Czy na pewno anulować zadanie?',
  'archiveTaskConfirmation?': 'Czy zarchiwizować wybrane zadania?',
  // filters
  area: 'obszar',
  withAnAsterisk: 'Gwiazdka',
  statusPDCA: 'Status PDCA',
  PDCANew: 'Nowe',
  responsible: 'Odpowiedzialny',
  author: 'Autor ',
  teamsAndGroups: 'Zespoły i grupy',
  taskPriorities: 'Priorytety zadań',
  typesOfTasks: 'Rodzaje zadań',
  idCodeName: 'Kod ID, nazwa',
  created: 'Data utworzenia',
  startDate: 'Data rozpoczęcia',
  timeLimitForCompletion: 'Termin realizacji',
  implementation: 'Realizacja',
  hidden: 'Ukryte',
  plan: 'Plan',
  starMy: 'Moja',
  starMyTeams: 'Moich zespołów',
  starAsterisk: 'Moich obszarów',
  starWithout: 'Bez gwiazdki',
  activeFilter: 'Aktywne',
  archivedFilter: 'Zarchiwizowane',
  canceledFilter: 'Anulowane',
  additionalPermissions: 'Uprawnienia dodatkowe',
  'Can perform some administrative tasks':
    'Może wykonywać niektóre czynności administracyjne',
  'Disabling and enabling user account activity':
    'Wyłączanie i włączanie aktywności kont użytkowników',
  deletingTasksInSubordinatesAreas: 'Usuwanie zadań w podległych obszarach',
  deletingTasksInAllAreas: 'Usuwanie zadań we wszystkich obszarach',
  'Access to the translation table': 'Dostęp do tabeli tłumaczeń',
  'Translation table preview': 'Podgląd tabeli tłumaczeń',
  languagesEditing: 'Edycja języków',
  // actions
  filtersSaved: 'Ustawienia filtra są zapisane',
  // table
  'task.table.star': '★ Gwiazdka',
  'task.table.practice': 'Praktyka',
  'task.table.name': 'Nazwa',
  'task.table.taskType': 'Rodzaj zadania',
  'task.table.responsible': 'Odpowiedzialny',
  'task.table.area': 'Obszar',
  'task.table.status': 'Status',
  'task.table.deadlineRealisation': 'Termin realizacji',
  'task.table.timeRealisation': 'Czas realizacji',
  'task.table.daysCount': 'Liczba dni',
  // table tooltips
  'task.table.tooltips.responsible': 'Kliknij, aby zobaczyć zadania tej osoby',
  //form
  taskName: 'Nazwa zadania',
  Priority: 'Priorytet',
  TaskType: 'Rodzaj zadania',
  participants: 'Uczestnicy',
  'Teams, shifts, groups': 'Zespoły i grupy',
  carryOut: 'Realizuj',
  planning: 'Planowanie',
  plannedStartDate: 'Planowana data rozpoczęcia',
  plannedTime: 'Planowany czas',
  'realizationOnly (D)': 'Tylko realizacja (D)',
  taskDescription: 'Opis zadania',
  taskParticipants: 'Uczestnicy zadania',
  teamLeader: 'Lider zespołu',
  addTeamMember: 'Dodaj członka zespołu',
  teamManager: 'Manager zespołu',
  addFromTeamOrGroup: 'Dodaj z zespołu lub grupy',
  teamMembers: 'Członkowie zespołu',
  'role/function': 'Rola / funkcja',
  starForTeamInstruction:
    'Włącza gwiazdkę wszystkim osobom z zespołu, realizującego to zadanie. Nowo dodane do zadania osoby też bedą ją miały włączoną.',
  'turn on the star?': 'Oznaczyć gwiazdką?',
  forMe: 'Dla mnie',
  forTeam: 'Dla zespołu',
  forArea: 'Dla obszaru',
  'Area(s)': 'Obszar(y)',
  'justification for the star': 'Uzasadnienie dla gwiazdki',
  'add another file/document': 'Dodaj inny plik / dokument',
  photos: 'Zdjęcia',
  TaskPhotosAndDocuments: 'Zdjęcia i dokumenty zadania',
  documents: 'Dokumenty',
  notebook: 'Notatki',
  commentTitle: 'Komentarze',
  'removeTask?': 'Czy chcesz usunąć to zadanie?',
  notifyParticipants: 'wyślij powiadomienia do uczestników',
  taskNameRequired: 'Nazwa zadania jest wymagana',
  taskDescriptionRequired: 'Opis zadania jest wymagany',
  'hrs.': 'godz.',
  'min.': 'min.',
  active: 'Aktywne',
  archived: 'Zarchiwizowane',
  canceled: 'Anulowane'
};

export const administration = {
  administrationLabel: 'Admin',
  company: 'Firma',
  companySettingsDescription:
    'Zarządzaj danymi firmy, licencjami, uprawnieniami',
  employeesAndUsers: 'Pracownicy i użytkownicy',
  employeesAndUsersSettingsDesc1ription:
    'Zarządzaj użytkownikami, ich rolami, uprawnieniami i dostępem itp.',
  areasAndOrganizationStructure: 'Obszary i struktura organizacji',
  areasAndOrganizationStructureSettingsDescription:
    'Zarządzaj obszarami i strukturą organizacji',
  tasks: 'Zadania',
  tasksSettingsDescription:
    'Zarządzaj zadaniami, ich rodzajami, kolorowaniem, podświetlaniem',
  audits: 'Audyty',
  auditsSettingsDescription:
    'Twórz systemowe formularze audytowe i zarządzaj nimi',
  translations: 'Tłumaczenia',
  translationsSettingsDescription:
    'Dodawanie i zmiana języków, edycja tłumaczeń, dostęp dla tłumaczy',
  otherSettingsListsDictionaries: 'Pozostałe ustawienia, listy, słowniki',
  companyData: 'Dane firmy',
  licencesAndPermission: 'Licencje i dostęp',
  usersPermission: 'Dostęp użytkowników',
  defaultSettings: 'Ustawienia domyślne',
  storage: 'Magazyn',
  contactAndHelp: 'Kontakt i wsparcie',
  fullCompanyName: 'Pełna nazwa firmy',
  shortCompanyName: 'Skrócona nazwa firmy',
  'unit/workplace': 'Jednostka / zakład',
  streetAndNumber: 'Ulica, numer',
  postCode: 'Kod pocztowy',
  city: 'Miasto',
  country: 'Kraj',
  NIPNumber: 'Nr NIP',
  KRSNumber: 'Nr KRS',
  website: 'Strona www firmy',
  officeContactData: 'DANE KONTAKTOWE BIURA',
  emailAddress: 'Adres e-mail',
  contactPeopleData: 'DANE OSÓB KONTAKTOWYCH',
  fullname: 'Imię i nazwisko',
  addPerson: 'Dodaj osobę',
  sendLogo: 'Prześlij logo',
  materialsLicensesAndPermission:
    'LICENCJE, DOSTĘP DO MATERIAŁÓW I METODOLOGII',
  materialsPermissionSettings: 'Ustawienia dostępu do materiałów:',
  global: 'globalne',
  byStructure: 'wg struktury',
  total: 'razem',
  admin: 'admin',
  'usr.': 'użytk.',
  guest: 'gość',
  usersLicensesCount: 'Liczba licencji (użytkowników)',
  usedLicensesCount: 'Liczba wykorzystanych licencji',
  licenseExpirationDate: 'Data wygaśnięcia licencji',
  extendOrChange: 'Przedłuż lub zmień',
  availableBlocks: 'Dostępne etapy',
  customizeBlocks: 'Dostosuj klocki',
  availableVersions: 'Dostępne wersje',
  selectAll: 'Zaznacz wszystkie:',
  toEveryone: 'Do wszystkich:',
  materialsLanguages: 'JĘZYKI MATERIAŁÓW',
  license: 'LICENCJA',
  update: 'Aktualizuj',
  leadershipDevelopmentAndChangeManagement:
    'Rozwój Liderów i Zarządzanie Zmianami',
  motivationAndProvidingOpportunity: 'Motywacja i Zapewnienie Możliwości',
  'Processes,Structures,RolesandResponsibilities':
    'Procesy, Struktury, Role i Odpowiedzialności',
  effectiveTeams: 'Efektywne Zespoły',
  '5S': '5S',
  continuousImprovement: 'Ciągłe Doskonalenie',
  safetyAndEnvironment: 'Bezpieczeństwo i Środowisko',
  manufacturingProcessManagement: 'Zarządzanie Procesami Produkcji',
  maintenanceManagement: 'Zarządzanie Utrzymaniem Ruchu',
  supplyChainManagement: 'Zarządzanie Łańcuchem Dostaw',
  rangeOfAccessToDiffrentUserAccounts:
    'ZAKRESY DOSTĘPU DLA RÓŻNYCH KONT UŻYTKOWNIKÓW',
  'Bydefault,eachtypeofuserhasaccesstotheareatowhichheisassignedandtoitssub-areas':
    'Domyślnie każdy typ konta użytkownika ma dostęp do obszaru, do którego jest przypisany oraz do jego podobszarów, np.:',
  addedattheGrouplevelhasaccesstoallfactoriesinthestructure:
    'dodany na poziomie Grupy ma dostęp do wszystkich lokalizacji / fabryk w strukturze,',
  addedatthefactorylevelhasaccesstoallareasofthefactory:
    'dodany na poziomie lokalizacji / fabryki ma dostęp do wszystkich obszarów tej lokalizacji / fabryki,',
  'addedatthedepartmentlevelhasaccesstoitsdepartmentanditssub-areas':
    'dodany na poziomie działu ma dostęp do swojego działu oraz znajdujących się w nim podobszarów.',
  Usingthecheckboxesbelowyoucanadjustthisscopeofaccess:
    'Za pomocą pól wyboru poniżej można ten zakres dostępu dostosować.',
  lawOfView: 'PRAWO PODGLĄDU',
  lawOfEdit: 'PRAWO EDYCJI',
  otherBookmarksInGroup: 'inne zakłady w Grupie',
  holeCompanyAreasTree: 'całe drzewo obszarów firmy',
  mineAndEqualAreas: 'swój obszar i równorzędne',
  mineAndLevelsBelowAreas: 'swój obszar i poziomy poniżej',
  user: 'użytkownik',
  consultant: 'konsultant',
  'creating assessments, audits': 'tworzenie ocen, audytów',
  'adding data (comments, photos) to existing ratings, audits':
    'dodawanie danych (komentarze, zdjęcia) do istniejących ocen, audytów',
  diagrams: 'wykresy',
  'plans, reports': 'plany, raporty',
  notifications: 'powiadomienia',
  'access to documents, templates, examples':
    'dostęp do dokumentów, szablonów, przykładów',
  'adding your own documents, templates, examples':
    'dodawanie własnych dokumentów, szablonów, przykładów',
  'changes in the structure of the company': 'zmiany w strukturze firmy',
  'access to the administration panel': 'dostep do panelu admina',
  'adding, blocking, unblocking users':
    'dodawanie, blokowanie, odblokowywanie użytkowników',
  'making changes to the settings': 'wprowadzanie zmian w ustawieniach',
  'The editing right allows you to create assessments, audits, charts, plans, reports and available areas':
    'Prawo edycji pozwala na tworzenie ocen, audytów, wykresów, planów, raportów dla dostępnych obszarów.',
  restoreDefault: 'Przywróć domyślne',
  legend: 'Legenda',
  lackOfAccess: 'brak dostępu',
  preview: 'podgląd',
  downloadOption: 'możliwość pobrania',
  selectedItems: 'wybrane elementy',
  'creating, editing': 'tworzenie, edycja',
  'FUNCTIONAL USER RIGHTS': 'FUNKCJONALNE UPRAWNIENIA UŻYTKOWNIKÓW',
  'Uses users middle name': 'Używaj drugiego imienia użytkowników',
  'USER CODES AND MAIL DOMAINS': 'KODY UŻYTKOWNIKÓW I DOMENY MAILOWE',
  'Data for own users': 'Dane dla użytkowników własnych',
  'Email domain': 'Domena mailowa',
  'user code': 'Kod użytk.',
  'next number': 'numer kolejny',
  'DATA FOR PARTNERS AND SUBCONTRACTORS':
    'DANE DLA FIRM PARTNERSKICH I PODWYKONAWCÓW',
  companyName: 'Nazwa firmy',
  'Feature / Service': 'Funkcja / usługa',
  LOCATION: 'LOKALIZACJA',
  'The localization parameters set here override the default localization settings assigned to the main application language.':
    'Ustawiane tu parametry lokalizacji nadpisują domyślne ustawienia lokalizacyjne, przypisane do głównego języka aplikacji.',
  LocaleID: 'Locale ID',
  currencySymbol: 'Symbol waluty',
  'First day of the week': 'Pierwszy dzień tygodnia',
  'Measuring units': 'Jednostki miary',
  'Calendar of holidays and days off': 'Kalendarz świąt i dni wolnych',
  color: 'kolor',
  'area(s)': 'Obszar(y)',
  'Import holidays to the calendar': 'Importuj święta do kalendarza',
  add: 'Dodaj',
  'Default language for new users': 'Domyślny język dla nowych użytkowników',
  mainAppLanguage: 'główny język aplikacji',
  'Interface languages available': 'Dostępne języki interfejsu',
  'Default session duration (minutes)': 'Domyślny czas trwania sesji (minut)',
  'Only use corporate email addresses': 'Używaj tylko firmowych adresów e-mail',
  'Along with the subscription, disk space for data research and the full amount of space for storing files - photos, documents, attachments, etc. are provided. You can provide any large storage for files on your servers or in the cloud service, providing access data below.':
    'Wraz z subskrypcją zapewniana jest przestrzeń dyskowa dla baz danych oraz pewna ilość miejsca do przechowywania plików – zdjęć, dokumentów, załączników itp. Możecie Państwo zapewnić dowolnie duży magazyn na pliki na swoich serwerach lub w usłudze chmurowej, podając dane dostępowe poniżej.',
  'Storage for storing files - photos, documents, switches, etc.:':
    'Magazyn do przechowywania plikóww – zdjęć, dokumentów, załączników itd.:',
  'Platform providers': 'dostawcy Platformy',
  'own or cloud': 'własny lub chmurowy',
  'Disk usage': 'Wykorzystanie dysku',
  'Access data:': 'Dane dostępowe:',
  login: 'login',
  'Check the connection': 'Sprawdź połączenie',
  'Migrate files': 'Migruj pliki',
  'ACCOUNT MANAGER': 'OPIEKUN KLIENTA',
  'PLATFORM ADMINISTRATOR / TECHNICAL CONTACT':
    'ADMINISTRATOR PLATFORMY / KONTAKT TECHNICZNY',
  CONSULTANT: 'KONSULTANT',
  newPerson: 'Dodaj osobę',

  'STRUCTURE OF AREAS': 'STRUKTURA OBSZARÓW',
  submissive: 'Podobszar',
  'Add a parent area': 'Dodaj obszar nadrzędny',
  areaCode: 'Kod obszaru',
  areaOwner: 'Właściciel obszaru',
  'active?': 'Aktywny?',
  deleteArea: 'Usuń obszar',
  name: 'Nazwa',
  description: 'Opis',
  'You add a subarea to:': 'Dodajesz podobszar do: ',
  'Add a subarea': 'Dodaj podobszar',

  OnlyUseCorporateEmailAddressesTooltip:
    'Zapobiega podaniu przez użytkownika adresu mailowego spoza domeny firmy (np. @gmail.com, @wp.pl,...), jako adresu do komunikacji – oznacza to, że maile m.in. z powiadomieniami i linkami do działań w obrębie Platformy będą wysyłane tylko na podane poniżej domeny pocztowe firmy.',

  stage: 'Etap',
  topics: 'Tematy',
  noCompanyData: 'Brak danych firmy',
  check: 'Sprawdź',
  role: 'Rola'
};

export const translationsView = {
  translations: 'Tłumaczenia',
  importTranslations: 'Importuj tłumaczenia',
  importedLanguage: 'Importowany język',
  addLanguage: 'Dodaj język',
  editLanguage: 'Edytuj język',
  'authorized to edit translations': 'Uprawnieni do edycji tłumaczeń',
  updateTranslation: 'Zaktualizuj tłumaczenie',
  tableHeaders: {
    number: 'Nr',
    idLong: 'Identyfikator',
    id: 'ID',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    email: 'Adres e-mail',
    PE: 'P/E',
    languagesEditing: 'Edycja języków',
    company: 'Firma',
    type: 'Typ konta',
    lastLogging: 'Ostatnie logowanie',
    lastActivity: 'Ostatnia aktywność',
    idetificator: 'Identyfikator',
    en: 'angielski',
    pl: 'polski',
    un: 'ukraiński',
    ru: 'rosyjski'
  },
  form: {
    language: 'Język',
    dateFormat: 'Format daty',
    localeId: 'LocaleID',
    separators: 'Separatory',
    decimal: 'dziesiętny',
    thousandth: 'tysięcy',
    country: 'Państwo',
    firstWeekDay: 'Pierwszy dzień tygodnia',
    languageFlag: 'Flaga języka',
    placeForFlag: 'Flaga',
    preview: 'Podgląd',
    activeLanguageInInterface: 'Język aktywny w interfejsie',
    personsAuthorizedToEdit: 'Osoby uprawnione do edycji jezyka',
    show: 'Pokazuj',
    tooltips: {
      language: 'Wpisz nazwę języka po angielsku',
      localeId:
        'Wybierz / podaj LocaleID, jeżeli potrzebne są różne warianty lokalizacji, np. en_GB, en_US. „Pokazuj” włącza widoczność locale na listach wyboru języków.',
      flag: 'Wczytaj plik .PNG, .GIF lub .BMP z flagą o rozmiarze XXX x YYY pikseli',
      flagFile: 'Wczytaj plik z flagą'
    }
  }
};

export const employeesAndUsersView = {
  usersList: 'Lista użytkowników',
  rolesAndFunctions: 'Role i funkcje',
  teamsAndGroups: 'Zespoły i grupy',
  importUsersList: 'Importuj listę użytkowników',
  shortInstruction:
    'Krótka instrukcja – zapisz szablon, uzupełnij dane, zaimportuj',
  errorsRaport: 'Raport błędów',
  addUser: 'Dodaj użytkownika',
  addTeam: 'Dodaj zespół',
  add: 'Dodaj',
  editRole: 'Edytuj rolę / funkcję',
  messageForTeam: 'Wiadomość do zespołu',
  team: 'Zespół',
  leader: 'Lider',
  specialPermissions: 'Uprawnienia specjalne',
  tableHeaders: {
    active: 'Aktywny?',
    id: 'ID',
    photo: 'Zdjęcie',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    emailAddress: 'Adres e-mail',
    userName: 'Nazwa użytkownika',
    accountType: 'Typ konta',
    area: 'Obszar',
    logo: 'Logo',
    teamName: 'Nazwa zespołu',
    leader: 'Lider',
    company: 'Firma',
    lastLogging: 'Ostatnie logowanie',
    lastActivity: 'Ostatnia aktywność',
    administrativeActivities: 'Czynności administr.',
    accountActivity: 'Aktywność konta',
    deletingTasksInAreas: 'Usuw. zadań w obszarach:',
    subordinate: 'podległych',
    all: 'wszystkich',
    accessToTranslations: 'Dostęp do tłumaczeń',
    PE: 'P/E',
    languagesEditing: 'edycja języków',
    email: 'Adres e-mail',
    type: 'Typ konta'
  },
  form: {
    photoPlaceholder: 'Miejsce na miniaturkę zdjęcia',
    companyName: 'Nazwa firmy',
    firstName: 'Imię',
    secondName: 'Drugie imię',
    lastName: 'Nazwisko',
    emailAddress: 'Adres e-mail',
    phone: 'Telefon stacjonarny',
    mobilePhone: 'Telefon komórkowy',
    userName: 'Nazwa użytkownika',
    password: 'Hasło',
    language: 'Język',
    mainArea: 'Główny obszar',
    roleInCompany: 'Funkcja w firmie',
    usingInAreas: 'Używane w obszаrach',
    usingInAreasHelperText: 'pozostaw puste, gdy cała firma',
    editorPlaceholder: 'Wpisz text...',
    onlyToLeader: 'tylko do lidera',
    toAllMembers: 'do wszystkich członków',
    teamName: 'Nazwa zespołu',
    id: 'ID',
    teamLeader: 'Lider zespołu',
    teamManager: 'Manager zespołu',
    areas: 'Obszar(y)',
    chooseCompany: 'Wybierz firmę',
    chooseArea: 'Wybierz obszar',
    search: 'Szukaj',
    filter: 'Filtruj',
    administrative: 'administracyjne',
    translations: 'tłumaczenia',
    areYouSureDeleteTeam: 'Czy na pewno chcesz usunąć zespół?',
    edit: 'edytuj'
  }
};

export const administrationTasksView = {
  TASKS: 'ZADANIA',
  tasksSettings: 'Ustawienia zadań',
  tasksRealizationProgressFollowedBySteps:
    'Postęp realizacji zadań śledzony wg kroków',
  'Dates given when confirming PDCA steps can be simple':
    'Daty potwierdzania kroków realizacji zadań mogą być przeszłe',
  'When creating a task, also notify the creator via':
    'Przy tworzeniu zadania powiadamiaj także tworzącego poprzez',
  'Highlighting tasks in the list':
    'Kolory podświetlenia zadań w tabeli, zależnie od czasu do końca',
  '- color (default Yellow) - time to end':
    '– kolor (domyślnie żółty) – czas do końca',
  '- color (default red) - time to end':
    '– kolor (domyślnie czerwony) – czas do końca',
  '- color (default burgundy) - time to end':
    '– kolor (domyślnie bordowy) – czas do końca',
  'Highlight color in the archived task table':
    'Kolor podświetlenia w tabeli zadań zarchiwizowanych',
  'The highlight color in the canceled job table':
    'Kolor podświetlenia w tabeli zadań anulowanych',
  tasksTypes: 'Rodzaje zadań',
  priorities: 'Priorytety',
  clickToChooseColor: 'Kliknij, by wybrać kolor: ',
  code: 'Kod',
  name: 'Nazwa',
  'active?': 'Aktywny?',
  usedInAreas: 'Używane w obszarach',
  followingStatusBy: 'Śledzenie statusu wg',
  addTaskType: 'Dodaj rodzaj zadania',
  editTaskType: 'Edytuj rodzaj zadania',
  realizationProgressesToolTip:
    'PDCA i DMAIC służą do śledzenia postępu realizacji zadań w systemie. Wszystkie nowe zadania bez zdefiniowanego rodzaju śledzone będą domyślnie wg wybranej metody.',

  taskTypeTableHeaders: {
    active: 'Aktywny?',
    taskType: 'Rodzaj zadania',
    status: 'Status wg',
    requiresApproval: 'Wymaga zatwierdzenia',
    stepsToApprove: 'Kroki do zatwierdzenia',
    whoApproves: 'Kto zatwierdza'
  }
};

export const administrationAuditsView = {
  AUDITS: 'AUDYTY',
  forms: 'Formularze',
  inactive: 'nieaktywne',
  archived: 'archiwalne',
  addAuditForm: 'Dodaj formularz audytu',
  editAuditForm: 'Edytuj formularz audytu',
  designing: 'Projektowanie',
  version: 'Wersja',
  pinToFormMethodologyPracticeArea:
    'Przypnij formularz do metodyki / praktyki / obszaru',
  createDate: 'Data utworzenia',
  lastChange: 'Ostatnia zmiana',
  goal: 'Cel',
  goodAboveBelow: 'Dobrze powyżej / poniżej?',
  createdBy: 'Utworzył(a)',
  authorizedToEdit: 'Uprawnieni do edycji',
  active: 'Aktywny?',
  'closed?': 'Zamknięty?',
  requireConfirmation: 'Wymaga zatwierdzenia?',
  panel: 'Panel',
  basicSettings: 'Ustawienia podstawowe',
  additionalSettings: 'Ustawienia dodatkowe',
  enforceCompatibility: 'Wymuszaj kompletność',
  enforceCompatibilityToolTip:
    'Włącza weryfikację kompletności audytu – jeżeli tak, aby skończyć audyt muszą w nim być ocenione wszystkie kryteria i muszą być spełnione wszystkie wymagania co do ocen kryteriów (np. komentarze, zadania).',
  maxTasksRealizationTime: 'Max czas realizacji zadań:',
  maxTasksRealizationTimeTooltip:
    'Ustawia czas, który będzie zakresem wykresu analitycznego zadań powiązanych z tym audytem.',
  allowCopyCopyingDoneAudit:
    'Przy kopiowaniu zrobionego audytu zezwalaj na kopiowanie:',
  allowCopyCopyingDoneAuditToolTip:
    'Określ, co może być kopiowane podczas kopiowania zrobionego już audytu do nowego obszaru.',
  answers: 'odpowiedzi',
  attachments: 'załączników',
  comments: 'komentarzy',
  auditHeading: 'Nagłówek audytu',
  name: 'Nazwa',
  content: 'Zawartość',
  field: 'Pole',
  codeAndFormName: 'Kod i nazwa formularza',
  days: 'Dni',
  weeks: 'Tygodni',
  months: 'Miesiące',
  formName: 'Nazwa formularza',
  area: 'Obszar',
  team: 'Zespół',
  shift: 'Zmiana',
  practice: 'Praktyka',
  methodology: 'Metodologia',
  process: 'Proces',
  audit_name: 'Proces',
  usedInAreas: 'Używane w obszarach',
  auditCriteria: 'Kryteria audytu',
  auditIntroductionInsertText: 'wprowadzenie do audytu – wpisz tekst',
  example: 'Przykład',
  subgroup: 'Podgrupa',
  group: 'Grupa',
  ratingScale: 'Skala oceny',
  criterion: 'Kryterium',
  showResults: 'Pokazuj wyniki',
  ratingScales: 'Skale oceny',
  ratingScalePreview: 'Podgląd skali',
  addCiterionDetails: 'Dodaj szczegóły do kryterium',
  tipsToDoEvaluation: 'Wskazówki do wykonywania oceny',
  addRatingScale: 'Dodaj skalę oceny',
  ratingScaleName: 'Nazwa skali oceny',
  consequences: 'Guziki',
  textType: 'tekstowe',
  pictorial: 'obrazkowe',
  text: 'Tekst:',
  value: 'Wartość:',
  color1: 'Kolor 1:',
  color2: 'Kolor 2:',
  ratingButtons: 'Guziki oceny:',
  evaluationInAudit: 'Ocena w audycie:',
  picture: 'Obrazek:',
  'frame?': 'Ramka?',
  'useThese?': 'Użyć tych?',
  'active?': 'Aktywne?',
  setNrManually: 'Ustaw numer ręcznie',
  omitInNumbering: 'Pomiń w numeracji',
  preview: 'Podgląd',
  radarChartOnThisLevel: 'Wykres radarowy \nna tym poziomie',
  copyForm: 'Kopiuj formularz',
  color1Tooltip:
    'Ten kolor stosowany jest do treści i ramki guzika oceny, wartości oceny w audycie, oraz słupków i pól na wykresach statystyki audytu.',
  color2Tooltip:
    'Ten kolor stosowany jest jako wypełnienie guzika po podświetleniu go oraz wypełnienie pola oceny w audycie.',

  tableHeaders: {
    active: 'Aktywny?',
    'practice/area/form': 'Praktyka / obszar / formularz',
    version: 'Wersja',
    usedInAreas: 'Używane w obszarach'
  },

  criteriaTableHeaders: {
    nr: 'Nr',
    evaluationCriteria: 'Kryteria oceny',
    weight: 'Waga',
    scaleDesc: 'Opis skali',
    tips: 'Wskazówki',
    example: 'Przykład'
  },

  ratingScaleTableHeaders: {
    active: 'Aktywny?',
    scaleName: 'Nazwa skali',
    levels: 'Stopni',
    preview: 'Podgląd',
    usedInForms: 'Używane w formularzach'
  }
};

export const documentsView = {
  practicesPlatT: 'Praktyki PlatT',
  areas: 'Obszary',
  searchIn: 'Szukaj w:',
  selectedBlock: 'wybranym klocku',
  inAll: 'we wszystkich',
  results: 'Wyników:',
  collapseBlocks: 'Zwiń klocki',
  expandBlocks: 'Rozwiń klocki',
  collapseSearchResults: 'Zwiń wyniki',
  expandSearchResults: 'Rozwiń wyniki',
  closeSearchResults: 'Zamknij wyniki',
  methodology: 'Metodyka',
  actions: 'Działania',
  examples: 'Przykłady',
  companyFiles: 'Pliki firmy',
  implementationActivities: 'Działania wdrożeniowe',
  run: 'Uruchom',

  tableHeaders: {
    number: 'Nr',
    activityName: 'Nazwa działania',
    area: 'Obszar',
    responsible: 'Odpowiedzialny',
    task: 'Zadanie',

    launchedFor: 'Uruchomione dla',
    status: 'Status',
    startDate: 'Data rozpoczęcia',
    redlizationDate: 'Termin realizacji',
    numberOfDays: 'Liczba dni',

    block: 'Klocek',
    document: 'Dokument / plik',
    date: 'Data',
    size: 'Rozmiar',
    addedBy: 'Dodane przez'
  },
  displayingCommentInfo:
    'Automatyczne pokazwyanie komentarzy można wyłączyć w ustawieniach profilu użytkownika',
  showInAllLanguages: 'Pokaż we wszystkich językach',
  showInMyLanguage: 'Pokaż w moim języku',

  sendLinkModal: {
    sendLink: 'Wyślij link',
    sendTo: 'Wyślij do',
    sendMessage: 'Wyślij wiadomość',
    system: 'systemową',
    email: 'e-mail',
    withCopy: 'z kopią dla mnie'
  }
};

export const evaluationsView = {
  evaluation: 'Ocena',
  change: 'Zmiana',
  team: 'Zespół',
  area: 'Obszar',
  audit: 'Audyt',
  practiceBasedAssessments: 'Oceny wg praktyki',
  evaluationConnectedTasksList: 'Lista zadań powiązanych z oceną',
  ratingsByDate: 'Oceny wg daty',
  audits: 'Audyty',
  blocks: 'Klocki',
  topics: 'Tematy',
  radar: 'Radar',
  data: 'Dane',
  operators: 'Operatorzy',
  management: 'Kierownictwo',

  practiceEvaluation: 'Ocena praktyki',
  topic: 'Temat',
  block: 'Klocek',

  nr: 'Nr',
  evaluationCriteria: 'Kryteria oceny',
  gradingScale: 'Skala ocen',
  standards: 'Standardy',
  guidelines: 'Wskazówki do wykonywania ocen',

  alwaysShow: 'Zawsze pokazuj',
  hints: 'Wskazówki',

  addAnArea: 'Dodaj obszar',
  chooseAreaWhichWantToAdd: 'Wybierz obszar(y), które chcesz dodać do oceny',

  tableHeaders: {
    type: 'Typ',
    number: 'Numer',
    evaluationDate: 'Data oceny',
    deadLine: 'Termin realizacji',
    lastUpdate: 'Ostatnia aktualizacja',
    evaluator: 'Oceniający',
    name: 'Nazwa',
    version: 'Wersja',
    result: 'Wynik',
    status: 'Status',
    group: 'Grupa',
    evaluationLeader: 'Lider oceny'
  },

  gradingScaleDescription: {
    '3': 'Clear maintenance strategy exists for brewing and packaging and it is well aligned with the global strategy',
    '1': 'There is a local (site) maintenance strategy, but not sufficiently linked it with the global strategy',
    '0': 'Global strategy not available on site, no local maintenance strategies (brewing and packaging)'
  },

  createEvaluation: 'Utwórz ocenę',
  editEvaluation: 'Edytuj ocenę',
  evaluationSubmitButton: 'Utwórz',
  selectEvaluationType: 'Wybierz typ oceny',
  selectEvaluationVersion: 'Wybierz wersję oceny',
  selectPractice: 'Wybierz praktykę',
  selectArea: 'Wybierz obszar',
  'deleteEvaluation?': 'Usunać tą ocenę?',
  evaluationTypes: {
    target: 'Cel',
    baseline: 'Odniesienia',
    current: 'Bieżąca',
    derivative: 'Pochodna',
    normal: 'Zwykła'
  },
  evaluationStatusModal: {
    closingEvaluation: 'Zamykanie oceny',
    table: {
      headers: {
        role: 'Rola',
        person: 'Osoba',
        isClosed: 'Zamknięta'
      }
    }
  },
  criteriaListModal: {
    openedCriteriasList: 'Lista otwartych kryteriów',
    table: {
      headers: {
        topic: 'Temat',
        stage: 'Etap',
        block: 'Klocek',
        criterion: 'Kryterium'
      }
    }
  },
  criterionTasksModal: {
    criterionTasks: 'Zadania kryterium',
    launchedTasks: 'Uruchomione zadania',
    predefinedTasks: 'Predefiniowane zadania',
    table: {
      headers: {
        name: 'Nazwa',
        description: 'Opis',
        responsible: 'Odpowiedzialny',
        area: 'Obszar'
      }
    }
  },
  actionsMenu: {
    newEvaluation: 'Nowa ocena praktyki w zaznaczonych obszarach',
    newAudit: 'Nowy audyt w zaznaczonych obszarach',
    newForm: 'Nowy formularz dla zaznaczonych obszarów',
    newDerivative: 'Nowa ocena pochodna dla zaznaczonego obszaru',
    importEvaluation: 'Importuj plik oceny lub audytu'
  }
};

export const createEvalutionView = {
  leader: 'Lider'
};

export const auditsView = {
  createAudit: 'Utwórz audyt',
  editAudit: 'Edytuj audyt',
  chart: 'Wykres',
  auditSummaryAndStatistics: 'Podsumowanie i statystyki audytu',
  criteriaEvaluation: 'Ocena kryteriów',
  criterionNumber: 'Numer kryterium',
  area: 'Obszar:',
  team: 'Zespół:',
  shift: 'Zmiana:',
  auditResult: 'Wynik audytu:',
  from: 'z',
  pnt: 'pkt.',
  finish: 'Zakończ',
  'deleteAudit?': 'Usunąć audyt?',

  tableHeaders: {
    type: 'Typ',
    auditForm: 'Formularz audytu',
    number: 'Numer',
    auditDate: 'Data audytu',
    deadLine: 'Termin realizacji',
    lastUpdate: 'Ostatnia aktualizacja',
    auditsLeader: 'Lider audytów',
    name: 'Nazwa',
    version: 'Wersja',
    result: 'Wynik',
    status: 'Status',
    team: 'Zespół'
  },
  previewTableHeaders: {
    number: 'Nr',
    evaluationCriteria: 'Kryteria oceny',
    evaluation: 'Ocena',
    comment: 'Komentarz'
  },
  mainThingsToImprove: 'Głowne obszary do poprawy i rozwoju',
  sum: 'Razem:',
  comment: 'Komentarz',
  show: 'Pokaż',
  closingAudit: 'Zamykanie audytu'
};

export const createAuditView = {
  proccess: 'Proces',
  combineProcessAuditPackage: 'Powiąż w pakiet audytu procesu',
  auditLeaderAuditor: 'Lider audytu / główny audytor',
  planSeries: 'Zaplanuj serię',
  sendInvitations: 'Wyślij zaproszenia'
};

export const createTaskView = {
  activities: 'działania',
  auditName: 'Nazwa audytu',
  createdBy: 'Utworzył(a)',
  auditForm: 'Formularz audytu',
  taskTitle: 'Utwórz zadanie',
  evaluationTitle: 'Utwórz ocenę',
  practice: 'Praktyka',
  footer: 'Wymaga zatwierdzenia?',
  isAssignedToPractice: 'Przypisane do praktyki?',
  areas: 'Obszar(y)',
  copy: 'Kopiuj',
  responsible: 'Odpowiedzialny',
  author: 'Autor',
  participants: 'Użytkownicy',
  manage: 'Zarządzaj',
  taskName: 'Nazwa zadania',
  evaluationName: 'Nazwa oceny',
  description: 'Opis',
  priority: 'Priorytet',
  taskType: 'Rodzaj zadania',
  teams: 'Zespoły i grupy',
  planning: 'Planowanie',
  plannedCommencementDate: 'Planowana data rozpoczęcia',
  completionDate: 'Termin realizacji',
  scheduledTime: 'Planowany czas godz./min.',
  onlyCompletion: 'Tylko realizacja (D)',
  completion: 'Realizacja',
  actualCommencementDate: 'Faktyczna data rozpoczęcia',
  actualCompletionDate: 'Faktyczna data zakończenia',
  completionTime: 'Czas realizacji',
  version: 'Wersja',
  evaluationOnLevel: 'Ocena na poziomie',
  status: 'Status',
  associateWith: 'Powiąż z',
  auditTeam: 'Zespół audytowy',
  evaluationType: 'Typ',
  evaluationScore: 'Wynik oceny',
  isFinished: 'Skończona',
  isClosed: 'Zamknięta',
  show: 'Pokaż',
  launch: 'Uruchom',
  tasks: 'Zadania'
};

export const buttons = {
  highlightBlocks: 'Podświetl klocki',
  add: 'Dodaj',
  change: 'Zmień',
  remove: 'Usuń',
  import: 'Importuj',
  close: 'Zamknij',
  saveTemplate: 'Zapisz szablon',
  cancel: 'Anuluj',
  ok: 'OK',
  save: 'Zapisz',
  saveAndClose: 'Zapisz i zamknij',
  delete: 'Usuń',
  sendMessage: 'Wyślij wiadomość',
  addNew: 'Dodaj nowy',
  importTranslation: 'Importuj tłumaczenie',
  exportToExcel: 'Eksportuj do Excela',
  entitled: 'Uprawnieni',
  addLanguage: 'Dodaj język',
  specialLicenses: 'Uprawnienia specjalne',
  addPerson: 'Dodaj osobę',
  manage: 'Zarządzaj',
  turnOn: 'Włącz',
  choose: 'Wybierz',
  download: 'Pobierz'
};

export const pagination = {
  show: 'Pokazuję',
  from: 'z',
  positions: 'pozycji',
  next: 'Następna',
  previous: 'Poprzednia',
  rowsPerPage: 'Wierszy na stronę',
  page: 'Strona',
  rows: 'wierszy'
};

export const criteriaView = {
  exampleForCriterion: 'Przykłady dla kryterium',
  attachmentsForCriterion: 'Załączniki dla kryterium',
  addFile: {
    title: 'Dodaj plik',
    selectFile: 'Wybierz plik',
    preview: 'Podgląd',
    fileNameDescription: 'Tu pojawia się nazwa pliku',
    documentName: 'Nazwa dokumentu',
    documentDescription: 'Opis dokumentu',
    link: 'Link',
    comment: 'Komentarz'
  },
  editFile: {
    title: 'Edytuj plik'
  }
};

export const loginView = {
  platform: 'Platforma',
  ofTransformation: 'Transformacji',
  welcome: 'Zapraszamy'
};

export default {
  common,
  nav,
  auth,
  profile,
  tasks,
  administration,
  employeesAndUsersView,
  buttons,
  pagination,
  translationsView,
  administrationTasksView,
  administrationAuditsView,
  documentsView,
  evaluationsView,
  createEvalutionView,
  auditsView,
  createAuditView,
  criteriaView,
  createTaskView,
  desktop,
  loginView
};
