import React, { useEffect, useState } from 'react';
import { Flex } from 'components/Flex';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from 'components/Box';

export interface IMenuItem {
  path: string;
  label: string;
  private: boolean;
  type?: string;
  icon?: JSX.Element;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
  view?: 'horizontal' | 'vertical';
}
interface IListItem {
  active?: boolean;
}

const NavigationLink = styled(NavLink)`
  text-align: center;
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.primary};
    text-decoration: none;
    font-size: 14px;

    :focus-visible {
      outline: 1px solid ${theme.palette.neutral.mainBlue};
    }
  `};
`;
const ListItem = styled(Flex)<IListItem>`
  text-transform: Uppercase;
  ${({ theme, active }) => `
    height: 60px;
    min-width: 130px;
    border-radius: 6px;
    font-weight: 700;
    background-color: ${
      active ? theme.palette.primary.main : theme.palette.neutral.light
    };
    color:${active && theme.palette.neutral.white};
    fill:${active ? theme.palette.neutral.white : theme.palette.neutral.grey};

    &:hover {    
      background-color: ${theme.palette.primary.main};        
      color: ${theme.palette.neutral.white};     
      fill: ${theme.palette.neutral.white};     
    }
  `}
`;

export const Menu = ({
  items,
  onMenuIconClick,
  view = 'horizontal'
}: IMenu) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState<string>(pathname || '');

  const handleOnClick = () => {
    onMenuIconClick();
  };

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label, icon }) => {
      const isItemSelected = active === path;

      return (
        <NavigationLink to={path} key={label}>
          <ListItem
            active={isItemSelected}
            px={3}
            py={2}
            m={1}
            alignItems="center"
            justifyContent="center"
            onClick={() => handleOnClick()}
            flexDirection="column"
          >
            {icon && (
              <Box mb={1} alignItems="start">
                {icon}
              </Box>
            )}
            {t(label)}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Flex width="100%" flexDirection={view === 'horizontal' ? 'column' : 'row'}>
      {renderItems()}
    </Flex>
  );
};
