import { IGenericModal, Modal } from 'components/Modal/Modal';
import { Checkbox } from 'components/_form';
import { useTranslation } from 'react-i18next';

interface Props extends IGenericModal {
  onSubmit: () => void;
}

export const DeleteAuditModal = ({
  isOpen,
  onCancelClick,
  onSubmit
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      gridTemplateColumns="45%"
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('auditsView.deleteAudit?')}
      mainButton={{
        action: onSubmit,
        variant: 'eucalyptus',
        label: t('common.yes')
      }}
      isSecondButtonVisible
    >
      <Checkbox checked={true} label="tasks.notifyParticipants" />
    </Modal>
  );
};
