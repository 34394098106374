import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './TeamsAndGroupsFilter.styled';
import { Select } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { IOption } from '../../../../../../../components/_form/Select/Select';
import { getTeams } from 'api/teams';

export const TeamsAndGroupsFilter: React.FC = () => {
  const [teams, setTeams] = useState<IOption[]>([]);
  const { t } = useTranslation();

  const {
    filters: { teamsAndGroups },
    setFilters
  } = useTaskSidebarContext();

  const selected = () =>
    teams.filter((el) => teamsAndGroups.value.includes(el.value));

  const handleOnChange = (val: IOption[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        teamsAndGroups: {
          label:
            val.length > 0
              ? t('tasks.teamsAndGroups') +
                ' - ' +
                val.map((el) => el.label).join(', ')
              : '',
          value: val.map((el) => el.value)
        }
      };
    });
  };

  const handleLoadData = useCallback(async () => {
    const { data } = await getTeams();
    setTeams(data.map(({ id, name }) => ({ label: name, value: String(id) })));
  }, []);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  return (
    <Wrapper>
      <Select
        isMulti
        options={teams}
        selectedOptions={selected()}
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};
