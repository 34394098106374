import { TabList } from 'react-tabs';
import styled from 'styled-components';

export const CustomTabList = styled(TabList)<{
  isMarginBottomDisabled?: boolean;
}>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.light};

  ${({ isMarginBottomDisabled }) =>
    isMarginBottomDisabled ? 'margin-bottom: 0;' : 'margin-bottom: 10px;'};
`;
