import styled from 'styled-components';
import { Flex } from '../../../../../../../components';

export const Wrapper = styled.div`
  position: relative;
  padding: 0 4px;
`;

export const Item = styled(Flex)<{ iconColor?: string }>`
  margin: 10px 0;
  cursor: pointer;
  padding: 4px 6px;
  align-items: center;
  position: relative;

  > p {
    font-size: 12px;
    font-weight: bold;
  }
`;
