import { FC, useState } from 'react';
import { Box, Flex, Spinner } from 'components';
import { Button, TextField } from 'components/_form';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from 'validation/authSchemas';
import { ILogin } from 'types/forms/auth';
import { login } from '../api/auth';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { P } from 'components/Typography/Typography';

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 400px;
  min-width: 230px;
  margin: 0 auto;
`;

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm<ILogin>({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: '',
        password: ''
      }
    },
    resolver: yupResolver(loginSchema)
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await login(data);
      if (!response) return;
      localStorage.setItem('auth-token', response.token);
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      navigate('/app/desktop');
    } catch (err: any) {
      toast.error(err?.message || t('anErrorOccurred'));
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper justifyContent="center" flexWrap="wrap" flexDirection="column">
      <form onSubmit={onSubmit}>
        <TextField
          label="common.username"
          transparent
          {...register('user.email')}
          error={t(errors?.user?.email?.message || '')}
        />
        <Box mt={2} mb={2}>
          <TextField
            type="password"
            label={t('auth.password')}
            transparent
            {...register('user.password')}
            error={t(errors?.user?.password?.message || '')}
          />
        </Box>

        <Box display="flex">
          <Link to="/password-reset">
            <P variant="body2" color="grey">
              {t('auth.forgotPass')}
            </P>
          </Link>
        </Box>

        <Box my={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button fullWidth type="submit" disabled={!formState.isValid}>
              {t('auth.login')}
            </Button>
          )}
        </Box>
      </form>
    </Wrapper>
  );
};
