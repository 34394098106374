import { Box, Flex } from 'components';
import { BackButton } from 'components/BackButton';
import { H2 } from 'components/Typography/Typography';
import { FC, useEffect, useState } from 'react';
import { Select } from 'components/_form';
import { IPractice } from 'types/methologies';
import { getPractice } from 'api/methodologies';
import { Blocks } from 'components/Blocks';

interface Props {
  selectedPractice: IPractice;
  setSelectedPractice: React.Dispatch<
    React.SetStateAction<IPractice | undefined>
  >;
}

export const AdjustBlocks: FC<Props> = ({
  selectedPractice,
  setSelectedPractice
}) => {
  const [practice, setPractice] = useState<IPractice>();

  const getPractise = async () => {
    if (selectedPractice) {
      const { data } = await getPractice(String(selectedPractice.id));
      setPractice(data);
    }
  };

  useEffect(() => {
    getPractise();
  }, [selectedPractice.id]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <BackButton
          customOnClick={() => {
            setSelectedPractice(undefined);
          }}
        />
        <H2 variant="h2" ml={2}>
          {selectedPractice.name}
        </H2>
        <Box ml={4} pt={3}>
          <Select options={[]} />
        </Box>
      </Flex>

      {practice && <Blocks practice={practice} />}
    </Flex>
  );
};
