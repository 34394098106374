import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button, Radio, TextField } from 'components/_form';
import { t } from 'i18next';
import { DonutChart } from 'react-circle-chart';
import { theme } from 'styles';

export const StorageForm = () => {
  return (
    <Flex p={4} flexDirection="column">
      <P variant="body">
        {t(
          'administration.Along with the subscription, disk space for data research and the full amount of space for storing files - photos, documents, attachments, etc. are provided. You can provide any large storage for files on your servers or in the cloud service, providing access data below.'
        )}
      </P>
      <P mt={3} variant="body" fontWeight={700}>
        {t('administration.Storage for storing files - photos, documents, switches, etc.:')}
      </P>

      <Flex pl={[0, 4]}>
        <Flex p={2} justifyContent="space-between" width={['100%', '50%']}>
          <Radio label="administration.Platform providers" />
          <Radio label="administration.own or cloud" />
        </Flex>
      </Flex>

      <Flex flexWrap="wrap">
        <Flex
          mt={3}
          width={['100%', '40%']}
          flexDirection="column"
          alignItems="center"
        >
          <P variant="body" fontWeight={700}>
            {t('administration.Disk usage')}
          </P>
          <DonutChart
            tooltipSx={{ zIndex: '100' }}
            size="md"
            trackColor={theme.palette.neutral.lightGrey}
            items={[
              {
                value: 25,
                label: 'used',
                color: theme.palette.accent.green
              }
            ]}
            tooltipFontSize="18"
          />
        </Flex>
        <Flex p={3} width={['100%', '60%']} flexDirection="column">
          <P mb={3} variant="body" fontWeight={700}>
            {t('administration.Access data:')}
          </P>

          <TextField label="Endpoint" />

          <Flex flexWrap="wrap">
            <Flex pt={3} width={['100%', '50%']} alignItems="start">
              <Radio />
              <Flex ml={2} flexDirection="column">
                <Flex>
                  <Box width="80px">
                    <P variant="body">{t('administration.login')}</P>
                  </Box>

                  <Box>
                    <TextField />
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Box width="80px">
                    <P variant="body">{t('auth.password')}</P>
                  </Box>

                  <Box mt={3}>
                    <TextField />
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Flex pt={3} width={['100%', '50%']} alignItems="start">
              <Radio />
              <Flex ml={2} flexDirection="column">
                <Flex mb={4}>
                  <Box width="60px">
                    <P variant="body">{t('key')}</P>
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  <Box mt={4}>
                    <input type="file" />
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex justifyContent="space-evenly" alignItems="center" pt={2}>
            <Button>{t('administration.Check the connection')}</Button>
            <FontAwesomeIcon icon={faCircle} />
            <Button variant="eucalyptus">{t('administration.Migrate files')}</Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
