import { useTranslation } from 'react-i18next';
import { IRatingScale } from 'types/ratingScales';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { format } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { IAuditFormCriterion, IAuditScore } from 'types/audits';
import { useMemo } from 'react';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';

interface Props {
  ratingScale?: IRatingScale;
  scores?: Partial<IAuditScore>[];
  criteria: Partial<IAuditFormCriterion>[];
  audit?: PartialAudit;
}

export const BarChart: React.FC<Props> = ({
  audit,
  scores,
  criteria,
  ratingScale
}) => {
  const { t } = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,

    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t('auditsView.criteriaEvaluation'),
        position: 'left' as const
      }
    },

    scales: {
      y: {
        suggestedMax: 3
      }
    }
  };

  const labels = criteria.map((criterion) => criterion.nr);

  const values = useMemo(
    () =>
      criteria.map(
        (criterion) =>
          ratingScale?.buttons.find((button) =>
            scores?.find((scoreAttr) => {
              return (
                scoreAttr.audit_criterion_id === criterion.id &&
                scoreAttr.rating_button_id === button.id
              );
            })
          )?.value || 0.05
      ),
    [
      JSON.stringify(criteria),
      JSON.stringify(ratingScale?.buttons),
      JSON.stringify(scores)
    ]
  );

  const colors = criteria.map(
    (criterion) =>
      ratingScale?.buttons.find((button) =>
        scores?.find(
          (scoreAttr) =>
            scoreAttr.audit_criterion_id === criterion.id &&
            scoreAttr.rating_button_id === button.id
        )
      )?.color1
  );

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: values,
        backgroundColor: colors,
        maxBarThickness: 20
      }
    ]
  };

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <P variant="h3" ml={4}>
          {audit?.first_header_name}
        </P>
        <Flex flexDirection="column">
          {audit?.second_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.area')}</P>
              <P variant="h4" color="grey">
                {audit?.second_header_name}
              </P>
            </Flex>
          )}
          {audit?.third_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.team')}</P>
              <P variant="h4" color="grey">
                {audit?.third_header_name}
              </P>
            </Flex>
          )}
          {audit?.fourth_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.shift')}</P>
              <P variant="h4" color="grey">
                {audit?.fourth_header_name}
              </P>
            </Flex>
          )}

          <Flex justifyContent="end" alignItems="center">
            <P variant="h4">{format(new Date(), dateFormat)}</P>
          </Flex>
        </Flex>
      </Flex>
      <Bar width="500px" options={options} data={data} />
      <P variant="body">{t('auditsView.criterionNumber')}</P>
    </Flex>
  );
};
