import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';

export const Container = styled(Flex)`
  position: relative;
`;

export const BlockContainer = styled(Flex)<{
  active?: boolean;
  block?: boolean;
  coloured?: boolean;
  variant?: 'normal' | 'big';
  width?: string;
  height?: string;
  bgColor?: string;
  selectedBgColor?: string;
}>`
  width: ${({ variant, width }) =>
    variant === 'big' ? '140px' : width || '70px'};
  height: ${({ variant, height }) =>
    variant === 'big' ? '100px' : height || '50px'};
  background-color: ${({ theme, coloured, bgColor, selectedBgColor }) =>
    coloured
      ? selectedBgColor || theme.palette.accent.eucalyptus
      : bgColor || theme.palette.accent.creme};
  border: 2px solid ${({ theme }) => theme.palette.neutral.dark};
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, coloured }) =>
    coloured ? theme.palette.neutral.white : theme.palette.neutral.dark};
  ${({ block }) => (block ? 'cursor: pointer;' : '')};
  opacity: ${({ block, active }) => (block ? (active ? 1 : 0.4) : 0)};

  position: ${({ variant }) => (variant === 'big' ? 'absolute' : 'relative')};
  z-index: ${({ variant }) => (variant === 'big' ? '10' : '1')};

  ${({ variant }) =>
    variant === 'big' &&
    `
    top: -30px;
    right: -30px;
  `}
`;

export const ButtonContainer = styled(Flex)`
  width: 18px;
  position: absolute;
  top: -10px;
`;

export const StyledP = styled(P)`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
