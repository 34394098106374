import {
  faChartPie,
  faCommentDots,
  faPaperclip,
  faRunning
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { Line } from 'components/Line';
import { H3 } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';
import { IRatingScale } from 'types/ratingScales';
import { BarChart } from './components/BarChart';
import { IAuditFormCriterion, IAuditScore } from 'types/audits';
import { PieChart } from './components/PieChart';
import { useMemo } from 'react';
import { RadarChart } from './components/RadarChart';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';
import { RatingScaleButton } from 'components/RatingScaleButtons/RatingScaleButton';

interface Props {
  audit?: PartialAudit;
  ratingScale?: IRatingScale;
  criteria: Partial<IAuditFormCriterion>[];
  scores?: Partial<IAuditScore>[];
  radarChartData?: { [key: string]: number };
}

export const AuditSummaryStats: React.FC<Props> = ({
  audit,
  ratingScale,
  criteria,
  scores,
  radarChartData
}) => {
  const { t } = useTranslation();

  const criteriaEvaluationCounts = useMemo(() => {
    const criteriaValues = criteria.map(
      (criterion) =>
        ratingScale?.buttons.find((button) =>
          scores?.find(
            (scoreAttr) =>
              scoreAttr.audit_criterion_id === criterion.id &&
              scoreAttr.rating_button_id === button.id
          )
        )?.value || 0
    );

    const counts: { [key: string]: number } = {};

    criteriaValues.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });

    return counts;
  }, [
    JSON.stringify(criteria),
    JSON.stringify(ratingScale?.buttons),
    JSON.stringify(scores)
  ]);

  return (
    <Flex width="100%" gap="15px">
      <Flex mt={2}>
        <FontAwesomeIcon
          icon={faChartPie}
          size="2x"
          color={theme.palette.neutral.mainBlue}
        />
      </Flex>
      <Flex width="100%" flexDirection="column">
        <H3 color="coloured" variant="h3">
          {t('auditsView.auditSummaryAndStatistics')}
        </H3>
        <Line />
        <Flex width="100%" justifyContent="space-between">
          <Flex mt={4} flexDirection="column" gap="20px">
            <Flex gap="5px" flexDirection="column">
              <Flex gap="5px">
                <FontAwesomeIcon
                  icon={faRunning}
                  color={theme.palette.accent.red}
                  size="xl"
                />
                : 0
              </Flex>
              <Flex gap="5px">
                <FontAwesomeIcon
                  icon={faPaperclip}
                  color={theme.palette.accent.eucalyptus}
                  size="xl"
                />
                : 0
              </Flex>
              <Flex gap="5px">
                <FontAwesomeIcon
                  icon={faCommentDots}
                  color={theme.palette.accent.blue}
                  size="xl"
                />
                : 0
              </Flex>
            </Flex>

            <Flex flexDirection="column" gap="3px">
              {ratingScale?.buttons.map((button) => {
                const foundKey = Object.keys(criteriaEvaluationCounts).find(
                  (key) => key.toString() === button.value?.toString()
                );

                return (
                  <Flex
                    key={`AuditSummaryStats-ratingScale-buttons-${button.id}`}
                    gap="5px"
                    alignItems="center"
                  >
                    <RatingScaleButton
                      button={button}
                      showImages={ratingScale.button_type === 'picture'}
                    />
                    :
                    <span>
                      {foundKey ? criteriaEvaluationCounts[foundKey] : 0}
                    </span>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>

          <Flex
            display={['none', 'flex']}
            width="80%"
            gap="100px"
            flexDirection="column"
          >
            <Flex height="300px" width="100%">
              <BarChart
                criteria={criteria}
                scores={scores}
                ratingScale={ratingScale}
                audit={audit}
              />
            </Flex>
            <Flex gap="20px" justifyContent="space-around">
              <Flex width="300px">
                <PieChart
                  criteriaEvaluationCounts={criteriaEvaluationCounts}
                  ratingScale={ratingScale}
                  audit={audit}
                />
              </Flex>
              {radarChartData && (
                <Flex width="400px">
                  <RadarChart audit={audit} radarChartData={radarChartData} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
