import { Card, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ResizerButton = styled.div`
  height: 24px;
  width: 24px;
  z-index: 1;
  border: 1px solid darkgray;
  border-radius: 50px;
  cursor: pointer;
  top: 50px;
  left: -4px;
  position: absolute;
  background: ${({ theme }) => theme.palette.neutral.white};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: darkgray;
  }
`;

export const SidebarResizer = styled.div`
  height: 100%;
  width: 4px;
  padding: 4px 8px;
  position: relative;
  cursor: col-resize;

  ::before {
    left: 6px;
    position: absolute;
    content: '';
    display: block;
    height: 99%;
    width: 1px;
    background: darkgray;
  }
  ::after {
    right: 6px;
    position: absolute;
    content: '';
    display: block;
    height: 99%;
    width: 1px;
    background: darkgray;
  }
`;
export const Content = styled.div`
  flex: 1;
  overflow: auto;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  > ${Card}:first-child {
    margin-bottom: 12px;
  }
`;

export const ContentChildrenWrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 8px;
  padding: 14px;
  margin-bottom: 35px;
  position: relative;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;

export const CollapseButtonWrapper = styled(Flex)`
  width: 100%;
  justify-content: flex-end;
  padding-right: 50px;
`;

export const StyledBlockName = styled(P)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
