import { serialize } from 'object-to-formdata';
import { IAreaForm, IAreaPatchResponse } from 'types/forms/area';
import { IAreaResponse, IAreasResponse } from 'types/area';
import request from './index';

export const getAreas = () =>
  request<IAreasResponse>({
    options: {
      url: '/areas',
      method: 'get'
    }
  });

export const getArea = (id: string) =>
  request<IAreaResponse>({
    options: {
      url: `/areas/${id}`,
      method: 'get'
    }
  });

export const editArea = (id: string, data: IAreaForm) =>
  request<IAreaPatchResponse>({
    options: {
      url: `/areas/${id}`,
      method: 'patch',
      data: serialize(data, { allowEmptyArrays: true })
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });
