import { faCircleExclamation, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldLabel as Label } from 'components/_form/FieldLabel';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import React, { InputHTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'utilities/rgba';
import { ErrorText } from '../ErrorText';
import { DefaultTFuncReturn } from 'i18next';
import { Flex } from 'components/Flex';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';

export interface IHint {
  label: string;
  value: string;
}

export enum LabelPosition {
  DEFAULT = 'default',
  BOTTOM = 'bottom'
}

export interface TextFieldType extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | DefaultTFuncReturn;
  labelPosition?: LabelPosition;
  labelStyles?: React.CSSProperties;
  containerStyles?: React.CSSProperties;
  width?: string | string[];
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  error?: string | DefaultTFuncReturn;
  unit?: string;
  icon?: React.ReactNode;
  hints?: Array<IHint>;
  onHintClick?: (hint: IHint) => void;
  onIconClick?: (e: any) => void;
  transparent?: boolean;
  bgColor?: string;
  type?: string;
  hideError?: boolean;
  isTooltip?: boolean;
  tooltipId?: string;
  tooltipWidth?: string;
  tooltipValue?: string;
  height?: string;
  centeredValue?: boolean;
}

interface IStyledInput {
  error?: TextFieldType['error'];
  unit?: TextFieldType['unit'];
  icon?: TextFieldType['icon'];
  bgColor?: TextFieldType['bgColor'];
  displayHints?: boolean;
  transparent?: boolean;
  height?: string;
  centeredValue?: TextFieldType['centeredValue'];
}

const unitWidthInPx = 44;
const iconSizeInPx = 44;
const iconPositionRight = 4;

const Root = styled.div<{ width?: string; labelPosition?: LabelPosition }>`
  ${({ width, labelPosition }) => `
      width: ${width || '100%'};
      ${
        labelPosition === LabelPosition.BOTTOM
          ? 'display: flex; flex-direction: column-reverse'
          : ''
      }
  `};
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;
    color: ${theme.palette.neutral.medium};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  cursor: pointer;
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${iconSizeInPx}px;
    color: ${theme.palette.neutral.medium};
  `}
`;

const StyledInput = styled.input<IStyledInput>`
  ${({
    theme,
    unit,
    icon,
    displayHints,
    transparent,
    bgColor,
    error,
    height,
    centeredValue
  }) => `
    background-color: ${transparent ? 'transparent' : bgColor};
    display: block;
    width: 100%;
    height: ${height || '35px'};
    padding: 0 8px;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ''};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ''};
    font-size: 13px;
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.lightGrey};
    border-radius: 6px;
    outline: none;
    ${centeredValue ? 'text-align: center;' : ''}

    ${
      error
        ? `
        border: 1px solid ${theme.palette.accent.red};
        box-shadow: 0 0 0 3px ${theme.palette.accent.lightRed};
      `
        : ''
    }

    ${
      displayHints
        ? `border-bottom-left-radius: 0; border-bottom-right-radius: 0;`
        : ''
    }

    &:focus {
      border-radius: 4px;
      border-color: ${theme.palette.primary.main};

      + ${Unit}::before {
        background-color: ${theme.palette.primary.main};
      }

      + ${Icon} {
        color: ${theme.palette.primary.main};
      }
    }

    &:disabled, &:read-only {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

const Hints = styled.div`
  ${({ theme }) => `
    positon: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    border-top: none;
    background-color: ${theme.palette.neutral.veryLight};
  `};
`;

const Hint = styled.div`
  ${({ theme }) => `  
    height: 36px;
    overflow: auto;
    font-size: 13px;
    color: ${theme.palette.text.primary};
    border-top: none;
    border-bottom: none;
    outline: none;
    background-color: ${theme.palette.neutral.veryLight};
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: ${theme.palette.primary.main};
    }
    &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    };
  `}
`;

const LabelSpan = styled.span<{ labelPosition?: LabelPosition }>`
  ${({ theme, labelPosition }) => `
    ${
      labelPosition === LabelPosition.BOTTOM
        ? `text-transform: uppercase; color: ${theme.palette.neutral.lightGrey}`
        : ''
    }
  `}
`;

// eslint-disable-next-line react/display-name
export const TextField = React.forwardRef<HTMLInputElement, TextFieldType>(
  (
    {
      label,
      labelPosition = LabelPosition.DEFAULT,
      labelStyles,
      value,
      name,
      width,
      error,
      unit,
      icon,
      disabled,
      readOnly,
      hints,
      onHintClick,
      transparent,
      bgColor,
      onIconClick,
      onChange,
      type,
      placeholder,
      hideError,
      isTooltip,
      tooltipId,
      tooltipWidth,
      tooltipValue,
      height,
      centeredValue,
      ...props
    },
    ref
  ) => {
    const [displayHints, setDisplayHints] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const handleFocus = () => {
      setDisplayHints(true);
    };

    const handleHintClick = (hint: IHint) => {
      if (onHintClick) onHintClick(hint);
    };

    useOnClickOutside(wrapperRef, () => {
      setDisplayHints(false);
    });

    return (
      <Root width={width} labelPosition={labelPosition}>
        {label ? (
          <Label
            disabled={disabled || readOnly}
            htmlFor={name}
            isFlex={isTooltip}
          >
            <LabelSpan labelPosition={labelPosition}>{t(label)}</LabelSpan>
            {isTooltip && tooltipId && tooltipValue && (
              <Flex marginLeft="5px">
                <InfoTooltip
                  tooltipId={tooltipId}
                  tooltipValue={tooltipValue}
                  tooltipWidth={tooltipWidth}
                />
              </Flex>
            )}
          </Label>
        ) : null}
        <InputWrapper ref={wrapperRef}>
          <StyledInput
            ref={ref}
            type={showPassword ? 'text' : type || 'text'}
            name={name}
            id={name}
            error={error}
            unit={unit}
            icon={
              error ? (
                <FontAwesomeIcon color="red" icon={faCircleExclamation} />
              ) : (
                icon
              )
            }
            disabled={disabled}
            readOnly={readOnly}
            onFocus={handleFocus}
            displayHints={displayHints}
            transparent={transparent}
            bgColor={bgColor}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            height={height}
            centeredValue={centeredValue}
            {...props}
          />
          {unit && <Unit>{unit}</Unit>}
          {error ? (
            <Icon onClick={onIconClick}>
              <FontAwesomeIcon color="red" icon={faCircleExclamation} />
            </Icon>
          ) : icon ? (
            <Icon onClick={onIconClick}>{icon}</Icon>
          ) : (
            type === 'password' && (
              <Icon
                onClick={() => {
                  setShowPassword((prevShowPassword) => !prevShowPassword);
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Icon>
            )
          )}
          {displayHints && hints && hints.length !== 0 && (
            <Hints>
              {hints.map((hint) => (
                <Hint key={hint.value} onClick={() => handleHintClick(hint)}>
                  {hint.label}
                </Hint>
              ))}
            </Hints>
          )}
        </InputWrapper>
        {!hideError && error && <ErrorText>{error}</ErrorText>}
      </Root>
    );
  }
);
