import {
  faCirclePlay,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons';
import {
  faComment,
  faPaperclip,
  faPersonRunning,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Table } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';
import { IconButtonContainer } from '../FormEvaluation/FormEvaluation.styled';

interface Props extends IGenericModal {}

export const CriteriaListModal = ({ isOpen, onCancelClick }: Props) => {
  const { t } = useTranslation();

  const modalHeader = {
    title: t('evaluationsView.criteriaListModal.openedCriteriasList'),
    value: 'Ocena okresowa RLiZZ - cała fabryka, H2.2023'
  };

  const tableHeaders: string[] = [
    t('evaluationsView.criteriaListModal.table.headers.topic'),
    t('evaluationsView.criteriaListModal.table.headers.stage'),
    t('evaluationsView.criteriaListModal.table.headers.block'),
    t('evaluationsView.criteriaListModal.table.headers.criterion')
  ];

  const data = [
    {
      topic: 'Rozwój liderów biznesu',
      stage: (
        <P variant="body" textAlign="center">
          1
        </P>
      ),
      block: '2.1A Szkolenia dla kierownictwa',
      criterion:
        'Cała wyższa kadra kierownicza została przeszkolona z programu zmian PlatT (proces doskonalenia i wdrażania praktyk dla kadry zarządzającej).',
      icons: (
        <Flex flexDirection="row">
          <FontAwesomeIcon icon={faPersonRunning} size="2x" cursor="pointer" />
          <FontAwesomeIcon icon={faPaperclip} size="2x" cursor="pointer" />
          <FontAwesomeIcon icon={faComment} size="2x" cursor="pointer" />
        </Flex>
      ),
      actions: (
        <Flex flexDirection="row">
          <IconButtonContainer
            flexDirection="row"
            width="35px"
            height="35px"
            mr="5px"
          >
            <FontAwesomeIcon icon={faCirclePlay} size="2x" />
          </IconButtonContainer>
          <IconButtonContainer
            flexDirection="row"
            width="35px"
            height="35px"
            bgColor={theme.palette.neutral.white}
            border={`1px solid ${theme.palette.accent.blue}`}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              size="2x"
              color={theme.palette.accent.blue}
            />
          </IconButtonContainer>
        </Flex>
      )
    },
    {
      topic: 'Rozwój liderów biznesu',
      stage: (
        <P variant="body" textAlign="center">
          1
        </P>
      ),
      block: '2.1A Szkolenia dla kierownictwa',
      criterion:
        'Każdy pracownik rozumie sens, cele i sposoby wdrażania zmian zachodzących w firmie.',
      icons: (
        <Flex flexDirection="row">
          <FontAwesomeIcon icon={faPersonRunning} size="2x" cursor="pointer" />
          <FontAwesomeIcon icon={faPaperclip} size="2x" cursor="pointer" />
          <FontAwesomeIcon icon={faComment} size="2x" cursor="pointer" />
        </Flex>
      ),
      actions: (
        <Flex flexDirection="row">
          <IconButtonContainer
            flexDirection="row"
            width="35px"
            height="35px"
            mr="5px"
          >
            <FontAwesomeIcon icon={faCirclePlay} size="2x" />
          </IconButtonContainer>
          <IconButtonContainer
            flexDirection="row"
            width="35px"
            height="35px"
            bgColor={theme.palette.neutral.white}
            border={`1px solid ${theme.palette.accent.blue}`}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              size="2x"
              color={theme.palette.accent.blue}
            />
          </IconButtonContainer>
        </Flex>
      )
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t(`buttons.saveAndClose`)
      }}
      isSecondButtonVisible={true}
      gridTemplateColumns="95%"
      bodyMaxHeight="70vh"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {modalHeader.title}:
          </H2>
          <H2 variant="h3" color="red" mr={20}>
            {modalHeader.value}
          </H2>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Flex width="100%" justifyContent="flex-end">
          <Button variant="eucalyptus" onClick={() => {}}>
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.addPerson')}
          </Button>
        </Flex>
        <Table header={tableHeaders} data={data} />
      </Flex>
    </Modal>
  );
};
