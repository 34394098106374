import {
  faCirclePlus,
  faFileImport,
  faFileLines,
  faLandmark,
  faListCheck,
  faTableList
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { Button } from 'components/_form';
import { useState } from 'react';
import { theme } from 'styles';
import { CreateEvaluationModal } from '../EvaluationActionsModal/CreateEvaluationModal';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useEvaluationContext } from 'contexts/EvaluationContext';

export const ActionsMenu = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { setIsCreateAuditModalOpen } = useEvaluationContext();

  const { t } = useTranslation();

  return (
    <>
      <Flex gap="5px" justifyContent="space-between">
        <Button
          variant="greyWhite"
          bordered
          icon={
            <Flex
              alignItems="end"
              id="new-evaluation"
              data-tooltip-content={
                t('evaluationsView.actionsMenu.newEvaluation') as string
              }
            >
              <FontAwesomeIcon
                icon={faLandmark}
                size="xl"
                color={theme.palette.accent.green}
              />
              <FontAwesomeIcon
                icon={faCirclePlus}
                size="xs"
                color={theme.palette.accent.green}
              />
              <Tooltip anchorId="new-evaluation" />
            </Flex>
          }
          onClick={() => {
            setIsOpenModal(true);
          }}
        />
        <Button
          variant="greyWhite"
          bordered
          onClick={() => {
            setIsCreateAuditModalOpen(true);
          }}
          icon={
            <Flex
              alignItems="end"
              id="new-audit"
              data-tooltip-content={
                t('evaluationsView.actionsMenu.newAudit') as string
              }
            >
              <FontAwesomeIcon
                icon={faListCheck}
                size="xl"
                color={theme.palette.accent.blue}
              />
              <FontAwesomeIcon
                icon={faCirclePlus}
                size="xs"
                color={theme.palette.accent.blue}
              />
              <Tooltip style={{ zIndex: 9 }} anchorId="new-audit" />
            </Flex>
          }
        />
        <Button
          variant="greyWhite"
          bordered
          icon={
            <Flex
              alignItems="end"
              id="new-form"
              data-tooltip-content={
                t('evaluationsView.actionsMenu.newForm') as string
              }
            >
              <FontAwesomeIcon
                icon={faTableList}
                size="xl"
                color={theme.palette.accent.red}
              />
              <FontAwesomeIcon
                icon={faCirclePlus}
                size="xs"
                color={theme.palette.accent.red}
              />
              <Tooltip style={{ zIndex: 9 }} anchorId="new-form" />
            </Flex>
          }
        />
        <Button
          variant="greyWhite"
          bordered
          icon={
            <Flex
              alignItems="end"
              id="new-derivative"
              data-tooltip-content={
                t('evaluationsView.actionsMenu.newDerivative') as string
              }
            >
              <FontAwesomeIcon
                icon={faFileLines}
                size="xl"
                color={theme.palette.accent.brown}
              />
              <FontAwesomeIcon
                icon={faCirclePlus}
                size="xs"
                color={theme.palette.accent.red}
              />
              <Tooltip style={{ zIndex: 9 }} anchorId="new-derivative" />
            </Flex>
          }
        />
        <Button
          variant="greyWhite"
          bordered
          icon={
            <Flex
              alignItems="end"
              id="import-evaluation"
              data-tooltip-content={
                t('evaluationsView.actionsMenu.importEvaluation') as string
              }
            >
              <FontAwesomeIcon
                icon={faFileImport}
                size="xl"
                color={theme.palette.accent.steelBlue}
              />
              <Tooltip style={{ zIndex: 9 }} anchorId={'import-evaluation'} />
            </Flex>
          }
        />
      </Flex>

      <CreateEvaluationModal
        isOpen={isOpenModal}
        onCancelClick={() => setIsOpenModal(false)}
      />
    </>
  );
};
