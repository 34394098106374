import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';

export const CellTextValueWrapper = styled(Flex)<{
  numberOfLines?: number;
  lineHeight?: string;
  cursor?: string;
}>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ numberOfLines }) =>
    numberOfLines ? numberOfLines : 3};
  -webkit-box-orient: vertical;
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')};
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : 'cursor: default;')};
`;

export const ItemTextField = styled(P)`
  font-size: inherit;
  line-height: inherit;
`;
