import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { Button, Select } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ITeamOption } from '../FormEvaluation/FormEvaluation';
import { FormDataControl } from '../CreateEvaluationModal';

interface Props extends IGenericModal {
  control: Control<FormDataControl, any>;
  usersList: IOption[];
  teamsList: ITeamOption[];
  userFunctionsList: IOption[];
  watch: UseFormWatch<FormDataControl>;
  setValue: UseFormSetValue<FormDataControl>;
  saveAndClose: () => void;
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  th,
  td {
    text-align: left;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
    padding: 10px 15px;
  }
`;

export const EvaluationParticipantsModal = ({
  isOpen,
  onCancelClick,
  control,
  usersList,
  watch,
  setValue,
  saveAndClose,
  teamsList,
  userFunctionsList
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      gridTemplateColumns="50%"
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('tasks.taskParticipants')}
      mainButton={{
        action: saveAndClose,
        variant: 'eucalyptus',
        label: t('buttons.saveAndClose')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column">
        <Flex>
          <Flex p={1} width="50%" flexDirection="column">
            <Controller
              control={control}
              name="leader_id"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('createEvalutionView.leader')}
                  selectedOptions={usersList.find(
                    (userOption) => userOption.value === String(value)
                  )}
                  onChange={(val: IOption) => {
                    onChange(val.value);
                  }}
                  options={usersList}
                />
              )}
            />
            <Controller
              control={control}
              name="team_leader_id"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('tasks.teamLeader')}
                  selectedOptions={usersList.find(
                    (userOption) => userOption.value === String(value)
                  )}
                  onChange={(val: IOption) => {
                    onChange(val.value);
                  }}
                  options={usersList}
                />
              )}
            />

            <Controller
              control={control}
              name="evaluation_participations_attributes"
              render={({ field: { onChange, value } }) => (
                <Select
                  isMulti
                  hideElements
                  label={t('tasks.addTeamMember')}
                  selectedOptions={value}
                  onChange={(val: IOption[]) => {
                    onChange(val);
                  }}
                  options={usersList}
                />
              )}
            />
          </Flex>

          <Flex p={1} width="50%" flexDirection="column">
            <Controller
              control={control}
              name="author_id"
              render={({ field: { onChange, value } }) => (
                <Select
                  disabled
                  label={t('tasks.author')}
                  selectedOptions={usersList.find(
                    (userOption) => userOption.value === value
                  )}
                  onChange={(val: IOption) => {
                    onChange(val.value);
                  }}
                  options={usersList}
                />
              )}
            />
            <Controller
              control={control}
              name="team_manager_id"
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('tasks.teamManager')}
                  selectedOptions={usersList.find(
                    (userOption) => userOption.value === String(value)
                  )}
                  onChange={(val: IOption) => {
                    onChange(val.value);
                  }}
                  options={usersList}
                />
              )}
            />
            <Controller
              control={control}
              name="evaluation_participations_attributes"
              render={({ field: { onChange, value } }) => (
                <Select
                  isMulti
                  hideElements
                  label={t('tasks.addFromTeamOrGroup')}
                  selectedOptions={
                    teamsList.filter((teamItem) =>
                      teamItem.members.every((teamMember) => {
                        !!value?.find(
                          (evaluationParticipationAttribute) =>
                            evaluationParticipationAttribute.value ===
                            teamMember.id?.toString()
                        );
                      })
                    ) || []
                  }
                  onChange={(val: ITeamOption[]) => {
                    const newParticipants = [
                      ...(value || []),
                      ...(val || [])
                        .map((team) =>
                          team.members?.map((member) => ({
                            value: member.id,
                            label: `${member.first_name || '-'} ${
                              member.last_name || '-'
                            }`
                          }))
                        )
                        .flat()
                    ].filter(
                      // remove duplicates
                      (value, index, self) =>
                        index === self.findIndex((t) => t.value === value.value)
                    );

                    onChange(newParticipants);
                  }}
                  options={teamsList}
                />
              )}
            />
          </Flex>
        </Flex>

        <Flex maxHeight={280} overflow="auto">
          <StyledTable>
            <thead>
              <th>
                <P variant="body">{t('tasks.teamMembers')}</P>
              </th>
              <th>
                <P variant="body">{t('tasks.role/function')}</P>
              </th>
              <th></th>
            </thead>
            <tbody>
              {watch('evaluation_participations_attributes')?.map(
                (evaluationParticipationAttribute) => (
                  <tr key={evaluationParticipationAttribute.value}>
                    <td>
                      <Flex alignItems="center">
                        <Box mr={2}>
                          <Avatar size="extra-small" />
                        </Box>
                        <P variant="body">
                          {evaluationParticipationAttribute.label}
                        </P>
                      </Flex>
                    </td>
                    <td align="center">
                      <Flex mt={2}>
                        <Select
                          selectedOptions={userFunctionsList.find(
                            (functionItem) =>
                              functionItem.value ===
                              evaluationParticipationAttribute.function_id
                          )}
                          onChange={(val: IOption) => {
                            setValue(
                              'evaluation_participations_attributes',
                              watch(
                                'evaluation_participations_attributes'
                              )?.map((mapEvaluationParticipationAttribute) =>
                                mapEvaluationParticipationAttribute.value ===
                                evaluationParticipationAttribute.value
                                  ? {
                                      ...mapEvaluationParticipationAttribute,
                                      function_id: val.value
                                    }
                                  : mapEvaluationParticipationAttribute
                              ) || []
                            );
                          }}
                          options={userFunctionsList}
                        />
                      </Flex>
                    </td>
                    <td>
                      <Button
                        variant="red"
                        icon={<FontAwesomeIcon icon={faUserSlash} />}
                        onClick={() => {
                          setValue(
                            'evaluation_participations_attributes',
                            watch(
                              'evaluation_participations_attributes'
                            )?.filter(
                              (filterEvaluationParticipationsAttribute) =>
                                filterEvaluationParticipationsAttribute.value !==
                                evaluationParticipationAttribute.value
                            ) || []
                          );
                        }}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </StyledTable>
        </Flex>
      </Flex>
    </Modal>
  );
};
