import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getRealizationProgresses,
  patchRealizationProgress
} from 'api/realizationProgresses';
import { Box, Flex } from 'components';
import { Disabled } from 'components/Disabled/Disabled';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { P } from 'components/Typography/Typography';
import { Button, Radio, SwitchButton } from 'components/_form';
import { ColorPicker } from 'components/_form/ColorPicker/ColorPicker';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { IRealizationProgress } from 'types/realizationProgresses';

export const TasksSettingsForm = () => {
  const [realizationProgresses, setRealizationProgresses] = useState<
    IRealizationProgress[]
  >([]);

  const handleGetRealizationProgresses = async () => {
    const realizationProgressesRes = await getRealizationProgresses();

    if ('error' in realizationProgressesRes) {
      return;
    }

    if (realizationProgressesRes.data) {
      setRealizationProgresses(realizationProgressesRes.data);
    }
  };

  const handleSetDefaultRealizationProgress = async (id: number) => {
    const payload = {
      realization_progress: {
        default: true
      }
    };

    const patchRealizationProgressRes = await patchRealizationProgress(
      id,
      payload
    );

    if (patchRealizationProgressRes.data) {
      setRealizationProgresses((prevRealizationProgresses) =>
        prevRealizationProgresses.map((prevRealizationProgress) => ({
          ...prevRealizationProgress,
          default:
            prevRealizationProgress.id === patchRealizationProgressRes.data.id
        }))
      );
    }
  };

  const handleSetAvailableKindsToBeFromPast = async (
    realizationProgress: IRealizationProgress,
    AvailableKindToChange: string
  ) => {
    const { available_kinds_to_be_from_past } = realizationProgress;

    const payload = {
      realization_progress: {
        available_kinds_to_be_from_past:
          available_kinds_to_be_from_past.includes(AvailableKindToChange)
            ? available_kinds_to_be_from_past.filter(
                (availableKindToBeFromPast) =>
                  availableKindToBeFromPast !== AvailableKindToChange
              )
            : [...available_kinds_to_be_from_past, AvailableKindToChange]
      }
    };

    const patchRealizationProgressRes = await patchRealizationProgress(
      realizationProgress.id,
      payload
    );

    if (patchRealizationProgressRes.data) {
      setRealizationProgresses((prevRealizationProgresses) =>
        prevRealizationProgresses.map((prevRealizationProgress) =>
          prevRealizationProgress.id === patchRealizationProgressRes.data.id
            ? patchRealizationProgressRes.data
            : prevRealizationProgress
        )
      );
    }
  };

  useEffect(() => {
    handleGetRealizationProgresses();
  }, []);

  return (
    <Flex width={['100%', '80%']} flexDirection="column" gap="5px">
      <Flex
        my={2}
        width="100%"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        <P variant="body" fontWeight={700}>
          {t('administrationTasksView.tasksRealizationProgressFollowedBySteps')}
          :
        </P>

        <Flex
          gap="50px"
          mx="auto"
          mt={3}
          alignItems="center"
          flexDirection={['column', 'row']}
        >
          {realizationProgresses.map((realizationProgress) => (
            <Flex
              key={`TasksSettingsForm-realizationProgresses-${realizationProgress.id}`}
            >
              <Radio
                checked={!!realizationProgress.default}
                onClick={() =>
                  handleSetDefaultRealizationProgress(realizationProgress.id)
                }
              />
              <RealizationProgress
                activeAll
                realizationProgress={realizationProgress}
              />
            </Flex>
          ))}

          <InfoTooltip
            tooltipId="TasksSettingsForm-realizationProgresses-tooltip"
            tooltipValue={t(
              'administrationTasksView.realizationProgressesToolTip'
            )}
            tooltipWidth="300px"
          />
        </Flex>
      </Flex>
      <Flex
        my={4}
        width="100%"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        gap="10px"
      >
        <P variant="body" fontWeight={700}>
          {t(
            'administrationTasksView.Dates given when confirming PDCA steps can be simple'
          )}
          :
        </P>

        <Flex flexDirection="column" gap="20px">
          {realizationProgresses.map((realizationProgress) => (
            <Flex
              key={`TasksSettingsForm-realizationProgresses-${realizationProgress.id}`}
              gap="50px"
              flexWrap="wrap"
            >
              {realizationProgress.kind.split('').map((kindLetter) => (
                <SwitchButton
                  key={`TasksSettingsForm-realizationProgresses-switch-${kindLetter}`}
                  label={kindLetter}
                  value={realizationProgress.available_kinds_to_be_from_past.includes(
                    kindLetter
                  )}
                  onChange={() => {
                    handleSetAvailableKindsToBeFromPast(
                      realizationProgress,
                      kindLetter
                    );
                  }}
                />
              ))}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Disabled>
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={['column', 'row']}
          gap="5px"
        >
          <P variant="body">
            {t(
              'administrationTasksView.When creating a task, also notify the creator via'
            )}
            :
          </P>

          <SwitchButton label="system" />
          <SwitchButton label="e-mail" />
        </Flex>

        <Flex my={3} flexDirection={['column', 'row']}>
          <P variant="body" color="red" fontWeight={700} mr={4}>
            {t('administrationTasksView.Highlighting tasks in the list')}
          </P>
          <Flex flexDirection="column">
            <P variant="body" color="red" fontWeight={700}>
              {t(
                'administrationTasksView.- color (default Yellow) - time to end'
              )}
            </P>
            <P variant="body" color="red" fontWeight={700}>
              {t('administrationTasksView.- color (default red) - time to end')}
            </P>
            <P variant="body" color="red" fontWeight={700}>
              {t(
                'administrationTasksView.- color (default burgundy) - time to end'
              )}
            </P>
          </Flex>
        </Flex>

        <Flex
          width={['100%', '70%']}
          flexDirection="column"
          alignItems="center"
        >
          <Flex my={4} width="100%" justifyContent="space-between">
            <P variant="body">
              {t(
                'administrationTasksView.Highlight color in the archived task table'
              )}
            </P>

            <Flex>
              <Box mr={4}>
                <ColorPicker />
              </Box>

              <Button variant="eucalyptus">
                <Flex>
                  <Box mr={1}>
                    <FontAwesomeIcon icon={faRotate} />
                  </Box>
                  {t('profile.default')}
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <P variant="body">
              {t(
                'administrationTasksView.The highlight color in the canceled job table'
              )}
            </P>

            <Flex>
              <Box mr={4}>
                <ColorPicker />
              </Box>

              <Button variant="eucalyptus">
                <Flex>
                  <Box mr={1}>
                    <FontAwesomeIcon icon={faRotate} />
                  </Box>
                  {t('profile.default')}
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Disabled>
    </Flex>
  );
};
