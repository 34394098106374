import {
  faCirclePlay,
  faClipboard,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { Button } from 'components/_form';
import { IAudit, IDetailedAudit } from 'types/audits';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { useCallback, useState } from 'react';
import { AuditCriteriaModal } from '../AuditFormModal/FormAudit/AuditCriteriaModal/AuditCriteriaModal';
import { getAudit } from 'api/audits';
import { Loader } from 'components/Loader/Loader';

interface Props {
  audit: IAudit;
}

export const OptionButtons: React.FC<Props> = ({ audit }) => {
  const [loading, setLoading] = useState(false);
  const [selectedAudit, setSelectedAudit] = useState<IDetailedAudit>();
  const {
    setSelectedAuditId,
    setIsEditAuditModalOpen,
    isAuditCriteriaModalOpen,
    setIsAuditCriteriaModalOpen,
    handleLoadAudits
  } = useEvaluationContext();

  const onLaunchClick = useCallback(async () => {
    setLoading(true);
    const auditSubmitResponse = await getAudit(audit.id);

    if (!auditSubmitResponse) return;

    if (auditSubmitResponse) {
      setSelectedAudit(auditSubmitResponse.data);
      setIsAuditCriteriaModalOpen(true);
    }
    setLoading(false);
  }, [audit.id, setIsAuditCriteriaModalOpen]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Flex style={{ gap: '10px' }} mx="auto" width="fit-content">
      <Button
        variant="green"
        onClick={onLaunchClick}
        icon={<FontAwesomeIcon icon={faCirclePlay} />}
      />
      <Button
        variant="grey"
        bordered
        onClick={() => {}}
        icon={<FontAwesomeIcon icon={faClipboard} />}
      />
      <Button
        onClick={() => {
          setIsEditAuditModalOpen(true);
          setSelectedAuditId(audit.id);
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />

      {selectedAudit && (
        <AuditCriteriaModal
          isOpen={isAuditCriteriaModalOpen}
          onCancelClick={() => {
            setIsAuditCriteriaModalOpen(false);
          }}
          audit={selectedAudit}
          setAudit={setSelectedAudit}
          onFinishAuditSuccess={(newAudit) => {
            setIsAuditCriteriaModalOpen(false);

            handleLoadAudits();
          }}
        />
      )}
    </Flex>
  );
};
