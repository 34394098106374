import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  comment: string;
  isOpen: boolean;
  onCancelClick: () => void;
}

const Wrapper = styled(Flex)`
  position: absolute;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.neutral.medium};
  border-radius: 6px;
  -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
`;

const Header = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.medium};
`;

const XButton = styled(Box)`
  justify-self: flex-end;
  padding: 10px;
  cursor: pointer;
  color: black;
`;

export const CommentModal: React.FC<Props> = ({
  isOpen,
  onCancelClick,
  comment
}) => {
  const { t } = useTranslation();

  return isOpen ? (
    <Wrapper flexDirection="column" px={2} pb={2}>
      <Header justifyContent="space-between" alignItems="center" mb={3}>
        <P variant="h4">{t(`auditsView.comment`)}</P>
        <XButton onClick={onCancelClick}>
          <FontAwesomeIcon size="xl" icon={faXmark} />
        </XButton>
      </Header>
      <Flex gap="10px" maxHeight="50px" overflow="auto">
        {comment}
      </Flex>
    </Wrapper>
  ) : (
    <></>
  );
};
