import { Flex, Typography } from 'components';
import { ReactComponent as TextLogoIcon } from 'assets/icons/textLogo.svg';
import styled from 'styled-components';
import logos from 'assets/images/eu-logos.jpg';
import { useState } from 'react';
import { P } from 'components/Typography/Typography';
import { InfoModal } from 'views/InfoModal';
import { useTranslation } from 'react-i18next';

const StyledWrapper = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.neutral.lightGrey};
`;

const StyledH1 = styled(Typography.H1)`
  text-transform: uppercase;
  font-family: Candara, Helvetica, sans-serif;
  font-size: 44px;
  line-height: 44px;
`;

const StyledH2 = styled(Typography.H2)`
  font-family: Candara, Helvetica, sans-serif;
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const Description = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <>
      <StyledWrapper flexDirection="column">
        <Flex pt={3} pl={3}>
          <TextLogoIcon />
        </Flex>

        <Flex height="100%" justifyContent="center" alignItems="center">
          <Flex flexDirection="column" p={2}>
            <StyledH1 fontWeight={700} variant="h1" color="red">
              {t('loginView.platform')}
            </StyledH1>
            <StyledH1
              fontWeight={700}
              variant="h1"
              color="green"
              marginBottom="30px"
            >
              {t('loginView.ofTransformation')}
            </StyledH1>
            <StyledH2 variant="h2"> {t('loginView.welcome')}!</StyledH2>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          gap="10px"
          p="20px"
          onClick={() => setIsOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          <P variant="body" textAlign="center">
            Sfinansowano w ramach reakcji Unii na pandemię COVID 19, realizujemy
            projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA 6.2 WSPARCIE MŚP
            W OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W RAMACH PROGRAMU
            OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
          </P>
          <Img src={logos} alt="eu-logos" />
        </Flex>
      </StyledWrapper>
      <InfoModal isOpen={isOpen} onCancelClick={() => setIsOpen(false)} />
    </>
  );
};
