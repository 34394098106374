import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { AppLayout } from 'layouts/App/AppLayout';
import { GuestLayout } from 'layouts/Guest/GuestLayout';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { RegisterView } from './views/RegisterView';
import { LoginView } from './views/LoginView';
import { ResetPasswordView } from './views/ResetPasswordView';
import { ChangePasswordView } from './views/ChangePasswordView';
import { ProfileView } from 'views/ProfileView/ProfileView';
import { TasksView } from './views/TasksView/TasksView';
import { AdministrationView } from 'views/AdministrationView/AdministrationView';
import { CompanyAdministrationView } from 'views/AdministrationView/views/CompanyAdministrationView/CompanyAdministrationView';
import { EmployeesAndUsersView } from 'views/AdministrationView/views/EmployeesAndUsersView/EmployeesAndUsersView';
import { TranslationsView } from 'views/AdministrationView/views/TranslationsView/TranslationsView';
import { AdministrationTasksView } from 'views/AdministrationView/views/TasksView/AdministrationTasksView';
import { AreasAndOrganizationStructureView } from 'views/AdministrationView/views/AreasAndOrganizationStructureView/AreasAndOrganizationStructureView';
import { TranslationsPermittedUsersView } from 'views/AdministrationView/views/TranslationsPermittedUsersView/TranslationsPermittedUsersView';
import { EmployeesAndUsersSpecialPermissionsView } from 'views/AdministrationView/views/EmployeesAndUsersSpecialPermissionsView/EmployeesAndUsersSpecialPermissionsView';
import { TeamDetailsView } from 'views/AdministrationView/views/EmployeesAndUsersView/views/TeamDetailsView';
import { DocumentsView } from 'views/DocumentsView/DocumentsView';
import { EvaluationsView } from 'views/EvaluationsView/EvaluationsView';
import { DesktopView } from 'views/DesktopView/DesktopView';
import { AnalyticsView } from 'views/AnalyticsView/AnalyticsView';
import { InfoView } from 'views/InfoView';
import { Footer } from 'layouts/components/Footer';
import { FooterContent } from 'layouts/Guest/GuestLayout.styled';
import { AdministrationAuditsView } from 'views/AdministrationView/views/AuditsView/AdministrationAuditsView';
import { AddAuditFormView } from 'views/AdministrationView/views/AuditsView/views/AddAuditFormView';
import { EditAuditFormView } from 'views/AdministrationView/views/AuditsView/views/EditAuditFormView';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedRoute isAuth>
        <GuestLayout>
          <Outlet />
        </GuestLayout>
      </ProtectedRoute>
    ),
    children: [
      { path: '/', element: <Navigate to="/login" /> },
      {
        path: '/login',
        element: <LoginView />
      },
      {
        path: '/register',
        element: <RegisterView />
      },
      {
        path: '/password-reset',
        element: <ResetPasswordView />
      },
      {
        path: '/password-reset/new-password',
        element: <ChangePasswordView />
      }
    ]
  },
  {
    path: '/',
    element: (
      <ProtectedRoute isAuth>
        <Outlet />
        <FooterContent>
          <Footer />
        </FooterContent>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/info/:slug',
        element: <InfoView />
      }
    ]
  },
  {
    path: '/app',
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'administration',
        children: [
          {
            path: '',
            element: <AdministrationView />
          },
          {
            path: 'company',
            element: <CompanyAdministrationView />
          },
          {
            path: 'employees-and-users',
            element: <EmployeesAndUsersView />
          },
          {
            path: 'employees-and-users/special-permissions',
            element: <EmployeesAndUsersSpecialPermissionsView />
          },
          {
            path: 'employees-and-users/user/:id',
            element: <ProfileView />
          },
          {
            path: 'employees-and-users/user/:id/:defaultTabIndex',
            element: <ProfileView />
          },
          {
            path: 'employees-and-users/teams/:id',
            element: <TeamDetailsView />
          },
          {
            path: 'areas-and-organization-structure',
            element: <AreasAndOrganizationStructureView />
          },
          {
            path: 'tasks',
            element: <AdministrationTasksView />
          },
          {
            path: 'audits',
            element: <AdministrationAuditsView />
          },
          {
            path: 'audits/add-audit-form',
            element: <AddAuditFormView />
          },
          {
            path: 'audits/edit-audit-form/:id',
            element: <EditAuditFormView />
          },
          {
            path: 'translations',
            element: <TranslationsView />
          },
          {
            path: 'translations/permitted-users',
            element: <TranslationsPermittedUsersView />
          }
        ]
      },
      {
        path: 'my-profile',
        element: <ProfileView />
      },
      {
        path: 'tasks',
        element: <TasksView />
      },
      {
        path: 'desktop',
        element: <DesktopView />
      },
      {
        path: 'tasks',
        element: <></>
      },
      {
        path: 'evaluations',
        element: <EvaluationsView />
      },
      {
        path: 'analitics',
        element: <AnalyticsView />
      },
      {
        path: 'documents',
        element: <DocumentsView />
      }
    ]
  }
];
