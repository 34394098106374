import { IAuditGroup, IGroupsAttributes } from 'types/audits';

export const parseGroupsToAttributes = (
  groups: IAuditGroup[]
): (IGroupsAttributes & Partial<IAuditGroup>)[] => {
  return groups.map((group) => {
    const { audit_form_id, groups: itemGroups, criteria, ...rest } = group;

    return {
      ...rest,
      criteria_attributes: criteria.map((criterion) => ({
        ...criterion
      })),
      groups_attributes: itemGroups?.length
        ? parseGroupsToAttributes(itemGroups)
        : []
    };
  });
};
