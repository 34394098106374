import MDEditor from '@uiw/react-md-editor';
import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { Button } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { Note } from './components/Note';
import { postNote } from 'api/notes';
import { useState } from 'react';
import { useAccount } from 'hooks/useAccount';
import { toast } from 'react-toastify';
import { INote } from 'types/notes';

interface Props extends IGenericModal {
  notes?: INote[];
  setNotes?: (newNotes: INote[]) => void;
  customTitle?: string;
  modalType?: 'notes' | 'comments';
  noteableId?: number;
  noteableType?: string;
}

export const NotesModal = ({
  isOpen,
  onCancelClick,
  notes,
  setNotes,
  customTitle,
  noteableId,
  noteableType
}: Props) => {
  const [content, setContent] = useState('');
  const { t } = useTranslation();

  const { loggedUserData } = useAccount();

  const handlePostNote = async () => {
    if (content && noteableId && noteableType) {
      const payload = {
        note: {
          content,
          author_id: loggedUserData.id,
          noteable_id: noteableId,
          noteable_type: noteableType
        }
      };

      const postNoteRes = await postNote(payload);

      if ('error' in postNoteRes) {
        toast.error(postNoteRes.error.message);
        return;
      }

      setNotes?.([...(notes || []), postNoteRes.data]);

      setContent('');
    }
  };

  return (
    <Modal
      gridTemplateColumns="60%"
      bodyMaxHeight=""
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t(`${customTitle ? customTitle : 'common.notes'}`)}
      mainButton={{
        action: onCancelClick,
        variant: 'grey',
        label: t('buttons.close')
      }}
    >
      <Flex flexDirection="column">
        <Flex flexDirection="column" overflow="auto" maxHeight="350px">
          {notes?.map((note) => (
            <Note
              key={`NotesModal-${note.id}`}
              note={note}
              noteableId={noteableId}
              noteableType={noteableType}
              notes={notes}
              setNotes={setNotes}
            />
          ))}
        </Flex>

        <div data-color-mode="light">
          <MDEditor
            height={200}
            preview="edit"
            value={content}
            onChange={(value) => {
              setContent(value || '');
            }}
            extraCommands={[
              {
                name: 'add',
                keyCommand: 'add',
                value: 'add',
                icon: (
                  <Button
                    type="button"
                    variant="eucalyptus"
                    onClick={handlePostNote}
                  >
                    {t('administration.add')}
                  </Button>
                )
              }
            ]}
          />
        </div>
      </Flex>
    </Modal>
  );
};
