import { Box, Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  GradingScaleInformationBox,
  GradingScaleInformationRowWrapper,
  ModalHeadersWrapper,
  Numbers,
  PaddingWrapper,
  EvaluationHeadersWrapper,
  StyledFlex,
  PracticeEvaluationHeader,
  FontSizeChangerInput,
  FontSizeChangerWrapper,
  Scores,
  FixedFontSizeWrapper,
  PointerWrapper,
  EvaluationsHeadersWrapper,
  DescriptionContentWrapper,
  StyledMDEditor
} from './EvaluationModal.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
  faLightbulb,
  faMapLocationDot,
  faRankingStar
} from '@fortawesome/free-solid-svg-icons';

import {
  faLightbulb as regularFaLightbulb,
  faSquareMinus,
  faSquarePlus
} from '@fortawesome/free-regular-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles';
import { SwitchButton } from 'components/_form';
import { HeaderCell } from './components/HeaderCell';
import partsImage from 'assets/images/PlatT-methodology.jpg';

import { Box as BoxMui } from '@mui/material';
import Slider from '@mui/material/Slider';
import { CriteriaModal } from './components/CriteriaModal/CriteriaModal';
import { ColumnOrder } from './components/ColumnOrder/ColumnOrder';
import { CellTextValue } from './components/CellTextValue/CellTextValue';
import { NumberCell } from './components/NumberCell/NumberCell';
import { IDetailedEvaluation, TableScore } from 'types/evaluations';
import { ChooseBlockWindow } from './components/ChooseBlockWindow/ChooseBlockWindow';
import { Blocks } from 'components/Blocks';
import { IBlock } from 'types/methologies';

interface Props extends IGenericModal {
  usedEvalations: IDetailedEvaluation[];
  setUsedEvalations: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation[]>
  >;
  selectedBlock: IBlock;
  setSelectedBlock: React.Dispatch<React.SetStateAction<IBlock | undefined>>;
}

const evaluationColors = [
  '#fff6ee',
  '#fbfff0',
  '#ecfcff',
  '#fffddd',
  '#fbf8ff',
  '#f9ffe5',
  '#fff7f4',
  '#fff0ff',
  '#f7fdff'
];

export interface ChangeComponentOrder {
  itemId: string;
  direction: 'up' | 'down';
}

export interface Item {
  number: string;
  description: string;
  scores: TableScore[];
  gradingScale: string;
  guidelines?: string;
  examples: boolean;
}

export const EvaluationModal = ({
  isOpen,
  onCancelClick,
  usedEvalations,
  setUsedEvalations,
  selectedBlock,
  setSelectedBlock
}: Props) => {
  const initValues = {
    baseFontSize: 16,
    minFontSize: 16,
    maxFontSize: 22,
    baseLineHeight: 24,
    baseRowHeight: 80,
    lineHeightAddend: 1,
    rowHeightAddend: 6
  };

  const [chooseBlockWindowOpen, setChooseBlockWindowOpen] = useState(false);
  const [gradingScaleOpen, setGradingScaleOpen] = useState(true);
  const [gradingScaleSwitch, setGradingScaleSwitch] = useState(false);
  const [hintSwitch, setHintSwitch] = useState(false);
  const [practiceEvaluationActive, setPracticeEvaluationActive] =
    useState(false);
  const [guidelinesOpen, setGuidelinesOpen] = useState(true);
  const [fontSize, setFontSize] = useState(initValues.baseFontSize);
  const [textInCellLineHeight, setTextInCellLineHeight] = useState(
    initValues.baseLineHeight
  );
  const [rowHeight, setRowHeight] = useState(initValues.baseRowHeight);
  const [showCriteriaModal, setShowCriteriaModal] = useState(false);
  const [criteriaModalTitle, setCriteriaModalTitle] = useState('');
  const [componentOrder, setComponentOrder] = useState<string[]>([]);
  const [data, setData] = useState<Item[]>([]);
  const [scoresColumn, setScoresColumn] = useState<TableScore[]>([]);
  const [clickedCriterion, setClickedCriterion] = useState<Item>();

  const { t } = useTranslation();

  const generateData = useCallback(() => {
    if (usedEvalations[0]) {
      let currentData: Item[] = [];
      usedEvalations[0].practice.blocks.map((block) => {
        if (block.id === selectedBlock?.id) {
          block.criteria.map((criterion) => {
            const scores: TableScore[] = [];
            usedEvalations.map((selectedEvaluation) => {
              const usedIds: number[] = [];

              const foundBlock = selectedEvaluation.practice.blocks.find(
                ({ id }) => selectedBlock?.id === id
              );

              const foundCriterion = foundBlock?.criteria.find(
                ({ id }) => criterion?.id === id
              );

              if (!usedIds.includes(usedEvalations[0].area.id)) {
                const score: TableScore = {
                  id: foundCriterion?.score?.id,
                  name: selectedEvaluation.area.name,
                  value: foundCriterion?.score?.score.toString(),
                  evaluationId: selectedEvaluation.id,
                  criterionId: foundCriterion?.id,
                  areaId: selectedEvaluation.area.id
                };
                scores.push(score);
                if (scoresColumn.length < scores.length)
                  setScoresColumn(scores);
                usedIds.push(usedEvalations[0].area.id);
              }
            });
            currentData.push({
              number: criterion.id.toString(),
              description: criterion.content,
              scores: scores,
              gradingScale: criterion.evaluation_instruction,
              guidelines: criterion.score?.guideline,
              examples: true
            });
          });
        }
      });
      setData(currentData);
    }
  }, [scoresColumn.length, selectedBlock?.id, usedEvalations]);

  const changeComponentOrder = useCallback(
    ({ itemId, direction }: ChangeComponentOrder) => {
      const parsedDirection = direction === 'up' ? -1 : 1;
      const index = componentOrder.indexOf(itemId);
      const newIndex = index + parsedDirection;
      const newComponentOrder = [...componentOrder];
      const temp = newComponentOrder[index];

      newComponentOrder[index] = newComponentOrder[newIndex];
      newComponentOrder[newIndex] = temp;
      setComponentOrder(newComponentOrder);
    },
    [componentOrder]
  );

  const table = useMemo(() => {
    return {
      nr: (
        <StyledFlex flexDirection="column">
          <FixedFontSizeWrapper>
            <HeaderCell>{t('evaluationsView.nr')}</HeaderCell>
          </FixedFontSizeWrapper>

          {data.map((item) => (
            <Cell
              key={item.number + t('evaluationsView.nr')}
              height={`${rowHeight}px`}
            >
              {item.number}
            </Cell>
          ))}
        </StyledFlex>
      ),
      criteria: (
        <StyledFlex
          minWidth={[
            '65%',
            !guidelinesOpen && !gradingScaleOpen ? '75%' : '50%'
          ]}
          width={['65%', 'auto']}
          flexDirection="column"
        >
          <FixedFontSizeWrapper>
            <HeaderCell>{t('evaluationsView.evaluationCriteria')}</HeaderCell>
          </FixedFontSizeWrapper>

          {data.map((item) => (
            <Cell
              key={item.description + item.number}
              height={`${rowHeight}px`}
            >
              <CellTextValue
                value={item.description}
                number={item.number}
                hintSwitch={hintSwitch}
                label="description"
                fontSize={fontSize}
                lineHeight={textInCellLineHeight}
              />
            </Cell>
          ))}
        </StyledFlex>
      ),
      areas: (
        <StyledFlex
          width={
            scoresColumn.length <= 3
              ? `${scoresColumn.length * 110}px`
              : '220px'
          }
          maxWidth="180px"
          overflow="auto"
        >
          {data.length &&
            Array.from(
              Array(
                Math.max(...data.map((item) => item.scores?.length || 0)) || 0
              ),
              (e, i) => {
                return (
                  <Scores key={i} minWidth="60px" flexDirection="column">
                    <HeaderCell rotated addingTag>
                      <FixedFontSizeWrapper>
                        <p>{data[0].scores[i].name}</p>
                      </FixedFontSizeWrapper>
                    </HeaderCell>
                    {data.map((item) => {
                      return (
                        <NumberCell
                          key={`${item.number}-${i}-scores`}
                          bgColor={evaluationColors[i % 8]}
                          score={item.scores[i]}
                          showTooltip={hintSwitch}
                          fontSize={fontSize}
                          setShowCriteriaModal={setShowCriteriaModal}
                          setCriteriaModalTitle={setCriteriaModalTitle}
                          cellHeight={rowHeight}
                          criterionId={parseInt(item.number)}
                          onClick={() => {
                            setClickedCriterion(item);
                          }}
                          usedEvalations={usedEvalations}
                          setUsedEvalations={setUsedEvalations}
                          selectedBlock={selectedBlock}
                        />
                      );
                    })}
                  </Scores>
                );
              }
            )}
        </StyledFlex>
      ),
      gradingScale: (
        <StyledFlex
          flexDirection="column"
          width="15%"
          display={['none', 'flex']}
        >
          <HeaderCell>
            <FixedFontSizeWrapper>
              {gradingScaleOpen ? (
                t('evaluationsView.gradingScale')
              ) : (
                <FontAwesomeIcon icon={faRankingStar} />
              )}
            </FixedFontSizeWrapper>

            <Flex p={2}>
              <button
                onClick={() => {
                  setGradingScaleOpen(
                    (prevGradingScaleOpen) => !prevGradingScaleOpen
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={gradingScaleOpen ? faSquareMinus : faSquarePlus}
                />
              </button>
            </Flex>
          </HeaderCell>

          {gradingScaleOpen ? (
            <DescriptionContentWrapper
              data-color-mode="light"
              mb={`-${data.length * rowHeight - rowHeight}px`}
              height={`${data.length * rowHeight}px`}
            >
              <StyledMDEditor
                height="100%"
                enableScroll={false}
                visibleDragbar={false}
                hideToolbar={true}
                preview={'preview'}
                value={clickedCriterion?.gradingScale || ''}
              />
            </DescriptionContentWrapper>
          ) : (
            data.map((item) => (
              <Cell
                key={`${item.gradingScale}-${item.number}`}
                height={`${rowHeight}px`}
              >
                <FontAwesomeIcon icon={faRankingStar} />
              </Cell>
            ))
          )}
        </StyledFlex>
      ),
      guidelines: (
        <StyledFlex
          flexDirection="column"
          width="15%"
          display={['none', 'flex']}
        >
          <HeaderCell>
            <FixedFontSizeWrapper>
              {guidelinesOpen ? (
                t('evaluationsView.guidelines')
              ) : (
                <FontAwesomeIcon icon={faMapLocationDot} />
              )}
            </FixedFontSizeWrapper>

            <Flex p={2}>
              <button
                onClick={() => {
                  setGuidelinesOpen(
                    (prevGuidelinesOpen) => !prevGuidelinesOpen
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={guidelinesOpen ? faSquareMinus : faSquarePlus}
                />
              </button>
            </Flex>
          </HeaderCell>

          {guidelinesOpen ? (
            <DescriptionContentWrapper
              data-color-mode="light"
              mb={`-${data.length * rowHeight - rowHeight}px`}
              height={`${data.length * rowHeight}px`}
            >
              <StyledMDEditor
                height="100%"
                enableScroll={false}
                visibleDragbar={false}
                hideToolbar={true}
                preview={'preview'}
                value={clickedCriterion?.guidelines || ''}
              />
            </DescriptionContentWrapper>
          ) : (
            data.map((item) => (
              <Cell
                key={`${item.guidelines}-${item.number}`}
                height={`${rowHeight}px`}
              >
                <FontAwesomeIcon icon={faMapLocationDot} />
              </Cell>
            ))
          )}
        </StyledFlex>
      ),
      bulbs: (
        <StyledFlex flexDirection="column" display={['none', 'flex']}>
          <HeaderCell>
            <FixedFontSizeWrapper>
              <FontAwesomeIcon size="2xl" icon={faLightbulb} />
            </FixedFontSizeWrapper>
          </HeaderCell>

          {data.map((item, i) => (
            <Cell key={`examples-${i}`} height={`${rowHeight}px`}>
              <FixedFontSizeWrapper>
                {item.examples && (
                  <PointerWrapper
                    onClick={() => {
                      setShowCriteriaModal(true);
                      setCriteriaModalTitle('exampleForCriterion');
                    }}
                  >
                    <FontAwesomeIcon
                      size="2xl"
                      color={theme.palette.accent.yellow}
                      icon={regularFaLightbulb}
                    />
                  </PointerWrapper>
                )}
              </FixedFontSizeWrapper>
            </Cell>
          ))}
        </StyledFlex>
      )
    };
  }, [
    data,
    fontSize,
    gradingScaleOpen,
    guidelinesOpen,
    hintSwitch,
    rowHeight,
    scoresColumn.length,
    t,
    textInCellLineHeight
  ]);

  const initComponentOrder = useCallback(() => {
    setComponentOrder(Object.keys(table));
  }, [table]);

  useEffect(() => {
    usedEvalations.length > 0 && generateData();
  }, [generateData, usedEvalations.length]);

  useEffect(() => {
    initComponentOrder();
  }, [initComponentOrder]);

  const changeRowHeight = (
    cellLineHeightAddend: number,
    rowHeightAddend: number
  ) => {
    const { baseLineHeight, baseFontSize } = initValues;
    if (
      textInCellLineHeight === baseLineHeight + (21 - baseFontSize) &&
      cellLineHeightAddend > 0
    )
      rowHeightAddend -= 2;
    else if (
      textInCellLineHeight === baseLineHeight + (22 - baseFontSize) &&
      cellLineHeightAddend < 0
    )
      rowHeightAddend += 2;

    setTextInCellLineHeight(textInCellLineHeight + cellLineHeightAddend);
    setRowHeight(rowHeight + rowHeightAddend);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        disableBodySharedStyles={true}
        disableHeaderSharedStyles={true}
        onCancelClick={onCancelClick}
        mainButton={{
          action: () => {},
          variant: 'eucalyptus',
          label: t('common.yes')
        }}
        isSecondButtonVisible
        gridTemplateColumns="99%"
        fontSize={fontSize}
        bodyMaxHeight="70vh"
        headerTitle={
          <ModalHeadersWrapper
            flexDirection="row"
            justifyContent="space-between"
          >
            <Flex width="100%">
              <PracticeEvaluationHeader
                isActive={practiceEvaluationActive}
                canBeActivated={hintSwitch}
                onClick={() => {
                  if (hintSwitch)
                    setPracticeEvaluationActive(!practiceEvaluationActive);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <Flex ml={2}>
                    <H2 variant="h3" mr={2}>
                      {t('evaluationsView.practiceEvaluation')}:
                    </H2>
                    <H2 variant="h3" color="red" mr={20}>
                      {usedEvalations[0]?.practice.name}
                    </H2>
                  </Flex>
                  {practiceEvaluationActive && (
                    <Flex
                      width={['100%', '200%']}
                      style={{
                        position: 'absolute',
                        left: '-5px',
                        top: '35px'
                      }}
                    >
                      <img width="100%" src={partsImage} alt="parts" />
                    </Flex>
                  )}
                </div>
              </PracticeEvaluationHeader>

              <EvaluationsHeadersWrapper
                width="100%"
                isActive={chooseBlockWindowOpen}
                onClick={() => {
                  setChooseBlockWindowOpen &&
                    setChooseBlockWindowOpen(
                      (prevChooseBlockWindowOpen) => !prevChooseBlockWindowOpen
                    );
                }}
                display={['none', 'flex']}
              >
                <EvaluationHeadersWrapper>
                  <H2
                    variant="h3"
                    mr={2}
                    color={chooseBlockWindowOpen ? 'white' : 'primary'}
                  >
                    {t('evaluationsView.topic')}:
                  </H2>
                  <H2 variant="h3" color="green" mr={20}>
                    {
                      usedEvalations[0]?.practice?.topics.find(
                        (topic) => topic.id === selectedBlock?.topic_id
                      )?.name
                    }
                  </H2>
                </EvaluationHeadersWrapper>

                <EvaluationHeadersWrapper>
                  <H2
                    variant="h3"
                    mr={2}
                    color={chooseBlockWindowOpen ? 'white' : 'primary'}
                  >
                    {t('evaluationsView.block')}:
                  </H2>
                  <H2 variant="h3" color="coloured">
                    {selectedBlock?.name}
                  </H2>
                </EvaluationHeadersWrapper>
              </EvaluationsHeadersWrapper>
            </Flex>
            {!chooseBlockWindowOpen && (
              <Flex alignItems="center">
                <ColumnOrder
                  switchableItems={componentOrder}
                  changeComponentOrder={changeComponentOrder}
                />
              </Flex>
            )}
          </ModalHeadersWrapper>
        }
        additionalFooter={
          <Flex
            minWidth="600px"
            gap="10px"
            alignItems="center"
            maxHeight="60vh"
          >
            <FontSizeChangerWrapper>
              <Box mr={1} alignItems="center" display="flex">
                <FontAwesomeIcon icon={faFont} />
              </Box>
              <BoxMui width={50} style={{ display: 'flex' }}>
                <Slider
                  size="small"
                  value={fontSize}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  min={initValues.minFontSize}
                  max={initValues.maxFontSize}
                  onChange={(event: Event, newValue: number | number[]) => {
                    if ((newValue as number) > fontSize) {
                      changeRowHeight(
                        initValues.lineHeightAddend,
                        initValues.rowHeightAddend
                      );
                    } else {
                      changeRowHeight(
                        initValues.lineHeightAddend,
                        initValues.rowHeightAddend
                      );
                    }
                    setFontSize(newValue as number);
                  }}
                />
              </BoxMui>
              <FontSizeChangerInput
                type="number"
                value={fontSize}
                onChange={(e) => {
                  const newFontSize = parseInt(e.target.value);
                  if (newFontSize <= 22 && newFontSize >= 16) {
                    if (newFontSize > fontSize) {
                      const substractionMultiplier = newFontSize - fontSize;
                      changeRowHeight(
                        initValues.lineHeightAddend * substractionMultiplier,
                        initValues.rowHeightAddend * substractionMultiplier
                      );
                    } else {
                      const substractionMultiplier = fontSize - newFontSize;
                      changeRowHeight(
                        -initValues.lineHeightAddend * substractionMultiplier,
                        -initValues.rowHeightAddend * substractionMultiplier
                      );
                    }
                    setFontSize(newFontSize);
                  }
                }}
              />
            </FontSizeChangerWrapper>

            <P variant="body">{t('evaluationsView.alwaysShow')}:</P>

            <Flex minWidth="150px" alignItems="center" gap="10px">
              <P variant="body">{t('evaluationsView.gradingScale')}:</P>

              <SwitchButton
                value={gradingScaleSwitch}
                onChange={setGradingScaleSwitch}
              />
            </Flex>

            <Flex minWidth="150px" alignItems="center" gap="10px">
              <P variant="body">{t('evaluationsView.hints')}:</P>
              <SwitchButton
                value={hintSwitch}
                onChange={(value) => {
                  setHintSwitch(value);
                }}
              />
            </Flex>
          </Flex>
        }
      >
        {chooseBlockWindowOpen && usedEvalations[0] && (
          <ChooseBlockWindow>
            <Blocks
              practice={usedEvalations[0].practice}
              selectedBlock={selectedBlock}
              checkButton={false}
              onBlockClick={(block) => {
                setSelectedBlock(block);
                setChooseBlockWindowOpen(false);
              }}
              width={80}
              height={50}
              fontVariant="body3"
              topicFontVariant="body"
              increasing={false}
              bgColor={theme.palette.neutral.white}
              selectedBgColor={theme.palette.accent.lightBlue}
            />
          </ChooseBlockWindow>
        )}
        <PaddingWrapper>
          <StyledFlex
            maxWidth={!guidelinesOpen && !gradingScaleOpen ? '99%' : undefined}
          >
            {componentOrder?.map((component: string) => {
              return <>{table[component as keyof typeof table]}</>;
            })}
          </StyledFlex>
        </PaddingWrapper>
        {gradingScaleSwitch && (
          <GradingScaleInformationBox>
            <GradingScaleInformationRowWrapper>
              <Numbers>3 -&nbsp;</Numbers>
              {t(`evaluationsView.gradingScaleDescription.3`)}
            </GradingScaleInformationRowWrapper>
            <GradingScaleInformationRowWrapper>
              <Numbers>1 -&nbsp;</Numbers>
              {t('evaluationsView.gradingScaleDescription.1')}
            </GradingScaleInformationRowWrapper>
            <GradingScaleInformationRowWrapper>
              <Numbers>0 -&nbsp;</Numbers>
              {t('evaluationsView.gradingScaleDescription.0')}
            </GradingScaleInformationRowWrapper>
          </GradingScaleInformationBox>
        )}
      </Modal>
      <CriteriaModal
        isOpen={showCriteriaModal}
        onCancelClick={() => setShowCriteriaModal(false)}
        title={criteriaModalTitle}
      />
    </>
  );
};
