import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { Checkbox } from 'components/_form';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export interface IStatusFilters {
  without_active?: boolean;
  without_archived?: boolean;
  without_cancelled?: boolean;
}

interface Props {
  statusFilters: IStatusFilters;
  setStatusFilters: Dispatch<SetStateAction<IStatusFilters>>;
}

export const TasksStatusFilters: React.FC<Props> = ({
  statusFilters,
  setStatusFilters
}) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" gap="10px">
      <P variant="h4">{t('common.show')}:</P>

      <Checkbox
        label={t('tasks.active')}
        checked={!statusFilters?.without_active}
        onChange={() => {
          setStatusFilters((prevStatusFilters) => ({
            ...prevStatusFilters,
            without_active: !prevStatusFilters.without_active
          }));
        }}
      />
      <Checkbox
        label={t('tasks.archived')}
        checked={!statusFilters?.without_archived}
        onChange={() => {
          setStatusFilters((prevStatusFilters) => ({
            ...prevStatusFilters,
            without_archived: !prevStatusFilters.without_archived
          }));
        }}
      />
      <Checkbox
        label={t('tasks.canceled')}
        checked={!statusFilters?.without_cancelled}
        onChange={() => {
          setStatusFilters((prevStatusFilters) => ({
            ...prevStatusFilters,
            without_cancelled: !prevStatusFilters.without_cancelled
          }));
        }}
      />
    </Flex>
  );
};
