import { ToastContainer } from 'react-toastify';
import { useRoutes } from 'react-router-dom';
import { routes } from 'routes';
import { Cookies } from './components/Cookies';
import PWAPrompt from 'react-ios-pwa-prompt';
import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

export const App = () => {
  const appRoutes = useRoutes(routes);
  const { t } = useTranslation();

  return (
    <div>
      {appRoutes}
      <Cookies />
      <ToastContainer position="bottom-right" />
      <PWAPrompt
        timesToShow={3}
        copyClosePrompt={t('common.close')}
        copyTitle={t('common.installApp')}
        copyBody=""
        copyShareButtonLabel={t('common.pressShareButton')}
        copyAddHomeButtonLabel={t('common.pressAddHomeScreen')}
      />
    </div>
  );
};

export default App;
