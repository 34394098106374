import {
  faCirclePlay,
  faClipboard,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEvaluation } from 'api/evaluations';
import { Flex } from 'components';
import { Button } from 'components/_form';
import { Loader } from 'components/Loader/Loader';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { IDetailedEvaluation, IEvaluation } from 'types/evaluations';
import { IBlock } from 'types/methologies';
import { EvaluationModal } from '../../EvaluationModal/EvaluationModal';

interface Props {
  evaluation: IEvaluation;
}

export const OptionButtons: React.FC<Props> = ({ evaluation }) => {
  const [loading, setLoading] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState<IBlock>();
  const [detailedEvaluations, setDetailedEvaluations] = useState<
    IDetailedEvaluation[]
  >([]);
  const { setEvaluationToEditId } = useEvaluationContext();

  const handleLaunchEvaluation = async () => {
    setLoading(true);
    const detailedEvaluationRes = await getEvaluation(evaluation.id);

    if ('error' in detailedEvaluationRes) {
      toast.error(detailedEvaluationRes.error.message);
      return;
    }

    setDetailedEvaluations([detailedEvaluationRes.data]);
    setSelectedBlock(detailedEvaluationRes.data.practice?.blocks[0]);
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Flex gap="10px" mx="auto" width="fit-content">
      <Button
        variant="green"
        onClick={handleLaunchEvaluation}
        icon={<FontAwesomeIcon icon={faCirclePlay} />}
      />
      <Button
        variant="grey"
        bordered
        onClick={() => {}}
        icon={<FontAwesomeIcon icon={faClipboard} />}
      />
      <Button
        onClick={() => {
          setEvaluationToEditId(evaluation.id);
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />

      {!!selectedBlock && (
        <EvaluationModal
          isOpen={!!selectedBlock}
          onCancelClick={() => {
            setSelectedBlock(undefined);
          }}
          usedEvalations={detailedEvaluations}
          setUsedEvalations={setDetailedEvaluations}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
        />
      )}
    </Flex>
  );
};
