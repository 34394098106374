import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAreas } from 'api/areas';
import { deleteStarMark, postStarMark } from 'api/stars';
import { editTask } from 'api/tasks';
import { Box, Flex } from 'components';
import { Loader } from 'components/Loader/Loader';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { Button, TextareaField } from 'components/_form';
import {
  IOption,
  NestedSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { useAccount } from 'hooks/useAccount';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { theme } from 'styles';
import { IStarResponse } from 'types/forms/stars';
import { ITaskPatchResponse } from 'types/forms/task';
import { ITask } from 'types/task';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';

interface Props extends IGenericModal {
  selectedItemData?: ITask;
  onSuccess?: () => void;
}

export const StarModal = ({
  isOpen,
  onCancelClick,
  selectedItemData,
  onSuccess
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState<
    'forMe' | 'forTeam' | 'forArea'
  >();

  const [selectedAreas, setSelectedAreas] = useState<IOption[]>([]);
  const [areas, setAreas] = useState<IOption[]>([]);

  console.log('selectedItemData', selectedItemData);
  const { t } = useTranslation();
  const { loggedUserData } = useAccount();

  const areaStarMarks =
    selectedItemData?.star_marks.filter(
      (star_mark) => star_mark.starred_by_type === 'Area'
    ) || [];

  const { register, watch, reset, setValue } = useForm();

  const handleSubmit = async () => {
    if (selectedItemData) {
      setLoading(true);
      let req:
        | Promise<IStarResponse>
        | Promise<ITaskPatchResponse>
        | Promise<null>
        | undefined;

      switch (destination) {
        case 'forMe':
          const body = {
            star_mark: {
              task_id: Number(selectedItemData.id),
              starred_by_id: Number(loggedUserData.id),
              starred_by_type: 'User'
            }
          };

          req = Promise.resolve(postStarMark(body));

          break;
        case 'forArea':
          if (selectedAreas.length && !watch('justification')) {
            toast.error(t('common.justificationRequired'));
            throw new Error();
          }

          selectedAreas.map(async (areaOption) => {
            if (
              (loggedUserData?.role === 'admin' ||
                !!loggedUserData?.ownedAreas.find(
                  (ownedArea) => String(ownedArea.id) === areaOption.value
                )) &&
              !areaStarMarks.find(
                (areaStarMark) =>
                  String(areaStarMark.starred_by_id) === areaOption.value
              )
            ) {
              const body = {
                star_mark: {
                  task_id: Number(selectedItemData.id),
                  starred_by_id: Number(areaOption.value),
                  starred_by_type: 'Area',
                  justification: watch('justification')
                }
              };

              req = Promise.resolve(postStarMark(body));
            }
          });

          const areaStarMarksToRemove = areaStarMarks.filter(
            (areaStarMark) =>
              !selectedAreas.find(
                (selectedArea) =>
                  selectedArea.value === String(areaStarMark.starred_by_id)
              )
          );

          areaStarMarksToRemove?.map(async (areaStarMarkToRemove) => {
            if (
              loggedUserData?.role === 'admin' ||
              !!loggedUserData?.ownedAreas.find(
                (ownedArea) =>
                  ownedArea.id === areaStarMarkToRemove.starred_by_id
              )
            ) {
              req = Promise.resolve(deleteStarMark(areaStarMarkToRemove.id));
            }
          });

          break;
        case 'forTeam':
          req = Promise.resolve(
            editTask(selectedItemData.id, {
              task: {
                starred_by_participants: true
              }
            })
          );

          break;
        case undefined:
          if (selectedItemData.starred_by_participants) {
            req = Promise.resolve(
              editTask(selectedItemData.id, {
                task: {
                  starred_by_participants: false
                }
              })
            );
          }

          selectedItemData.star_marks.length &&
            selectedItemData.star_marks.map(({ id }) => {
              req = Promise.resolve(deleteStarMark(id));
            });
      }

      if (req) {
        req
          .then(async () => {
            if (onSuccess) {
              await onSuccess();
              reset({ justification: '' });
            }
            setLoading(false);
            toast.success(t('common.success'));
          })
          .catch((e) => {
            setLoading(false);
            toast.error(t('common.anErrorOccurred'));
          });

        return;
      }

      if (onSuccess) {
        await onSuccess();
        reset({ justification: '' });
      }
      setLoading(false);
    }
  };

  const loadStarDestination = useCallback(() => {
    if (selectedItemData) {
      if (!!selectedItemData?.starred_by_current_user_areas) {
        setDestination('forArea');
      } else if (
        !!selectedItemData?.starred_by_team_and_current_user_participate_in
      ) {
        setDestination('forTeam');
      } else if (!!selectedItemData?.starred_by_current_user) {
        setDestination('forMe');
      }
    }
  }, [selectedItemData, loggedUserData?.id, isOpen]);

  useEffect(() => {
    loadStarDestination();
  }, [loadStarDestination]);

  const loadOptions = useCallback(async () => {
    const { data: areasData } = await getAreas();

    const filteredAreasData = areasData.map((areaData) => ({
      ...areaData,
      isDisabled:
        loggedUserData?.role === 'admin'
          ? false
          : !loggedUserData?.ownedAreas.find(
              (ownedArea) => ownedArea.id === areaData.id
            )
    }));

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(filteredAreasData)
      )
    );

    if (selectedItemData) {
      const defaultSelectedAreas = areaStarMarks
        .map((star_mark) => {
          const foundArea = areasData.find(
            (areaData) => areaData.id === star_mark.starred_by_id
          );

          if (foundArea)
            return {
              value: String(foundArea.id),
              label: foundArea.name,
              isDisabled:
                loggedUserData?.role === 'admin'
                  ? false
                  : !loggedUserData?.ownedAreas.find(
                      (ownedArea) => ownedArea.id === foundArea.id
                    )
            };
        })
        .filter((item) => !!item) as IOption[];

      setSelectedAreas(defaultSelectedAreas);
    }
  }, [JSON.stringify(loggedUserData), selectedItemData]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  useEffect(() => {
    if (areaStarMarks.length) {
      setValue(
        'justification',
        areaStarMarks[areaStarMarks.length - 1].justification
      );
    }
  }, [JSON.stringify(areaStarMarks)]);

  return (
    <Modal
      gridTemplateColumns="45%"
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('turn on the star?')}
      mainButton={{
        action: handleSubmit,
        variant: 'eucalyptus',
        label: loading ? <Loader /> : t('buttons.turnOn')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column">
        <Flex justifyContent="space-between">
          <Button
            variant="secondary"
            onClick={() => {
              setDestination(destination === 'forMe' ? undefined : 'forMe');
            }}
          >
            <Flex alignItems="center">
              <Box mr={2}>
                <FontAwesomeIcon
                  size="xl"
                  color={theme.palette.accent.green}
                  icon={destination === 'forMe' ? faSolidStar : faStar}
                />
              </Box>
              <P variant="body">{t('tasks.forMe')}</P>
            </Flex>
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              setDestination(destination === 'forTeam' ? undefined : 'forTeam');
            }}
          >
            <Flex alignItems="center">
              <Box mr={2}>
                <FontAwesomeIcon
                  size="xl"
                  color={theme.palette.accent.blue}
                  icon={destination === 'forTeam' ? faSolidStar : faStar}
                />
              </Box>
              <P variant="body">{t('tasks.forTeam')}</P>
            </Flex>
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              setDestination(destination === 'forArea' ? undefined : 'forArea');
            }}
          >
            <Flex alignItems="center">
              <Box mr={2}>
                <FontAwesomeIcon
                  size="xl"
                  color={theme.palette.accent.red}
                  icon={destination === 'forArea' ? faSolidStar : faStar}
                />
              </Box>
              <P variant="body">{t('tasks.forArea')}</P>
            </Flex>
          </Button>
        </Flex>

        <Flex flexDirection="column" my={3}>
          {destination === 'forTeam' && (
            <P variant="body">{t('tasks.starForTeamInstruction')}</P>
          )}

          {destination === 'forArea' && (
            <>
              <NestedSelect
                label="administration.Area(s)"
                helperText="leave blank if all company"
                selectedOptions={selectedAreas}
                setSelectedOptions={setSelectedAreas}
                options={areas}
              />

              <Box mt={2}>
                <TextareaField
                  {...register('justification')}
                  label="justification for the star"
                  resize
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
