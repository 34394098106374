import { serialize } from 'object-to-formdata';
import { IUserForm, IUserPatchResponse } from 'types/forms/user';
import { IUserResponse, IUsersResponse } from 'types/user';
import request from './index';

export const getUsers = () =>
  request<IUsersResponse>({
    options: {
      url: '/users',
      method: 'get'
    }
  });

export const getUser = (id: string) =>
  request<IUserResponse>({
    options: {
      url: `/users/${id}`,
      method: 'get'
    }
  });

export const editUser = (id: number, data: IUserForm) =>
  request<IUserPatchResponse>({
    options: {
      url: `/users/${id}`,
      method: 'patch',
      data: serialize(data)
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const getMe = () =>
  request<IUserResponse>({
    options: {
      url: '/users/me',
      method: 'get'
    }
  });
