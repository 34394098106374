import { useState } from 'react';
import { Cell } from './Cell';
import { Button } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ITable } from '../Table';
import styled from 'styled-components';
import { Flex } from 'components/Flex';

const StyledTr = styled.tr<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ theme }) => theme.palette.neutral.white};
  text-shadow: 3px 2px 2px rgba(55, 55, 55, 1);
`;

const NonExpandableTr = styled.tr<{ bgColor?: string }>`
  cursor: pointer;
  background-color: ${({ bgColor }) => bgColor};
`;

const StyledTd = styled.td`
  padding: 5px 15px;
`;

interface CellsProps {
  obj: { [key: string]: any };

  e: { [key: string]: any };

  hiddenItems?: string[];
  leftFixedKeys?: number[];
  rightFixedKeys?: number[];
  highlightCells?: boolean;
  fontSize?: ITable['fontSize'];
  onClickRow?: (evaluation: { number: string }) => void;
}

export const Cells: React.FC<CellsProps> = ({
  obj,
  hiddenItems,
  fontSize,
  leftFixedKeys,
  rightFixedKeys,
  highlightCells,
  e,
  onClickRow
}) => {
  const [showCells, setShowCells] = useState(false);

  const renderCells = (cellsObj: { [key: string]: any }) =>
    Object.keys(cellsObj).find((objKey) => Array.isArray(cellsObj[objKey])) ? (
      <>
        <StyledTr bgColor={cellsObj.secondaryAccordion.bgColor}>
          <StyledTd colSpan={100}>
            <Flex gap="10px" alignItems="center">
              <Button
                variant="grey"
                minHeight="24px"
                onClick={() => {
                  setShowCells((prevShowCells) => !prevShowCells);
                }}
              >
                <FontAwesomeIcon icon={showCells ? faMinus : faPlus} />
              </Button>
              {cellsObj.secondaryAccordion.value}
            </Flex>
          </StyledTd>
        </StyledTr>

        {showCells &&
          Object.keys(cellsObj).map((v, k) => {
            return Array.isArray(cellsObj[v]) ? (
              cellsObj[v].map((objItem: any, i: number) => (
                <NonExpandableTr
                  key={`row-${i}`}
                  onClick={() => onClickRow && onClickRow(cellsObj[v][i])}
                >
                  {renderCells(objItem)}
                </NonExpandableTr>
              ))
            ) : (
              <></>
            );
          })}
      </>
    ) : (
      <>
        {Object.keys(cellsObj).map((v, k) => {
          return hiddenItems?.includes(v) ? (
            <></>
          ) : (
            <Cell
              fontSize={fontSize}
              key={`cell-${v}`}
              isHighlighted={!e[v as keyof typeof e] && highlightCells}
              isLeftFixed={leftFixedKeys?.includes(k)}
              isRightFixed={rightFixedKeys?.includes(k)}
            >
              {cellsObj[v as keyof typeof e]}
            </Cell>
          );
        })}
      </>
    );

  return renderCells(obj);
};
