import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { Button } from 'components/_form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditModal } from './components/AddEditModal';
import RolesAndFunctionsTable from './components/RolesAndFunctionsTable';

export const RolesAndFunctions = () => {
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [highlightCells, setHighlightCells] = useState<boolean>(false);
  return (
    <>
      <AddEditModal
        isOpen={openAddModal}
        onCancelClick={() => setOpenAddModal(false)}
      />
      <Flex justifyContent="flex-end" width="100%" style={{ gap: '10px' }}>
        <Button
          variant="primary"
          onClick={() => {
            setHighlightCells((prev) => !prev);
          }}
        >
          {t('buttons.highlightBlocks')}
        </Button>
        <Button
          variant="eucalyptus"
          onClick={() => {
            setOpenAddModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.add')}
        </Button>
      </Flex>
      <RolesAndFunctionsTable highlightCells={highlightCells} />
    </>
  );
};
