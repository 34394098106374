import { Flex } from 'components';
import styled from 'styled-components';

export const NumberCell = styled.input<{ bgColor?: string; height: string }>`
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
  background-color: ${({ bgColor }) => bgColor};
  padding: 4px;
  width: 100%;
  height: ${({ height }) => height};
  box-sizing: border-box;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.neutral.mainBlue};
  }
`;

export const NumberCellWrapper = styled(Flex)`
  position: relative;
`;

export const NumberBoxWrapper = styled(Flex)`
  flex-direction: row;
  z-index: 99;
`;

export const IconsWrapper = styled(Flex)<{ cellHeight: number }>`
  margin-top: ${({ cellHeight }) => cellHeight - 40}px;
  position: absolute;
  width: 60px;
  flex-direction: row;
  justify-content: space-around;
`;
