import { IAuditForm } from 'types/audits';

export const makeAuditFormDataForSelect = (data: IAuditForm[]) => {
  const auditFormParsed = data.map((auditForm) => {
    return {
      value: auditForm.id.toString(),
      label: auditForm.name
    }
  })

  return auditFormParsed
};