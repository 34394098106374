import {
  faEdit,
  faFloppyDisk,
  faStopwatch,
  faTrash,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDEditor from '@uiw/react-md-editor';
import { patchNote, removeNote } from 'api/notes';
import { Avatar, Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { dateHoursFormat } from 'constants/dateFormats/dateHourFormat';
import { format } from 'date-fns';
import { useAccount } from 'hooks/useAccount';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { INote } from 'types/notes';
import { fullName } from 'utilities/fullName';

const Wrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const Header = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  note: INote;
  noteableId?: number;
  noteableType?: string;
  notes?: INote[];
  setNotes?: (newNotes: INote[]) => void;
}

export const Note: React.FC<Props> = ({
  note,
  noteableId,
  noteableType,
  notes,
  setNotes
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [mdValue, setMdValue] = useState(note.content.body);

  const { loggedUserData } = useAccount();

  const handleEditNote = async () => {
    if (mdValue && noteableId && noteableType) {
      const payload = {
        note: {
          content: mdValue,
          author_id: loggedUserData.id,
          noteable_id: noteableId,
          noteable_type: noteableType
        }
      };

      const patchNoteRes = await patchNote(note.id, payload);

      if ('error' in patchNoteRes) {
        toast.error(patchNoteRes.error.message);
        return;
      }

      setNotes?.(
        notes?.map((note) =>
          note.id === patchNoteRes.data.id ? patchNoteRes.data : note
        ) || []
      );
      setIsEditing((prevIsEditing) => !prevIsEditing);
    }
  };

  const handleRemoveNote = async () => {
    if (noteableId && noteableType) {
      const postNoteRes = await removeNote(note.id);

      if (postNoteRes && 'error' in postNoteRes) {
        toast.error(postNoteRes.error.message);
        return;
      }

      setNotes?.(notes?.filter((prevNote) => prevNote.id !== note.id) || []);
    }
  };

  return (
    <Wrapper>
      <Header>
        <Flex alignItems="center">
          <Avatar
            id={`note-${note.id}-${note.author.id}-Avatar`}
            size="extra-small"
            url={`${process.env.REACT_APP_API_URL}${note.author.photo}`}
            tooltipContent={fullName(note.author)}
          />
          <P mx={2} variant="body">
            {note.author.username}
          </P>

          <FontAwesomeIcon icon={faStopwatch} />

          <P mx={2} variant="body">
            {format(new Date(note.updated_at), dateHoursFormat)}
          </P>
        </Flex>
        <Flex>
          {isEditing ? (
            <>
              <Box mr={2}>
                <Button
                  variant="secondary"
                  transparent
                  bordered
                  icon={<FontAwesomeIcon icon={faX} />}
                  onClick={() => {
                    setIsEditing((prevIsEditing) => !prevIsEditing);
                  }}
                />
              </Box>
              <Box mr={2}>
                <Button
                  variant="secondary"
                  transparent
                  bordered
                  icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                  onClick={handleEditNote}
                />
              </Box>
            </>
          ) : (
            <Box mr={2}>
              <Button
                variant="secondary"
                transparent
                bordered
                icon={<FontAwesomeIcon icon={faEdit} />}
                onClick={() => setIsEditing(true)}
              />
            </Box>
          )}

          <Button
            variant="redWhite"
            transparent
            bordered
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={handleRemoveNote}
          />
        </Flex>
      </Header>

      <Box mt={3}>
        <div data-color-mode="light">
          <MDEditor
            height={200}
            hideToolbar={!isEditing}
            preview={isEditing ? 'edit' : 'preview'}
            value={mdValue}
            onChange={(value) => {
              setMdValue(value || '');
            }}
            visibleDragbar={false}
            enableScroll={false}
          />
        </div>
      </Box>
    </Wrapper>
  );
};
