import axios, { AxiosError, AxiosResponse } from 'axios';
import { IAxiosConfig, IRequestOptions } from 'types/common';

const request = <T>({
  options = { url: '/', method: 'get' },
  headers = {}
}: IRequestOptions): Promise<T> => {
  const authToken = localStorage.getItem('auth-token');
  const lang = localStorage.getItem('lang') || 'pl';
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api/v1',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ACCEPT_LANGUAGE: lang,
      authorization: authToken,
      ...headers
    },
    responseType: 'json'
  });

  const onSuccess = (response: AxiosResponse) => {
    if (['/login', '/signup'].includes(options?.url)) {
      return {
        ...response.data,
        token: response.headers.authorization
      };
    }

    return response.data;
  };

  // todo later
  const onError = async (error: AxiosError) => {
    // tmp
    if (error?.response?.status === 401 && options?.url !== '/login') {
      localStorage.removeItem('auth-token');
      localStorage.removeItem('auth-refresh-token');
      document.location.href = '/login';
    } else {
      const data = error?.response?.data as { error: string };
      throw new Error(data?.error || 'anErrorOccurred');
    }
    // tmp

    const originalRequest: IAxiosConfig = error.config as IAxiosConfig;

    // handle refresh token
    // if (error?.response?.status === 401 && !originalRequest.isRetryRequest) {
    //   originalRequest.isRetryRequest = true;
    //   const refreshToken = localStorage.getItem('auth-refresh-token');
    //
    //   await axios
    //     .put(process.env.REACT_APP_API_URL + '/auth/refresh', { refreshToken })
    //     .then((res) => {
    //       const { accessToken, refreshToken } = res.data;
    //
    //       localStorage.setItem('auth-token', accessToken);
    //       localStorage.setItem('auth-refresh-token', refreshToken);
    //
    //       originalRequest.headers &&
    //         (originalRequest.headers[
    //           'Authorization'
    //         ] = `Bearer ${accessToken.value}`);
    //
    //       return client.request(originalRequest);
    //     })
    //     .catch((reason) => {
    //       if (
    //         reason.response.status === 400 ||
    //         reason.response.status === 404
    //       ) {
    //         localStorage.removeItem('auth-token');
    //         localStorage.removeItem('auth-refresh-token');
    //       }
    //     });
    // }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
