export type SidebarFilters = {
  areas: {
    label: string;
    value: number[];
  };
  starred: {
    label: string;
    value: number[];
  };
  PDCAStatuses: {
    label: string;
    value: string[];
  };
  PDCAStatusesIsNew: {
    label: string;
    value: boolean;
  };
  responsiblePersons: {
    label: string;
    value: string[];
  };
  authors: {
    label: string;
    value: string[];
  };
  teamsAndGroups: {
    label: string;
    value: string[];
  };
  taskPriorities: {
    label: string;
    value: string[];
  };
  typesOfTasks: {
    label: string;
    value: string[];
  };
  idCodeName: {
    label: string;
    value: string;
  };
  created: {
    label: string;
    value: {
      from: Date | null;
      to: Date | null;
    };
  };
  startDate: {
    label: string;
    value: {
      from: Date | null;
      to: Date | null;
    };
  };
  timeLimitForCompletion: {
    label: string;
    value: {
      from: Date | null;
      to: Date | null;
    };
  };
  implementation: {
    label: string;
    value: {
      from: Date | null;
      to: Date | null;
    };
  };
  hidden: {
    label: string;
    value: number[];
  };
};

export const initSidebarFilters = {
  areas: {
    label: '',
    value: []
  },
  starred: {
    label: '',
    value: []
  },
  PDCAStatuses: {
    label: '',
    value: []
  },
  PDCAStatusesIsNew: {
    label: '',
    value: false
  },
  responsiblePersons: {
    label: '',
    value: []
  },
  authors: {
    label: '',
    value: []
  },
  teamsAndGroups: {
    label: '',
    value: []
  },
  taskPriorities: {
    label: '',
    value: []
  },
  typesOfTasks: {
    label: '',
    value: []
  },
  idCodeName: {
    label: '',
    value: ''
  },
  created: {
    label: '',
    value: {
      from: null,
      to: null
    }
  },
  startDate: {
    label: '',
    value: {
      from: null,
      to: null
    }
  },
  timeLimitForCompletion: {
    label: '',
    value: {
      from: null,
      to: null
    }
  },
  implementation: {
    label: '',
    value: {
      from: null,
      to: null
    }
  },
  hidden: {
    label: '',
    value: []
  }
};
