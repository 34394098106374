import { Flex } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 8px;
  padding: 14px;
  overflow: auto;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;
