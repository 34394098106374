import { postEvaluations } from 'api/evaluations';
import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { SwitchButton } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import { IOption as OptionForSelect } from 'components/_form/NestedSelect/NestedSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CreateEvaluationBody } from 'types/evaluations';
import { FormEvaluation } from './FormEvaluation/FormEvaluation';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { iso8601HourAndHalf } from 'constants/iso8601';
import { useAccount } from 'hooks/useAccount';

export type FormData = Omit<
  CreateEvaluationBody,
  'evaluation_participations_attributes'
>;

export type FormDataControl = FormData & {
  practice_id: IOption;
  version: IOption;
  evaluation_type: IOption;
  evaluation_participations_attributes: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
};

interface Props extends IGenericModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

export const CreateEvaluationModal: React.FC<Props> = ({
  isOpen,
  onCancelClick
}) => {
  const [approvalSwitch, setApprovalSwitch] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState<OptionForSelect[]>(
    []
  );

  const { t } = useTranslation();
  const { selectedEvaluation, handleLoadEvaluations } = useEvaluationContext();

  const { loggedUserData } = useAccount();

  const methods = useForm<FormDataControl>();

  const { handleSubmit, reset } = methods;

  const defaultFormValues: FormData = useMemo(() => {
    return {
      realization_date: new Date().toJSON().slice(0, 10).toString(),
      evaluation_type: {
        value: 'normal',
        label: 'normal'
      },
      version: {
        value: '3.0-GW',
        label: '3.0-GW'
      },
      date: new Date().toJSON().slice(0, 10).toString(),
      name: '',
      description: '',
      group: '',
      practice_id:
        selectedEvaluation !== undefined ? selectedEvaluation.practice?.id : 1,
      area_id: selectedEvaluation?.area.id?.toString() || '',
      related_to_evaluation_id: '',
      planned_duration: iso8601HourAndHalf,
      duration: iso8601HourAndHalf,
      type: 'Evaluation',
      notes: [],
      leader_id: undefined,
      team_leader_id: undefined,
      team_manager_id: undefined,
      archived_at: '',
      planned_start_date: '',
      planned_completion_date: '',
      only_realization: false,
      start_time: '',
      completion_time: '',
      evaluation_participations_attributes: [],
      team_ids: [],
      closing_ids: [loggedUserData.id]
    };
  }, [loggedUserData.id, selectedEvaluation]);

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  useEffect(() => {
    if (selectedEvaluation)
      setSelectedPractice([
        {
          label: selectedEvaluation?.practice.name,
          value: selectedEvaluation?.practice.id.toString(10)
        }
      ]);
  }, [selectedEvaluation]);

  const handleSubmitEvaluationForm = useCallback(
    async (data: FormDataControl, closeOnSubmit = true) => {
      try {
        const payload = {
          evaluation: {
            ...data,
            type: 'Evaluation',
            practice_id: Number(selectedPractice[0].value),
            evaluation_type: data.evaluation_type.value,
            author_id: JSON.parse(localStorage.getItem('currentUser') as string)
              .id,
            evaluation_participations_attributes:
              data.evaluation_participations_attributes?.map(
                (evaluationParticipationAttribute) => ({
                  participant_id: evaluationParticipationAttribute.value,
                  function_id: evaluationParticipationAttribute.function_id
                })
              )
          }
        };

        const postEvaluationsRes = await postEvaluations(payload);
        await handleLoadEvaluations();
        toast.success(t('common.addedEvaluation'));

        if (closeOnSubmit) {
          onCancelClick();
          reset(defaultFormValues);
        }

        if (postEvaluationsRes.data) {
          return postEvaluationsRes.data;
        }
      } catch (error) {
        console.log(error);
        toast.error(t('common.anErrorOccurred'));
      }
    },
    [handleLoadEvaluations, onCancelClick, selectedPractice, t]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {
          handleSubmit((data) => handleSubmitEvaluationForm(data))();
        },
        variant: 'eucalyptus',
        label: t(`evaluationsView.evaluationSubmitButton`)
      }}
      isSecondButtonVisible={true}
      gridTemplateColumns="95%"
      bodyMaxHeight="80vh"
      headerTitle={t(`evaluationsView.createEvaluation`)}
      additionalFooter={
        <Flex
          minWidth="250px"
          alignItems="center"
          gap="10px"
          display={['none', 'flex']}
        >
          <P variant="body">{t('createTaskView.footer')}</P>
          <SwitchButton value={approvalSwitch} onChange={setApprovalSwitch} />
        </Flex>
      }
    >
      <FormProvider {...methods}>
        <FormEvaluation
          selectedPractice={selectedPractice}
          setSelectedPractice={setSelectedPractice}
          handleSubmitEvaluationForm={handleSubmitEvaluationForm}
        />
      </FormProvider>
    </Modal>
  );
};
