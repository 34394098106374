import { Flex } from 'components/Flex';
import { IRatingScale, IRatingScaleButton } from 'types/ratingScales';
import { RatingScaleButton } from './RatingScaleButton';

interface Props {
  buttons: IRatingScale['buttons'];
  onButtonClick?: (button: IRatingScaleButton) => void;
  showImages?: boolean;
  useValues?: boolean;
  bordered?: boolean;
  borderRadius?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

export const RatingScaleButtons: React.FC<Props> = ({
  buttons,
  onButtonClick,
  showImages,
  useValues,
  bordered,
  borderRadius,
  minHeight,
  maxHeight
}) => {
  return (
    <Flex>
      {buttons.map((button) => (
        <RatingScaleButton
          key={`RatingScaleButtons-${button.id}`}
          button={button}
          showImages={showImages}
          useValues={useValues}
          bordered={bordered}
          borderRadius={borderRadius}
          minHeight={minHeight}
          maxHeight={maxHeight}
          onClick={() => {
            onButtonClick && onButtonClick(button);
          }}
        />
      ))}
    </Flex>
  );
};
