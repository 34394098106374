import toErrorWithMessage from 'utilities/getErrorMessage';
import request from './index';
import {
  IPatchRealizationProgress,
  IRealizationProgressRequest,
  IRealizationProgressesRequest
} from 'types/realizationProgresses';

export const getRealizationProgresses = () => {
  try {
    return request<IRealizationProgressesRequest>({
      options: {
        url: `/realization_progresses`,
        method: 'get'
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const patchRealizationProgress = (
  id: number,
  data: IPatchRealizationProgress
) => {
  try {
    return request<IRealizationProgressRequest>({
      options: {
        url: `/realization_progresses/${id}`,
        method: 'patch',
        data: data
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
