import { IPostAuditFormRequest } from 'types/audits';

export const addFakeIdToCriteria = (
  criteria: IPostAuditFormRequest['audit_form']['criteria_attributes']
) => {
  return criteria.map((criterion) =>
    criterion.id
      ? criterion
      : {
          ...criterion,
          id: `fakeId-${
            Date.now().toString(36) + Math.random().toString(36).substr(2)
          }`
        }
  );
};
