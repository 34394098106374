import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'components/Box';
import { Dropdown } from 'components/Dropdown';
import { Flex } from 'components/Flex';
import { Checkbox } from 'components/_form';

interface IColumnsOptionsProps {
  switchableItems: string[];
  hiddenItems: string[];
  setHiddenItems: React.Dispatch<React.SetStateAction<string[]>>;
  labelPrefix?: string;
}

export const ColumnsOptions: React.FC<IColumnsOptionsProps> = ({
  switchableItems,
  hiddenItems,
  setHiddenItems,
  labelPrefix
}) => {
  const onDropdownItemChange = (item: string) => {
    if (hiddenItems.includes(item)) {
      setHiddenItems((prevHiddenItems) =>
        prevHiddenItems.filter((filterItem) => filterItem !== item)
      );
    } else {
      setHiddenItems((prevHiddenItems) => [...prevHiddenItems, item]);
    }
  };

  const CustomDropdown = () => (
    <Flex flexDirection="column">
      {switchableItems.map((switchableItem) => (
        <Box key={switchableItem} my={1}>
          <Checkbox
            label={`${labelPrefix || ''}${switchableItem}`}
            checked={!hiddenItems.includes(switchableItem)}
            onChange={() => {
              onDropdownItemChange(switchableItem);
            }}
          />
        </Box>
      ))}
    </Flex>
  );

  return (
    <Dropdown
      customDropdown={<CustomDropdown />}
      bgColor="inherit"
      width="10px"
    >
      <Box mr={2}>
        <FontAwesomeIcon icon={faGear} />
      </Box>
    </Dropdown>
  );
};
