import MDEditor from '@uiw/react-md-editor';
import { Box, Flex } from 'components';
import styled from 'styled-components';
import { device } from 'styles/theme';

export const StyledFlex = styled(Flex)`
  box-sizing: border-box;
  min-height: 180px;
`;

export const Scores = styled(StyledFlex)`
  overflow-y: hidden;
`;

export const PaddingWrapper = styled.div`
  padding: 10px 20px;

  @media ${device.tablet} {
    padding: 4px;
  }
`;

export const Cell = styled(Flex)<{
  bgColor?: string;
  cursor?: string;
  height?: string;
}>`
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
  background-color: ${({ bgColor }) => bgColor};
  padding: 4px;
  width: 100%;
  height: ${({ height }) => (height ? height : '80px')};
  box-sizing: border-box;
  font-weight: bold;
  cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
`;

export const ModalHeadersWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: 42px;
`;

export const GradingScaleInformationBox = styled(Flex)`
  width: 100%;
  padding: 5px 0 5px 10px;
  background-color: ${({ theme }) => theme.palette.neutral.lightBlue};
  flex-direction: column;
  font-family: 'Open Sans';
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lightBlue};
`;

export const GradingScaleInformationRowWrapper = styled(Flex)`
  flex-direction: row;
`;

export const Numbers = styled(Flex)`
  font-weight: bold;
`;

export const EvaluationsHeadersWrapper = styled(Flex)<{ isActive?: boolean }>`
  ${({ theme, isActive }) =>
    `background-color: ${isActive ? theme.palette.primary.main : ''}`};
`;

export const EvaluationHeadersWrapper = styled(Flex)`
  padding: 0 5px 0 5px;
  align-items: center;
`;

export const PracticeEvaluationHeader = styled(EvaluationHeadersWrapper)<{
  isActive?: boolean;
  canBeActivated?: boolean;
}>`
  z-index: 100;
  width: 363px;
  ${({ theme, isActive }) =>
    isActive
      ? `background-color: ${theme.palette.primary.main}; color: white;`
      : `background-color: inherit; color: black;`}
  ${({ canBeActivated }) =>
    canBeActivated ? `cursor: pointer;` : `cursor: not-allowed;`}
`;

export const XButton = styled(Box)`
  justify-self: flex-end;
  padding: 10px;
  cursor: pointer;
  color: black;
`;

export const FontSizeChangerInput = styled.input`
  width: 45px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightBlue};
`;

export const FontSizeChangerWrapper = styled(Flex)`
  width: 150px;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const FixedFontSizeWrapper = styled.div`
  font-size: 13px;
`;

export const PointerWrapper = styled(Flex)`
  cursor: pointer;
`;

export const ComponentOrderMenu = styled(Flex)`
  position: absolute;
  flex-direction: column;
`;

export const StyledMDEditor = styled(MDEditor)`
  width: 100%;
`;

export const DescriptionContentWrapper = styled(Flex)`
  z-index: 100;
`;
