import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { useTranslation } from 'react-i18next';
import { TabPanel } from 'react-tabs';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ContentChildrenWrapper,
  ResizerWrapper,
  TabsWrapper,
  Wrapper
} from './Content.styled';

import { TaskTable } from 'views/TasksView/components/Content/TaskTable/TaskTable';
import { useDesktopContext } from 'contexts/DesktopContext';
import { useAccount } from 'hooks/useAccount';
import { TaskChart } from 'views/TasksView/components/Content/TaskChart/TaskChart';
import { TaskCalendar } from 'views/TasksView/components/Content/TaskCalendar/TaskCalendar';
import { PracticeBasedAssessments } from 'views/EvaluationsView/components/PracticeBasedAssessments/PracticeBasedAssessments';
import { theme } from 'styles';
import { Flex } from 'components';
import { Button } from 'components/_form';
import { useNavigate } from 'react-router-dom';

export const Content: React.FC = () => {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [mainContentWidth, setMainContentWidth] = useState(400);

  const { loggedUserData } = useAccount();

  const { myTeams, setSelectedTeam } = useDesktopContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: unknown) => {
      if (isResizing) {
        const val =
          // @ts-ignore
          mouseMoveEvent?.clientY -
          // @ts-ignore
          mainContentRef?.current?.getBoundingClientRect().top;

        val > 300 && val < 600 && setMainContentWidth(val);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <Wrapper>
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList style={{ borderBottom: 'none' }}>
            <TabsWrapper flexWrap="wrap">
              <CustomTab
                onClick={() => {
                  setSelectedTeam(undefined);
                }}
              >
                {t('desktop.my')}
              </CustomTab>
              {myTeams.map((team) => (
                <CustomTab
                  key={`desktop-team-tab-${team.id}`}
                  onClick={() => {
                    setSelectedTeam(team);
                  }}
                >
                  {team.name}
                </CustomTab>
              ))}
            </TabsWrapper>
          </CustomTabList>

          {[
            { itemType: 'user', id: loggedUserData.id },
            ...myTeams.map((team) => ({ ...team, itemType: 'team' }))
          ].map((item) => (
            <TabPanel key={`desktop-item-panel-${item.id}`}>
              <CustomTabs selectedTabClassName="is-selected">
                <CustomTabList style={{ borderBottom: 'none' }}>
                  <TabsWrapper flexWrap="wrap">
                    <CustomTab bgColor={theme.palette.accent.steelBlue}>
                      {t('administration.tasks')}
                    </CustomTab>
                    <CustomTab bgColor={theme.palette.accent.steelBlue}>
                      {t('desktop.solvingProblems')}
                    </CustomTab>
                    <CustomTab bgColor={theme.palette.accent.steelBlue}>
                      {t('desktop.audits')}
                    </CustomTab>
                    <CustomTab bgColor={theme.palette.accent.steelBlue}>
                      {t('tasks.chart')}
                    </CustomTab>
                    <CustomTab bgColor={theme.palette.accent.steelBlue}>
                      {t('tasks.calendar')}
                    </CustomTab>
                  </TabsWrapper>
                </CustomTabList>

                <TabPanel>
                  <ContentChildrenWrapper
                    ref={mainContentRef}
                    style={{ height: mainContentWidth }}
                  >
                    <Flex>
                      <Button
                        onClick={() => {
                          navigate('/app/tasks');
                        }}
                      >
                        {t('common.showAll')}
                      </Button>
                    </Flex>

                    {item.itemType === 'user' ? (
                      <TaskTable
                        showTopBar={false}
                        showAdditionalHeader={false}
                        person_in_charge_ids={[item.id.toString()]}
                        loadContextFilters={false}
                      />
                    ) : (
                      <TaskTable
                        showTopBar={false}
                        showAdditionalHeader={false}
                        team_ids={[item.id.toString()]}
                        loadContextFilters={false}
                      />
                    )}
                    <ResizerWrapper onMouseDown={startResizing as any}>
                      <FontAwesomeIcon icon={faGripLines} />
                    </ResizerWrapper>
                  </ContentChildrenWrapper>
                </TabPanel>

                <TabPanel>
                  <ContentChildrenWrapper>
                    <Flex>
                      <Button
                        onClick={() => {
                          navigate('/app/tasks');
                        }}
                      >
                        {t('common.showAll')}
                      </Button>
                    </Flex>

                    {item.itemType === 'user' ? (
                      <TaskTable
                        showTopBar={false}
                        showAdditionalHeader={false}
                        person_in_charge_ids={[item.id.toString()]}
                        task_type_kinds={['problem_solving']}
                        loadContextFilters={false}
                      />
                    ) : (
                      <TaskTable
                        showTopBar={false}
                        showAdditionalHeader={false}
                        team_ids={[item.id.toString()]}
                        task_type_kinds={['problem_solving']}
                        loadContextFilters={false}
                      />
                    )}
                  </ContentChildrenWrapper>
                </TabPanel>

                <TabPanel>
                  <ContentChildrenWrapper>
                    {/* TODO: replace with Audits list when it's ready  */}
                    <PracticeBasedAssessments showAllButton />
                  </ContentChildrenWrapper>
                </TabPanel>

                <TabPanel>
                  <ContentChildrenWrapper>
                    <TaskChart />
                  </ContentChildrenWrapper>
                </TabPanel>

                <TabPanel>
                  <TaskCalendar />
                </TabPanel>
              </CustomTabs>
            </TabPanel>
          ))}
        </CustomTabs>
      </Wrapper>
    </>
  );
};
