import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IUser } from 'types/user';

export interface IUserState {
  me: IUser;
  friends?: IUser[];
}

const initialState: IUserState = {
  me: {
    id: 0,
    username: '-',
    email: '-',
    role: 'user',
    company_id: 0,
    owned_areas: [],
    profile: {
      id: 0,
      active: true,
      first_name: '-',
      second_name: '-',
      last_name: '-',
      id_code: '-',
      language: '-',
      phone: '-',
      landline_phone: '-',
      photo: ''
    }
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getMe: (state, { payload }) => {
      state.me = payload;
    }
  }
});

export const userData = (state: RootState) => state.user;

export const { getMe } = userSlice.actions;

export default userSlice.reducer;
