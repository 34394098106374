import { Box } from 'components/Box';
import React, { useEffect } from 'react';
import { StylesConfig, default as reactSelect } from 'react-select';
import styled from 'styled-components';
import { FieldLabel as Label } from 'components/_form/FieldLabel';
import { ErrorText } from '../ErrorText';
import { DefaultTFuncReturn, t } from 'i18next';
import { P } from 'components/Typography/Typography';
import { Flex } from 'components/Flex';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { theme } from 'styles';

export interface IOption {
  label: string | JSX.Element;
  value: string;
}

interface ISelect extends Omit<StateManagerProps, 'onChange'> {
  error?: string | DefaultTFuncReturn;
  isClearable?: boolean;
  options: IOption[];
  onChange?(e: IOption | IOption[]): void;
  transparent?: boolean;
  label?: string | DefaultTFuncReturn;
  hideElements?: boolean;
  withoutMargin?: boolean;
  helperText?: string | DefaultTFuncReturn;
  placeholder?: string;
  disabled?: boolean;
  height?: string;
}

interface MultiSelect {
  isMulti?: true;
  selectedOptions?: IOption[];
}

interface SingleSelect {
  isMulti?: false;
  selectedOptions?: IOption;
}

interface IStyledTags {
  transparent?: ISelect['transparent'];
}

const StyledSelect = styled(reactSelect)<IStyledTags>`
  ${({ transparent }) => `
    ${transparent && `& > div:nth-child(2) {background-color: transparent;}`}
  `}
  .valueContainer {
    opacity: 1;
  }
`;

const HelperText = styled(P)`
  text-align: right;
  font-style: oblique;
  width: 100%;
`;

export const Select: React.FC<ISelect & (MultiSelect | SingleSelect)> = ({
  error,
  selectedOptions,
  isClearable = false,
  options,
  onChange,
  label,
  transparent,
  hideElements,
  isMulti,
  withoutMargin,
  helperText,
  placeholder,
  disabled,
  height,
  ...restProps
}) => {
  const [value, setValue] = React.useState<IOption[] | IOption>(
    selectedOptions || []
  );

  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      minHeight: '35px',
      height: height || '35px',
      border: error ? `1px solid ${theme.palette.accent.red}` : '',
      boxShadow: error ? `0 0 0 3px ${theme.palette.accent.lightRed}` : ''
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: '999'
    })
  };
  if (hideElements) {
    customStyles.multiValue = (provided: any) => ({
      ...provided,
      display: 'none'
    });
  }

  const handleChange = (newValue: IOption) => {
    const val = isMulti
      ? Array.isArray(newValue)
        ? newValue
        : [newValue]
      : newValue;
    setValue(val);
    if (onChange) onChange(val);
  };

  useEffect(() => {
    setValue(selectedOptions || []);
  }, [selectedOptions]);

  return (
    <>
      <Box mb={!withoutMargin ? 3 : undefined} width="100%">
        <Flex justifyContent="space-between">
          {label && <Label error={error}>{t(label)}</Label>}
          {helperText && (
            <HelperText variant="body">{t(helperText)}</HelperText>
          )}
        </Flex>
        <StyledSelect
          {...restProps}
          /*// @ts-ignore*/
          onChange={handleChange}
          isClearable={isClearable}
          styles={customStyles}
          transparent={transparent}
          value={value}
          isMulti={isMulti}
          closeMenuOnSelect={true}
          options={options}
          menuPortalTarget={document.body}
          placeholder={placeholder}
          isDisabled={disabled}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Box>
    </>
  );
};
