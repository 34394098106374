import { Flex } from 'components';
import { TableCell } from '../../AuditFormForm.styled';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/_form';
import { CriteriaTable } from '../CriteriaTable/CriteriaTable';
import { IRatingScale } from 'types/ratingScales';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IPostAuditFormRequest } from 'types/audits';
import { CriteriaRow } from '../CriteriaTable/components/CriteriaRow';
import { RatingScaleButton } from 'components/RatingScaleButtons/RatingScaleButton';

interface Props {
  ratingScale?: IRatingScale;
}

export const AuditCriteria: React.FC<Props> = ({ ratingScale }) => {
  const { t } = useTranslation();

  const { control, watch, register } =
    useFormContext<IPostAuditFormRequest['audit_form']>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `groups_attributes`
  });

  let groupDefaultNumber = 0;

  const addNewGroupsAttributes = () => {
    append({
      order_number: fields.length + 1,
      name: '',
      show_results: true,
      groups_attributes: [],
      criteria_attributes: []
    });
  };

  const {
    fields: fieldsCriteriaAttributes,
    append: appendCriteriaAttributes,
    remove: removeCriteriaAttributes
  } = useFieldArray({
    control,
    name: 'criteria_attributes'
  });

  let criteriaDefaultNumber = 0;

  const addNewCriteriaAttributes = () => {
    appendCriteriaAttributes({
      order_number: fieldsCriteriaAttributes.length + 1,
      content: '',
      weight: 1,
      tips: '',
      blocked_rating_button_ids: []
    });
  };

  return (
    <Flex overflow="auto" width="100%">
      <Flex flexDirection="column" minWidth="1200px" width="100%">
        <Flex mt={5}>
          <TableCell width="3%"></TableCell>
          <TableCell width="7%" justifyContent="center">
            {t('administrationAuditsView.criteriaTableHeaders.nr')}
          </TableCell>
          <TableCell width="66%" justifyContent="space-between">
            <Flex>
              {t(
                'administrationAuditsView.criteriaTableHeaders.evaluationCriteria'
              )}
            </Flex>

            <Flex
              justifyContent="space-between"
              width={`${(ratingScale?.buttons?.length || 1) * 50}px`}
              mr="-6px"
            >
              {ratingScale?.buttons?.map((ratingScaleButton) => (
                <Flex
                  width="100%"
                  justifyContent="center"
                  key={`AuditCriteria-ratingScaleButton-${ratingScaleButton.id}`}
                >
                  <RatingScaleButton
                    minHeight="50px"
                    showImages={ratingScale.button_type === 'picture'}
                    button={ratingScaleButton}
                  />
                </Flex>
              ))}
            </Flex>
          </TableCell>

          <TableCell width="5%" justifyContent="center">
            {t('administrationAuditsView.criteriaTableHeaders.weight')}
          </TableCell>
          <TableCell width="5%" justifyContent="center">
            {t('administrationAuditsView.criteriaTableHeaders.scaleDesc')}
          </TableCell>
          <TableCell width="5%" justifyContent="center">
            {t('administrationAuditsView.criteriaTableHeaders.tips')}
          </TableCell>
          <TableCell width="5%" justifyContent="center">
            {t('administrationAuditsView.criteriaTableHeaders.example')}
          </TableCell>
          <TableCell width="4%"></TableCell>
        </Flex>

        <TableCell mt={2} justifyContent="end" gap="10px">
          <Button variant="green" onClick={addNewCriteriaAttributes}>
            <Flex alignItems="center" gap="8px">
              <FontAwesomeIcon icon={faQuestionCircle} />
              {t('administrationAuditsView.criterion')}
            </Flex>
          </Button>
          <Button onClick={addNewGroupsAttributes}>
            <Flex alignItems="center" gap="8px">
              <FontAwesomeIcon icon={faCube} />
              {t('administrationAuditsView.group')}
            </Flex>
          </Button>
        </TableCell>

        {fieldsCriteriaAttributes.map((field, index) => {
          if (index > 0) {
            const numberToAdd = watch(
              `criteria_attributes.${index - 1}.omit_in_numbering`
            )
              ? 0
              : 1;
            criteriaDefaultNumber = criteriaDefaultNumber + numberToAdd;
          }

          return (
            <Flex key={field.id} flexDirection="column" width="100%">
              <CriteriaRow
                defaultNumber={criteriaDefaultNumber}
                prefix={`criteria_attributes.${index}.`}
                indentNumber={0}
                removeCriteriaAttributes={() => {
                  removeCriteriaAttributes(index);
                }}
                ratingScaleButtons={ratingScale?.buttons}
              />
            </Flex>
          );
        })}

        {fields.map((field, index) => {
          if (index > 0) {
            const numberToAdd = watch(
              `groups_attributes.${index - 1}.omit_in_numbering`
            )
              ? 0
              : 1;
            groupDefaultNumber = groupDefaultNumber + numberToAdd;
          }

          return (
            <CriteriaTable
              defaultNumber={groupDefaultNumber}
              key={`CriteriaTable-${field.id}-${field.name}`}
              ratingScaleButtons={ratingScale?.buttons}
              prefix={`groups_attributes.${index}.`}
              indentNumber={0}
              removeGroupsAttributes={() => {
                remove(index);
              }}
              addNewGroupsAttributes={addNewGroupsAttributes}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
