import { Block } from 'components/Block';
import { Flex } from 'components/Flex';
import { H2, H3, P, TypographyType } from 'components/Typography/Typography';
import { IBlock, IPractice } from 'types/methologies';
import { Topic } from './Blocks.styled';
import { useTranslation } from 'react-i18next';
import { BlockScore, IEvaluationPractice } from 'types/evaluations';
import { useCallback } from 'react';
import { useEvaluationContext } from 'contexts/EvaluationContext';

interface Score {
  evaluationType: string;
  avgScore: number;
  emptyScores?: boolean;
}

interface Props {
  practice: IPractice | IEvaluationPractice;
  checkButton?: boolean;
  onBlockClick?: (block: IBlock) => void;
  selectedBlock?: IBlock;
  width?: number;
  height?: number;
  fontVariant?: TypographyType['variant'];
  topicFontVariant?: TypographyType['variant'];
  increasing?: boolean;
  showScores?: boolean;
  bgColor?: string;
  selectedBgColor?: string;
}

export const Blocks: React.FC<Props> = ({
  practice,
  onBlockClick,
  checkButton = true,
  selectedBlock,
  width = 70,
  height = 50,
  fontVariant,
  topicFontVariant,
  increasing,
  showScores,
  bgColor,
  selectedBgColor
}) => {
  const { t } = useTranslation();
  const { selectedEvaluations } = useEvaluationContext();

  const filterScores = useCallback(
    (blockId: number) => {
      const scoresFiltered: Score[] = [];
      selectedEvaluations?.map((evaluation, i) => {
        evaluation.practice.blocks.map((block) => {
          if (block.id === blockId) {
            scoresFiltered.push({
              evaluationType: evaluation.evaluation_type,
              avgScore: block.avg_score,
              emptyScores: !!block.criteria.every(
                (criterion) => criterion.score === null
              )
            });
          }
        });
      });

      return scoresFiltered;
    },
    [selectedEvaluations]
  );

  const checkIsOnlyBasicEvaluationType = useCallback(
    (filteredScores: Score[]) => {
      const evaluationTypesForOneColumn = ['target', 'baseline', 'current'];
      let isOneColumnScore: boolean[] = [];
      evaluationTypesForOneColumn.map((evaluationType) => {
        filteredScores.map((score) => {
          if (score.evaluationType === evaluationType) {
            isOneColumnScore.push(true);
          }
        });
      });

      return isOneColumnScore.length === filteredScores.length;
    },
    []
  );

  const isScoreDuplicate = useCallback((filteredScores: Score[]) => {
    return filteredScores.some(
      (score, i) =>
        filteredScores.findIndex(
          (findScore) => findScore.evaluationType === score.evaluationType
        ) !== i
    );
  }, []);

  const checkForOneColumnScores = useCallback((filteredScores: Score[]) => {
    const isOnlyBasicEvaluationType =
      checkIsOnlyBasicEvaluationType(filteredScores);
    const isDuplicate = isScoreDuplicate(filteredScores);
    const isOneColumnScore = isOnlyBasicEvaluationType && !isDuplicate;

    return isOneColumnScore;
  }, []);

  const makeScore = useCallback(
    (blockId: number) => {
      const filteredScores = filterScores(blockId);

      const scores: BlockScore[] = [];
      let scoreObject: BlockScore = {
        goal: null,
        current: null,
        external: null
      };

      if (checkForOneColumnScores(filteredScores)) {
        filteredScores.map((score) => {
          switch (score.evaluationType) {
            case 'target':
              scoreObject.goal = score.avgScore + '%';
              break;
            case 'current':
              scoreObject.current = score.avgScore + '%';
              break;
            case 'baseline':
              scoreObject.external = score.avgScore + '%';
              break;
          }
        });
      } else {
        filteredScores.map((score) => {
          scoreObject.current = score.avgScore + '%';
          scores.push(scoreObject);
        });
      }
      if (scores.length === 0) scores.push(scoreObject);

      return scores;
    },
    [checkForOneColumnScores, filterScores]
  );

  return (
    <Flex overflow="auto">
      <Flex flexDirection="column" gap="6px" mt={5} pb={2}>
        {practice?.stages
          ?.sort((a, b) => a.number - b.number)
          .reverse()
          .map((stage) => (
            <Flex key={stage.id} gap="6px">
              <Flex
                width={`${width}px`}
                alignItems="center"
                justifyContent="center"
              >
                <Flex flexDirection="column" alignItems="center">
                  <H3 variant="h3">{t('administration.stage')}</H3>
                  <H2 variant="h2">{stage.name}</H2>
                </Flex>
              </Flex>

              {practice?.topics
                .sort((a, b) => a.number - b.number)
                .map((topic) => {
                  const filteredBlock = practice?.blocks
                    .filter(
                      (block) =>
                        block.stage_id === stage.id &&
                        block.topic_id === topic.id
                    )
                    .sort((a, b) => a.column - b.column);

                  let blocks: (IBlock | undefined)[] = [];

                  for (let i = 1; i <= topic.columns; i++) {
                    const foundBlock = filteredBlock.find(
                      (findBlock) => findBlock.column === i
                    );
                    blocks.push(foundBlock);

                    if (foundBlock) {
                      i = i + 1;
                    }
                  }

                  return {
                    blocks: blocks,
                    topic
                  };
                })
                .map((cell) =>
                  cell.blocks.length ? (
                    cell.blocks.map((block) => {
                      let score;
                      let isOneColumn;
                      let everyEvaluationEmpty;
                      if (block) {
                        score = makeScore(block.id);
                        isOneColumn = checkForOneColumnScores(
                          filterScores(block.id)
                        );
                        const filteredScores = filterScores(block.id);
                        everyEvaluationEmpty = filteredScores.every(
                          ({ emptyScores }) => emptyScores
                        );
                      }

                      return (
                        <Flex
                          key={block?.id}
                          width={block ? `${width}px` : `${width / 2 - 3}px`}
                        >
                          <Block
                            coloured={selectedBlock?.id === block?.id}
                            checkButton={checkButton}
                            block={block}
                            onClick={() => {
                              onBlockClick && block && onBlockClick(block);
                            }}
                            width={`${width}px`}
                            height={`${height}px`}
                            fontVariant={fontVariant}
                            increasing={increasing}
                            scores={showScores ? score : undefined}
                            isOneColumn={isOneColumn}
                            bgColor={everyEvaluationEmpty ? bgColor : undefined}
                            selectedBgColor={selectedBgColor}
                          />
                        </Flex>
                      );
                    })
                  ) : (
                    <Flex key={cell.topic.id} width={`${width / 2 - 3}px`}>
                      <Block
                        block={undefined}
                        width={`${width}px`}
                        height={`${height}px`}
                      />
                    </Flex>
                  )
                )}
            </Flex>
          ))}

        <Flex gap="6px">
          <Topic
            alignItems="center"
            justifyContent="center"
            width={`${width}px`}
          >
            <P variant={topicFontVariant || 'body3'}>{t('administration.topics')}:</P>
          </Topic>
          {practice?.topics.map((topic) => (
            <Topic
              key={topic.id}
              width={`${
                (topic.columns / 2) * width + (topic.columns / 2 - 1) * 6 + 1
              }px`}
              alignItems="center"
              justifyContent="center"
            >
              <P variant={topicFontVariant || 'body3'}>{topic.name}</P>
            </Topic>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
