import { Flex, Line } from 'components';
import { Button } from 'components/_form';
import UserListTable from './components/UserListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../CompanyAdministrationView/CompanyAdministrationView.styled';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/BackButton';
import { H1 } from 'components/Typography/Typography';

export const TranslationsPermittedUsersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} - {t('administration.translations')} |{' '}
          {t('translationsView.authorized to edit translations')}
        </H1>
      </Flex>
      <Line />
      <Flex>
        <Button
          variant="eucalyptus"
          onClick={() => {
            navigate('/app/administration/employees-and-users');
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.addPerson')}
        </Button>
      </Flex>
      <UserListTable />
    </Wrapper>
  );
};
