import {
  faArrowsRotate,
  faCirclePlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { Button } from 'components/_form';
import { useRef } from 'react';
import styled from 'styled-components';

const ImagePreview = styled.img`
  ${({ theme }) => `border: 1px solid ${theme.palette.neutral.greyButton}`}
  width: 80px;
  height: 80px;
  object-fit: cover;
`;

interface Props {
  onChange: (newFile: File) => void;
  previewUrl?: string;
}

export const ImagePicker: React.FC<Props> = ({ onChange, previewUrl }) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Flex flexDirection="column" gap="3px">
      <ImagePreview src={previewUrl} />

      <input
        ref={ref}
        onChange={(e) => {
          const newFile = e.target.files?.[0];

          newFile && onChange(newFile);
        }}
        type="file"
        style={{ display: 'none' }}
      />

      <Button
        onClick={() => {
          ref.current?.click();
        }}
        variant="grey"
        bordered
        width="80px"
      >
        <FontAwesomeIcon icon={faCirclePlus} color="black" /> /{' '}
        <FontAwesomeIcon icon={faArrowsRotate} color="black" />
      </Button>
    </Flex>
  );
};
