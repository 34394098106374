import { IOption } from 'components/_form/NestedSelect/NestedSelect';
import { AreaAccordionItem, IArea } from 'types/area';

export const parentToChildrenAreasStructure = (dataToRestructure: IArea[]) => {
  const data = structuredClone(dataToRestructure);
  const idMap = new Map(); // Map to store ids and their corresponding objects

  // First loop to create idMap
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    const id = obj.id;
    idMap.set(id, obj);
  }

  // Second loop to set parent references
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    const parentId = obj.parent ? obj.parent.id : null; // Get parentId from parent object, if present
    if (parentId) {
      const parentObj = idMap.get(parentId);
      if (parentObj) {
        if (!parentObj.children) {
          parentObj.children = []; // Create children array if not present
        }
        parentObj.children.push(obj); // Add current object as child of parent object
      }
    }
  }

  // Filter out objects that were originally children (i.e., have parent references)
  const result = data.filter((obj: any) => !obj.parent);

  return result;
};

export const parseParentToChildrenAreasStructureToOptions = (
  items: AreaAccordionItem[]
): IOption[] => {
  return items.map((item) => ({
    label: item.name,
    value: String(item.id),
    isDisabled: item.isDisabled,
    ...(item.children?.length
      ? {
          options: parseParentToChildrenAreasStructureToOptions(item.children)
        }
      : {})
  }));
};
