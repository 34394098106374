import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { IRatingScale } from 'types/ratingScales';
import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { format } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { useTranslation } from 'react-i18next';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  audit?: PartialAudit;
  criteriaEvaluationCounts: {
    [key: string]: number;
  };
  ratingScale?: IRatingScale;
}

export const PieChart: React.FC<Props> = ({
  audit,
  criteriaEvaluationCounts,
  ratingScale
}) => {
  const { t } = useTranslation();

  const colors = Object.keys(criteriaEvaluationCounts).map(
    (value) =>
      ratingScale?.buttons.find(
        (button) => button.value.toString() === value.toString()
      )?.color1
  );

  const data = {
    labels: Object.keys(criteriaEvaluationCounts),
    datasets: [
      {
        label: '',
        data: Object.values(criteriaEvaluationCounts),
        backgroundColor: colors,
        borderWidth: 0
      }
    ]
  };

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <P variant="h3" ml={4}>
          {audit?.first_header_name}
        </P>
        <Flex flexDirection="column">
          {audit?.second_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.area')}</P>
              <P variant="h4" color="grey">
                {audit?.second_header_name}
              </P>
            </Flex>
          )}
          {audit?.third_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.team')}</P>
              <P variant="h4" color="grey">
                {audit?.third_header_name}
              </P>
            </Flex>
          )}
          {audit?.fourth_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.shift')}</P>
              <P variant="h4" color="grey">
                {audit?.fourth_header_name}
              </P>
            </Flex>
          )}

          <Flex justifyContent="end" alignItems="center">
            <P variant="h4">{format(new Date(), dateFormat)}</P>
          </Flex>
        </Flex>
      </Flex>
      <Pie data={data} />
    </Flex>
  );
};
