import { Card } from 'components/Card';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'utilities/rgba';
import { device } from 'styles/theme';
import { H2, TypographyType } from 'components/Typography/Typography';
import { Flex } from 'components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Box } from 'components/Box';
import { Button } from 'components/_form';
import { ButtonI } from 'components/_form/Button/Button';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';

const modalRoot = document.getElementById('modal-root')!;

export interface IGenericModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

export type GenericModalProps = IGenericModal;

export interface IModal extends IGenericModal {
  headerTitle?: string | DefaultTFuncReturn | JSX.Element;
  headerTitleColor?: TypographyType['color'];
  children?: React.ReactNode;
  mainButton: Button;
  isSecondButtonVisible?: boolean;
  secondButton?: Button;
  isThirdButtonVisible?: boolean;
  thirdButton?: Button;
  additionalFooter?: JSX.Element;
  disableBodySharedStyles?: boolean;
  disableHeaderSharedStyles?: boolean;
  bodyMaxHeight?: string | string[];
  disableOverflow?: boolean;
  gridTemplateColumns?: string;
  fontSize?: number;
}

type Button = {
  isDisabled?: boolean;
  variant?: ButtonI['variant'];
  icon?: ButtonI['icon'];
  action: () => void;
  label?: string | DefaultTFuncReturn | JSX.Element;
};

const WrapperModal = styled.div<{
  gridTemplateColumns?: string;
  fontSize?: number;
}>`
  z-index: 20;
  grid-template-columns: ${({ gridTemplateColumns }) =>
    gridTemplateColumns ? gridTemplateColumns : '85%'};

  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px;` : '')}

  @media ${device.mobile} {
    grid-template-columns: 97%;
  }

  ${({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${rgba(theme.palette.neutral.dark, 0.3)};
  `}
`;

const StyledCard = styled(Card)`
  max-height: 100vh;
  padding: 0;
`;

const sharedStyle = css`
  width: 100%;
  min-height: 10px;
  padding: 6px 20px;
`;

const Body = styled(Flex)<{
  disableSharedStyles?: boolean;
  isOverflowY?: boolean;
}>`
  flex-direction: column;
  ${({ disableSharedStyles }) => (disableSharedStyles ? `` : sharedStyle)}
  ${({ isOverflowY }) =>
    isOverflowY ? `overflow-y: auto;` : 'overflow-y: hidden;'}
  position: relative;

  ${({ theme }) => `
    ::-webkit-scrollbar {
      width: 10px;

      @media ${device.tablet} {
        width: 2px;
      }
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.accent.steelBlue};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.palette.accent.blue};
    }
  `}
`;

const Header = styled(Flex)<{ disableSharedStyles?: boolean }>`
  ${({ disableSharedStyles }) => (disableSharedStyles ? `` : sharedStyle)}
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.medium};
`;

const Footer = styled(Flex)<{ displayBorder?: boolean }>`
  ${sharedStyle};
  justify-content: space-between;
  align-items: center;
  ${({ theme, displayBorder }) =>
    displayBorder
      ? `border-top: 1px solid ${theme.palette.neutral.medium};`
      : ''}
`;
const Buttons = styled(Flex)`
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const XButton = styled(Box)`
  justify-self: flex-end;
  padding: 10px;
  cursor: pointer;
  color: black;
`;

export const Modal: React.FC<IModal> = ({
  isOpen,
  onCancelClick,
  headerTitle,
  headerTitleColor,
  children,
  mainButton,
  isSecondButtonVisible = false,
  secondButton,
  isThirdButtonVisible,
  thirdButton,
  additionalFooter,
  disableBodySharedStyles = false,
  disableHeaderSharedStyles = false,
  bodyMaxHeight = ['75vh', '85vh'],
  gridTemplateColumns,
  fontSize,
  disableOverflow
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCancelClick();
      }
    },
    [onCancelClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const generatedButtons = (
    <Flex
      width="100%"
      flexDirection="row-reverse"
      justifyContent="space-between"
    >
      {isThirdButtonVisible && (
        <Button
          onClick={thirdButton?.action ?? onCancelClick}
          variant={thirdButton?.variant ? thirdButton?.variant : 'red'}
          disabled={thirdButton?.isDisabled}
        >
          <Flex gap="5px" alignItems="center">
            {thirdButton?.icon}
            {thirdButton?.label ?? t('buttons.cancel')}
          </Flex>
        </Button>
      )}
      <Buttons>
        {isSecondButtonVisible && (
          <Button
            onClick={secondButton?.action ?? onCancelClick}
            variant={secondButton?.variant ? secondButton?.variant : 'grey'}
            disabled={secondButton?.isDisabled}
          >
            <Flex gap="5px" alignItems="center">
              {secondButton?.icon}
              {secondButton?.label ?? t('buttons.cancel')}
            </Flex>
          </Button>
        )}
        <Button
          onClick={mainButton.action}
          disabled={mainButton.isDisabled}
          variant={mainButton.variant ?? 'eucalyptus'}
        >
          <Flex gap="5px" alignItems="center">
            {mainButton?.icon}
            {mainButton.label ?? t('buttons.ok')}
          </Flex>
        </Button>
      </Buttons>

      {additionalFooter && additionalFooter}
    </Flex>
  );

  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <WrapperModal
          gridTemplateColumns={gridTemplateColumns}
          fontSize={fontSize}
        >
          <div ref={wrapperRef}>
            <StyledCard>
              <H2 variant="h3" color={headerTitleColor}>
                <Header disableSharedStyles={disableHeaderSharedStyles}>
                  {headerTitle}
                  <XButton onClick={onCancelClick}>
                    <FontAwesomeIcon icon={faXmark} />
                  </XButton>
                </Header>
              </H2>
              <Body
                disableSharedStyles={disableBodySharedStyles}
                maxHeight={bodyMaxHeight}
                isOverflowY={
                  disableOverflow ? false : bodyMaxHeight?.length ? true : false
                }
              >
                {children}
              </Body>
              <Footer displayBorder={!!children}>{generatedButtons}</Footer>
            </StyledCard>
          </div>
        </WrapperModal>
      ) : null}
    </>,
    modalRoot
  );
};
