import { Box, Flex, Line } from 'components';
import PlatTImage from 'assets/images/PlatT-methodology.jpg';
import ADMAImage from 'assets/images/ADMA-methdology.png';
import { H2, P } from 'components/Typography/Typography';
import { t } from 'i18next';
import {
  Button,
  Checkbox,
  Radio,
  Select,
  TextareaField
} from 'components/_form';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { AdjustBlocks } from './components/AdjustBlocks/AdjustBlocks';
import { IMethodology, IPractice } from 'types/methologies';
import { getMethodologies, getPractices } from 'api/methodologies';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTab } from 'components/Tabs/CustomTab';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const OverflowFlex = styled(Flex)`
  overflow: auto;
  width: 100%;
`;

const CenteredTd = styled.td`
  text-align: center;
  align-items: center;
`;

const StyledTh = styled.th`
  border-bottom: 1px solid black;
`;

const StyledTr = styled.tr`
  border-bottom: 1px solid black;
`;

const ToRightTd = styled.td`
  text-align: end;
`;

const methodologyImagesDict = {
  PlatT: PlatTImage,
  ADMA: ADMAImage
};

export const LicensesAndPermissionForm = () => {
  const [methodologies, setMethodologies] = useState<IMethodology[]>([]);
  const [practices, setPractices] = useState<IPractice[]>([]);
  const [selectedMethodology, setSelectedMethodology] =
    useState<IMethodology>();
  const [selectedPractice, setSelectedPractice] = useState<IPractice>();
  const { control, setValue, watch } = useForm();

  const handleCheckAllByIndex = (index: number) => {
    practices.map((practice) => {
      setValue(`${practice.id}${index}`, true);
    });
  };

  const handleGetMethodologies = async () => {
    const { data } = await getMethodologies();
    setMethodologies(data);
    setSelectedMethodology(data[0]);
  };

  const handleGetPractices = useCallback(async () => {
    const { data } = await getPractices({
      methodology_ids: selectedMethodology?.id ? [selectedMethodology?.id] : []
    });
    setPractices(data);
  }, [selectedMethodology?.id]);

  useEffect(() => {
    handleGetMethodologies();
  }, []);

  useEffect(() => {
    handleGetPractices();
  }, [handleGetPractices]);

  return (
    <Flex flexDirection="column">
      {!selectedPractice && (
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList>
            <Flex flexWrap="wrap">
              {methodologies.map((methodology) => (
                <CustomTab
                  key={`company-methodologies-${methodology.id}`}
                  onClick={() => {
                    setSelectedMethodology(methodology);
                  }}
                >
                  {methodology.name}
                </CustomTab>
              ))}
            </Flex>
          </CustomTabList>
        </CustomTabs>
      )}

      <H2 variant="h2" color="coloured">
        {t('administration.materialsLicensesAndPermission')}
      </H2>
      <Line />
      {selectedPractice ? (
        <AdjustBlocks
          selectedPractice={selectedPractice}
          setSelectedPractice={setSelectedPractice}
        />
      ) : (
        <>
          <Flex width="100%" flexDirection={['column', 'row']}>
            <Flex width={['100%', '50%']}>
              <img
                width="100%"
                src={
                  methodologyImagesDict[
                    (selectedMethodology?.name ||
                      'PlatT') as keyof typeof methodologyImagesDict
                  ]
                }
                alt="parts"
              />
            </Flex>
            <Flex width={['100%', '50%']} flexDirection="column">
              <P variant="body">
                {t('administration.materialsPermissionSettings')}
              </P>
              <Flex alignItems="start" mt={2} flexWrap={['wrap', 'nowrap']}>
                <Box mr={4}>
                  <Radio label="administration.global" />
                </Box>
                <Box mr={4} width="40%">
                  <Radio label="administration.byStructure" />
                </Box>
                <Box width="100%" m={[2, 0]}>
                  <Select options={[]} />
                </Box>
              </Flex>
              <OverflowFlex>
                <StyledTable>
                  <tr>
                    <th></th>
                    <th>{t('administration.total')}</th>
                    <th>{t('administration.admin')}</th>
                    <th>{t('administration.usr.')}</th>
                    <th>{t('administration.guest')}</th>
                  </tr>
                  <tr>
                    <CenteredTd>
                      {t('administration.usersLicensesCount')}
                    </CenteredTd>
                    <CenteredTd>99</CenteredTd>
                    <CenteredTd>5</CenteredTd>
                    <CenteredTd>33</CenteredTd>
                    <CenteredTd>60</CenteredTd>
                  </tr>
                  <tr>
                    <CenteredTd>
                      {t('administration.usedLicensesCount')}
                    </CenteredTd>
                    <CenteredTd>99</CenteredTd>
                    <CenteredTd>5</CenteredTd>
                    <CenteredTd>33</CenteredTd>
                    <CenteredTd>60</CenteredTd>
                  </tr>
                </StyledTable>
              </OverflowFlex>

              <Flex
                justifyContent="space-between"
                alignItems="center"
                pt={4}
                flexDirection={['column', 'row']}
              >
                <P variant="body">
                  {t('administration.licenseExpirationDate')}
                </P>
                <P variant="body" color="green">
                  17.10.2022
                </P>
                <Button variant="eucalyptus">
                  {t('administration.extendOrChange')}
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <OverflowFlex>
            <StyledTable>
              <thead>
                <tr>
                  <th></th>
                  <StyledTh colSpan={4}>
                    {t('administration.availableBlocks')}
                  </StyledTh>
                  <th></th>
                  <th></th>
                </tr>
                <StyledTr>
                  <th></th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>
                    <Box px={1}>{t('administration.customizeBlocks')}</Box>
                  </th>
                  <th>
                    <Box px={1}>{t('administration.availableVersions')}</Box>
                  </th>
                </StyledTr>
              </thead>

              <tbody>
                {practices.map((practice) => (
                  <StyledTr key={practice.id}>
                    <td>{practice.name}</td>
                    <CenteredTd>
                      <Controller
                        control={control}
                        name={`${practice.id}1`}
                        render={({ field: { onChange, value } }) => (
                          <Flex my={1} justifyContent="center">
                            <Checkbox
                              unCheckedIconDisplay
                              variant="secondary"
                              checked={!!value}
                              onChange={(val) => {
                                onChange(
                                  val.target.checked ? 'field.id' : false
                                );
                              }}
                            />
                          </Flex>
                        )}
                      />
                    </CenteredTd>
                    <CenteredTd>
                      <Controller
                        control={control}
                        name={`${practice.id}2`}
                        render={({ field: { onChange, value } }) => (
                          <Flex my={1} justifyContent="center">
                            <Checkbox
                              unCheckedIconDisplay
                              variant="secondary"
                              checked={!!value}
                              onChange={(val) => {
                                onChange(
                                  val.target.checked ? 'field.id' : false
                                );
                              }}
                            />
                          </Flex>
                        )}
                      />
                    </CenteredTd>
                    <CenteredTd>
                      <Controller
                        control={control}
                        name={`${practice.id}3`}
                        render={({ field: { onChange, value } }) => (
                          <Flex my={1} justifyContent="center">
                            <Checkbox
                              unCheckedIconDisplay
                              variant="secondary"
                              checked={!!value}
                              onChange={(val) => {
                                onChange(
                                  val.target.checked ? 'field.id' : false
                                );
                              }}
                            />
                          </Flex>
                        )}
                      />
                    </CenteredTd>
                    <CenteredTd>
                      <Controller
                        control={control}
                        name={`${practice.id}4`}
                        render={({ field: { onChange, value } }) => (
                          <Flex my={1} justifyContent="center">
                            <Checkbox
                              unCheckedIconDisplay
                              variant="secondary"
                              checked={!!value}
                              onChange={(val) => {
                                onChange(
                                  val.target.checked ? 'field.id' : false
                                );
                              }}
                            />
                          </Flex>
                        )}
                      />
                    </CenteredTd>
                    <CenteredTd>
                      <Box p={1}>
                        <Button
                          disabled={
                            !(
                              watch(`${practice.id}${1}`) ||
                              watch(`${practice.id}${2}`) ||
                              watch(`${practice.id}${3}`) ||
                              watch(`${practice.id}${4}`)
                            )
                          }
                          icon={<FontAwesomeIcon icon={faPenToSquare} />}
                          onClick={() => {
                            setSelectedPractice(practice);
                          }}
                        />
                      </Box>
                    </CenteredTd>
                    <CenteredTd>
                      <Box pt={3}>
                        <Select options={[]} />
                      </Box>
                    </CenteredTd>
                  </StyledTr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <ToRightTd>{t('administration.selectAll')}: </ToRightTd>
                  <td>
                    <Flex p={1} justifyContent="center">
                      <Button
                        variant="eucalyptus"
                        icon={<FontAwesomeIcon size="lg" icon={faCirclePlus} />}
                        onClick={() => {
                          handleCheckAllByIndex(1);
                        }}
                      />
                    </Flex>
                  </td>
                  <td>
                    <Flex p={1} justifyContent="center">
                      <Button
                        variant="eucalyptus"
                        icon={<FontAwesomeIcon size="lg" icon={faCirclePlus} />}
                        onClick={() => {
                          handleCheckAllByIndex(2);
                        }}
                      />
                    </Flex>
                  </td>
                  <td>
                    <Flex p={1} justifyContent="center">
                      <Button
                        variant="eucalyptus"
                        icon={<FontAwesomeIcon size="lg" icon={faCirclePlus} />}
                        onClick={() => {
                          handleCheckAllByIndex(3);
                        }}
                      />
                    </Flex>
                  </td>
                  <td>
                    <Flex p={1} justifyContent="center">
                      <Button
                        variant="eucalyptus"
                        icon={<FontAwesomeIcon size="lg" icon={faCirclePlus} />}
                        onClick={() => {
                          handleCheckAllByIndex(4);
                        }}
                      />
                    </Flex>
                  </td>
                  <ToRightTd>{t('administration.toEveryone')}: </ToRightTd>
                  <td>
                    <Box pt={3} pl={1}>
                      <Select options={[]} />
                    </Box>
                  </td>
                </tr>
              </tfoot>
            </StyledTable>
          </OverflowFlex>

          <Flex flexDirection="column" py={4}>
            <H2 variant="h2" color="coloured">
              {t('administration.materialsLanguages')}
            </H2>
            <Line />

            <Select label="administration.materialsLanguages" options={[]} />
          </Flex>

          <H2 variant="h2" color="coloured">
            {t('administration.license')}
          </H2>
          <Line />

          <TextareaField rows={15} />

          <Box width="100%" py={3}>
            <Button fullWidth variant="eucalyptus">
              {t('administration.update')}
            </Button>
          </Box>
        </>
      )}
    </Flex>
  );
};
