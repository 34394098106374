import { serialize } from 'object-to-formdata';
import { ICompanyForm, ICompanyPatchResponse } from 'types/forms/company';
import {
  ICompanyResponse,
  ICompaniesResponse,
  ICompanyContactAndSupportsResponse
} from 'types/company';
import request from './index';

export const getCompanies = () =>
  request<ICompaniesResponse>({
    options: {
      url: '/companies',
      method: 'get'
    }
  });

export const getCompany = (id: string) =>
  request<ICompanyResponse>({
    options: {
      url: `/companies/${id}`,
      method: 'get'
    }
  });

export const editCompany = (id: string, data: ICompanyForm) =>
  request<ICompanyPatchResponse>({
    options: {
      url: `/companies/${id}`,
      method: 'patch',
      data: serialize(data, { allowEmptyArrays: true })
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const getCompanyContactAndSupports = () =>
  request<ICompanyContactAndSupportsResponse>({
    options: {
      url: '/contact_and_supports',
      method: 'get'
    }
  });
