import {
  IEvaluationsClosingCloseResponse,
  IEvaluationsClosingOpenResponse
} from 'types/evaluationsClosings';
import request from './index';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const closeEvaluationClosing = (id: number | string) => {
  try {
    return request<IEvaluationsClosingCloseResponse>({
      options: {
        url: `/evaluations_closings/${id}/close`,
        method: 'patch'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const openEvaluationClosing = (id: number | string) => {
  try {
    return request<IEvaluationsClosingOpenResponse>({
      options: {
        url: `/evaluations_closings/${id}/open`,
        method: 'patch'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
