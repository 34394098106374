import { Flex } from 'components';
import styled from 'styled-components';

export const ThingToImproveRow = styled.div`
  width: 100%;
  padding: 10px 15px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
`;

export const CommentWrapper = styled(Flex)`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
