import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H1 } from 'components/Typography/Typography';
import { t } from 'i18next';
import { Wrapper } from './AdministrationAuditsView.styled';
import { AuditFormsList } from './components/AuditFormsList/AuditFormsList';
import { RatingScalesList } from './components/RatingScalesList/RatingScalesList';

export const AdministrationAuditsView = () => {
  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} |{' '}
          {t('administrationAuditsView.AUDITS')}
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px" flexWrap="wrap">
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList>
            <Flex flexWrap="wrap">
              <CustomTab>{t('administrationAuditsView.forms')}</CustomTab>
              <CustomTab>
                {t('administrationAuditsView.ratingScales')}
              </CustomTab>
            </Flex>
          </CustomTabList>
          <CustomTabPanel>
            <AuditFormsList />
          </CustomTabPanel>
          <CustomTabPanel>
            <RatingScalesList />
          </CustomTabPanel>
        </CustomTabs>
      </Flex>
    </Wrapper>
  );
};
