import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Flex } from 'components';
import { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { RadarGraphDataset } from 'types/evaluations';
import { Topic } from 'types/methologies';

interface Props {
  axes: Topic[];
  datasets: RadarGraphDataset[];
  graphStages: number[];
}

export const EvaluationRadar: React.FC<Props> = ({
  axes,
  datasets,
  graphStages
}) => {
  const [graphAxesNames, setGraphAxesNames] = useState<string[]>([]);

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  useEffect(() => {
    const names = axes.map((axis) => axis.name);
    setGraphAxesNames(names);
  }, [axes]);

  const data = {
    labels: graphAxesNames,
    datasets: datasets
  };

  return datasets.length ? (
    <Flex height={'450px'} width={'450px'} flexDirection="row">
      <Radar
        data={data}
        options={{
          scales: {
            r: {
              suggestedMin: graphStages[0],
              suggestedMax: graphStages[graphStages.length - 1],
              beginAtZero: true,
              ticks: {
                stepSize: 1
              }
            }
          }
        }}
      />
    </Flex>
  ) : (
    <></>
  );
};
