import styled from 'styled-components';
import { IRatingScaleButton } from 'types/ratingScales';

export const StyledButton = styled.button<{
  color1?: string;
  color2?: string;
  bordered?: boolean;
  borderRadius?: boolean;
  minHeight?: string;
  maxHeight?: string;
}>`
  width: 50px;
  ${({ minHeight }) => `min-height: ${minHeight ? minHeight + 'px' : '40px'};`}
  ${({ maxHeight }) => `max-height: ${maxHeight ? maxHeight + 'px' : '40px'};`}
  ${({ borderRadius }) => (borderRadius ? 'border-radius: 5px;' : '')}
  ${({ color1, bordered, theme }) => `
    border: 2px solid ${bordered ? color1 : 'transparent'};
    color: ${color1};
    ${
      bordered
        ? `font-size: ${theme.textStyles.h2.fontSize}px; font-weight: 900;`
        : `font-size: ${theme.textStyles.h3.fontSize}px; font-weight: 700;`
    }
  `}
  ${({ color2 }) => `background-color: ${color2};`}
`;

const StyledImg = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
`;

interface Props {
  button: IRatingScaleButton;
  bordered?: boolean;
  onClick?: () => void;
  clearSrc?: boolean;
  showImages?: boolean;
  useValues?: boolean;
  borderRadius?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

export const RatingScaleButton: React.FC<Props> = ({
  button,
  bordered,
  onClick,
  showImages,
  useValues,
  clearSrc,
  borderRadius,
  minHeight,
  maxHeight
}) => {
  return (
    <StyledButton
      type="button"
      color1={button.color1}
      color2={button.color2}
      onClick={onClick}
      bordered={bordered}
      borderRadius={borderRadius}
      minHeight={minHeight}
      maxHeight={maxHeight}
    >
      {useValues ? (
        button.value
      ) : showImages ? (
        <StyledImg
          alt={button.text_value}
          src={`${clearSrc ? '' : process.env.REACT_APP_API_URL}${
            button.image_url
          }`}
        />
      ) : (
        button.text_value
      )}
    </StyledButton>
  );
};
