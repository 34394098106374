import { Flex, Line, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { Select, SwitchButton, TextField } from 'components/_form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IAddEditModal extends IGenericModal {
  isEditing?: boolean;
}

const RowLabel = styled(P)`
  width: 25%;
`;

export const AddEditModal = ({
  isOpen,
  onCancelClick,
  isEditing
}: IAddEditModal) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={
        isEditing
          ? t('employeesAndUsersView.editRole')
          : t('employeesAndUsersView.add')
      }
      mainButton={{ label: t('buttons.save'), action: () => {} }}
      isSecondButtonVisible
      isThirdButtonVisible={isEditing}
      thirdButton={{ label: t('buttons.delete'), action: () => {} }}
    >
      <Flex alignItems="center">
        <RowLabel variant="h4">polski</RowLabel>
        <TextField />
      </Flex>
      <Flex alignItems="center">
        <RowLabel variant="h4">angielski (en_GB)</RowLabel>
        <TextField />
      </Flex>
      <Flex alignItems="center">
        <RowLabel variant="h4">ukraiński</RowLabel>
        <TextField />
      </Flex>
      <Line />

      <Flex alignItems="center">
        <Flex flexDirection="column" width="25%">
          <RowLabel variant="h4">
            {t('employeesAndUsersView.tableHeaders.active')}?
          </RowLabel>
          <SwitchButton />
        </Flex>
        <Select
          options={[]}
          label={t('employeesAndUsersView.form.usingInAreas')}
          helperText={t('employeesAndUsersView.form.usingInAreasHelperText')}
        ></Select>
      </Flex>
    </Modal>
  );
};
