import { faEdit, faGear, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dropdown, Flex, Table } from 'components';
import { Button, Select, TextField } from 'components/_form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AddEditModal } from './AddEditModal';

const Icon = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  ::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ isActive }) => (isActive ? 'green' : 'grey')};
  }
`;

interface IRolesAndFunctionsTable {
  highlightCells: boolean;
}

const RolesAndFunctionsTable = ({
  highlightCells
}: IRolesAndFunctionsTable) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleClick = (id: string) => {
    setSelectedId(id);
  };

  const button = (
    <Box mx="auto" width="fit-content">
      <Button
        variant="eucalyptus"
        onClick={() => {
          handleClick('123-123-123');
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />
    </Box>
  );
  const header: string[] = [
    t('employeesAndUsersView.tableHeaders.active'),
    'Język główny: polski',
    'angielski (en_GB)',
    'ukraiński',
    t('employeesAndUsersView.tableHeaders.area'),
    ''
  ];
  const data = [
    {
      active: <Icon isActive />,
      mainLanguage: 'Lider',
      english: 'Leader',
      ukrainian: '',
      obszar: 'ALOHA Group',
      edit: button
    },
    {
      active: <Icon isActive />,
      mainLanguage: 'Operator',
      english: 'Operator',
      ukrainian: '',
      obszar: 'ALOHA Group',
      edit: button
    },
    {
      active: <Icon isActive />,
      mainLanguage: 'Kierownik działu',
      english: 'Department Manager',
      ukrainian: '',
      obszar: 'ALOHA Group',
      edit: button
    }
  ];

  const additionalHeader = (
    <>
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        hideError
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
      />
      <Dropdown items={[]}>
        <FontAwesomeIcon icon={faGear} />
      </Dropdown>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseArea') ?? undefined}
        options={[]}
        withoutMargin
      />
    </>
  );

  return (
    <>
      <AddEditModal
        isOpen={!!selectedId}
        onCancelClick={() => setSelectedId(null)}
        isEditing
      />
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        highlightCells={highlightCells}
        leftFixedKeys={[0, 1]}
        rightFixedKeys={[header.length - 1, header.length - 2]}
        additionalHeaderElement={additionalHeader}
      />
    </>
  );
};

export default RolesAndFunctionsTable;
