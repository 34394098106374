import { faGripLines, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { Button } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { TabPanel } from 'react-tabs';
import { PracticeBasedAssessments } from '../components/PracticeBasedAssessments/PracticeBasedAssessments';
import { EvaluationDetails } from '../components/EvaluationDetails/EvaluationDetails';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ContentChildrenWrapper,
  ResizerWrapper,
  Wrapper
} from './Content.styled';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { CreateEvaluationModal } from '../components/EvaluationActionsModal/CreateEvaluationModal';
import { Audits } from '../components/Audits';
import { CreateAuditModal } from '../components/Audits/AuditFormModal/CreateAuditModal';
import { AuditsDetails } from '../components/Audits/AuditsDetails/AuditsDetails';
import { EvaluationModal } from '../components/EvaluationModal/EvaluationModal';

export const Content: React.FC = () => {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [mainContentWidth, setMainContentWidth] = useState(400);
  const [isFormEvaluationModalOpen, setIsFormEvaluationModalOpen] =
    useState(false);

  const {
    filters: { area_ids },
    tabIndex,
    setTabIndex,
    isCreateAuditModalOpen,
    setIsCreateAuditModalOpen,
    selectedBlock,
    setSelectedBlock,
    selectedEvaluations,
    setSelectedEvaluations
  } = useEvaluationContext();
  const { t } = useTranslation();

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: unknown) => {
      if (isResizing) {
        const val =
          // @ts-ignore
          mouseMoveEvent?.clientY -
          // @ts-ignore
          mainContentRef?.current?.getBoundingClientRect().top;

        val > 300 && val < 600 && setMainContentWidth(val);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <Wrapper>
        <ContentChildrenWrapper ref={mainContentRef}>
          <CustomTabs
            selectedTabClassName="is-selected"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <CustomTabList style={{ borderBottom: 'none' }}>
              <Flex flexWrap="wrap">
                <CustomTab>
                  {t('evaluationsView.practiceBasedAssessments')}
                </CustomTab>
                <CustomTab>{t('evaluationsView.ratingsByDate')}</CustomTab>
                <CustomTab>{t('evaluationsView.audits')}</CustomTab>

                <Flex ml="auto" my="auto">
                  <Button
                    variant="eucalyptus"
                    onClick={() =>
                      tabIndex === 2
                        ? setIsCreateAuditModalOpen(true)
                        : setIsFormEvaluationModalOpen(true)
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.add')}
                  </Button>
                </Flex>
              </Flex>
            </CustomTabList>

            <TabPanel>
              <PracticeBasedAssessments />
            </TabPanel>

            <TabPanel>---</TabPanel>

            <TabPanel>
              <Audits />
            </TabPanel>
          </CustomTabs>

          <ResizerWrapper onMouseDown={startResizing as any}>
            <FontAwesomeIcon icon={faGripLines} />
          </ResizerWrapper>
        </ContentChildrenWrapper>

        {tabIndex === 0 && (
          <ContentChildrenWrapper>
            <EvaluationDetails />
          </ContentChildrenWrapper>
        )}

        {tabIndex === 2 && (
          <ContentChildrenWrapper>
            <AuditsDetails />
          </ContentChildrenWrapper>
        )}
      </Wrapper>
      <CreateEvaluationModal
        isOpen={isFormEvaluationModalOpen}
        onCancelClick={() => setIsFormEvaluationModalOpen(false)}
      />

      <CreateAuditModal
        isOpen={isCreateAuditModalOpen}
        onCancelClick={() => setIsCreateAuditModalOpen(false)}
      />

      {!!selectedBlock && (
        <EvaluationModal
          isOpen={!!selectedBlock}
          onCancelClick={() => {
            setSelectedBlock(undefined);
          }}
          usedEvalations={selectedEvaluations}
          setUsedEvalations={setSelectedEvaluations}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
        />
      )}
    </>
  );
};
