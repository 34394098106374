import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './IdCodeNameFilter.styled';
import { TextField } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { debounce } from 'lodash';

export const IdCodeNameFilter: React.FC = () => {
  const { t } = useTranslation();
  const {
    filters: { idCodeName },
    setFilters
  } = useTaskSidebarContext();

  const handleOnChange = debounce((e) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        idCodeName: {
          label:
            e.target.value.length > 0
              ? t('tasks.idCodeName') + ' - ' + e.target.value
              : '',
          value: e.target.value
        }
      };
    });
  }, 500);

  return (
    <Wrapper>
      <TextField
        onChange={(e) => {
          handleOnChange(e);
        }}
      />
    </Wrapper>
  );
};
