import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { format } from 'date-fns';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';

interface Props {
  audit?: PartialAudit;
  radarChartData: { [key: string]: number };
}

export const RadarChart: React.FC<Props> = ({ audit, radarChartData }) => {
  const { t } = useTranslation();

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  const data = {
    labels: Object.keys(radarChartData),
    datasets: [
      {
        label: '',
        data: Object.values(radarChartData),
        backgroundColor: 'transparent',
        borderColor: 'black',
        borderWidth: 1
      }
    ]
  };

  return (
    <Flex width="100%" flexDirection="column" alignItems="center">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <P variant="h3" ml={4}>
          {audit?.first_header_name}
        </P>
        <Flex flexDirection="column">
          {audit?.second_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.area')}</P>
              <P variant="h4" color="grey">
                {audit?.second_header_name}
              </P>
            </Flex>
          )}
          {audit?.third_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.team')}</P>
              <P variant="h4" color="grey">
                {audit?.third_header_name}
              </P>
            </Flex>
          )}
          {audit?.fourth_header_name && (
            <Flex gap="4px" justifyContent="space-between" alignItems="center">
              <P variant="body">{t('auditsView.shift')}</P>
              <P variant="h4" color="grey">
                {audit?.fourth_header_name}
              </P>
            </Flex>
          )}

          <Flex justifyContent="end" alignItems="center">
            <P variant="h4">{format(new Date(), dateFormat)}</P>
          </Flex>
        </Flex>
      </Flex>
      <Radar
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            r: {
              suggestedMax: 3
            }
          }
        }}
      />
    </Flex>
  );
};
