import { FC, useCallback, useEffect, useState } from 'react';
import { Flex } from 'components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IPage } from 'types/pages';
import { getPage } from 'api/pages';
import { device } from 'styles/theme';

const Wrapper = styled(Flex)`
  max-width: 700px;
  width: 700px;
  min-width: 230px;
  min-height: 90vh;
  margin: 20px auto;

  @media ${device.tablet} {
    width: 90%;
  }
`;

export const InfoView: FC = () => {
  const [page, setPage] = useState<IPage>();

  const { slug } = useParams();

  if (!slug) {
    return <></>;
  }

  const handleGetPage = useCallback(async () => {
    const pagesResponse = await getPage(slug);

    if ('error' in pagesResponse) {
      return;
    }

    setPage(pagesResponse.data);
  }, [slug]);

  useEffect(() => {
    handleGetPage();
  }, [handleGetPage]);

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      dangerouslySetInnerHTML={{ __html: page?.content || '' }}
    ></Wrapper>
  );
};
