import { serialize } from 'object-to-formdata';
import { ITeamPatchResponse, ITeamPayload } from 'types/forms/team';
import { ITeamResponse, ITeamsResponse } from 'types/team';
import request from './index';

export const getTeams = () => {
  return request<ITeamsResponse>({
    options: {
      url: `/teams`,
      method: 'get'
    }
  });
};

export const getTeam = (id: string) =>
  request<ITeamResponse>({
    options: {
      url: `/teams/${id}`,
      method: 'get'
    }
  });

export const createTeam = (data: ITeamPayload) =>
  request<ITeamPatchResponse>({
    options: {
      url: `/teams`,
      method: 'post',
      data: serialize(data)
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const editTeam = (id: string, data: ITeamPayload) =>
  request<ITeamPatchResponse>({
    options: {
      url: `/teams/${id}`,
      method: 'patch',
      data: serialize(data, {
        allowEmptyArrays: true
      })
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const deleteTeam = (id: string) =>
  request({
    options: {
      url: `/teams/${id}`,
      method: 'delete'
    }
  });
