export const usersRoles = ['admin', 'user', 'guest', 'consultant'];

export const eyesEmoji = 128064;
export const nibEmoji = 10002;
export const diskEmoji = 128190;
export const handEmoji = 9997;
export const crossEmoji = 10060;

export const functionalUserRights = [
  {
    name: 'administration.creating assessments, audits',
    guest: eyesEmoji,
    user: nibEmoji,
    admin: eyesEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.adding data (comments, photos) to existing ratings, audits',
    guest: nibEmoji,
    user: nibEmoji,
    admin: eyesEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.diagrams',
    guest: eyesEmoji,
    user: nibEmoji,
    admin: eyesEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.plans, reports',
    guest: eyesEmoji,
    user: nibEmoji,
    admin: eyesEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.notifications',
    guest: nibEmoji,
    user: nibEmoji,
    admin: nibEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.access to documents, templates, examples',
    guest: eyesEmoji,
    user: diskEmoji,
    admin: eyesEmoji,
    consultant: diskEmoji
  },
  {
    name: 'administration.adding your own documents, templates, examples',
    guest: crossEmoji,
    user: nibEmoji,
    admin: crossEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.changes in the structure of the company',
    guest: crossEmoji,
    user: nibEmoji,
    admin: nibEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.access to the administration panel',
    guest: crossEmoji,
    user: handEmoji,
    admin: nibEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.adding, blocking, unblocking users',
    guest: crossEmoji,
    user: crossEmoji,
    admin: nibEmoji,
    consultant: nibEmoji
  },
  {
    name: 'administration.making changes to the settings',
    guest: crossEmoji,
    user: crossEmoji,
    admin: nibEmoji,
    consultant: nibEmoji
  }
];
