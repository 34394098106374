import { IOption } from './NestedSelect';

export const flattenOptions = (
  rawOptions?: IOption[],
  removeOptions: boolean = true
): IOption[] => {
  if (rawOptions) {
    let options: IOption[] = [];
    const flattenMembers = rawOptions.map((m) => {
      if (m.options && m.options.length) {
        options = [...options, ...m.options];
      }
      return m;
    });

    return flattenMembers
      .concat(options.length ? flattenOptions(options, removeOptions) : options)
      .filter((item) => (removeOptions ? !item.options : true));
  } else return [];
};
