import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio, TextField } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './SearchBar.styled';
import { P } from 'components/Typography/Typography';
import { Flex } from 'components';

interface Props {
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
}

export const SearchBar: React.FC<Props> = ({
  searchPhrase,
  setSearchPhrase
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper flexDirection={['column', 'row']} alignItems={['start', 'center']}>
      <Flex alignItems="center" gap="10px" width="100%">
        <FontAwesomeIcon icon={faSearch} size="lg" />
        <TextField
          placeholder={t('employeesAndUsersView.form.search') ?? undefined}
          hideError
          value={searchPhrase}
          onChange={(e) => {
            setSearchPhrase(e.target.value);
            e.preventDefault();
          }}
        />
      </Flex>
      <Flex minWidth="70px">
        <P variant="body" fontWeight={700}>
          {t('documentsView.searchIn')}
        </P>
      </Flex>

      <Flex minWidth="150px">
        <Radio label="documentsView.selectedBlock" />
      </Flex>
      <Flex minWidth="150px">
        <Radio label="documentsView.inAll" />
      </Flex>

      <P variant="body" fontWeight={700}>
        {t('documentsView.results')}
      </P>
    </Wrapper>
  );
};
