import { Flex, Modal } from 'components';
import { P } from 'components/Typography/Typography';
import {
  IOption,
  NestedSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { t } from 'i18next';
import { Dispatch, useEffect, useState } from 'react';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const AddAreaModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [areasOptions, setAreasOptions] = useState<IOption[]>([]);

  const { areas } = useEvaluationContext();

  const loadOptions = async () => {
    setAreasOptions(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areas)
      )
    );
  };

  useEffect(() => {
    loadOptions();
  }, [areas.length]);

  return (
    <Modal
      isOpen={isModalOpen}
      onCancelClick={() => {
        setIsModalOpen(false);
      }}
      headerTitle={t('evaluationsView.addAnArea')}
      mainButton={{ label: t('administration.add'), action: () => {} }}
      isSecondButtonVisible
      thirdButton={{ label: t('buttons.delete'), action: () => {} }}
    >
      <Flex minHeight="300px" flexDirection="column">
        <P variant="body" my={3} fontWeight={700}>
          {t('evaluationsView.chooseAreaWhichWantToAdd')}
        </P>

        <NestedSelect
          label={t('buttons.choose') || ''}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          options={areasOptions}
        />
      </Flex>
    </Modal>
  );
};
