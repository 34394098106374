import { Flex } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  position: absolute;
  left: 70px;
  top: 0;
  width: 90vw;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  z-index: 10;
`;
