import {
  faGaugeHigh,
  faPersonWalking,
  faList,
  faChartBar,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as DocumentsLogo } from 'assets/icons/documentsLogo.svg';

export const navItems = [
  {
    label: 'nav.my-desktop',
    path: '/app/desktop',
    private: true,
    icon: <FontAwesomeIcon icon={faGaugeHigh} size="lg" />
  },
  {
    label: 'nav.tasks',
    path: '/app/tasks',
    private: true,
    icon: <FontAwesomeIcon icon={faPersonWalking} size="lg" />
  },
  {
    label: 'nav.evaluation',
    path: '/app/evaluations',
    private: true,
    icon: <FontAwesomeIcon icon={faList} size="lg" />
  },
  {
    label: 'nav.analytics',
    path: '/app/analitics',
    private: true,
    icon: <FontAwesomeIcon icon={faChartBar} size="lg" />
  },
  {
    label: 'nav.documents',
    path: '/app/documents',
    private: true,
    icon: <DocumentsLogo width="30px" height="17.5px" />
  },
  {
    label: 'nav.administration',
    path: '/app/administration',
    private: true,
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />
  }
];
