import { IGenericModal, Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { RatingScaleForm } from './RatingScaleForm';
import { postRatingScale } from 'api/ratingScales';
import { IPostRatingScale } from 'types/ratingScales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Flex } from 'components';
import { SwitchButton } from 'components/_form';

interface Props extends IGenericModal {
  onSubmitSuccess: () => void;
}

export const AddRatingScaleForm = ({
  isOpen,
  onCancelClick,
  onSubmitSuccess
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<IPostRatingScale['rating_scale']>({
    defaultValues: {
      button_type: 'text',
      active: true,
      border: true,
      use_colored_values: true,
      buttons_attributes: [
        {
          text_value: '1',
          value: 1,
          color1: 'grey',
          color2: 'white'
        },
        {
          text_value: '1',
          value: 1,
          color1: 'grey',
          color2: 'white'
        }
      ]
    }
  });

  const { handleSubmit, reset, control } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const ratingScaleRes = await postRatingScale({ rating_scale: data });

    if ('error' in ratingScaleRes) {
      toast.error(ratingScaleRes.error.message);
    }

    if (ratingScaleRes.data) {
      onSubmitSuccess();
      reset();
    }
  });

  const handleCancelClick = () => {
    reset();
    onCancelClick();
  };

  return (
    <Modal
      gridTemplateColumns="50%"
      isOpen={isOpen}
      mainButton={{
        action: onSubmit,
        variant: 'eucalyptus',
        label: t('buttons.save')
      }}
      secondButton={{
        action: handleCancelClick,
        label: t('common.cancel')
      }}
      isSecondButtonVisible
      headerTitle={t('administrationAuditsView.addRatingScale')}
      onCancelClick={handleCancelClick}
      additionalFooter={
        <Flex justifyContent="end" minWidth="250px">
          <Controller
            control={control}
            name="active"
            render={({ field: { onChange, value } }) => (
              <SwitchButton
                label={t('administrationAuditsView.active?')}
                value={!!value}
                onChange={(checked) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </Flex>
      }
    >
      <FormProvider {...methods}>
        <RatingScaleForm />
      </FormProvider>
    </Modal>
  );
};
