import { Flex } from 'components/Flex';
import styled from 'styled-components';

const RedFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.accent.red};
`;

const GreenFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.accent.green};
`;

const BlueFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.accent.blue};
`;

export const RGBLine = () => {
  return (
    <Flex>
      <RedFlex width="100%" height="10px"></RedFlex>
      <GreenFlex width="100%" height="10px"></GreenFlex>
      <BlueFlex width="100%" height="10px"></BlueFlex>
    </Flex>
  );
};
