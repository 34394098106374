import { BlockScore } from 'types/evaluations';
import { Flex } from 'components/Flex';
import styled from 'styled-components';
import { theme } from 'styles';
import { IBlock } from 'types/methologies';
import { P } from 'components/Typography/Typography';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { useCallback } from 'react';

const Wrapper = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme.palette.neutral.dark};
`;

const StackableScore = styled(Flex)<{
  bgColor: string;
  value: string;
}>`
  bottom: 0;
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ value }) => value};
  width: 18px;
`;

const Score = styled(Flex)<{ bgColor: string; value: string }>`
  position: absolute;
  bottom: 0;
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ value }) => value};
  width: 18px;
`;

const ExternalScore = styled(Flex)<{ value: string }>`
  position: absolute;
  bottom: 0;
  border-top: 2px solid ${({ theme }) => theme.palette.accent.red};
  height: ${({ value }) => value};
  width: 18px;
`;

const PercentageWrapper = styled(Flex)`
  color: ${({ theme }) => theme.palette.neutral.white};
  font-weight: bold;
  width: 10px;
  z-index: 1;
`;

const Percentage = styled(P)`
  font-weight: bold;
  font-size: 9px;
`;

const ExtraordinaryBlockPercentageWrapper = styled(Flex)`
  position: absolute;
  color: ${({ theme }) => theme.palette.neutral.white};
  font-weight: bold;
`;

const columnColors = [
  theme.palette.accent.brown,
  theme.palette.accent.red,
  theme.palette.accent.blue,
  theme.palette.accent.eucalyptus
];

interface Props {
  scores?: BlockScore[];
  block?: IBlock;
  isOneColumn?: boolean;
}

export const EvaluationStats: React.FC<Props> = ({
  scores,
  block,
  isOneColumn
}) => {
  const { selectedEvaluation, selectedEvaluations } = useEvaluationContext();

  const makePercentage = useCallback((): string => {
    let percentage = '';
    selectedEvaluations.map((evaluation) => {
      if (selectedEvaluation) {
        const { number: evaluationNumber } = selectedEvaluation;

        if (evaluation.number === evaluationNumber) {
          evaluation.practice.blocks.map((mapBlock) => {
            if (block && block.id === mapBlock.id) {
              percentage = mapBlock.avg_score?.toString() || '0' + '%';
              return;
            }
          });
        }
      }
    });
    return percentage;
  }, [block, selectedEvaluation, selectedEvaluations]);

  return (
    <Wrapper
      // mr={scores && scores.length > 1 ? '' : '4px'}
      width="18px"
      height="100%"
      alignItems="end"
      mr={!isOneColumn ? '0' : '4px'}
    >
      {(!isOneColumn && scores) || (scores && scores.length > 1)
        ? scores.map((score, i) => (
            <>
              {scores[0].current && (
                <ExtraordinaryBlockPercentageWrapper>
                  <Percentage variant="body">{makePercentage()}</Percentage>
                </ExtraordinaryBlockPercentageWrapper>
              )}
              <StackableScore
                bgColor={columnColors[i]}
                key={block?.id + 'evaluation_stats' + i}
                value={score.current ? score.current : ''}
              />
            </>
          ))
        : scores && (
            <>
              {scores && scores[0].current && (
                <PercentageWrapper>
                  <Percentage variant="body">
                    {Math.floor(parseInt(scores[0].current.slice(0, -1)) || 0) +
                      '%'}
                  </Percentage>
                </PercentageWrapper>
              )}
              {scores[0].goal && (
                <Score
                  bgColor={theme.palette.accent.lightBlue}
                  value={scores[0].goal}
                />
              )}
              {scores[0].current && (
                <Score
                  bgColor={theme.palette.accent.eucalyptus}
                  value={scores[0].current}
                />
              )}
              {scores[0].external && (
                <ExternalScore value={scores[0].external} />
              )}
            </>
          )}
    </Wrapper>
  );
};
