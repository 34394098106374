import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUser, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';

interface Props {
  data: {
    title: string;
    name: string;
    author: string;
    date: string;
    person: string;
  };
}

export const DayTooltip = ({ data }: Props) => {
  return (
    <Flex flexDirection="column">
      <P variant="body">{data.title}</P>
      <P variant="body">{data.name}</P>
      <P variant="body">
        <FontAwesomeIcon icon={faUserPen} /> {data.author}
      </P>
      <P variant="body">
        <FontAwesomeIcon icon={faClock} /> {data.date}
      </P>
      <P variant="body">
        <FontAwesomeIcon icon={faUser} /> {data.person}
      </P>
    </Flex>
  );
};
