import { getAreas } from 'api/areas';
import { getUsers } from 'api/user';
import { Flex, ImageInput } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { Select, TextField } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ITeamForm } from 'types/forms/team';
import { ITeam } from 'types/team';
import { getFile } from 'utilities/files';

const Row = styled(Flex)`
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const ImageBox = styled(Flex)`
  width: 110px;
  height: 110px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;

  ${({ theme }) =>
    `color: ${theme.palette.text.secondary};
  border: 1px dashed ${theme.palette.neutral.medium};`};
`;

interface Props extends IGenericModal {
  onSubmit: (data: ITeamForm['team']) => Promise<void>;
  selectedItemData?: ITeam;
}

export const TeamAddModal = ({
  isOpen,
  onCancelClick,
  onSubmit,
  selectedItemData
}: Props) => {
  const [users, setUsers] = useState<IOption[]>([]);
  const [areas, setAreas] = useState<IOption[]>([]);
  const { t } = useTranslation();

  const { register, handleSubmit, reset, setValue, watch, control } = useForm();

  const loadData = async () => {
    if (selectedItemData) {
      const {
        id,
        state,
        image_urls,
        logo_url,
        leader,
        manager,
        areas,
        members,
        ...restData
      } = selectedItemData;

      reset({
        ...restData,
        images: image_urls.length
          ? (await Promise.all(
              image_urls.map((image_url) => getFile(image_url))
            ).then((values) => {
              return values;
            })) || []
          : [],
        logo: logo_url ? await getFile(logo_url) : undefined,
        leader: { value: leader.id, label: leader.username },
        manager: { value: manager.id, label: manager.username },
        areas: areas.map((area) => ({ value: area.id, label: area.name })),
        members: members.map((member) => ({
          value: member.id,
          label: member.username
        }))
      });
    }
  };

  const loadOptions = async () => {
    const { data: usersData } = await getUsers();
    const usersOptions = usersData.map((user) => ({
      value: String(user.id),
      label: user.username
    }));

    setUsers(usersOptions);

    const { data: areasData } = await getAreas();
    const areasOptions = areasData.map((area) => ({
      value: String(area.id),
      label: area.name
    }));

    setAreas(areasOptions);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedItemData]);

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={() => {
        reset();
        onCancelClick();
      }}
      mainButton={{
        label: t('buttons.add'),
        action: () => {
          handleSubmit(onSubmit)();
          reset();
        }
      }}
      headerTitle={t('employeesAndUsersView.addTeam')}
      isSecondButtonVisible
    >
      <form>
        <Flex flexDirection="column">
          <Row flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
            <TextField
              {...register('id_code')}
              label={t('employeesAndUsersView.form.id')}
            />
            <TextField
              {...register('name')}
              label={t('employeesAndUsersView.form.teamName')}
            />
            <ImageInput
              defaultImage={
                selectedItemData?.image_urls?.length
                  ? `${process.env.REACT_APP_API_URL}${selectedItemData?.image_urls[0]}`
                  : ''
              }
              setValue={(file: Blob | undefined) => {
                setValue('images', file ? [file] : []);
              }}
              type="photo"
              placeholder={t('employeesAndUsersView.form.photoPlaceholder')}
            />
          </Row>
          <Row flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
            <Flex flexDirection="column" width="100%">
              <Row flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
                <Controller
                  control={control}
                  name="leader"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('employeesAndUsersView.form.teamLeader')}
                      selectedOptions={value}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={users}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="manager"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('employeesAndUsersView.form.teamManager')}
                      selectedOptions={value}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={users}
                    />
                  )}
                />
              </Row>
              <Row flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
                <Controller
                  control={control}
                  name="areas"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isMulti
                      label={t('employeesAndUsersView.form.areas')}
                      selectedOptions={value}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={areas}
                    />
                  )}
                />
              </Row>
            </Flex>
            <Flex width="50%">
              <ImageInput
                defaultImage={
                  selectedItemData?.logo_url &&
                  `${process.env.REACT_APP_API_URL}${selectedItemData?.logo_url}`
                }
                setValue={(file: Blob | undefined) => {
                  setValue('logo', file);
                }}
                type="logo"
                placeholder={t('employeesAndUsersView.form.photoPlaceholder')}
              />
            </Flex>
          </Row>
        </Flex>
      </form>
    </Modal>
  );
};
