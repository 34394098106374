import { Flex, Table } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeopleGroup,
  faEdit,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { Button, Select, TextField } from 'components/_form';
import { SendMessageModal } from './SendMessageModal';
import { useTranslation } from 'react-i18next';
import { TablePagination } from 'components/Table/TablePagination';
import { ITeam } from 'types/team';
import { useNavigate } from 'react-router-dom';

const Icon = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  ::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ isActive }) => (isActive ? 'green' : 'grey')};
  }
`;

const Logo = styled(Flex)`
  justify-content: center;
  align-items: center;
  font-size: 30px;
  margin: 0 auto;
  padding: 2px;
  width: fit-content;
  ${({ theme }) => `
  background-color: ${theme.palette.primary.dark};
  color: ${theme.palette.neutral.light};
  `};
`;

const Buttons = styled(Flex)`
  width: fit-content;
  margin: 0 auto;
  gap: 10px;
`;

interface Props {
  teams: ITeam[];
  setSelectedItemData: (data: ITeam) => void;
  setOpenAddModal: (open: boolean) => void;
}

const TeamsAndGroupsTable: React.FC<Props> = ({
  teams,
  setSelectedItemData,
  setOpenAddModal
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [openSendModal, setOpenSendModal] = useState<boolean>(false);

  const logo = (
    <Logo>
      <FontAwesomeIcon icon={faPeopleGroup} />
    </Logo>
  );

  const buttons = (item: ITeam) => (
    <Buttons>
      <Button
        variant="blue"
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        onClick={() => {
          setOpenSendModal(true);
        }}
      />
      <Button
        variant="eucalyptus"
        icon={<FontAwesomeIcon icon={faEdit} />}
        onClick={() => {
          // setOpenAddModal(true);
          // setSelectedItemData(item);

          navigate(`teams/${item.id}`);
        }}
      />
    </Buttons>
  );

  const header: string[] = [
    t('employeesAndUsersView.tableHeaders.active'),
    t('employeesAndUsersView.tableHeaders.id'),
    t('employeesAndUsersView.tableHeaders.logo'),
    t('employeesAndUsersView.tableHeaders.teamName'),
    t('employeesAndUsersView.tableHeaders.leader'),
    t('employeesAndUsersView.tableHeaders.area'),
    ''
  ];

  const data = teams.map((team) => ({
    active: <Icon isActive={team.state === 'active'} />,
    id: team.id,
    logo: team.logo_url ? (
      <img
        width="50px"
        src={process.env.REACT_APP_API_URL + team.logo_url}
        alt="logo"
      />
    ) : (
      logo
    ),
    name: team.name,
    leader: team.leader.email,
    ereas: team.areas.map((area) => area.name).join(', '),
    edit: buttons(team)
  }));

  const additionalHeader = (
    <>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseArea') ?? undefined}
        options={[]}
        withoutMargin
      />
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        hideError
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
      />
      <TablePagination
        hidePageInput
        page={page}
        total={150}
        perPage={perPage}
        setPage={setPage}
      />
    </>
  );
  return (
    <>
      <SendMessageModal
        isOpen={openSendModal}
        onCancelClick={() => setOpenSendModal(false)}
      />
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        additionalHeaderElement={additionalHeader}
      />
    </>
  );
};

export default TeamsAndGroupsTable;
