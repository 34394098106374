import { IGenericModal, Modal } from 'components/Modal/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TaskTypeForm } from '../TaskTypeForm/TaskTypeForm';
import { ITaskType, ITaskTypePayload } from 'types/taskType';
import { createTaskType } from 'api/taskTypes';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskTypeSchema } from 'validation/taskTypeSchemas';

interface Props extends IGenericModal {
  onSuccess?: (newTaskType: ITaskType) => void;
  parentTaskTypeId?: number;
  taskTypes?: ITaskType[];
}

export const AddTaskTypeForm = ({
  isOpen,
  onCancelClick,
  onSuccess,
  parentTaskTypeId,
  taskTypes
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<ITaskTypePayload['task_type']>({
    mode: 'onChange',
    defaultValues: { color_code: 'red' },
    resolver: yupResolver(taskTypeSchema)
  });

  const { handleSubmit } = methods;

  const handleSubmitTaskTypeForm = async (
    data: ITaskTypePayload['task_type']
  ) => {
    const taskTypeRes = await createTaskType({
      task_type: {
        ...data,
        ...(parentTaskTypeId && { parent_id: parentTaskTypeId }),
        order:
          (taskTypes?.filter(({ parent_id }) =>
            parentTaskTypeId ? parent_id === parentTaskTypeId : !parent_id
          ).length || 0) + 1
      }
    });

    if ('error' in taskTypeRes) {
      toast.error(taskTypeRes.error.message);
      return;
    }

    onSuccess?.(taskTypeRes.data);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={() => {
        onCancelClick();
      }}
      headerTitle={t('administrationTasksView.addTaskType')}
      mainButton={{
        action: handleSubmit((data) => {
          handleSubmitTaskTypeForm(data);
        }),
        variant: 'eucalyptus',
        label: t('buttons.save')
      }}
      isSecondButtonVisible
      gridTemplateColumns="60%"
    >
      <FormProvider {...methods}>
        <form>
          <TaskTypeForm />
        </form>
      </FormProvider>
    </Modal>
  );
};
