import React, { useEffect } from 'react';
import { Footer } from 'layouts/components/Footer';
import { Box, Card, Flex } from 'components';
import {
  DescriptionContent,
  FooterContent,
  ViewContent,
  Wrapper
} from './GuestLayout.styled';
import { Description } from 'layouts/components/Description';
import { Select } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlFlag } from 'assets/icons/flag_pl.svg';
import { ReactComponent as EnFlag } from 'assets/icons/flag_en_GB.svg';
import { ReactComponent as RuFlag } from 'assets/icons/flaga_ru.svg';

export const GuestLayout: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { i18n } = useTranslation();
  const selectedLang = localStorage.getItem('lang');

  const handleLangChange = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (localStorage.getItem('lang')) return;
    handleLangChange('en');
  }, []);

  return (
    <Wrapper>
      <FooterContent>
        <Footer />
      </FooterContent>
      <DescriptionContent>
        <Description />
      </DescriptionContent>
      <ViewContent>
        <Flex p={5}>Logo klienta</Flex>
        <Card p={4}>
          <Flex width="100%" justifyContent="end">
            <Box width="80px">
              <Select
                selectedOptions={
                  selectedLang
                    ? {
                        label: selectedLang,
                        value: selectedLang
                      }
                    : undefined
                }
                options={[
                  { label: <PlFlag width="18px" />, value: 'pl' },
                  { label: <EnFlag width="18px" />, value: 'en' },
                  { label: <RuFlag width="18px" />, value: 'ru' }
                ]}
                onChange={(val) => {
                  if (!(val instanceof Array)) handleLangChange(val.value);
                }}
              />
            </Box>
          </Flex>
          {children}
        </Card>
      </ViewContent>
    </Wrapper>
  );
};
