import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';

export const MainSection = styled(Flex)`
  width: 100%;
  font-size: ${({ theme }) => `${theme.textStyles.body.fontSize}px`};
`;

export const TitleContainer = styled(P)<{ lineHeight?: string }>`
  font-weight: bold;
  font-size: inherit;
`;

export const IconButtonContainer = styled(Flex)<{
  bgColor?: string;
  border?: string;
}>`
  justify-content: space-evenly;
  cursor: pointer;
  align-items: center;
  color: white;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.palette.accent.eucalyptus};
  border: ${({ border }) => border && border};
  border-radius: 5px;
`;

export const IconColorWrapper = styled(Flex)`
  color: ${({ theme }) => theme.palette.accent.eucalyptus};
`;

export const FlexPointer = styled(Flex)`
  cursor: pointer;
`;
