import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

export interface ILink {
  to: LinkProps['to'];
  target?: string;
  children?: React.ReactNode;
}

const StyledLink = styled(RouterLink)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.grey};
    display: block;
    
    :focus-visible {
      outline: 1px solid ${theme.palette.neutral.grey};
    }
  `};
`;

const Link: React.FC<ILink> = ({ children, to, target }) => (
  <StyledLink target={target} to={to}>
    {children}
  </StyledLink>
);

export { Link };
