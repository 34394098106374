import { Flex, Line } from 'components';
import { H2, P } from 'components/Typography/Typography';
import { Button, Radio } from 'components/_form';
import { t } from 'i18next';
import styled from 'styled-components';
import {
  crossEmoji,
  diskEmoji,
  eyesEmoji,
  functionalUserRights,
  handEmoji,
  nibEmoji,
  usersRoles
} from './UsersPermissionForm.constants';

const StyledTable = styled.table`
  border-spacing: 0px;
`;

const OverflowFlex = styled(Flex)`
  overflow: auto;
  width: 100%;
`;

const CenteredTd = styled.td`
  text-align: center;
  align-items: center;
  padding: 5px;
`;

const BottomRightBorderCenteredTd = styled(CenteredTd)`
  border-bottom: 1px solid black;
  border-right: 1px solid black;
`;

const BottomBorderCenteredTd = styled(CenteredTd)`
  border-bottom: 1px solid black;
`;

const BottomBorderTd = styled.td`
  border-bottom: 1px solid black;
`;

const RightBorderTd = styled.td`
  border-right: 1px solid black;
`;

const BottomRightBorderTd = styled.td`
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 5px;
`;

export const UsersPermissionForm = () => {
  return (
    <Flex flexDirection="column">
      <H2 mt={2} variant="h2" color="coloured">
        {t('administration.rangeOfAccessToDiffrentUserAccounts')}
      </H2>
      <Line />

      <Flex p={2} flexDirection="column">
        <P variant="body" fontWeight={700}>
          {t(
            'administration.Bydefault,eachtypeofuserhasaccesstotheareatowhichheisassignedandtoitssub-areas'
          )}
        </P>
        <Flex p={3} flexDirection="column">
          <P variant="body">
            -{' '}
            {t(
              'administration.addedattheGrouplevelhasaccesstoallfactoriesinthestructure'
            )}
          </P>
          <P variant="body">
            -{' '}
            {t(
              'administration.addedatthefactorylevelhasaccesstoallareasofthefactory'
            )}
          </P>
          <P variant="body">
            -{' '}
            {t(
              'administration.addedatthedepartmentlevelhasaccesstoitsdepartmentanditssub-areas'
            )}
          </P>
        </Flex>

        <P variant="body" fontWeight={700}>
          {t(
            'administration.Usingthecheckboxesbelowyoucanadjustthisscopeofaccess'
          )}
        </P>

        <OverflowFlex mt={4}>
          <StyledTable>
            <tr>
              <RightBorderTd>&nbsp;</RightBorderTd>
              <BottomRightBorderCenteredTd colSpan={4}>
                {t('administration.lawOfView')}
              </BottomRightBorderCenteredTd>
              <BottomRightBorderCenteredTd colSpan={4}>
                {t('administration.lawOfEdit')}
              </BottomRightBorderCenteredTd>
            </tr>
            <tr>
              <BottomRightBorderCenteredTd>&nbsp;</BottomRightBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.otherBookmarksInGroup')}
              </BottomBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.holeCompanyAreasTree')}
              </BottomBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.mineAndEqualAreas')}
              </BottomBorderCenteredTd>
              <BottomRightBorderCenteredTd>
                {t('administration.mineAndLevelsBelowAreas')}
              </BottomRightBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.otherBookmarksInGroup')}
              </BottomBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.holeCompanyAreasTree')}
              </BottomBorderCenteredTd>
              <BottomBorderCenteredTd>
                {t('administration.mineAndEqualAreas')}
              </BottomBorderCenteredTd>
              <BottomRightBorderCenteredTd>
                {t('administration.mineAndLevelsBelowAreas')}
              </BottomRightBorderCenteredTd>
            </tr>

            {usersRoles.map((userRole) => (
              <tr key={userRole}>
                <BottomRightBorderTd>{t(userRole)}</BottomRightBorderTd>

                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomRightBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomRightBorderCenteredTd>
                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomBorderCenteredTd>
                <BottomRightBorderCenteredTd>
                  <Flex justifyContent="center">
                    <Radio />
                  </Flex>
                </BottomRightBorderCenteredTd>
              </tr>
            ))}

            <tr>
              <td colSpan={5}>
                <Flex justifyContent="end" p={1}>
                  <Button variant="eucalyptus">
                    {t('administration.restoreDefault')}
                  </Button>
                </Flex>
              </td>
              <td colSpan={5}>
                <Flex justifyContent="end" p={1}>
                  <Button variant="eucalyptus">
                    {t('administration.restoreDefault')}
                  </Button>
                </Flex>
              </td>
            </tr>
          </StyledTable>
        </OverflowFlex>

        <Flex ml={['0', '55%']}>
          <P variant="body" fontWeight={700}>
            {t(
              'administration.The editing right allows you to create assessments, audits, charts, plans, reports and available areas'
            )}
          </P>
        </Flex>
      </Flex>
      <H2 mt={4} variant="h2" color="coloured">
        {t('administration.FUNCTIONAL USER RIGHTS')}
      </H2>
      <Line />
      <Flex flexDirection={['column', 'row']}>
        <Flex width={['100%', '60%']}>
          <OverflowFlex mt={4}>
            <StyledTable>
              <tr>
                <BottomBorderCenteredTd></BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  {t('administration.guest')}
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  {t('administration.user')}
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  {t('administration.admin')}
                </BottomBorderCenteredTd>
                <BottomBorderCenteredTd>
                  {t('administration.consultant')}
                </BottomBorderCenteredTd>
              </tr>

              {functionalUserRights.map((functionalUserRight) => (
                <tr key={functionalUserRight.name}>
                  <BottomBorderTd>{t(functionalUserRight.name)}</BottomBorderTd>
                  <BottomBorderCenteredTd>
                    <p>{String.fromCodePoint(functionalUserRight.guest)}</p>
                  </BottomBorderCenteredTd>
                  <BottomBorderCenteredTd>
                    {String.fromCodePoint(functionalUserRight.user)}
                  </BottomBorderCenteredTd>
                  <BottomBorderCenteredTd>
                    {String.fromCodePoint(functionalUserRight.admin)}
                  </BottomBorderCenteredTd>
                  <BottomBorderCenteredTd>
                    {String.fromCodePoint(functionalUserRight.consultant)}
                  </BottomBorderCenteredTd>
                </tr>
              ))}
            </StyledTable>
          </OverflowFlex>
        </Flex>
        <Flex
          mt={4}
          width={['100%', '40%']}
          alignItems="center"
          flexDirection="column"
        >
          <P variant="body" fontWeight={700}>
            {t('administration.legend')}:
          </P>
          <Flex flexDirection="column">
            <Flex pl={[0, 6]} pt={3}>
              {String.fromCodePoint(crossEmoji)}
              <P ml={4} variant="body">
                {t('administration.lackOfAccess')}
              </P>
            </Flex>
            <Flex pl={[0, 6]} pt={3}>
              {String.fromCodePoint(eyesEmoji)}
              <P ml={4} variant="body">
                {t('administration.preview')}
              </P>
            </Flex>
            <Flex pl={[0, 6]} pt={3}>
              {String.fromCodePoint(diskEmoji)}
              <P ml={4} variant="body">
                {t('administration.downloadOption')}
              </P>
            </Flex>
            <Flex pl={[0, 6]} pt={3}>
              {String.fromCodePoint(handEmoji)}
              <P ml={4} variant="body">
                {t('administration.selectedItems')}
              </P>
            </Flex>
            <Flex pl={[0, 6]} pt={3}>
              {String.fromCodePoint(nibEmoji)}
              <P ml={4} variant="body">
                {t('administration.creating, editing')}
              </P>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
