import { Flex } from 'components';
import styled from 'styled-components';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';

export const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  padding: 10px;
  height: max-content;
  width: 100%;
`;

export const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: 10px;
`;

export const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
`;

export const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  padding: 10px;
`;
