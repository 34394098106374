import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Line } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { Button, Select, SwitchButton, TextField } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

const Row = styled(Flex)`
  align-items: center;
  gap: 0px 30px;
  margin: 10px 0 10px;
`;

const Grid4 = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 70px 28px;
  gap: 0px 10px;
  align-items: center;
`;

const Grid3 = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 1fr 28px;
  gap: 0px 10px;
  align-items: center;
`;

const Grid2 = styled(Box)`
  width: 80%;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 0px 10px;
  align-items: center;
`;

const Image = styled(Box)`
  height: 43px;
  width: 55px;
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
  ${({ theme }) =>
    `background-color: ${theme.palette.neutral.light}; border: 1px dashed ${theme.palette.neutral.grey}`};
`;

interface Props extends IGenericModal {
  data?: { name: string };
}

export const AddLanguageModal = ({ isOpen, onCancelClick, data }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={
        data
          ? t('translationsView.editLanguage')
          : t('translationsView.addLanguage')
      }
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t(data ? 'buttons.save' : 'buttons.addLanguage')
      }}
      isSecondButtonVisible
    >
      <Row flexWrap={['wrap', 'wrap', 'nowrap']}>
        <Grid3>
          <P variant="h4">{t('translationsView.form.language')}</P>
          <TextField defaultValue={data?.name} />
          <FontAwesomeIcon
            size="xl"
            icon={faQuestionCircle}
            id="languageTooltip"
            data-tooltip-content={t('translationsView.form.tooltips.language')}
          />
          <Tooltip anchorId="languageTooltip" />
        </Grid3>
        <Grid2>
          <P variant="h4">{t('translationsView.form.dateFormat')}</P>
          <TextField />
        </Grid2>
      </Row>
      <Row flexWrap={['wrap', 'wrap', 'nowrap']}>
        <Grid4>
          <P variant="h4">{t('translationsView.form.localeId')}</P>
          <TextField />
          <SwitchButton label="translationsView.form.show" />
          <FontAwesomeIcon
            size="xl"
            icon={faQuestionCircle}
            id="localeIdTooltip"
            data-tooltip-content={t('translationsView.form.tooltips.localeId')}
          />
          <Tooltip anchorId="localeIdTooltip" />
        </Grid4>
        <Grid2>
          <P variant="h4">{t('translationsView.form.separators')}</P>
          <Flex style={{ gap: '10px' }} flexWrap="nowrap">
            <TextField label={t('translationsView.form.decimal')} />
            <TextField label={t('translationsView.form.thousandth')} />
          </Flex>
        </Grid2>
      </Row>
      <Row flexWrap={['wrap', 'wrap', 'nowrap']}>
        <Grid3>
          <P variant="h4">{t('translationsView.form.country')}</P>
          <TextField />
          <FontAwesomeIcon size="xl" icon={faQuestionCircle} />
        </Grid3>
        <Grid2>
          <P variant="h4">{t('translationsView.form.firstWeekDay')}</P>
          <Select options={[]} />
        </Grid2>
      </Row>
      <Row flexWrap={['wrap', 'wrap', 'nowrap']}>
        <Grid3>
          <P variant="h4">{t('translationsView.form.languageFlag')}</P>
          <Flex justifyContent="space-between">
            <Flex style={{ gap: '5px' }}>
              <Button
                variant="eucalyptus"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                id="flagFileTooltip"
                data-tooltip-content={
                  t('translationsView.form.tooltips.flagFile') || ''
                }
              />
              <Tooltip anchorId="flagFileTooltip" />

              <Image>{t('translationsView.form.placeForFlag')}</Image>
            </Flex>
            <Flex style={{ gap: '5px' }}>
              <P variant="h4">{t('translationsView.form.preview')}:</P>
              <Select options={[]} />
            </Flex>
          </Flex>
          <FontAwesomeIcon
            size="xl"
            icon={faQuestionCircle}
            id="flagTooltip"
            data-tooltip-content={t('translationsView.form.tooltips.flag')}
          />
          <Tooltip anchorId="flagTooltip" />
        </Grid3>
        <Grid2></Grid2>
      </Row>
      <Line variant="secondary" />
      <Box width={['100%', '50%']}>
        <SwitchButton
          label={t('translationsView.form.activeLanguageInInterface') ?? ''}
        />
      </Box>
      <Line variant="secondary" />
      <Select
        label={t('translationsView.form.personsAuthorizedToEdit')}
        isMulti
        options={[
          { label: 'Jacek Kowalski', value: '1' },
          { label: 'Marek Kowalski', value: '2' },
          { label: 'Zbyszek Kowalski', value: '3' }
        ]}
      />
    </Modal>
  );
};
