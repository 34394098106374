import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
// import { Tooltip } from "components/Tooltip";
import { theme } from 'styles/theme';
import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { rgba } from 'utilities/rgba';
import { DefaultTFuncReturn } from 'i18next';

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'blueWhite'
    | 'blue'
    | 'grey'
    | 'greyWhite'
    | 'lightBlue'
    | 'green'
    | 'lightGreen'
    | 'eucalyptus'
    | 'redWhite'
    | 'orange'
    | 'red';
  disabled?: boolean;
  label?: string | DefaultTFuncReturn;
  iconPosition?: 'left' | 'right';
  icon?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
  minHeight?: string;
  width?: string;
  minWidth?: string;
  fontSize?: string;
  transparent?: boolean;
  textAlign?: 'center' | 'end' | 'start';
  opacity?: string;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI['variant']>;
  icon?: ButtonI['icon'];
  fullWidth?: ButtonI['fullWidth'];
  bordered?: ButtonI['bordered'];
  minHeight?: ButtonI['minHeight'];
  width?: ButtonI['width'];
  minWidth?: ButtonI['minWidth'];
  fontSize?: ButtonI['fontSize'];
  transparent?: ButtonI['transparent'];
  textAlign?: ButtonI['textAlign'];
  label?: ButtonI['label'];
  opacity?: ButtonI['opacity'];
}

const { palette } = theme;

const colorVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.primary.main,
  blueWhite: palette.accent.blue,
  blue: palette.neutral.white,
  grey: palette.text.primary,
  greyWhite: theme.palette.neutral.grey,
  lightBlue: palette.neutral.mainBlue,
  green: palette.neutral.white,
  lightGreen: palette.accent.green,
  eucalyptus: palette.neutral.white,
  redWhite: palette.accent.red,
  red: palette.neutral.white,
  orange: palette.neutral.white
};

const colorDisabledVariantsMap = {
  primary: rgba(palette.neutral.dark, 0.7),
  secondary: rgba(palette.neutral.dark, 0.7),
  blueWhite: palette.neutral.white,
  blue: palette.neutral.white,
  grey: palette.text.primary,
  greyWhite: palette.text.primary,
  lightBlue: palette.accent.lightBlue,
  green: rgba(palette.neutral.dark, 0.7),
  lightGreen: rgba(palette.neutral.dark, 0.7),
  eucalyptus: rgba(palette.neutral.dark, 0.7),
  redWhite: palette.neutral.lightGrey,
  red: rgba(palette.neutral.dark, 0.7),
  orange: rgba(palette.neutral.dark, 0.7)
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.neutral.white,
  blueWhite: palette.neutral.white,
  blue: palette.neutral.mainBlue,
  grey: palette.neutral.light,
  greyWhite: palette.neutral.white,
  lightBlue: palette.neutral.lightBlue,
  green: palette.accent.green,
  lightGreen: palette.accent.lightGreen,
  eucalyptus: palette.accent.eucalyptus,
  redWhite: palette.neutral.white,
  red: palette.accent.red,
  orange: palette.accent.orange
};

const backgroundColorDisabledVariantsMap = {
  primary: palette.neutral.medium,
  secondary: palette.neutral.veryLight,
  blueWhite: palette.neutral.medium,
  blue: palette.neutral.medium,
  grey: palette.neutral.veryLight,
  greyWhite: palette.neutral.veryLight,
  lightBlue: palette.neutral.veryLight,
  green: palette.neutral.medium,
  lightGreen: palette.neutral.medium,
  eucalyptus: palette.neutral.medium,
  redWhite: palette.neutral.medium,
  red: palette.neutral.medium,
  orange: palette.neutral.medium
};

const borderColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.primary.main,
  blueWhite: palette.accent.blue,
  blue: palette.accent.blue,
  grey: palette.neutral.greyButton,
  greyWhite: palette.neutral.lightGrey,
  lightBlue: palette.accent.lightBlue,
  green: palette.accent.green,
  lightGreen: palette.neutral.dark,
  eucalyptus: palette.neutral.dark,
  redWhite: palette.accent.red,
  red: palette.accent.red,
  orange: palette.neutral.dark
};

const StyledButton = styled.button<StyledButtonI>`
  &&& {
    white-space: nowrap;
    position: relative;
    ${({
      variant,
      icon,
      fullWidth,
      bordered,
      minHeight,
      width,
      minWidth,
      fontSize,
      transparent,
      textAlign,
      theme,
      label,
      opacity
    }) => `
    display: ${fullWidth ? 'flex' : 'inline-flex'};
    align-items: center;
    justify-content: center;
    text-align: ${textAlign ? textAlign : 'center'};
    ${fullWidth ? `width: 100%;` : width ? `width: ${width};` : ''};
    ${minWidth ? `min-width: ${minWidth};` : ''}
    min-height: ${minHeight ? minHeight : '34px'};
    height: fit-content;
    border-radius: 5px;    
    font-family: "OpenSans", sans-serif;
    font-size: ${fontSize ? fontSize : `${theme.textStyles.body.fontSize}px`};
    color: ${colorVariantsMap[variant]};
    background-color: ${
      transparent ? 'transparent' : backgroundColorVariantsMap[variant]
    };
    ${
      bordered
        ? `
          box-shadow: inset 0px 0px 0px 2px ${borderColorVariantsMap[variant]}; 
          border: 0;
          color: ${borderColorVariantsMap[variant]};
          background-color: ${
            transparent
              ? 'transparent'
              : rgba(borderColorVariantsMap[variant], 0.2)
          }; 
        `
        : 'border: 0;'
    }
    cursor: pointer;
    ${icon && !fullWidth ? 'width: 40px;' : ''};
    ${!icon ? 'padding: 2px 4px;' : ''};
    ${opacity ? `opacity: ${opacity};` : ''};

    &:hover {
      opacity: 70%;
    }

    &:disabled {
      pointer-events: none;
      color: ${colorDisabledVariantsMap[variant]};
      background-color: ${backgroundColorDisabledVariantsMap[variant]};
      ${
        bordered &&
        `
          box-shadow: inset 0px 0px 0px 2px ${theme.palette.neutral.light};
          color: ${theme.palette.neutral.light};
          background-color: ${theme.palette.neutral.white}; 
        `
      }
    }

    :focus-visible {
      outline: 1px solid ${colorVariantsMap[variant]};
    }
  `}
  }
`;

const IconWrapper = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  font-size: 16px;
  ${({ position }) => `
    ${position === 'left' && `left: 12px;`};
    ${position === 'right' && `right: 12px;`};
  `}
`;

const StyledP = styled.p`
  ${({ theme }) => `
    font-size: ${theme.textStyles.button.fontSize}px;
  `}
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  iconPosition,
  bordered,
  variant = 'primary',
  type = 'button',
  disabled = false,
  fullWidth,
  width,
  minWidth,
  minHeight,
  onClick,
  children,
  fontSize,
  transparent,
  tooltipContent,
  textAlign,
  opacity,
  ...props
}) => {
  const getIcon = () => {
    if (!icon) return;
    if (!iconPosition) return icon;
    return <IconWrapper position={iconPosition}>{icon}</IconWrapper>;
  };

  return (
    // <Tooltip fullWidth={fullWidth} content={tooltipContent}>
    <StyledButton
      type={type}
      icon={icon}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      width={width}
      minHeight={minHeight}
      bordered={bordered}
      onClick={onClick}
      fontSize={fontSize}
      transparent={transparent}
      textAlign={textAlign}
      minWidth={minWidth}
      opacity={opacity}
      {...props}
    >
      <Flex justifyContent="center" alignItems="center" width="100%">
        {label}
        {getIcon()}
        {children && (
          <Box width="100%" mx={1}>
            <StyledP>{children}</StyledP>
          </Box>
        )}
      </Flex>
    </StyledButton>
    // </Tooltip>
  );
};
