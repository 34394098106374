import { IUserFunctionsResponse } from 'types/functions';
import request from './index';

export const getUserFunctions = () => {
  try {
    return request<IUserFunctionsResponse>({
      options: {
        url: `/functions`,
        method: 'get'
      }
    });
  } catch (e) {
    console.error(e);
    return { data: [], error: e };
  }
};
