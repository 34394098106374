import MDEditor from '@uiw/react-md-editor';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import styled from 'styled-components';
import { device } from 'styles/theme';

export const PaddingWrapper = styled.div`
  padding: 10px 20px;

  @media ${device.tablet} {
    padding: 5px;
  }
`;

export const StyledFlex = styled(Flex)`
  box-sizing: border-box;
`;

export const SubGroupHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.veryLight};
`;

export const StyledMDEditor = styled(MDEditor)`
  width: 100%;
`;

export const DescriptionContentWrapper = styled(Flex)`
  z-index: 100;
`;

export const ResultHeading = styled(P)`
  white-space: nowrap;
`;
