import { IGenericModal, Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Flex } from 'components';
import {
  Button,
  FieldLabel,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { postAuditForm } from 'api/audits';
import { IPostAuditFormRequest } from 'types/audits';
import { useEffect } from 'react';
import {
  NestedSelect,
  IOption as OptionForSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { IOption } from 'components/_form/Select/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { clearCriteriaFromIds } from 'utilities/auditForm/clearCriteriaFromIds';
import { clearGroupsFromIds } from 'utilities/auditForm/clearGroupsFromIds';
import { useNavigate } from 'react-router-dom';

interface Props extends IGenericModal {
  defaultValues: IPostAuditFormRequest['audit_form'];
  methodologyTreeForSelect: OptionForSelect[];
  areas: OptionForSelect[];
  usersList: IOption[];
}

export const CopyForm = ({
  isOpen,
  onCancelClick,
  defaultValues,
  methodologyTreeForSelect,
  areas,
  usersList
}: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const methods = useForm<IPostAuditFormRequest['audit_form']>({
    defaultValues: {}
  });

  const { handleSubmit, reset, control, register } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { id, criteria_attributes, groups_attributes, ...restData } = data;

    const payload = {
      audit_form: {
        ...restData,
        criteria_attributes: clearCriteriaFromIds(criteria_attributes),
        groups_attributes: clearGroupsFromIds(groups_attributes)
      }
    };

    const auditFormRes = await postAuditForm(payload);

    if ('error' in auditFormRes) {
      toast.error(auditFormRes.error.message);
      return;
    }

    navigate(
      `/app/administration/audits/edit-audit-form/${auditFormRes.data.id}`
    );
  });

  const handleCancelClick = () => {
    reset();
    onCancelClick();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Modal
      gridTemplateColumns="40%"
      isOpen={isOpen}
      mainButton={{
        action: onSubmit,
        variant: 'eucalyptus',
        label: t('common.copy')
      }}
      secondButton={{
        action: handleCancelClick,
        label: t('common.cancel')
      }}
      isSecondButtonVisible
      headerTitle={t('administrationAuditsView.copyForm')}
      onCancelClick={handleCancelClick}
    >
      <Flex flexDirection="column" gap="20px">
        <Controller
          control={control}
          name="practice_id"
          render={({ field: { onChange, value } }) => (
            <NestedSelect
              width="100%"
              label={t(
                'administrationAuditsView.pinToFormMethodologyPracticeArea'
              )}
              selectedOptions={methodologyTreeForSelect
                .map((item) => item.options || [])
                .flat()
                .filter((option) => option?.value === value)}
              setSelectedOptions={(newOption) => {
                onChange(newOption[0]?.value);
              }}
              options={methodologyTreeForSelect}
              isOneOptionSelect={true}
            />
          )}
        />

        <TextField {...register('name')} />

        <Controller
          control={control}
          name="area_ids"
          render={({ field: { onChange, value } }) => (
            <NestedSelect
              label={t('administrationAuditsView.usedInAreas') as string}
              selectedOptions={flattenOptions(areas).filter(
                (option) => option?.value && value?.includes(option?.value)
              )}
              setSelectedOptions={(newOptions) => {
                onChange(
                  flattenOptions(newOptions).map((newOption) => newOption.value)
                );
              }}
              options={areas}
            />
          )}
        />

        <Flex gap="20px">
          <Controller
            control={control}
            name="author_id"
            render={({ field: { onChange, value } }) => (
              <Select
                disabled
                label={t('administrationAuditsView.createdBy')}
                selectedOptions={usersList.find(
                  (userOption) => userOption.value === value
                )}
                onChange={(option: IOption) => {
                  onChange(option.value);
                }}
                options={usersList}
              />
            )}
          />

          <Flex flexDirection="column">
            <FieldLabel>{t('administrationAuditsView.active')}</FieldLabel>
            <Flex mt={2}>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value } }) => (
                  <SwitchButton
                    value={value === 'active'}
                    onChange={(checked) => {
                      onChange(checked ? 'active' : 'inactive');
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column">
            <FieldLabel whiteSpace="nowrap">
              {t('administrationAuditsView.requireConfirmation')}
            </FieldLabel>
            <Flex mt={2} gap="10px">
              <SwitchButton />
              <Button>
                <Flex gap="5px">
                  <FontAwesomeIcon size="xl" icon={faPersonCircleCheck} />
                  {t('administrationAuditsView.panel')}
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Controller
          control={control}
          name="person_authorized_to_edit_ids"
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('administrationAuditsView.authorizedToEdit')}
              selectedOptions={
                usersList.filter((userOption) =>
                  value?.includes(userOption.value)
                ) || []
              }
              isMulti
              onChange={(val) => {
                onChange(
                  Array.isArray(val) ? val.map(({ value }) => value) : []
                );
              }}
              options={usersList}
              withoutMargin
            />
          )}
        />
      </Flex>
    </Modal>
  );
};
