import { IAuditGroup, IGroupsAttributes } from 'types/audits';
import { parseRemovedCriteria } from './parseRemovedCriteria';

type GroupsAttributes = (IGroupsAttributes & Partial<IAuditGroup>)[];

export const parseRemovedGroups = (
  defaultGroupsAttribute: GroupsAttributes,
  groupsAttributes: GroupsAttributes
): GroupsAttributes => {
  return [
    ...(
      defaultGroupsAttribute?.filter(
        (defaultGroupsAttribute) =>
          !groupsAttributes.find(
            (groupsAttribute) =>
              'id' in groupsAttribute &&
              'id' in defaultGroupsAttribute &&
              groupsAttribute.id === defaultGroupsAttribute.id
          )
      ) || []
    ).map((groupsAttribute) => ({ ...groupsAttribute, _destroy: 1 })),
    ...groupsAttributes.map((groupsAttribute, index) => {
      const defaultGroupsAttributeWthoutRemoved =
        defaultGroupsAttribute?.filter((defaultGroupsAttribute) =>
          groupsAttributes.find(
            (groupsAttribute) =>
              ('id' in groupsAttribute &&
                'id' in defaultGroupsAttribute &&
                groupsAttribute.id === defaultGroupsAttribute.id) ||
              !('id' in defaultGroupsAttribute)
          )
        ) || [];

      return {
        ...groupsAttribute,
        groups_attributes: parseRemovedGroups(
          defaultGroupsAttributeWthoutRemoved[index]?.groups_attributes || [],
          groupsAttribute.groups_attributes
        ),
        criteria_attributes: parseRemovedCriteria(
          defaultGroupsAttributeWthoutRemoved[index]?.criteria_attributes || [],
          groupsAttribute.criteria_attributes
        )
      };
    })
  ];
};
