import { IStarPayload, IStarResponse } from 'types/forms/stars';
import request from './index';
import { serialize } from 'object-to-formdata';

export const postStarMark = (data: IStarPayload) =>
  request<IStarResponse>({
    options: {
      url: `/star_marks`,
      method: 'post',
      data: serialize(data)
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const deleteStarMark = (id: number) =>
  request<null>({
    options: {
      url: `/star_marks/${id}`,
      method: 'delete'
    }
  });
