import { Flex } from 'components/Flex';
import { IRealizationProgress } from 'types/realizationProgresses';
import {
  IRealizationStatus,
  IRealizationStatusAttribute
} from 'types/realizationStatuses';
import { PDCAPie } from 'components/RealizationProgress/components/PDCAPie';
import { useState } from 'react';
import { ConfirmModal } from './components/ConfirmModal';
import { useAccount } from 'hooks/useAccount';
import { RealizationStatusInfo } from './components/RealizationStatusInfo';
import { IUser } from 'types/user';
import { FieldLabel } from 'components/_form';
import { DMAIC } from './components/DMAIC';
import { Tooltip } from 'react-tooltip';
import { fullName } from 'utilities/fullName';
import { P } from 'components/Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { findLastRealizationProgressLetter } from 'utilities/lastRealizationProgressLetter';

interface Props {
  id?: string;
  realizationProgress?: IRealizationProgress;
  activeAll?: boolean;
  realizationStatusAttributes?: (
    | IRealizationStatusAttribute
    | IRealizationStatus
  )[];
  onChange?: (
    kind: (IRealizationStatusAttribute | IRealizationStatus)[]
  ) => void;
  usersList?: IUser[];
  label?: string | null;
  showStatusInfo?: boolean;
  onlyRealization?: boolean;
}

export const RealizationProgress: React.FC<Props> = ({
  id,
  activeAll,
  realizationProgress,
  realizationStatusAttributes,
  onChange,
  usersList,
  label,
  showStatusInfo,
  onlyRealization
}) => {
  if (!realizationProgress) {
    return <></>;
  }

  const [chosenKind, setChosenKind] = useState<string>();
  const [tooltipData, setTooltipData] = useState<{
    user: string;
    date: string;
  }>();

  const { loggedUserData } = useAccount();

  const activeLetters = realizationStatusAttributes?.map(
    (realizationStatusAttribute) => realizationStatusAttribute.kind
  );

  const lastRealizationProgressLetter = findLastRealizationProgressLetter(
    realizationProgress,
    realizationStatusAttributes || []
  );

  const letters = realizationProgress.kind.split('');

  const availableLetter =
    letters[
      (letters.findIndex((val) => val === lastRealizationProgressLetter) || 0) +
        1
    ];

  const minDate =
    chosenKind && !activeLetters?.includes(chosenKind) ? new Date() : undefined;

  const handleClickKind = (kind: string) => {
    onChange && setChosenKind(kind);
  };

  const handleSubmit = (date: string) => {
    if (chosenKind) {
      onChange?.([
        ...(realizationStatusAttributes || []),
        {
          kind: chosenKind,
          confirmed_at: date,
          realization_progress_id: realizationProgress.id,
          approver_id: Number(loggedUserData.id)
        },
        ...(onlyRealization && chosenKind === 'C'
          ? [
              {
                kind: 'A',
                confirmed_at: date,
                realization_progress_id: realizationProgress.id,
                approver_id: Number(loggedUserData.id)
              }
            ]
          : [])
      ]);

      setChosenKind(undefined);
    }
  };

  const handleRemoveRealizationStatus = (kindToRemove: string) => {
    onChange?.(
      (realizationStatusAttributes || []).filter(
        ({ kind }) => kind !== kindToRemove
      )
    );
  };

  const onLetterHover = (letter: string) => {
    const realizationStatusAttribute = realizationStatusAttributes?.find(
      ({ kind }) => kind === letter
    );

    const user = usersList?.find(
      ({ id }) => id === realizationStatusAttribute?.approver_id
    );

    if (realizationStatusAttribute && user) {
      setTooltipData({
        user: fullName(user?.profile),
        date: realizationStatusAttribute?.confirmed_at || ''
      });
    }
  };

  const onLetterMouseLeave = () => {
    setTooltipData(undefined);
  };

  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      gap="12px"
      flexDirection={['column', 'row']}
    >
      <Flex
        id={`${id}-RealizationProgress`}
        flexDirection="column"
        alignItems={realizationProgress.kind === 'PDCA' ? 'center' : ''}
      >
        {label && <FieldLabel width="fit-content">{label}</FieldLabel>}
        {realizationProgress.kind === 'PDCA' ? (
          <PDCAPie
            activeAll={activeAll}
            activeLetters={activeLetters}
            handleClickKind={handleClickKind}
            onLetterHover={onLetterHover}
            onLetterMouseLeave={onLetterMouseLeave}
            availableLetter={availableLetter}
          />
        ) : (
          <DMAIC
            activeAll={activeAll}
            activeLetters={activeLetters}
            handleClickKind={handleClickKind}
            realizationProgress={realizationProgress}
            onLetterHover={onLetterHover}
            onLetterMouseLeave={onLetterMouseLeave}
            availableLetter={availableLetter}
          />
        )}
      </Flex>

      {showStatusInfo && (
        <RealizationStatusInfo
          realizationProgress={realizationProgress}
          realizationStatusAttributes={realizationStatusAttributes}
          usersList={usersList}
          handleRemoveRealizationStatus={handleRemoveRealizationStatus}
        />
      )}

      <ConfirmModal
        isOpen={!!chosenKind}
        onCancelClick={() => {
          setChosenKind(undefined);
        }}
        handleSubmit={handleSubmit}
        minDate={minDate}
      />

      <Tooltip anchorId={`${id}-RealizationProgress`} isOpen={!!tooltipData}>
        <Flex gap="5px" alignItems="center">
          <FontAwesomeIcon icon={faClock} />
          <P variant="body">
            {tooltipData?.date &&
              format(new Date(tooltipData?.date), dateFormat)}
          </P>
        </Flex>
        <Flex gap="5px" alignItems="center">
          <FontAwesomeIcon icon={faUser} />
          <P variant="body">{tooltipData?.user}</P>
        </Flex>
      </Tooltip>
    </Flex>
  );
};
