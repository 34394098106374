import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { SwitchButton } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormAudit } from './FormAudit/FormAudit';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { postAudit } from 'api/audits';
import { CreateAuditBody } from 'types/audits';
import { iso8601HourAndHalf } from 'constants/iso8601';

export type FormData = Omit<CreateAuditBody, 'audit_participations_attributes'>;

export type FormDataControl = FormData & {
  practice_id: IOption;
  version: IOption;
  evaluation_type: IOption;
  audit_participations_attributes: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
};

interface Props extends IGenericModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

export const CreateAuditModal: React.FC<Props> = ({
  isOpen,
  onCancelClick
}) => {
  const [approvalSwitch, setApprovalSwitch] = useState(false);

  const { t } = useTranslation();
  const { selectedAudit, handleLoadAudits } = useEvaluationContext();

  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FormDataControl>();

  const defaultFormValues: FormData = useMemo(() => {
    return {
      realization_date: new Date().toJSON().slice(0, 10).toString(),
      evaluation_type: {
        value: 'normal',
        label: 'normal'
      },
      version: {
        value: '3.0-GW',
        label: '3.0-GW'
      },
      date: new Date().toJSON().slice(0, 10).toString(),
      name: '',
      description: '',
      audit_form_id: selectedAudit?.audit_form?.id,
      area_id: selectedAudit?.area.id?.toString() || '',
      related_to_evaluation_id: '',
      planned_duration: iso8601HourAndHalf,
      duration: iso8601HourAndHalf,
      type: 'Evaluation',
      notes: '',
      leader_id: undefined,
      team_leader_id: undefined,
      team_manager_id: undefined,
      archived_at: '',
      planned_start_date: '',
      planned_completion_date: '',
      start_time: '',
      completion_time: '',
      audit_participations_attributes: [],
      team_ids: [],
      closing_ids: []
    };
  }, [selectedAudit]);

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const handleSubmitAuditForm = useCallback(
    async (data: FormDataControl, closeOnSubmit = true) => {
      try {
        const payload = {
          audit: {
            ...data,
            type: 'Audit',
            audit_type: data.evaluation_type.value,
            author_id: JSON.parse(localStorage.getItem('currentUser') as string)
              .id,
            audit_participations_attributes:
              data.audit_participations_attributes?.map(
                (auditParticipationsAttribute) => ({
                  participant_id: auditParticipationsAttribute.value,
                  function_id: auditParticipationsAttribute.function_id
                })
              )
          }
        };

        const auditResponse = await postAudit(payload);
        await handleLoadAudits();
        toast.success(t('common.success'));
        closeOnSubmit && onCancelClick();

        return auditResponse.data;
      } catch (error) {
        console.log(error);
        toast.error(t('common.anErrorOccurred'));
      }
    },
    [handleLoadAudits, onCancelClick, t]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {
          handleSubmit((data) => handleSubmitAuditForm(data))();
          reset(defaultFormValues);
        },
        variant: 'eucalyptus',
        label: t(`evaluationsView.evaluationSubmitButton`)
      }}
      isSecondButtonVisible={true}
      gridTemplateColumns="95%"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {t(`auditsView.createAudit`)}
          </H2>
        </Flex>
      }
      additionalFooter={
        <Flex
          minWidth="250px"
          alignItems="center"
          gap="10px"
          display={['none', 'flex']}
        >
          <P variant="body">{t('createTaskView.footer')}</P>
          <SwitchButton value={approvalSwitch} onChange={setApprovalSwitch} />
        </Flex>
      }
    >
      <FormAudit
        control={control}
        register={register}
        canChangeAuditStatus={false}
        errors={errors}
        watch={watch}
        setValue={setValue}
        submitAuditForm={handleSubmitAuditForm}
      />
    </Modal>
  );
};
