import React from 'react';
import { Tooltip } from 'react-tooltip';
import { createRoot } from 'react-dom/client';
import { theme } from 'styles';
import ReactDOMServer from 'react-dom/server';
import { DayTooltip } from './components/DayTooltip';
import { Wrapper } from './TaskCalendar.styled';

// @ts-ignore
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.pl';

Calendar.locales['pl'] = {
  ...Calendar.locales['pl'],
	weekShort: 'T'
};

export const TaskCalendar: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const tmpData = [
    {
      startDate: new Date(currentYear, 1, 4),
      endDate: new Date(currentYear, 1, 4),
      name: 'Nazwa',
      description: ReactDOMServer.renderToStaticMarkup(
        <DayTooltip
          data={{
            title: 'SS 22/03/2022',
            name: 'Pierwszy Audyt SS w UR',
            author: 'Jan Kowalski',
            date: '06.08.2022',
            person: 'Eustachy Spręzyna'
          }}
        />
      ),
      color: theme.palette.accent.red
    },
    {
      startDate: new Date(currentYear, 3, 5),
      endDate: new Date(currentYear, 3, 5),
      name: 'Nazwa 2',
      description: 'Opis 2',
      color: theme.palette.accent.green
    }
  ];

  const handleCustomDataSourceRenderer = (
    e: HTMLElement,
    date: Date,
    options: { description: string | JSX.Element; color: string }[]
  ) => {
    e.setAttribute('id', 'dayTooltip' + date);
    e.setAttribute('data-tooltip-html', options[0].description as string);
    e.setAttribute('data-action', 'removeDefault');
    e.setAttribute('data-tooltip-place', 'bottom');

    e.style.background = options[0].color;
    e.style.borderRadius = '0';

    let toolTipContainer = document.createElement('div');
    e.after(toolTipContainer);
    const root = createRoot(toolTipContainer);
    root.render(<Tooltip anchorId={'dayTooltip' + date} />);
  };

  return (
    <Wrapper flexDirection="column">
      <Calendar
        displayWeekNumber
        enableContextMenu
        language="pl"
        dataSource={tmpData}
        style="custom"
        customDataSourceRenderer={handleCustomDataSourceRenderer}
      />
    </Wrapper>
  );
};
