import { Flex } from "components";
import styled from "styled-components";

const SquareNumberBox = styled(Flex)`
  width: 20px;
  height: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
`;

export const SquareNumberBoxGreen = styled(SquareNumberBox)`
  border: 1px solid ${({ theme }) => theme.palette.accent.green};
  color: ${({ theme }) => theme.palette.accent.green};
  background-color: ${({ theme }) => theme.palette.neutral.lightGreen};
`;
export const SquareNumberBoxBlue = styled(SquareNumberBox)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.mainBlue};
  color: ${({ theme }) => theme.palette.neutral.mainBlue};
  background-color: ${({ theme }) => theme.palette.neutral.white};;
`;
export const SquareNumberBoxRed = styled(SquareNumberBox)`
  border: 1px solid ${({ theme }) => theme.palette.accent.red};
  color: ${({ theme }) => theme.palette.accent.red};
  background-color: ${({ theme }) => theme.palette.neutral.white};
`;
