import { Flex, Line } from 'components';
import { H1 } from 'components/Typography/Typography';
import { Wrapper } from '../AdministrationAuditsView.styled';
import { useTranslation } from 'react-i18next';
import { AuditFormForm } from '../components/AuditFormForm/AuditFormForm';
import { postAuditForm } from 'api/audits';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IPostAuditFormRequest } from 'types/audits';
import { useAccount } from 'hooks/useAccount';

export const AddAuditFormView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { loggedUserData } = useAccount();

  const handleSubmitAuditForm = async (
    data: IPostAuditFormRequest['audit_form']
  ) => {
    const payload = { audit_form: { ...data } };

    const auditFormRes = await postAuditForm(payload);

    if ('error' in auditFormRes) {
      toast.error(auditFormRes.error.message);
      return;
    }

    navigate('/app/administration/audits');
  };

  const defaultValues = {
    author_id: loggedUserData.id,
    maximum_task_completion_time: 'P1D'
  };

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <H1 m={2} variant={['h3', 'h2']}>
          {t('administrationAuditsView.addAuditForm')}
        </H1>
      </Flex>
      <Line />

      <AuditFormForm
        handleSubmitAuditForm={handleSubmitAuditForm}
        defaultValues={defaultValues}
      />
    </Wrapper>
  );
};
