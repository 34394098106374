import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/_form';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  customOnClick?: () => void;
}

export const BackButton: FC<Props> = ({ customOnClick }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<FontAwesomeIcon icon={faChevronLeft} />}
      onClick={() => {
        if (customOnClick) {
          customOnClick();
        } else {
          navigate(-1);
        }
      }}
    />
  );
};
