import { Avatar, Box, Flex } from 'components';
import { Button } from 'components/_form';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import styled from 'styled-components';
import { t } from 'i18next';
import 'cropperjs/dist/cropper.css';

const StyledInput = styled.input`
  display: none;
`;

interface Props {
  handleSubmit?: (croppedData: Blob) => void;
  avatar?: string;
}

export const AvatarForm: FC<Props> = ({ handleSubmit, avatar }) => {
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState<Cropper>();

  const ref = useRef<HTMLInputElement>(null);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    if (files) reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const croppedResult = cropper.getCroppedCanvas().toDataURL();

      const blobBin = atob(croppedResult.split(',')[1]);
      const array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new File(
        [new Uint8Array(array)],
        ref.current?.files ? ref.current.files[0].name : 'file'
      );

      setImage(undefined);
      handleSubmit && handleSubmit(file);
    }
  };

  return (
    <>
      <Box mb={3}>
        {image ? (
          <Cropper
            style={{ height: '228px', width: '228px' }}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={150}
            minCropBoxWidth={150}
            cropBoxResizable={false}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        ) : (
          <Avatar
            size="large"
            url={`${process.env.REACT_APP_API_URL}${avatar}`}
          />
        )}
      </Box>
      <StyledInput
        ref={ref}
        type="file"
        accept=".jpg, .png, .jpeg, .gif, .bmp"
        onChange={onChange}
      />

      {image ? (
        <Flex>
          <Box mr={2} pb={1} width="100%">
            <Button
              fullWidth
              fontSize="12px"
              variant="grey"
              bordered
              onClick={() => {
                ref?.current?.click();
              }}
            >
              {t('profile.imageChange')}
            </Button>
          </Box>
          <Box mr={2} width="100%">
            <Button fullWidth variant="eucalyptus" onClick={getCropData}>
              {t('common.save')}
            </Button>
          </Box>
          <Button
            fullWidth
            fontSize="12px"
            variant="secondary"
            bordered
            onClick={() => {
              setImage(undefined);
              if (ref.current?.value) ref.current.value = '';
            }}
          >
            {t('profile.imageRemove')}
          </Button>
        </Flex>
      ) : (
        <Button
          fullWidth
          fontSize="12px"
          variant="grey"
          onClick={() => {
            ref?.current?.click();
          }}
        >
          {t('profile.addImage')}
        </Button>
      )}
    </>
  );
};
