import styled, {css} from "styled-components";
import {
  space,
  SpaceProps,
  LayoutProps,
  layout, flexbox, gridColumnGap
} from "styled-system"

interface ICard extends SpaceProps, LayoutProps {}

export const Card = styled.div<ICard>(
  () =>
    css`
    ${({ theme }) => `
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 6px 24px -12px ${theme.palette.neutral.medium};
    overflow: hidden;
  `};
  `,
  space,
  flexbox,
  gridColumnGap,
  layout
)
