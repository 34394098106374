import { Flex } from 'components';
import { Button } from 'components/_form';
import React, { useEffect, useState } from 'react';
import UserListTable from './components/UserListTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { UserAddModal } from './components/UserAddModal';
import { UsersImportModal } from './components/UsersImportModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IUser } from 'types/user';
import { getUsers } from 'api/user';

export const UserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const handleLoadUsers = async () => {
    const { data } = await getUsers();
    setUsersList(data);
  };

  useEffect(() => {
    handleLoadUsers();
  }, []);

  return (
    <>
      <UserAddModal
        isOpen={openAddModal}
        onCancelClick={() => setOpenAddModal(false)}
      />
      <UsersImportModal
        isOpen={openImportModal}
        onCancelClick={() => setOpenImportModal(false)}
      />
      <Flex
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        gap="5px"
      >
        <Button
          variant="redWhite"
          bordered
          onClick={() => {
            navigate('special-permissions');
          }}
        >
          <FontAwesomeIcon icon={faGear} /> {t('buttons.specialLicenses')}
        </Button>

        <Flex
          justifyContent="flex-end"
          width="100%"
          gap="10px"
          flexDirection={['column', 'row']}
        >
          <Button
            variant="eucalyptus"
            onClick={() => {
              setOpenAddModal(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.add')}
          </Button>
          <Button
            variant="eucalyptus"
            onClick={() => {
              setOpenImportModal(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.import')}
          </Button>
        </Flex>
      </Flex>
      <UserListTable usersList={usersList} />
    </>
  );
};
