import { IGenericModal, Modal } from 'components/Modal/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TaskTypeForm } from '../TaskTypeForm/TaskTypeForm';
import { ITaskType, ITaskTypePayload } from 'types/taskType';
import { deleteTaskType, editTaskType, getTaskType } from 'api/taskTypes';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/_form';
import { yupResolver } from '@hookform/resolvers/yup';
import { taskTypeSchema } from 'validation/taskTypeSchemas';

interface Props extends IGenericModal {
  onSubmitSuccess?: (newTaskType: ITaskType) => void;
  onDeleteSuccess?: () => void;
  editingTaskTypeId: number;
}

export const EditTaskTypeForm = ({
  isOpen,
  onCancelClick,
  onSubmitSuccess,
  onDeleteSuccess,
  editingTaskTypeId
}: Props) => {
  const [editingTaskType, setEditingTaskType] = useState<ITaskType>();

  const { t } = useTranslation();

  const methods = useForm<ITaskTypePayload['task_type']>({
    mode: 'onChange',
    resolver: yupResolver(taskTypeSchema)
  });

  const { handleSubmit, reset } = methods;

  const handleSubmitTaskTypeForm = async (
    data: ITaskTypePayload['task_type']
  ) => {
    const taskTypeRes = await editTaskType(editingTaskTypeId, {
      task_type: data
    });

    if ('error' in taskTypeRes) {
      toast.error(taskTypeRes.error.message);
      return;
    }

    onSubmitSuccess?.(taskTypeRes.data);
  };

  const handleDeleteTaskType = async () => {
    const deleteTaskTypeRes = await deleteTaskType(editingTaskTypeId);

    if (deleteTaskTypeRes && 'error' in deleteTaskTypeRes) {
      toast.error(deleteTaskTypeRes.error.message);
      return;
    }

    onDeleteSuccess?.();
  };

  const handleGetTaskType = useCallback(async () => {
    const taskTypeRes = await getTaskType(editingTaskTypeId);

    if ('error' in taskTypeRes) {
      return;
    }

    setEditingTaskType(taskTypeRes.data);
  }, [editingTaskTypeId]);

  const handleSetDefaultValues = useCallback(() => {
    reset({
      ...editingTaskType,
      area_ids: editingTaskType?.areas?.map(({ id }) => id.toString()) || [],
      realization_progress_id: editingTaskType?.realization_progress?.id
    });
  }, [editingTaskType, reset]);

  useEffect(() => {
    handleGetTaskType();
  }, [handleGetTaskType]);

  useEffect(() => {
    handleSetDefaultValues();
  }, [handleSetDefaultValues]);

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={() => {
        onCancelClick();
      }}
      headerTitle={t('administrationTasksView.editTaskType')}
      mainButton={{
        action: handleSubmit((data) => {
          handleSubmitTaskTypeForm(data);
        }),
        variant: 'eucalyptus',
        label: t('buttons.save')
      }}
      isSecondButtonVisible
      gridTemplateColumns="60%"
      additionalFooter={
        <Button variant="red" onClick={handleDeleteTaskType}>
          {t('buttons.delete')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form>
          <TaskTypeForm />
        </form>
      </FormProvider>
    </Modal>
  );
};
