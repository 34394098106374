import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
  isAuth = false,
  children
}: {
  isAuth?: boolean;
  children: React.ReactNode;
}) => {
  const token = localStorage.getItem('auth-token');
  if (!token && !isAuth) {
    return <Navigate to="/login" />;
  }
  if (token && isAuth) {
    return <Navigate to="/app" />;
  }
  return <>{children}</>;
};
