import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { dateHoursFormat } from 'constants/dateFormats/dateHourFormat';
import { format } from 'date-fns';
import styled from 'styled-components';
import { IRealizationProgress } from 'types/realizationProgresses';
import {
  IRealizationStatus,
  IRealizationStatusAttribute
} from 'types/realizationStatuses';
import { IUser } from 'types/user';
import { findLastRealizationProgressLetter } from 'utilities/lastRealizationProgressLetter';

const Wrapper = styled(Flex)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  flex-direction: column;
  padding: 6px;
`;

const StyledImg = styled.img`
  object-fit: cover;
`;

const StyledButton = styled.button`
  background: transparent;
`;

interface Props {
  realizationProgress: IRealizationProgress;
  realizationStatusAttributes?: (
    | IRealizationStatusAttribute
    | IRealizationStatus
  )[];
  usersList?: IUser[];
  handleRemoveRealizationStatus?: (kind: string) => void;
}

export const RealizationStatusInfo: React.FC<Props> = ({
  realizationProgress,
  realizationStatusAttributes,
  usersList,
  handleRemoveRealizationStatus
}) => {
  const letters = realizationProgress.kind.split('');

  const lastRealizationProgressLetter = findLastRealizationProgressLetter(
    realizationProgress,
    realizationStatusAttributes || []
  );

  return (
    <Wrapper>
      {letters.map((letter) => {
        const realizationStatusAttribute = realizationStatusAttributes?.find(
          ({ kind }) => kind === letter
        );

        const user = usersList?.find(
          ({ id }) => id === realizationStatusAttribute?.approver_id
        );

        return (
          <Flex
            minWidth="160px"
            key={`RealizationStatusInfo-${letter}`}
            gap="4px"
          >
            <P variant="body" color="green">
              {letter}:
              {realizationStatusAttribute?.confirmed_at &&
                format(
                  new Date(realizationStatusAttribute.confirmed_at),
                  dateHoursFormat
                )}
            </P>
            {realizationStatusAttribute && user && (
              <>
                <StyledImg
                  width="20px"
                  height="20px"
                  src={`${process.env.REACT_APP_API_URL}${user.profile?.photo}`}
                  alt={user.email}
                />
                {realizationStatusAttribute.kind ===
                  lastRealizationProgressLetter && (
                  <StyledButton>
                    <FontAwesomeIcon
                      color="grey"
                      icon={faRotateLeft}
                      onClick={() => {
                        handleRemoveRealizationStatus?.(letter);
                      }}
                    />
                  </StyledButton>
                )}
              </>
            )}
          </Flex>
        );
      })}
    </Wrapper>
  );
};
