import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  CriteriaExample,
  HeaderTitle,
  HeaderTitleWrapper,
  LeftPanelScrollWrapper,
  StyledButton
} from './CriteriaModal.styled';
import { useState } from 'react';
import { LeftPanel } from './LeftPanel/LeftPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faDownload,
  faFileCirclePlus,
  faFilePen,
  faShareFromSquare,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { theme } from 'styles/theme';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { FileModal, ModalType } from './FileModal/FileModal';

type FileType = 'doc' | 'img' | 'mov' | 'excel' | 'pdf';

export interface Data {
  title: string;
  subTitle: string;
  mainTitle: string;
  file: string;
  fileType: FileType;
  number: number;
}

export interface SetTitleAndFile {
  value: string;
  fileName: string;
  fileType: FileType;
  activeId: number;
}

interface Props extends IGenericModal {
  title?: string;
}

export const CriteriaModal = ({ isOpen, onCancelClick, title }: Props) => {
  const [mainTitle, setMainTitle] = useState('Wybierz element');
  const [currentFile, setCurrentFile] = useState('');
  const [currentFileType, setCurrentFileType] = useState('');
  const [activeId, setActiveId] = useState<number>();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>('add');
  const [currentFilename, setCurrentFilename] = useState('');

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const setTitleAndFile = ({
    value,
    fileName,
    fileType,
    activeId
  }: SetTitleAndFile) => {
    setCurrentFile(fileName);
    setMainTitle(value);
    setActiveId(activeId);
    setCurrentFileType(fileType);
  };

  const { t } = useTranslation();

  const data: Data[] = [
    {
      number: 0,
      mainTitle: 'Plik',
      title: 'Mapa 5s na planie hali 540',
      subTitle: 'na warsztatach 23-24.04.2022',
      file: 'documents/example.docx',
      fileType: 'doc'
    },
    {
      number: 1,
      mainTitle: 'Plik',
      title: 'PLANTA 5S-ws-fire workshop video',
      subTitle: 'Film promocyjny z wasrztatow 5S',
      file: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
      fileType: 'mov'
    },
    {
      number: 2,
      mainTitle: 'Plik',
      title: 'Plan szkoleń dla kierownictwa ',
      subTitle: 'Plac na hl 2023',
      file: 'images/example.png',
      fileType: 'img'
    },
    {
      number: 3,
      mainTitle: 'Plik',
      title: 'Plan szkoleń dla kierownictwa ',
      subTitle: 'Plac na hl 2023',
      file: 'excel/example.excel',
      fileType: 'excel'
    },
    {
      number: 4,
      mainTitle: 'Plik',
      title: 'Plan szkoleń dla kierownictwa ',
      subTitle: 'Plac na hl 2023',
      file: 'https://pdfobject.com/pdf/sample.pdf',
      fileType: 'pdf'
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t('common.close')
      }}
      isSecondButtonVisible
      gridTemplateColumns="99%"
      headerTitle={
        <Flex width="100%">
          <CriteriaExample variant="h3">
            {t(`criteriaView.${title ? title : 'exampleForCriterion'}`)}:
          </CriteriaExample>
          <HeaderTitleWrapper>
            <HeaderTitle variant="h3">{mainTitle}</HeaderTitle>
          </HeaderTitleWrapper>
        </Flex>
      }
    >
      <Flex flexDirection="row">
        <Flex flexDirection={'column'}>
          <LeftPanelScrollWrapper flexDirection="column">
            {data.map((item) => (
              <LeftPanel
                key={item.number}
                data={{
                  file: item.file,
                  fileType: item.fileType,
                  mainTitle: item.mainTitle,
                  subTitle: item.subTitle,
                  title: item.title,
                  number: item.number
                }}
                setTitleAndFile={setTitleAndFile}
                activeId={activeId}
              />
            ))}
          </LeftPanelScrollWrapper>
          <Flex
            width="80%"
            justifyContent="flex-end"
            marginBottom="5px"
            marginTop="5px"
          >
            <StyledButton
              variant="greyWhite"
              bordered
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faFileCirclePlus}
                    size="xl"
                    color={theme.palette.accent.green}
                  />
                </Flex>
              }
              onClick={() => {
                setIsFileModalOpen(true);
                setModalType('add');
              }}
            />
            <StyledButton
              variant="greyWhite"
              bordered
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faFilePen}
                    size="xl"
                    color={theme.palette.accent.blue}
                  />
                </Flex>
              }
              onClick={() => {
                setIsFileModalOpen(true);
                setModalType('edit');
                setCurrentFilename(currentFilename);
              }}
            />
            <StyledButton
              variant="greyWhite"
              bordered
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="xl"
                    color={theme.palette.primary.main}
                  />
                </Flex>
              }
            />
            <StyledButton
              variant="greyWhite"
              bordered
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size="xl"
                    color={theme.palette.accent.red}
                  />
                </Flex>
              }
            />
          </Flex>
          <Flex flexDirection="row" justifyContent="flex-end" width="75%">
            <StyledButton
              variant="greyWhite"
              bordered
              style={{ width: '50px' }}
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faShareFromSquare}
                    size="xl"
                    color={theme.palette.accent.blue}
                  />
                </Flex>
              }
            />
            <StyledButton
              variant="greyWhite"
              bordered
              style={{ width: '50px' }}
              icon={
                <Flex alignItems="end">
                  <FontAwesomeIcon
                    icon={faComment}
                    size="xl"
                    color={theme.palette.neutral.grey}
                  />
                </Flex>
              }
            />
          </Flex>
        </Flex>
        {modalType === 'add' ? (
          <FileModal
            isOpen={isFileModalOpen}
            onCancelClick={() => setIsFileModalOpen(false)}
            modalType={modalType}
          />
        ) : (
          modalType === 'edit' && (
            <FileModal
              isOpen={isFileModalOpen}
              onCancelClick={() => setIsFileModalOpen(false)}
              modalType={modalType}
              currentFilename={currentFilename as string}
            />
          )
        )}
        {currentFileType === 'img' ? (
          <Flex
            width="80%"
            maxHeight="60vh"
            flexDirection="row"
            justifyContent="center"
          >
            <img
              height="100%"
              src={require(`assets/${currentFile}`)}
              alt="parts"
            />
          </Flex>
        ) : currentFile.includes('.mp4') ? (
          <video
            id="video"
            controls={true}
            preload="none"
            width="100%"
            poster=""
          >
            <source id="mp4" src={currentFile} type="video/mp4" />

            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
        ) : currentFile.includes('.pdf') ? (
          <Flex
            maxHeight="480px"
            overflowY="auto"
            overflowX="hidden"
            width="80%"
            flexDirection="row"
            justifyContent="center"
          >
            <Document file={currentFile} onLoadSuccess={onDocumentLoadSuccess}>
              {[...Array(numPages)].map((x, i) => (
                <Page pageNumber={i + 1} key={i + 1} />
              ))}
            </Document>
          </Flex>
        ) : currentFile === '' ? (
          <Flex flexDirection="row" marginLeft="5px">
            <p>Wybierz plik z lewego panelu</p>
          </Flex>
        ) : (
          <Flex flexDirection="row" marginLeft="5px">
            <p>
              Nieobsługiwany typ pliku, kliknij linka aby pobrać plik:&nbsp;
            </p>
            <p>
              <a
                href={process.env.REACT_APP_API_URL + currentFile}
                target="_blank"
                rel="noreferrer"
              >
                {currentFile.substring(currentFile.lastIndexOf('/') + 1)}
              </a>
            </p>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
