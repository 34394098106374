import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './TypesOfTasksFilter.styled';
import { Select } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { IOption } from '../../../../../../../components/_form/Select/Select';
import { getTaskTypes } from 'api/taskTypes';

export const TypesOfTasksFilter: React.FC = () => {
  const [taskTypes, setTaskTypes] = useState<IOption[]>([]);

  const { t } = useTranslation();
  const {
    filters: { typesOfTasks },
    setFilters
  } = useTaskSidebarContext();

  const selected = () =>
    taskTypes.filter((el) => typesOfTasks.value.includes(el.value));
  const handleOnChange = (val: IOption[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        typesOfTasks: {
          label:
            val.length > 0
              ? t('tasks.typesOfTasks') +
                ' - ' +
                val.map((el) => el.label).join(', ')
              : '',
          value: val.map((el) => el.value)
        }
      };
    });
  };

  const loadOptions = async () => {
    const { data: taskTypes } = await getTaskTypes();

    const taskTypesOptions = taskTypes.map(({ name, id }) => ({
      value: String(id),
      label: name
    }));

    setTaskTypes(taskTypesOptions);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <Wrapper>
      <Select
        isMulti
        options={taskTypes}
        selectedOptions={selected()}
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};
