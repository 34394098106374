import { Tooltip } from 'react-tooltip';
import { useCallback, useRef, useState } from 'react';
import { CellTextValueWrapper, ItemTextField } from './CellTextValue.styled';
import { lineHeight } from 'styled-system';
import ReactDOMServer from 'react-dom/server';

interface Props {
  value: string | JSX.Element;
  number: string | number;
  hintSwitch: boolean;
  label: 'gradingScale' | 'guidelines' | 'description';
  fontSize: number;
  lineHeight: number;
}

type cursorType = 'default' | 'help' | 'not-allowed';

export const CellTextValue = ({
  value,
  number,
  hintSwitch,
  label,
  fontSize
}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [cursorType, setCursorType] = useState<cursorType>('default');
  const paragraph = useRef<HTMLParagraphElement>(null);

  const setTooltip = useCallback(() => {
    if (
      paragraph.current &&
      paragraph.current.clientHeight < paragraph.current.scrollHeight
    ) {
      setIsTooltipVisible(true);
      if (hintSwitch) setCursorType('help');
      else setCursorType('not-allowed');
    } else {
      if (hintSwitch) setCursorType('default');
      else setCursorType('not-allowed');
    }
  }, [paragraph, fontSize, hintSwitch]);

  return (
    <CellTextValueWrapper lineHeight={`${lineHeight}px`} cursor={cursorType}>
      <ItemTextField
        id={`${number}:tooltip:${label}`}
        variant="h3"
        data-tooltip-content={ReactDOMServer.renderToStaticMarkup(<>{value}</>)}
        onMouseEnter={() => setTooltip()}
        onMouseLeave={() => setIsTooltipVisible(false)}
        ref={paragraph}
      >
        {value}
      </ItemTextField>
      <Tooltip
        anchorId={`${number}:tooltip:${label}`}
        isOpen={isTooltipVisible && hintSwitch ? true : false}
        style={{ height: 'auto', width: '400px', zIndex: 999 }}
      />
    </CellTextValueWrapper>
  );
};
