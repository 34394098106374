import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from 'styles';
import './config/i18n';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import { TaskSidebarContextContextProvider } from './contexts/TaskSidebarContext';
import { DocumentsSidebarContextContextProvider } from 'contexts/DocumentsSidebarContext';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { EvaluationContextProvider } from 'contexts/EvaluationContext';
import { DesktopContextProvider } from 'contexts/DesktopContext';
import './fonts/Open_Sans/static/OpenSans-Regular.ttf';
import './fonts/Roboto/Roboto-Regular.ttf';
import './fonts/MaterialIcons/MaterialIcons.woff2';
import { TaskContextProvider } from 'contexts/TaskContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <DesktopContextProvider>
            <TaskSidebarContextContextProvider>
              <DocumentsSidebarContextContextProvider>
                <EvaluationContextProvider>
                  <TaskContextProvider>
                    <App />
                  </TaskContextProvider>
                </EvaluationContextProvider>
              </DocumentsSidebarContextContextProvider>
            </TaskSidebarContextContextProvider>
          </DesktopContextProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
