import React, { createRef, useCallback, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  TimeScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Wrapper } from './TaskChart.styled';
import { theme } from 'styles';
import { t } from 'i18next';
// @ts-ignore
import zoomPlugin from 'chartjs-plugin-zoom';
import Chart from 'react-apexcharts';
import { useTaskContext } from 'contexts/TaskContext';
import { ApexOptions } from 'apexcharts';

interface ApexAxisChartSeriesElement {
  x: string;
  y: [number, number];
}

ChartJS.register(
  TimeScale,
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const TaskChart: React.FC = () => {
  const [series, setSeries] = useState<
    ApexAxisChartSeriesElement | ApexNonAxisChartSeries | undefined | any
  >([]);

  const ref = createRef<HTMLDivElement>();
  const { tasks } = useTaskContext();

  const lang = localStorage.getItem('lang') || 'pl';

  const options_apex: ApexCharts.ApexOptions = {
    chart: {
      defaultLocale: lang,
      locales: [
        {
          name: 'pl',
          options: {
            months: [
              'Styczeń',
              'Luty',
              'Marzec',
              'Kwiecień',
              'Maj',
              'Czerwiec',
              'Lipiec',
              'Sierpień',
              'Wrzesień',
              'Październik',
              'Listopad',
              'Grudzień'
            ],
            shortMonths: [
              'Sty',
              'Lut',
              'Mar',
              'Kwi',
              'Maj',
              'Cze',
              'Lip',
              'Sie',
              'Wrz',
              'Paź',
              'Lis',
              'Gru'
            ],
            days: [
              'Niedziela',
              'Poniedziałek',
              'Wtorek',
              'Środa',
              'Czwartek',
              'Piątek',
              'Sobota'
            ],
            shortDays: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
            toolbar: {
              exportToSVG: 'Pobierz SVG',
              exportToPNG: 'Pobierz PNG',
              exportToCSV: 'Pobierz CSV',
              download: 'Pobierz',
              selection: 'Wybór',
              selectionZoom: 'Zoom',
              zoomIn: 'Przybliż',
              zoomOut: 'Oddal',
              pan: 'Przesuń',
              reset: 'Resetuj zoom'
            }
          }
        },
        {
          name: 'en',
          options: {
            months: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ],
            shortMonths: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ],
            days: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday'
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              download: 'Download SVG',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom'
            }
          }
        },
        {
          name: 'ru',
          options: {
            months: [
              'Январь',
              'февраль',
              'Март',
              'Апрель',
              'май',
              'Июнь',
              'июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'декабрь'
            ],
            shortMonths: [
              'Янв',
              'фев',
              'Мар',
              'Апр',
              'май',
              'Июн',
              'июл',
              'Авг',
              'Сен',
              'Окт',
              'Ноя',
              'дек'
            ],
            days: [
              'Воскресенье',
              'Понедельник',
              'вторник',
              'среда',
              'Четверг',
              'пятница',
              'суббота'
            ],
            shortDays: ['Вос', 'Пон', 'вто', 'сре', 'Чет', 'пят', 'суб'],
            toolbar: {
              download: 'Скачать SVG',
              selection: 'Выбор',
              selectionZoom: 'Выбор масштаба',
              zoomIn: 'Увеличить',
              zoomOut: 'Уменьшить масштаб',
              pan: 'Панорамирование',
              reset: 'Сброс масштаба'
            }
          }
        }
      ]
    },
    xaxis: {
      type: 'datetime'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: '#eb4034',
          label: {
            style: {
              color: theme.palette.neutral.white,
              background: theme.palette.text.red
            },
            text: `${t('common.today')}`
          }
        }
      ]
    }
  };

  const makeSeries = useCallback(() => {
    if (!tasks || tasks.length === 0) return;

    let planned: ApexAxisChartSeriesElement[] = [];

    tasks.forEach((task) => {
      if (
        task.planned_start_date === null ||
        task.planned_completion_date === null
      )
        return;

      planned.push({
        x: task.name,
        y: [
          new Date(task.planned_start_date).getTime(),
          new Date(task.planned_completion_date).getTime()
        ]
      });
    });

    let completion: ApexAxisChartSeriesElement[] = [];

    tasks.forEach((task) => {
      if (task.start_time === null || task.completion_time === null) return;

      completion.push({
        x: task.name,
        y: [
          new Date(task.start_time).getTime(),
          new Date(task.completion_time).getTime()
        ]
      });
    });

    const series: ApexOptions['series'] = [
      {
        name: `${t('tasks.plan')}`,
        data: planned
      },
      {
        name: `${t('tasks.implementation')}`,
        data: completion
      }
    ];

    return series;
  }, [tasks]);

  useEffect(() => {
    const seriesReturn = makeSeries();

    if (seriesReturn) {
      setSeries(seriesReturn);
    }
  }, [makeSeries]);

  return (
    <Wrapper flexDirection="column">
      <div ref={ref}>
        <Chart
          options={options_apex}
          series={series}
          type="rangeBar"
          width="100%"
        />
      </div>
    </Wrapper>
  );
};
