import { FC } from 'react';
import styled from 'styled-components';

const StyledNumber = styled.p`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.grey};
    color: ${theme.palette.neutral.white};
  `};
  padding: 1px;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  right: -4px;
  font-size: 12px;
  min-width: 20px;
`;

interface Props {
  value?: string | number;
}

export const CountTag: FC<Props> = ({ value }) => {
  return value ? <StyledNumber>{value}</StyledNumber> : <></>;
};
