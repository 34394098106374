import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2 } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormDataControl } from '../CreateEvaluationModal';
import { IOption } from 'components/_form/Select/Select';
import { Dispatch, SetStateAction, useState } from 'react';
import { IDetailedEvaluation } from 'types/evaluations';
import {
  closeEvaluationClosing,
  openEvaluationClosing
} from 'api/evaluationsClosings';
import { IEvaluationsClosing } from 'types/evaluationsClosings';
import { Loader } from 'components/Loader/Loader';
import { ClosingTable } from 'components/ClosingTable/ClosingTable';

interface Props extends IGenericModal {
  watch: UseFormWatch<FormDataControl>;
  setValue: UseFormSetValue<FormDataControl>;
  usersList: IOption[];
  userFunctionsList: IOption[];
  selectedEvaluation?: IDetailedEvaluation;
  setSelectedEvaluation?: Dispatch<
    SetStateAction<IDetailedEvaluation | undefined>
  >;
}

export const EvaluationStatusModal = ({
  isOpen,
  onCancelClick,
  watch,
  setValue,
  usersList,
  userFunctionsList,
  selectedEvaluation,
  setSelectedEvaluation
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isAddingRowVisible, setIsAddingRowVisible] = useState(false);

  const { t } = useTranslation();

  const closingIds = watch('closing_ids') || [];

  const insertEvaluationClosingIntoEvaluation = (
    newEvaluationClosing: IEvaluationsClosing
  ) => {
    const evaluationClosing = selectedEvaluation?.evaluations_closings.find(
      ({ id: findId }) =>
        findId.toString() === newEvaluationClosing.id.toString()
    );

    if (evaluationClosing) {
      setSelectedEvaluation?.(
        (prevEvaluation) =>
          prevEvaluation && {
            ...prevEvaluation,
            evaluations_closings: evaluationClosing
              ? prevEvaluation.evaluations_closings.map((evaluationClosing) =>
                  evaluationClosing.id.toString() ===
                  newEvaluationClosing.id.toString()
                    ? newEvaluationClosing
                    : evaluationClosing
                )
              : [...prevEvaluation.evaluations_closings, newEvaluationClosing]
          }
      );
    }
  };

  const handleCloseEvaluation = async (closingId?: string | number) => {
    setLoading(true);
    if (closingId) {
      const closeEvaluationClosingRes = await closeEvaluationClosing(closingId);

      if (closeEvaluationClosingRes.data) {
        insertEvaluationClosingIntoEvaluation(closeEvaluationClosingRes.data);
      }
    }
    setLoading(false);
  };

  const handleOpenEvaluation = async (closingId?: string | number) => {
    setLoading(true);
    if (closingId) {
      const openEvaluationClosingRes = await openEvaluationClosing(closingId);

      if (openEvaluationClosingRes.data) {
        insertEvaluationClosingIntoEvaluation(openEvaluationClosingRes.data);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: onCancelClick,
        variant: 'eucalyptus',
        label: loading ? <Loader /> : t(`buttons.saveAndClose`)
      }}
      gridTemplateColumns="50%"
      bodyMaxHeight="70vh"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {t(`evaluationsView.evaluationStatusModal.closingEvaluation`)}
          </H2>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Flex width="100%" justifyContent="flex-end">
          <Button
            variant="eucalyptus"
            onClick={() => {
              setIsAddingRowVisible(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.addPerson')}
          </Button>
        </Flex>
        <ClosingTable
          closingIds={closingIds}
          setClosingIds={(ids) => setValue('closing_ids', ids)}
          closings={selectedEvaluation?.evaluations_closings || []}
          authorId={watch('author_id')}
          leaderId={watch('leader_id')}
          teamLeaderId={watch('team_leader_id')}
          teamManagerId={watch('team_manager_id')}
          usersList={usersList}
          userFunctionsList={userFunctionsList}
          participationsAttributes={
            watch('evaluation_participations_attributes') || []
          }
          handleClose={handleCloseEvaluation}
          handleOpen={handleOpenEvaluation}
          isAddingRowVisible={isAddingRowVisible}
          setIsAddingRowVisible={setIsAddingRowVisible}
        />
      </Flex>
    </Modal>
  );
};
