import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { TableHeader } from 'views/AdministrationView/views/AuditsView/components/AuditFormsList/AuditFormsList.styled';
import { TableCell } from '../../../../AuditFormForm.styled';
import { useState } from 'react';
import { IPostAuditFormRequest } from 'types/audits';
import { useFormContext } from 'react-hook-form';
import MDEditor from '@uiw/react-md-editor';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapLocationDot,
  faPencil,
  faRankingStar,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/_form';
import { P } from 'components/Typography/Typography';

const StyledMDEditor = styled(MDEditor)`
  width: 100%;
`;

interface Props extends IGenericModal {
  prefix: string;
  closeModal: () => void;
}

export const CriterionDetailsModal = ({
  isOpen,
  onCancelClick,
  closeModal,
  prefix
}: Props) => {
  const { setValue, watch } =
    useFormContext<IPostAuditFormRequest['audit_form']>();

  const [content, setContent] = useState({
    scale_description: watch(
      `${prefix}scale_description` as 'criteria_attributes.0.scale_description'
    ),
    tips: watch(`${prefix}tips` as 'criteria_attributes.0.tips')
  });
  const [showEditor, setShowEditor] = useState(false);

  const { t } = useTranslation();

  return (
    <Modal
      gridTemplateColumns="70%"
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('administrationAuditsView.addCiterionDetails')}
      mainButton={{
        action: () => {
          Object.keys(content).map((key) =>
            setValue(
              `${prefix}${key}` as
                | 'criteria_attributes.0.scale_description'
                | 'criteria_attributes.0.tips',
              content[key as 'scale_description' | 'tips']
            )
          );
          closeModal();
        },
        variant: 'eucalyptus',
        label: t('buttons.save')
      }}
    >
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" mt={1} mb={4}>
          <Flex gap="15px">
            <P variant="h3">
              {watch(`${prefix}nr` as 'criteria_attributes.0.nr')}
            </P>
            <P variant="h3">
              {watch(`${prefix}content` as 'criteria_attributes.0.content')}
            </P>
          </Flex>

          <Button
            variant="grey"
            icon={<FontAwesomeIcon icon={showEditor ? faX : faPencil} />}
            onClick={() => setShowEditor((prevShowEditor) => !prevShowEditor)}
          />
        </Flex>

        <Flex>
          <TableHeader width="50%" gap="10px">
            <FontAwesomeIcon icon={faRankingStar} size="2x" />

            {t('administrationAuditsView.ratingScale')}
          </TableHeader>
          <TableHeader width="50%" gap="10px">
            <FontAwesomeIcon icon={faMapLocationDot} size="2x" />

            {t('administrationAuditsView.tipsToDoEvaluation')}
          </TableHeader>
        </Flex>

        <Flex>
          <TableCell width="50%" data-color-mode="light">
            <StyledMDEditor
              hideToolbar={!showEditor}
              preview={showEditor ? 'edit' : 'preview'}
              value={content.scale_description}
              onChange={(newValue) => {
                setContent((prevContent) => ({
                  ...prevContent,
                  scale_description: newValue
                }));
              }}
            />
          </TableCell>
          <TableCell width="50%" data-color-mode="light">
            <StyledMDEditor
              hideToolbar={!showEditor}
              preview={showEditor ? 'edit' : 'preview'}
              value={content.tips}
              onChange={(newValue) => {
                setContent((prevContent) => ({
                  ...prevContent,
                  tips: newValue
                }));
              }}
            />
          </TableCell>
        </Flex>
      </Flex>
    </Modal>
  );
};
