export const formatDate = (d: Date, char?: string) => d ? `${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${d.getFullYear()}` : '';
export const formatDateConstructable = (d: Date, char?: string) => d ? `${d.getFullYear()}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}` : '';

type typeToCompare = '<' | '>' | '<='

export const compareDates = (date1: Date, date2: Date, type?: typeToCompare) => {
  if (type === '<') return date1.setHours(0, 0, 0, 0) < date2.setHours(0, 0, 0, 0)
  if (type === '>') return date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0)
  if (type === '<=') return date1.setHours(0, 0, 0, 0) <= date2.setHours(0, 0, 0, 0)
  return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)
}
