import { Flex } from 'components/Flex';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import styled from 'styled-components';
import { FieldLabel } from '../FieldLabel';
import rgbHex from 'rgb-hex';

const Wrapper = styled(Flex)`
  position: relative;
`;

const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  borderradius: 1px;
  boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 10;
  padding-left: 280px;
`;

const Color = styled.div<{ color: string; error?: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background: ${({ color }) => color};
  ${({ error, theme }) =>
    error
      ? `
      border: 0.5px solid ${theme.palette.accent.red};
      box-shadow: 0 0 0 3px ${theme.palette.accent.lightRed};
    `
      : 'border: 0.5px solid black;'}
`;

const StyledLabel = styled(FieldLabel)`
  text-align: center;
`;

interface Props {
  label?: string;
  controlledDisplayColorPicker?: string;
  onChange?: (color: string) => void;
  error?: boolean;
}

export const ColorPicker = ({
  label,
  controlledDisplayColorPicker,
  onChange,
  error
}: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('orange');

  const handleClick = () => {
    setDisplayColorPicker((prevDisplayColorPicker) => !prevDisplayColorPicker);
  };

  const handleChange = (c: ColorResult) => {
    const newColor = '#' + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a);

    !controlledDisplayColorPicker && setColor(newColor);
    onChange?.(newColor);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => {
    setDisplayColorPicker(false);
  });

  useEffect(() => {
    controlledDisplayColorPicker && setColor(controlledDisplayColorPicker);
  }, [controlledDisplayColorPicker]);

  return (
    <Wrapper flexDirection="column" alignItems="center" justifyContent="center">
      {label && <StyledLabel>{t(label)}</StyledLabel>}
      <Swatch onClick={handleClick}>
        <Color color={color} error={error} />
      </Swatch>
      {displayColorPicker ? (
        <Popover>
          <div ref={wrapperRef}>
            <SketchPicker color={color} onChange={handleChange} />
          </div>
        </Popover>
      ) : null}
    </Wrapper>
  );
};
