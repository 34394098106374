import { Flex, Line } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H3 } from 'components/Typography/Typography';
import { Button, SwitchButton, TextareaField } from 'components/_form';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IOption } from 'components/_form/Select/Select';
import { AuditHeadingField } from './components/AuditHeadingField/AuditHeadingField';
import { IAuditForm, IPostAuditFormRequest } from 'types/audits';
import { useNavigate } from 'react-router-dom';
import { AuditCriteria } from './components/AuditCriteria/AuditCriteria';
import { getRatingScales } from 'api/ratingScales';
import { IRatingScale } from 'types/ratingScales';
import { BasicSettings } from './components/BasicSettings/BasicSettings';
import { AdditionalSettings } from './components/AdditionalSettings/AdditionalSettings';
import { AuditFormPreview } from './components/AuditFormPreview/AuditFormPreview';
import { auditFormSchema } from 'validation/auditFormSchemas';
import { yupResolver } from '@hookform/resolvers/yup';

export interface IRatingScaleOption extends IOption {
  buttons: IRatingScale['buttons'];
  button_type: IRatingScale['button_type'];
}

interface Props {
  handleSubmitAuditForm: (data: IPostAuditFormRequest['audit_form']) => {};
  defaultValues: Partial<IPostAuditFormRequest['audit_form']>;
  selectedAuditForm?: IAuditForm;
  setSelectedAuditForm: Dispatch<SetStateAction<IAuditForm | undefined>>;
  showCopyButton?: boolean;
}

export const AuditFormForm: React.FC<Props> = ({
  handleSubmitAuditForm,
  defaultValues,
  selectedAuditForm,
  setSelectedAuditForm,
  showCopyButton
}) => {
  const { t } = useTranslation();
  const [ratingScales, setRatingScales] = useState<IRatingScale[]>([]);

  const navigate = useNavigate();

  const methods = useForm<IPostAuditFormRequest['audit_form']>({
    defaultValues,
    resolver: yupResolver(auditFormSchema)
  });

  const { control, handleSubmit, register, watch, setValue } = methods;

  const selectedRatingScale = ratingScales.find(
    ({ id }) => id.toString() === watch('rating_scale_id')?.toString()
  );

  const onSubmit = handleSubmit(handleSubmitAuditForm);

  const loadOptions = useCallback(async () => {
    const ratingScalesRes = await getRatingScales({});

    setRatingScales(ratingScalesRes.data);
  }, []);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Flex minHeight="400px" flexWrap="wrap">
          <CustomTabs selectedTabClassName="is-selected">
            <CustomTabList>
              <Flex flexWrap="wrap">
                <CustomTab>{t('administrationAuditsView.designing')}</CustomTab>
                <CustomTab>{t('administrationAuditsView.preview')}</CustomTab>
              </Flex>
            </CustomTabList>
            <CustomTabPanel>
              <BasicSettings
                ratingScales={ratingScales}
                selectedAuditForm={selectedAuditForm}
                setSelectedAuditForm={setSelectedAuditForm}
                showCopyButton={showCopyButton}
              />

              <AdditionalSettings />

              <H3 variant="h3" color="coloured">
                {t('administrationAuditsView.auditHeading')}
              </H3>
              <Line />

              <Flex
                flexDirection={['column', 'row']}
                justifyContent="space-between"
                gap="10px"
              >
                {Array.from(Array(4), (e, i) => {
                  return (
                    <AuditHeadingField
                      key={`AuditHeadingField-${i + 1}`}
                      fieldNumber={i + 1}
                      register={register}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                    />
                  );
                })}
              </Flex>

              <H3 variant="h3" color="coloured" mt={3}>
                {t('administrationAuditsView.auditCriteria')}
              </H3>
              <Line />
              <Flex mt={3} flexDirection="column">
                <Flex gap="15px" width="70%" alignItems="start">
                  <SwitchButton />
                  <TextareaField
                    {...register('introduction')}
                    rows={4}
                    placeholder={
                      t(
                        'administrationAuditsView.auditIntroductionInsertText'
                      ) || ''
                    }
                  />
                </Flex>

                <AuditCriteria ratingScale={selectedRatingScale} />
              </Flex>
            </CustomTabPanel>

            <CustomTabPanel>
              <AuditFormPreview ratingScale={selectedRatingScale} />
            </CustomTabPanel>

            <Flex justifyContent="end" mt={3} gap="10px">
              <Button
                type="button"
                variant="grey"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button type="submit" variant="eucalyptus">
                {t('common.add')}
              </Button>
            </Flex>
          </CustomTabs>
        </Flex>
      </form>
    </FormProvider>
  );
};
