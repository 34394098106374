import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Checkbox, Select, TextField } from 'components/_form';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IPostAuditFormRequest } from 'types/audits';

const prefixDict: { [key: number]: 'first' | 'second' | 'third' | 'fourth' } = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth'
};

interface Props {
  fieldNumber: number;
  register: UseFormRegister<IPostAuditFormRequest['audit_form']>;
  control: Control<IPostAuditFormRequest['audit_form']>;
  watch: UseFormWatch<IPostAuditFormRequest['audit_form']>;
  setValue: UseFormSetValue<IPostAuditFormRequest['audit_form']>;
}

export const AuditHeadingField: React.FC<Props> = ({
  fieldNumber,
  register,
  control,
  watch,
  setValue
}) => {
  if (!Object.keys(prefixDict).includes(fieldNumber.toString())) {
    return <></>;
  }

  const { t } = useTranslation();

  const contentOptions = [
    {
      label: t('administrationAuditsView.formName') || '',
      value: 'form_name'
    },
    {
      label: t('administrationAuditsView.area') || '',
      value: 'area'
    },
    {
      label: t('administrationAuditsView.team') || '',
      value: 'team'
    },
    {
      label: t('administrationAuditsView.shift') || '',
      value: 'shift'
    },
    {
      label: t('administrationAuditsView.practice') || '',
      value: 'practice'
    },
    {
      label: t('administrationAuditsView.process') || '',
      value: 'process'
    },
    {
      label: t('administrationAuditsView.audit_name') || '',
      value: 'audit_name'
    }
  ];

  return (
    <Flex flexDirection="column" width={['100%', '25%']}>
      <Flex alignItems="center">
        <Checkbox
          checked={
            !!(
              watch(`${prefixDict[fieldNumber]}_header_name`) ||
              watch(`${prefixDict[fieldNumber]}_header_value`)
            )
          }
          onChange={(e) => {
            if (!e.target.checked) {
              setValue(`${prefixDict[fieldNumber]}_header_name`, '');
              setValue(`${prefixDict[fieldNumber]}_header_value`, '');
            }
          }}
        />

        <P variant="body" fontWeight={700}>
          {t('administrationAuditsView.field')} {fieldNumber}
        </P>
      </Flex>
      <Flex ml="30px" alignItems="end" gap="10px">
        <TextField
          label={t('administrationAuditsView.name')}
          {...register(`${prefixDict[fieldNumber]}_header_name`)}
          value={watch(`${prefixDict[fieldNumber]}_header_name`)}
        />

        <Controller
          control={control}
          name={`${prefixDict[fieldNumber]}_header_value`}
          render={({ field: { onChange, value } }) => (
            <Select
              menuPlacement="top"
              withoutMargin
              label={t('administrationAuditsView.content')}
              selectedOptions={contentOptions.find(
                (contentOption) => value === contentOption.value
              )}
              options={contentOptions}
              onChange={(selectedOption) => {
                !Array.isArray(selectedOption) &&
                  onChange(selectedOption.value);
              }}
            />
          )}
        />
      </Flex>
    </Flex>
  );
};
