import { Flex } from 'components';
import { H2 } from 'components/Typography/Typography';
import styled from 'styled-components';

export const SubTitle = styled(H2)`
  color: ${({ theme }) => theme.palette.neutral.lightGrey};
`;

export const LeftPanel = styled(Flex)<{
  highlight: boolean;
  margin?: string;
  width?: string;
  height?: string;
}>`
  background-color: ${({ theme, highlight }) =>
    highlight ? theme.palette.neutral.veryLight : theme.palette.neutral.white};
  margin: ${({ margin }) => margin || '0 50px 20px 0'};
  width: ${({ width }) => width || '230px'};
  height: ${({ height }) => height || '100px'};
  flex-direction: column;
`;

export const FileLinkContainer = styled(Flex)<{ isHover?: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightBlue};
  box-shadow: 1px 1px ${({ theme }) => theme.palette.neutral.veryLight};
  ${({ isHover }) =>
    isHover && { '&:hover': { backgroundColor: '#e6f2ff' }, cursor: 'pointer' }}
`;
