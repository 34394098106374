import { Flex } from 'components';
import { H2 } from 'components/Typography/Typography';
import {
  FileLinkContainer,
  SubTitle,
  LeftPanel as LeftPanelStyled
} from './LeftPanel.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from 'styles/theme';
import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { faFileWord } from '@fortawesome/free-regular-svg-icons';
import { Data, SetTitleAndFile } from '../CriteriaModal';

interface Props {
  data: Data;
  setTitleAndFile: ({
    value,
    fileName,
    fileType,
    activeId
  }: SetTitleAndFile) => void;
  activeId?: number;
}

export const LeftPanel = ({
  data: { title, subTitle, mainTitle, file, fileType, number },
  setTitleAndFile,
  activeId
}: Props) => {
  let icon = faFileImage;

  switch (fileType) {
    case 'doc':
      icon = faFileWord;
      break;
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'mov':
      icon = faFileVideo;
      break;
    case 'img':
      icon = faFileImage;
      break;
    case 'excel':
      icon = faFileExcel;
  }

  return (
    <LeftPanelStyled
      highlight={activeId === number}
      onClick={() =>
        setTitleAndFile({
          value: mainTitle,
          fileName: file,
          fileType,
          activeId: number
        })
      }
    >
      <FileLinkContainer isHover>
        <Flex
          height={100}
          flexDirection="column"
          paddingTop="10px"
          style={{ width: '25%' }}
        >
          <FontAwesomeIcon icon={icon} size="3x" />
        </Flex>
        <Flex
          flexDirection="column"
          paddingTop="10px"
          justifyContent="flex-start"
          style={{ width: '75%' }}
        >
          <H2 variant="h3">{title}</H2>
          <SubTitle variant="body">{subTitle}</SubTitle>
        </Flex>
      </FileLinkContainer>
    </LeftPanelStyled>
  );
};
