import { Box, Flex } from 'components';
import { ImagesPicker } from 'components/ImagesPicker/ImagesPicker';
import { P } from 'components/Typography/Typography';
import {
  Button,
  Select,
  SwitchButton,
  TextareaField,
  TextField
} from 'components/_form';
import { t } from 'i18next';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AreaAccordionItem } from 'types/area';
import { getFile } from 'utilities/files';

interface Props {
  data: AreaAccordionItem;
}

export const AreaDetailsForm: React.FC<Props> = ({ data }) => {
  const { register, control, reset, watch, setValue } = useForm();

  const loadData = async () => {
    const { image_urls } = data;
    const images = image_urls
      ? (await Promise.all(
          image_urls.map((image_url) => getFile(image_url))
        ).then((values) => {
          return values;
        })) || []
      : [];

    reset({
      ...data,
      images
    });
  };

  useEffect(() => {
    loadData();
  }, [data]);

  return (
    <Flex flexWrap="wrap">
      <Box width={['100%', '50%']}>
        <ImagesPicker
          images={watch('images') || []}
          setImages={(images: File[]) => {
            setValue('images', images);
          }}
          disabled
        />
      </Box>
      <Flex width={['100%', '50%']} flexDirection="column" pl={4}>
        <Flex width="100%" justifyContent="space-between" mb={2}>
          <Flex flexDirection="column">
            <P mb={1} variant="body">
              {t('administration.active?')}
            </P>
            <SwitchButton value={data.state === 'active'} />
          </Flex>

          <Box>
            <Button variant="red">{t('administration.deleteArea')}</Button>
          </Box>
        </Flex>

        <TextField {...register('id_code')} label="administration.areaCode" />
        <TextField {...register('name')} label="profile.name" />
        <TextareaField
          {...register('description')}
          label={t('administration.description') as string}
          rows={10}
        />
        <Box mt={2}>
          <Controller
            control={control}
            name={`owner`}
            render={({ field: { value } }) => (
              <Select
                label="administration.areaOwner"
                options={[]}
                selectedOptions={{
                  label: value?.username,
                  value: String(value?.id)
                }}
              />
            )}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
