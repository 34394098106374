import { IOption } from 'components/_form/Select/Select';

export const makeUserOption = ({
  usersList,
  userFunctionsList,
  userId,
  functionId,
  functionName
}: {
  usersList: IOption[];
  userFunctionsList: IOption[];
  userId?: string;
  functionId?: string;
  functionName?: string | null;
}) => {
  const foundUser = usersList.find(
    ({ value }) => userId?.toString() === value.toString()
  );

  return (
    foundUser && {
      ...foundUser,
      role:
        functionName ||
        userFunctionsList.find(
          ({ value }) => functionId?.toString() === value.toString()
        )?.value
    }
  );
};
