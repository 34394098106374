import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 10px 0 0 0;

  * {
    font-size: 14px;
  }
`;
