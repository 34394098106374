import { IRealizationProgress } from 'types/realizationProgresses';
import {
  IRealizationStatus,
  IRealizationStatusAttribute
} from 'types/realizationStatuses';

export const findLastRealizationProgressLetter = (
  realizationProgress: IRealizationProgress,
  realizationStatusAttributes: (
    | IRealizationStatusAttribute
    | IRealizationStatus
  )[]
) => {
  let result;

  realizationProgress.kind.split('').map((letter) => {
    if (
      realizationStatusAttributes.find((attribute) => attribute.kind === letter)
    ) {
      result = letter;
    }
  });

  return result;
};
