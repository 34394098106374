import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './TaskPrioritiesFilter.styled';
import { Select } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { IOption } from '../../../../../../../components/_form/Select/Select';
import { getTaskPriorityDegrees } from 'api/tasks';
import { Flex } from 'components';

export const TaskPrioritiesFilter: React.FC = () => {
  const [priorities, setPriorities] = useState<IOption[]>([]);

  const { t } = useTranslation();
  const {
    filters: { taskPriorities },
    setFilters
  } = useTaskSidebarContext();

  const selected = () =>
    priorities.filter((el) => taskPriorities.value.includes(el.value));

  const handleOnChange = (val: IOption[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        taskPriorities: {
          label:
            val.length > 0
              ? t('tasks.taskPriorities') +
                ' - ' +
                val.map((el) => el.label).join(', ')
              : '',
          value: val.map((el) => el.value)
        }
      };
    });
  };

  const loadOptions = async () => {
    const { data: priorities } = await getTaskPriorityDegrees();

    const prioritiesOptions = priorities.map(({ name, id, image_url }) => ({
      value: String(id),
      label: (
        <Flex gap="10px" alignItems="center">
          <img
            width="20px"
            height="20px"
            src={process.env.REACT_APP_API_URL + image_url}
          />
          {name}
        </Flex>
      )
    }));

    setPriorities(prioritiesOptions);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <Wrapper>
      <Select
        isMulti
        options={priorities}
        selectedOptions={selected()}
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};
