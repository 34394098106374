import { Box, Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import {
  FontSizeChangerInput,
  FontSizeChangerWrapper
} from 'views/EvaluationsView/components/EvaluationModal/EvaluationModal.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from '@fortawesome/free-solid-svg-icons';

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { SwitchButton } from 'components/_form';
import { Box as BoxMui } from '@mui/material';
import Slider from '@mui/material/Slider';
import { IDetailedAudit } from 'types/audits';
import { AuditCriteriaTable } from './AuditCriteriaTable';
import AuditCriteriaHeaders from './AuditCriteriaHeaders/AuditCriteriaHeaders';
import { AuditSummaryStats } from 'components/_audit/AuditSummaryStats/AuditSummaryStats';
import { flattenNestedCriteria } from 'utilities/auditForm/flattenNestedCriteria';
import { parseGroupsToRadarChart } from 'utilities/audit/parseGroupsToRadarChart';

interface Props extends IGenericModal {
  audit: IDetailedAudit | undefined;
  setAudit: Dispatch<SetStateAction<IDetailedAudit | undefined>>;
  onFinishAuditSuccess?: (newAudit: IDetailedAudit) => void;
}

export const AuditCriteriaModal = ({
  isOpen,
  onCancelClick,
  audit,
  setAudit,
  onFinishAuditSuccess
}: Props) => {
  const initValues = useMemo(() => {
    return {
      baseFontSize: 16,
      minFontSize: 16,
      maxFontSize: 22,
      baseLineHeight: 24,
      baseRowHeight: 80,
      lineHeightAddend: 1,
      rowHeightAddend: 6
    };
  }, []);

  const [gradingScaleSwitch, setGradingScaleSwitch] = useState(false);
  const [hintSwitch, setHintSwitch] = useState(false);
  const [fontSize, setFontSize] = useState(initValues.baseFontSize);
  const [textInCellLineHeight, setTextInCellLineHeight] = useState(
    initValues.baseLineHeight
  );
  const [rowHeight, setRowHeight] = useState(initValues.baseRowHeight);

  const { t } = useTranslation();

  const flattenCriteria = useMemo(
    () => [
      ...(audit?.audit_form?.criteria || []),
      ...flattenNestedCriteria(audit?.audit_form?.groups || [])
    ],
    [
      JSON.stringify(audit?.audit_form?.criteria),
      JSON.stringify(audit?.audit_form?.groups)
    ]
  );

  const radarChartData = useMemo(
    () =>
      parseGroupsToRadarChart({
        groups: audit?.audit_form?.groups || [],
        radarChartOrderNumber:
          audit?.audit_form?.radar_chart_order_number || '',
        scores: audit?.scores || [],
        buttons: audit?.used_rating_scale?.buttons || []
      }),
    [
      JSON.stringify(audit?.audit_form?.groups),
      audit?.scores,
      JSON.stringify(audit?.used_rating_scale?.buttons)
    ]
  );

  const changeRowHeight = useCallback(
    (cellLineHeightAddend: number, rowHeightAddend: number) => {
      const { baseLineHeight, baseFontSize } = initValues;
      if (
        textInCellLineHeight === baseLineHeight + (21 - baseFontSize) &&
        cellLineHeightAddend > 0
      )
        rowHeightAddend -= 2;
      else if (
        textInCellLineHeight === baseLineHeight + (22 - baseFontSize) &&
        cellLineHeightAddend < 0
      )
        rowHeightAddend += 2;

      setTextInCellLineHeight(textInCellLineHeight + cellLineHeightAddend);
      setRowHeight(rowHeight + rowHeightAddend);
    },
    [initValues, rowHeight, textInCellLineHeight]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        disableBodySharedStyles={true}
        disableHeaderSharedStyles={true}
        onCancelClick={onCancelClick}
        mainButton={{
          action: onCancelClick,
          variant: 'grey',
          label: t('common.close')
        }}
        gridTemplateColumns="99%"
        bodyMaxHeight={['80vh', '70vh']}
        headerTitle={<AuditCriteriaHeaders audit={audit} />}
        additionalFooter={
          <Flex
            minWidth="650px"
            gap="15px"
            alignItems="center"
            maxHeight="60vh"
            display={['none', 'flex']}
          >
            <FontSizeChangerWrapper>
              <Box mr={1} alignItems="center" display="flex">
                <FontAwesomeIcon icon={faFont} />
              </Box>
              <BoxMui width={50} style={{ display: 'flex' }}>
                <Slider
                  size="small"
                  value={fontSize}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  min={initValues.minFontSize}
                  max={initValues.maxFontSize}
                  onChange={(event: Event, newValue: number | number[]) => {
                    const newValueParsed = Number(newValue);
                    if (Number(newValue) > fontSize) {
                      const substractionMultiplier = newValueParsed - fontSize;

                      changeRowHeight(
                        initValues.lineHeightAddend * substractionMultiplier,
                        initValues.rowHeightAddend * substractionMultiplier
                      );
                    } else {
                      const substractionMultiplier = newValueParsed - fontSize;

                      changeRowHeight(
                        initValues.lineHeightAddend * substractionMultiplier,
                        initValues.rowHeightAddend * substractionMultiplier
                      );
                    }
                    setFontSize(newValue as number);
                  }}
                />
              </BoxMui>
              <FontSizeChangerInput
                type="number"
                value={fontSize}
                onChange={(e) => {
                  const newFontSize = parseInt(e.target.value);
                  if (newFontSize <= 22 && newFontSize >= 16) {
                    if (newFontSize > fontSize) {
                      const substractionMultiplier = newFontSize - fontSize;
                      changeRowHeight(
                        initValues.lineHeightAddend * substractionMultiplier,
                        initValues.rowHeightAddend * substractionMultiplier
                      );
                    } else {
                      const substractionMultiplier = fontSize - newFontSize;
                      changeRowHeight(
                        -initValues.lineHeightAddend * substractionMultiplier,
                        -initValues.rowHeightAddend * substractionMultiplier
                      );
                    }
                    setFontSize(newFontSize);
                  }
                }}
              />
            </FontSizeChangerWrapper>

            <P variant="body">{t('evaluationsView.alwaysShow')}:</P>

            <Flex alignItems="center" gap="10px">
              <P variant="body" textTransform="lowercase">
                {t('evaluationsView.gradingScale')}:
              </P>

              <SwitchButton
                value={gradingScaleSwitch}
                onChange={setGradingScaleSwitch}
              />
            </Flex>

            <Flex alignItems="center" gap="10px">
              <P variant="body" textTransform="lowercase">
                {t('evaluationsView.hints')}:
              </P>
              <SwitchButton
                value={hintSwitch}
                onChange={(value) => {
                  setHintSwitch(value);
                }}
              />
            </Flex>

            <Flex alignItems="center" gap="10px">
              <P variant="body" textTransform="lowercase">
                {t('evaluationsView.standards')}:
              </P>

              <SwitchButton
                value={gradingScaleSwitch}
                onChange={setGradingScaleSwitch}
              />
            </Flex>
          </Flex>
        }
      >
        <AuditCriteriaTable
          isFullData
          audit={audit}
          setAudit={setAudit}
          rowHeight={rowHeight}
          gradingScaleSwitch={gradingScaleSwitch}
          hintSwitch={hintSwitch}
          fontSize={fontSize}
          textInCellLineHeight={textInCellLineHeight}
          flattenCriteria={flattenCriteria}
          onFinishAuditSuccess={onFinishAuditSuccess}
        />

        <Flex p={3}>
          <AuditSummaryStats
            radarChartData={
              Object.keys(radarChartData).length > 2
                ? radarChartData
                : undefined
            }
            scores={audit?.scores || []}
            criteria={flattenCriteria}
            ratingScale={audit?.used_rating_scale}
            audit={audit}
          />
        </Flex>
      </Modal>
    </>
  );
};
