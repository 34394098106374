import { Flex } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faLightbulb,
  faMapLocationDot,
  faRankingStar,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, TextField } from 'components/_form';
import { IRatingScale } from 'types/ratingScales';
import { Controller, useFormContext } from 'react-hook-form';
import { IPostAuditFormRequest } from 'types/audits';
import { TableCell } from '../../../AuditFormForm.styled';
import { CriterionDetailsModal } from './CriterionDetailsModal/CriterionDetailsModal';
import { useEffect, useState } from 'react';
import { theme } from 'styles';
import { useTranslation } from 'react-i18next';

interface Props {
  ratingScaleButtons?: IRatingScale['buttons'];
  prefix?: string;
  indentNumber: number;
  removeCriteriaAttributes: () => void;
  defaultNumber: number;
}

export const CriteriaRow: React.FC<Props> = ({
  ratingScaleButtons,
  prefix = '',
  indentNumber,
  removeCriteriaAttributes,
  defaultNumber
}) => {
  const [isCriterionDetailsModalOpen, setIsCriterionDetailsModalOpen] =
    useState(false);

  const { register, control, watch, setValue } =
    useFormContext<IPostAuditFormRequest['audit_form']>();

  const { t } = useTranslation();

  useEffect(() => {
    setValue(
      `${prefix}.nr` as 'criteria_attributes.0.nr',
      watch(
        `${prefix}.omit_in_numbering` as 'criteria_attributes.0.omit_in_numbering'
      )
        ? ''
        : watch(
            `${prefix}.set_number_manually` as 'criteria_attributes.0.set_number_manually'
          )
        ? watch(`${prefix}.nr` as 'criteria_attributes.0.nr')
        : `${defaultNumber + 1}`
    );
  }, [
    defaultNumber,
    prefix,
    setValue,
    watch,
    watch(
      `${prefix}.omit_in_numbering` as 'criteria_attributes.0.omit_in_numbering'
    ),
    watch(
      `${prefix}.set_number_manually` as 'criteria_attributes.0.set_number_manually'
    )
  ]);

  return (
    <Flex mt={1} flexDirection="column" alignItems="end" width="100%">
      <Flex justifyContent="end" width="100%">
        <TableCell width="3%" justifyContent="center">
          <FontAwesomeIcon icon={faGripVertical} size="2x" color="grey" />
        </TableCell>
        <TableCell width="7%" justifyContent="center">
          <Controller
            control={control}
            name={`${prefix}.nr` as 'criteria_attributes.0.nr'}
            render={({ field: { value, onChange } }) => (
              <TextField
                readOnly={
                  !watch(
                    `${prefix}.set_number_manually` as 'criteria_attributes.0.set_number_manually'
                  ) ||
                  watch(
                    `${prefix}.omit_in_numbering` as 'criteria_attributes.0.omit_in_numbering'
                  )
                }
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Flex flexDirection="column" gap="3px" ml={1}>
            <Controller
              control={control}
              name={
                `${prefix}.set_number_manually` as 'criteria_attributes.0.set_number_manually'
              }
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  sizeVariant="small"
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  id={`${prefix}-setNrManuallyTooltip`}
                  tooltip={t('administrationAuditsView.setNrManually') || ''}
                />
              )}
            />
            <Controller
              control={control}
              name={
                `${prefix}.omit_in_numbering` as 'criteria_attributes.0.omit_in_numbering'
              }
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  sizeVariant="small"
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  id={`${prefix}-omitInNumberingTooltip`}
                  tooltip={t('administrationAuditsView.omitInNumbering') || ''}
                />
              )}
            />
          </Flex>
        </TableCell>
        <TableCell
          width={`${66 - indentNumber * 4}%`}
          justifyContent="space-between"
        >
          <Flex gap="10px" flexGrow={1}>
            <TextField
              {...register(
                `${prefix}.content` as 'criteria_attributes.0.content'
              )}
            />
          </Flex>
          <Controller
            control={control}
            name={
              `${prefix}.blocked_rating_button_ids` as 'criteria_attributes.0.blocked_rating_button_ids'
            }
            render={({ field: { value, onChange } }) => (
              <Flex
                width={`${(ratingScaleButtons?.length || 1) * 50}px`}
                justifyContent="center"
                mr="-6px"
              >
                {ratingScaleButtons?.map((ratingScaleButton) => (
                  <Flex
                    width="100%"
                    justifyContent="center"
                    key={`CiteriaRow-${indentNumber}-${ratingScaleButton.id}`}
                  >
                    <Checkbox
                      checked={!value?.includes(Number(ratingScaleButton.id))}
                      onChange={(e) => {
                        if (e.target.checked) {
                          onChange(
                            value?.filter(
                              (id: number) => id !== ratingScaleButton.id
                            )
                          );
                        } else {
                          onChange([...(value || []), ratingScaleButton.id]);
                        }
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
            )}
          />
        </TableCell>

        <TableCell width="5%" justifyContent="center">
          <TextField
            type="number"
            {...register(`${prefix}.weight` as 'criteria_attributes.0.weight')}
          />
        </TableCell>

        <TableCell width="5%" justifyContent="center">
          <Button
            variant="secondary"
            transparent
            onClick={() => {
              setIsCriterionDetailsModalOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faRankingStar}
              size="2x"
              color={
                watch(
                  `${prefix}scale_description` as 'criteria_attributes.0.scale_description'
                )
                  ? theme.palette.accent.yellow
                  : 'grey'
              }
            />
          </Button>
        </TableCell>
        <TableCell width="5%" justifyContent="center">
          <Button
            variant="secondary"
            transparent
            onClick={() => {
              setIsCriterionDetailsModalOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faMapLocationDot}
              size="2x"
              color={
                watch(`${prefix}tips` as 'criteria_attributes.0.tips')
                  ? theme.palette.accent.yellow
                  : 'grey'
              }
            />
          </Button>
        </TableCell>
        <TableCell width="5%" justifyContent="center">
          <Button
            variant="secondary"
            transparent
            onClick={() => {
              setIsCriterionDetailsModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faLightbulb} size="2x" color="grey" />
          </Button>
        </TableCell>

        <TableCell width="4%" justifyContent="end">
          <Button
            opacity="60%"
            variant="red"
            icon={<FontAwesomeIcon icon={faTrashCan} size="xl" />}
            onClick={removeCriteriaAttributes}
          />
        </TableCell>
      </Flex>

      <CriterionDetailsModal
        isOpen={isCriterionDetailsModalOpen}
        onCancelClick={() => {
          setIsCriterionDetailsModalOpen(false);
        }}
        closeModal={() => {
          setIsCriterionDetailsModalOpen(false);
        }}
        prefix={prefix}
      />
    </Flex>
  );
};
