import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Line } from 'components';
import { H2, H3, P } from 'components/Typography/Typography';
import {
  Button,
  Checkbox,
  Radio,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from 'styles/theme';

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(6, minmax(min-content, 1fr));
  align-items: center;
  justify-items: center;
  gap: 8px;

  @media ${device.tablet} {
    grid-template-columns: repeat(1, minmax(min-content, 1fr));
    justify-items: start;
  }
`;

export const MailNotificationsForm = () => {
  const { t } = useTranslation();
  const { control } = useForm();

  return (
    <>
      <H2 variant="h2" color="coloured">
        {t('profile.overallSettings')}
      </H2>
      <Line />

      <Box pr={5}>
        <SwitchButton label="profile.sendMailAfterSystemNotifications" />
      </Box>

      <Box my={3}>
        <Radio label="profile.sendMailStraightAfter" />
      </Box>
      <Flex justifyContent="space-between" alignItems="center" pr={5}>
        <Radio label="profile.groupNotificationsAndSendEvery" />
        <TextField width="60px" type="number" />
      </Flex>

      <Flex
        pt={3}
        pr={2}
        justifyContent={['column', 'space-between']}
        alignItems="start"
        flexWrap="wrap"
        flexDirection={['column', 'row']}
        gap="8px"
      >
        <P variant="body">{t('profile.sendTasksStatusSummary')}: </P>
        <Radio label="profile.never" />
        <Flex flexDirection="column">
          <Radio label="profile.everyday" />
          <Flex mt={3} alignItems="center">
            <P mr={2} variant="body">
              {t('profile.by')}
            </P>
            <TextField width="60px" />
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Radio label="profile.everyWeek" />
          <Flex mt={3} alignItems="center">
            <P mr={2} variant="body">
              {t('profile.on')}
            </P>
            <Box width="100%">
              <Select options={[]} />
            </Box>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Radio label="profile.everyMonth" />
          <Flex mt={3} alignItems="center">
            <P mr={2} variant="body">
              {t('profile.ds.')}
            </P>
            <Box width="100%">
              <Select options={[]} />
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <H2 variant="h2" color="coloured">
        {t('profile.notificationsAboutplannedTasks')}
      </H2>
      <Line />

      <Box pr={5}>
        <SwitchButton label="profile.sendMailFutureNotifications" />
      </Box>

      <Grid pl={4} pt={2} pr={5}>
        <Controller
          control={control}
          name={`beforeStart`}
          render={({ field: { onChange, value } }) => (
            <Box my={2}>
              <Checkbox
                label="profile.beforeStart"
                checked={!!value}
                onChange={(val) => {
                  onChange(val.target.checked ? 'field.id' : false);
                }}
              />
            </Box>
          )}
        />
        <TextField width="60px" type="number" />
        <Radio label="profile.minutes" />
        <Radio label="profile.hours" />
        <Radio label="profile.days" />
        <P variant="body">{t('profile.before')}</P>
        <Controller
          control={control}
          name={`beforeFinish`}
          render={({ field: { onChange, value } }) => (
            <Box my={2}>
              <Checkbox
                label="profile.beforeFinish"
                checked={!!value}
                onChange={(val) => {
                  onChange(val.target.checked ? 'field.id' : false);
                }}
              />
            </Box>
          )}
        />
        <TextField width="60px" type="number" />
        <Radio label="profile.minutes" />
        <Radio label="profile.hours" />
        <Radio label="profile.days" />
        <P variant="body">{t('profile.before')}</P>
      </Grid>

      <Box pr={5} pt={2} pb={4}>
        <SwitchButton label="profile.notifyAboutPricesAndAuditsInMyAreas" />
      </Box>

      <H2 variant="h2" color="coloured">
        {t('profile.mailNotificationsAboutPDCATasksStatus')}
      </H2>
      <Line />

      <H3 variant="h3" mb={2}>
        {t('profile.sendMailNotificationsWhenPDCATasksStatusChangedWhichIAm')}:
      </H3>
      <Flex overflow="auto">
        <Flex flexDirection="column" mr={6} mt={3}>
          <P variant="body" mt={3}>
            {t('profile.byTheAuthor')}
          </P>
          <P variant="body" mt={3}>
            {t('profile.responsibleBy')}
          </P>
          <P variant="body" mt={3}>
            {t('profile.participantBy')}
          </P>
        </Flex>
        <Flex width="100%" flexDirection="column">
          <Flex width="100%" justifyContent="space-between">
            <P variant="body">{t('profile.StatusP')}</P>
            <P variant="body">{t('profile.StatusD')}</P>
            <P variant="body">{t('profile.StatusC')}</P>
            <P variant="body">{t('profile.StatusA')}</P>
          </Flex>
          <Flex width="100%" justifyContent="space-between" mt={2}>
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
          </Flex>
          <Flex width="100%" justifyContent="space-between" mt={2}>
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
          </Flex>
          <Flex width="100%" justifyContent="space-between" mt={2}>
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
            <SwitchButton />
          </Flex>
        </Flex>
      </Flex>

      <Line />

      <Flex justifyContent="end">
        <Button variant="eucalyptus">
          <Flex>
            <Box mr={2}>
              <FontAwesomeIcon icon={faRotate} />
            </Box>
            {t('profile.recoverDefault')}
          </Flex>
        </Button>
      </Flex>
    </>
  );
};
