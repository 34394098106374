import {
  deleteEvaluation,
  getEvaluation,
  patchEvaluations
} from 'api/evaluations';
import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { SwitchButton } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import { IOption as OptionForSelect } from 'components/_form/NestedSelect/NestedSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CreateEvaluationBody } from 'types/evaluations';
import { FormEvaluation } from './FormEvaluation/FormEvaluation';
import { Loader } from 'components/Loader/Loader';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { iso8601HourAndHalf } from 'constants/iso8601';
import { onlyUniqueArray } from 'utilities/onlyUniqueArray';

export type FormData = Omit<
  CreateEvaluationBody,
  'evaluation_participations_attributes'
>;

export type FormDataControl = FormData & {
  practice_id: IOption;
  version: IOption;
  evaluation_type: IOption;
  evaluation_participations_attributes: {
    value: string;
    label: string | JSX.Element;
    function_id?: string;
  }[];
};

interface Props extends IGenericModal {
  isOpen: boolean;
  onCancelClick: () => void;
}

export const EditEvaluationModal: React.FC<Props> = ({
  isOpen,
  onCancelClick
}) => {
  const [loading, setLoading] = useState(true);
  const [approvalSwitch, setApprovalSwitch] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState<OptionForSelect[]>(
    []
  );

  const {
    handleLoadEvaluations,
    evaluationToEditId,
    evaluationToEdit,
    setEvaluationToEdit
  } = useEvaluationContext();

  const { t } = useTranslation();

  const methods = useForm<FormDataControl>();

  const { handleSubmit, reset } = methods;

  const handleGetEvaluationToEdit = useCallback(
    async (id: number) => {
      setLoading(true);

      const evaluationRes = await getEvaluation(id);

      if ('data' in evaluationRes && evaluationRes.data) {
        setSelectedPractice([
          {
            label: evaluationRes.data.practice.name,
            value: evaluationRes.data.practice.id.toString(10)
          }
        ]);

        setEvaluationToEdit(evaluationRes.data);
      }

      setLoading(false);
    },
    [setEvaluationToEdit]
  );

  const defaultFormValues: FormData = useMemo(() => {
    return {
      realization_date: new Date().toJSON().slice(0, 10).toString(),
      evaluation_type: {
        value: evaluationToEdit?.evaluation_type || 'normal',
        label: evaluationToEdit?.evaluation_type || 'normal'
      },
      version: {
        value: '3.0-GW',
        label: '3.0-GW'
      },
      date: new Date().toJSON().slice(0, 10).toString(),
      name: evaluationToEdit?.name || '',
      description: evaluationToEdit?.description.body || '',
      practice_id:
        evaluationToEdit !== undefined ? evaluationToEdit.practice.id : 1,
      area_id: evaluationToEdit?.area.id.toString() || '',
      group: evaluationToEdit?.group || '',
      related_to_evaluation_id:
        evaluationToEdit?.related_to_evaluation?.id?.toString() || '',
      planned_duration:
        evaluationToEdit?.planned_duration_iso8601 || iso8601HourAndHalf,
      duration: evaluationToEdit?.duration_iso8601 || iso8601HourAndHalf,
      author_id: evaluationToEdit?.author?.id?.toString(),
      leader_id: evaluationToEdit?.leader?.id?.toString(),
      team_leader_id: evaluationToEdit?.team_leader?.id?.toString(),
      team_manager_id: evaluationToEdit?.team_manager?.id?.toString(),
      evaluation_participations_attributes:
        evaluationToEdit?.evaluation_participations?.map(
          (evaluationParticipation) => ({
            value: evaluationParticipation.participant.id,
            label: `${evaluationParticipation.participant.first_name} ${evaluationParticipation.participant.last_name}`,
            function_id: evaluationParticipation.function?.id
          })
        ),
      team_ids: evaluationToEdit?.teams?.map((team) => team.id.toString()),
      notes: evaluationToEdit?.notes || [],
      planned_start_date: evaluationToEdit?.planned_start_date,
      planned_completion_date: evaluationToEdit?.planned_completion_date,
      only_realization: evaluationToEdit?.only_realization,
      start_time: evaluationToEdit?.start_time,
      completion_time: evaluationToEdit?.completion_time,
      closing_ids: onlyUniqueArray(
        [
          ...(evaluationToEdit?.evaluations_closings.map(({ user: { id } }) =>
            id.toString()
          ) || []),
          evaluationToEdit?.author?.id?.toString()
        ].filter((id) => id)
      )
    };
  }, [evaluationToEdit]);

  const handleSubmitEvaluationForm = useCallback(
    async (data: FormDataControl, closeOnSubmit = true) => {
      try {
        const actualEvaluationParticipationsAttributes = evaluationToEdit
          ? [
              // participants included in fetched list and not in selected list are removed
              ...(evaluationToEdit?.evaluation_participations
                ?.filter(
                  (evaluationParticipation) =>
                    !data.evaluation_participations_attributes?.find(
                      (evaluationParticipationAttribute) =>
                        evaluationParticipationAttribute.value.toString() ===
                        evaluationParticipation.participant?.id.toString()
                    )
                )
                .map((evaluationParticipation) => ({
                  id: evaluationParticipation.id,
                  _destroy: 1
                })) || []),
              // participants included in selected list and not in fetched list are removed
              ...(
                data.evaluation_participations_attributes?.filter(
                  (evaluationParticipationAttribute) =>
                    !evaluationToEdit?.evaluation_participations?.find(
                      (evaluationParticipation) =>
                        evaluationParticipationAttribute.value.toString() ===
                        evaluationParticipation.participant.id.toString()
                    )
                ) || []
              ).map((evaluationParticipationAttribute) => ({
                participant_id: evaluationParticipationAttribute.value,
                function_id: evaluationParticipationAttribute.function_id
              })),
              // participants included in both but with different function_id
              ...(
                evaluationToEdit?.evaluation_participations?.filter(
                  (evaluationParticipation) =>
                    data.evaluation_participations_attributes?.find(
                      (evaluationParticipationAttribute) =>
                        evaluationParticipationAttribute.value.toString() ===
                          evaluationParticipation.participant?.id.toString() &&
                        evaluationParticipationAttribute.function_id?.toString() !==
                          evaluationParticipation.function?.id.toString()
                    )
                ) || []
              ).map((evaluationParticipation) => ({
                id: evaluationParticipation.id,
                function_id: data.evaluation_participations_attributes?.find(
                  (evaluationParticipationAttribute) =>
                    evaluationParticipationAttribute.value.toString() ===
                    evaluationParticipation.participant?.id.toString()
                )?.function_id
              }))
            ]
          : data.evaluation_participations_attributes?.map(
              (evaluationParticipationAttribute) => ({
                participant_id: evaluationParticipationAttribute.value,
                function_id: evaluationParticipationAttribute.function_id
              })
            );

        const payload = {
          evaluation: {
            ...data,
            type: 'Evaluation',
            practice_id: Number(selectedPractice[0].value) || data.practice_id,
            evaluation_type: data.evaluation_type.value || data.evaluation_type,
            author_id: JSON.parse(localStorage.getItem('currentUser') as string)
              .id,
            evaluation_participations_attributes:
              actualEvaluationParticipationsAttributes
          }
        };

        const patchEvaluationsRes = await patchEvaluations(
          evaluationToEdit?.id || 1,
          payload
        );
        await handleLoadEvaluations();
        toast.success(t('common.success'));
        if (closeOnSubmit) {
          onCancelClick();
          reset(defaultFormValues);
        }

        if (patchEvaluationsRes.data) {
          return patchEvaluationsRes.data;
        }
      } catch (error) {
        console.log(error);
        toast.error(t('common.anErrorOccurred'));
      }
    },
    [
      handleLoadEvaluations,
      onCancelClick,
      evaluationToEdit?.evaluation_participations,
      evaluationToEdit?.id,
      evaluationToEdit,
      selectedPractice,
      t
    ]
  );

  const handleDeleteEvaluation = async () => {
    if (evaluationToEdit) {
      const deleteEvaluationRes = await deleteEvaluation(evaluationToEdit.id);

      if (deleteEvaluationRes && 'error' in deleteEvaluationRes) {
        return;
      }

      toast.success(t('common.success'));

      await handleLoadEvaluations();
      onCancelClick();
    }
  };

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  useEffect(() => {
    if (evaluationToEditId) {
      handleGetEvaluationToEdit(evaluationToEditId);
    }
  }, [evaluationToEditId, handleGetEvaluationToEdit]);

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {
          handleSubmit((data) => handleSubmitEvaluationForm(data))();
        },
        variant: 'eucalyptus',
        label: t(`common.save`)
      }}
      isSecondButtonVisible={true}
      gridTemplateColumns="95%"
      bodyMaxHeight="80vh"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {t(`evaluationsView.editEvaluation`)}
          </H2>
        </Flex>
      }
      additionalFooter={
        <Flex
          minWidth="250px"
          alignItems="center"
          gap="10px"
          display={['none', 'flex']}
        >
          <P variant="body">{t('createTaskView.footer')}</P>
          <SwitchButton value={approvalSwitch} onChange={setApprovalSwitch} />
        </Flex>
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <FormProvider {...methods}>
          <FormEvaluation
            selectedPractice={selectedPractice}
            setSelectedPractice={setSelectedPractice}
            selectedEvaluation={evaluationToEdit}
            setSelectedEvaluation={setEvaluationToEdit}
            handleSubmitEvaluationForm={handleSubmitEvaluationForm}
            handleDeleteEvaluation={handleDeleteEvaluation}
          />
        </FormProvider>
      )}
    </Modal>
  );
};
