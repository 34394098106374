import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsRotate,
  faAt,
  faCirclePlus,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Line } from 'components';
import { H2, P } from 'components/Typography/Typography';
import {
  Button,
  FieldLabel,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { ColorPicker } from 'components/_form/ColorPicker/ColorPicker';
import { t } from 'i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

export const DefaultSettingsForm = () => {
  const { control } = useForm();

  const { fields: partnerCompaniesFields, append: partnerCompaniesAppend } =
    useFieldArray({
      name: 'partnerCompanies',
      control
    });

  const {
    fields: calendarLocalizationFields,
    append: calendarLocalizationAppend,
    remove: calendarLocalizationRemove
  } = useFieldArray({
    name: 'calendarLocalization',
    control
  });

  const addNewPartnerCompaniesField = () => {
    partnerCompaniesAppend({});
  };

  const addNewCalendarLocalizationField = () => {
    calendarLocalizationAppend({});
  };

  return (
    <Flex flexDirection="column">
      <Flex p={4} flexDirection="column" width="100%">
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <P variant="body">{t('administration.Default language for new users')}</P>
          </Box>
          <Box mt={3}>
            <Select options={[]} />
          </Box>

          <P variant="body" ml={2}>
            {' '}
            - {t('administration.mainAppLanguage')}
          </P>
        </Flex>
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <P variant="body">{t('administration.Interface languages available')}</P>
          </Box>
          <Box mt={3} width={['100%', '70%']}>
            <Select options={[]} />
          </Box>
        </Flex>
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <P variant="body">{t('administration.Default session duration (minutes)')}</P>
          </Box>
          <Box mt={3}>
            <TextField type="number" />
          </Box>
        </Flex>
        <Flex alignItems="center" flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <P variant="body">{t('administration.Only use corporate email addresses')}</P>
          </Box>
          <SwitchButton />
          <Box
            ml={3}
            id={'OnlyUseCorporateEmailAddresses' + 'Tooltip'}
            data-tooltip-content={
              t('administration.OnlyUseCorporateEmailAddressesTooltip') as string
            }
          >
            <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
          </Box>
          <Tooltip
            style={{ width: '90%' }}
            anchorId={'OnlyUseCorporateEmailAddresses' + 'Tooltip'}
          />
          <Box ml={3}>
            <Button>
              <Flex>
                <Box mr={2}>
                  <FontAwesomeIcon icon={faAt} />
                </Box>
                {t('administration.check')}
              </Flex>
            </Button>
          </Box>
        </Flex>
        <Flex mt={3} alignItems="center" flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <P variant="body">{t('administration.Uses users middle name')}</P>
          </Box>
          <SwitchButton />
        </Flex>
      </Flex>

      <H2 mt={4} variant="h2" color="coloured">
        {t('administration.USER CODES AND MAIL DOMAINS')}
      </H2>
      <Line />

      <Flex p={4} flexDirection="column">
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={['100%', '30%']}>
            <P pt={3} variant="body">
              {t('administration.Data for own users')}:
            </P>
          </Box>
          <Box mr={3}>
            <TextField label="administration.Email domain" />
          </Box>
          <Box>
            <TextField label="administration.user code" />
          </Box>
          <P pl={2} pt={3} variant="body">
            + {t('administration.next number')}
          </P>
        </Flex>

        <H2 mt={4} variant="h2">
          {t('DATA FOR PARTNERS AND SUBCONTRACTORS')}
        </H2>
        <Line variant="secondary" />

        {partnerCompaniesFields.map((field) => (
          <Flex key={field.id} flexWrap={['wrap', 'nowrap']}>
            <Box mr={2} width="100%">
              <TextField label="administration.companyName" />
            </Box>
            <Box mr={2} width="100%">
              <TextField label="administration.Feature / Service" />
            </Box>
            <Box mr={2} width="100%">
              <TextField label="administration.Email domain" />
            </Box>
            <Box width="50%">
              <TextField label="administration.user code" />
            </Box>
            <Box width="100%">
              <P pl={2} pt={4} variant="body">
                + {t('administration.next number')}
              </P>
            </Box>
          </Flex>
        ))}

        <Flex justifyContent="end">
          <Button variant="eucalyptus" onClick={addNewPartnerCompaniesField}>
            <FontAwesomeIcon icon={faCirclePlus} /> {t('addCompany')}
          </Button>
        </Flex>
      </Flex>

      <H2 mt={4} variant="h2" color="coloured">
        {t('administration.LOCATION')}
      </H2>
      <Line />

      <Flex px={4} justifyContent="space-between" alignItems="center">
        <P variant="body">
          {t(
            'The localization parameters set here override the default localizationThe localization parameters set here override the default localization settings assigned to the main application language.'
          )}
        </P>

        <Button variant="eucalyptus">
          <FontAwesomeIcon icon={faArrowsRotate} /> {t('profile.default')}
        </Button>
      </Flex>

      <Flex
        px={4}
        py={2}
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="end"
      >
        <Flex width={['100%', '32%']} flexDirection="column">
          <Flex width="100%">
            <Box width="50%" mt={2}>
              <P variant="body" fontWeight={700}>
                {t('administration.LocaleID')}
              </P>
            </Box>
            <Box width="50%">
              <Select options={[]} />
            </Box>
          </Flex>

          <Flex width="100%">
            <Box width="50%" mt={2}>
              <P variant="body" fontWeight={700}>
                {t('profile.dateFormat')}
              </P>
            </Box>
            <Box width="50%">
              <TextField type="date" />
            </Box>
          </Flex>

          <Flex width="100%">
            <Box width="50%" mt={2}>
              <P variant="body" fontWeight={700}>
                {t('currency')}
              </P>
            </Box>
            <Box width="50%">
              <TextField />
            </Box>
          </Flex>
        </Flex>

        <Flex width={['100%', '32%']} flexDirection="column">
          <Flex width="100%">
            <Box width="50%" mt={4}>
              <P variant="body" fontWeight={700}>
                {t('profile.separators')}
              </P>
            </Box>
            <Box width="25%" mr={2}>
              <TextField label="profile.decimal" />
            </Box>
            <Box width="24%">
              <TextField label="profile.thousands" />
            </Box>
          </Flex>

          <Flex width="100%">
            <Box width="50%" mt={2}>
              <P variant="body" fontWeight={700}>
                {t('administration.currencySymbol')}
              </P>
            </Box>
            <Box width="50%">
              <TextField />
            </Box>
          </Flex>
        </Flex>

        <Flex width={['100%', '32%']} flexDirection="column">
          <Flex width="100%">
            <Box width="40%">
              <P variant="body" fontWeight={700}>
                {t('administration.First day of the week')}
              </P>
            </Box>
            <Box width="60%">
              <Select options={[]} />
            </Box>
          </Flex>

          <Flex width="100%">
            <Box width="40%">
              <P variant="body" fontWeight={700}>
                {t('administration.Measuring units')}
              </P>
            </Box>
            <Box width="60%">
              <Select options={[]} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex px={4} flexDirection="column">
        <Line variant="secondary" />

        <Flex
          pt={3}
          justifyContent="space-between"
          alignItems="center"
          flexDirection={['column', 'row']}
        >
          <Box width={['100%', '27%']}>
            <Select label="administration.Calendar of holidays and days off" options={[]} />
          </Box>
          <Box width="5%">
            <ColorPicker label="administration.color" />
          </Box>
          <Box width={['100%', '30%']}>
            <Select
              label="administration.area(s)"
              helperText="leave blank if all company"
              options={[]}
            />
          </Box>

          <Box width={['100%', '10%']}>
            <Flex flexDirection="column" alignItems="center">
              <FieldLabel>{t('administration.Import holidays to the calendar')}</FieldLabel>
              <SwitchButton />
            </Flex>
          </Box>

          <Box py={2} width={['100%', '10%']}>
            <Button
              variant="eucalyptus"
              onClick={addNewCalendarLocalizationField}
            >
              <FontAwesomeIcon icon={faCirclePlus} /> {t('administration.add')}
            </Button>
          </Box>
        </Flex>

        {calendarLocalizationFields.map((field) => (
          <Flex
            key={field.id}
            pt={3}
            justifyContent="space-between"
            alignItems="center"
            flexDirection={['column', 'row']}
          >
            <Box width={['100%', '27%']}>
              <Select label="administration.Calendar of holidays and days off" options={[]} />
            </Box>
            <Box width="5%">
              <ColorPicker label="administration.color" />
            </Box>
            <Box width={['100%', '30%']}>
              <Select
                label="administration.area(s)"
                helperText="leave blank if all company"
                options={[]}
              />
            </Box>

            <Box width={['100%', '10%']}>
              <Flex flexDirection="column" alignItems="center">
                <FieldLabel>{t('administration.Import holidays to the calendar')}</FieldLabel>
                <SwitchButton />
              </Flex>
            </Box>

            <Box
              py={2}
              width={['100%', '10%']}
              onClick={() => {
                calendarLocalizationRemove(0);
              }}
            >
              <Button variant="redWhite" bordered>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
