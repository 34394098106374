import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H3, H4, P } from 'components/Typography/Typography';
import styled from 'styled-components';
import logos from 'assets/images/eu-logos.jpg';

const Img = styled.img`
  width: 100%;
  height: auto;
`;

export const InfoModal = ({ isOpen, onCancelClick }: IGenericModal) => {
  return (
    <Modal
      isOpen={isOpen}
      mainButton={{
        isDisabled: false,
        variant: 'blue',
        action: onCancelClick,
        label: 'Zamknij'
      }}
      headerTitle="Informacje o projekcie"
      onCancelClick={onCancelClick}
    >
      <Flex flexDirection="column" gap="10px">
        <H3 variant="h3" textAlign="center">
          Cyfrowa Platforma Doskonalenia dla oceny dojrzałości przedsiębiorstw i
          zarządzania programami rozwoju Lean/WCM/TPM, pozwalająca na
          prowadzenie ocen i samoocen oraz zdalne wsparcie wdrożenia programu
        </H3>
        <Img src={logos} alt="eu-logos" />
        <Flex flexDirection="column" py="10px">
          <H4 variant="h4">Cel projektu:</H4>
          <P variant="body">
            Celem projektu jest stworzenie Cyfrowej Platformy Doskonalenia dla
            oceny dojrzałości przedsiębiorstw i zarządzania programami rozwoju
            Lean/WCM/TPM. Przeniesienie metodologii na platformę, pozwoli na
            łatwiejsze i szersze świadczenie usług, szczególnie w warunkach
            ograniczeń pandemicznych, a także będą możliwe zdalne wdrożenia
            procesów. Projekt pozwoli na zmianę modelu biznesowego firmy i
            umożliwi świadczenie nowoczesnych usług zdalnych oraz rozszerzenie
            zasięgu oferty. Proces scyfryzowany będzie znacznie bardziej
            efektywny i będzie stanowił lepszą ofertę rynkową. Innowacja w
            postaci Cyfrowej Platformy Doskonalenia pozwoli na zaoferowanie
            byłym, obecnym i nowym Klientom nowej formy usługi, całkowicie
            nowego procesu, korzystnego zarówno dla Wnioskodawcy, jak i jego
            Klientów. Klienci na bieżąco będą mieć dostęp do metodologii,
            możliwości samooceny, śledzenia postępów, raportowania, aktualizacji
            materiałów i wytycznych wdrożeniowych oraz do zintegrowanych szkoleń
            on-line.
          </P>
        </Flex>
        <H4 variant="h4" textAlign="center">
          Wartość projektu: 297 200 PLN
        </H4>
        <H4 variant="h4" textAlign="center">
          Dofinansowanie projektu z UE: 252 620 PLN
        </H4>
        <H4 variant="h4" textAlign="center" color="blue">
          Sfinansowano w ramach reakcji Unii na pandemię Covid-19
        </H4>
      </Flex>
    </Modal>
  );
};
