import { Flex, Modal } from 'components';
import { P } from 'components/Typography/Typography';
import { Select, TextField } from 'components/_form';
import { t } from 'i18next';
import { Dispatch } from 'react';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
  parentAreaTitle?: string;
}

export const AddAreaModal = ({
  isModalOpen,
  setIsModalOpen,
  parentAreaTitle
}: Props) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onCancelClick={() => {
        setIsModalOpen(false);
      }}
      headerTitle={t(parentAreaTitle ? 'administration.Add a subarea' : 'administration.Add a parent area')}
      mainButton={{ label: t('administration.add'), action: () => {} }}
      isSecondButtonVisible
      thirdButton={{ label: t('buttons.delete'), action: () => {} }}
    >
      {
        <Flex flexDirection="column">
          {parentAreaTitle && (
            <P my={3} variant="body">
              {t('administration.You add a subarea to:')} {parentAreaTitle}
            </P>
          )}
          <TextField label="administration.areaCode" />
          <TextField label="profile.name" />
          <Select label="administration.areaOwner" options={[]} />
        </Flex>
      }
    </Modal>
  );
};
