import { Avatar, Box, Flex } from 'components';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Table } from '../../../../../../../components/Table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button, Select, TextField } from 'components/_form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TablePagination } from 'components/Table/TablePagination';
import { IUser } from 'types/user';

const Icon = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  ::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ isActive }) => (isActive ? 'green' : 'grey')};
  }
`;

interface Data {
  active: React.ReactNode;
  id: number;
  photo: JSX.Element;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  type: string;
  editing: JSX.Element;
}

interface Props {
  usersList?: IUser[];
}

const UserListTable: FC<Props> = ({ usersList }) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const header: string[] = [
    t('employeesAndUsersView.tableHeaders.active'),
    t('employeesAndUsersView.tableHeaders.id'),
    t('employeesAndUsersView.tableHeaders.photo'),
    t('employeesAndUsersView.tableHeaders.firstName'),
    t('employeesAndUsersView.tableHeaders.lastName'),
    t('employeesAndUsersView.tableHeaders.emailAddress'),
    t('employeesAndUsersView.tableHeaders.userName'),
    t('employeesAndUsersView.tableHeaders.accountType'),
    ''
  ];

  const button = (id: number) => (
    <Box mx="auto" width="fit-content">
      <Button
        onClick={() => {
          navigate(`user/${id}`);
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />
    </Box>
  );

  const data: Data[] =
    usersList?.map((mapUser) => ({
      active: <Icon isActive={mapUser.profile?.active} />,
      id: mapUser.id,
      photo: (
        <Avatar
          url={
            mapUser.profile?.photo
              ? `${process.env.REACT_APP_API_URL}${mapUser.profile?.photo}`
              : ''
          }
        />
      ),
      firstName: mapUser.profile?.first_name,
      lastName: mapUser.profile?.last_name,
      email: mapUser.email,
      userName: mapUser.username,
      type: mapUser.role,
      editing: button(mapUser.id)
    })) || [];

  const additionalHeader = (
    <>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseCompany') ?? undefined}
        options={[]}
        withoutMargin
      />
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
        hideError
      />
      <TablePagination
        total={150}
        page={page}
        setPage={setPage}
        perPage={perPage}
        hidePageInput
      />
    </>
  );

  return (
    <>
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        leftFixedKeys={[0, 1]}
        additionalHeaderElement={additionalHeader}
      />
    </>
  );
};

export default UserListTable;
