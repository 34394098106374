import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import {
  Button,
  Radio,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { t } from 'i18next';
import { BaseSyntheticEvent } from 'react';
import { AccordionItemButton } from 'react-accessible-accordion';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister
} from 'react-hook-form';
import {
  StyledAccordionItemHeading,
  StyledAccordionItemPanel
} from '../../ProfileView.styled';

interface Props {
  id?: string;
  register: UseFormRegister<FieldValues>;
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  control: Control<FieldValues, any>;
}

export const BasicInformationForm = ({
  id,
  register,
  onSubmit,
  control
}: Props) => {
  return (
    <form onSubmit={onSubmit}>
      <StyledAccordionItemHeading>
        <AccordionItemButton>
          <Flex p={2}>
            <FontAwesomeIcon icon={faAddressCard} />
            <P ml={2} variant="body">
              {t('profile.basicInformation')}
            </P>
          </Flex>
        </AccordionItemButton>
      </StyledAccordionItemHeading>
      <StyledAccordionItemPanel>
        <Flex flexWrap="wrap">
          <Flex mr={2} width={['100%', '32%']}>
            {id && (
              <Controller
                control={control}
                name="active"
                render={({ field: { onChange, value } }) => (
                  <Flex my={1} justifyContent="center">
                    <SwitchButton
                      label="administration.active?"
                      value={value}
                      onChange={onChange}
                    />
                  </Flex>
                )}
              />
            )}
            <TextField label="profile.idCode" {...register('id_code')} />
          </Flex>
          <Box mr={2} mt={1} width={['100%', '32%']}>
            <Select label="profile.userCompany" options={[]} />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box mr={2} width={['100%', '32%']}>
            <TextField label="profile.name" {...register('first_name')} />
          </Box>
          <Box mr={2} width={['100%', '32%']}>
            <TextField
              label="profile.secondname"
              {...register('second_name')}
            />
          </Box>
          <Box mr={2} width={['100%', '32%']}>
            <TextField label="profile.surname" {...register('last_name')} />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box mr={2} width={['100%', '32%']}>
            <TextField label="common.language" {...register('language')} />
          </Box>
          <Box mr={2} width={['100%', '32%']}>
            <TextField label="profile.phone" {...register('phone')} />
          </Box>
          <Box mr={2} width={['100%', '32%']}>
            <TextField
              label="profile.landlinePhone"
              {...register('landline_phone')}
            />
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          <Box mr={[0, 2]} width={['100%', '49%']}>
            <Select label="profile.mainArea" options={[]} />
          </Box>
          <Box width={['100%', '49%']}>
            <Select
              label={id ? 'profile.employeeRoles' : 'profile.myRoleInCompany'}
              options={[]}
            />
          </Box>
        </Flex>

        {id && (
          <Flex flexDirection="column">
            <P variant="body" mb={2}>
              {t('employeesAndUsersView.tableHeaders.accountType')}
            </P>

            <Flex flexWrap="wrap" gap="8px">
              <Radio
                {...register('role')}
                label="administration.admin"
                value="admin"
              />
              <Radio
                {...register('role')}
                label="administration.user"
                value="user"
              />
              <Radio
                {...register('role')}
                label="administration.guest"
                value="guest"
              />
              <Radio
                {...register('role')}
                label="administration.consultant"
                value="consultant"
              />
            </Flex>
          </Flex>
        )}

        <Flex justifyContent="end">
          <Button variant="eucalyptus" type="submit">
            {t('common.save')}
          </Button>
        </Flex>
      </StyledAccordionItemPanel>
    </form>
  );
};
