import {
  faCirclePlay,
  faClipboard,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Flex, Table } from 'components';
import { Button } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { PDCAPie } from 'components/RealizationProgress/components/PDCAPie';

export const DetailsTable = () => {
  const { t } = useTranslation();

  const header: string[] = [
    t('documentsView.tableHeaders.launchedFor'),
    t('documentsView.tableHeaders.responsible'),
    t('documentsView.tableHeaders.status'),
    t('documentsView.tableHeaders.startDate'),
    t('documentsView.tableHeaders.redlizationDate'),
    t('documentsView.tableHeaders.numberOfDays'),
    ''
  ];

  const rowButtons = () => (
    <Flex style={{ gap: '5px' }} mx="auto" width="fit-content">
      <Button
        variant="green"
        onClick={() => {
          console.log(111);
        }}
        icon={<FontAwesomeIcon icon={faCirclePlay} />}
      />
      <Button
        variant="greyWhite"
        bordered
        onClick={() => {
          console.log(222);
        }}
        icon={<FontAwesomeIcon icon={faClipboard} />}
      />
      <Button
        onClick={() => {
          //   setIsFormModalOpen((prevIsFormModalOpen) => !IsFormModalOpen);
          //   setSelectedItemData(item);
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />
    </Flex>
  );

  const data = [
    {
      launchedFor: 'Aloha Polska',
      responsible: (
        <Flex gap="5px" alignItems="center">
          <Avatar size="extra-small" />
          Janusz Spręzyna
        </Flex>
      ),
      status: (
        <Flex width="40px">
          <PDCAPie />
        </Flex>
      ),
      startDate: '18.01.2023',
      redlizationDate: '18.01.2023',
      numberOfDays: '-456',
      buttons: rowButtons()
    },
    {
      launchedFor: 'Aloha Polska',
      responsible: (
        <Flex gap="5px" alignItems="center">
          <Avatar size="extra-small" />
          Janusz Spręzyna
        </Flex>
      ),
      status: (
        <Flex width="40px">
          <PDCAPie />
        </Flex>
      ),
      startDate: '18.01.2023',
      redlizationDate: '18.01.2023',
      numberOfDays: '-456',
      buttons: rowButtons()
    }
  ];

  return (
    <Table
      header={header}
      data={data}
      leftFixedKeys={[0, 1]}
      sortTable={false}
      fontSize="small"
      width="87%"
    />
  );
};
