import {
  faPlusCircle,
  faFileArrowDown,
  faFileArrowUp,
  faUserShield
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { H1 } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../CompanyAdministrationView/CompanyAdministrationView.styled';
import { AddLanguageModal } from './components/AddLanguageModal';
import { ImportTranslationsModal } from './components/ImportTranslationsModal';
import { TranslationsTable } from './components/TranslationsTable';

export const TranslationsView = () => {
  const { t } = useTranslation();
  const [highlightCells, setHighlightCells] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openAddLanguageModal, setOpenAddLanguageModal] =
    useState<boolean>(false);
  const [editingLanguageData, setEditingLanguageData] = useState<{
    name: string;
  }>();

  const navigate = useNavigate();
  return (
    <>
      <ImportTranslationsModal
        isOpen={openImportModal}
        onCancelClick={() => setOpenImportModal(false)}
      />
      <AddLanguageModal
        data={editingLanguageData}
        isOpen={openAddLanguageModal}
        onCancelClick={() => setOpenAddLanguageModal(false)}
      />
      <Wrapper flexDirection="column">
        <Flex width="100%">
          <BackButton />
          <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
            {t('administration.administrationLabel')} -{' '}
            {t('administration.translations')}
          </H1>
        </Flex>
        <Line />
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          width="100%"
          gap="10px"
        >
          <Button
            variant="eucalyptus"
            onClick={() => {
              setOpenAddLanguageModal(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.addNew')}
          </Button>
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="flex-end"
            gap="10px"
          >
            <Button
              variant="redWhite"
              bordered
              onClick={() => {
                navigate('permitted-users');
              }}
            >
              <FontAwesomeIcon icon={faUserShield} /> {t('buttons.entitled')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setOpenImportModal(true);
              }}
            >
              <FontAwesomeIcon icon={faFileArrowDown} />{' '}
              {t('buttons.importTranslation')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setHighlightCells((prev) => !prev);
              }}
            >
              {t('buttons.highlightBlocks')}
            </Button>
            <Button variant="eucalyptus" onClick={() => {}}>
              <FontAwesomeIcon icon={faFileArrowUp} />{' '}
              {t('buttons.exportToExcel')}
            </Button>
          </Flex>
        </Flex>
        <TranslationsTable
          highlightCells={highlightCells}
          setOpenAddLanguageModal={setOpenAddLanguageModal}
          setEditingLanguageData={setEditingLanguageData}
        />
      </Wrapper>
    </>
  );
};
