import {
  faBuilding,
  faGears,
  faLanguage,
  faSitemap,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { Flex, Line } from 'components';
import { H1, H2 } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './AdministrationView.styled';
import { SettingTab } from './components/SettingTab/SettingTab';

export const AdministrationView = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <H1 variant="h1">{t('administration.administrationLabel')}</H1>
      <Line />
      <H2 mt={3} variant="h2" color="coloured">
        {t('profile.overallSettings')}
      </H2>
      <Line />
      <Flex flexWrap="wrap">
        <SettingTab
          title="administration.company"
          description="administration.companySettingsDescription"
          icon={faBuilding}
          link="company"
        />
        <SettingTab
          title="administration.employeesAndUsers"
          description="administration.employeesAndUsersSettingsDescription"
          icon={faUsers}
          link="employees-and-users"
        />
        <SettingTab
          title="administration.areasAndOrganizationStructure"
          description="administration.areasAndOrganizationStructureSettingsDescription"
          icon={faSitemap}
          link="areas-and-organization-structure"
        />
      </Flex>
      <H2 mt={2} variant="h2" color="coloured">
        {t('administration.otherSettingsListsDictionaries')}
      </H2>
      <Line />
      <Flex flexWrap="wrap">
        <SettingTab
          title="administration.tasks"
          description="administration.tasksSettingsDescription"
          icon={faGears}
          variant="secondary"
          link="tasks"
        />
        <SettingTab
          title="administration.audits"
          description="administration.auditsSettingsDescription"
          icon={faGears}
          variant="secondary"
          link="audits"
        />
      </Flex>

      <H2 mt={2} variant="h2" color="coloured">
        {t('administration.translations')}
      </H2>
      <Line />
      <Flex>
        <SettingTab
          title="administration.translations"
          description="administration.translationsSettingsDescription"
          icon={faLanguage}
          link="translations"
        />
      </Flex>
    </Wrapper>
  );
};
