import { Flex } from 'components';
import React, { ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/_form';
import { P } from 'components/Typography/Typography';
import { debounce } from 'lodash';

export interface ITablePagination {
  total: number;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  hidePageInput?: boolean;
  headerRef?: React.RefObject<HTMLTableSectionElement>;
}

const PaginationButton = styled(Flex)<{ disabled?: boolean }>`
  gap: 5px;
  align-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  font-size: ${({ theme }) => theme.textStyles.body.fontSize}px;
  ${({ disabled, theme }) =>
    disabled &&
    `cursor: auto; color: ${theme.palette.neutral.greyBlue};
  `};
`;

const PaginationWrapper = styled(Flex)`
  width: fit-content;
  align-items: center;
  gap: 30px;
  white-space: nowrap;
`;

export const TablePagination = ({
  total,
  page,
  perPage,
  setPage,
  hidePageInput,
  headerRef
}: ITablePagination) => {
  const { t } = useTranslation();
  const handlePrev = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);

      headerRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }
  };

  const handleNext = () => {
    if (page * perPage < total) {
      setPage((prev) => prev + 1);

      headerRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }
  };

  const handleTextFieldChange = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        setPage(Number(e.target.value));
        headerRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      }, 500),
    [headerRef, setPage]
  );

  const pages = Math.ceil(total / perPage);

  return (
    <PaginationWrapper flexWrap="wrap">
      {!hidePageInput && (
        <Flex alignItems="center" style={{ gap: '5px' }} width="min-content">
          <P variant="body">{t('pagination.page')}:</P>
          <TextField
            type="number"
            defaultValue={page}
            onChange={handleTextFieldChange}
            hideError
            width="55px"
            min={1}
            max={pages}
          />
          <P variant="body">
            {t('pagination.from')} {pages}
          </P>
        </Flex>
      )}
      <P variant="body">
        {t('pagination.show')}: {1 + page * perPage - perPage} -{' '}
        {page * perPage < total ? page * perPage : total} {t('pagination.from')}{' '}
        {total} {t('pagination.rows')}
      </P>
      <PaginationButton onClick={handlePrev} disabled={page < 2}>
        <FontAwesomeIcon icon={faCaretLeft} />
        {t('pagination.previous')}
      </PaginationButton>
      <PaginationButton onClick={handleNext} disabled={page * perPage >= total}>
        {t('pagination.next')}
        <FontAwesomeIcon icon={faCaretRight} />
      </PaginationButton>
    </PaginationWrapper>
  );
};
