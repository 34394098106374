import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2 } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IOption } from 'components/_form/Select/Select';
import { Dispatch, SetStateAction, useState } from 'react';
import { Loader } from 'components/Loader/Loader';
import { ClosingTable } from 'components/ClosingTable/ClosingTable';
import { FormDataControl } from '../CreateAuditModal';
import { IDetailedAudit } from 'types/audits';
import { closeAuditClosing, openAuditClosing } from 'api/auditsClosings';
import { IAuditsClosing } from 'types/auditsClosings';

interface Props extends IGenericModal {
  watch: UseFormWatch<FormDataControl>;
  setValue: UseFormSetValue<FormDataControl>;
  usersList: IOption[];
  userFunctionsList: IOption[];
  selectedAudit?: IDetailedAudit;
  setSelectedAudit?: Dispatch<SetStateAction<IDetailedAudit | undefined>>;
}

export const AuditStatusModal = ({
  isOpen,
  onCancelClick,
  watch,
  setValue,
  usersList,
  userFunctionsList,
  selectedAudit,
  setSelectedAudit
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isAddingRowVisible, setIsAddingRowVisible] = useState(false);

  const { t } = useTranslation();

  const closingIds = watch('closing_ids') || [];

  const insertAuditClosingIntoAudit = (newAuditClosing: IAuditsClosing) => {
    const auditClosing = selectedAudit?.audits_closings.find(
      ({ id: findId }) => findId.toString() === newAuditClosing.id.toString()
    );

    if (auditClosing) {
      setSelectedAudit?.(
        (prevAudit) =>
          prevAudit && {
            ...prevAudit,
            audits_closings: auditClosing
              ? prevAudit.audits_closings.map((auditClosing) =>
                  auditClosing.id.toString() === newAuditClosing.id.toString()
                    ? newAuditClosing
                    : auditClosing
                )
              : [...prevAudit.audits_closings, newAuditClosing]
          }
      );
    }
  };

  const handleCloseAudit = async (closingId?: string | number) => {
    setLoading(true);
    if (closingId) {
      const closeAuditClosingRes = await closeAuditClosing(closingId);

      if (closeAuditClosingRes.data) {
        insertAuditClosingIntoAudit(closeAuditClosingRes.data);
      }
    }
    setLoading(false);
  };

  const handleOpenAudit = async (closingId?: string | number) => {
    setLoading(true);
    if (closingId) {
      const openAuditClosingRes = await openAuditClosing(closingId);

      if (openAuditClosingRes.data) {
        insertAuditClosingIntoAudit(openAuditClosingRes.data);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: onCancelClick,
        variant: 'eucalyptus',
        label: loading ? <Loader /> : t(`buttons.saveAndClose`)
      }}
      gridTemplateColumns="50%"
      bodyMaxHeight="70vh"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3" mr={2}>
            {t(`auditsView.closingAudit`)}
          </H2>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Flex width="100%" justifyContent="flex-end">
          <Button
            variant="eucalyptus"
            onClick={() => {
              setIsAddingRowVisible(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.addPerson')}
          </Button>
        </Flex>
        <ClosingTable
          closingIds={closingIds}
          setClosingIds={(ids) => setValue('closing_ids', ids)}
          closings={selectedAudit?.audits_closings || []}
          authorId={watch('author_id')}
          leaderId={watch('leader_id')}
          teamLeaderId={watch('team_leader_id')}
          teamManagerId={watch('team_manager_id')}
          usersList={usersList}
          userFunctionsList={userFunctionsList}
          participationsAttributes={
            watch('audit_participations_attributes') || []
          }
          handleClose={handleCloseAudit}
          handleOpen={handleOpenAudit}
          isAddingRowVisible={isAddingRowVisible}
          setIsAddingRowVisible={setIsAddingRowVisible}
        />
      </Flex>
    </Modal>
  );
};
