import { IGenericModal, Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { RatingScaleForm } from './RatingScaleForm';
import {
  deleteRatingScale,
  getRatingScale,
  patchRatingScale
} from 'api/ratingScales';
import { IPostRatingScale } from 'types/ratingScales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from 'react';
import { Button, SwitchButton } from 'components/_form';
import { Flex } from 'components';

interface Props extends IGenericModal {
  onSubmitSuccess: () => void;
  editingRatingScaleId?: number;
  onDeleteSuccess: () => void;
}

export const EditRatingScaleForm = ({
  isOpen,
  editingRatingScaleId,
  onCancelClick,
  onSubmitSuccess,
  onDeleteSuccess
}: Props) => {
  if (!editingRatingScaleId) {
    return <></>;
  }

  const [defaultValues, setDefaultValues] =
    useState<IPostRatingScale['rating_scale']>();

  const { t } = useTranslation();

  const methods = useForm<IPostRatingScale['rating_scale']>();

  const { handleSubmit, reset, watch, control } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      rating_scale: {
        ...data,
        buttons_attributes:
          'buttons_attributes' in data
            ? [
                ...data.buttons_attributes,
                ...(defaultValues && 'buttons_attributes' in defaultValues
                  ? defaultValues.buttons_attributes
                      .filter(
                        (buttonAttribute) =>
                          !data.buttons_attributes.find(
                            (dataButtonAttribute) =>
                              'id' in dataButtonAttribute &&
                              'id' in buttonAttribute &&
                              dataButtonAttribute.id === buttonAttribute.id
                          )
                      )
                      .map((buttonAttribute) => ({
                        ...buttonAttribute,
                        _destroy: 1
                      }))
                  : [])
              ]
            : []
      }
    };

    const ratingScaleRes = await patchRatingScale(
      editingRatingScaleId,
      payload
    );

    if ('error' in ratingScaleRes) {
      toast.error(ratingScaleRes.error.message);
    }

    if (ratingScaleRes.data) {
      onSubmitSuccess();
      reset();
    }
  });

  const handleCancelClick = () => {
    reset();
    onCancelClick();
  };

  const handleLoadDefaultValues = useCallback(async () => {
    const ratingScaleRes = await getRatingScale(editingRatingScaleId);

    if ('error' in ratingScaleRes) {
      return;
    }

    const buttonsAttributes = await Promise.all(
      ratingScaleRes.data.buttons?.map(async (button) => {
        let file;
        if (button.image_url) {
          const imgRes = await fetch(
            `${process.env.REACT_APP_API_URL}${button.image_url}`
          );

          const blob = await imgRes.blob();
          file = new File([blob], 'image', { type: blob.type });
        }

        return { ...button, image: file };
      })
    );

    const newDefaultValues = {
      ...ratingScaleRes.data,
      buttons_attributes: buttonsAttributes
    };

    setDefaultValues(newDefaultValues);
    reset(newDefaultValues);
  }, [editingRatingScaleId, reset]);

  const handleDeleteRatingScale = async () => {
    const deleteRatingScaleRes = await deleteRatingScale(editingRatingScaleId);

    if (deleteRatingScaleRes && 'error' in deleteRatingScaleRes) {
      toast.error(deleteRatingScaleRes.error.message);
      return;
    }

    reset();
    onDeleteSuccess();
  };

  useEffect(() => {
    handleLoadDefaultValues();
  }, [handleLoadDefaultValues]);

  return (
    <Modal
      gridTemplateColumns="50%"
      isOpen={isOpen}
      mainButton={{
        action: onSubmit,
        variant: 'eucalyptus',
        label: t('buttons.save')
      }}
      secondButton={{
        action: handleCancelClick,
        label: t('common.cancel')
      }}
      isSecondButtonVisible
      headerTitle={t('administrationAuditsView.addRatingScale')}
      onCancelClick={handleCancelClick}
      additionalFooter={
        <Flex gap="20px" minWidth="250px">
          <Button
            disabled={watch('any_active_audit_form')}
            variant="red"
            onClick={handleDeleteRatingScale}
          >
            {t('buttons.delete')}
          </Button>

          <Controller
            control={control}
            name="active"
            render={({ field: { onChange, value } }) => (
              <SwitchButton
                label={t('administrationAuditsView.active?')}
                value={!!value}
                onChange={(checked) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </Flex>
      }
    >
      <FormProvider {...methods}>
        <RatingScaleForm />
      </FormProvider>
    </Modal>
  );
};
