import { TypographyType } from 'components/Typography/Typography';
import {
  BlockContainer,
  ButtonContainer,
  Container,
  StyledP
} from './Block.styled';
import { Button } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { EvaluationStats } from './components/EvaluationStats/EvaluationStats';
import { Flex } from 'components/Flex';
import { IBlock } from 'types/methologies';
import { BlockScore } from 'types/evaluations';

interface ClearBlockProps {
  block?: IBlock;
  active?: boolean;
  checkButton?: boolean;
  onClick?: () => void;
  coloured?: boolean;
  variant?: 'normal' | 'big';
  width?: string;
  height?: string;
  fontVariant?: TypographyType['variant'];
  scores?: BlockScore[];
  isOneColumn?: boolean;
  bgColor?: string;
  selectedBgColor?: string;
}

interface BlockProps {
  block?: ClearBlockProps['block'];
  active?: ClearBlockProps['active'];
  checkButton?: ClearBlockProps['checkButton'];
  onClick?: ClearBlockProps['onClick'];
  coloured?: ClearBlockProps['coloured'];
  width?: ClearBlockProps['width'];
  height?: ClearBlockProps['height'];
  fontVariant?: ClearBlockProps['fontVariant'];
  increasing?: boolean;
  scores?: ClearBlockProps['scores'];
  isOneColumn?: boolean;
  bgColor?: ClearBlockProps['bgColor'];
  selectedBgColor?: ClearBlockProps['selectedBgColor'];
}

export const ClearBlock: React.FC<ClearBlockProps> = ({
  block,
  active,
  checkButton,
  onClick,
  coloured,
  variant,
  width,
  height,
  fontVariant,
  scores,
  isOneColumn,
  bgColor,
  selectedBgColor
}) => {
  return (
    <BlockContainer
      block={!!block}
      active={active}
      coloured={coloured}
      onClick={onClick}
      variant={variant}
      width={width}
      height={height}
      bgColor={bgColor}
      selectedBgColor={selectedBgColor}
    >
      {block && (
        <>
          {checkButton && (
            <ButtonContainer>
              <Button
                minHeight="16px"
                variant={active ? 'eucalyptus' : 'greyWhite'}
                bordered={!active}
                icon={<FontAwesomeIcon icon={active ? faCheck : faX} />}
              />
            </ButtonContainer>
          )}
          <Flex width="100%" alignItems="center" justifyContent="center">
            <StyledP
              variant={variant === 'big' ? 'h4' : fontVariant || 'body4'}
              textAlign="center"
              fontWeight={700}
            >
              {block.name}
            </StyledP>
          </Flex>
        </>
      )}

      {scores?.length && (
        <EvaluationStats
          block={block}
          scores={scores}
          isOneColumn={isOneColumn}
        />
      )}
    </BlockContainer>
  );
};

export const Block: React.FC<BlockProps> = ({
  block,
  active = true,
  checkButton = true,
  onClick,
  coloured,
  width,
  increasing = true,
  height,
  fontVariant,
  scores,
  isOneColumn,
  bgColor,
  selectedBgColor
}) => {
  const [biggerBlockVisible, setBiggerBlockVisible] = useState(false);

  return (
    <Container
      onMouseEnter={() => setBiggerBlockVisible(true)}
      onMouseLeave={() => setBiggerBlockVisible(false)}
    >
      <ClearBlock
        block={block}
        active={active}
        checkButton={checkButton}
        onClick={onClick}
        coloured={coloured}
        width={width}
        height={height}
        fontVariant={fontVariant}
        scores={scores}
        isOneColumn={isOneColumn}
        bgColor={bgColor}
        selectedBgColor={selectedBgColor}
      />

      {increasing && biggerBlockVisible && block && (
        <ClearBlock
          block={block}
          active={active}
          checkButton={checkButton}
          onClick={onClick}
          coloured={coloured}
          variant="big"
          width={width}
          height={height}
          fontVariant={fontVariant}
          scores={scores}
          bgColor={bgColor}
          selectedBgColor={selectedBgColor}
        />
      )}
    </Container>
  );
};
