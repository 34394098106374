import { Flex } from 'components';
import { TableCell, TableHeader } from './AuditFormsList.styled';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAuditForms } from 'api/audits';
import { IAuditForm } from 'types/audits';
import { parseItemsToMethodologyTree } from 'utilities/practices';
import { ActiveDot } from 'components/ActiveDot/ActiveDot';
import { P } from 'components/Typography/Typography';
import { Button, Checkbox } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const AuditFormsList = () => {
  const [forms, setForms] = useState<IAuditForm[]>([]);
  const [statesFilter, setStatesFilter] = useState<string[]>([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const formsMethodologiesStructure = useMemo(
    () => parseItemsToMethodologyTree(forms),
    [JSON.stringify(forms)]
  );

  const handleGetAuditForms = useCallback(async () => {
    const auditFormsRes = await getAuditForms({
      filters: { states: statesFilter }
    });

    if ('error' in auditFormsRes) {
      return;
    }

    setForms(auditFormsRes.data);
  }, [JSON.stringify(statesFilter)]);

  useEffect(() => {
    handleGetAuditForms();
  }, [handleGetAuditForms]);

  return (
    <Flex flexDirection="column">
      <Flex
        my={2}
        width="100%"
        justifyContent="end"
        alignItems="center"
        gap="25px"
        flexDirection={['column', 'row']}
      >
        <Flex alignItems="center" gap="15px" flexDirection={['column', 'row']}>
          <P variant="h4">{t('common.show')}:</P>
          <Checkbox
            label={t('administrationAuditsView.inactive')}
            checked={statesFilter.includes('inactive')}
            onChange={(e) => {
              setStatesFilter((prevStatesFilter) =>
                e.target.checked
                  ? [...prevStatesFilter, 'inactive']
                  : prevStatesFilter.filter((state) => state !== 'inactive')
              );
            }}
          />
          <Checkbox
            label={t('administrationAuditsView.archived')}
            checked={statesFilter.includes('archived')}
            onChange={(e) => {
              setStatesFilter((prevStatesFilter) =>
                e.target.checked
                  ? [...prevStatesFilter, 'archived']
                  : prevStatesFilter.filter((state) => state !== 'archived')
              );
            }}
          />
        </Flex>
        <Button
          variant="eucalyptus"
          onClick={() => {
            navigate('add-audit-form');
          }}
        >
          <Flex alignItems="center" gap="5px">
            <FontAwesomeIcon icon={faCirclePlus} />
            {t('common.add')}
          </Flex>
        </Button>
      </Flex>

      <Flex overflow="auto" width="100%">
        <Flex flexDirection="column" minWidth="1200px" width="100%">
          <Flex>
            <TableHeader width="10%"></TableHeader>
            <TableHeader width="10%">
              {t('administrationAuditsView.tableHeaders.active')}
            </TableHeader>
            <TableHeader width="30%">
              {t('administrationAuditsView.tableHeaders.practice/area/form')}
            </TableHeader>
            <TableHeader width="10%">
              {t('administrationAuditsView.tableHeaders.version')}
            </TableHeader>
            <TableHeader width="30%">
              {t('administrationAuditsView.tableHeaders.usedInAreas')}
            </TableHeader>
            <TableHeader width="10%"></TableHeader>
          </Flex>

          {formsMethodologiesStructure.map((methodology) => (
            <Flex
              minWidth="100%"
              key={`formsMethodologiesStructure-methodology-${methodology.id}`}
              overflow="hidden"
            >
              <Flex minWidth="10%" p={2}>
                <P variant="h3">{methodology.name}</P>
              </Flex>
              <Flex minWidth="100%" flexDirection="column">
                {methodology.practices.map((practice) => (
                  <Flex
                    minWidth="100%"
                    key={`formsMethodologiesStructure-practice-${practice.id}`}
                    flexDirection="column"
                    gap="8px"
                  >
                    <Flex ml="10%" my={2}>
                      <P variant="h3">{practice.name}</P>
                    </Flex>

                    {practice.items.map((auditForm) => (
                      <Flex
                        key={`formsMethodologiesStructure-audit-${auditForm.id}`}
                      >
                        <TableCell width="10%" justifyContent="center">
                          <ActiveDot active={auditForm.state === 'active'} />
                        </TableCell>
                        <TableCell width="30%">{auditForm.name}</TableCell>
                        <TableCell width="10%">-</TableCell>
                        <TableCell width="30%">
                          {auditForm.areas.map(({ name }) => name).join(', ')}
                        </TableCell>
                        <TableCell width="10%" gap="5px">
                          <Button
                            variant="eucalyptus"
                            icon={<FontAwesomeIcon icon={faEdit} size="xl" />}
                            onClick={() => {
                              navigate(
                                `/app/administration/audits/edit-audit-form/${auditForm.id}`
                              );
                            }}
                          />
                          <Flex flexDirection="column">
                            <Button
                              bordered
                              transparent
                              icon={
                                <FontAwesomeIcon icon={faChevronUp} size="xl" />
                              }
                              minHeight="17px"
                            />
                            <Button
                              bordered
                              transparent
                              icon={
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  size="xl"
                                />
                              }
                              minHeight="17px"
                            />
                          </Flex>
                        </TableCell>
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
