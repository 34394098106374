import { Box, Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { H1 } from 'components/Typography/Typography';
import { Wrapper } from './AreasAndOrganizationStructureView.styled';
import { t } from 'i18next';
import { Button } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { AreaAccordion } from './components/AreaAccordion';
import { useEffect, useState } from 'react';
import { AddAreaModal } from './components/AddAreaModal';
import { AreaDetailsForm } from './components/AreaDetailsForm';
import { getAreas } from 'api/areas';
import { parentToChildrenAreasStructure } from 'utilities/areas';
import { AreaAccordionItem } from 'types/area';

export const AreasAndOrganizationStructureView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState<
    AreaAccordionItem | undefined
  >();
  const [areas, setAreas] = useState<AreaAccordionItem[]>([]);

  const handleGetAreas = async () => {
    const { data } = await getAreas();

    setAreas(parentToChildrenAreasStructure(data));
  };

  useEffect(() => {
    handleGetAreas();
  }, []);

  const renderAccordion = (item: AreaAccordionItem) => {
    return (
      <AreaAccordion
        key={item.id}
        title={item.name}
        active={item.state === 'active'}
        onAddClick={() => {
          setIsModalOpen(true);
          setSelectedArea(item);
        }}
        onEditClick={() => {
          setIsEditFormOpen(true);
          setSelectedArea(item);
        }}
      >
        {item.children?.length &&
          item.children.map((child) => renderAccordion(child))}
      </AreaAccordion>
    );
  };

  return (
    <>
      <Wrapper>
        <Flex width="100%">
          <BackButton
            customOnClick={
              isEditFormOpen
                ? () => {
                    setIsEditFormOpen(false);
                  }
                : undefined
            }
          />
          <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
            {t('administration.administrationLabel')} - {t('administration.STRUCTURE OF AREAS')}
            {isEditFormOpen && selectedArea && `: ${selectedArea.name}`}
          </H1>
        </Flex>
        <Line />

        {isEditFormOpen && selectedArea ? (
          <AreaDetailsForm data={selectedArea} />
        ) : (
          <>
            {/* <Flex>
              <Button
                variant="eucalyptus"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <Flex>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                  </Box>
                  {t('add')}
                </Flex>
              </Button>
            </Flex> */}

            {areas.map((item) => renderAccordion(item))}
          </>
        )}
      </Wrapper>

      <AddAreaModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        parentAreaTitle={selectedArea?.name}
      />
    </>
  );
};
