import styled from 'styled-components';
import { Card, Flex } from '../../../../../../components';

export const Root = styled.div`
  position: relative;
`;

export const WrapperChildren = styled(Flex)`
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.neutral.white};
`;

export const WrapperDropdown = styled(Card)`
  ${({ theme }) => `
    padding: 0;
    right: 0;
    position: absolute;
    top: 100%;
    margin-top: 2px;
    min-width: 180px;
    z-index: 1000;
    width: 100%;
    border: 1px solid ${theme.palette.neutral.lightGrey};
    border-radius: 4px;
  `}
`;

export const Item = styled.div`
  h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    opacity: 0.5;
  }
  p {
    font-size: 14px;
    padding: 4px;
    line-break: anywhere;
  }
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  padding: 6px 10px;

  * {
    user-select: auto;
  }
`;

export const FilterItem = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;

  :hover {
    background: ${({ theme }) => theme.palette.neutral.veryLight};
  }

  svg:first-child {
    margin-right: 8px;

    :hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.neutral.lightGrey};

    :hover {
      color: ${({ theme }) => theme.palette.accent.red};
    }
  }

  svg[data-action='removeDefault'] {
    :hover {
      color: ${({ theme }) => theme.palette.accent.red};
    }
  }
`;
