import { IPostAuditFormRequest } from 'types/audits';
import { addFakeIdToCriteria } from './addFakeIdToCriteria';

export const addFakeIdToCriteriaInGroups = (
  groups: IPostAuditFormRequest['audit_form']['groups_attributes']
): IPostAuditFormRequest['audit_form']['groups_attributes'] => {
  return groups.map((group) => ({
    ...group,
    criteria_attributes: addFakeIdToCriteria(group.criteria_attributes),
    groups_attributes: addFakeIdToCriteriaInGroups(
      group.groups_attributes || []
    )
  })) as IPostAuditFormRequest['audit_form']['groups_attributes'];
};
