import React, { useContext, useState } from 'react';
import { IArea } from 'types/area';
import { DesktopFilters } from 'types/desktop';
import { ITeam } from 'types/team';

export interface IDesktopContext {
  areas: IArea[];
  setAreas: React.Dispatch<React.SetStateAction<IArea[]>>;
  myTeams: ITeam[];
  setMyTeams: React.Dispatch<React.SetStateAction<ITeam[]>>;
  selectedTeam: ITeam | undefined;
  setSelectedTeam: React.Dispatch<React.SetStateAction<ITeam | undefined>>;
  filters: DesktopFilters;
  defaultFilters: Partial<DesktopFilters>;
  savedFilters: Partial<DesktopFilters>;
  setFilters: React.Dispatch<React.SetStateAction<DesktopFilters>>;
  setDefaultFilters: React.Dispatch<
    React.SetStateAction<Partial<DesktopFilters>>
  >;
  setSavedFilters: React.Dispatch<
    React.SetStateAction<Partial<DesktopFilters>>
  >;
}

const initDesktopFilters = {
  area_ids: {
    value: []
  }
};

const DesktopContext = React.createContext<IDesktopContext | undefined>(
  undefined
);

export const DesktopContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [myTeams, setMyTeams] = useState<ITeam[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<ITeam>();
  const [defaultFilters, setDefaultFilters] = useState<Partial<DesktopFilters>>(
    {}
  );
  const [savedFilters, setSavedFilters] = useState<Partial<DesktopFilters>>({});
  const [filters, setFilters] = useState<DesktopFilters>(initDesktopFilters);

  return (
    <DesktopContext.Provider
      value={{
        areas,
        setAreas,
        myTeams,
        setMyTeams,
        selectedTeam,
        setSelectedTeam,
        filters,
        defaultFilters,
        savedFilters,
        setFilters,
        setDefaultFilters,
        setSavedFilters
      }}
    >
      {children}
    </DesktopContext.Provider>
  );
};

export const useDesktopContext = () => {
  const context = useContext(DesktopContext);
  if (!context) {
    throw new Error(
      'useDesktopContext must be inside a DesktopContextProvider with a value'
    );
  }
  return context;
};
