import React, { useEffect, useState } from 'react';
import { Box, Flex, Line } from 'components';
import styled from 'styled-components';
import { device } from 'styles/theme';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MenuIcon } from 'assets/icons/bx-x.svg';
import { IMenuItem, Menu } from 'components/Menu/Menu';
import { Footer } from 'layouts/components/Footer';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

interface ISideBar {
  logged: boolean;
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
  sidebarItems: Array<IMenuItem>;
}

const Wrapper = styled(Flex)<{ isSidebarOpen: boolean }>`
  transition: all 0.4s ease-in-out;
  z-index: 1000;
  position: relative;
  grid-area: sidebar;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 0px 12px  ${theme.palette.neutral.medium};
  `}
  display: none;

  @media ${device.tablet} {
    display: flex;
    position: absolute;
    height: 100%;
    width: 60%;
    ${({ isSidebarOpen }) => `
       margin-left: ${isSidebarOpen ? '0px' : '-100%'};
   `}
  }
`;
const Image = styled.img`
  width: 100%;
  cursor: pointer;
`;
const MenuToggleIcon = styled(MenuIcon)`
  align-self: flex-start;
  cursor: pointer;
  display: none;
  color: black;
  margin-top: 20px;

  @media ${device.tablet} {
    display: block;
  }
`;

export const SideBar = ({
  logged,
  onMenuIconClick,
  isSidebarOpen,
  sidebarItems
}: ISideBar) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState<Array<IMenuItem>>([]);

  const privatePathGuard = (
    isLogged: boolean,
    itemsToCheck: Array<IMenuItem>
  ) => {
    isLogged
      ? setMenu(itemsToCheck)
      : setMenu(itemsToCheck.filter((item) => isLogged === item.private));
  };

  useEffect(() => {
    privatePathGuard(logged, sidebarItems);
  }, [logged, sidebarItems]);

  return (
    <Wrapper
      isSidebarOpen={isSidebarOpen}
      alignItems="center"
      flexDirection="column"
    >
      <Box>
        <MenuToggleIcon onClick={onMenuIconClick} />
      </Box>
      <Box>
        <LogoIcon onClick={() => navigate('/app')} />
      </Box>
      <Line />
      <Menu onMenuIconClick={onMenuIconClick} items={menu} />
      <Footer />
    </Wrapper>
  );
};
