import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuditCriteria, getAuditThingsToImprove } from 'api/audits';
import { Flex, Table } from 'components';
import { H3, P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { dateFormat } from 'constants/dateFormats/dateFormat';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAuditCriterion, IDetailedAudit } from 'types/audits';
import { CommentWrapper, ThingToImproveRow } from './AuditPreview.styled';
import { EditAuditModal } from '../../AuditFormModal/EditAuditModal';
import { Buttons } from './components/Buttons/Buttons';

interface Props {
  audit: IDetailedAudit;
}

export const AuditPreview: React.FC<Props> = ({ audit }) => {
  const { t } = useTranslation();
  const [criteria, setCriteria] = useState<IAuditCriterion[]>([]);
  const [thingsToImprove, setThingsToImprove] = useState<string[]>([]);
  const [isEditAuditModalOpen, setIsEditAuditModalOpen] = useState(false);

  const sumOfCriteriaEvaluation = criteria.reduce((accumulator, object) => {
    return accumulator + object['evaluation'];
  }, 0);

  const handleGetAuditCriteria = useCallback(async () => {
    const auditCriteriaResponse = await getAuditCriteria(audit.id);

    if (auditCriteriaResponse.data) {
      setCriteria(auditCriteriaResponse.data);
    }
  }, [audit.id]);

  const handleGetAuditThingsToImprove = useCallback(async () => {
    const thingsToImproveResponse = await getAuditThingsToImprove(audit.id);

    if (thingsToImproveResponse.data) {
      setThingsToImprove(thingsToImproveResponse.data);
    }
  }, [audit.id]);

  useEffect(() => {
    handleGetAuditCriteria();
  }, [handleGetAuditCriteria]);

  useEffect(() => {
    handleGetAuditThingsToImprove();
  }, [handleGetAuditThingsToImprove]);

  const parsedData = criteria.map((criterion) => {
    return {
      number: criterion.number,
      evaluationCriteria: <Flex width="500px">{criterion.name}</Flex>,
      evaluation: <Flex justifyContent="center">{criterion.evaluation}</Flex>,
      attachments: <Buttons criterion={criterion} />,
      comment: (
        <CommentWrapper width="80px" overflow="hidden">
          {criterion.comment}
        </CommentWrapper>
      )
    };
  });

  const header: (string | JSX.Element | null)[] = [
    t('auditsView.previewTableHeaders.number'),
    t('auditsView.previewTableHeaders.evaluationCriteria'),
    t('auditsView.previewTableHeaders.evaluation'),
    '',
    t('auditsView.previewTableHeaders.comment')
  ];

  return (
    <Flex flexDirection="column">
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <P variant="h4">
          {audit.name}: {audit.area.name}
        </P>
        <Flex gap="30px" alignItems="center">
          <P variant="h4">{format(new Date(audit.date || ''), dateFormat)}</P>
          <Button
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() => {
              setIsEditAuditModalOpen(true);
            }}
          />
        </Flex>
      </Flex>

      <Table header={header} data={parsedData} />

      <Flex gap="4%" mt={-15}>
        <Flex width="67%" justifyContent="end">
          <P variant="h4">{t('auditsView.sum')}</P>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <P variant="h4">
            {sumOfCriteriaEvaluation} / {criteria.length}
          </P>
          <P variant="h4" color="darkGreen">
            {(sumOfCriteriaEvaluation / criteria.length) * 100}%
          </P>
        </Flex>
      </Flex>

      <Flex flexDirection="column">
        <H3 variant="h4">{t('auditsView.mainThingsToImprove')}</H3>
        {thingsToImprove.map((thingToImprove) => (
          <ThingToImproveRow key={`thingToImprove-list-${thingToImprove}`}>
            <P variant="body">{thingToImprove}</P>
          </ThingToImproveRow>
        ))}
      </Flex>

      <EditAuditModal
        isOpen={isEditAuditModalOpen}
        onCancelClick={() => setIsEditAuditModalOpen(false)}
        auditId={audit?.id}
      />
    </Flex>
  );
};
