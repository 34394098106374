import { Flex, Line, Table } from 'components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPencil, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Checkbox,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../CompanyAdministrationView/CompanyAdministrationView.styled';
import { BackButton } from 'components/BackButton';
import { H1, P } from 'components/Typography/Typography';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';

const Icon = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  ::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ isActive }) => (isActive ? 'green' : 'grey')};
  }
`;

interface Data {
  active: React.ReactNode;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  type: string;
  lastLogging: string;
  lastActivity: string;
  administrativeActivities: JSX.Element;
  accountActivity: JSX.Element;
  subordinate: JSX.Element;
  all: JSX.Element;
  accessToTranslations: JSX.Element;
  PE: JSX.Element;
  languagesEditing: JSX.Element;
  editing: JSX.Element;
}

export const EmployeesAndUsersSpecialPermissionsView = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const header: (string | { title: string; items: string[] } | null)[] = [
    ' ',
    t('employeesAndUsersView.tableHeaders.id'),
    t('employeesAndUsersView.tableHeaders.firstName'),
    t('employeesAndUsersView.tableHeaders.lastName'),
    t('employeesAndUsersView.tableHeaders.email'),
    t('employeesAndUsersView.tableHeaders.company'),
    t('employeesAndUsersView.tableHeaders.accountType'),
    t('employeesAndUsersView.tableHeaders.lastLogging'),
    t('employeesAndUsersView.tableHeaders.lastActivity'),
    t('employeesAndUsersView.tableHeaders.administrativeActivities'),
    t('employeesAndUsersView.tableHeaders.accountActivity'),
    {
      title: t(
        'employeesAndUsersView.tableHeaders.deletingTasksInAreas'
      ) as string,
      items: [
        t('employeesAndUsersView.tableHeaders.subordinate'),
        t('employeesAndUsersView.tableHeaders.all')
      ]
    },
    t('employeesAndUsersView.tableHeaders.accessToTranslations'),
    t('employeesAndUsersView.tableHeaders.PE'),
    t('employeesAndUsersView.tableHeaders.languagesEditing'),
    ''
  ];

  const button = (id: string) => (
    <Button
      variant="eucalyptus"
      onClick={() => {
        navigate(`/app/administration/employees-and-users/user/${id}/3`);
      }}
      icon={<FontAwesomeIcon icon={faEdit} />}
    />
  );

  const AdministrativeActivitiesForm = (id: string) => (
    <Flex justifyContent="center">
      <SwitchButton />
    </Flex>
  );

  const AccountActivityForm = (id: string) => (
    <Flex justifyContent="center">
      <Checkbox />
    </Flex>
  );
  const SubordinateForm = (id: string) => (
    <Flex justifyContent="center">
      <Checkbox />
    </Flex>
  );
  const AllForm = (id: string) => (
    <Flex justifyContent="center">
      <Checkbox />
    </Flex>
  );

  const AccessToTranslationsForm = (id: string) => (
    <Flex justifyContent="center">
      <SwitchButton />
    </Flex>
  );

  const PEForm = (id: string) => (
    <Flex justifyContent="center">
      <Button variant="secondary" icon={<FontAwesomeIcon icon={faPencil} />} />
    </Flex>
  );

  const LanguagesEditingForm = (id: string) => (
    <Flex>
      <Select options={[]} />
    </Flex>
  );

  const data: Data[] = [
    {
      active: <Icon isActive />,
      id: 'EF2211',
      firstName: 'Michał',
      lastName: 'Łopaciński',
      email: 'michał@email.com',
      company: 'Softmaker',
      type: 'admin',
      lastLogging: '23.07.2022, 16:27',
      lastActivity: '23.07.2022, 18:27',
      administrativeActivities: AdministrativeActivitiesForm('EF2211'),
      accountActivity: AccountActivityForm('EF2211'),
      subordinate: SubordinateForm('EF2211'),
      all: AllForm('EF2211'),
      accessToTranslations: AccessToTranslationsForm('EF2211'),
      PE: PEForm('EF2211'),
      languagesEditing: LanguagesEditingForm('EF2211'),
      editing: button('EF2211')
    },
    {
      active: <Icon isActive />,
      id: 'EF2211',
      firstName: 'Michał',
      lastName: 'Łopaciński',
      email: 'michał@email.com',
      company: 'Softmaker',
      type: 'admin',
      lastLogging: '23.07.2022, 16:27',
      lastActivity: '23.07.2022, 18:27',
      administrativeActivities: AdministrativeActivitiesForm('EF2211'),
      accountActivity: AccountActivityForm('EF2211'),
      subordinate: SubordinateForm('EF2211'),
      all: AllForm('EF2211'),
      accessToTranslations: AccessToTranslationsForm('EF2211'),
      PE: PEForm('EF2211'),
      languagesEditing: LanguagesEditingForm('EF2211'),
      editing: button('EF2211')
    }
  ];

  const switchableItems: (keyof Data)[] = [
    'id',
    'email',
    'company',
    'type',
    'lastLogging',
    'lastActivity'
  ];

  const additionalHeader = (
    <>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseCompany') ?? undefined}
        options={[]}
        withoutMargin
      />
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
        hideError
      />
      <P variant="body">{t('employeesAndUsersView.form.filter')}: </P>

      <Checkbox label="employeesAndUsersView.form.administrative" />
      <Checkbox label="employeesAndUsersView.form.translations" />

      <ColumnsOptions
        switchableItems={switchableItems}
        hiddenItems={hiddenItems}
        setHiddenItems={setHiddenItems}
        labelPrefix="employeesAndUsersView.tableHeaders."
      />
    </>
  );

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} color="red" textTransform="uppercase">
          {t('administration.administrationLabel')} -{' '}
          {t('administration.employeesAndUsers')} |{' '}
          {t('employeesAndUsersView.specialPermissions')}
        </H1>
      </Flex>
      <Line />
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        leftFixedKeys={[0, 1]}
        additionalHeaderElement={additionalHeader}
        hiddenItems={hiddenItems}
      />
    </Wrapper>
  );
};
