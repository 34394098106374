import { Flex, Line } from 'components';
import { H1 } from 'components/Typography/Typography';
import { Wrapper } from '../AdministrationAuditsView.styled';
import { useTranslation } from 'react-i18next';
import { AuditFormForm } from '../components/AuditFormForm/AuditFormForm';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { IAuditForm, IPostAuditFormRequest } from 'types/audits';
import { getAuditForm, patchAuditForm } from 'api/audits';
import { useCallback, useEffect, useState } from 'react';
import { Loader } from 'components/Loader/Loader';
import { parseGroupsToAttributes } from 'utilities/auditForm/parseGroupsToAttributes';
import { parseRemovedGroups } from 'utilities/auditForm/parseRemovedGroups';
import { parseRemovedCriteria } from 'utilities/auditForm/parseRemovedCriteria';

export const EditAuditFormView = () => {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    Partial<IPostAuditFormRequest['audit_form']>
  >({});
  const [selectedAuditForm, setSelectedAuditForm] = useState<IAuditForm>();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id: selectedAuditFormId } = useParams();

  if (!selectedAuditFormId) return <></>;

  const handleSubmitAuditForm = async (
    data: IPostAuditFormRequest['audit_form']
  ) => {
    const { groups_attributes, criteria_attributes, ...rest } = data;

    const payload = {
      audit_form: {
        ...rest,
        groups_attributes: parseRemovedGroups(
          defaultValues.groups_attributes || [],
          groups_attributes
        ),
        criteria_attributes: parseRemovedCriteria(
          defaultValues.criteria_attributes || [],
          criteria_attributes
        )
      }
    };

    const auditFormRes = await patchAuditForm(selectedAuditFormId, payload);

    if ('error' in auditFormRes) {
      toast.error(auditFormRes.error.message);
      return;
    }

    navigate('/app/administration/audits');
  };

  const handleLoadData = useCallback(async () => {
    setLoading(true);
    const auditFormRes = await getAuditForm(selectedAuditFormId);

    if ('error' in auditFormRes) {
      return;
    }

    setSelectedAuditForm(auditFormRes.data);

    const {
      areas,
      practice,
      author,
      people_authorized_to_edit,
      maximum_task_completion_time_iso8601,
      rating_scale,
      criteria,
      groups,
      methodology,
      ...rest
    } = auditFormRes.data;

    setDefaultValues({
      ...rest,
      area_ids: areas.map(({ id }) => id.toString()),
      practice_id: practice?.id?.toString(),
      author_id: author?.id?.toString(),
      person_authorized_to_edit_ids: people_authorized_to_edit?.map(({ id }) =>
        id.toString()
      ),
      maximum_task_completion_time: maximum_task_completion_time_iso8601,
      rating_scale_id: rating_scale?.id?.toString(),
      criteria_attributes: criteria.map((criterion) => ({ ...criterion })),
      groups_attributes: parseGroupsToAttributes(groups)
    });
    setLoading(false);
  }, [selectedAuditFormId]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <H1 m={2} variant={['h3', 'h2']}>
          {t('administrationAuditsView.editAuditForm')}
        </H1>
      </Flex>
      <Line />

      {loading ? (
        <Loader />
      ) : (
        <AuditFormForm
          handleSubmitAuditForm={handleSubmitAuditForm}
          defaultValues={defaultValues}
          selectedAuditForm={selectedAuditForm}
          setSelectedAuditForm={setSelectedAuditForm}
          showCopyButton
        />
      )}
    </Wrapper>
  );
};
