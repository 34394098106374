import styled from 'styled-components';
import { device } from 'styles/theme';

export const Wrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `}
  height: fit-content;
  grid-template-areas:
    'navbar'
    'content';

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'navbar'
      'content';
  }
`;
export const Content = styled.div`
  padding: 18px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.light};
  `};
  grid-area: content;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    padding: 8px;
  }
`;
