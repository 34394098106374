import { Flex } from 'components';
import { RatingScaleButton } from 'components/RatingScaleButtons/RatingScaleButton';
import { RatingScaleButtons } from 'components/RatingScaleButtons/RatingScaleButtons';
import ClickOutside from 'components/click-outside';
import { useState } from 'react';
import styled from 'styled-components';
import { device } from 'styles/theme';
import { IRatingScale, IRatingScaleButton } from 'types/ratingScales';

const CellWrapper = styled(Flex)`
  cursor: pointer;
  position: relative;
`;

const ButtonsWrapper = styled(Flex)`
  position: absolute;
  top: 70px;
  z-index: 10;

  @media ${device.tablet} {
    right: 0px;
  }
`;

interface Props {
  onChooseScore: (buttonId: number, criterionId: number | string) => void;
  criterionId: number | string;
  selectedButton?: IRatingScaleButton;
  cellHeight: number;
  ratingScale?: IRatingScale;
  onClick?: () => void;
  onClickOutside?: () => void;
}

export const AuditCriteriaNumberCell = ({
  onChooseScore,
  criterionId,
  selectedButton,
  cellHeight,
  ratingScale,
  onClick,
  onClickOutside
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <ClickOutside
      active={isActive}
      onClick={() => {
        setIsActive(false);
        onClickOutside?.();
      }}
    >
      <CellWrapper
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height={`${isActive ? cellHeight - 20 : cellHeight - 5}px`}
        onClick={() => {
          setIsActive((prevIsActive) => !prevIsActive);
          onClick?.();
        }}
      >
        <Flex justifyContent="center" alignItems="center" height="100%">
          {selectedButton && (
            <RatingScaleButton
              button={selectedButton}
              showImages={ratingScale?.button_type === 'picture'}
              useValues={ratingScale?.use_colored_values}
              borderRadius
            />
          )}
        </Flex>

        {isActive && (
          <ButtonsWrapper ml={`${-(ratingScale?.buttons?.length || 1) * 10}px`}>
            <RatingScaleButtons
              buttons={ratingScale?.buttons || []}
              showImages={ratingScale?.button_type === 'picture'}
              bordered={ratingScale?.border}
              borderRadius
              onButtonClick={(button) => {
                onChooseScore(Number(button.id), criterionId);
              }}
            />
          </ButtonsWrapper>
        )}
      </CellWrapper>
    </ClickOutside>
  );
};
