import request from './index';
import {
  IChangePassword,
  ILogin,
  ILoginResponse,
  IRegister,
  IRegisterResponse,
  IResetPassword
} from 'types/forms/auth';

export const login = (data: ILogin) =>
  request<ILoginResponse>({
    options: {
      url: '/login',
      method: 'post',
      data
    }
  });

export const register = (data: IRegister) =>
  request<IRegisterResponse>({
    options: {
      url: '/signup',
      method: 'post',
      data
    }
  });

export const confirmUser = async (token: string) =>
  await request({
    options: {
      url: '/confirmation?confirmation_token=' + token,
      method: 'get'
    }
  });

export const resetUserPassword = async (user: IResetPassword) =>
  await request({
    options: {
      url: '/password',
      method: 'post',
      data: JSON.stringify(user)
    }
  });

export const changeUserPassword = async (user: IChangePassword) =>
  await request({
    options: {
      url: '/password',
      method: 'patch',
      data: JSON.stringify(user)
    }
  });

export const logoutUser = async () =>
  await request({
    options: {
      url: '/logout',
      method: 'delete'
    }
  });
