import { INoteResponse, IPostNotes } from 'types/notes';
import request from './index';
import { serialize } from 'object-to-formdata';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const postNote = (data: IPostNotes) => {
  try {
    return request<INoteResponse>({
      options: {
        url: `/notes`,
        method: 'post',
        data: serialize(data)
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const patchNote = (id: number, data: IPostNotes) => {
  try {
    return request<INoteResponse>({
      options: {
        url: `/notes/${id}`,
        method: 'patch',
        data: serialize(data)
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const removeNote = (id: number) => {
  try {
    return request<{}>({
      options: {
        url: `/notes/${id}`,
        method: 'delete'
      },
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};
