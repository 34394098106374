import { deleteTeam, editTeam, getTeam, getTeams } from 'api/teams';
import { Avatar, Box, Flex, Line, Modal } from 'components';
import { ImagesPicker } from 'components/ImagesPicker/ImagesPicker';
import { H1, P } from 'components/Typography/Typography';
import {
  Button,
  Select,
  SwitchButton,
  TextareaField,
  TextField
} from 'components/_form';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getFile } from 'utilities/files';
import { Wrapper } from '../../CompanyAdministrationView/CompanyAdministrationView.styled';
import { BackButton } from 'components/BackButton';
import { IOption } from 'components/_form/Select/Select';
import { getUsers } from 'api/user';
import { getAreas } from 'api/areas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { ImageBox, StyledTable } from './TeamDetailsView.styled';
import { ITeamForm } from 'types/forms/team';
import { toast } from 'react-toastify';
import { fullName } from 'utilities/fullName';

interface IMemberOption extends IOption {
  photo?: string;
  role?: string;
}

interface ITeamsOption extends IOption {
  members: IMemberOption[];
}

interface Props {}

export const TeamDetailsView: React.FC<Props> = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [users, setUsers] = useState<IMemberOption[]>([]);
  const [teams, setTeams] = useState<ITeamsOption[]>([]);
  const [areas, setAreas] = useState<IOption[]>([]);
  const [availableUsers, setAvailableUsers] = useState<IMemberOption[]>([]);

  const { register, control, reset, watch, setValue, handleSubmit } = useForm();

  const { id } = useParams();

  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);

  const loadData = async () => {
    if (id) {
      const { data } = await getTeam(id);

      if (data) {
        const {
          id,
          image_urls,
          logo_url,
          leader,
          manager,
          areas,
          members,
          ...restData
        } = data;

        reset({
          ...restData,
          images: image_urls.length
            ? (await Promise.all(
                image_urls.map((image_url) => getFile(image_url))
              ).then((values) => {
                return values;
              })) || []
            : [],
          logo: logo_url ? await getFile(logo_url) : undefined,
          leader: { value: leader.id, label: leader.username },
          manager: { value: manager.id, label: manager.username },
          areas: areas.map((area) => ({ value: area.id, label: area.name })),
          members: members.map((member) => ({
            value: member.id,
            label: member.first_name + ' ' + member.last_name,
            role: member.role,
            photo: member.photo
          }))
        });
      }
    }
  };

  const loadOptions = async () => {
    const { data: usersData } = await getUsers();
    const usersOptions = usersData.map((user) => ({
      value: String(user.id),
      label: fullName(user.profile),
      role: user.role,
      photo: user.profile?.photo
    }));

    setUsers(usersOptions);

    const { data: areasData } = await getAreas();
    const areasOptions = areasData.map((area) => ({
      value: String(area.id),
      label: area.name
    }));

    setAreas(areasOptions);

    const { data: teamsData } = await getTeams();
    const teamsOptions = teamsData.map((team) => ({
      value: String(team.id),
      label: team.name,
      members: team.members.map((member) => ({
        value: String(member.id),
        label: member.first_name + ' ' + member.last_name,
        photo: member.photo,
        role: member.role
      }))
    }));

    setTeams(teamsOptions);
  };

  const onSubmit = handleSubmit(async (data: ITeamForm['team']) => {
    try {
      if (id) {
        const { leader, manager, areas, logo, members, ...restData } = data;

        const payload = {
          team: {
            ...restData,
            leader_id: leader?.value,
            manager_id: manager?.value,
            area_ids: areas?.map((area) => area.value),
            member_ids: members?.map((member) => member.value),
            logo: logo || null
          }
        };

        const { data: resultData } = await editTeam(id, payload);

        toast.success(t('common.success'));
      }
    } catch (e) {
      toast.error(t('anErrorOccurred'));
    }
  });

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    setAvailableUsers(
      users.filter(
        (user) =>
          !watch('members')?.some(
            (member: IOption) => String(member.value) === String(user.value)
          )
      )
    );
  }, [users, watch('members')]);

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} -{' '}
          {t('administration.employeesAndUsers')} |{' '}
          {t('employeesAndUsersView.form.edit')} - {watch('name') || ''}
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px">
        <Flex flexWrap="wrap" width="100%">
          <Box width={['100%', '50%']}>
            <ImagesPicker
              images={watch('images') || []}
              setImages={(images: File[]) => {
                setValue('images', images);
                onSubmit();
              }}
            />
          </Box>
          <Flex
            width={['100%', '50%']}
            gap="10px"
            flexDirection="column"
            pl={4}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex width="65%" flexDirection="column">
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  mb={2}
                  alignItems="end"
                >
                  <Flex flexDirection="column">
                    <P mb={1} variant="body">
                      {t('administration.active?')}
                    </P>
                    <SwitchButton
                      value={watch('state') === 'active'}
                      onChange={(value) => {
                        setValue('state', value ? 'active' : 'inactive');
                        onSubmit();
                      }}
                    />
                  </Flex>

                  <Flex gap="10px" alignItems="end">
                    <TextField
                      {...register('id_code')}
                      label="administration.areaCode"
                      onBlur={onSubmit}
                    />
                    <Button
                      variant="red"
                      icon={<FontAwesomeIcon icon={faPeopleGroup} size="xl" />}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    />
                  </Flex>

                  <Button
                    variant="green"
                    onClick={() => {
                      ref?.current?.click();
                    }}
                  >
                    {t('logo')}
                  </Button>
                </Flex>
                <TextField
                  {...register('name')}
                  label="profile.name"
                  onBlur={onSubmit}
                />
              </Flex>
              <Flex width="30%">
                <input
                  type="file"
                  ref={ref}
                  hidden
                  onChange={(e) => {
                    setValue('logo', e?.target?.files && e?.target?.files[0]);
                    onSubmit();
                  }}
                />
                <ImageBox
                  imageUrl={watch('logo') && URL.createObjectURL(watch('logo'))}
                  flexShrink="0"
                >
                  {!watch('logo') &&
                    t('employeesAndUsersView.form.photoPlaceholder')}
                </ImageBox>
              </Flex>
            </Flex>
            <TextareaField
              {...register('description')}
              label={t('administration.description') as string}
              rows={4}
              onBlur={onSubmit}
            />

            <Flex flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
              <Flex flexDirection="column" width="100%">
                <Flex flexWrap={['wrap', 'nowrap']} alignItems="flex-end">
                  <Controller
                    control={control}
                    name="areas"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isMulti
                        label={t('employeesAndUsersView.form.areas')}
                        selectedOptions={value}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={areas}
                      />
                    )}
                  />
                </Flex>
                <Flex
                  gap="10px"
                  flexWrap={['wrap', 'nowrap']}
                  alignItems="flex-end"
                >
                  <Controller
                    control={control}
                    name="leader"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('employeesAndUsersView.form.teamLeader')}
                        selectedOptions={value}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={users}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="manager"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('employeesAndUsersView.form.teamManager')}
                        selectedOptions={value}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={users}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex gap="10px">
              <Select
                label={t('tasks.addTeamMember')}
                onChange={(val) => {
                  setValue('members', [...watch('members'), val]);
                  onSubmit();
                }}
                selectedOptions={[]}
                options={availableUsers}
              />
              <Select
                label={t('tasks.addFromTeamOrGroup')}
                onChange={(val: ITeamsOption) => {
                  setValue(
                    'members',
                    [...watch('members'), ...val.members].filter(
                      (value, index, self) =>
                        index ===
                        self.findIndex(
                          (t) => String(t.value) === String(value.value)
                        )
                    )
                  );
                  onSubmit();
                }}
                selectedOptions={[]}
                options={teams}
              />
            </Flex>

            <StyledTable>
              <thead>
                <th>
                  <P variant="body">{t('tasks.teamMembers')}</P>
                </th>
                <th>
                  <P variant="body">{t('tasks.role/function')}</P>
                </th>
                <th></th>
              </thead>
              <tbody>
                {watch('members')?.map((member: IMemberOption) => (
                  <tr key={member.value}>
                    <td>
                      <Flex alignItems="center">
                        <Box mr={2}>
                          <Avatar
                            size="extra-small"
                            url={`${process.env.REACT_APP_API_URL}${member.photo}`}
                          />
                        </Box>
                        <P variant="body"> {member.label}</P>
                      </Flex>
                    </td>
                    <td align="center">
                      <Flex mt={2}>
                        <Select options={[]} />
                      </Flex>
                    </td>
                    <td>
                      <Button
                        variant="red"
                        icon={<FontAwesomeIcon icon={faUserSlash} />}
                        onClick={() => {
                          setValue(
                            'members',
                            watch('members').filter(
                              (filterMember: IOption) =>
                                filterMember.value !== member.value
                            )
                          );
                          onSubmit();
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </Flex>
        </Flex>
      </Flex>

      <Modal
        isOpen={isDeleteModalOpen}
        onCancelClick={() => {
          setIsDeleteModalOpen(false);
        }}
        headerTitle={t('employeesAndUsersView.form.areYouSureDeleteTeam')}
        mainButton={{
          action: async () => {
            if (id) {
              try {
                await deleteTeam(id);
                toast.success(t('common.success'));
                navigate(-1);
              } catch (e) {
                toast.error(t('anErrorOccurred'));
              }
            }
          },
          variant: 'eucalyptus',
          label: t('buttons.delete')
        }}
        isSecondButtonVisible
      />
    </Wrapper>
  );
};
