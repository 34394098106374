import { FC, useEffect, useState } from 'react';
import { Box, Flex, Spinner } from 'components';
import { Button, Checkbox, TextField } from 'components/_form';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from 'validation/authSchemas';
import { IRegister } from 'types/forms/auth';
import { register as registerRec } from '../api/auth';
import { ReactComponent as EmailIcon } from 'assets/icons/user.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/lock-password-fill.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IRegulations } from '../types/forms/regulation';
import { fetchRegulations } from '../api/regulations';

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
`;

export const RegisterView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [acceptedCommonRegulations, setAcceptedCommonRegulations] = useState<
    number[]
  >([]);
  const [regulations, setRegulations] = useState<IRegulations>({ data: [] });
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    formState: { errors }
  } = useForm<IRegister>({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: '',
        password: '',
        password_confirmation: '',
        regulation_ids: [],
        profile_attributes: {
          first_name: '',
          last_name: ''
        }
      }
    },
    resolver: yupResolver(registerSchema)
  });

  const handleCommonRegulations = (id: number) => {
    setAcceptedCommonRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const fullData = { ...data };
    fullData.user.regulation_ids = [
      ...data.user.regulation_ids,
      ...acceptedCommonRegulations
    ].filter((v, i, a) => a.indexOf(v) === i);

    try {
      const response = await registerRec(fullData);
      if (!response) return;
      localStorage.setItem('auth-token', response.token);
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      navigate('/app');
    } catch (err: any) {
      toast.error(err?.message || t('anErrorOccurred'));
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchRegulations().then((res) => setRegulations(res));
  }, []);

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      height="100%"
    >
      <form onSubmit={onSubmit}>
        <TextField
          label={t('common.first_name')}
          transparent
          icon={<EmailIcon />}
          {...register('user.profile_attributes.first_name')}
          error={t(errors?.user?.profile_attributes?.first_name?.message || '')}
        />
        <TextField
          label={t('common.last_name')}
          transparent
          icon={<EmailIcon />}
          {...register('user.profile_attributes.last_name')}
          error={t(errors?.user?.profile_attributes?.last_name?.message || '')}
        />
        <TextField
          label="common.email"
          transparent
          icon={<EmailIcon />}
          {...register('user.email')}
          error={t(errors?.user?.email?.message || '')}
        />
        <TextField
          type="password"
          label={t('auth.password')}
          transparent
          icon={<PasswordIcon />}
          {...register('user.password')}
          error={t(errors?.user?.password?.message || '')}
        />
        <TextField
          type="password"
          label={t('auth.password_confirmation')}
          transparent
          icon={<PasswordIcon />}
          {...register('user.password_confirmation')}
          error={t(errors?.user?.password_confirmation?.message || '')}
        />
        {regulations.data.map((field, index) =>
          field.required ? (
            // todo link
            <Controller
              key={field.id}
              control={control}
              name={`user.regulation_ids.${index}`}
              render={({ field: { onChange, value } }) => (
                <Box my={2}>
                  <Checkbox
                    label={field.name}
                    onChange={(val) => {
                      onChange(val.target.checked ? field.id : false);
                    }}
                    checked={!!value}
                  />
                </Box>
              )}
            />
          ) : (
            <Box my={2} key={field.id}>
              <Checkbox
                label={field.name}
                onChange={() => handleCommonRegulations(field.id)}
                checked={
                  !!acceptedCommonRegulations.find((el) => el === field.id)
                }
              />
            </Box>
          )
        )}

        <Box my={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              variant="blue"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
            >
              {t('auth.register')}
            </Button>
          )}
        </Box>
      </form>
    </Wrapper>
  );
};
