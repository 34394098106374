import { Flex } from 'components';
import { TableCell } from '../../AuditFormForm.styled';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Select, TextField } from 'components/_form';
import { IRatingScale } from 'types/ratingScales';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IPostAuditFormRequest } from 'types/audits';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCube, faCubes } from '@fortawesome/free-solid-svg-icons';
import { CriteriaRow } from './components/CriteriaRow';
import { useEffect } from 'react';

interface Props {
  ratingScaleButtons?: IRatingScale['buttons'];
  prefix?: string;
  indentNumber: number;
  addNewGroupsAttributes: () => void;
  removeGroupsAttributes: () => void;
  defaultNumber: number;
}

export const CriteriaTable: React.FC<Props> = ({
  ratingScaleButtons,
  prefix = '',
  indentNumber,
  addNewGroupsAttributes,
  removeGroupsAttributes,
  defaultNumber
}) => {
  const { t } = useTranslation();

  const { control, register, watch, setValue } =
    useFormContext<IPostAuditFormRequest['audit_form']>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${prefix}groups_attributes` as 'groups_attributes'
  });

  let groupDefaultNumber = 0;

  const addNewSubGroupsAttributes = () => {
    append({
      order_number: fields.length + 1,
      name: '',
      show_results: true,
      groups_attributes: [],
      criteria_attributes: []
    });
  };

  const {
    fields: fieldsCriteriaAttributes,
    append: appendCriteriaAttributes,
    remove: removeCriteriaAttributes
  } = useFieldArray({
    control,
    name: `${prefix}criteria_attributes` as 'criteria_attributes'
  });

  let criteriaDefaultNumber = 0;

  const addNewCriteriaAttributes = () => {
    appendCriteriaAttributes({
      order_number: fieldsCriteriaAttributes.length + 1,
      content: '',
      weight: 1,
      tips: '',
      blocked_rating_button_ids: []
    });
  };

  useEffect(() => {
    setValue(
      `${prefix}.nr` as 'groups_attributes.0.nr',
      watch(
        `${prefix}.omit_in_numbering` as 'groups_attributes.0.omit_in_numbering'
      )
        ? ''
        : watch(
            `${prefix}.set_number_manually` as 'groups_attributes.0.set_number_manually'
          )
        ? watch(`${prefix}.nr` as 'groups_attributes.0.nr')
        : `${defaultNumber + 1}`
    );

    setValue(
      `${prefix}.order_number` as 'groups_attributes.0.order_number',
      defaultNumber + 1
    );
  }, [
    defaultNumber,
    prefix,
    setValue,
    watch,
    watch(
      `${prefix}.omit_in_numbering` as 'groups_attributes.0.omit_in_numbering'
    ),
    watch(
      `${prefix}.set_number_manually` as 'groups_attributes.0.set_number_manually'
    )
  ]);

  return (
    <Flex mt={4} flexDirection="column" alignItems="end" width="100%">
      <Flex justifyContent="end" width="100%">
        <TableCell colored width="3%" justifyContent="center">
          <FontAwesomeIcon icon={faGripVertical} size="2x" color="grey" />
        </TableCell>
        <TableCell colored width="7%" justifyContent="center">
          <Controller
            control={control}
            name={`${prefix}.nr` as 'groups_attributes.0.nr'}
            render={({ field: { value, onChange } }) => (
              <TextField
                disabled={
                  !watch(
                    `${prefix}.set_number_manually` as 'groups_attributes.0.set_number_manually'
                  ) ||
                  watch(
                    `${prefix}.omit_in_numbering` as 'groups_attributes.0.omit_in_numbering'
                  )
                }
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />

          <Flex flexDirection="column" gap="3px" ml={1}>
            <Controller
              control={control}
              name={
                `${prefix}.set_number_manually` as 'groups_attributes.0.set_number_manually'
              }
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  sizeVariant="small"
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  id={`${prefix}-setNrManuallyTooltip`}
                  tooltip={t('administrationAuditsView.setNrManually') || ''}
                />
              )}
            />
            <Controller
              control={control}
              name={
                `${prefix}.omit_in_numbering` as 'groups_attributes.0.omit_in_numbering'
              }
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  sizeVariant="small"
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                  id={`${prefix}-omitInNumberingTooltip`}
                  tooltip={t('administrationAuditsView.omitInNumbering') || ''}
                />
              )}
            />
          </Flex>
        </TableCell>
        <TableCell
          colored
          width={`${66 - indentNumber * 4}%`}
          justifyContent="space-between"
        >
          <Flex gap="10px" flexGrow={1}>
            <Flex gap="3px">
              <Flex width="100px">
                <Select options={[]} withoutMargin />
              </Flex>
            </Flex>
            <TextField type="number" width="60px" />
            <TextField {...register(`${prefix}.name` as 'name')} />
          </Flex>

          <Flex
            width={`${(ratingScaleButtons?.length || 1) * 50}px`}
            justifyContent="center"
            mr="-6px"
          ></Flex>
        </TableCell>
        <TableCell colored width="24%" justifyContent="end" gap="10px">
          <Flex gap="20px">
            <Controller
              control={control}
              name={
                `${prefix}.show_results` as 'groups_attributes.0.show_results'
              }
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('administrationAuditsView.showResults')}
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              )}
            />

            <Flex maxWidth="140px">
              <Controller
                control={control}
                name="radar_chart_order_number"
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    label={t('administrationAuditsView.radarChartOnThisLevel')}
                    checked={
                      value === prefix.replaceAll('groups_attributes.', '')
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChange(prefix.replaceAll('groups_attributes.', ''));
                      } else {
                        onChange(undefined);
                      }
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>

          <Button
            opacity="60%"
            variant="red"
            icon={<FontAwesomeIcon icon={faTrashCan} size="xl" />}
            onClick={removeGroupsAttributes}
          />
        </TableCell>
      </Flex>

      {fieldsCriteriaAttributes.map((field, index) => {
        if (index > 0) {
          const numberToAdd = watch(
            `${prefix}.criteria_attributes.${
              index - 1
            }.omit_in_numbering` as 'criteria_attributes.0.omit_in_numbering'
          )
            ? 0
            : 1;
          criteriaDefaultNumber = criteriaDefaultNumber + numberToAdd;
        }

        return (
          <Flex key={field.id} flexDirection="column" width="100%">
            <CriteriaRow
              defaultNumber={criteriaDefaultNumber}
              prefix={`${prefix}.criteria_attributes.${index}.`}
              indentNumber={indentNumber}
              removeCriteriaAttributes={() => {
                removeCriteriaAttributes(index);
              }}
              ratingScaleButtons={ratingScaleButtons}
            />
          </Flex>
        );
      })}

      <TableCell
        width={`${100 - indentNumber * 4}%`}
        mt={2}
        justifyContent="end"
        gap="10px"
      >
        <Button variant="green" onClick={addNewCriteriaAttributes}>
          <Flex alignItems="center" gap="8px">
            <FontAwesomeIcon icon={faQuestionCircle} />
            {t('administrationAuditsView.criterion')}
          </Flex>
        </Button>
        <Button variant="blue" onClick={addNewSubGroupsAttributes}>
          <Flex alignItems="center" gap="8px">
            <FontAwesomeIcon icon={faCubes} />
            {t('administrationAuditsView.subgroup')}
          </Flex>
        </Button>
        <Button onClick={addNewGroupsAttributes}>
          <Flex alignItems="center" gap="8px">
            <FontAwesomeIcon icon={faCube} />
            {t('administrationAuditsView.group')}
          </Flex>
        </Button>
      </TableCell>

      {fields.map((field, index) => {
        if (index > 0) {
          const numberToAdd = watch(
            `${prefix}.groups_attributes.${
              index - 1
            }.omit_in_numbering` as 'groups_attributes.0.omit_in_numbering'
          )
            ? 0
            : 1;
          groupDefaultNumber = groupDefaultNumber + numberToAdd;
        }

        return (
          <Flex key={field.id} flexDirection="column" width="100%">
            <CriteriaTable
              defaultNumber={groupDefaultNumber}
              prefix={`${prefix}groups_attributes.${index}.`}
              indentNumber={indentNumber + 1}
              removeGroupsAttributes={() => {
                remove(index);
              }}
              addNewGroupsAttributes={addNewSubGroupsAttributes}
              ratingScaleButtons={ratingScaleButtons}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};
