import React, { useCallback, useContext, useState } from 'react';
import { formatDate } from '../utilities/date';
import {
  EvaluationFilters,
  IEvaluation,
  IDetailedEvaluation,
  initEvaluationFilters
} from 'types/evaluations';
import { IArea } from 'types/area';
import { IBlock } from 'types/methologies';
import { getEvaluations } from 'api/evaluations';
import { IAudit, IDetailedAudit } from 'types/audits';
import { getAudits } from 'api/audits';

export interface IEvaluationContext {
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  areas: IArea[];
  setAreas: React.Dispatch<React.SetStateAction<IArea[]>>;
  evaluations: IEvaluation[];
  setEvaluations: React.Dispatch<React.SetStateAction<IEvaluation[]>>;
  selectedBlock: IBlock | undefined;
  setSelectedBlock: React.Dispatch<React.SetStateAction<IBlock | undefined>>;
  selectedEvaluations: IDetailedEvaluation[];
  setSelectedEvaluations: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation[]>
  >;
  selectedEvaluation: IDetailedEvaluation | undefined;
  setSelectedEvaluation: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation | undefined>
  >;
  evaluationToEdit: IDetailedEvaluation | undefined;
  setEvaluationToEdit: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation | undefined>
  >;
  evaluationToEditId: IDetailedEvaluation['id'] | undefined;
  setEvaluationToEditId: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation['id'] | undefined>
  >;
  evaluationsToLaunch: IDetailedEvaluation[];
  setEvaluationsToLaunch: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation[]>
  >;
  filters: EvaluationFilters;
  defaultFilters: Partial<EvaluationFilters>;
  savedFilters: Partial<EvaluationFilters>;
  setFilters: React.Dispatch<React.SetStateAction<EvaluationFilters>>;
  setDefaultFilters: React.Dispatch<
    React.SetStateAction<Partial<EvaluationFilters>>
  >;
  setSavedFilters: React.Dispatch<
    React.SetStateAction<Partial<EvaluationFilters>>
  >;
  getDateLabel: (
    filterName: string,
    from: Date | null,
    to: Date | null
  ) => string;
  audits: IAudit[];
  setAudits: React.Dispatch<React.SetStateAction<IAudit[]>>;
  selectedAudit: IDetailedAudit | undefined;
  setSelectedAudit: React.Dispatch<
    React.SetStateAction<IDetailedAudit | undefined>
  >;
  selectedAudits: IDetailedAudit[];
  setSelectedAudits: React.Dispatch<React.SetStateAction<IDetailedAudit[]>>;
  selectedAuditId?: IAudit['id'];
  setSelectedAuditId: React.Dispatch<
    React.SetStateAction<IAudit['id'] | undefined>
  >;
  isAuditCriteriaModalOpen: boolean;
  setIsAuditCriteriaModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEditAuditModalOpen: boolean;
  setIsEditAuditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateAuditModalOpen: boolean;
  setIsCreateAuditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLoadEvaluations: () => void;
  handleLoadAudits: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  auditsPage: number;
  setAuditsPage: React.Dispatch<React.SetStateAction<number>>;
  auditsPerPage: number;
  setAuditsPerPage: React.Dispatch<React.SetStateAction<number>>;
  auditsPaginationTotal: number;
  setAuditsPaginationTotal: React.Dispatch<React.SetStateAction<number>>;
}

const EvaluationContext = React.createContext<IEvaluationContext | undefined>(
  undefined
);

export const EvaluationContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [evaluations, setEvaluations] = useState<IEvaluation[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<IBlock>();
  const [selectedEvaluation, setSelectedEvaluation] = useState<
    IDetailedEvaluation | undefined
  >();
  const [selectedEvaluations, setSelectedEvaluations] = useState<
    IDetailedEvaluation[]
  >([]);
  const [evaluationToEdit, setEvaluationToEdit] =
    useState<IDetailedEvaluation>();
  const [evaluationToEditId, setEvaluationToEditId] =
    useState<IDetailedEvaluation['id']>();
  const [evaluationsToLaunch, setEvaluationsToLaunch] = useState<
    IDetailedEvaluation[]
  >([]);
  const [defaultFilters, setDefaultFilters] = useState<
    Partial<EvaluationFilters>
  >({});
  const [savedFilters, setSavedFilters] = useState<Partial<EvaluationFilters>>(
    {}
  );
  const [filters, setFilters] = useState<EvaluationFilters>(
    initEvaluationFilters
  );
  const [audits, setAudits] = useState<IAudit[]>([]);
  const [selectedAudit, setSelectedAudit] = useState<
    IDetailedAudit | undefined
  >();
  const [selectedAudits, setSelectedAudits] = useState<IDetailedAudit[]>([]);
  const [selectedAuditId, setSelectedAuditId] = useState<IAudit['id']>();
  const [isAuditCriteriaModalOpen, setIsAuditCriteriaModalOpen] =
    useState(false);
  const [isEditAuditModalOpen, setIsEditAuditModalOpen] = useState(false);
  const [isCreateAuditModalOpen, setIsCreateAuditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auditsPage, setAuditsPage] = useState(1);
  const [auditsPerPage, setAuditsPerPage] = useState(10);
  const [auditsPaginationTotal, setAuditsPaginationTotal] = useState(0);

  const getDateLabel = (
    filterName: string,
    from: Date | null,
    to: Date | null
  ) => {
    if (!from && !to) return '';
    let label = filterName + ' -';
    if (from) label += ' od: ' + formatDate(from);
    if (to) label += ' do: ' + formatDate(to);
    return label;
  };

  const handleLoadEvaluations = useCallback(async () => {
    const { area_ids } = filters;
    const filtersOrganized = {
      area_ids: area_ids.value
    };

    const { data } = await getEvaluations({
      filters: filtersOrganized
    });
    setEvaluations(data);
  }, [filters, setEvaluations]);

  const handleLoadAudits = useCallback(async () => {
    setLoading(true);
    const { area_ids } = filters;
    const filtersOrganized = {
      area_ids: area_ids.value,
      page: auditsPage,
      per_page: auditsPerPage
    };

    const { data, pagination } = await getAudits({
      filters: filtersOrganized
    });

    setAudits(data);

    if (pagination?.count) {
      setAuditsPaginationTotal(Number(pagination.count));
    }

    setLoading(false);
  }, [auditsPage, auditsPerPage, filters, setAudits]);

  return (
    <EvaluationContext.Provider
      value={{
        tabIndex,
        setTabIndex,
        areas,
        setAreas,
        evaluations,
        setEvaluations,
        selectedBlock,
        setSelectedBlock,
        selectedEvaluations,
        setSelectedEvaluations,
        selectedEvaluation,
        setSelectedEvaluation,
        evaluationToEdit,
        setEvaluationToEdit,
        evaluationToEditId,
        setEvaluationToEditId,
        evaluationsToLaunch,
        setEvaluationsToLaunch,
        filters,
        defaultFilters,
        savedFilters,
        setFilters,
        setDefaultFilters,
        setSavedFilters,
        getDateLabel,
        audits,
        setAudits,
        selectedAudit,
        setSelectedAudit,
        selectedAudits,
        setSelectedAudits,
        selectedAuditId,
        setSelectedAuditId,
        isAuditCriteriaModalOpen,
        setIsAuditCriteriaModalOpen,
        isEditAuditModalOpen,
        setIsEditAuditModalOpen,
        isCreateAuditModalOpen,
        setIsCreateAuditModalOpen,
        handleLoadEvaluations,
        handleLoadAudits,
        loading,
        setLoading,
        auditsPage,
        setAuditsPage,
        auditsPerPage,
        setAuditsPerPage,
        auditsPaginationTotal,
        setAuditsPaginationTotal
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};

export const useEvaluationContext = () => {
  const context = useContext(EvaluationContext);
  if (!context) {
    throw new Error(
      'useEvaluationContext must be inside a EvaluationContextProvider with a value'
    );
  }
  return context;
};
