import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { useState } from 'react';
import { TabPanel } from 'react-tabs';
import { theme } from 'styles';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

interface Props {
  document_urls?: string[];
}

export const Examples: React.FC<Props> = ({ document_urls }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  return (
    <CustomTabs selectedTabClassName="is-selected" style={{ display: 'flex' }}>
      <CustomTabList style={{ width: '180px' }}>
        <Flex flexDirection="column" width="180px">
          {document_urls?.map((document_url) => (
            <CustomTab
              key={document_url}
              bgColor={theme.palette.neutral.light}
              color={theme.palette.neutral.dark}
            >
              {document_url.substring(document_url.lastIndexOf('/') + 1)}
            </CustomTab>
          ))}
        </Flex>
      </CustomTabList>
      {document_urls?.map((document_url) => (
        <TabPanel key={document_url}>
          {document_url.includes('.mp4') ? (
            <video
              id="video"
              controls={true}
              preload="none"
              width="100%"
              poster=""
            >
              <source id="mp4" src={document_url} type="video/mp4" />

              <p>Your user agent does not support the HTML5 Video element.</p>
            </video>
          ) : document_url.includes('.pdf') ? (
            <Document
              file={process.env.REACT_APP_API_URL + document_url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {[...Array(numPages)].map((x, i) => (
                <Page pageNumber={i + 1} key={i + 1} />
              ))}
            </Document>
          ) : (
            <a
              href={process.env.REACT_APP_API_URL + document_url}
              target="_blank" rel="noreferrer"
            >
              {document_url.substring(document_url.lastIndexOf('/') + 1)}
            </a>
          )}
        </TabPanel>
      ))}

      <TabPanel></TabPanel>
    </CustomTabs>
  );
};
