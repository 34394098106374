import styled from 'styled-components';
import React from 'react';
import { Flex } from 'components/Flex';
import { IRealizationProgress } from 'types/realizationProgresses';

const colors = ['#e9511e', '#ff8f2a', '#f6bb00', '#94c11f', '#00a0e4'];

const Entry = styled.button<{ isActive?: boolean; bgColor: string }>`
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 20px;
  color: ${({ theme }) => theme.palette.neutral.white};
  background-color: ${({ isActive, theme, bgColor }) =>
    isActive
      ? bgColor || theme.palette.accent.green
      : theme.palette.neutral.lightGrey};
`;

export interface Props {
  activeAll?: boolean;
  activeLetters?: string[];
  handleClickKind?: (kind: string) => void;
  onLetterHover?: (kind: string) => void;
  realizationProgress?: IRealizationProgress;
  onLetterMouseLeave: () => void;
  availableLetter: string;
}

export const DMAIC: React.FC<Props> = ({
  activeAll,
  activeLetters,
  handleClickKind,
  realizationProgress,
  onLetterHover,
  onLetterMouseLeave,
  availableLetter
}) => {
  return (
    <Flex gap="2px">
      {realizationProgress?.kind.split('').map((letter, i) => (
        <Entry
          key={`DMAIC-realizationProgress-${letter}`}
          type="button"
          isActive={activeAll || activeLetters?.includes(letter)}
          onClick={() => {
            if (
              !(activeAll || activeLetters?.includes(letter)) &&
              availableLetter === letter
            ) {
              handleClickKind?.(letter);
            }
          }}
          onMouseEnter={() => {
            onLetterHover?.(letter);
          }}
          onMouseLeave={onLetterMouseLeave}
          bgColor={colors[i % 5]}
        >
          {letter}
        </Entry>
      ))}
    </Flex>
  );
};
