import { DatePicker, Flex, Line } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { H2, H4, P } from 'components/Typography/Typography';
import {
  Button,
  FieldLabel,
  SwitchButton,
  TextField,
  TextareaField
} from 'components/_form';
import {
  MainSection,
  TitleContainer,
  IconButtonContainer
} from './FormEvaluation.styled';
import {
  faCirclePlay,
  faClipboard,
  faCopy,
  faPaperPlane
} from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faClockRotateLeft,
  faDownload,
  faFilePen,
  faPeopleGroup,
  faPersonRunning,
  faTrashCan,
  faEdit,
  faPeopleRoof,
  faPeopleLine,
  faCalendar
} from '@fortawesome/free-solid-svg-icons';
import { theme } from 'styles';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'components/_form';
import { IOption } from 'components/_form/Select/Select';
import { getPractices } from 'api/methodologies';
import {
  NestedSelect,
  IOption as OptionForSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { EvaluationStatusModal } from '../EvaluationStatusModal/EvaluationStatusModal';
import { CriteriaListModal } from '../CriteriaListModal/CriteriaListModal';
import { Methodologies } from 'types/methologies';
import {
  makeMethodologyPracticeTree,
  parseMethodologyPracticeTreeForSelect
} from 'utilities/practices';
import { DescriptionModal } from 'components/_form/DescriptionModal/DescriptionModal';
import { getEvaluations } from 'api/evaluations';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';
import { flattenOptions } from 'components/_form/NestedSelect/utilities';
import { hourChecker, minuteChecker } from 'utilities/timePicker';
import { LabelPosition } from 'components/_form/TextField/TextField';
import { format } from 'date-fns';
import { getUsers } from 'api/user';
import { getTeams } from 'api/teams';
import { ITeam } from 'types/team';
import { EvaluationParticipantsModal } from '../EvaluationParticipantsModal/EvaluationParticipantsModal';
import { FormDataControl } from '../CreateEvaluationModal';
import { fullName } from 'utilities/fullName';
import { TasksConnectedWithEvaluationModal } from 'components/TasksConnectedWithEvaluationModal/TasksConnectedWithEvaluationModal';
import { PadlockButton } from 'components/_form/PadlockButton/PadlockButton';
import { getUserFunctions } from 'api/userFunctions';
import { IDetailedEvaluation } from 'types/evaluations';
import { RealizationProgress } from 'components/RealizationProgress/RealizationProgress';
import { NotesModal } from 'components/NotesModal/NotesModal';
import { IconButton } from '../../IconButton/IconButton';
import { DeleteEvaluationModal } from '../DeleteEvaluationModal/DeleteEvaluationModal';

interface Props {
  selectedPractice: OptionForSelect[];
  setSelectedPractice: React.Dispatch<React.SetStateAction<OptionForSelect[]>>;
  canChangeEvaluationStatus?: boolean;
  selectedEvaluation?: IDetailedEvaluation;
  setSelectedEvaluation?: Dispatch<
    SetStateAction<IDetailedEvaluation | undefined>
  >;
  handleSubmitEvaluationForm: (
    data: FormDataControl,
    closeOnSubmit?: boolean
  ) => Promise<IDetailedEvaluation | undefined>;
  handleDeleteEvaluation?: () => {};
}

export interface ITeamOption extends IOption {
  members: ITeam['members'];
}

export const FormEvaluation: React.FC<Props> = ({
  selectedPractice,
  setSelectedPractice,
  canChangeEvaluationStatus = true,
  selectedEvaluation,
  setSelectedEvaluation,
  handleSubmitEvaluationForm,
  handleDeleteEvaluation
}) => {
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [methodologyTree, setMethodologyTree] = useState<Methodologies>({});
  const [methodologyTreeForSelect, setMethodologyTreeForSelect] = useState<
    OptionForSelect[]
  >([]);
  const [isEvaluationStatusModalOpen, setIsEvaluationStatusModalOpen] =
    useState(false);
  const [isCriteriaListModalOpen, setIsCriteriaListModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [evaluationsToRelation, setEvaluationsToRelation] = useState<IOption[]>(
    []
  );
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [areas, setAreas] = useState<OptionForSelect[]>([]);
  const [usersList, setUsersList] = useState<IOption[]>([]);
  const [teamsList, setTeamsList] = useState<ITeamOption[]>([]);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [userFunctionsList, setUserFunctionsList] = useState<IOption[]>([]);

  const { t } = useTranslation();
  const ctx = useEvaluationContext();

  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<FormDataControl>();

  const evaluationTypes: IOption[] = [
    {
      label: t('evaluationsView.evaluationTypes.target') as string,
      value: 'target'
    },
    {
      label: t('evaluationsView.evaluationTypes.baseline') as string,
      value: 'baseline'
    },
    {
      label: t('evaluationsView.evaluationTypes.current') as string,
      value: 'current'
    },
    {
      label: t('evaluationsView.evaluationTypes.derivative') as string,
      value: 'derivative'
    },
    {
      label: t('evaluationsView.evaluationTypes.normal') as string,
      value: 'normal'
    }
  ];

  const handleGetAreas = useCallback(async () => {
    const areasData = ctx.areas;

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ctx.areas)]);

  const handleGetPractices = useCallback(async () => {
    const { data } = await getPractices();

    const methodologyObject = makeMethodologyPracticeTree(data);
    setMethodologyTree(methodologyObject);

    const treeForSelect =
      parseMethodologyPracticeTreeForSelect(methodologyObject);
    setMethodologyTreeForSelect(treeForSelect);
  }, []);

  const handleSelectedAreaIdChange = useCallback(() => {
    const selectedAreaIds = ctx.filters.area_ids.value;
    if (selectedAreaIds.length) {
      flattenOptions(areas).map((area) => {
        if (area.value && selectedAreaIds.includes(Number(area.value))) {
          setValue('area_id', area.value);
        }
      });
    }
  }, [ctx.filters.area_ids.value, areas]);

  const handleGetEvaluations = useCallback(async () => {
    const evaluationsResponse = await getEvaluations({
      filters: {
        area_ids: watch('area_id') ? [Number(watch('area_id'))] : []
      }
    });

    if (evaluationsResponse.data) {
      setEvaluationsToRelation(
        evaluationsResponse.data.map((evaluation) => ({
          label: evaluation.name,
          value: evaluation.id.toString()
        }))
      );
    }
  }, [watch('area_id')]);

  const handleGetUsers = async () => {
    const { data } = await getUsers();

    setUsersList(
      data.map((user) => ({
        label: fullName(user.profile),
        value: String(user.id)
      }))
    );
  };

  const handleGetTeams = async () => {
    const teamsReponse = await getTeams();

    setTeamsList(
      teamsReponse.data.map((team) => ({
        value: team.id?.toString(),
        label: team.name,
        members: team.members
      }))
    );
  };

  const hadnleFunctionsOptions = useCallback(async () => {
    const { data } = await getUserFunctions();

    setUserFunctionsList(
      data.map((functionItem) => ({
        value: functionItem.id,
        label: functionItem.name
      }))
    );
  }, []);

  const handleLaunchEvaluation = async () => {
    const EvaluationData = await handleSubmitEvaluationForm(getValues(), false);

    if (EvaluationData) {
      ctx.setEvaluationToEditId(EvaluationData.id);
      ctx.setSelectedBlock(EvaluationData.practice?.blocks[0]);
    }
  };

  useEffect(() => {
    hadnleFunctionsOptions();
  }, [hadnleFunctionsOptions]);

  useEffect(() => {
    handleGetPractices();
  }, [handleGetPractices]);

  useEffect(() => {
    handleGetAreas();
  }, [handleGetAreas]);

  useEffect(() => {
    handleSelectedAreaIdChange();
  }, [handleSelectedAreaIdChange]);

  useEffect(() => {
    handleGetEvaluations();
  }, [handleGetEvaluations]);

  useEffect(() => {
    handleGetUsers();
    handleGetTeams();
  }, []);

  const groupOptions = [
    {
      label: (
        <Flex gap="5px">
          <FontAwesomeIcon
            icon={faPeopleRoof}
            color={theme.palette.accent.blue}
          />
          {t('evaluationsView.management')}
        </Flex>
      ),
      value: 'management'
    },
    {
      label: (
        <Flex gap="5px">
          <FontAwesomeIcon
            icon={faPeopleLine}
            color={theme.palette.accent.red}
          />
          {t('evaluationsView.operators')}
        </Flex>
      ),
      value: 'operators'
    }
  ];

  return (
    <>
      <MainSection flexDirection={['column', 'row']}>
        <Flex flexDirection="column" width={['100%', '50%']} gap="8px">
          <Flex width="95%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="65%">
              <NestedSelect
                label={t('createTaskView.practice')}
                selectedOptions={selectedPractice}
                setSelectedOptions={setSelectedPractice}
                options={methodologyTreeForSelect}
                isOneOptionSelect={true}
              />
            </Flex>
            <Flex flexDirection="column" width="30%">
              <Controller
                control={control}
                name="version"
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    label={t('createTaskView.version')}
                    selectedOptions={value}
                    options={[{ value: '3.0-GW', label: '3.0-GW' }]}
                    withoutMargin
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="100%">
              <TextareaField
                label={t('createTaskView.evaluationName')}
                width="95%"
                {...register('name')}
              />
            </Flex>
          </Flex>
          <Flex width="100%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="95%">
              <TextareaField
                {...register('description')}
                label={t('createTaskView.description') || ''}
                rows={5}
                error={t(errors?.description?.message || '')}
                disabled
                ExternalItem={
                  <Button
                    variant="secondary"
                    icon={
                      <FontAwesomeIcon color="grey" size="2x" icon={faEdit} />
                    }
                    onClick={() => {
                      setIsDescriptionModalOpen(
                        (prevIsDescriptionModalOpen) =>
                          !prevIsDescriptionModalOpen
                      );
                    }}
                  />
                }
              />
            </Flex>
          </Flex>
          <Flex width="95%" flexDirection="row" justifyContent="space-between">
            <Flex flexDirection="column" width="30%">
              <Controller
                control={control}
                name="group"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={t('createTaskView.evaluationOnLevel')}
                    withoutMargin
                    selectedOptions={groupOptions.find(
                      (groupOption) => groupOption.value === value
                    )}
                    onChange={(option: IOption) => {
                      onChange(option.value);
                    }}
                    options={groupOptions}
                  />
                )}
              />
            </Flex>

            <Flex flexDirection="column" width="65%">
              <Controller
                control={control}
                name="related_to_evaluation_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={t('createTaskView.associateWith')}
                    withoutMargin
                    selectedOptions={evaluationsToRelation.find(
                      (evaluationToRelation) =>
                        evaluationToRelation.value === value
                    )}
                    onChange={(option: IOption) => {
                      onChange(option.value);
                    }}
                    options={evaluationsToRelation}
                  />
                )}
              />
            </Flex>
          </Flex>

          <Flex
            width="95%"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Flex flexDirection="column" width="30%">
              <FieldLabel htmlFor="priority">
                {t('createTaskView.evaluationType')}
              </FieldLabel>
              <Controller
                control={control}
                name="evaluation_type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    selectedOptions={(() => {
                      let selectedEvaluation: IOption = {
                        label: '',
                        value: ''
                      };

                      evaluationTypes.forEach((evaluationType) => {
                        if (evaluationType.value === value.value)
                          selectedEvaluation = evaluationType;
                      });

                      return selectedEvaluation;
                    })()}
                    options={evaluationTypes}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.evaluationScore')}:
              </TitleContainer>
              <TitleContainer
                variant="body"
                lineHeight="21px"
                textAlign="center"
              >
                0.00
              </TitleContainer>
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.isFinished')}?
              </TitleContainer>
              <Flex flexDirection="row" justifyContent="center">
                <SwitchButton value={isFinished} onChange={setIsFinished} />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              width="auto"
              justifyContent="space-evenly"
            >
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.isClosed')}?
              </TitleContainer>
              <PadlockButton
                locked={
                  !!selectedEvaluation?.evaluations_closings.find(
                    ({ closed_at }) => closed_at
                  )
                }
                onClick={() => {
                  if (canChangeEvaluationStatus === true) {
                    setIsEvaluationStatusModalOpen(true);
                  }
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" width={['100%', '50%']}>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="end"
            gap="10px"
          >
            <Flex flexDirection="column" width="100%">
              <FieldLabel htmlFor="area-select">
                {t('createTaskView.areas')}
              </FieldLabel>

              <Controller
                control={control}
                name="area_id"
                render={({ field: { onChange, value } }) => (
                  <NestedSelect
                    selectedOptions={flattenOptions(areas).filter(
                      (option) => value === option?.value
                    )}
                    setSelectedOptions={(newOptions) => {
                      onChange(newOptions[0]?.value);
                    }}
                    options={areas}
                    isOneOptionSelect
                  />
                )}
              />
            </Flex>
            <Button variant="grey">
              <FontAwesomeIcon icon={faCopy} />
              <P variant="body">{t('createTaskView.copy')}</P>
            </Button>
          </Flex>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="10px"
          >
            <Flex flexDirection="column" width="100%">
              <Flex width="95%">
                <Controller
                  control={control}
                  name="leader_id"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('createEvalutionView.leader')}
                      selectedOptions={usersList.find(
                        (userOption) => userOption.value === String(value)
                      )}
                      onChange={(val: IOption) => {
                        onChange(val.value);
                      }}
                      options={usersList}
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" width="100%">
              <TextField
                label={t('createTaskView.author')}
                value={
                  usersList
                    .find(({ value }) => value === watch('author_id'))
                    ?.label?.toString() || ''
                }
                disabled
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Controller
              control={control}
              name="team_ids"
              render={({ field: { onChange, value } }) => (
                <Select
                  isMulti
                  label={t('createTaskView.auditTeam')}
                  selectedOptions={teamsList.filter((teamItem) =>
                    value?.includes(teamItem.value)
                  )}
                  onChange={(options: IOption[]) => {
                    onChange(options.length ? [options[0].value] : []);
                  }}
                  options={teamsList}
                />
              )}
            />
          </Flex>
          <Flex
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="10px"
          >
            <Flex flexDirection="column" width="100%">
              <TitleContainer variant="body">
                {t('createTaskView.participants')}
              </TitleContainer>
              <Flex flexDirection="row" justifyContent="space-between">
                <TextareaField
                  disabled
                  width="80%"
                  rows={2}
                  value={watch('evaluation_participations_attributes')?.map(
                    (taskParticipationAttribute) =>
                      `${taskParticipationAttribute.label} \n`
                  )}
                />
                <IconButtonContainer
                  flexDirection="column"
                  width="100px"
                  height="55px"
                  onClick={() => {
                    setIsParticipantsModalOpen(
                      (prevIsParticipantsModalOpen) =>
                        !prevIsParticipantsModalOpen
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPeopleGroup} size="xl" />
                  <H2 variant="h3">{t('createTaskView.manage')}</H2>
                </IconButtonContainer>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            width="90%"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="22px"
            flexWrap="wrap"
            gap="5px"
          >
            <IconButtonContainer
              flexDirection="row"
              width="120px"
              height="45px"
              onClick={handleLaunchEvaluation}
            >
              <FontAwesomeIcon icon={faCirclePlay} size="xl" />
              <H2 variant="h3">{t('createTaskView.launch')}</H2>
            </IconButtonContainer>
            <Flex justifyContent="center" gap="4px" flexWrap="wrap">
              <IconButton
                onClick={() => {
                  setIsTasksModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faBars} size="2x" />
              </IconButton>
              <IconButton onClick={() => setIsCriteriaListModalOpen(true)}>
                <FontAwesomeIcon icon={faFilePen} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faDownload} size="2x" />
              </IconButton>
              <IconButton
                disabled={!selectedEvaluation}
                onClick={() => {
                  setIsNotesModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faClipboard} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faPaperPlane} size="2x" />
              </IconButton>
              <IconButton>
                <FontAwesomeIcon icon={faClockRotateLeft} size="2x" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="2x" />
              </IconButton>
            </Flex>
          </Flex>
          <Flex
            width="90%"
            flexDirection="row"
            justifyContent="center"
            marginTop="10px"
          >
            <Flex flexDirection="column">
              <TitleContainer variant="body" lineHeight="21px">
                {t('createTaskView.show')}:
              </TitleContainer>
              <IconButtonContainer
                flexDirection="row"
                width="100px"
                height="30px"
                bgColor={theme.palette.primary.main}
                onClick={() => {
                  setIsTasksModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faPersonRunning} />
                <H2 variant="h3">{t('createTaskView.tasks')}</H2>
              </IconButtonContainer>
            </Flex>
          </Flex>
        </Flex>
      </MainSection>

      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <H4 variant="h4" color="coloured">
            {t('tasks.planning')}
          </H4>
          <Line />

          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="planned_start_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.plannedStartDate') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>

            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="planned_completion_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.timeLimitForCompletion') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column" width={['100%', '30%']} mt="4px">
              <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
              <Flex mr={1} width="100%" alignItems="end">
                <Flex mr={1} width="50%">
                  <Controller
                    control={control}
                    name="planned_duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.hrs."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('T') + 1,
                            value.indexOf('H')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!hourChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('T') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('H'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex width="50%">
                  <Controller
                    control={control}
                    name="planned_duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.min."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('H') + 1,
                            value.indexOf('M')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('H') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('M'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width={['100%', '30%']} mt={3} justifyContent="center">
              <Controller
                control={control}
                name="only_realization"
                render={({ field: { onChange, value } }) => (
                  <SwitchButton
                    label="tasks.realizationOnly (D)"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="column">
          <H4 variant="h4" color="green">
            {t('tasks.implementation')}
          </H4>
          <Line variant="tertiary" />

          <Flex alignItems="center" flexDirection={['column', 'row']}>
            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="start_time"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.plannedStartDate') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>

            <Flex mb={3} mr={2} width={['100%', '25%']}>
              <Controller
                control={control}
                name="completion_time"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label={t('tasks.timeLimitForCompletion') || ''}
                    icon={<FontAwesomeIcon color="grey" icon={faCalendar} />}
                    value={value ? new Date(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue && format(newValue, 'yyyy-MM-dd'));
                    }}
                  />
                )}
              />
            </Flex>
            <Flex flexDirection="column" width={['100%', '30%']} mt="6px">
              <FieldLabel>{t('tasks.plannedTime')}</FieldLabel>
              <Flex mr={1} width="100%" alignItems="end">
                <Flex mr={1} width="50%">
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.hrs."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('T') + 1,
                            value.indexOf('H')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('T') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('H'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
                <Flex width="50%">
                  <Controller
                    control={control}
                    name="duration"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="tasks.min."
                        type="number"
                        value={Number(
                          value?.slice(
                            value.indexOf('H') + 1,
                            value.indexOf('M')
                          )
                        )}
                        labelPosition={LabelPosition.BOTTOM}
                        onChange={(newValue) => {
                          if (!minuteChecker(newValue.target.value)) return;

                          onChange(
                            `${value.slice(0, value.indexOf('H') + 1)}${
                              newValue.target.value
                            }${value.slice(value.indexOf('M'))}`
                          );
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width={['100%', '30%']}>
              <RealizationProgress
                label={t('createTaskView.status')}
                showStatusInfo
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <DescriptionModal
        value={watch('description')}
        setValue={(value) => {
          setValue('description', value || '');
        }}
        isOpen={isDescriptionModalOpen}
        onCancelClick={() => {
          setIsDescriptionModalOpen(
            (prevIsDescriptionModalOpen) => !prevIsDescriptionModalOpen
          );
        }}
      />

      <EvaluationParticipantsModal
        isOpen={isParticipantsModalOpen}
        control={control}
        watch={watch}
        setValue={setValue}
        usersList={usersList}
        teamsList={teamsList}
        userFunctionsList={userFunctionsList}
        onCancelClick={() => {
          setIsParticipantsModalOpen(
            (prevIsParticipantsModalOpen) => !prevIsParticipantsModalOpen
          );
        }}
        saveAndClose={() => {
          setIsParticipantsModalOpen(
            (prevIsParticipantsModalOpen) => !prevIsParticipantsModalOpen
          );
        }}
      />

      {isEvaluationStatusModalOpen && (
        <EvaluationStatusModal
          watch={watch}
          setValue={setValue}
          isOpen={isEvaluationStatusModalOpen}
          onCancelClick={() => setIsEvaluationStatusModalOpen(false)}
          usersList={usersList}
          userFunctionsList={userFunctionsList}
          selectedEvaluation={selectedEvaluation}
          setSelectedEvaluation={setSelectedEvaluation}
        />
      )}
      <CriteriaListModal
        isOpen={isCriteriaListModalOpen}
        onCancelClick={() => setIsCriteriaListModalOpen(false)}
      />

      <TasksConnectedWithEvaluationModal
        isOpen={isTasksModalOpen}
        onCancelClick={() => setIsTasksModalOpen(false)}
      />

      <NotesModal
        isOpen={isNotesModalOpen}
        onCancelClick={() => {
          setIsNotesModalOpen((prevIsNotesModalOpen) => !prevIsNotesModalOpen);
        }}
        noteableId={selectedEvaluation?.id}
        noteableType="Evaluation"
        notes={selectedEvaluation?.notes}
        setNotes={(newNotes) =>
          setSelectedEvaluation?.(
            (prevSelectedItemData) =>
              prevSelectedItemData && {
                ...prevSelectedItemData,
                notes: newNotes
              }
          )
        }
      />

      {handleDeleteEvaluation && (
        <DeleteEvaluationModal
          onSubmit={handleDeleteEvaluation}
          isOpen={isDeleteModalOpen}
          onCancelClick={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
};
