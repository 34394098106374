import { getAreas } from 'api/areas';
import { Box, Flex } from 'components';
import { SwitchButton } from 'components/_form';
import {
  IOption,
  NestedSelect
} from 'components/_form/NestedSelect/NestedSelect';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  parentToChildrenAreasStructure,
  parseParentToChildrenAreasStructureToOptions
} from 'utilities/areas';

export const AreaPermissionsForm = () => {
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>([]);
  const [areas, setAreas] = useState<IOption[]>([]);

  const loadOptions = async () => {
    const { data: areasData } = await getAreas();

    setAreas(
      parseParentToChildrenAreasStructureToOptions(
        parentToChildrenAreasStructure(areasData)
      )
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <Flex flexDirection="column">
      <Box width="100%">
        <NestedSelect
          label={t('profile.userAreaPermissions') as string}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          options={areas}
        />
      </Box>
      <Box my={4} pr={[0, 6]}>
        <SwitchButton label="profile.userHasRiparianAreasViewPermission" />
      </Box>
      <Box pr={[0, 6]}>
        <SwitchButton label="profile.userHasNeighboringAreasViewPermission" />
      </Box>
    </Flex>
  );
};
