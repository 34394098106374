import {
  faCommentDots,
  faPaperclip,
  faPersonRunning
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { CommentModal } from '../CommentModal/CommentModal';
import { IAuditCriterion } from 'types/audits';
import { useState } from 'react';
import { FilesModal } from 'components/FilesModal/FilesModal';
import { theme } from 'styles';
import { TasksConnectedWithCriterionModal } from 'components/TasksConnectedWithCriterionModal/TasksConnectedWithCriterionModal';

interface Props {
  criterion: IAuditCriterion;
}

export const Buttons: React.FC<Props> = ({ criterion }) => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [documents, setDocuments] = useState<File[]>([]);

  return (
    <Flex gap="2px" width="60px">
      <FontAwesomeIcon
        icon={faPersonRunning}
        size="xl"
        cursor="pointer"
        color={theme.palette.accent.red}
        onMouseDown={() => {
          setIsTasksModalOpen(true);
        }}
      />
      <FontAwesomeIcon
        icon={faPaperclip}
        size="xl"
        cursor="pointer"
        color={theme.palette.accent.green}
        onMouseDown={() => {
          setIsFilesModalOpen(true);
        }}
      />

      {criterion.comment && (
        <FontAwesomeIcon
          icon={faCommentDots}
          size="xl"
          cursor="pointer"
          color={theme.palette.neutral.mainBlue}
          onMouseDown={() => {
            setIsCommentModalOpen(true);
          }}
        />
      )}

      <TasksConnectedWithCriterionModal
        isOpen={isTasksModalOpen}
        onCancelClick={() => setIsTasksModalOpen(false)}
      />

      <FilesModal
        documents={documents}
        setDocuments={setDocuments}
        images={images}
        setImages={setImages}
        isOpen={isFilesModalOpen}
        onCancelClick={() => setIsFilesModalOpen(false)}
        allowEdit={false}
      />

      <CommentModal
        comment={criterion.comment || ''}
        isOpen={isCommentModalOpen}
        onCancelClick={() => setIsCommentModalOpen(false)}
      />
    </Flex>
  );
};
