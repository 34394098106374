import { Flex, Modal } from 'components';
import { IGenericModal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { H2, P } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import {
  faArrowRotateRight,
  faFileCirclePlus
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FileLinkContainer,
  LeftPanel,
  SubTitle
} from '../LeftPanel/LeftPanel.styled';
import { TitleContainer } from '../../../../EvaluationActionsModal/FormEvaluation/FormEvaluation.styled';
import { useEffect, useRef, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';

type Props1 = IGenericModal & {
  modalType: ModalTypeNoEdit;
};

type Props2 = IGenericModal & {
  modalType: 'edit';
  currentFilename: string;
};

type Props = IGenericModal & (Props1 | Props2);

export type ModalType = 'add' | 'edit' | 'delete';
type ModalTypeNoEdit = 'add' | 'delete';

export const FileModal = (props: Props) => {
  const { isOpen, onCancelClick, modalType } = props;
  const { t } = useTranslation();

  const [filename, setFilename] = useState<string>(
    t('criteriaView.addFile.fileNameDescription') as string
  );
  const [fileIcon, setFileIcon] = useState(faFileCirclePlus);
  const [documentName, setDocumentName] = useState('Nadaj nazwę');
  const [documentDescription, setDocumentDescription] = useState('Stwórz opis');
  const [link, setLink] = useState('');
  const [comment, setComment] = useState<string | undefined>();
  const [modalTitle, setModalTitle] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const HandleModalType = () => {
    switch (modalType) {
      case 'add':
        setModalTitle(t('criteriaView.addFile.title') as string);
        break;
      case 'edit':
        setModalTitle(t('criteriaView.editFile.title') as string);
        setFilename(props.currentFilename);
        setFileIcon(faArrowRotateRight);
        break;
      default:
        setModalTitle(t('criteriaView.addFile.title') as string);
        break;
    }
  };

  useEffect(() => {
    HandleModalType();
  }, [modalType]);

  const activateInput = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setFilename(file.name);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t('administration.add')
      }}
      isSecondButtonVisible
      gridTemplateColumns="60%"
      headerTitle={
        <Flex width="100%">
          <H2 variant="h3">{modalTitle}</H2>
        </Flex>
      }
    >
      <Flex flexDirection="row" width="100%" justifyContent="space-between">
        <Flex flexDirection="column" width="35%">
          <Flex width="130px">
            <input
              type="file"
              style={{ display: 'none' }}
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Button
              variant="eucalyptus"
              label={t('criteriaView.addFile.selectFile')}
              icon={
                <FontAwesomeIcon
                  icon={fileIcon}
                  size="xl"
                  style={{ marginLeft: '5px' }}
                />
              }
              fullWidth={true}
              onClick={activateInput}
            />
          </Flex>
          <Flex flexDirection="column">
            <TitleContainer variant="body">
              {t('criteriaView.addFile.preview')}:
            </TitleContainer>
            <LeftPanel
              highlight={false}
              margin="0"
              height="85px"
              width={'240px'}
            >
              <FileLinkContainer>
                <Flex
                  height={100}
                  flexDirection="column"
                  paddingTop="10px"
                  marginLeft="10px"
                  width="25%"
                >
                  <FontAwesomeIcon icon={faFile} size="3x" />
                </Flex>
                <Flex
                  flexDirection="column"
                  paddingTop="10px"
                  justifyContent="flex-start"
                  margin="0 5px"
                  width="75%"
                >
                  <H2 variant="h3">{documentName}</H2>
                  <SubTitle variant="body">{documentDescription}</SubTitle>
                </Flex>
              </FileLinkContainer>
            </LeftPanel>
          </Flex>
        </Flex>
        <Flex flexDirection="column" width="60%">
          <P variant="body">{filename}</P>
          <Flex flexDirection="column" width="100%" marginTop="10px">
            <TextField
              label={t('criteriaView.addFile.documentName')}
              isTooltip
              tooltipId="documentName"
              tooltipValue={t('criteriaView.addFile.documentName') as string}
              value={documentName}
              onChange={(e) => {
                setDocumentName(e.target.value);
              }}
            />
            <Flex marginTop="10px" width="100%">
              <Flex width="60%" marginRight="10px">
                <TextField
                  label={t('criteriaView.addFile.documentDescription')}
                  isTooltip
                  tooltipId="documentDescription"
                  tooltipValue={
                    t('criteriaView.addFile.documentDescription') as string
                  }
                  height="60px"
                  value={documentDescription}
                  onChange={(e) => {
                    setDocumentDescription(e.target.value);
                  }}
                />
              </Flex>
              <TextField
                label={t('criteriaView.addFile.link')}
                isTooltip
                tooltipId="link"
                tooltipValue={t('criteriaView.addFile.link') as string}
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
            </Flex>
            <Flex
              data-color-mode="light"
              marginTop="10px"
              flexDirection="column"
            >
              <P variant="body">{t('criteriaView.addFile.comment')}</P>

              <MDEditor
                height={200}
                preview="edit"
                value={comment}
                onChange={setComment}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
