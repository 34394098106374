import { DatePicker } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './TimeLimitFilter.styled';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { formatDate } from '../../../../../../../utilities/date';

export const TimeLimitFilter: React.FC = () => {
  const { t } = useTranslation();
  const { filters: { timeLimitForCompletion }, setFilters, getDateLabel } = useTaskSidebarContext();

  return (
    <Wrapper>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          label="Od"
          isClearable
          value={timeLimitForCompletion.value.from}
          maxDate={timeLimitForCompletion.value.to}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              timeLimitForCompletion: {
                label: getDateLabel(t('tasks.timeLimitForCompletion') as string, val, prevState.timeLimitForCompletion.value.to),
                value: {
                  from: val,
                  to: prevState.timeLimitForCompletion.value.to
                }
              }
            }
          })}
        />
      </div>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          label="Do"
          isClearable
          value={timeLimitForCompletion.value.to}
          minDate={timeLimitForCompletion.value.from}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              timeLimitForCompletion: {
                label: getDateLabel(t('tasks.timeLimitForCompletion') as string, prevState.timeLimitForCompletion.value.from, val),
                value: {
                  from: prevState.timeLimitForCompletion.value.from,
                  to: val
                }
              }
            }
          })}
        />
      </div>
    </Wrapper>
  );
};
