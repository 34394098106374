import {
  ResizerButton,
  SidebarResizer,
  Wrapper
} from './EvaluationsView.styled';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Sidebar } from './components/Sidebar/Sidebar';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from './content/Content';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { getAreas } from 'api/areas';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const EvaluationsView = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(280);

  const windowWidth = useWindowWidth();

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const { setAreas } = useEvaluationContext();

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: unknown) => {
      if (isResizing) {
        const val =
          // @ts-ignore
          mouseMoveEvent?.clientX -
          // @ts-ignore
          sidebarRef?.current?.getBoundingClientRect().left;

        val > 250 && val < 400 && setSidebarWidth(val);
      }
    },
    [isResizing]
  );

  const loadData = async () => {
    const { data: areasData } = await getAreas();

    setAreas(areasData);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setSidebarOpen(windowWidth > 768);
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const sidebarMemo = useMemo(() => <Sidebar />, []);

  return (
    <Wrapper>
      <div
        ref={sidebarRef}
        style={{ width: sidebarWidth, display: sidebarOpen ? 'block' : 'none' }}
      >
        {sidebarMemo}
      </div>
      <SidebarResizer onMouseDown={startResizing}>
        <ResizerButton
          onClick={() => setSidebarOpen((prevState) => !prevState)}
        >
          <FontAwesomeIcon icon={sidebarOpen ? faCaretLeft : faCaretRight} />
        </ResizerButton>
      </SidebarResizer>
      <Content />
    </Wrapper>
  );
};
