import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import { t } from 'i18next';
import { BaseSyntheticEvent, FC } from 'react';
import { AccordionItemButton } from 'react-accessible-accordion';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import {
  StyledAccordionItemHeading,
  StyledAccordionItemPanel
} from '../../ProfileView.styled';

interface Props {
  register: UseFormRegister<FieldValues>;
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
}

export const UsernameForm: FC<Props> = ({ register, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <StyledAccordionItemHeading>
        <AccordionItemButton>
          <Flex p={2}>
            <FontAwesomeIcon icon={faCircleUser} />
            <P ml={2} variant="body">
              {t('profile.changeUsername')}
            </P>
          </Flex>
        </AccordionItemButton>
      </StyledAccordionItemHeading>
      <StyledAccordionItemPanel>
        <Flex>
          <TextField {...register('username')} label="common.username" />
        </Flex>

        <Flex justifyContent="end">
          <Button variant="green" type="submit">
            {t('common.save')}
          </Button>
        </Flex>
      </StyledAccordionItemPanel>
    </form>
  );
};
