import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ICommentPopperProps {
  file: {
    name: string;
    desc: string;
  };
  comment: string;
  onClose: () => void;
}

const Wrapper = styled(Flex)`
  margin-left: 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  background-color: #fff;
  flex-flow: column nowrap;
  min-width: 200px;
  max-width: 400px;
`;

const Header = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const Content = styled.span`
  padding: 10px;
  font-size: 12px;
`;

const Footer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const CommentPopper = ({ file, onClose, comment }: ICommentPopperProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Flex flexDirection="column">
          <P variant="h4" color="blue">
            {file.name}
          </P>
          <P variant="body2" color="coloured">
            {file.desc}
          </P>
        </Flex>
        <Button
          fontSize="18px"
          variant="greyWhite"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={onClose}
        />
      </Header>
      <Content>{comment}</Content>
      <Footer>
        <P variant="body2" color="grey">
          {t('documentsView.displayingCommentInfo')}
        </P>
        <Button variant="grey" label={t('buttons.close')} onClick={onClose} />
      </Footer>
    </Wrapper>
  );
};

export default CommentPopper;
