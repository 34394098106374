import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDEditor from '@uiw/react-md-editor';
import { Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { Checkbox, Select } from 'components/_form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldLabel } from 'components/_form';
import styled from 'styled-components';

interface SendLinkModal extends IGenericModal {}

const Label = styled(FieldLabel)`
  width: max-content;
  margin: 0px;
  flex-shrink: 0;
`;

const SendLinkModal = ({ isOpen, onCancelClick }: SendLinkModal) => {
  const { t } = useTranslation();
  const [sendingMethods, setSendingMethods] = useState({
    email: false,
    system: false,
    copy: false
  });

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerTitle={t('documentsView.sendLinkModal.sendLink')}
      mainButton={{
        label: t('documentsView.sendLinkModal.sendMessage'),
        variant: 'blue',
        icon: <FontAwesomeIcon icon={faPaperPlane} />,
        action: () => {}
      }}
      isSecondButtonVisible
      secondButton={{ action: onCancelClick }}
    >
      <Flex flexDirection="column" gap="20px">
        <Flex flexDirection="row" alignItems="center" gap="10px">
          <Label>{t('documentsView.sendLinkModal.sendTo')}:</Label>
          <Select options={[]} withoutMargin />
        </Flex>
        <MDEditor placeholder="Treść wiadomości"></MDEditor>
        <Flex flexDirection="column" gap="10px">
          <Label>{t('documentsView.sendLinkModal.sendMessage')}:</Label>

          <Flex flexDirection="row" width="100%" gap="20px">
            <Checkbox
              label={t('documentsView.sendLinkModal.system') ?? ''}
              checked={sendingMethods.system}
              onChange={(e) => {
                setSendingMethods((prev) => {
                  return {
                    ...prev,
                    system: e.target.checked
                  };
                });
              }}
            />
            <Checkbox
              label={t('documentsView.sendLinkModal.email') ?? ''}
              checked={sendingMethods.email}
              onChange={(e) => {
                setSendingMethods((prev) => {
                  return {
                    ...prev,
                    email: e.target.checked
                  };
                });
              }}
            />
            <Checkbox
              label={t('documentsView.sendLinkModal.withCopy') ?? ''}
              checked={sendingMethods.copy}
              onChange={(e) => {
                setSendingMethods((prev) => {
                  return {
                    ...prev,
                    copy: e.target.checked
                  };
                });
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default SendLinkModal;
