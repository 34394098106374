import { DatePicker } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './StartDateFilter.styled';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { formatDate } from '../../../../../../../utilities/date';

export const StartDateFilter: React.FC = () => {
  const { t } = useTranslation();
  const { filters: { startDate }, setFilters, getDateLabel } = useTaskSidebarContext();

  return (
    <Wrapper>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          isClearable
          label="Od"
          value={startDate.value.from}
          maxDate={startDate.value.to}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              startDate: {
                label: getDateLabel(t('tasks.startDate') as string, val, prevState.startDate.value.to),
                value: {
                  from: val,
                  to: prevState.startDate.value.to
                }
              }
            }
          })}
        />
      </div>
      <div>
        <DatePicker
          icon={<FontAwesomeIcon icon={faCalendar} />}
          isClearable
          label="Do"
          value={startDate.value.to}
          minDate={startDate.value.from}
          onChange={(val: Date | null) => setFilters(prevState => {
            return {
              ...prevState,
              startDate: {
                label: getDateLabel(t('tasks.startDate') as string, prevState.startDate.value.from, val),
                value: {
                  from: prevState.startDate.value.from,
                  to: val
                }
              }
            }
          })}
        />
      </div>
    </Wrapper>
  );
};
