import styled from 'styled-components';
import { Flex } from '../../../../../../../components';

export const Wrapper = styled.div`
  position: relative;
  padding: 10px 8px 0;

  > ${Flex}:first-child {
    > label {
      cursor: pointer;
      font-weight: bold;
      font-size: 12px;
    }
  }
`;

export const Item = styled(Flex)`
  margin: 10px 0;
  align-items: center;
  cursor: pointer;
  position: relative;

  > p {
    font-size: 12px;
    font-weight: bold;
    margin-right: 6px;
  }
`;
