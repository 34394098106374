import {
  CreateEvaluationBody,
  IEvaluationResponse,
  IEvaluationsRequest,
  IEvaluationsResponse
} from 'types/evaluations';
import request from './index';
import qs from 'qs';
import {
  IEvaluationScoreResponse,
  IPatchEvaluationScoreRequest,
  IPostEvaluationScoreRequest
} from 'types/forms/evaluations';
import { serialize } from 'object-to-formdata';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const getEvaluations = ({ filters }: IEvaluationsRequest) => {
  const query = qs.stringify(filters, { arrayFormat: 'brackets' });

  return request<IEvaluationsResponse>({
    options: {
      url: `/evaluations?${query}`,
      method: 'get'
    }
  });
};

export const postEvaluations = (data: { evaluation: CreateEvaluationBody }) => {
  return request<IEvaluationResponse>({
    options: {
      url: `/evaluations`,
      method: 'post',
      data
    }
  });
};

export const patchEvaluations = (
  id: number,
  data: { evaluation: CreateEvaluationBody }
) => {
  return request<IEvaluationResponse>({
    options: {
      url: `/evaluations/${id}`,
      method: 'patch',
      data
    }
  });
};

export const getEvaluation = (id: number) => {
  try {
    return request<IEvaluationResponse>({
      options: {
        url: `/evaluations/${id}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const deleteEvaluation = (id: number) => {
  try {
    return request<{}>({
      options: {
        url: `/evaluations/${id}`,
        method: 'delete'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const postEvaluationScore = (data: IPostEvaluationScoreRequest) => {
  return request<IEvaluationScoreResponse>({
    options: {
      url: `/scores`,
      method: 'post',
      data: serialize(data)
    }
  });
};

export const patchEvaluationScore = (
  id: number,
  data: IPatchEvaluationScoreRequest
) => {
  return request<IEvaluationScoreResponse>({
    options: {
      url: `/scores/${id}`,
      method: 'patch',
      data: serialize(data)
    }
  });
};
