export const getFile = async (file_url: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}${file_url}`);
  const blob = await response.blob();
  const fileName = (await response).url.split('/').pop();

  const file = new File([blob], `${fileName}`, {
    type: 'image'
  });

  return file;
};
