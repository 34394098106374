import { Flex } from 'components';
import styled from 'styled-components';

export const ImageBox = styled(Flex)<{ imageUrl?: string }>`
  width: 165px;
  height: 110px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${({ theme }) => `color: ${theme.palette.text.secondary};
  border: 1px dashed ${theme.palette.text.secondary};`};

  ${({ imageUrl }) => !!imageUrl && `background-image: url("${imageUrl}");`}
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  th,
  td {
    text-align: left;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
    padding: 10px 15px;
  }
`;
