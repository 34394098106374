import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export interface AvatarI {
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  url?: string;
  border?: boolean;
  tooltipContent?: string;
  id?: string;
}

interface RootI {
  size: AvatarI['size'];
  border: AvatarI['border'];
}

interface ImageI {
  url?: AvatarI['url'];
}

const Root = styled.div<RootI>`
  ${({ size, theme, border }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${
      (size === 'extra-small' && `24px`) ||
      (size === 'small' && `48px`) ||
      (size === 'medium' && `64px`) ||
      (size === 'large' && `128px`)
    };
    width: ${
      (size === 'extra-small' && `24px`) ||
      (size === 'small' && `48px`) ||
      (size === 'medium' && `64px`) ||
      (size === 'large' && `128px`)
    };
    ${border && `border: 2px solid ${theme.palette.neutral.mainBlue}`};
    aspect-ratio: 1/1;
    flex: 0 0 auto;
    border-radius: 50%;
    color: ${theme.palette.neutral.dark};
    background-color: ${theme.palette.neutral.light};
    overflow: hidden;
  `}
`;

const Image = styled.div<ImageI>`
  ${({ url }) => `
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

export const Avatar: React.FC<AvatarI> = ({
  id,
  size = 'small',
  url,
  border,
  tooltipContent
}) => (
  <>
    <Root
      id={id}
      size={size}
      border={border}
      data-tooltip-content={tooltipContent}
    >
      <UserIcon width="100%" height="100%" />
      <Image url={url} />
    </Root>
    {tooltipContent && <Tooltip style={{ zIndex: 10 }} anchorId={id} />}
  </>
);
