import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H1 } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './CompanyAdministrationView.styled';
import { CompanyDataForm } from './components/CompanyData/CompanyDataForm';
import { ContactAndHelpForm } from './components/ContactAndHelp/ContactAndHelpForm';
import { DefaultSettingsForm } from './components/DefaultSettings/DefaultSettingsForm';
import { LicensesAndPermissionForm } from './components/LicensesAndPermission/LicensesAndPermissionForm';
import { StorageForm } from './components/Storage/StorageForm';
import { UsersPermissionForm } from './components/UsersPermission/UsersPermissionForm';

export const CompanyAdministrationView = () => {
  const { t } = useTranslation();

  return (
    <Wrapper mb={94} flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} - company
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px" flexWrap="wrap">
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList>
            <Flex flexWrap="wrap">
              <CustomTab>{t('administration.companyData')}</CustomTab>
              <CustomTab>{t('administration.licencesAndPermission')}</CustomTab>
              <CustomTab>{t('administration.usersPermission')}</CustomTab>
              <CustomTab>{t('administration.defaultSettings')}</CustomTab>
              <CustomTab>{t('administration.storage')}</CustomTab>
              <CustomTab>{t('administration.contactAndHelp')}</CustomTab>
            </Flex>
          </CustomTabList>
          <CustomTabPanel>
            <CompanyDataForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <LicensesAndPermissionForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <UsersPermissionForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <DefaultSettingsForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <StorageForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <ContactAndHelpForm />
          </CustomTabPanel>
        </CustomTabs>
      </Flex>
    </Wrapper>
  );
};
