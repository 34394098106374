import { Flex } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  padding: 10px;
  height: max-content;
  width: 100%;
  flex-direction: column;
`;
