import { useState, useEffect } from "react";

export function useWindowWidth(): number {
  const isScreenSize =
    typeof document.documentElement.clientWidth !== "undefined";
  const [windowWidth, setWindowWidth] = useState(1200);

  function changeWindowWidth() {
    setWindowWidth(isScreenSize ? document.documentElement.clientWidth : 1200);
  }

  useEffect(() => {
    changeWindowWidth();
    window.addEventListener("resize", changeWindowWidth);

    return () => {
      window.removeEventListener("resize", changeWindowWidth);
    };
  }, []);

  return windowWidth;
}
