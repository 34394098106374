import { FC, useState } from 'react';
import { Box, Flex, Spinner } from 'components';
import { Button, TextField } from 'components/_form';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from 'validation/authSchemas';
import { IResetPassword } from 'types/forms/auth';
import { resetUserPassword } from '../api/auth';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { P } from 'components/Typography/Typography';

const Wrapper = styled(Flex)`
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
`;

const SuccedInfo = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.accent.lightGreen};
`;

export const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [succeed, setSucceed] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm<IResetPassword>({
    mode: 'onChange',
    defaultValues: {
      user: {
        email: ''
      }
    },
    resolver: yupResolver(resetPasswordSchema)
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await resetUserPassword(data);
      if (!response) return;
      toast.success(t('auth.verify_your_email'));
      setSucceed(true);
    } catch (err: any) {
      toast.error(err?.message || t('anErrorOccurred'));
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      height="100%"
    >
      <form onSubmit={onSubmit}>
        <TextField
          label="auth.writeRegisteredEmail"
          transparent
          {...register('user.email')}
          error={t(errors?.user?.email?.message || '')}
        />

        {succeed && (
          <SuccedInfo p={2}>
            <P variant="body" color="darkGreen">
              {t('auth.succeedSendResetEmail')}
            </P>
          </SuccedInfo>
        )}

        <Flex minWidth={['0px', '350px']} my={4} flexWrap={['wrap', 'nowrap']}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Box mr={[0, 1]} mb={[2, 0]} width={['100%', '70%']}>
                <Button
                  variant={succeed ? 'green' : 'primary'}
                  fullWidth
                  type="button"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  {t('auth.backToLoginPage')}
                </Button>
              </Box>
              <Box width={['100%', '40%']}>
                <Button
                  variant={succeed ? 'primary' : 'green'}
                  fullWidth
                  type="submit"
                  disabled={!formState.isValid}
                >
                  {t('auth.send_email')}
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </form>
    </Wrapper>
  );
};
