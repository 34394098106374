import { Wrapper } from './Sidebar.styled';
import React, { useCallback, useEffect, useState } from 'react';
import { AreasTree } from 'components/AreasTree/AreasTree';
import { AreaAccordionItem } from 'types/area';
import { parentToChildrenAreasStructure } from 'utilities/areas';
import { Flex } from 'components';
import { handlePrevState } from 'utilities/handlePrevState';
import { useDesktopContext } from 'contexts/DesktopContext';

export const Sidebar: React.FC = () => {
  const [areasItems, setAreasItems] = useState<AreaAccordionItem[]>([]);

  const { setFilters, filters, areas } = useDesktopContext();

  const handleOnClick = useCallback((id: number) => {
    setFilters((prevState) => {
      const actualState = handlePrevState(prevState.area_ids.value, id);
      return {
        ...prevState,
        area_ids: {
          value: actualState
        }
      };
    });
  }, []);

  const handleGetAreas = () => {
    setAreasItems(parentToChildrenAreasStructure(areas));
  };

  useEffect(() => {
    handleGetAreas();
  }, [areas.length]);

  return (
    <Wrapper flexDirection="column">
      <Flex
        flexDirection="column"
        height="70vh"
        overflow="auto"
        style={{ zIndex: '0' }}
      >
        <AreasTree
          onClick={handleOnClick}
          items={areasItems}
          canBeActive={true}
          controlledCheckedFilters={filters.area_ids.value}
        />
      </Flex>
    </Wrapper>
  );
};
