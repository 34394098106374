import { Flex, Table } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { H2, P } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';
import { TaskTable } from 'views/TasksView/components/Content/TaskTable/TaskTable';
import { IconWrapper, Title } from './CriterionTasksModal.styled';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { TaskFormModal } from 'views/TasksView/components/TaskFormModal/TaskFormModal';
import { ITask } from 'types/task';
import { createTask } from 'api/tasks';
import { toast } from 'react-toastify';
import { ITaskForm } from 'types/forms/task';
import { useAccount } from 'hooks/useAccount';
import { TableScore } from 'types/evaluations';

interface Props extends IGenericModal {
  // criterionId: number;
  score: TableScore;
}

const initialTaskFormValues = {
  name: 'abcd',
  description: 'dcba'
};

export const CriterionTaskModal = ({
  isOpen,
  onCancelClick,
  score: { criterionId, id }
}: Props) => {
  const [predefinedTasksForTable, setPredefinedTasksForTable] = useState<{}[]>(
    []
  );
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState(false);
  const [taskFormData, setTaskFormData] = useState<ITask>();
  const [taskFormInitialValues, setTaskFormInitialValues] = useState(
    initialTaskFormValues
  );

  const { t } = useTranslation();
  const { selectedBlock, areas } = useEvaluationContext();
  const { loggedUserData } = useAccount();

  const initPredefinedTasks = useCallback(() => {
    const activeCriterion = selectedBlock?.criteria.find(
      (criterion) => criterion.id === criterionId
    );

    const predefinedTasks = activeCriterion?.predefined_tasks || [];
    const tasksForTable = predefinedTasks.map((task) => {
      return {
        name: task.name,
        description:
          task.description === null
            ? 'Przykładowy opis jak nie ma w bazie'
            : task.description,
        responsible:
          task.person_in_charge?.first_name && task.person_in_charge?.last_name
            ? `${task.person_in_charge.first_name} ${task.person_in_charge.last_name}`
            : task.person_in_charge?.username,
        area:
          areas.filter(
            (area) => task.area_ids && area.id === task.area_ids[0]
          )[0]?.name || '',
        button: (
          <IconWrapper>
            <FontAwesomeIcon
              icon={faPenToSquare}
              size="lg"
              color={theme.palette.neutral.white}
              style={{ padding: '2px' }}
              onClick={() => {
                setTaskFormInitialValues({
                  name: task.name,
                  description: task.description
                });
                setIsTaskFormModalOpen(true);
              }}
            />
          </IconWrapper>
        )
      };
    });

    setPredefinedTasksForTable(tasksForTable);
  }, [areas, criterionId, selectedBlock?.criteria]);

  useEffect(() => {
    initPredefinedTasks();
  }, [initPredefinedTasks]);

  const modalHeaderMock = {
    value: '',
  };

  const tableHeaders = [
    t('evaluationsView.criterionTasksModal.table.headers.name'),
    t('evaluationsView.criterionTasksModal.table.headers.description'),
    t('evaluationsView.criterionTasksModal.table.headers.responsible'),
    t('evaluationsView.criterionTasksModal.table.headers.area'),
    <Flex key="columns options" ml="auto">
      <ColumnsOptions
        hiddenItems={hiddenItems}
        setHiddenItems={setHiddenItems}
        switchableItems={[]}
        labelPrefix="tasks.task.table."
      />
    </Flex>
  ];

  const handleSubmitTaskForm = useCallback(
    async (data: ITaskForm['task']) => {
      try {
        const {
          taskType,
          priority,
          task_participations_attributes,
          ...restData
        } = data;

        const payload = {
          task: {
            ...restData,
            task_type_id: taskType?.value,
            priority_degree_id: priority?.value,
            score_ids: [id],
            person_in_charge_id: Number(loggedUserData?.id),
            task_participations_attributes: task_participations_attributes?.map(
              (taskParticipationAttribute) => ({
                participant_id: taskParticipationAttribute.value,
                function_id: taskParticipationAttribute.function_id
              })
            )
          }
        };

        const createTaskRes = await createTask(payload);

        setIsTaskFormModalOpen(false);
        toast.success(t('common.success'));
      } catch (e) {
        toast.error(t('anErrorOccurred'));
      }
    },
    [id, loggedUserData?.id, t]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: () => {},
        variant: 'eucalyptus',
        label: t(`evaluationsView.evaluationSubmitButton`)
      }}
      isSecondButtonVisible={false}
      gridTemplateColumns="95%"
      bodyMaxHeight="75vh"
      disableOverflow={true}
      headerTitle={
        <Flex ml={2} height="30px" overflow="hidden">
          <Flex width="25%" alignItems="center">
            <H2 variant="h3" mr={2}>
              {t('evaluationsView.criterionTasksModal.criterionTasks')}
            </H2>
          </Flex>
          <H2 variant="h3" color="red" mr={20}>
            {modalHeaderMock.value}
          </H2>
        </Flex>
      }
    >
      <Flex maxHeight="40vh" overflowY="auto">
        <TaskTable
          customTitle={`${t(
            'evaluationsView.criterionTasksModal.launchedTasks'
          )}`}
          score_ids={id ? [id] : []}
          loadContextFilters={false}
        />
      </Flex>
      <Flex maxHeight="35vh" flexDirection="column" width="100%">
        <Title
          fontColor={theme.palette.accent.green}
          width="250px"
          flexDirection="row"
          justifyContent="space-between"
        >
          <P variant="h3" fontWeight={700}>
            {t('evaluationsView.criterionTasksModal.predefinedTasks')}
          </P>
        </Title>
        <Flex maxWidth="100%">
          <Table header={tableHeaders} data={predefinedTasksForTable} />
        </Flex>
      </Flex>
      {isTaskFormModalOpen && (
        <TaskFormModal
          isOpen={isTaskFormModalOpen}
          data={taskFormData}
          handleSubmitTaskForm={handleSubmitTaskForm}
          onCancelClick={() => {
            setIsTaskFormModalOpen(false);
          }}
          initialValues={taskFormInitialValues}
        />
      )}
    </Modal>
  );
};
