import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './AuthorFilter.styled';
import { Select } from '../../../../../../../components/_form';
import { IOption } from '../../../../../../../components/_form/Select/Select';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { getUsers } from 'api/user';
import { fullName } from 'utilities/fullName';

export const AuthorFilter: React.FC = () => {
  const [usersList, setUsersList] = useState<IOption[]>([]);
  const { t } = useTranslation();
  const {
    filters: { authors },
    setFilters
  } = useTaskSidebarContext();

  const selected = () =>
    usersList.filter((el) => authors.value.includes(el.value));

  const handleOnChange = (val: IOption[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        authors: {
          label:
            val.length > 0
              ? t('authors') + ' - ' + val.map((el) => el.label).join(', ')
              : '',
          value: val.map((el) => el.value)
        }
      };
    });
  };

  const handleLoadUsers = useCallback(async () => {
    const { data } = await getUsers();
    setUsersList(
      data.map((user) => ({
        label: fullName(user.profile),
        value: String(user.id)
      }))
    );
  }, []);

  useEffect(() => {
    handleLoadUsers();
  }, [handleLoadUsers]);

  return (
    <Wrapper>
      <Select
        isMulti
        isClearable
        options={usersList}
        selectedOptions={selected()}
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};
