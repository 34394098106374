import { IAuditGroup, IPostAuditFormRequest } from 'types/audits';

export const parseGroupsAtrributesToGroups = (
  groups: IPostAuditFormRequest['audit_form']['groups_attributes']
): IAuditGroup[] => {
  return groups.map((group) => {
    const {
      groups_attributes: itemGroups,
      criteria_attributes,
      ...rest
    } = group;

    return {
      ...rest,
      criteria: criteria_attributes,
      groups: itemGroups?.length
        ? parseGroupsAtrributesToGroups(itemGroups)
        : []
    };
  }) as IAuditGroup[];
};
