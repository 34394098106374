import {
  faChevronDown,
  faChevronUp,
  faX
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { P } from 'components/Typography/Typography';
import { DefaultTFuncReturn, t } from 'i18next';
import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { FieldLabel } from '../FieldLabel';
import { Option } from './Option';
import { flattenOptions } from './utilities';
import { ErrorText } from '../ErrorText';
import ClickOutside from 'components/click-outside';

const Wrapper = styled(Flex)<{ error: Props['error'] }>`
  width: 100%;
  min-height: 35px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  justify-content: space-between;
  position: relative;

  ${({ error, theme }) =>
    error
      ? `
      border: 1px solid ${theme.palette.accent.red};
      box-shadow: 0 0 0 3px ${theme.palette.accent.lightRed};
    `
      : ''}
`;

const Menu = styled(Flex)`
  z-index: 1000;
  width: 100%;
  max-height: 500px;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  margin-top: 50px;
  position: absolute;
  flex-direction: column;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  box-shadow: 2px 2px 10px 0px ${({ theme }) => theme.palette.neutral.lightGrey};
`;

const SelectedTitle = styled(Flex)<{ disabled?: boolean }>`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette.neutral.lightGrey
      : theme.palette.accent.veryLightBlue};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.neutral.grey : theme.palette.accent.blue};
  align-items: center;
  height: 24px;
`;

const HelperText = styled(P)`
  text-align: right;
  font-style: oblique;
  width: 100%;
`;

export interface IOption {
  readonly value?: string;
  readonly label: string;
  readonly isDisabled?: boolean;
  readonly isHidden?: boolean;
  readonly options?: IOption[];
}

interface Props {
  label?: string | null;
  disabled?: boolean;
  helperText?: string;
  selectedOptions: IOption[];
  setSelectedOptions: (options: IOption[]) => void;
  options: IOption[];
  isOneOptionSelect?: boolean;
  width?: string | string[];
  error?: string | DefaultTFuncReturn;
}

export const NestedSelect = ({
  selectedOptions,
  setSelectedOptions,
  label,
  helperText,
  disabled,
  options,
  isOneOptionSelect,
  width,
  error
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const parseSelectedOptions = (parsingOptions: IOption[]): string[] => {
    return (parsingOptions
      .map((option) =>
        option.options
          ? !!flattenOptions(option.options)?.every(
              (item) =>
                !!flattenOptions(selectedOptions).find(
                  (selectedOption) => selectedOption.value === item.value
                )
            )
            ? option.label
            : parseSelectedOptions(option.options)
          : !!flattenOptions(selectedOptions).find(
              (selectedOption) => selectedOption.value === option.value
            )
          ? option.label
          : null
      )
      .flat(Infinity)
      .filter((item) => item) || []) as string[];
  };

  const handleRemoveSpecificOption = (optionLabel: string) => {
    const foundItem = flattenOptions(options, false).find(
      (item) => item.label === optionLabel
    );

    if (foundItem) {
      const foundOptions = flattenOptions(foundItem?.options || [foundItem]);

      const newSelectedOptions = selectedOptions.filter(
        (selectedOptionFilter) =>
          !foundOptions.find(
            (selectedOption) =>
              selectedOption.value === selectedOptionFilter.value
          )
      );

      setSelectedOptions(newSelectedOptions);
    }
  };

  return (
    <ClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
      <Flex flexDirection="column" width={width}>
        <Flex justifyContent="space-between">
          {label && <FieldLabel>{t(label)}</FieldLabel>}
          {helperText && (
            <HelperText variant="body">{t(helperText)}</HelperText>
          )}
        </Flex>

        <Wrapper error={error}>
          <Flex overflow="auto" width="100%" pl={2} alignItems="center">
            {parseSelectedOptions(options).length
              ? parseSelectedOptions(options).map((selectedOption: string) => (
                  <SelectedTitle
                    mr={1}
                    p={1}
                    key={selectedOption}
                    disabled={disabled}
                  >
                    {selectedOption}
                    {!disabled && (
                      <Button
                        minHeight="18px"
                        variant="lightBlue"
                        icon={<FontAwesomeIcon icon={faX} />}
                        onClick={() => {
                          handleRemoveSpecificOption(selectedOption);
                        }}
                      />
                    )}
                  </SelectedTitle>
                ))
              : ''}
          </Flex>
          <Flex>
            {!disabled && (
              <Button
                minHeight="32px"
                variant="secondary"
                icon={<FontAwesomeIcon color="grey" icon={faX} />}
                onClick={() => {
                  setSelectedOptions([]);
                }}
              />
            )}

            <Button
              minHeight="32px"
              variant="secondary"
              icon={
                <FontAwesomeIcon
                  color="grey"
                  icon={isOpen ? faChevronUp : faChevronDown}
                />
              }
              onClick={() => {
                setIsOpen((prevIsOpen) => !prevIsOpen);
              }}
            />
          </Flex>

          {isOpen && (
            <Menu>
              {options.map((option) => (
                <Option
                  disabled={disabled}
                  key={option.label}
                  option={option}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  isOneOptionSelect={isOneOptionSelect}
                  setIsSelectOpen={setIsOpen}
                />
              ))}
            </Menu>
          )}
        </Wrapper>
        {error && <ErrorText>{error}</ErrorText>}
      </Flex>
    </ClickOutside>
  );
};
