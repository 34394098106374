import { Flex, Line } from 'components';
import { H3 } from 'components/Typography/Typography';
import {
  Checkbox,
  FieldLabel,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { Controller, useFormContext } from 'react-hook-form';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { useTranslation } from 'react-i18next';
import { IPostAuditFormRequest } from 'types/audits';

export const AdditionalSettings = () => {
  const { t } = useTranslation();

  const { control, register } =
    useFormContext<IPostAuditFormRequest['audit_form']>();

  const timeTypeOptions = [
    {
      label: t('administrationAuditsView.days') || '',
      value: 'D'
    },
    {
      label: t('administrationAuditsView.weeks') || '',
      value: 'W'
    },
    {
      label: t('administrationAuditsView.months') || '',
      value: 'M'
    }
  ];

  return (
    <>
      <H3 variant="h3" color="coloured" mt={3}>
        {t('administrationAuditsView.additionalSettings')}
      </H3>
      <Line />
      <Flex alignItems="start" mt={2} flexDirection={['column', 'row']}>
        <Flex alignItems="center" gap="10px" mr="10%">
          <FieldLabel>
            {t('administrationAuditsView.enforceCompatibility')}
          </FieldLabel>

          <Controller
            control={control}
            name="enforce_completeness"
            render={({ field: { onChange, value } }) => (
              <SwitchButton
                value={!!value}
                onChange={(checked) => {
                  onChange(checked);
                }}
              />
            )}
          />

          <InfoTooltip
            tooltipId="enforce-compatibility-tooltip"
            tooltipValue={t(
              'administrationAuditsView.enforceCompatibilityToolTip'
            )}
            tooltipWidth="300px"
          />
        </Flex>

        <Flex flexDirection="column">
          <Flex mb={1} alignItems="center">
            <FieldLabel>
              {t('administrationAuditsView.maxTasksRealizationTime')}
            </FieldLabel>
            <InfoTooltip
              tooltipId="max-tasks-realization-time-tooltip"
              tooltipValue={t(
                'administrationAuditsView.maxTasksRealizationTimeTooltip'
              )}
              tooltipWidth="300px"
            />
          </Flex>
          <Flex gap="10px">
            <Controller
              control={control}
              name="maximum_task_completion_time"
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    width="120px"
                    type="number"
                    value={Number(value?.slice(1, -1) || 1)}
                    onChange={(e) => {
                      onChange(
                        `P${e.target.value}${
                          value?.charAt(value?.length - 1) || 'D'
                        }`
                      );
                    }}
                  />
                  <Select
                    selectedOptions={timeTypeOptions.find(
                      (timeTypeOption) =>
                        timeTypeOption.value ===
                        value?.charAt(value?.length - 1)
                    )}
                    onChange={(selectedOption) => {
                      if (!Array.isArray(selectedOption))
                        onChange(
                          `${value?.slice(0, -1) || 'P1'}${
                            selectedOption.value
                          }`
                        );
                    }}
                    options={timeTypeOptions}
                  />
                </>
              )}
            />
          </Flex>
        </Flex>

        <Flex flexDirection="column" mx="auto">
          <Flex gap="5px" mb={1} alignItems="center">
            <FieldLabel>
              {t('administrationAuditsView.allowCopyCopyingDoneAudit')}
            </FieldLabel>
            <InfoTooltip
              tooltipId="allow-copy-copying-done-audit-tooltip"
              tooltipValue={t(
                'administrationAuditsView.allowCopyCopyingDoneAuditToolTip'
              )}
              tooltipWidth="300px"
            />
          </Flex>
          <Flex
            gap="10px"
            justifyContent="center"
            flexDirection={['column', 'row']}
          >
            <Controller
              control={control}
              name="allow_copying_answers"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('administrationAuditsView.answers')}
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="allow_copying_attachments"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('administrationAuditsView.attachments')}
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="allow_copying_comments"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('administrationAuditsView.comments')}
                  checked={!!value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              )}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
