import {
  faEdit,
  faPencil,
  faPlus,
  faSearch,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Table } from 'components';
import { Button, Select, TextField } from 'components/_form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UpdateTranslationModal } from './UpdateTranslationModal';
import { ColumnsOptions } from 'components/Table/components/ColumnsOptions';

interface ITranslationsTable {
  highlightCells: boolean;
  setOpenAddLanguageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingLanguageData: React.Dispatch<
    React.SetStateAction<{ name: string }>
  >;
}

const OptionHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.accent.green};
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

export const TranslationsTable = ({
  highlightCells,
  setOpenAddLanguageModal,
  setEditingLanguageData
}: ITranslationsTable) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const [isUpdateTranslationModal, setIsUpdateTranslationModal] =
    useState(false);
  const [editingTranslationData, setEditingTranslationData] = useState({
    idetificator: ''
  });

  const LanguageHeaderWrapper = ({ text }: { text: string }) => (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      {t(`translationsView.tableHeaders.${text}`)}

      <Flex width="30px" height="30px">
        <Button
          minHeight="30px"
          icon={<FontAwesomeIcon icon={faPencil} />}
          onClick={() => {
            setOpenAddLanguageModal(
              (prevOpenAddLanguageModal) => !prevOpenAddLanguageModal
            );
            setEditingLanguageData({
              name: t(`translationsView.tableHeaders.${text}`)
            });
          }}
        />
      </Flex>
    </Flex>
  );

  const header = [
    t('translationsView.tableHeaders.number'),
    t('translationsView.tableHeaders.idLong'),
    <LanguageHeaderWrapper key="en" text="en" />,
    <LanguageHeaderWrapper key="pl" text="pl" />,
    <LanguageHeaderWrapper key="un" text="un" />,
    <LanguageHeaderWrapper key="ru" text="ru" />,
    <OptionHeader
      key="OptionHeader"
      onClick={() => {
        setOpenAddLanguageModal(
          (prevOpenAddLanguageModal) => !prevOpenAddLanguageModal
        );
      }}
    >
      <FontAwesomeIcon icon={faPlus} />
    </OptionHeader>
  ];

  const buttons = (languageData: { idetificator: string }) => (
    <Flex style={{ gap: '10px' }} mx="auto" width="fit-content">
      <Button
        variant="eucalyptus"
        onClick={() => {
          setEditingTranslationData(languageData);
          setIsUpdateTranslationModal(
            (prevIsUpdateTranslationModal) => !prevIsUpdateTranslationModal
          );
        }}
        icon={<FontAwesomeIcon icon={faEdit} />}
      />
      <Button
        variant="redWhite"
        bordered
        onClick={() => {
          console.log('123-123-123');
        }}
        icon={<FontAwesomeIcon icon={faTrash} />}
      />
    </Flex>
  );

  const data = [
    {
      id: 1,
      idetificator: 'ściezka',
      en: 'translated text',
      pl: 'tekst po polsku',
      un: '',
      ru: '',
      options: buttons({ idetificator: 'ściezka' })
    },
    {
      id: 2,
      idetificator: 'ściezka',
      en: 'translated text',
      pl: 'tekst po polsku',
      un: '',
      ru: '',
      options: buttons({ idetificator: 'ściezka' })
    },
    {
      id: 3,
      idetificator: 'ściezka',
      en: 'translated text',
      pl: 'tekst po polsku',
      un: '',
      ru: '',
      options: buttons({ idetificator: 'ściezka' })
    },
    {
      id: 4,
      idetificator: 'ściezka',
      en: 'translated text',
      pl: 'tekst po polsku',
      un: '',
      ru: '',
      options: buttons({ idetificator: 'ściezka' })
    }
  ];

  const switchableItems: (keyof (typeof data)[0])[] = [
    'idetificator',
    'en',
    'pl',
    'un',
    'ru'
  ];

  const additionalHeader = (
    <>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseCompany') ?? undefined}
        options={[]}
        withoutMargin
      />
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
        hideError
      />

      <ColumnsOptions
        switchableItems={switchableItems}
        hiddenItems={hiddenItems}
        setHiddenItems={setHiddenItems}
        labelPrefix="translationsView.tableHeaders."
      />
    </>
  );

  return (
    <>
      <UpdateTranslationModal
        data={editingTranslationData}
        isOpen={isUpdateTranslationModal}
        onCancelClick={() => {
          setIsUpdateTranslationModal(
            (prevIsUpdateTranslationModal) => !prevIsUpdateTranslationModal
          );
        }}
      />
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        highlightCells={highlightCells}
        leftFixedKeys={[0, 1]}
        rightFixedKeys={[6]}
        additionalHeaderElement={additionalHeader}
        hiddenItems={hiddenItems}
      />
    </>
  );
};
