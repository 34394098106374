import React, { useContext, useState } from 'react';
import { ITask } from 'types/task';

export interface ITaskContext {
  tasks: ITask[];
  setTasks: React.Dispatch<React.SetStateAction<ITask[]>>;
}

const TaskContext = React.createContext<ITaskContext | undefined>(undefined);

export const TaskContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [tasks, setTasks] = useState<ITask[]>([]);

  return (
    <TaskContext.Provider
      value={{
        tasks,
        setTasks
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error(
      'useEvaluationContext must be inside a EvaluationContextProvider with a value'
    );
  }
  return context;
};
