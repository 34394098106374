import { Flex, Modal } from 'components';
import { P } from 'components/Typography/Typography';
import { t } from 'i18next';
import { Dispatch } from 'react';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordErrorModal = ({ isModalOpen, setIsModalOpen }: Props) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onCancelClick={() => {
        setIsModalOpen(false);
      }}
      headerTitle={t('profile.Password change error')}
      headerTitleColor="red"
      mainButton={{
        label: t('buttons.close'),
        action: () => {
          setIsModalOpen(false);
        },
        variant: 'grey'
      }}
    >
      {
        <Flex flexDirection="column">
          <P my={3} variant="body" color="red" fontWeight={700}>
            {t('profile.Your password cannot be:')}
          </P>
          <Flex mx={3} flexDirection="column">
            <P variant="body">
              - {t('profile.such as your username or similar')},
            </P>
            <P variant="body">
              - {t('profile.such as your first and/or last name')},
            </P>
            <P variant="body">
              - {t('profile.company name or a variation thereof')},
            </P>
            <P variant="body">
              - {t('profile.the names of your children, family, dogs and cats')}
              ,
            </P>
            <P variant="body">
              -{' '}
              {t(
                'profile.a string of consecutive letters and/or keyboard characters, e.g. qwertyuiop, abcd1234, 12345678, 09876543...'
              )}
              ,
            </P>
            <P variant="body">
              - {t('profile.be on the list of commonly known passwords')}.
            </P>
          </Flex>

          <P my={3} variant="body" fontWeight={700}>
            {t(
              'profile.Instead, we encourage you to use phrases that are easy to remember, e.g.'
            )}
            <P variant="body" color="green" fontWeight={700}>
              {t(
                "profile.My pink dog is in love with my neighbor's green parrot"
              )}
            </P>
          </P>
        </Flex>
      }
    </Modal>
  );
};
