import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, Flex, Link } from 'components';
import { RGBLine } from 'components/RGBLine/RGBLine';
import { getPages } from 'api/pages';
import { IPage } from 'types/pages';

const Wrapper = styled(Flex)`
  flex-direction: column;

  a > p {
    text-decoration: underline;
  }
`;

export const Footer = () => {
  const [pages, setPages] = useState<IPage[]>([]);

  const handleGetPages = async () => {
    const pagesResponse = await getPages();

    if ('error' in pagesResponse) {
      return;
    }

    setPages(pagesResponse.data);
  };

  useEffect(() => {
    handleGetPages();
  }, []);

  return (
    <Wrapper width="100%">
      <RGBLine />
      <Flex justifyContent="center" gap="10px" p={3} flexWrap="wrap">
        {pages.map((page) => (
          <Link
            target="_blank"
            key={`footer-page-${page.id}`}
            to={`/info/${page.slug}`}
          >
            <Typography.P mr={2} variant="body" color="white">
              {page.name}
            </Typography.P>
          </Link>
        ))}
        <Typography.P mr={3} variant="body" color="white">
          © Copyright Effective Systems, 2022 Wszystkie prawa zastrzezone.
        </Typography.P>
        <Typography.P variant="body" color="white">
          v1.0
        </Typography.P>
      </Flex>
    </Wrapper>
  );
};
