import { Table } from 'components';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

export const SearchResults = () => {
  const { t } = useTranslation();

  const header: string[] = [
    t('documentsView.tableHeaders.block'),
    t('documentsView.tableHeaders.document'),
    t('documentsView.tableHeaders.date'),
    t('documentsView.tableHeaders.size'),
    t('documentsView.tableHeaders.addedBy'),
    ''
  ];

  const data = [
    {
      block: '11.A   Strategia wdrozeniowa',
      document: 'Przygotowanie planu wdrozenia',
      date: '19.09.2023',
      size: '34.5 kB',
      addedBy: 'Marerial PlatT',
      folder: (
        <Button
          variant="eucalyptus"
          icon={<FontAwesomeIcon icon={faFolderOpen} size="lg" />}
        />
      )
    },
    {
      block: '11.A   Strategia wdrozeniowa',
      document: 'Przygotowanie planu wdrozenia',
      date: '19.09.2023',
      size: '34.5 kB',
      addedBy: 'Marerial PlatT',
      folder: (
        <Button
          variant="eucalyptus"
          icon={<FontAwesomeIcon icon={faFolderOpen} size="lg" />}
        />
      )
    }
  ];

  return <Table header={header} data={data} leftFixedKeys={[0, 1]} />;
};
