import { DefaultTFuncReturn } from 'i18next';
import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { rgba } from 'utilities/rgba';

interface IFieldLabel {
  error?: string | DefaultTFuncReturn;
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  isFlex?: boolean;
  whiteSpace?: string;
  width?: string;
}

export const FieldLabel = styled.label<IFieldLabel>`
  :first-letter {
    text-transform: uppercase;
  }

  ${({ theme, disabled, error, isFlex, whiteSpace, width }) => `
    ${isFlex ? 'display: flex;' : 'display: inline-block;'}
    width: ${width ? width : '95%'};    
    font-weight: bold;
    font-size: ${theme.textStyles.body.fontSize}px;
    line-height: 20px;
    ${disabled && `color: ${rgba(theme.palette.text.primary, 0.4)}`};
    ${error && `color: ${theme.palette.accent.red}`};
    ${whiteSpace && `white-space: ${whiteSpace};`}
  `}
`;
