import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H1 } from 'components/Typography/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../CompanyAdministrationView/CompanyAdministrationView.styled';
import { RolesAndFunctions } from './tabs/RolesAndFunctions/RolesAndFunctions';
import { TeamsAndGroups } from './tabs/TeamsAndGroups/TeamsAndGroups';
import { UserList } from './tabs/UserList/UserList';

export const EmployeesAndUsersView = () => {
  const { t } = useTranslation();
  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} - {t('administration.employeesAndUsers')}
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px" flexWrap="wrap">
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList>
            <Flex flexWrap="wrap">
              <CustomTab>{t('employeesAndUsersView.usersList')}</CustomTab>
              <CustomTab>
                {t('employeesAndUsersView.rolesAndFunctions')}
              </CustomTab>
              <CustomTab>{t('employeesAndUsersView.teamsAndGroups')}</CustomTab>
            </Flex>
          </CustomTabList>
          <CustomTabPanel>
            <UserList />
          </CustomTabPanel>
          <CustomTabPanel>
            <RolesAndFunctions />
          </CustomTabPanel>
          <CustomTabPanel>
            <TeamsAndGroups />
          </CustomTabPanel>
        </CustomTabs>
      </Flex>
    </Wrapper>
  );
};
