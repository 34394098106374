import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Wrapper } from './StatusPDCAFilter.styled';
import { Flex } from '../../../../../../../components';
import { Checkbox } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { handlePrevState } from 'utilities/handlePrevState';

interface IStatusPDCAFilterItem {
  value: string;
}

const filterItems: IStatusPDCAFilterItem[] = [
  {
    value: 'P'
  },
  {
    value: 'D'
  },
  {
    value: 'C'
  },
  {
    value: 'A'
  }
];

export const StatusPDCAFilter: React.FC = () => {
  const [items, setItems] = useState<IStatusPDCAFilterItem[]>(filterItems);
  const {
    filters: { PDCAStatuses, PDCAStatusesIsNew },
    setFilters
  } = useTaskSidebarContext();
  const { t } = useTranslation();

  const handleSelect = useCallback((id: string) => {
    setFilters((prevState) => {
      const actualState = handlePrevState(prevState.PDCAStatuses.value, id);
      return {
        ...prevState,
        PDCAStatuses: {
          label:
            actualState.length > 0
              ? t('tasks.statusPDCA') +
                ' - ' +
                filterItems
                  .filter((el) => actualState.includes(el.value))
                  .map((el) => el.value)
                  .join(', ')
              : '',
          value: actualState
        }
      };
    });
  }, []);

  return (
    <Wrapper>
      <Flex>
        <label htmlFor="isNewFilter">{t('tasks.PDCANew')}</label>
        <Flex ml="auto">
          <Checkbox
            id="isNewFilter"
            onChange={() =>
              setFilters((prevState) => {
                return {
                  ...prevState,
                  PDCAStatusesIsNew: {
                    label: !prevState.PDCAStatusesIsNew.value
                      ? 'PDCA - ' + t('tasks.PDCANew')
                      : '',
                    value: !prevState.PDCAStatusesIsNew.value
                  }
                };
              })
            }
            checked={PDCAStatusesIsNew.value}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        {items.map((el) => (
          <Item key={el.value} onClick={() => handleSelect(el.value)}>
            <p>{el.value}:</p>
            <Flex ml="auto">
              <Checkbox
                onChange={() => handleSelect(el.value)}
                checked={PDCAStatuses.value.includes(el.value)}
              />
            </Flex>
          </Item>
        ))}
      </Flex>
    </Wrapper>
  );
};
