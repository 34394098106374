import styled from 'styled-components';
import { Card, Flex } from 'components';

export const Wrapper = styled.div`
  flex: 1;
  overflow: auto;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  > ${Card}:first-child {
    margin-bottom: 12px;
  }
`;

export const ContentChildrenWrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 8px;
  padding: 14px;
  margin-bottom: 35px;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: fit-content;

  * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;

export const ResizerWrapper = styled.div`
  cursor: row-resize;
  margin: 0 auto;
`;
