import {
  CreateAuditBody,
  IAuditCriteriaResponse,
  IAuditFormResponse,
  IAuditFormsRequest,
  IAuditFormsResponse,
  IAuditResponse,
  IAuditsRequest,
  IAuditsResponse,
  IPostAuditFormRequest,
  PatchAuditBody
} from 'types/audits';
import request from './index';
import qs from 'qs';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const getAudits = ({ filters }: IAuditsRequest) => {
  try {
    const query = qs.stringify(filters, { arrayFormat: 'brackets' });

    return request<IAuditsResponse>({
      options: {
        url: `/audits?${query}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], pagination: null, error: toErrorWithMessage(e) };
  }
};

export const getAudit = (id: number) => {
  return request<IAuditResponse>({
    options: {
      url: `/audits/${id}`,
      method: 'get'
    }
  });
};

export const postAudit = (data: { audit: CreateAuditBody }) => {
  return request<IAuditResponse>({
    options: {
      url: `/audits`,
      method: 'post',
      data
    }
  });
};

export const patchAudit = (id: number, data: { audit: PatchAuditBody }) => {
  return request<IAuditResponse>({
    options: {
      url: `/audits/${id}`,
      method: 'patch',
      data
    }
  });
};

export const deleteAudit = (id: number) => {
  try {
    return request<{}>({
      options: {
        url: `/audits/${id}`,
        method: 'delete'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const getAuditForms = ({ filters }: IAuditFormsRequest) => {
  const query = qs.stringify(filters, { arrayFormat: 'brackets' });

  try {
    return request<IAuditFormsResponse>({
      options: {
        url: `/audit_forms?${query}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const getAuditForm = (id: string) => {
  try {
    return request<IAuditFormResponse>({
      options: {
        url: `/audit_forms/${id}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: null, error: toErrorWithMessage(e) };
  }
};

export const postAuditForm = (payload: IPostAuditFormRequest) => {
  try {
    return request<IAuditFormResponse>({
      options: {
        url: `/audit_forms`,
        method: 'post',
        data: JSON.stringify(payload)
      }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const patchAuditForm = (id: string, payload: IPostAuditFormRequest) => {
  try {
    return request<IAuditFormResponse>({
      options: {
        url: `/audit_forms/${id}`,
        method: 'patch',
        data: JSON.stringify(payload)
      }
    });
  } catch (e) {
    return { data: [], error: toErrorWithMessage(e) };
  }
};

export const getAuditCriteria = (id: number) => {
  // TODO: handle real api request
  // return request<IAuditCriteriaResponse>({
  //   options: {
  //     url: `/audits/${id}/criteria`,
  //     method: 'get'
  //   }
  // });

  const mockedCriteria = {
    data: [
      {
        number: 1,
        name: 'Lider zespołu efektywanie zarządza czasem i treścią spotkania zespołu',
        evaluation: 1,
        attachments: [],
        comment: 'Brak anazlizy trednów i ich przyczyn'
      }
    ]
  };

  return new Promise<IAuditCriteriaResponse>((resolve) => {
    resolve(mockedCriteria);
  });
};

export const getAuditThingsToImprove = (id: number) => {
  // TODO: handle real api request
  // return request<{data: string[]}>({
  //   options: {
  //     url: `/audits/${id}/things-to-improve`,
  //     method: 'get'
  //   }
  // });

  const mockedThingsToImprove = {
    data: ['angażowanie zespołu w doskonalaniu wskaźników KPI']
  };

  return new Promise<{ data: string[] }>((resolve) => {
    resolve(mockedThingsToImprove);
  });
};
