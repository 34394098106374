import { Flex } from 'components/Flex';
import styled from 'styled-components';

const StyledDot = styled(Flex)<{ active: Props['active'] }>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.accent.green : theme.palette.neutral.medium};
`;

interface Props {
  active: boolean;
}

export const ActiveDot: React.FC<Props> = ({ active }) => {
  return <StyledDot active={active} />;
};
