import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup
      .string()
      .required('auth.emailRequired')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'auth.badEmailFormat'
      ),
    password: yup
      .string()
      .min(6, 'auth.passMinVal')
      .max(40, 'auth.passMaxVal')
      .required('auth.passwordRequired')
  })
});

export const registerSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup
      .string()
      .required('auth.emailRequired')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'auth.badEmailFormat'
      ),
    password: yup
      .string()
      .min(6, 'auth.passMinVal')
      .max(40, 'auth.passMaxVal')
      .required('auth.passwordRequired'),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'auth.passDiffErr'),
    regulation_ids: yup
      .array()
      .of(yup.number().required('auth.acceptRegulamin')),
    profile_attributes: yup.object().shape({
      first_name: yup.string(),
      last_name: yup.string()
    })
  })
});

export const changePasswordSchema = yup.object().shape({
  user: yup.object().shape({
    password: yup
      .string()
      .min(6, 'auth.passMinVal')
      .max(40, 'auth.passMaxVal')
      .required('auth.passwordRequired'),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'auth.passDiffErr')
  })
});

export const resetPasswordSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup
      .string()
      .required('auth.emailRequired')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'auth.badEmailFormat'
      )
  })
});
