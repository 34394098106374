import styled from 'styled-components';
import { Flex } from '../../../../../../../components';

export const Wrapper = styled(Flex)`
  position: relative;
  padding: 10px 0 0 0;
  justify-content: space-between;

  > div {
    width: 48%;
  }
`;
