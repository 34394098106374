import { IOption } from 'components/_form/Select/Select';
import { IArea } from './area';
import { IPagination } from './pagination';
import { IBlock, IPractice } from './methologies';
import { IPredefinedTask } from './task';
import { IUserWithoutProfile } from './user';
import { IEvaluationsClosing } from './evaluationsClosings';
import { INote } from './notes';

export interface IScore {
  id: number;
  score: number;
  comment: string;
  guideline: string;
  criterion_id: number;
  evaluation_id: number;
  task_ids: number[];
  predefinedTasks: [];
}

export interface TableScore {
  id?: number;
  name: string;
  value?: string;
  evaluationId: number;
  criterionId?: number;
  areaId: number;
}

export interface Criterion {
  id: number;
  content: string;
  evaluation_instruction: string;
  score?: IScore | null;
  predefined_tasks: IPredefinedTask[];
}

export interface BlockInEvaluation {
  id: number;
  name: string;
  avg_score: number;
  criteria: Criterion[];
  column: number;
  implementation_steps: [];
  topic_id: number;
  stage_id: number;
  documents: [];
}

export interface IEvaluation {
  id: number;
  date: string;
  due_date: string;
  number: string;
  group: string;
  updated_at: string;
  author: IUserWithoutProfile;
  block: Omit<IBlock, 'document_urls'>;
  avg_score?: string;
  evaluation_type: 'normal' | 'target' | 'baseline' | 'current' | 'derivative';
  name: string;
  description: {
    id: number;
    name: string;
    body: string;
    record_type: string;
    record_id: number;
    created_at: string;
    updated_at: string;
  };
  version: string;
  realization_date: string;
  methodology: {
    id: number;
    name: string;
  };
  practice: {
    id: number;
    name: string;
    state: string;
  };
  scores: IScore[];
  area: IArea;
  related_to_evaluation: IEvaluation;
  leader: IUserWithoutProfile;
  team_leader: IUserWithoutProfile;
  team_manager: IUserWithoutProfile;
  teams: {
    id: string;
    id_code: string;
    name: string;
    description: string;
    state: string;
    image_urls: string[];
    logo_url: string;
  }[];
  planned_duration_iso8601: string;
  duration_iso8601: string;
  notes?: INote[];
  planned_start_date: string;
  planned_completion_date: string;
  only_realization?: boolean;
  start_time: string;
  completion_time: string;
  evaluations_closings: IEvaluationsClosing[];
}

export interface IEvaluationPractice extends Omit<IPractice, 'blocks'> {
  blocks: BlockInEvaluation[];
}

export interface IDetailedEvaluation
  extends Omit<IEvaluation, 'practice' | 'evaluation_participations'> {
  practice: IEvaluationPractice;
  evaluation_participations?: {
    id: string;
    participant: {
      id: string;
      email: string;
      role: string;
      username: string;
      company_id: string;
      photo: string;
      first_name: string;
      last_name: string;
    };
    function: {
      id: string;
      name: string;
      state: string;
      created_at: string;
      updated_at: string;
    };
  }[];
}

export interface IEvaluationResponse {
  data: IDetailedEvaluation;
}

export interface IEvaluationsRequest {
  filters?: {
    area_ids?: number[];
  };
}

export interface IEvaluationsResponse extends IPagination {
  data: IEvaluation[];
}

export type EvaluationFilters = {
  area_ids: {
    value: number[];
  };
};

export const initEvaluationFilters = {
  area_ids: {
    value: []
  }
};

export interface BlockScore {
  goal: string | null;
  current: string | null;
  external: string | null;
}

export interface SelectedEvaluation {
  evaluationNumber: string;
}

export const initSelectedEvaluation: SelectedEvaluation = {
  evaluationNumber: '0'
};

export type CreateEvaluationBody = {
  realization_date: string | IOption;
  evaluation_type: string | IOption;
  version: string | IOption;
  date: string | IOption;
  name: string | IOption;
  practice_id: number | IOption;
  area_id: string;
  description: string;
  group: string;
  related_to_evaluation_id: string;
  notes: INote[];
  author_id?: string;
  leader_id?: string;
  team_leader_id?: string;
  team_manager_id?: string;
  archived_at?: string;
  planned_start_date?: string;
  planned_completion_date?: string;
  planned_duration: string;
  only_realization?: boolean;
  start_time?: string;
  completion_time?: string;
  duration: string;
  evaluation_participations_attributes?: {
    id?: string;
    participant_id?: string;
    function_id?: string;
    _destroy?: number;
  }[];
  team_ids?: string[];
  closing_ids?: string[];
};

export interface CreateEvaluationResponse {}

export enum ModalTypeList {
  CreateEvaluation = 'createEvaluation',
  EditEvaluation = 'editEvaluation'
}

// GRAPH TYPES

export interface RadarGraphDataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}
