import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { Button } from 'components/_form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamAddModal } from './components/TeamAddModal';
import TeamsAndGroupsTable from './components/TeamsAndGroupsTable';
import { ITeam } from 'types/team';
import { createTeam, editTeam, getTeams } from 'api/teams';
import { toast } from 'react-toastify';
import { ITeamForm } from 'types/forms/team';

export const TeamsAndGroups = () => {
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [selectedItemData, setSelectedItemData] = useState<ITeam | undefined>();

  const handleLoadData = async () => {
    const { data } = await getTeams();
    setTeams(data);
  };

  const onSubmit = async (data: ITeamForm['team']) => {
    try {
      const { leader, manager, areas, logo, members, ...restData } = data;

      const payload = {
        team: {
          ...restData,
          leader_id: leader?.value,
          manager_id: manager?.value,
          area_ids: areas?.map((area) => area.value),
          member_ids: members?.map((member) => member.value),
          logo: logo || null
        }
      };

      if (selectedItemData) {
        const { data: resultData } = await editTeam(
          String(selectedItemData.id),
          payload
        );
        setTeams((prevTeams) =>
          prevTeams.map((prevTeam) =>
            prevTeam.id === resultData.id ? resultData : prevTeam
          )
        );
        setOpenAddModal(false);
        toast.success(t('common.success'));
        setSelectedItemData(undefined);
      } else {
        const { data: resultData } = await createTeam(payload);
        setTeams((prevTeams) => [...prevTeams, resultData]);
        setOpenAddModal(false);
        toast.success(t('common.success'));
      }
    } catch (e) {
      toast.error(t('anErrorOccurred'));
    }
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <TeamAddModal
        isOpen={openAddModal}
        onCancelClick={() => setOpenAddModal(false)}
        onSubmit={onSubmit}
        selectedItemData={selectedItemData}
      />
      <Flex justifyContent="flex-end" width="100%" style={{ gap: '10px' }}>
        <Button
          variant="eucalyptus"
          onClick={() => {
            setOpenAddModal(true);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> {t('buttons.add')}
        </Button>
      </Flex>
      <TeamsAndGroupsTable
        teams={teams}
        setSelectedItemData={setSelectedItemData}
        setOpenAddModal={setOpenAddModal}
      />
    </>
  );
};
