import { IAuditGroup } from 'types/audits';

export const countGroups = (groups: IAuditGroup[]) => {
  let count = 0;

  count = count + (groups?.length || 0);

  groups?.map((group) => {
    if (group.groups?.length) {
      const groupCount = countGroups(group.groups);
      count = count + groupCount;
    }
  });

  return count;
};
