import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { Button } from 'components/_form';
import { TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { AuditPreview } from './AuditPreview/AuditPreview';

export const AuditsDetails: React.FC = () => {
  const { t } = useTranslation();

  const { selectedAudits } = useEvaluationContext();

  return (
    <CustomTabs selectedTabClassName="is-selected">
      <CustomTabList style={{ borderBottom: 'none' }}>
        <Flex flexWrap="wrap">
          <CustomTab>{t('auditsView.chart')}</CustomTab>

          {selectedAudits.map((mapSelectedAudit) => (
            <CustomTab key={`auditsView-tab-${mapSelectedAudit.id}`}>
              {mapSelectedAudit.number}
            </CustomTab>
          ))}

          <Flex ml="auto" my="auto" gap="20px">
            <Button variant="secondary" bordered onClick={() => {}}>
              <FontAwesomeIcon icon={faDownload} /> {t('buttons.save')}
            </Button>
          </Flex>
        </Flex>
      </CustomTabList>

      <TabPanel>---</TabPanel>

      {selectedAudits.map((mapSelectedAudit) => (
        <TabPanel key={`auditsView-TabPanel-${mapSelectedAudit.id}`}>
          <AuditPreview audit={mapSelectedAudit} />
        </TabPanel>
      ))}
    </CustomTabs>
  );
};
