import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Line } from 'components';
import { H2, P } from 'components/Typography/Typography';
import {
  Button,
  Checkbox,
  Radio,
  Select,
  SwitchButton,
  TextField
} from 'components/_form';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  id?: string;
}

export const OtherForm = ({ id }: Props) => {
  const { control } = useForm();

  return (
    <Flex flexWrap="wrap">
      <Flex flexDirection="column" width={['100%', '50%']}>
        <H2 variant="h3" color="coloured">
          {t('profile.displayPreferences')}
        </H2>
        <Line />

        <Flex alignItems="center">
          <Box width="40%">
            <P mx={4} variant="body">
              {t('profile.dateFormat')}
            </P>
          </Box>
          <Box mt={3} width="60%">
            <TextField />
          </Box>
        </Flex>

        <Flex alignItems="center">
          <Box width="40%">
            <P mx={4} variant="body">
              {t('profile.separators')}
            </P>
          </Box>
          <Flex width="60%">
            <Box mr={2} width="100%">
              <TextField label="profile.decimal" />
            </Box>
            <TextField label="profile.thousands" />
          </Flex>
        </Flex>

        <Flex alignItems="center">
          <Box width="40%">
            <P mx={4} variant="body">
              {t('profile.firstWeekDay')}
            </P>
          </Box>
          <Box mt={3} width="60%">
            <Select options={[]} />
          </Box>
        </Flex>

        <Flex justifyContent="end">
          <Button variant="eucalyptus">
            <Flex>
              <Box mr={2}>
                <FontAwesomeIcon icon={faRepeat} />
              </Box>
              {t('profile.default')}
            </Flex>
          </Button>
        </Flex>
      </Flex>

      {id && (
        <Flex pl={[0, 3]} flexDirection="column" width={['100%', '50%']}>
          <H2 variant="h3" color="coloured">
            {t('tasks.additionalPermissions')}
          </H2>
          <Line />

          <Flex p={3} flexDirection="column">
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <P variant="body" fontWeight={700}>
                {t('Can perform some administrative tasks')}
              </P>
              <SwitchButton />
            </Flex>
            <Controller
              control={control}
              name={`areUsersAccountsActive`}
              render={({ field: { onChange, value } }) => (
                <Box my={2}>
                  <Checkbox
                    label="Disabling and enabling user account activity"
                    checked={!!value}
                    onChange={(val) => {
                      onChange(val.target.checked ? 'field.id' : false);
                    }}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name={`deletingTasksInSubordinatesAreas`}
              render={({ field: { onChange, value } }) => (
                <Box my={2}>
                  <Checkbox
                    label="deletingTasksInSubordinatesAreas"
                    checked={!!value}
                    onChange={(val) => {
                      onChange(val.target.checked ? 'field.id' : false);
                    }}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name={`deletingTasksInAllAreas`}
              render={({ field: { onChange, value } }) => (
                <Box my={2}>
                  <Checkbox
                    label="deletingTasksInAllAreas"
                    checked={!!value}
                    onChange={(val) => {
                      onChange(val.target.checked ? 'field.id' : false);
                    }}
                  />
                </Box>
              )}
            />

            <Flex
              width="100%"
              mt={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <P variant="body" fontWeight={700}>
                {t('Access to the translation table')}
              </P>
              <SwitchButton />
            </Flex>

            <Flex flexDirection="column" p={3}>
              <Radio label="tasks.Translation table preview" />
              <Flex mt={2} justifyContent="space-between">
                <Radio label="languagesEditing" />
                <Box>
                  <Select options={[]} />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
