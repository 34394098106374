import { serialize } from 'object-to-formdata';
import { ITaskPatchResponse, ITaskPayload } from 'types/forms/task';
import {
  IPredefinedTasksResponse,
  ITaskPriorityDegreesResponse,
  ITaskResponse,
  ITasksRequest,
  ITasksResponse
} from 'types/task';
import request from './index';
import qs from 'qs';
import toErrorWithMessage from 'utilities/getErrorMessage';

export const getTasks = ({ filters }: ITasksRequest) => {
  try {
    const query = qs.stringify(filters, { arrayFormat: 'brackets' });

    return request<ITasksResponse>({
      options: {
        url: `/tasks?${query}`,
        method: 'get'
      }
    });
  } catch (e) {
    return { data: [], pagination: {}, error: toErrorWithMessage(e) };
  }
};

export const getPredefinedTasks = ({ filters }: ITasksRequest) => {
  const query = qs.stringify(filters, { arrayFormat: 'brackets' });

  return request<IPredefinedTasksResponse>({
    options: {
      url: `/predefined_tasks?${query}`,
      method: 'get'
    }
  });
};

export const getTask = (id: string | number) =>
  request<ITaskResponse>({
    options: {
      url: `/tasks/${id}`,
      method: 'get'
    }
  });

export const createTask = (data: ITaskPayload) =>
  request<ITaskPatchResponse>({
    options: {
      url: `/tasks`,
      method: 'post',
      data: serialize(data)
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const editTask = (id: number, data: ITaskPayload) =>
  request<ITaskPatchResponse>({
    options: {
      url: `/tasks/${id}`,
      method: 'patch',
      data: serialize(data, { allowEmptyArrays: true })
    },
    headers: { 'Content-Type': 'multipart/form-data' }
  });

export const deleteTask = (id: number) =>
  request({
    options: {
      url: `/tasks/${id}`,
      method: 'delete'
    }
  });

export const getTaskPriorityDegrees = () =>
  request<ITaskPriorityDegreesResponse>({
    options: {
      url: '/priority_degrees',
      method: 'get'
    }
  });
