import {
  faMinus,
  faPenToSquare,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  active?: boolean;
  children?: React.ReactNode;
  onAddClick?: () => void;
  onEditClick?: () => void;
}

const StyledWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  border-radius: 5px;
`;

const ColouredDot = styled(Flex)<{ active?: Props['active'] }>`
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: ${({ theme, active }) =>
    active ? theme.palette.accent.eucalyptus : theme.palette.neutral.lightGrey};
`;

export const AreaAccordion = ({
  title,
  onAddClick,
  onEditClick,
  children
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flex flexDirection="column" width="100%">
      <StyledWrapper width="100%" mt={3}>
        <Box width="40px">
          <Button
            fullWidth
            variant="grey"
            icon={
              children && <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
            }
            onClick={() => {
              setIsOpen((prevIsOpen) => !prevIsOpen);
            }}
          />
        </Box>

        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex alignItems="center">
            <ColouredDot ml={3} />

            <P ml={3} variant="body">
              {title}
            </P>
          </Flex>
          <Flex mx={2}>
            {/* <Box mr={2}>
              <Button
                minHeight="30px"
                variant="eucalyptus"
                onClick={onAddClick}
              >
                <Flex>
                  <Box mr={2}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                  </Box>
                  {t('submissive')}
                </Flex>
              </Button>
            </Box> */}
            <Button
              minHeight="30px"
              icon={<FontAwesomeIcon icon={faPenToSquare} />}
              onClick={onEditClick}
            />
          </Flex>
        </Flex>
      </StyledWrapper>
      {isOpen && (
        <Flex ml={4} flexDirection="column">
          {children}
        </Flex>
      )}
    </Flex>
  );
};
