import styled from 'styled-components';
import { IHeader, ITable } from '../Table';

interface ICell extends IHeader {
  isHighlighted?: boolean;
  fontSize?: ITable['fontSize'];
}

export const Cell = styled.td<ICell>`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
  padding: 5px;
  font-size: ${({ theme, fontSize }) =>
    fontSize === 'small'
      ? theme.textStyles.body2.fontSize
      : theme.textStyles.body.fontSize}px;
  color: ${({ theme, fontSize }) =>
    fontSize === 'small'
      ? theme.palette.text.secondary
      : theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.neutral.white};
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `background-color: ${theme.palette.neutral.tableHighlight} `}
  ${({ isLeftFixed }) => isLeftFixed && `position: sticky;`}
  ${({ isRightFixed }) => isRightFixed && `position: sticky;`}
`;
