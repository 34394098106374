import styled from 'styled-components';

const Wrapper = styled.span`
  opacity: 50%;
  pointer-events: none;
`;

interface Props {
  children: React.ReactNode;
}

export const Disabled: React.FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
