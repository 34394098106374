import { Flex } from 'components';
import {
  NumberCell as NumberCellStyled,
  NumberBoxWrapper,
  IconsWrapper,
  NumberCellWrapper
} from './NumberCell.styled';
import { useCallback, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import {
  GradingScaleInformationRowWrapper,
  Numbers
} from '../../EvaluationModal.styled';
import { useTranslation } from 'react-i18next';
import { SquareNumberBox } from './SquareNumberBox/SquareNumberBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faPersonRunning
} from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { NotesModal } from 'components/NotesModal/NotesModal';
import { IDetailedEvaluation, IScore, TableScore } from 'types/evaluations';
import { patchEvaluationScore, postEvaluationScore } from 'api/evaluations';
import { toast } from 'react-toastify';
import { CriterionTaskModal } from './CriterionTasksModal/CriterionTasksModal';
import { IBlock } from 'types/methologies';

interface Props {
  bgColor: string;
  score: TableScore;
  onClick?: (state: boolean) => void;
  showTooltip?: boolean;
  fontSize: number;
  setShowCriteriaModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCriteriaModalTitle: React.Dispatch<React.SetStateAction<string>>;
  cellHeight: number;
  criterionId: number;
  usedEvalations: IDetailedEvaluation[];
  setUsedEvalations: React.Dispatch<
    React.SetStateAction<IDetailedEvaluation[]>
  >;
  selectedBlock: IBlock;
}

export const NumberCell = ({
  bgColor,
  score,
  onClick,
  showTooltip = false,
  fontSize,
  setShowCriteriaModal,
  setCriteriaModalTitle,
  cellHeight,
  criterionId,
  usedEvalations,
  setUsedEvalations,
  selectedBlock
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [isSmallerCell, setIsSmallerCell] = useState(false);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isCriterionTasksModalOpen, setIsCriterionTasksModalOpen] =
    useState(false);

  const { t } = useTranslation();

  const injectReceivedData = useCallback(
    (newScore: IScore, prevEvaluations: IDetailedEvaluation[]) => {
      const oldEvaluation = prevEvaluations.find(
        ({ id }) => id === score.evaluationId
      );

      const oldBlock = oldEvaluation?.practice.blocks.find(
        (block) => block.id === selectedBlock?.id
      );

      const oldCriterion = oldBlock?.criteria.find(
        (criterion) => criterion.id === score.criterionId
      );

      if (oldEvaluation && oldBlock && oldCriterion) {
        const newEvaluations = prevEvaluations.map((prevEvaluation) =>
          prevEvaluation.id !== score.evaluationId
            ? prevEvaluation
            : {
                ...prevEvaluation,
                practice: {
                  ...prevEvaluation.practice,
                  blocks: prevEvaluation.practice.blocks.map((prevBlock) =>
                    oldBlock.id !== prevBlock.id
                      ? prevBlock
                      : {
                          ...oldBlock,
                          criteria: oldBlock.criteria.map((prevCriterion) =>
                            oldCriterion.id !== prevCriterion.id
                              ? prevCriterion
                              : { ...oldCriterion, score: newScore }
                          )
                        }
                  )
                }
              }
        );

        return newEvaluations;
      }
    },
    [score.criterionId, score.evaluationId, selectedBlock?.id]
  );

  const handleChangeCellValue = async (newValue: string) => {
    if (newValue !== score.value) {
      try {
        let newScore;

        if (score.value === undefined || score.value === null) {
          const body = {
            score: {
              score: Number(newValue),
              criterion_id: score.criterionId,
              evaluation_id: score.evaluationId,
              area_id: score.areaId
            }
          };

          const { data } = await postEvaluationScore(body);

          if (data) {
            newScore = data;
          }
        } else if (score.id) {
          const body = {
            score: {
              score: Number(newValue)
            }
          };

          const { data } = await patchEvaluationScore(score.id, body);

          if (data) {
            newScore = data;
          }
        }

        if (newScore) {
          const newSelectedEvaluations = injectReceivedData(
            newScore,
            usedEvalations
          );

          if (newSelectedEvaluations) {
            setUsedEvalations(newSelectedEvaluations);
          }
        }
      } catch (e) {
        toast.error(t('anErrorOccurred'));
      }
    }
  };

  return (
    <Flex
      flexDirection={'column'}
      onFocus={() => {
        setIsActive(() => {
          const newIsActive = true;
          onClick && onClick(newIsActive);
          setIsSmallerCell(true);

          return newIsActive;
        });
      }}
      onBlur={() => {
        setIsActive(() => {
          const newIsActive = false;
          onClick && onClick(newIsActive);
          setIsSmallerCell(false);

          return newIsActive;
        });
      }}
    >
      <NumberCellWrapper>
        <NumberCellStyled
          height={`${isSmallerCell ? cellHeight - 20 : cellHeight}px`}
          type="text"
          bgColor={bgColor}
          value={score.value}
          readOnly={true}
        />
        {isActive && (
          <IconsWrapper cellHeight={cellHeight}>
            <FontAwesomeIcon
              icon={faPersonRunning}
              cursor="pointer"
              onMouseDown={() => setIsCriterionTasksModalOpen(true)}
            />
            <FontAwesomeIcon
              icon={faPaperclip}
              cursor="pointer"
              onMouseDown={() => {
                setShowCriteriaModal(true);
                setCriteriaModalTitle('attachmentsForCriterion');
              }}
            />
            <FontAwesomeIcon
              icon={faComment}
              cursor="pointer"
              onMouseDown={() => setIsCommentModalOpen(true)}
            />
          </IconsWrapper>
        )}
      </NumberCellWrapper>

      {isActive && (
        <>
          <NumberBoxWrapper id="grading-scale">
            <SquareNumberBox
              color="green"
              value="3"
              setValue={handleChangeCellValue}
            />
            <SquareNumberBox
              color="blue"
              value="1"
              setValue={handleChangeCellValue}
            />
            <SquareNumberBox
              color="red"
              value="0"
              setValue={handleChangeCellValue}
            />
          </NumberBoxWrapper>
          {showTooltip && (
            <Tooltip
              style={{ width: 60 + (fontSize - 16) * 5 + '%', zIndex: '99' }}
              anchorId="grading-scale"
            >
              <GradingScaleInformationRowWrapper>
                <Numbers>3 -&nbsp;</Numbers>
                {t('evaluationsView.gradingScaleDescription.3')}
              </GradingScaleInformationRowWrapper>
              <GradingScaleInformationRowWrapper>
                <Numbers>1 -&nbsp;</Numbers>
                {t('evaluationsView.gradingScaleDescription.1')}
              </GradingScaleInformationRowWrapper>
              <GradingScaleInformationRowWrapper>
                <Numbers>0 -&nbsp;</Numbers>
                {t('evaluationsView.gradingScaleDescription.0')}
              </GradingScaleInformationRowWrapper>
            </Tooltip>
          )}
        </>
      )}
      <NotesModal
        isOpen={isCommentModalOpen}
        onCancelClick={() => setIsCommentModalOpen(false)}
        customTitle="tasks.commentTitle"
      />
      {isCriterionTasksModalOpen && (
        <CriterionTaskModal
          isOpen={isCriterionTasksModalOpen}
          onCancelClick={() => setIsCriterionTasksModalOpen(false)}
          score={score}
        />
      )}
    </Flex>
  );
};
