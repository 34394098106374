import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortAsc,
  faSortDesc
} from '@fortawesome/free-solid-svg-icons';
import { Box, Flex } from 'components';
import { IOption, Select } from 'components/_form/Select/Select';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ITablePagination, TablePagination } from './TablePagination';
import { useTranslation } from 'react-i18next';
import { P } from 'components/Typography/Typography';
import { TableRow } from './components/TableRow';

const Container = styled(Box)<{ height?: string }>`
  padding-bottom: 12px;
  overflow: auto;
  width: 100%;
  ${({ height }) => `height: ${height}`};
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
`;

export interface IHeader {
  isLeftFixed?: boolean;
  isRightFixed?: boolean;
  fontSize?: ITable['fontSize'];
}

const Header = styled.th<IHeader>`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.neutral.tableHeader};
  padding: ${({ fontSize }) =>
    fontSize === 'small' ? '5px 10px' : '10px 15px;'};
  font-size: ${({ theme, fontSize }) =>
    fontSize === 'small'
      ? theme.textStyles.body2.fontSize
      : fontSize === 'big'
      ? theme.textStyles.tableHeader.fontSize
      : theme.textStyles.body.fontSize}px;
  color: ${({ theme, fontSize }) =>
    fontSize === 'small'
      ? theme.palette.text.secondary
      : theme.palette.text.primary};

  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.neutral.tableHeader};
  ${({ isLeftFixed }) => isLeftFixed && `position: sticky;`}
  ${({ isRightFixed }) => isRightFixed && `position: sticky;`}
`;

const HelpersWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  gap: 10px;
  background-color: ${({ theme }) => theme.palette.neutral.tablePagination};
  justify-content: space-between;
  padding: 10px 20px;
`;

export interface ITable {
  additionalHeaderElement?: React.ReactNode;
  header: (string | React.ReactNode | { title: string; items: string[] })[];
  data: {
    [key: string]: any;
  }[];
  highlightCells?: boolean;
  pagination?: ITablePagination & {
    setPerPage: React.Dispatch<React.SetStateAction<number>>;
  };
  leftFixedKeys?: number[];
  rightFixedKeys?: number[];
  hiddenItems?: string[];
  sortTable?: boolean;
  accordionsChildren?: JSX.Element[];
  fontSize?: 'big' | 'normal' | 'small';
  width?: string;
  containerHeight?: string;
  onClickRow?: (evaluation: { number: string }) => void;
}

export interface ISorting {
  by?: number;
  direction: 'asc' | 'desc' | 'none';
}

const SelectOptions = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '200', value: '200' }
];

export const Table = ({
  additionalHeaderElement,
  header,
  data,
  pagination,
  highlightCells,
  leftFixedKeys,
  rightFixedKeys,
  hiddenItems,
  sortTable = true,
  accordionsChildren,
  fontSize,
  width,
  containerHeight,
  onClickRow
}: ITable) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const { t } = useTranslation();
  const defaultSelectedOption =
    pagination &&
    SelectOptions.findIndex((e) => e.value === String(pagination.perPage));

  const [sorting, setSorting] = useState<ISorting>({
    by: undefined,
    direction: 'asc'
  });

  const hiddenItemsIndexes =
    (data.length &&
      hiddenItems?.map((prop) =>
        data[0].colType === 'internallyAccordable'
          ? Object.keys(data[0].children[0].children[0]).indexOf(prop)
          : Object.keys(data[0]).indexOf(prop)
      )) ||
    [];

  const sortDirection = () => {
    switch (sorting.direction) {
      case 'asc':
        return 'desc';
      case 'desc':
        return 'none';
      case 'none':
        return 'asc';
    }
  };

  const handleSort = (k: number) => {
    setSorting({ by: k, direction: sortDirection() });
  };

  return (
    <Box my="10px" width={width ? width : '100%'}>
      {!!additionalHeaderElement && (
        <HelpersWrapper display={['none', 'flex']}>
          {additionalHeaderElement}
        </HelpersWrapper>
      )}

      <Container height={containerHeight}>
        <StyledTable>
          <thead ref={headerRef}>
            <tr>
              {accordionsChildren?.find((child) => child) && <div></div>}

              {header.map(
                (
                  e:
                    | string
                    | React.ReactNode
                    | { title: string; items: string[] },
                  k
                ) => {
                  return hiddenItemsIndexes.includes(k) ? (
                    <></>
                  ) : e instanceof Object && !React.isValidElement(e) ? (
                    <Header
                      key={k}
                      onClick={() => {
                        handleSort(k);
                      }}
                      isLeftFixed={leftFixedKeys?.includes(k)}
                      isRightFixed={rightFixedKeys?.includes(k)}
                      colSpan={2}
                      fontSize={fontSize}
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        {(e as { title: string; items: string[] }).title}
                      </Flex>
                    </Header>
                  ) : (
                    <Header
                      key={k}
                      onClick={() => {
                        handleSort(k);
                      }}
                      isLeftFixed={leftFixedKeys?.includes(k)}
                      isRightFixed={rightFixedKeys?.includes(k)}
                      rowSpan={2}
                      fontSize={fontSize}
                    >
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap="5px"
                      >
                        {e}
                        {sortTable &&
                          !!e &&
                          !React.isValidElement(e) &&
                          (sorting.by !== k ? (
                            <FontAwesomeIcon icon={faSort} />
                          ) : (
                            {
                              asc: <FontAwesomeIcon icon={faSortAsc} />,
                              desc: <FontAwesomeIcon icon={faSortDesc} />,
                              none: <FontAwesomeIcon icon={faSort} />
                            }[sorting.direction]
                          ))}
                      </Flex>
                    </Header>
                  );
                }
              )}
            </tr>
            <tr>
              {accordionsChildren?.find((child) => child) && <div></div>}
              {header.map(
                (
                  e:
                    | string
                    | React.ReactNode
                    | { title: string; items: string[] },
                  k
                ) => {
                  return (
                    e instanceof Object &&
                    !React.isValidElement(e) &&
                    (e as { title: string; items: string[] }).items.map(
                      (item) => (
                        <Header
                          key={k}
                          onClick={() => {
                            handleSort(k);
                          }}
                          isLeftFixed={leftFixedKeys?.includes(k)}
                          isRightFixed={rightFixedKeys?.includes(k)}
                          fontSize={fontSize}
                        >
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {item}
                            {!!e &&
                              !React.isValidElement(e) &&
                              (sorting.by !== k ? (
                                <FontAwesomeIcon icon={faSort} />
                              ) : (
                                {
                                  asc: <FontAwesomeIcon icon={faSortAsc} />,
                                  desc: <FontAwesomeIcon icon={faSortDesc} />,
                                  none: <FontAwesomeIcon icon={faSort} />
                                }[sorting.direction]
                              ))}
                          </Flex>
                        </Header>
                      )
                    )
                  );
                }
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((e, k) => {
              return (
                <TableRow
                  key={k}
                  k={k}
                  e={e}
                  accordionsChildren={accordionsChildren}
                  hiddenItems={hiddenItems}
                  leftFixedKeys={leftFixedKeys}
                  rightFixedKeys={rightFixedKeys}
                  highlightCells={highlightCells}
                  fontSize={fontSize}
                  onClickRow={onClickRow}
                />
              );
            })}
          </tbody>
        </StyledTable>
      </Container>

      {pagination &&
        defaultSelectedOption !== -1 &&
        defaultSelectedOption !== undefined && (
          <HelpersWrapper flexWrap="wrap">
            <Flex
              width="fit-content"
              alignItems="center"
              style={{ gap: '10px', whiteSpace: 'nowrap' }}
            >
              <P variant="body">{t('pagination.rowsPerPage')}:</P>
              <Select
                options={SelectOptions}
                selectedOptions={
                  defaultSelectedOption !== -1
                    ? SelectOptions[defaultSelectedOption]
                    : undefined
                }
                onChange={(e: IOption) => {
                  pagination.setPerPage(Number(e.value));
                }}
                withoutMargin
                menuPlacement="top"
              />
            </Flex>
            <TablePagination
              total={pagination.total}
              page={pagination.page}
              perPage={pagination.perPage}
              setPage={pagination.setPage}
              headerRef={headerRef}
            />
          </HelpersWrapper>
        )}
    </Box>
  );
};
