import { ChangeEvent, useRef, useState } from 'react';
import { Flex } from 'components';
import { Button } from 'components/_form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IImageInput {
  type: 'photo' | 'logo';
  placeholder: 'string';
  setValue?: (file: Blob | undefined) => void;
  defaultImage?: string;
}

const HiddenInput = styled.input`
  display: none;
`;

const ImageBox = styled(Flex)<{ imageUrl?: string }>`
  width: 165px;
  height: 110px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${({ theme }) => `color: ${theme.palette.text.secondary};
  border: 1px dashed ${theme.palette.text.secondary};`};

  ${({ imageUrl }) => !!imageUrl && `background-image: url("${imageUrl}");`}
`;

export const ImageInput = ({
  type,
  placeholder,
  setValue,
  defaultImage
}: IImageInput) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<string>(defaultImage || '');

  const typeMap = {
    photo: t('employeesAndUsersView.tableHeaders.photo'),
    logo: t('employeesAndUsersView.tableHeaders.logo')
  };

  const ref = useRef<HTMLInputElement>(null);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setValue && files && setValue(files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    if (files) reader.readAsDataURL(files[0]);
  };

  return (
    <Flex width="100%" height="100%" style={{ gap: '10px' }}>
      <HiddenInput ref={ref} type="file" onChange={onChange} />
      <Flex
        flexDirection="column"
        width="100%"
        justifyContent={!image ? 'end' : 'space-between'}
      >
        {image && (
          <Button
            variant="redWhite"
            bordered
            onClick={() => {
              setImage('');
              setValue && setValue(undefined);
            }}
            label={`${t('buttons.delete')} ${typeMap[type]}`}
          />
        )}

        <Button
          variant="grey"
          bordered
          onClick={() => {
            ref?.current?.click();
          }}
          label={
            !image
              ? `${t('buttons.add')} ${typeMap[type]}`
              : `${t('buttons.change')} ${typeMap[type]}`
          }
        />
      </Flex>

      <ImageBox imageUrl={image} flexShrink="0">
        {!image && placeholder}
      </ImageBox>
    </Flex>
  );
};
