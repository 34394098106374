import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { Button, TextField } from 'components/_form';
import { t } from 'i18next';

import { AccordionItemButton } from 'react-accessible-accordion';
import {
  StyledAccordionItemHeading,
  StyledAccordionItemPanel
} from '../../ProfileView.styled';

export const EmailAddressForm = () => {
  return (
    <>
      <StyledAccordionItemHeading>
        <AccordionItemButton>
          <Flex p={2}>
            <FontAwesomeIcon icon={faEnvelope} />
            <P ml={2} variant="body">
              {t('profile.changeEmailAddress')}
            </P>
          </Flex>
        </AccordionItemButton>
      </StyledAccordionItemHeading>
      <StyledAccordionItemPanel>
        <Flex>
          <TextField label="common.email" />
        </Flex>

        <Flex justifyContent="end">
          <Button variant="green">{t('common.save')}</Button>
        </Flex>
      </StyledAccordionItemPanel>
    </>
  );
};
