import { editUser, getMe, getUser } from 'api/user';
import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H1, P } from 'components/Typography/Typography';
import { Button } from 'components/_form';
import { useAccount } from 'hooks/useAccount';
import { useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IProfileForm } from 'types/forms/profile';
import { IUserForm } from 'types/forms/user';
import { IUser } from 'types/user';
import { AreaPermissionsForm } from './components/AreaPermissions/AreaPermissionsForm';
import { AvatarForm } from './components/Avatar/AvatarForm';
import { MailNotificationsForm } from './components/MailNotifications/MailNotificationsForm';
import { OtherForm } from './components/Other/OtherForm';
import { BasicInformationForm } from './components/Profile/BasicInformationForm';
import { EmailAddressForm } from './components/Profile/EmailAddressForm';
import { PasswordForm } from './components/Profile/PasswordForm/PasswordForm';
import { UsernameForm } from './components/Profile/UsernameForm';
import { StyledAccordionItem, Wrapper } from './ProfileView.styled';

export const ProfileView = () => {
  const { loggedUserData } = useAccount();
  const [userData, setUserData] = useState<IUser>();

  const { id, defaultTabIndex } = useParams();

  const { t } = useTranslation();

  const { register, handleSubmit, reset, control } = useForm();

  const handleGetUserData = async () => {
    const { data } = id ? await getUser(id) : await getMe();
    setUserData(data);

    const { role, username, profile } = data;

    if (profile) {
      const { photo, ...restData } = profile;
      reset({ ...restData, role, username });
      return;
    }

    reset({ role, username });
  };

  const handleUpdateProfile = async (
    data: Partial<IProfileForm['profile']> &
      Partial<Omit<IUserForm['user'], 'profile'>>
  ) => {
    try {
      if (userData && userData.id) {
        const { id: userDataId, email, profile, ...restUserData } = userData;
        const {
          id: profileId,
          photo,
          ...restProfile
        } = profile || { id: null, photo: null };
        const { username, role, ...restData } = data;

        const payload = {
          user: {
            ...restData,
            username: username || restUserData.username,
            role: role || restUserData.role,
            profile_attributes: { ...restProfile, ...restData }
          }
        };

        const { data: newUserData } = await editUser(userData.id, payload);
        setUserData(newUserData);

        toast.success(t('success'));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('anErrorOccurred'));
    }
  };

  const handleUpdateProfilePhoto = (photo: Blob) => {
    handleUpdateProfile({ photo });
  };

  const handleSubmitUpdateProfile = handleSubmit((data) => {
    handleUpdateProfile(data);
  });

  useEffect(() => {
    handleGetUserData();
  }, []);

  return (
    <Wrapper flexDirection="column">
      <Flex width="100%">
        <BackButton />

        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t(!id ? 'profile.myProfile' : 'administration.employeesAndUsers')} -{' '}
          {userData?.profile?.first_name} {userData?.profile?.last_name}
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px" flexWrap="wrap">
        <Flex
          px={3}
          width={['100%', '30%']}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex width="100%" alignItems="center" flexDirection="column">
            <AvatarForm
              handleSubmit={handleUpdateProfilePhoto}
              avatar={userData?.profile?.photo}
            />
          </Flex>
          <Flex flexDirection="column" py={3}>
            <P variant="body">Ostatnie logowanie: 20.12.2022, 16:02</P>
            <P mb={2} variant="body">
              Ostatnia aktywność: 20.12.2022, 18:02
            </P>

            {id && (
              <Button variant="redWhite" bordered>
                {t('profile.deleteUser')}
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex width={['100%', '70%']}>
          <CustomTabs
            defaultIndex={parseInt(defaultTabIndex || '0')}
            selectedTabClassName="is-selected"
          >
            <CustomTabList>
              <Flex flexWrap="wrap">
                <CustomTab>{t('profile.profile')}</CustomTab>
                <CustomTab>{t('profile.areaPermissions')}</CustomTab>
                <CustomTab>{t('profile.mailNotifications')}</CustomTab>
                <CustomTab>{t('common.other')}</CustomTab>
              </Flex>
            </CustomTabList>
            <CustomTabPanel>
              <Accordion allowMultipleExpanded allowZeroExpanded>
                <StyledAccordionItem>
                  <BasicInformationForm
                    id={id}
                    register={register}
                    onSubmit={handleSubmitUpdateProfile}
                    control={control}
                  />
                </StyledAccordionItem>
                <StyledAccordionItem>
                  <EmailAddressForm />
                </StyledAccordionItem>
                <StyledAccordionItem>
                  <UsernameForm
                    register={register}
                    onSubmit={handleSubmitUpdateProfile}
                  />
                </StyledAccordionItem>
                <StyledAccordionItem>
                  <PasswordForm />
                </StyledAccordionItem>
              </Accordion>
            </CustomTabPanel>
            <CustomTabPanel>
              <AreaPermissionsForm />
            </CustomTabPanel>
            <CustomTabPanel>
              <MailNotificationsForm />
            </CustomTabPanel>
            <CustomTabPanel>
              <OtherForm id={id} />
            </CustomTabPanel>
          </CustomTabs>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
