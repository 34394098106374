import {
  faChevronLeft,
  faChevronRight,
  faFileArrowDown,
  faImages,
  faRotate,
  faStar as faStarSolid,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { Button } from 'components/_form';
import { Box } from 'components/Box';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { P } from 'components/Typography/Typography';

const StyledInput = styled.input`
  display: none;
`;

const StyledImage = styled.img`
  object-fit: contain;
`;

const OverflowFlex = styled(Flex)`
  overflow-y: hidden;
  overflow-x: auto;
`;

const ImageFlex = styled(Flex)<{ bordered?: boolean }>`
  ${({ theme, bordered }) =>
    bordered ? `border: 2px solid ${theme.palette.accent.green}` : ''}
`;

const ImageTextWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: center;
`;

const CounterText = styled(P)`
  position: absolute;
  top: 4px;
  left: 0px;
`;

interface Props {
  images: File[];
  setImages: (images: File[]) => void;
  disabled?: boolean;
  allowEdit?: boolean;
}

export const ImagesPicker: FC<Props> = ({
  images,
  setImages,
  disabled,
  allowEdit
}) => {
  const [selectedImage, setSelectedImage] = useState<File>();
  const [mainImage, setMainImage] = useState<File>();

  const ref = useRef<HTMLInputElement>(null);
  const refReplace = useRef<HTMLInputElement>(null);

  const onChange = (
    e: ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    if (e.target.files) {
      const newImages = [...images, ...Array.from(e.target.files || [])];

      setImages(newImages);

      if (!selectedImage) {
        setSelectedImage(e.target.files[0]);
      }

      if (!mainImage) {
        setMainImage(e.target.files[0]);
      }
    }
  };

  const onReplaceChange = (
    e: ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    if (e.target.files && e.target.files[0] && selectedImage) {
      const selectedImageIndex = images.indexOf(selectedImage);

      const newImages = e.target.files
        ? [
            ...images.slice(0, selectedImageIndex),
            e.target.files[0],
            ...images.slice(selectedImageIndex + 1)
          ]
        : images;

      setImages(newImages);

      setSelectedImage(e.target.files[0]);

      if (!mainImage) {
        setMainImage(e.target.files[0]);
      }
    }
  };

  const handleDeleteImage = () => {
    if (selectedImage) {
      const filterdImages = images.filter(
        (prevImage) => prevImage.name !== selectedImage.name
      );

      setImages(filterdImages);
      setSelectedImage(filterdImages[0]);
      setMainImage((prevMainImage) =>
        prevMainImage?.name === selectedImage.name
          ? filterdImages[0]
          : prevMainImage
      );
    }
  };

  const handleDownloadImage = () => {
    if (selectedImage) {
      saveAs(URL.createObjectURL(selectedImage), selectedImage.name);
    }
  };

  const handleSelectRightImage = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage
        ? images[images.indexOf(prevSelectedImage) + 1] || images[0]
        : images[0]
    );
  };

  const handleSelectLeftImage = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage
        ? images[images.indexOf(prevSelectedImage) - 1] || images[0]
        : images[0]
    );
  };

  useEffect(() => {
    setSelectedImage(images[0]);
    setMainImage(images[0]);
  }, [images]);

  return (
    <Flex flexDirection="column" m={1}>
      {selectedImage && (
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="320px"
          pb={4}
        >
          <StyledImage
            src={URL.createObjectURL(selectedImage)}
            width="100%"
            height="100%"
          />
          <ImageTextWrapper>
            {selectedImage.name && (
              <CounterText variant="h3" color="secondary">
                <Flex gap="4px">
                  <span>
                    {images
                      .map(({ name }) => name)
                      .findIndex((name) => name === selectedImage.name) + 1}
                  </span>
                  / {images?.length}
                </Flex>
              </CounterText>
            )}
            <P variant="h3" color="secondary">
              {selectedImage.name}
            </P>
          </ImageTextWrapper>
        </Flex>
      )}

      <StyledInput ref={ref} type="file" multiple onChange={onChange} />
      <StyledInput ref={refReplace} type="file" onChange={onReplaceChange} />

      {images?.length ? (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Button
                minHeight="45px"
                minWidth="45px"
                transparent
                variant="greyWhite"
                bordered
                icon={
                  <FontAwesomeIcon
                    size="2x"
                    icon={faChevronLeft}
                    color="black"
                  />
                }
                onClick={handleSelectLeftImage}
                disabled={disabled}
              />
            </Box>

            <Flex p={2} flexWrap="wrap">
              <Box mr={1}>
                <Button
                  disabled={!allowEdit || disabled}
                  variant="lightGreen"
                  icon={
                    <FontAwesomeIcon
                      icon={
                        selectedImage &&
                        mainImage &&
                        selectedImage.name === mainImage.name
                          ? faStarSolid
                          : faStar
                      }
                    />
                  }
                  onClick={() => {
                    if (selectedImage) {
                      setImages([
                        selectedImage,
                        ...images.filter(
                          (image) => image.name !== selectedImage.name
                        )
                      ]);
                      setMainImage(selectedImage);
                    }
                  }}
                />
              </Box>
              <Box mr={1}>
                <Button
                  disabled={!allowEdit || disabled}
                  variant="eucalyptus"
                  onClick={() => {
                    ref?.current?.click();
                  }}
                >
                  <Flex>
                    <Box mr={1}>
                      <FontAwesomeIcon icon={faImages} />
                    </Box>
                    {t('common.addImage')}
                  </Flex>
                </Button>
              </Box>
              <Box mr={1}>
                <Button
                  disabled={!allowEdit || disabled}
                  variant="eucalyptus"
                  onClick={() => {
                    refReplace?.current?.click();
                  }}
                >
                  <Flex>
                    <Box mr={1}>
                      <FontAwesomeIcon icon={faRotate} />
                    </Box>
                    {t('buttons.change')}
                  </Flex>
                </Button>
              </Box>
              <Box mr={1}>
                <Button
                  disabled={disabled}
                  variant="eucalyptus"
                  onClick={handleDownloadImage}
                >
                  <Flex>
                    <Box mr={1}>
                      <FontAwesomeIcon icon={faFileArrowDown} />
                    </Box>
                    {t('buttons.download')}
                  </Flex>
                </Button>
              </Box>
              <Button
                disabled={!allowEdit || disabled}
                variant="red"
                icon={<FontAwesomeIcon icon={faTrashCan} />}
                onClick={handleDeleteImage}
              />
            </Flex>
            <Box>
              <Button
                minHeight="45px"
                minWidth="45px"
                transparent
                disabled={disabled}
                variant="greyWhite"
                bordered
                icon={
                  <FontAwesomeIcon
                    size="2x"
                    icon={faChevronRight}
                    color="black"
                  />
                }
                onClick={handleSelectRightImage}
              />
            </Box>
          </Flex>

          <OverflowFlex pt={2} pb={3}>
            {images?.map((image) => (
              <ImageFlex
                bordered={image.name === selectedImage?.name}
                mr={2}
                p={1}
                flexDirection="column"
                alignItems="center"
                key={image.name}
              >
                <Flex
                  width="90px"
                  height="90px"
                  onClick={() => {
                    setSelectedImage(image);
                  }}
                >
                  <StyledImage
                    src={URL.createObjectURL(image)}
                    height="100%"
                    width="100%"
                  />
                </Flex>
                <P variant="body3">{image.name}</P>
              </ImageFlex>
            ))}
          </OverflowFlex>
        </>
      ) : (
        <>
          <Button
            variant="eucalyptus"
            onClick={() => {
              ref?.current?.click();
            }}
            disabled={!allowEdit || disabled}
          >
            <Flex width="100%" justifyContent="space-between">
              <Flex width="40%">
                <Box mr={1}>
                  <FontAwesomeIcon icon={faImages} />
                </Box>
              </Flex>
              <Flex width="60%">{t('common.addImage')}</Flex>
            </Flex>
          </Button>
        </>
      )}
    </Flex>
  );
};
