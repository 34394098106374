import { rgba } from 'utilities/rgba';

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`
};

const palettePrimaryMain = '#326889';

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      dark: '#23485e'
    },
    neutral: {
      mainBlue: '#00a0e6',
      dark: '#283038',
      grey: '#464646',
      greyButton: '#979fa5',
      lightGrey: '#d9d9d9',
      lightBlue: '#DFF2FB',
      lightGreen: '#evf7d7',
      light: '#dee2e6',
      white: '#FFFFFF',
      black: '#000000',
      veryLight: '#F6F6F6',
      medium: '#ced4da',
      greyBlue: '#d0d0de',
      tablePagination: '#f2f3f7',
      tableHeader: '#eaebf0',
      tableHighlight: '#ffffaa'
    },
    accent: {
      yellow: '#F5D73F',
      orange: '#F5843F',
      veryLightBlue: '#e3f2fd',
      lightBlue: '#26B9E7',
      blue: '#00a0e6',
      steelBlue: '#4084AE',
      red: '#e6501e',
      lightRed: '#f6ccd0',
      darkGreen: '#0f5164',
      green: '#96c31e',
      lightGreen: '#d1e7dd',
      eucalyptus: '#1f9d57',
      creme: '#f0faeb',
      brown: '#82501e'
    },
    text: {
      primary: '#333333',
      secondary: rgba('#283038', 0.7),
      red: '#dc3545'
    },
    background: '#E2E2E2'
  },
  textStyles: {
    tableHeader: {
      fontSize: 14,
      lineHeight: '19px'
    },
    body4: {
      fontSize: 6,
      lineHeight: '8px'
    },
    body3: {
      fontSize: 8,
      lineHeight: '10px'
    },
    body2: {
      fontSize: 11,
      lineHeight: '13px'
    },
    body: {
      fontSize: 13,
      lineHeight: '15px'
    },
    h4: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 700
    },
    h3: {
      fontSize: 15,
      lineHeight: '20px',
      fontWeight: 700
    },
    h2: {
      fontSize: 22,
      lineHeight: '24px',
      fontWeight: 700
    },
    h1: {
      fontSize: 26,
      lineHeight: '27px',
      fontWeight: 700
    },
    button: {
      fontSize: 13
    }
  }
};

export const typeColorsDict = {
  normal: theme.palette.neutral.grey,
  target: theme.palette.accent.blue,
  baseline: theme.palette.accent.red,
  current: theme.palette.accent.green,
  derivative: theme.palette.accent.brown
};
