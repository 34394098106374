import {
  IAuditGroup,
  IGroupsAttributes,
  PostAuditFormCriteriaAttribute
} from 'types/audits';

type Data = (
  | (IGroupsAttributes & Partial<IAuditGroup>)
  | IAuditGroup
  | PostAuditFormCriteriaAttribute
)[];

export const flattenNestedCriteria = (
  data: Data
): PostAuditFormCriteriaAttribute[] => {
  let newData: Data = [];
  const flattenMembers = data.map((m, index) => {
    if (
      'criteria_attributes' in m &&
      m.criteria_attributes &&
      m.criteria_attributes?.length
    ) {
      newData = [
        ...newData,
        ...m.criteria_attributes.map((criteriionAttr) => ({
          ...criteriionAttr,
          nr: `${m.nr || index + 1}.${criteriionAttr.nr}`
        }))
      ];
    }

    if ('criteria' in m && m.criteria && m.criteria?.length) {
      newData = [
        ...newData,
        ...m.criteria.map((criteriion) => ({
          ...criteriion,
          nr: `${m.nr || index + 1}.${criteriion.nr}`
        }))
      ];
    }

    if (
      'groups_attributes' in m &&
      m.groups_attributes &&
      m.groups_attributes?.length
    ) {
      newData = [
        ...newData,
        ...m.groups_attributes.map((groupAttr, groupIndex) => ({
          ...groupAttr,
          nr: `${m.nr || index + 1}.${groupAttr.nr || groupIndex + 1}`
        }))
      ];
    }

    if ('groups' in m && m.groups && m.groups?.length) {
      newData = [
        ...newData,
        ...m.groups.map((group, groupIndex) => ({
          ...group,
          nr: `${m.nr || index + 1}.${group.nr || groupIndex + 1}`
        }))
      ];
    }

    return m;
  });

  return flattenMembers
    .concat(newData.length ? flattenNestedCriteria(newData) : newData)
    .filter(
      (item) => !('criteria_attributes' in item || 'criteria' in item)
    ) as PostAuditFormCriteriaAttribute[];
};
