import { Button } from 'components/_form';
import styled from 'styled-components';
import { ITable } from '../Table';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faMinus,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { Flex } from 'components/Flex';
import { Cells } from './Cells';
import { Cell } from './Cell';

const Child = styled.td`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: none !important;
`;

interface Props {
  k: number;
  e: { [key: string]: any };
  accordionsChildren?: JSX.Element[];
  hiddenItems?: string[];
  leftFixedKeys?: number[];
  rightFixedKeys?: number[];
  highlightCells?: boolean;
  fontSize?: ITable['fontSize'];
  onClickRow?: (evaluation: { number: string }) => void;
}

export const TableRow: React.FC<Props> = ({
  k,
  e,
  accordionsChildren,
  hiddenItems,
  leftFixedKeys,
  rightFixedKeys,
  highlightCells,
  fontSize,
  onClickRow
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <>
      {e.colType === 'internallyAccordable' ? (
        <>
          <tr>
            <Cell colSpan={100}>
              <Flex alignItems="center">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsAccordionOpen(
                      (prevIsAccordionOpen) => !prevIsAccordionOpen
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={isAccordionOpen ? faCaretUp : faCaretDown}
                  />
                </Button>{' '}
                {e.primaryAccordion}
              </Flex>
            </Cell>
          </tr>

          {isAccordionOpen &&
            e.children.map((child: object, index: number) => (
              <Cells
                key={`cells-${index}`}
                obj={child}
                e={e}
                hiddenItems={hiddenItems}
                leftFixedKeys={leftFixedKeys}
                rightFixedKeys={rightFixedKeys}
                highlightCells={highlightCells}
                fontSize={fontSize}
                onClickRow={onClickRow}
              />
            ))}
        </>
      ) : (
        <>
          <tr>
            {accordionsChildren && (
              <td>
                <Flex mx={2}>
                  <Button
                    variant="grey"
                    minHeight="24px"
                    onClick={() => {
                      setIsAccordionOpen(
                        (prevIsAccordionOpen) => !prevIsAccordionOpen
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={isAccordionOpen ? faMinus : faPlus}
                    />
                  </Button>
                </Flex>
              </td>
            )}

            {Object.keys(e).map((v, k) => {
              return hiddenItems?.includes(v) ? (
                <></>
              ) : (
                <Cell
                  fontSize={fontSize}
                  key={k}
                  isHighlighted={!e[v as keyof typeof e] && highlightCells}
                  isLeftFixed={leftFixedKeys?.includes(k)}
                  isRightFixed={rightFixedKeys?.includes(k)}
                >
                  {e[v as keyof typeof e]}
                </Cell>
              );
            })}
          </tr>
          {isAccordionOpen && accordionsChildren && (
            <tr>
              <Child colSpan={6}>
                <Flex justifyContent="end" width="100%">
                  {accordionsChildren[k]}
                </Flex>
              </Child>
            </tr>
          )}
        </>
      )}
    </>
  );
};
