import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { P } from 'components/Typography/Typography';
import { t } from 'i18next';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'styles';
import { device } from 'styles/theme';

const StyledLink = styled(Link)<{ variant?: Props['variant'] }>`
  display: flex;
  background-color: ${({ theme, variant }) =>
    variant === 'primary'
      ? theme.palette.primary.main
      : theme.palette.neutral.white};
  color: ${({ theme, variant }) =>
    variant === 'primary'
      ? theme.palette.neutral.white
      : theme.palette.text.primary};
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  margin: 10px;
  padding: 10px;
  width: 31%;
  border-radius: 5px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

interface Props {
  title: string;
  description: string;
  icon: IconDefinition;
  variant?: 'primary' | 'secondary';
  link?: string;
}

export const SettingTab: FC<Props> = ({
  title,
  description,
  icon,
  variant = 'primary',
  link
}) => {
  return (
    <StyledLink to={link ?? title} variant={variant}>
      <Flex pt={1} justifyContent="center" alignItems="center">
        <FontAwesomeIcon
          width="130px"
          color={theme.palette.accent.steelBlue}
          icon={icon}
          size="5x"
        />
      </Flex>
      <Flex flexDirection="column">
        <P variant="h3">{t(title)}</P>
        <P variant="body">{t(description)}</P>
      </Flex>
    </StyledLink>
  );
};
