import React, { useContext, useState } from 'react';
import { IPractice } from 'types/methologies';

export interface IDocumentsSidebarContext {
  selectedPractice: IPractice | undefined;
  setSelectedPractice: React.Dispatch<
    React.SetStateAction<IPractice | undefined>
  >;
}

const DocumentsSidebarContext = React.createContext<
  IDocumentsSidebarContext | undefined
>(undefined);

export const DocumentsSidebarContextContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedPractice, setSelectedPractice] = useState<IPractice>();

  return (
    <DocumentsSidebarContext.Provider
      value={{
        selectedPractice,
        setSelectedPractice
      }}
    >
      {children}
    </DocumentsSidebarContext.Provider>
  );
};

export const useDocumentsSidebarContext = () => {
  const context = useContext(DocumentsSidebarContext);
  if (!context) {
    throw new Error(
      'useDocumentsSidebarContext must be inside a DocumentsSidebarContextProvider with a value'
    );
  }
  return context;
};
