import { Flex } from 'components';
import styled from 'styled-components';

export const Title = styled(Flex)<{ fontColor?: string }>`
  color: ${({ fontColor }) => fontColor};
`;

export const IconWrapper = styled(Flex)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: auto;
`;
