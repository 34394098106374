import { IPostAuditFormRequest } from 'types/audits';
import { clearCriteriaFromIds } from './clearCriteriaFromIds';

export const clearGroupsFromIds = (
  groupsAttributes: IPostAuditFormRequest['audit_form']['groups_attributes']
) => {
  let result: IPostAuditFormRequest['audit_form']['groups_attributes'] = [];

  groupsAttributes.map((groupAttribute) => {
    const { id, criteria_attributes, groups_attributes, ...restData } =
      groupAttribute;

    result = [
      ...result,
      {
        ...restData,
        groups_attributes: clearGroupsFromIds(groups_attributes),
        criteria_attributes: clearCriteriaFromIds(criteria_attributes)
      }
    ];
  });

  return result;
};
