import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, Wrapper } from './HiddenFilter.styled';
import { Flex } from '../../../../../../../components';
import { Checkbox } from '../../../../../../../components/_form';
import { useTaskSidebarContext } from '../../../../../../../contexts/TaskSidebarContext';
import { handlePrevState } from 'utilities/handlePrevState';

interface IHiddenFilterItem {
  id: number;
  label: string;
}

const filterItems: IHiddenFilterItem[] = [
  {
    id: 1,
    label: 'tasks.activeFilter'
  },
  {
    id: 2,
    label: 'tasks.archivedFilter'
  },
  {
    id: 3,
    label: 'tasks.canceledFilter'
  }
];

export const HiddenFilter: React.FC = () => {
  const [items, setItems] = useState<IHiddenFilterItem[]>(filterItems);
  const {
    filters: { hidden },
    setFilters
  } = useTaskSidebarContext();
  const { t } = useTranslation();

  const handleSelect = useCallback((id: number) => {
    setFilters((prevState) => {
      const actualState = handlePrevState(prevState.hidden.value, id);
      return {
        ...prevState,
        hidden: {
          label:
            actualState.length > 0
              ? t('tasks.hidden') +
                ' - ' +
                filterItems
                  .filter((el) => actualState.includes(el.id))
                  .map((el) => t(el.label))
                  .join(', ')
              : '',
          value: actualState
        }
      };
    });
  }, []);

  return (
    <Wrapper>
      {items.map((el) => (
        <React.Fragment key={el.id}>
          <Item onClick={() => handleSelect(el.id)}>
            <p>{t(el.label)}</p>
            <Flex ml="auto">
              <Checkbox
                onChange={() => handleSelect(el.id)}
                checked={hidden.value.includes(el.id)}
              />
            </Flex>
          </Item>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
