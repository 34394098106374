import { CollapseButton, Wrapper } from './Sidebar.styled';
import React, { useState } from 'react';
import { DropdownFilters } from './components/DropdownFilters/DropdownFilters';
import { Collapse } from 'react-collapse';
import { Box } from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faFilter,
  faFloppyDisk
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { AreaFilter } from './components/Filters/AreaFilter/AreaFilter';
import { StarredFilter } from './components/Filters/StarredFilter/StarredFilter';
import { StatusPDCAFilter } from './components/Filters/StatusPDCAFilter/StatusPDCAFilter';
import { ResponsibleFilter } from './components/Filters/ResponsibleFilter/ResponsibleFilter';
import { AuthorFilter } from './components/Filters/AuthorFilter/AuthorFilter';
import { TeamsAndGroupsFilter } from './components/Filters/TeamsAndGroupsFilter/TeamsAndGroupsFilter';
import { TypesOfTasksFilter } from './components/Filters/TypesOfTasksFilter/TypesOfTasksFilter';
import { TaskPrioritiesFilter } from './components/Filters/TaskPrioritiesFilter/TaskPrioritiesFilter';
import { IdCodeNameFilter } from './components/Filters/IdCodeNameFilter/IdCodeNameFilter';
import { CreatedFilter } from './components/Filters/CreatedFilter/CreatedFilter';
import { StartDateFilter } from './components/Filters/StartDateFilter/StartDateFilter';
import { TimeLimitFilter } from './components/Filters/TimeLimitFilter/TimeLimitFilter';
import { ImplementationFilter } from './components/Filters/ImplementationFilter/ImplementationFilter';
import { HiddenFilter } from './components/Filters/HiddenFilter/HiddenFilter';
import { Button } from '../../../../components/_form';
import { useTaskSidebarContext } from '../../../../contexts/TaskSidebarContext';
import { toast } from 'react-toastify';

const filterItems = [
  {
    label: 'tasks.area',
    component: <AreaFilter />
  },
  {
    label: 'tasks.withAnAsterisk',
    component: <StarredFilter />
  },
  {
    label: 'tasks.statusPDCA',
    component: <StatusPDCAFilter />
  },
  {
    label: 'tasks.responsible',
    component: <ResponsibleFilter />
  },
  {
    label: 'tasks.author',
    component: <AuthorFilter />
  },
  {
    label: 'tasks.teamsAndGroups',
    component: <TeamsAndGroupsFilter />
  },
  {
    label: 'tasks.taskPriorities',
    component: <TaskPrioritiesFilter />
  },
  {
    label: 'tasks.typesOfTasks',
    component: <TypesOfTasksFilter />
  },
  {
    label: 'tasks.idCodeName',
    component: <IdCodeNameFilter />
  },
  {
    label: 'tasks.created',
    component: <CreatedFilter />
  },
  {
    label: 'tasks.startDate',
    component: <StartDateFilter />
  },
  {
    label: 'tasks.timeLimitForCompletion',
    component: <TimeLimitFilter />
  },
  {
    label: 'tasks.implementation',
    component: <ImplementationFilter />
  },
  {
    label: 'tasks.hidden',
    component: <HiddenFilter />
  }
];

export const Sidebar: React.FC = () => {
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const { filters, setSavedFilters } = useTaskSidebarContext();
  const { t } = useTranslation();

  const handleOpenFilter = (label: string) => {
    setOpenFilters((prevState) => {
      if (prevState.includes(label))
        return prevState.filter((el) => el !== label);
      return [...prevState, label];
    });
  };

  return (
    <Wrapper flexDirection="column">
      <Box mb={2}>
        <DropdownFilters />
      </Box>
      {filterItems.map((el) => (
        <Box key={el.label} my={2}>
          <CollapseButton onClick={() => handleOpenFilter(el.label)}>
            <p>{t(el.label)}</p>
            <FontAwesomeIcon
              icon={
                openFilters.includes(el.label) ? faChevronUp : faChevronDown
              }
            />
          </CollapseButton>
          <Collapse isOpened={openFilters.includes(el.label)}>
            {/* @ts-ignore*/}
            {el.component}
          </Collapse>
        </Box>
      ))}
      <Box my={2} width="100%">
        <Button
          iconPosition="left"
          icon={<FontAwesomeIcon icon={faFilter} />}
          fullWidth
        >
          Filtruj
        </Button>
      </Box>
      <Button
        onClick={() => {
          setSavedFilters(filters);
          toast.success(t('tasks.filtersSaved'));
        }}
        iconPosition="left"
        variant="eucalyptus"
        fullWidth
        icon={<FontAwesomeIcon icon={faFloppyDisk} />}
      >
        Zapisz
      </Button>
    </Wrapper>
  );
};
