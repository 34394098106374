import { Box, Flex } from 'components';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { P } from 'components/Typography/Typography';
import { TextField } from 'components/_form';
import { t } from 'i18next';

interface Props extends IGenericModal {
  data?: { idetificator: string };
}

export const UpdateTranslationModal = ({
  isOpen,
  onCancelClick,
  data
}: Props) => {
  return (
    <Modal
      headerTitle={t('translationsView.updateTranslation')}
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      mainButton={{
        action: onCancelClick,
        label: t('buttons.save')
      }}
      isSecondButtonVisible
    >
      <Flex flexDirection="column">
        <P color="coloured" variant="body">
          {data?.idetificator}
        </P>

        <Flex mt={3} alignItems="center">
          <Box width="25%">
            <P mr={4} mb={3} variant="body">
              {t('translationsView.tableHeaders.en')}
            </P>
          </Box>
          <TextField />
        </Flex>

        <Flex alignItems="center">
          <Box width="25%">
            <P mr={4} mb={3} variant="body">
              {t('translationsView.tableHeaders.pl')}
            </P>
          </Box>
          <TextField />
        </Flex>

        <Flex alignItems="center">
          <Box width="25%">
            <P mr={4} mb={3} variant="body">
              {t('translationsView.tableHeaders.un')}
            </P>
          </Box>
          <TextField />
        </Flex>

        <Flex alignItems="center">
          <Box width="25%">
            <P mr={4} mb={3} variant="body">
              {t('translationsView.tableHeaders.ru')}
            </P>
          </Box>
          <TextField />
        </Flex>
      </Flex>
    </Modal>
  );
};
