import { Flex } from 'components';
import styled from 'styled-components';

export const Topic = styled(Flex)<{ width?: string }>`
  border-right: 2px solid ${({ theme }) => theme.palette.neutral.dark};
  color: ${({ theme }) => theme.palette.accent.steelBlue};
  width: ${({ width }) => width || '70px'};
  text-align: center;
  overflow: hidden;
`;
