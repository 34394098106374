import { Flex } from 'components';
import styled from 'styled-components';

export const Cell = styled(Flex)<{
  isBorderRight?: boolean;
  isBorderBottom?: boolean;
}>`
  width: 150px;
  border: 1px solid black;
  border-bottom: ${({ isBorderBottom }) => (isBorderBottom ? '1px' : '0px')}
    solid;
  border-right: ${({ isBorderRight }) => (isBorderRight ? '1px' : '0px')} solid;
  flex-direction: column;
  justify-content: space-around;
  gap: 3px;
  position: relative;
  padding: 5px 0;
`;

export const ValueBar = styled(Flex)<{
  bgColor?: string;
  value: number;
  height?: string;
}>`
  height: 100%;
  color: ${({ theme }) => theme.palette.accent.green};
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.palette.neutral.mainBlue};
  width: ${({ value }) => `${value}%`};
`;

export const DestinationValueBar = styled(Flex)<{
  bgColor?: string;
  value: number;
}>`
  height: 100%;
  position: absolute;
  left: ${({ value }) => `${value}%`};
  width: 1px;
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.palette.neutral.mainBlue};
`;
