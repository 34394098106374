import { Flex } from 'components';
import { H2 } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { PartialAudit } from 'views/AdministrationView/views/AuditsView/components/AuditFormForm/components/AuditFormPreview/AuditFormPreview';

interface Props {
  audit?: PartialAudit;
}

const AuditCriteriaHeaders: React.FC<Props> = ({ audit }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      height={['auto', '42px']}
    >
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        width="100%"
        alignItems={['start', 'center']}
        mx={2}
      >
        <Flex ml={2}>
          <H2 variant="h3" mr={2}>
            {t('evaluationsView.audit')}:
          </H2>
          <H2 variant="h3" color="red" mr={20}>
            {audit?.name}
          </H2>
        </Flex>
        {audit?.audit_form?.first_header_name && (
          <Flex ml={2}>
            <H2 variant="h3" mr={2}>
              {audit?.audit_form?.first_header_name}:
            </H2>
            <H2 variant="h3" color="red" mr={20}>
              {audit?.first_header_value_content}
            </H2>
          </Flex>
        )}
        {audit?.audit_form?.second_header_name && (
          <Flex ml={2}>
            <H2 variant="h3" mr={2}>
              {audit?.audit_form?.second_header_name}:
            </H2>
            <H2 variant="h3" color="red" mr={20}>
              {audit?.second_header_value_content}
            </H2>
          </Flex>
        )}
        {audit?.audit_form?.third_header_name && (
          <Flex ml={2}>
            <H2 variant="h3" mr={2}>
              {audit?.audit_form?.third_header_name}:
            </H2>
            <H2 variant="h3" color="red" mr={20}>
              {audit?.third_header_value_content}
            </H2>
          </Flex>
        )}
        {audit?.audit_form?.fourth_header_name && (
          <Flex ml={2}>
            <H2 variant="h3" mr={2}>
              {audit?.audit_form?.fourth_header_name}:
            </H2>
            <H2 variant="h3" color="red" mr={20}>
              {audit?.fourth_header_value_content}
            </H2>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default AuditCriteriaHeaders;
