import {
  Content,
  ContentChildrenWrapper,
  ResizerButton,
  SidebarResizer,
  Wrapper
} from './TasksView.styled';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Sidebar } from './components/Sidebar/Sidebar';
import {
  faCalendarDays,
  faCaretLeft,
  faCaretRight,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskTable } from './components/Content/TaskTable/TaskTable';
import { TaskChart } from './components/Content/TaskChart/TaskChart';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { t } from 'i18next';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { TaskCalendar } from './components/Content/TaskCalendar/TaskCalendar';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const TasksView = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(250);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const windowWidth = useWindowWidth();

  const startResizing = useCallback((mouseDownEvent: MouseEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: unknown) => {
      if (isResizing) {
        const val =
          // @ts-ignore
          mouseMoveEvent?.clientX -
          // @ts-ignore
          sidebarRef?.current?.getBoundingClientRect().left;

        val > 250 && val < 400 && setSidebarWidth(val);
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    setSidebarOpen(windowWidth > 768);
  }, [windowWidth]);

  const sidebarMemo = useMemo(() => <Sidebar />, []);
  const content = useMemo(
    () => (
      <Content>
        <TaskTable />

        <ContentChildrenWrapper>
          <CustomTabs selectedTabClassName="is-selected">
            <CustomTabList isMarginBottomDisabled>
              <Flex flexWrap="wrap">
                <CustomTab>
                  <FontAwesomeIcon icon={faChartBar} /> {t('tasks.chart')}
                </CustomTab>

                <CustomTab>
                  <FontAwesomeIcon icon={faCalendarDays} />{' '}
                  {t('tasks.calendar')}
                </CustomTab>
              </Flex>
            </CustomTabList>
            <CustomTabPanel>
              <TaskChart />
            </CustomTabPanel>
            <CustomTabPanel>
              <TaskCalendar />
            </CustomTabPanel>
          </CustomTabs>
        </ContentChildrenWrapper>
      </Content>
    ),
    []
  );

  return (
    <Wrapper>
      <div
        ref={sidebarRef}
        // onMouseDown={(e: MouseEvent) => e.preventDefault()}
        style={{ width: sidebarWidth, display: sidebarOpen ? 'block' : 'none' }}
      >
        {sidebarMemo}
      </div>
      <SidebarResizer onMouseDown={startResizing}>
        <ResizerButton
          onClick={() => setSidebarOpen((prevState) => !prevState)}
        >
          <FontAwesomeIcon icon={sidebarOpen ? faCaretLeft : faCaretRight} />
        </ResizerButton>
      </SidebarResizer>
      {content}
    </Wrapper>
  );
};
