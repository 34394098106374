import { DatePicker } from 'components/DatePicker';
import { Flex } from 'components/Flex';
import { IGenericModal, Modal } from 'components/Modal/Modal';
import { TextField } from 'components/_form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends IGenericModal {
  handleSubmit: (date: string) => void;
  minDate?: Date;
}

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onCancelClick,
  handleSubmit,
  minDate
}) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [hour, setHour] = useState(
    `${new Date().getHours()}:${new Date().getMinutes()}`
  );

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      mainButton={{
        action: () => {
          if (date) {
            const fullDate = new Date(date);

            fullDate.setHours(
              Number(hour.split(':')[0] || '00'),
              Number(hour.split(':')[1] || '00')
            );

            handleSubmit(JSON.parse(JSON.stringify(fullDate)));
          }
        },
        label: t('common.save')
      }}
      headerTitle=""
      gridTemplateColumns="30%"
      onCancelClick={onCancelClick}
    >
      <Flex height="320px" justifyContent="center">
        <Flex gap="10px">
          <DatePicker
            minDate={minDate}
            value={date}
            onChange={(date) => setDate(date)}
          />

          <TextField
            type="time"
            value={hour}
            onChange={(e) => {
              setHour(e.target.value || '');
            }}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
