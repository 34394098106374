import { Flex, Table } from 'components';
import { RGBLine } from 'components/RGBLine/RGBLine';
import { H1 } from 'components/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlatTLogo } from 'assets/icons/PlatTLogo.svg';
import { DetailsTable } from './components/DetailsTable';
import { Button } from 'components/_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export const ImplementationActivities = () => {
  const { t } = useTranslation();

  const header: string[] = [
    t('documentsView.tableHeaders.number'),
    t('documentsView.tableHeaders.activityName'),
    t('documentsView.tableHeaders.area'),
    t('documentsView.tableHeaders.responsible'),
    t('documentsView.tableHeaders.task')
  ];

  const data = [
    {
      number: '1',
      activityName:
        'Woparciu o ocenę stylów liderstwa i klimatu przygotuj plan szkoleń dla kierowników, objętych oceną eli o ZE',
      area: 'Cała firma',
      responsible: 'Koordynator',
      task: (
        <Button variant="eucalyptus">
          <Flex alignItems="center" gap="10px">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            {t('documentsView.run')}
          </Flex>
        </Button>
      )
    },
    {
      number: '2',
      activityName:
        'Woparciu o ocenę stylów liderstwa i klimatu przygotuj plan szkoleń dla kierowników, objętych oceną eli o ZE',
      area: 'Cała firma',
      responsible: 'Koordynator',
      task: (
        <Button variant="eucalyptus">
          <Flex alignItems="center" gap="10px">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            {t('documentsView.run')}
          </Flex>
        </Button>
      )
    }
  ];

  const accordionsChildren = [<DetailsTable key="" />, <DetailsTable key="" />];

  return (
    <Flex width="80%" flexDirection="column">
      <Flex gap="20px" width="100%" alignItems="end">
        <PlatTLogo width="120px" height="120px" />

        <Flex width="100%" flexDirection="column" mb={3}>
          <H1 variant="h1" m={3}>
            {t('documentsView.implementationActivities')}
          </H1>
          <RGBLine />
        </Flex>
      </Flex>

      <Table
        header={header}
        data={data}
        leftFixedKeys={[0, 1]}
        sortTable={false}
        accordionsChildren={accordionsChildren}
      />
    </Flex>
  );
};
