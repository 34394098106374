import styled from 'styled-components';
import { Card, Flex } from 'components';
import { device } from 'styles/theme';

export const LogoContent = styled(Flex)`
  align-items: center;
  height: 350px;
  text-align: left;

  > h1 {
    max-width: 500px;
    margin: 40px 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    align-items: center;

    > h1 {
      text-align: center;
    }
  }

  @media ${device.mobile} {
    height: unset;

    > h1 {
      margin: unset;
    }
  }
`;

export const DescriptionContent = styled(Flex)`
  grid-area: description;
`;

export const FooterContent = styled(Flex)`
  grid-area: footer;
  ${({ theme }) => `
      background-color: ${theme.palette.neutral.grey};
    `}
  @media ${device.laptop} {
    justify-content: center;
  }
`;
export const ViewContent = styled(Flex)`
  grid-area: content;
  background-color: ${({ theme }) => theme.palette.neutral.medium};
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > ${Card} {
    width: 80%;
    margin: 10px;
    border-radius: 0px;

    @media ${device.tablet} {
      width: auto;
    }
  }
`;

export const Wrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `}
  display: grid;
  height: 100vh;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'description content'
    'footer footer';

  > ${LogoContent}, ${FooterContent} {
    justify-content: center;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;

    > ${LogoContent}, ${FooterContent} {
      padding: 30px;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'description'
      'content'
      'footer';
  }
`;
