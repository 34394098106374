import { Flex, Line } from 'components';
import { BackButton } from 'components/BackButton';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabPanel } from 'components/Tabs/CustomTabPanel';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { H1 } from 'components/Typography/Typography';
import { t } from 'i18next';
import { TasksSettingsForm } from './components/TasksSettings/TasksSettingsForm';
import { Wrapper } from './AdministrationTasksView.styled';
import { TaskTypes } from './components/TaskTypes/TaskTypes';

export const AdministrationTasksView = () => {
  return (
    <Wrapper mb={110} flexDirection="column">
      <Flex width="100%">
        <BackButton />
        <H1 m={2} variant={['h3', 'h2']} textTransform="uppercase">
          {t('administration.administrationLabel')} |{' '}
          {t('administrationTasksView.TASKS')}
        </H1>
      </Flex>
      <Line />
      <Flex minHeight="400px" flexWrap="wrap">
        <CustomTabs selectedTabClassName="is-selected">
          <CustomTabList>
            <Flex flexWrap="wrap">
              <CustomTab>
                {t('administrationTasksView.tasksSettings')}
              </CustomTab>
              <CustomTab>{t('administrationTasksView.tasksTypes')}</CustomTab>
              <CustomTab>{t('administrationTasksView.priorities')}</CustomTab>
            </Flex>
          </CustomTabList>
          <CustomTabPanel>
            <TasksSettingsForm />
          </CustomTabPanel>
          <CustomTabPanel>
            <TaskTypes />
          </CustomTabPanel>
        </CustomTabs>
      </Flex>
    </Wrapper>
  );
};
