import { Flex, Table } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPencil, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button, Select, TextField } from 'components/_form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TablePagination } from 'components/Table/TablePagination';

const Icon = styled(Flex)<{ isActive?: boolean }>`
  justify-content: center;
  ::after {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: ${({ isActive }) => (isActive ? 'green' : 'grey')};
  }
`;

interface Data {
  active: React.ReactNode;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  PE: JSX.Element;
  languagesEditing: string;
  company: string;
  type: string;
  lastLogging: string;
  lastActivity: string;
  editing: JSX.Element;
}

const UserListTable = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const header: string[] = [
    '',
    t('translationsView.tableHeaders.id'),
    t('translationsView.tableHeaders.firstName'),
    t('translationsView.tableHeaders.lastName'),
    t('translationsView.tableHeaders.email'),
    t('translationsView.tableHeaders.PE'),
    t('translationsView.tableHeaders.languagesEditing'),
    t('translationsView.tableHeaders.company'),
    t('translationsView.tableHeaders.type'),
    t('translationsView.tableHeaders.lastLogging'),
    t('translationsView.tableHeaders.lastActivity'),
    ''
  ];

  const button = (id: string) => (
    <Button
      variant="eucalyptus"
      onClick={() => {
        navigate(`/app/administration/employees-and-users/user/${id}/3`);
      }}
      icon={<FontAwesomeIcon icon={faEdit} />}
    />
  );

  const PEForm = (id: string) => (
    <Button
      variant="secondary"
      onClick={() => {}}
      icon={<FontAwesomeIcon icon={faPencil} />}
    />
  );

  const data: Data[] = [
    {
      active: <Icon isActive />,
      id: 'EF2211',
      firstName: 'Michał',
      lastName: 'Łopaciński',
      email: 'michał@email.com',
      PE: PEForm('EF2211'),
      languagesEditing: 'Polski',
      company: 'Softmaker',
      type: 'admin',
      lastLogging: '23.12.2022 16:40',
      lastActivity: '23.12.2022 16:40',
      editing: button('EF2211')
    },
    {
      active: <Icon isActive />,
      id: 'EF2211',
      firstName: 'Michał',
      lastName: 'Łopaciński',
      email: 'michał@email.com',
      PE: PEForm('EF2211'),
      languagesEditing: 'Polski',
      company: 'Softmaker',
      type: 'admin',
      lastLogging: '23.12.2022 16:40',
      lastActivity: '23.12.2022 16:40',
      editing: button('EF2211')
    }
  ];

  const additionalHeader = (
    <>
      <Select
        placeholder={t('employeesAndUsersView.form.chooseCompany') ?? undefined}
        options={[]}
        withoutMargin
      />
      <FontAwesomeIcon icon={faSearch} />
      <TextField
        placeholder={t('employeesAndUsersView.form.search') ?? undefined}
        hideError
      />
      <TablePagination
        total={150}
        page={page}
        setPage={setPage}
        perPage={perPage}
      />
    </>
  );

  return (
    <>
      <Table
        header={header}
        data={data}
        pagination={{
          page: page,
          setPage: setPage,
          perPage: perPage,
          total: 150,
          setPerPage: setPerPage
        }}
        leftFixedKeys={[0, 1]}
        additionalHeaderElement={additionalHeader}
      />
    </>
  );
};

export default UserListTable;
