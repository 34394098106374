import styled from 'styled-components';

interface Props {
  variant?: 'primary' | 'secondary' | 'tertiary';
}

export const Line = styled.span<Props>`
  ${({ theme, variant = 'primary' }) => `
	    display: flex;
      background-color: ${
        variant === 'primary'
          ? theme.palette.neutral.mainBlue
          : variant === 'secondary'
          ? theme.palette.neutral.lightGrey
          : theme.palette.accent.green
      };
      margin: 5px 0;
      width: 100%;
      height: 1px;
  `};
`;
