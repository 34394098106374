import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/Flex';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

interface Props {
  tooltipId: string;
  tooltipValue: string;
  tooltipWidth?: string;
}

const Container = styled(Flex)`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)<{ tooltipWidth?: string }>`
  ${({ tooltipWidth }) => `width: ${tooltipWidth ? tooltipWidth : 'auto'};`}
  z-index: 10;
`;

export const InfoTooltip: React.FC<Props> = ({
  tooltipId,
  tooltipValue,
  tooltipWidth
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Container
        id={tooltipId}
        data-tooltip-content={t(tooltipValue) as string}
      >
        <FontAwesomeIcon size="xl" icon={faCircleInfo} />
      </Container>
      <StyledTooltip tooltipWidth={tooltipWidth} anchorId={tooltipId} />
    </>
  );
};
