import {
  faBullseye,
  faDownload,
  faList
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components';
import { CustomTab } from 'components/Tabs/CustomTab';
import { CustomTabList } from 'components/Tabs/CustomTabList';
import { CustomTabs } from 'components/Tabs/CustomTabs';
import { Button, Checkbox } from 'components/_form';
import { TabPanel } from 'react-tabs';
import { EvaluationBlocks } from '../EvaluationBlocks/EvaluationBlocks';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useEvaluationContext } from 'contexts/EvaluationContext';
import { EvaluationTopics } from '../EvaluationTopics/EvaluationTopics';
import { EvaluationRadar } from '../EvaluationRadar/EvaluationRadar';
import { Topic } from 'types/methologies';
import { RadarGraphDataset } from 'types/evaluations';

const initGraphValueStagesForPlat = [1.025, 2.05, 3.075, 4.1];
const initGraphValueStagesForADMA = [...initGraphValueStagesForPlat, 5.125];

const radarGraphColors = {
  border: [
    'rgb(255, 0, 0)',
    'rgb(153, 0, 255)',
    'rgb(0, 4, 255)',
    'rgb(96, 165, 255)',
    'rgb(96, 255, 255)',
    'rgb(96, 255, 99)',
    'rgba(255, 99, 132, 1)'
  ],
  background: [
    'rgba(255, 0, 0, 0.2)',
    'rgba(153, 0, 255, 0.2)',
    'rgba(0, 4, 255, 0.2)',
    'rgba(96, 165, 255, 0.2)',
    'rgba(96, 255, 255, 0.2)',
    'rgba(96, 255, 99, 0.2)',
    'rgba(255, 99, 132, 0.2)'
  ]
};

export const EvaluationDetails: React.FC = () => {
  const [showLegend, setShowLegend] = useState(false);
  const [axes, setAxes] = useState<Topic[]>([]);
  const [graphTitle, setGraphTitle] = useState('');
  const [datasets, setDatasets] = useState<RadarGraphDataset[]>([]);
  const [radarGraphStages, setRadarGraphStages] = useState<number[]>(
    initGraphValueStagesForPlat
  );

  const { t } = useTranslation();
  const { selectedEvaluations } = useEvaluationContext();

  const getRadarGraphColors = useCallback(
    (variant: 'border' | 'background', index: number) => {
      const colorIndex = index % radarGraphColors[variant].length;

      return radarGraphColors[variant][colorIndex];
    },
    []
  );

  // TODO: might be usefull later, we will know after the meeting with client
  // const roundToNumber = useCallback((numbersToRound: number[], num: number) => {
  //   const differenceWithEachNumber: number[] = [];

  //   numbersToRound.map((number) => {
  //     differenceWithEachNumber.push(Math.abs(num - number));
  //   });

  //   const minDifference = Math.min(...differenceWithEachNumber);
  //   const minIndex = differenceWithEachNumber.indexOf(minDifference);

  //   const closestNumber = numbersToRound[minIndex];

  //   return Number(closestNumber);
  // }, []);

  const checkNumberOfSelectedPractices = useCallback(() => {
    const practices: number[] = [];

    if (selectedEvaluations.length) {
      selectedEvaluations.map((evaluation) => {
        if (!practices.includes(evaluation.practice?.id)) {
          practices.push(evaluation.practice?.id);
        }
      });
    }

    return practices.length;
  }, [selectedEvaluations]);

  useEffect(() => {
    if (selectedEvaluations.length) {
      const numberOfSelectedPractices = checkNumberOfSelectedPractices();
      if (numberOfSelectedPractices === 1) {
        setAxes(selectedEvaluations[0].practice?.topics);
        setGraphTitle(selectedEvaluations[0].practice?.name);
      } else if (numberOfSelectedPractices > 1) {
        setRadarGraphStages(initGraphValueStagesForADMA);
      }
    }
  }, [checkNumberOfSelectedPractices, selectedEvaluations]);

  const makeDatasets = useCallback(() => {
    const datasets: RadarGraphDataset[] = [];

    if (selectedEvaluations.length) {
      selectedEvaluations.map((evaluation, i) => {
        const dataset: RadarGraphDataset = {
          label: evaluation.practice?.name + ' | ' + evaluation.name,
          data: [],
          backgroundColor: getRadarGraphColors('background', i),
          borderColor: getRadarGraphColors('border', i),
          borderWidth: 1
        };

        evaluation.practice?.topics.map((topic) => {
          dataset.data.push(topic.score);
        });

        datasets.push(dataset);
      });
    }

    return datasets;
  }, [getRadarGraphColors, selectedEvaluations]);

  useEffect(() => {
    setDatasets(makeDatasets());
  }, [makeDatasets]);

  return (
    <CustomTabs selectedTabClassName="is-selected">
      <CustomTabList style={{ borderBottom: 'none' }}>
        <Flex flexWrap="wrap">
          <CustomTab>{t('evaluationsView.blocks')}</CustomTab>
          <CustomTab>{t('evaluationsView.topics')}</CustomTab>
          <CustomTab>{t('evaluationsView.radar')}</CustomTab>
          <CustomTab>{t('evaluationsView.data')}</CustomTab>

          <Flex ml="auto" my="auto" gap="20px">
            <Flex alignItems="center">
              <Checkbox
                checked={showLegend}
                onChange={(val) => {
                  setShowLegend(val.target.checked);
                }}
              />
              <FontAwesomeIcon icon={faList} />
            </Flex>

            <Flex alignItems="center">
              <Checkbox />
              <FontAwesomeIcon icon={faBullseye} />
            </Flex>

            <Button variant="secondary" bordered onClick={() => {}}>
              <FontAwesomeIcon icon={faDownload} /> {t('buttons.save')}
            </Button>
          </Flex>
        </Flex>
      </CustomTabList>

      <TabPanel>
        {selectedEvaluations.length ? (
          <EvaluationBlocks showLegend={showLegend} />
        ) : (
          ''
        )}
      </TabPanel>

      <TabPanel>
        <EvaluationTopics />
      </TabPanel>

      <TabPanel>
        <EvaluationRadar
          axes={axes}
          datasets={datasets}
          graphStages={radarGraphStages}
        />
      </TabPanel>

      <TabPanel>-</TabPanel>
    </CustomTabs>
  );
};
