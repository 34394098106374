import { Flex } from 'components';
import {
  TableCell,
  TableHeader
} from '../AuditFormsList/AuditFormsList.styled';
import { Radio, SwitchButton, TextField } from 'components/_form';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { P } from 'components/Typography/Typography';
import { ColorPicker } from 'components/_form/ColorPicker/ColorPicker';
import { RatingScaleButton } from 'components/RatingScaleButtons/RatingScaleButton';
import { IPostRatingScale } from 'types/ratingScales';
import { ImagePicker } from './components/ImagePicker';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { isTooDarkYIQ } from 'utilities/colors/isTooDarkYIQ';
import { isTooLightYIQ } from 'utilities/colors/isTooLightYIQ';

interface Props {}

export const RatingScaleForm: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const { control, register, watch } =
    useFormContext<IPostRatingScale['rating_scale']>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `buttons_attributes`
  });

  return (
    <form>
      <Flex flexDirection="column" minHeight="500px" minWidth="550px">
        <Flex>
          <TableHeader width="50%">
            {t('administrationAuditsView.ratingScaleName')}
          </TableHeader>
          <TableHeader width="10%">
            {t('administrationAuditsView.ratingScaleTableHeaders.levels')}
          </TableHeader>
          <TableHeader width="40%">
            {t('administrationAuditsView.consequences')}
          </TableHeader>
        </Flex>
        <Flex>
          <TableCell width="50%">
            <TextField {...register('name')} />
          </TableCell>
          <TableCell width="10%">
            <TextField
              type="number"
              value={fields.length}
              min={2}
              max={10}
              onChange={(e) => {
                const newValue = Number(e.target.value);

                if (newValue > 1 && newValue <= 10) {
                  const buttonsNumberDiff = fields.length - newValue;

                  if (buttonsNumberDiff > 0) {
                    for (let i = 0; i < buttonsNumberDiff; i++) {
                      remove(fields.length - i - 1);
                    }
                  } else {
                    for (let i = 0; i < -buttonsNumberDiff; i++) {
                      append({
                        text_value: '1',
                        value: 1,
                        color1: 'grey',
                        color2: 'white'
                      });
                    }
                  }
                }
              }}
            />
          </TableCell>
          <TableCell width="40%" justifyContent="space-around">
            <Radio
              label={t('administrationAuditsView.textType')}
              {...register('button_type')}
              value="text"
            />
            <Radio
              label={t('administrationAuditsView.pictorial')}
              {...register('button_type')}
              value="picture"
            />
          </TableCell>
        </Flex>

        <Flex pt={4} pb={2} gap="25px" overflow="auto">
          <Flex flexDirection="column" gap="20px">
            {watch('button_type') === 'picture' ? (
              <Flex minHeight="120px" alignItems="center">
                <P variant="h3">{t('administrationAuditsView.picture')}</P>
              </Flex>
            ) : (
              <Flex minHeight="40px" alignItems="center">
                <P variant="h3">{t('administrationAuditsView.text')}</P>
              </Flex>
            )}

            <Flex minHeight="35px" alignItems="center">
              <P variant="h3">{t('administrationAuditsView.value')}</P>
            </Flex>
            <Flex
              minHeight="35px"
              alignItems="center"
              justifyContent="space-between"
            >
              <P variant="h3">{t('administrationAuditsView.color1')}</P>
              <InfoTooltip
                tooltipId="rating-scale-color1-tooltip"
                tooltipValue={t('administrationAuditsView.color1Tooltip')}
                tooltipWidth="300px"
              />
            </Flex>
            <Flex
              minHeight="40px"
              alignItems="center"
              justifyContent="space-between"
            >
              <P variant="h3">{t('administrationAuditsView.color2')}</P>
              <InfoTooltip
                tooltipId="rating-scale-color2-tooltip"
                tooltipValue={t('administrationAuditsView.color2Tooltip')}
                tooltipWidth="300px"
              />
            </Flex>
            <Flex minHeight="50px" minWidth="100px" alignItems="center">
              <P variant="h3">{t('administrationAuditsView.ratingButtons')}</P>
            </Flex>
            <Flex minHeight="20px" width="100px" alignItems="center">
              <P variant="h3">
                {t('administrationAuditsView.evaluationInAudit')}
              </P>
            </Flex>
          </Flex>

          {fields.map((field, index) => {
            const button = {
              id: `fake-rating-scale-button-${index}`,
              rating_scale_id: 'fake-rating-scale-id',
              text_value: watch(`buttons_attributes.${index}.text_value`),
              value: watch(`buttons_attributes.${index}.value`),
              color1: watch(`buttons_attributes.${index}.color1`),
              color2: watch(`buttons_attributes.${index}.color2`),
              image_url:
                watch(`buttons_attributes.${index}.image`) &&
                URL.createObjectURL(
                  watch(`buttons_attributes.${index}.image`) as File
                )
            };

            return (
              <Flex
                key={field.id}
                flexDirection="column"
                gap="20px"
                alignItems="center"
              >
                {watch('button_type') === 'picture' ? (
                  <Controller
                    name={`buttons_attributes.${index}.image`}
                    render={({ field: { value, onChange } }) => (
                      <ImagePicker
                        onChange={onChange}
                        previewUrl={value && URL.createObjectURL(value)}
                      />
                    )}
                  />
                ) : (
                  <TextField
                    width="60px"
                    centeredValue
                    {...register(`buttons_attributes.${index}.text_value`)}
                  />
                )}

                <TextField
                  width="50px"
                  type="number"
                  centeredValue
                  {...register(`buttons_attributes.${index}.value`)}
                />
                <Controller
                  name={`buttons_attributes.${index}.color1`}
                  render={({ field: { value, onChange } }) => (
                    <ColorPicker
                      controlledDisplayColorPicker={value}
                      onChange={(color) => {
                        onChange(color);
                      }}
                      error={isTooLightYIQ(value)}
                    />
                  )}
                />
                <Controller
                  name={`buttons_attributes.${index}.color2`}
                  render={({ field: { value, onChange } }) => (
                    <ColorPicker
                      controlledDisplayColorPicker={value}
                      onChange={(color) => {
                        onChange(color);
                      }}
                      error={isTooDarkYIQ(value)}
                    />
                  )}
                />
                <RatingScaleButton
                  showImages={watch('button_type') === 'picture'}
                  clearSrc
                  bordered={watch('border')}
                  button={button}
                  borderRadius
                />
                <RatingScaleButton clearSrc useValues button={button} />
              </Flex>
            );
          })}

          <Flex
            justifyContent="end"
            flexDirection="column"
            gap="40px"
            mb={2}
            minWidth="130px"
          >
            <Controller
              control={control}
              name="border"
              render={({ field: { onChange, value } }) => (
                <SwitchButton
                  label={t('administrationAuditsView.frame?')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="use_colored_values"
              render={({ field: { onChange, value } }) => (
                <SwitchButton
                  label={t('administrationAuditsView.useThese?')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};
